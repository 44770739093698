export const StreamVideo = async (props) => {
  console.log(props.video,props.audio)
   return  props.on === true? await onMyVideo(props.video,props.audio):offMyVideo();
    }

    const onMyVideo = async (video,audio) => {
     return await  navigator.mediaDevices
        .getUserMedia({ video:true, audio:true })
        .then((currentStream) => {
           currentStream
            .getVideoTracks()
            .forEach((track) => (track.enabled = video));
          currentStream
            .getAudioTracks()
            .forEach((track) => (track.enabled = audio));
          return currentStream;
        });
    };

    const offMyVideo = async () => {
      await  navigator.mediaDevices
        .getUserMedia({ video:true, audio:true })
        .then((currentStream) => {
          currentStream
          .getTracks().forEach((track) => {
            track.stop();
        });
      });
      return null;
    };
