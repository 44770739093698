import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import './InterviewDetails.css'
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { UserContext } from './UserContext';
import TimePicker from '@mui/lab/TimePicker';
import { useSearchParams } from 'react-router-dom';
import BulkDataUpload from './BulkDataUpload';
import Checkbox from '@mui/material/Checkbox';
// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Bookmarked = (props) => {
  
const [searchParams] = useSearchParams();
const [interview, setInterview] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [name, setName] = useState();
const [mail, setMail] = useState();
const [mobile, setMobile] = useState();
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState();
const [applicantId,setApplicantId]= useState()
const [companyName, setCompanyName] = useState();
const [open, setOpen] = React.useState(false);
const [Rounds, setRounds] = useState();
const [InterViewerName, setInterViewerName]= useState("");
const [InterViewDate, setInterViewDate] = React.useState(new Date());
const [InterViewTime, setInterViewTime] = React.useState();
const [interviewlocation, setInterviewlocation] = useState();
const [selectedRow,setSelectedRow] = useState();
const { user, setUser} = useContext(UserContext);
// const [companyName, setCompanyName] = useState();
// const [jobTitle, setJobTitle] = React.useState();
const [filterModel, setFilterModel] = React.useState({
  items: [
    // {columnField: 'createdbyname', operatorValue: 'contains', value:user.name}
  ],
});

const style3 = {
  position: 'absolute',
  top: '50%',

  transform: 'translate(87%, -50%)',
  height: 489,
  width: 533,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 10,

};

const cols = [
  {field:'action', headerName: '',width: 80, headerAlign:'center',

  renderCell: (params) => {
   return[
      <div>
       <Button size='small' title="Schedule"  onClick={()=>showApplicant(params.row)}><PersonSearchIcon/></Button>
       {/* <Button size='small' title="Approve" onClick={()=>ApproveApplicants(params.row)}><CheckIcon/></Button> */}
    {/* <Button size='small'  title="Reject" onClick={()=>RejectApplicants(params.row)}><CancelOutlinedIcon/></Button> */}
</div>
   ];
  },
},  
  {field:'name', headerName: 'Name',width: 190 },
  {field:'createdbyname', headerName: 'Created By',width: 190,hide: true },
  {field:'mobile', headerName: 'Mobile No',width: 140},
  {field:'bookmarkcomment', headerName: 'Remarks',width: 450},
  {field:'hrname', headerName: 'HR Name',width: 190},
  {field:'jobtitle', headerName: 'Job Title',width: 200},
  {field:'companyname', headerName: 'Company Name',width: 200},
//   {field:'Resume', headerName: 'Resume',width: 150,
//   renderCell: (params) =>{

// return[
// <div>

// <Button size='small' className='btn' title="Reject" onClick={()=>downloadApplicant(params.id)}><FileDownloadIcon/></Button></div>
// ];
// },
// },  
  {field:'interviewername', headerName: 'Interviewer Name',width: 190},
 
  {field:'interviewdate', headerName: 'Interview Date',width: 140},
  // {field:'interviewtime', headerName: 'Interview Time',width: 140},
  // {field:'rounds', headerName: 'Rounds',width: 150},
  // {field:'interviewlocation', headerName: 'Interview Location',width: 200},
  // {field:'status', headerName: 'Status',width: 110}
  ];

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const getInterviews = ()=>{
    
    let apiURL=process.env.REACT_APP_API_URL + "/interview/bookmarked/"+ user.name;
    let apiParams = {
        method:"GET", 
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // console.log(repos.data)
          setInterview(repos.data)
        }else props.showAlert(""+(repos.message || repos),"error");  
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }


  useEffect(()=>{
  getInterviews()
  },[])
  
  const scheduleinterview = (row) =>{

    setSelectedRow(row)
        setOpenScedule(true);
      }
    
  const handleCloseView = () => setOpen(false)


  
  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }


  const rowData = (interview)?.map((interview) => {
// alert(JSON.stringify(interview))
let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);

  return{
  id: interview?._id,  //applicant id
  applicantid:interview?.applicantId, 
  jobid:interview?.jobId,  //interview id
  name: interview?.name,
  mobile: interview?.mobile,
  jobtitle:interview?.jobtitle,
  companyname:interview?.companyname,
  interviewername:interview?.interviewername,
  hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
  interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
  interviewtime:  IntvwTime,
  interviewlocation:interview?.location,
  rounds:interview?.rounds,
  reference:interview?.reference,
  status:interview?.status,
  bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
  bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
  feedback:interview?.feedback,
  createdbyname:interview?.createdbyname,
  createdbyid:interview?.createdbyId,
  showAlert:props.showAlert,
    };
  });

  return (
    <div>
    <br/>
    <br/>
    <div style={{height:750,width: '100%'}}>
      <DataGrid 
      rows={rowData} columns={cols}  components={{
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowDoubleClick={(e)=>scheduleinterview(e.row)}
          />
 </div>
 {/* <ScheduleInterview open={OpenShedule} showAlert={props.showAlert} interview={selectedRow} close={()=>setOpenScedule(false)} /> */}
 <ScheduleInterviewUI open={OpenShedule} showAlert={props.showAlert} interview={selectedRow} close={()=>setOpenScedule(false)} />

</div>
  ) 
}

export default Bookmarked







