import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import './WorkPreferencesInterviewApplicantUI.css'
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';



const WorkPreferencesScheduleInterviewUI = (props) => {

//useState

    const [voiceProcess,setVoiceProcess] = useState(true)
    const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
    const [workFromHome,setWorkFromHome] = useState(false)
    const [workFromOffice,setWorkFromOffice] = useState(true)
    const [partTime,setPartTime] = useState(false)
    const [fullTime,setFullTime] = useState(true)
    const [temporary,setTemporary] = useState(true)
    const [nightShift,setNightShift] = useState(true)
    const [dayShift,setDayShift] = useState(true)
    const [companyTransportDay,setCompanyTransportDay] = useState(true)
    const [companyTransportNight,setCompanyTransportNight] = useState(true)
    const [ownTransport,setOwnTransport] = useState(true)
    const [hightlight,setHighlight] = useState({})

  //UseEffect     
 useEffect(()=>{
      let preferences = props.preferences;
     
      if(props.highlight.jobPreference)
    { 
      //  console.clear()
       
       setHighlight(props.highlight.jobPreference)
       console.log(props.highlight.jobPreference)
    }

    if(preferences){
      // console.log(preferences)
            setVoiceProcess(preferences.voiceProcess)
            setNonVoiceProcess(preferences.nonVoiceProcess)
            setWorkFromHome(preferences.workFromHome)
            setWorkFromOffice(preferences.workFromOffice)
            setPartTime(preferences.partTime)
            setFullTime(preferences.fullTime)
            setNightShift(preferences.nightShift)
            setDayShift(preferences.dayShift)
            setCompanyTransportDay(preferences.companyTransportDay)
            setCompanyTransportNight(preferences.companyTransportNight)
            setOwnTransport(preferences.ownTransport)
    }
   
   },[props.preferences,props.highlight])

//Functions

 
  return (
    <div>
  
  <div className='chkVoice_app'>
    <input disabled={props.type==="View"}  type="checkbox" id="chkVoice_app" name="chkVoice" checked={voiceProcess} onChange={()=>props.setPreference("voiceProcess",!voiceProcess)}/>
    </div>
    <label id='WPVoice_app' for="chkVoice" style={{color:voiceProcess?"red":"black",backgroundColor:(hightlight && hightlight.voiceProcess===true)?"yellow":"transparent"}}> Voice </label>

	
  <div className='Non_VoicePreference_app'>
<input disabled={props.type==="View"} type="checkbox" id="Non_VoicePreference_app" name="chkNonvoice" checked={nonVoiceProcess} onChange={()=>props.setPreference("nonVoiceProcess",!nonVoiceProcess)}/>
</div>
 <label id='Non-Voice_lbl_app' for="chkNonvoice" style={{color:nonVoiceProcess?"red":"black",backgroundColor:(hightlight && hightlight.nonVoiceProcess===true)?"yellow":"transparent"}}> Non-Voice </label>

	

  <div className='Semi-VoicePreference_app'>
    <input disabled={props.type==="View"} type="checkbox" id="Semi-VoicePreference_app" name="chkSemiVoice" checked={nonVoiceProcess===true && voiceProcess===true} onChange={(e)=>{
        
        if(e.target.checked===true){
          props.setPreference("voiceProcess",true)
          props.setPreference("nonVoiceProcess",true)
        }
        
        }}/>
	</div>
    <label id='Semi-Voice_candidate_Lbl_app' for="chkSemiVoice" style={{color:(nonVoiceProcess===true && voiceProcess===true?"red":"black"),backgroundColor:(hightlight && hightlight.nonVoiceProcess===true) && (hightlight.voiceProcess===true) ?"yellow":"transparent"}}> Semi Voice </label>

	
  <div id="Employment_Type_fs_app">
		<span>Employment Type</span>
	</div>


  <div className='FullTime_Preference_app_'>
        <input disabled={props.type==="View"} type="checkbox" id="FullTime_Preference_app_" name="chkFullTime" checked={fullTime} onChange={()=> props.setPreference("fullTime",!fullTime)}/>
    </div>
    <label id='Full_Time_Interview_Lbl_app_' for="chkFullTime" style={{color:fullTime?"red":"black",backgroundColor:(hightlight && hightlight.fullTime===true)?"yellow":"transparent"}}> Full Time </label>


	
  <div className='Part_Time_Preference_app_'>
    <input disabled={props.type==="View"} type="checkbox" id="Part_Time_Preference_app_" name="chkPartTime" checked={partTime} onChange={()=>props.setPreference("partTime",!partTime)}/>     
    </div>
    <label id='Part_Time_Candidate_lbl_app' for="chkPartTime" style={{color:partTime?"red":"black",backgroundColor:(hightlight && hightlight.partTime===true)?"yellow":"transparent"}}> Part Time </label>

    <div className='Temporary_Preference_app'>
    <input disabled={props.type==="View"} type="checkbox" id="chkTemporary" name="chkPartTime" checked={temporary} onChange={()=>props.setPreference("temporary",!temporary)}/>     
    </div>
    <label id='Temporary_Preference_Lbl_app_' for="chkPartTime" style={{color:temporary?"red":"black",backgroundColor:(hightlight && hightlight.temporary===true)?"yellow":"transparent"}}> Temporary</label>

  <div id="Work_Mode_fh_app">
		<span>Work Mode</span>
	</div>
 
  <div className='Office_Preferences_app'>
    <input disabled={props.type==="View"} type="checkbox" id="Office_Preferences_app" name="chkWorkfromoffcice" checked={workFromOffice} onChange={()=> props.setPreference("workFromOffice",!workFromOffice)}/>
    </div>
    <label id='Office_Preference_lbl_app' for="chkWorkfromoffcice" style={{color:workFromOffice?"red":"black",backgroundColor:(hightlight && hightlight.workFromOffice===true)?"yellow":"transparent"}}>Office</label>
	
  <div className='Home_Preferences_app_'>
        <input disabled={props.type==="View"} type="checkbox" id="Home_Preferences_app_" name="chkWorkfromhome" checked={workFromHome} onChange={()=> props.setPreference("workFromHome",!workFromHome)}/>
    </div>
        <label id='Home_Preferences_lbl_app_' for="chkWorkfromhome" style={{color:workFromHome?"red":"black",backgroundColor:(hightlight && hightlight.workFromHome===true)?"yellow":"transparent"}}>Home</label>
  

	
  <div className='Hybrid_Preference_app_'>
    <input disabled={props.type==="View"} type="checkbox" id="Hybrid_Preference_app_" name="chkHybrid" checked={workFromOffice===true && workFromHome===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("workFromHome",true)
          props.setPreference("workFromOffice",true)
          setWorkFromOffice(true)
          setWorkFromHome(true)
         }
         
        }}/>
    </div>

    <label id='Hybrid_Preference_lbl_app' for="chkHybrid" style={{color:workFromOffice===true && workFromHome===true?"red":"black",backgroundColor:(hightlight.workFromOffice===true)&& (hightlight.workFromHome===true)?"yellow":"transparent"}}>Hybrid</label>

  <div id="Shifts_f_app">
		<span>Shifts</span>
	</div>

  <div className='Day_Preference_app_'>
    <input disabled={props.type==="View"} type="checkbox" id="Day_Preference_app_" name="chkDayShift" checked={dayShift} onChange={()=> props.setPreference("dayShift",!dayShift)}/>
    </div>
    <label id='Day_Preference_lbl_app' for="chkDayShift" style={{color:dayShift?"red":"black",backgroundColor:(hightlight.dayShift===true)?"yellow":"transparent"}}>Day</label>

  <div className='Night_preference_app'>
    <input disabled={props.type==="View"} type="checkbox" id="Night_preference_app" name="chkNightShift" checked={nightShift} onChange={()=> props.setPreference("nightShift",!nightShift)}/>
    </div>
    <label id='Night_preference_lbl_app_' for="chkNightShift" style={{color:nightShift?"red":"black",backgroundColor:(hightlight.nightShift===true)?"yellow":"transparent"}}>Night</label>

  
	
  <div className='Rotational_Preference_app'>
    <input disabled={props.type==="View"} type="checkbox" id="Rotational_Preference_app" name="chkRotaionalShift" checked={nightShift===true && dayShift===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("nightShift",true)
          props.setPreference("dayShift",true)
         }
          }}/>
    </div>
        <label id='Rotational_Preference_lbl_app' for="chkRotationalShift" style={{color:nightShift===true && dayShift===true?"red":"black",backgroundColor:(hightlight.nightShift===true)  && (hightlight.dayShift===true)?"yellow":"transparent"}}>Rotational</label>

	

	<div id="Transportation_fn_app">
		<span>Transportation</span>
	</div>
 

   <div className='Own_Transporation_Preference_app'>
    <input disabled={props.type==="View"} type="checkbox" id="Own_Transporation_Preference_app" name="chkOwnTransport" checked={ownTransport} onChange={()=> props.setPreference("ownTransport",!ownTransport)}/>
    </div>
    <label id='Own_Transporation_Preference_lbl_app' for="chkOwnTransport" style={{color:ownTransport?"red":"black",backgroundColor:(hightlight.ownTransport===true)?"yellow":"transparent"}}>Own</label>
	
  <div className='Company_Day_Preference_app_'>
    <input disabled={props.type==="View"} type="checkbox" id="Company_Day_Preference_app_" name="chkCompanyTransportDay" checked={companyTransportDay} onChange={()=>props.setPreference("companyTransportDay",!companyTransportDay)}/>
    </div>
    <label id='Company_Day_Preference_lbl_app_' for="chkCompanyTransportDay" style={{color:companyTransportDay?"red":"black",backgroundColor:(hightlight.companyTransportDay===true)?"yellow":"transparent"}}> Company Day</label>       

  <div className='Company_Night_Preference_app_'>
    <input disabled={props.type==="View"} type="checkbox" id="Company_Night_Preference_app_" name="chkCompanyTransportNight" checked={companyTransportNight} onChange={()=>props.setPreference("companyTransportNight",!companyTransportNight)}/>
    </div>
    
    <label id='Company_Night_Preference_lbl_app_' for="chkCompanyTransportNight" style={{color:companyTransportNight?"red":"black",backgroundColor:(hightlight.companyTransportNight===true)?"yellow":"transparent"}}> Company Night</label>       

     </div>
  )
}

export default WorkPreferencesScheduleInterviewUI
