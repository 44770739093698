import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import {useNavigate} from "react-router-dom";
import '../Styles/RecruiterVacancyTopBar.css';



const RecruiterVacancyTopBar = () => {
    var navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const handlelogout = () =>{
		localStorage.clear();
		setUser(null);
		window.location.href = "/";
	  }

  return (
    <div>

   <div id="vacancies_pc_header_">
	<div id="left_grp_vacancies_rec">
		<svg class="left_corner_vacancies_rec" viewBox="11429 2956.231 545.423 71">
			<path id="left_corner_vacancies_rec" d="M 11429 2977.013916015625 L 11429 2956.2314453125 L 11974.4228515625 2956.2314453125 L 11974.4228515625 3026.17724609375 L 11823.4423828125 3026.17724609375 C 11823.4423828125 3026.17724609375 11808.845703125 3027.49853515625 11808.267578125 3010.958251953125 C 11807.689453125 2994.418212890625 11808.267578125 2991.22607421875 11808.267578125 2991.22607421875 C 11808.267578125 2991.22607421875 11807.6884765625 2976.13671875 11789.7646484375 2976.13671875 C 11771.8408203125 2976.13671875 11672.9658203125 2976.13671875 11672.9658203125 2976.13671875 C 11672.9658203125 2976.13671875 11654.751953125 2978.45849609375 11654.751953125 2991.22607421875 C 11654.751953125 3003.99365234375 11654.751953125 3017.34228515625 11654.751953125 3017.34228515625 C 11654.751953125 3017.34228515625 11649.837890625 3027.788818359375 11640.0078125 3027.208251953125 C 11630.177734375 3026.62841796875 11622.9501953125 3019.37353515625 11623.2392578125 3017.34228515625 C 11623.5283203125 3015.311279296875 11623.2392578125 2991.22607421875 11623.2392578125 2991.22607421875 C 11623.2392578125 2991.22607421875 11622.083984375 2976.13671875 11605.8935546875 2976.13671875 C 11589.703125 2976.13671875 11429 2977.013916015625 11429 2977.013916015625 Z">
			</path>
		</svg>
		<a href="https://bizibees.in">
		<img id="bizibeesjpg_vacancies_rec" src="bizibeesjpg_vacancies_rec.png" srcset="bizibeesjpg_vacancies_rec.png 1x" alt=""/>
			
		</a>
		<div id="vacancies_rec">
			<span>Vacancies</span>
		</div>
		<div id="setup_vacancies_rec" onClick={()=>navigate('/officelocations')}>
			<span>Setup</span>
		</div>
	</div>
	<div id="right_grp_vacancies_rec">
		<svg class="right_corner_vacancies_rec" viewBox="12604.423 2956.948 175.078 71.411">
			<path id="right_corner_vacancies_rec" d="M 12604.4228515625 2958.037841796875 L 12604.4228515625 3028.342041015625 L 12618.232421875 3028.342041015625 C 12618.232421875 3028.342041015625 12635.0185546875 3029.3564453125 12635.0185546875 3015.6845703125 C 12635.0185546875 3002.01318359375 12635.0185546875 2988.84814453125 12635.0185546875 2988.84814453125 C 12635.0185546875 2988.84814453125 12636.486328125 2978.21484375 12648.7314453125 2977.708251953125 C 12660.9755859375 2977.20166015625 12779.5009765625 2977.708251953125 12779.5009765625 2977.708251953125 L 12779.5009765625 2956.94775390625 L 12766.078125 2958.037841796875 L 12604.4228515625 2958.037841796875 Z">
			</path>
		</svg>
		<img title="Log Out" id="logout_vacancies_rec" src="logout_vacancies_rec.png" srcset="logout_vacancies_rec.png 1x" onClick={()=>handlelogout()}/>
			
		<img title="Profile" id="profile_vacancies_rec_" src="profile_vacancies_rec_.png" srcset="profile_vacancies_rec_.png 1x" onClick={()=>navigate('/dashboard')}/>
			
	</div>
	<div id="centre_header_pc_grp_vacancies">
		<svg class="centre_header_pc_vacancies_rec">
			<rect id="centre_header_pc_vacancies_rec" rx="0" ry="0" x="0" y="0" width="630" height="70">
			</rect>
		</svg>
	</div>
</div>
    </div>
  )
}

export default RecruiterVacancyTopBar
