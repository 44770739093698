import React, { useState, useEffect, useContext } from "react";
import './Styles/QuickRegisterPopUp.css';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import CryptoJS from 'crypto-js';

const QuickRegisterPopUp = (props) => {

    const [openQRPopUp,setOpenQRPopUp] = useState(false);
    const [name,setName] = useState("");
    const [email,setEmail]= useState("");
    const [mobile,setMobile] = useState("");
    const [password,setPassword] = useState("");
    const [toggle,setToggle] = useState(false);

    useEffect(()=>{
if(props.openQR){
    setOpenQRPopUp(props.openQR)
}

},[props.openQR])


      
async function encrypt(plainText) {
  return await CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
}

    async function saveData() {
      
        // Get form data
       
        var option = "candidate";
        if (name == "" || email == "" || mobile == "" || password == "") {
          props.showAlert("Please fill all the details","error")
                  return
              }
        
      
        // Send data to server
        const apiurl = "https://jobsapis.bizibees.in/website/selfregister";
        const apiParams={
          method: "POST",
          headers: {
                      name: name,
                      email: email,
                      mobile: mobile,
                      password:  encodeURIComponent(await encrypt(password)),
                      usertype:option
          }
        };
        
        fetch(apiurl, apiParams)
              .then((res) => (res.status === 200 ? res.json() : res.text()))
              .then((repos) => {
                props.showAlert((repos.data || repos.message),"success")
                reset_details()
              
              })
              .catch((err) => alert("error",err));
      }


      function closeDiv() {
        var myDiv = document.getElementById("pop_up");
        myDiv.style.display = "none";
        var overlay = document.getElementById("home"); 
      overlay.classList.remove("show");
      myDiv.classList.remove("show");
      overlay.classList.remove("overlay")
      // document.body.classList.remove("blur");
      }

      const reset_details = ()=>{
        setName("")
        setEmail("")
        setMobile("")
        setPassword("")
      }

    
    const handleClose =()=>{
        setOpenQRPopUp(false)
        props.closeQR()
    }

    const login = async()=> {
      if ( email == "" ||  password == "") {
          alert("Please enter the credentials")
          return
        }
        let emailString=JSON.stringify(email);
        let passwordString=JSON.stringify(password);
         const secret="Bizi Bees";
         const encEmail=await CryptoJS.AES.encrypt(emailString,secret).toString();
         const encPassword=await CryptoJS.AES.encrypt(passwordString,secret).toString();
    
        window.location.href = `http://jobs.bizibees.in?email=${encodeURIComponent(encEmail)}&password=${encodeURIComponent(encPassword)}`;
      }


  return (
    <div>
  <Modal
        open={openQRPopUp}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
      
    <div id="pop_up" style={{display:"block"}} class="show">
    <img id="background_qr_pic" src="QRbackgroundpic.png" srcset="QRbackgroundpic.png 1x" alt="reload"/>
      
   {!toggle ?<div class="fadeIn toggleable-div " id="Group_Qr_details">
    
      <input placeholder="Name" class="qr_name_" name="name" id="name" value={name} onChange={(e)=>setName(e.target.value)}/>
       
      <input placeholder="Email" class="qr_email_" name="email" id="email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
      
      <input placeholder="Phone Number" type="number" class="phone_number_qr_" id="phone_number_qr_" name="phone" value={mobile} onChange={(e)=>setMobile(e.target.value)}/>
      
      <input placeholder="Set Password" class="qr_set_password_" name="password" id="password" type="password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
       
    </div>:""}


   {toggle ? <div class="fadeIn_popup_2 toggleable-div" id="Group_login_qr">

      <input placeholder="Email / Phone Number" class="qr_email_popup_2" id="login_email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
       
      <input placeholder="Password" type="password" class="qr_password_popup_2" id="login_password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
      
      
      <div id="forgot_Password_qr_popup_2">
        <span><a href="https://jobs.bizibees.in/forgotpassword">Forgot Password</a></span>
      </div>
    </div>:""}

   
    <img id="close" src="close_Qr.png" srcset="close_Qr.png 1x" onClick={()=>handleClose()} alt=""/>
      
    <div class="fadeInDown" id="Group_top_menu_qr">
      <div id="I_am_Registered_qr">
        <span>I am Registered</span>
      </div>
      <div id="Create_a_Login_qr">
        <span>Create a Login</span>
      </div>
    </div>

    {!toggle ? <button type="button" onClick={()=>saveData()} class="qr_lbl_ql_btn" id="register-button">
      Quick Register
    </button>:""}
    
    {toggle ?  <button type="button" onClick={()=>login()} class="qr_lbl_ql_btn " id="login-button">
       Quick Login
    </button>:""}

    <div class="fadeInDown container_qr_">
    <label class="switch" for="toggle-checkbox"><input type="checkbox" id="toggle-checkbox" checked={toggle}  onChange={(e)=>setToggle(e.target.checked)}/><div></div>
    </label>
    </div>
  </div>
</Modal>
    </div>
  )
}

export default QuickRegisterPopUp
