import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import "./EmployeeDashboardMob.css"
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { UserContext } from './UserContext';
import { color } from '@mui/system';
import { Button} from '@mui/material';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import  {TextField,Container,Autocomplete} from '@mui/material';
import OnlinePredictionSharpIcon from '@mui/icons-material/OnlinePredictionSharp';
import BackupIcon from '@mui/icons-material/Backup';
import Timer from './Timer';
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import SearchIcon from '@mui/icons-material/Search';
import ApplicantPopup from './ApplicantPopup';
import { width } from '@material-ui/system';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Peer from 'simple-peer';
import { parse, set } from 'date-fns';
import { clear } from '@testing-library/user-event/dist/clear';
import {io} from 'socket.io-client';
import CallIcon from '@mui/icons-material/Call';
import Switch from '@mui/material/Switch';
import FilterInterviewsMob from './FilterInterviewsMob';
import EmployeeChats from './EmployeeChats';
import { SocketContext } from '../SocketContext';

const stopModalStyle = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const opentimer = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 500,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

const Login = {
  position: 'relative',
  margin:"auto",
  height: "1000px",
  width:"800px",

  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
};



const EmployeeDashboardMob = (props) => {
  
// const ioSocket = io(process.env.REACT_APP_API_URL);

const {remoteVideo,getHrSocketId,leaveCall} = useContext(SocketContext);

// })  

    //useStates
    const [rowData,setRowData]=useState([]);
    const [progress, setProgress] = React.useState(0);
    const [fromDate, setFromDate] = useState(new Date().toISOString().substring(0,10));
    const [toDate, setToDate] = useState(new Date().toISOString().substring(0,10));
    const [ loadTime, setLoadTime] = useState(new Date());
    const [showOnlyHR,setShowOnlyHR]=useState(true);
    const [selectedRow,setSelectedRow] = useState();
    const { user, setUser} = useContext(UserContext);
    const [searchData, setSearchData] = useState("");
    const [openShedule, setOpenScedule ]= React.useState();
    const [openApplicant,setOpenApplicant] = useState();
    const [open,setOpen] = useState(false);
    const [data ,setData] = useState("mobile");
    const isCallingActive = useRef(false);
    const [stopComments,setStopComments] = useState("");
    const [openStopModel,setOpenStopModel] = useState(false);
    const [userStatusId,setUserStatusId]= useState("");
    const [result,setResult] = useState([]);
    const [openLoginHours, setLoginHours] = React.useState(false);
    const [loginDetails,setLoginDetails] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const alertStart = useRef(new Date());
    const [empName,setEmpName] = useState("")
    const [empCallingTime,setCallingTime] = useState("")
    const [room,setRoom] = useState("")
    const [currentMessage,setCurrentMessage] = useState("")
    const [callFilterText,setCallFilterText] = useState("Graduate");
    const [filterCallsByEducation,setFilterCallsByEducation] = useState(false);
    const [messageList,setMessageList] = useState([]);
    const longCallMsg = "I am on Call from long time!!";
    const longTimeIdleMsg = "I am idle since long time!";
    const [openChatsModal,setOpenChatsModal] = useState(false);
    const [settingFilter,setSettingFilter] = useState(false);
    const [chattingView,setChattingView] = useState(false);
    //const [stream,setStream] = useState(null);
    const [camAllowed,setCamAllowed] = useState(false);
    const [micAllowed,setMicAllowed] = useState(false);
    const roomIdRef = useRef("");  
    const audio = useRef();  
    const [activeAdmin,setActiveAdmin] = useState(false);


 
  //useEffects
  useEffect(() => {
   
  //  interviewStatus()
    LoadCurrentData()
   // getUsers()
  
    clearInterval()
    setInterval(() =>{ 
    LoadCurrentData();
    if(roomIdRef.current && roomIdRef.current!=="")
    {     
      // ioSocket.emit("join_audio_room",roomIdRef.current)
    }
    },15000);
    if(openStopModel !== true)
    {
    setStopComments("")
    }
if(isCallingActive.current === true)
{
  navigator.mediaDevices.getUserMedia ({audio:true})
 .then((stm)=>{
  
alertStart.current = new Date()
// ioSocket.emit("create_audio_room",user._id)
setTimeout(()=>checkIdle(),300000);
props.showAlert("Calling Started!!","success")
 })
 .catch(err=>{
  alert("Your microphone is not accessible!")
  setStopComments("Stopped since microphone is not accessible!")
  // ioSocket.emit("delete_audio_room", user._id)
  stopCalling(user)
 })
}
// else if(isCallingActive.current === false){
//   ioSocket.emit("delete_audio_room", user._id)
// }
setIsLoading(false)
}, [isCallingActive.current])

useEffect(()=>{

result.map((res)=> {
  if(res.user_id===user._id){
    isCallingActive.current = Boolean(res.isCallingActive)  
  
  setUserStatusId(res.status_id)
}
}
  )

},[result])


//Socket UseEffect

// useEffect (()=>{
  
//   if(user.accesstype==="5"){
 
//   ioSocket.on("init_message",(data)=>{
//   // console.log(data)
//   setMessageList((list)=>data)
//   const element = document.getElementById("chatBody");
//     element.scrollTop = element.scrollHeight;
// })

// ioSocket.on("recieve_message",(data)=>{
//   // console.log(data)
//   setMessageList((list)=>[...list,data])
//   const element = document.getElementById("chatBody");
//     element.scrollTop = element.scrollHeight;
// })   
// }

// if(user.accesstype==="5")
// {
//   ioSocket.on('audiostream', function(arrayBuffer) {
//   //console.log(arrayBuffer)
//   if(roomIdRef.current && roomIdRef.current!=="")
//   {
//     var blob = new Blob([arrayBuffer], { 'type' : 'audio/ogg; codecs=opus' });
//     audio.current = document.createElement('audio');
//     audio.current.src = window.URL.createObjectURL(blob);
//     audio.current.play();
//   }
//   else{
//     audio.current.stop();
//   }
//   });
// }
//  ioSocket.on("audio",(data)=>{
//   navigator.mediaDevices.getUserMedia ({audio:true})
//   .then((currentStream)=>{
//   //stream.current = currentStream.getAudioTracks().forEach(track => track.enabled = true);
//   var mediaRecorder = new MediaRecorder(currentStream);
//   mediaRecorder.onstart = function(e) {
//       this.chunks = [];
//   };
//   mediaRecorder.ondataavailable = function(e) {
//       this.chunks.push(e.data);
//       // if(isCallingActive.current === true)
//       // {
//       // ioSocket.emit("stream",{room:user._id,audio:e.data});
//       // }
//       // else
//       // {
//       //   mediaRecorder.stop();
//       // }
//   };
//   mediaRecorder.onstop = function(e) {
//     var blob = new Blob(this.chunks, { 'type' : 'audio/ogg; codecs=opus' });
//   //  console.log(blob)
//   //  ioSocket.emit("stream",{room:user._id,audio:blob});
//    if(isCallingActive.current === true)
//    {
//    //mediaRecorder.start();
//   //  setTimeout(function() {
//   //   mediaRecorder.stop()
//   //  }, 1000);
//     } 
//   };

// // Start recording
// //mediaRecorder.start();
// // setTimeout(function() {
// //  mediaRecorder.stop()
// // }, 1000);
// }).catch(
// err=>console.log(err)
// );

// });
// },[ioSocket])



useEffect (()=>{

  

  navigator.permissions
  .query({ name: "camera" })
  .then((permissionStatus) => {
    setCamAllowed(permissionStatus.state==="granted");
    permissionStatus.onchange = () => {
      setCamAllowed(permissionStatus.state==="granted");
    };
  });
  
  navigator.permissions
  .query({ name: "microphone" })
  .then((permissionStatus) => {
    setMicAllowed(permissionStatus.state==="granted");
    permissionStatus.onchange = () => {
      setMicAllowed(permissionStatus.state==="granted");
    };
  });
  
  // joinRoom()

  },[navigator.permissions])

  // useEffect (()=>{
 
  //   if(open === true)
  //   {  
  //     ioSocket.emit("send_message",{
  //     userName : user.name,
  //     message : openShedule? longCallMsg:longTimeIdleMsg,
  //     time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
  //   });
  // }
  //   },[open])

//sending messages from socket 

// const sendMessage = async ()=>{
 
//   if(currentMessage!=="")
//   { 
//     const messageData = {
//       userName : user.name,
//       message : currentMessage,
//       time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
//     };

//     ioSocket.emit("send_message",messageData);
//      //setMessageList((list)=>[...list,messageData]);
//      setCurrentMessage("");
//   }
// }


//Join Room from socket 

// const joinRoom = ()=>{

//   ioSocket.emit("join_room",room)

// }


//get Chats status
const getAllChats = () =>{
      
  let apiURL=process.env.REACT_APP_API_URL + "/getchats"; 
  let apiParams = {
      method:"GET",
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        setMessageList((list)=>[...list,repos.data])
      }else props.showAlert(""+(repos.message || repos),"error"); 
  }) 
  .catch((err) =>props.showAlert((''+err),"error"));
}


  //Variables
  var checkIdle=()=>
  {
     //let date = ("Wed Nov 02 2022 18:57:25 GMT+0530 (India Standard Time)")
    
   if( (isCallingActive.current === true) && ((new Date() - alertStart.current) > 300000))
  {
    setOpen(true);
    setTimeout(()=>checkIdle(),30000)  
  }
  }
     

const addApplicants = () =>{
  setOpenApplicant(true)
}


const SearchCandidate = () =>{
  let apiURL="";
//   if(candidate === "new" && user.accesstype == 5)
// {
//    apiURL = process.env.REACT_APP_API_URL + "/interview/newverification/"+hr
// }
// else if(candidate === "new" && user.accesstype != 5)
// {
//    apiURL = process.env.REACT_APP_API_URL + "/interview/newinterview/"+hr
// }
// else
// {

     apiURL = process.env.REACT_APP_API_URL + "/interview/getinterviewbysearch/"+searchData;
// }
    let apiParams = {
      method: "GET",
      headers:{
        searchBy:data,
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             if (repos.data.length == 1){        
                const rowData = ([repos.data[0]])?.map((interview) => {
                let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
             
              //alert(JSON.stringify(interview))
                return{
              id: interview?._id,  //applicant id
              applicantid:interview?.applicantId, 
              jobid:interview?.jobId,  //interview id
              name: interview?.name,
              mobile: interview?.mobile,
              jobtitle:interview?.jobtitle,
              companyname:interview?.companyname,
              interviewername:interview?.interviewername,
              timespent:interview?.timeSpent,
              hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
              interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
              interviewtime:IntvwTime,
              interviewlocation:interview?.location,
              rounds:interview?.rounds,
              reference:interview?.reference,
              status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
              feedback:interview?.feedback,
              bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
              bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
              languages:interview?.languages,
              englishrating:interview?.englishRating,
              communicationrating:interview?.communicationRating,
              createdbyname:interview?.createdbyname,
              createdbyid:interview?.createdbyId,
                };
              });
              
               setSelectedRow(rowData[0])

               setOpenScedule(true)
            }else{
              window.location.href= "/interviewdetails?selectedCell=" +([data,user._id,searchData]);
            }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));

}

const showCandidate=(candidate,hr)=>{
  //setTimeout(alert("You are taking too long for the call!",30000))
  
  var filters =  localStorage.getItem("filters")
  let apiURL="";
if(candidate === "new" && user.accesstype == 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newverification/"+hr
}
else if(filterCallsByEducation===true && candidate === "new" && user.accesstype != 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr+"/"+callFilterText;
}
else if(candidate === "new" && user.accesstype != 5 )
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr
}
else
{
     apiURL = process.env.REACT_APP_API_URL + "/interview/getinterview/"+candidate;
}
    let apiParams = {
      method: "GET",
      headers:{
        filters:filters,
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              //  console.log(repos.data)
               const rowData = ([repos.data])?.map((interview) => {
                let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
              
              //alert(JSON.stringify(interview))
                return{
              id: interview?._id,  //applicant id
              applicantid:interview?.applicantId, 
              jobid:interview?.jobId,  //interview id
              name: interview?.name,
              mobile: interview?.mobile,
              jobtitle:interview?.jobtitle,
              companyname:interview?.companyname,
              interviewername:interview?.interviewername,
              timespent:interview?.timeSpent,
              hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
              interviewdate:interview?.interviewdate && (new Date(interview?.interviewdate)),
              interviewtime:IntvwTime,
              interviewlocation:interview?.location,
              rounds:interview?.rounds,
              reference:interview?.reference,
              status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
              feedback:interview?.feedback,
              bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
              bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
              languages:interview?.languages,
              englishrating:interview?.englishRating,
              communicationrating:interview?.communicationRating,
              createdbyname:interview?.createdbyname,
              createdbyid:interview?.createdbyId,
                };
              });
              
               setSelectedRow(rowData[0])

               setOpenScedule(true)
              }else props.showAlert("No data available for selected filters!","error");  
            }) 
        .catch((err) => props.showAlert((''+err),"error"));
}

const msgCandidate=(candidate,hr)=>{
  //setTimeout(alert("You are taking too long for the call!",30000))
  
  var filters =  localStorage.getItem("filters")
  let apiURL="";
if(candidate === "new" && user.accesstype == 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newverification/"+hr
}
else if(filterCallsByEducation===true && candidate === "new" && user.accesstype != 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr+"/"+callFilterText;
}
else if(candidate === "new" && user.accesstype != 5 )
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr
}
else
{
     apiURL = process.env.REACT_APP_API_URL + "/interview/getinterview/"+candidate;
}
    let apiParams = {
      method: "GET",
      headers:{
        filters:filters,
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              //  console.log(repos.data)
               const rowData = ([repos.data])?.map((interview) => {
                let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
              
              //alert(JSON.stringify(interview))
                return{
              id: interview?._id,  //applicant id
              applicantid:interview?.applicantId, 
              jobid:interview?.jobId,  //interview id
              name: interview?.name,
              mobile: interview?.mobile,
              jobtitle:interview?.jobtitle,
              companyname:interview?.companyname,
              interviewername:interview?.interviewername,
              timespent:interview?.timeSpent,
              hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
              interviewdate:interview?.interviewdate && (new Date(interview?.interviewdate)),
              interviewtime:IntvwTime,
              interviewlocation:interview?.location,
              rounds:interview?.rounds,
              reference:interview?.reference,
              status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
              feedback:interview?.feedback,
              bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
              bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
              languages:interview?.languages,
              englishrating:interview?.englishRating,
              communicationrating:interview?.communicationRating,
              createdbyname:interview?.createdbyname,
              createdbyid:interview?.createdbyId,
                };
              });
              
              let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+rowData[0].mobile.trim().replace(' ','').substring(rowData[0].mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+rowData[0].name+",%0a%0aI am contacting you for a job vacancy (Work from Office). Please let me know the best time to call you.%0a%0aMy name is "+user.name+", I am from Bizi Bees Outsourcing Private Limited. You can call me on "+user.phone+" as well at your convenient time to discuss about the vacancy.%0a%0aPlease follow us on our LinkedIn page url below for latest updates.%0a%0ahttps://www.linkedin.com/company/bizi-bees-outsourcing";
              const newWindow = window.open(apiURL, '_blank')
              //  setSelectedRow(rowData[0])
              UpdateMessagedStatus(rowData[0].id)
              //  setOpenScedule(true)
            }else props.showAlert("No data available for selected filters!","error");  
          }) 
        .catch((err) => props.showAlert((''+err),"error"));

}
    //functions
    // const getUsers = () =>{
    //     let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
    //     let apiParams = {
    //       method: "GET",
    //     };
    //     fetch(apiURL, apiParams)
    //         .then((res)=> (res.status===200 ? res.json() : res.text()))
    //         .then((repos)=> {
    //             if (repos.data) {
                  
    //                 setUsers(repos.data)
    //             }else props.showAlert(""+(repos.message || repos),"error");  
    //         }) 
    //         .catch((err) => props.showAlert((''+err),"error"));
    //     }

            function max_of_three(x, y, z,a,b,c,d,e) 
            {
                let max_val;
                if (x.length > y.length)
            {
                max_val = x;
            } 
            else
            {
                max_val = y;
            }
            if (z.length > max_val.length) 
            {
                max_val = z;
            }
            if (a.length > max_val.length) 
            {
                max_val = a;
            }
            if (b.length > max_val.length) 
            {
                max_val = b;
            }
            if (c.length > max_val.length) 
            {
                max_val = c;
            }
             if (d.length > max_val.length) 
            {
                max_val = d;
             }
             if (e.length > max_val.length) 
            {
                max_val = e;
             }
            return max_val;
            }

    //View Data 
    const viewData = (row) =>{

    window.location.href= "/interviewdetails?selectedCell=" + row;
    }
  

    function LinearProgressWithLabel(props) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary" >{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
      LinearProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate and buffer variants.
         * Value between 0 and 100.
         */
        value: PropTypes.number.isRequired,
      };
   



      const UpdateMessagedStatus = (intwID)=>{
        let apiURL=process.env.REACT_APP_API_URL + "/interview/messagedStatus/"+intwID; 
        let apiParams = {
            method:"POST",
            headers:{
              updatedbyid:user._id,
              updatedbyname:user.name,
              authtoken: localStorage.getItem("loggedintoken")
            }
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
       
            }else 
            props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }

//Open alert popup
      const handleClose = () =>{
      
        setOpen(false);
        
      }

//get interview status
      const getLoginDetails = (id) =>{
      
            let apiURL=process.env.REACT_APP_API_URL + "/usercallingstatus/logindetails/"+id; 
            let apiParams = {
                method:"GET",
                headers:{
                  authtoken: localStorage.getItem("loggedintoken")
                }
            };
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  // console.log(repos.data);
                  setLoginDetails(repos.data);
                }else props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
      }

      
      
     //Search Data By  date
      const LoadPreviousData = () =>{
        let apiParams;
          apiParams = {
            method: "GET",
            headers:{
              fromdate:fromDate,
              todate:toDate,
              authtoken: localStorage.getItem("loggedintoken")
            }
          };
        
        let apiURL = process.env.REACT_APP_API_URL + "/dashboard/searchbydate/"
       
       fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  console.clear()
                  //  console.log(repos.data)
                  var passed = repos.data.passed;
                    var failed = repos.data.failed;
                    var messaged = repos.data.messaged;
                    var pending = repos.data.pending;
                    var applied = repos.data.applied;
                    var verified = repos.data.verified;
                    var verificationFailed = repos.data.verificationFailed;
                    var verificationPending = repos.data.verificationPending;
                    var verificationAvailable = repos.data.verificationAvailable;
                    var currentCandidate = repos.data.currentCandidate;
                    var idleTimeRec = repos.data.idleTime;
                    let namesCol = max_of_three(passed,failed,pending,verified,verificationFailed,verificationPending,currentCandidate,idleTimeRec)
                   
                    setRowData(namesCol.map((name,id)=>{
                    let idleTime = Math.abs(new Date().getTime() - new Date((idleTimeRec.filter(rec=>rec._id===name._id)[0]||{closedOn:new Date().toISOString()}).closedOn).getTime())/1000;
                    let openTime =(Math.abs(new Date().getTime() - new Date((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{openedOn:new Date().toISOString()}).openedOn).getTime())/1000) + parseInt((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{timeSpent:0}).timeSpent);
                    openTime=parseInt(openTime)>0?parseInt(openTime):parseInt(idleTime);
                    return {id,
                        name:name._id,
                        verified:(verified.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        verificationFailed:(verificationFailed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        verificationPending:(verificationAvailable.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(verificationPending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        passed:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        messaged:(messaged.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        failed:(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        called:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count+(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        pending:(applied.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(pending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        currentCandidate:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""}).name,
                        interviewid:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""})._id,
                        //openTime:openTime==0?"":String(parseInt(openTime/60/60)).padStart(2, '0')+":"+String(parseInt(openTime/60)).padStart(2, '0')+":"+String(parseInt(openTime%60)).padStart(2, '0'),
                        //idleTime:parseInt(idleTime),
                        openTime:openTime,
                      }})
                      //.sort((a,b)=>a.verified-b.verified)
                      )
                      //console.log(rowData)
                    setLoadTime(new Date())
                }else props.showAlert(repos.message || repos,"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));   
        }

        const LoadCurrentData = () =>{
          
          if(fromDate === new Date().toISOString().substring(0,10) && toDate===new Date().toISOString().substring(0,10))
{
          let apiParams
            apiParams = {
              method: "GET",
              headers:{
                fromdate:new Date(),
                todate:new Date(),
                authtoken: localStorage.getItem("loggedintoken")
              }
            };
          
          let apiURL = process.env.REACT_APP_API_URL + "/dashboard/searchbydate/"
         
         fetch(apiURL, apiParams)
              .then((res)=> (res.status===200 ? res.json() : res.text()))
              .then(async (repos)=> {
                  if (repos.data) {
                  
                    setResult(repos.data.userStatus)
                    
                      var passed = repos.data.passed;
                      var messaged = repos.data.messaged;
                      var failed = repos.data.failed;
                      var pending = repos.data.pending;
                      var applied = repos.data.applied;
                   
                      var verificationAvailable = repos.data.verificationAvailable;
                      var verified = repos.data.verified;
                      var verificationFailed = repos.data.verificationFailed;
                      var verificationPending = repos.data.verificationPending;
                      var currentCandidate = repos.data.currentCandidate;
                      var userStatus = repos.data.userStatus;
                      var loggedTime = repos.data.loggedinTime;
                      var calledTime = repos.data.callingTime;
                      var idleTimeRec = repos.data.idleTime;
                      var longCalls = repos.data.longCalls;
                      //let namesCol = max_of_three(passed,failed,pending,verified,verificationFailed,verificationPending,currentCandidate,idleTimeRec)
                      let namesCol = repos.data.users;
                      let newData = namesCol.map((name,id)=>{
                      let idleTime = Math.abs(new Date(new Date().toISOString()).getTime() - new Date((idleTimeRec.filter(rec=>rec._id===name._id)[0]||{closedOn:new Date().toISOString()}).closedOn).getTime())/1000;
                      idleTime = parseInt(idleTime);
                      let openTime =(Math.abs(new Date(new Date().toISOString()).getTime() - new Date((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{openedOn:new Date().toISOString()}).openedOn).getTime())/1000) + parseInt(((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{timeSpent:0}).timeSpent));
                      openTime =  parseInt(openTime)>0 ?parseInt(openTime): parseInt(idleTime)
                      
                      let logTimeValue =(loggedTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;
                      //console.log(loggedTime)
                      let agentId = (loggedTime.filter(rec=>rec._id.name===name._id)[0]||{_id:{id:""}})._id.id;
                      let newLogTimeValue = parseInt(logTimeValue * 0.65);
                      //console.log(parseInt(newLogTimeValue))

                      let calTimeValue = (calledTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;
                      calTimeValue = parseInt(calTimeValue)
                      //console.log(calTimeValue)  

                      let callVol = ((passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count+(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count);
                      let newCalVol = parseInt(callVol * 0.15)
                     

                      let longCallVol = (longCalls||[].filter(rec=>rec.name===name._id)[0]||{name:""}).count;
                       longCallVol = parseInt(longCallVol)
                      
                      //Loggedin Time
                      let time = (loggedTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;

                      let num = (Math.round(time)*100)/100;
                      let hours = (num/60);
                      let rhours = Math.floor(num/60);
                      let minutes = ( hours-rhours)*60;
                      let rminutes = Math.round(minutes);
                     
                      //Called Time
                      let cTime = (calledTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;
                      let cNum = (Math.round(cTime)*100)/100;
                      let cHours = (cNum/60);
                      let cRhours = Math.floor(cNum/60);
                      let cMinutes = ( cHours-cRhours)*60;
                      let cRminutes = Math.round(cMinutes);
                     
                      //Idle Time
                      let iTime = parseInt(time) - parseInt(cTime);
                      //console.log(name,parseInt(time))
                      //console.log(name,parseInt(cTime))

                     // console.log(name,iTime)
                      let iNum = (Math.round(iTime)*100)/100;
                      let iHours = (iNum/60);
                      let iRhours = Math.floor(iHours/60);
                      let iMinutes = ( iHours-iRhours)*60;
                      let iRminutes = Math.round(iMinutes);
                     
                      let loginid =(loggedTime.filter((req)=>req._id.name ===name._id)).map(r=>r._id.id)[0];
// let idleAlert = (parseInt(repos.data.loggedinTime) * 0.7)>parseInt(repos.data.callingTime);
// let longCallsAlert = ((parseInt(repos.data.passed)+parseInt(repos.data.failed)) * 0.7)>parseInt(repos.data.longCalls);

                      return {id,

                          name:name._id,
                          verified:(verified.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          verificationFailed:(verificationFailed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          verificationPending:(verificationAvailable.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(verificationPending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          passed:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          messaged:(messaged.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          failed:(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          called:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count+(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          pending:(applied.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(pending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          currentCandidate:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""}).name,
                          userStatus:(userStatus.filter(rec=>rec.name===name._id)[0]||{name:""}).message,
                          longCalls:(longCalls||[].filter(rec=>rec.name===name._id)[0]||{name:""}).count,
                          idleAlert : (newLogTimeValue > calTimeValue),
                          longCallsAlert :(newCalVol < longCallVol),
                          //calledTime:(calledTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime,
                          loggedTime:("00" +(rhours?rhours:0)).slice(-2)+":"+("00" +(rminutes?rminutes:0)).slice(-2)+":00",
                          calledTime:("00" +(cRhours?cRhours:0)).slice(-2)+":"+("00" +(cRminutes?cRminutes:0)).slice(-2)+":00",
                          hrIdleTime:("00" +(iTime>60?parseInt(iTime/60):0)).slice(-2)+":"+("00" +(iTime>=1?parseInt(iTime-(iTime>60?parseInt(iTime/60):0)*60):0)).slice(-2)+":00",//+iTime>0?(iTime-(iTime>60?iTime/60:0)*60):0,
                          loginId:loginid,
                          agentId:agentId,
                          
                          isCallingActive:Boolean((userStatus.filter(rec=>rec.name===name._id)[0]||{name:""}).isCallingActive),
                          interviewid:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""})._id,

                          //openTime:openTime==0?"":String(parseInt(openTime/60/60)).padStart(2, '0')+":"+String(parseInt(openTime/60)).padStart(2, '0')+":"+String(parseInt(openTime%60)).padStart(2, '0'),
                           //idleTime:parseInt(idleTime),
                          idleTime:idleTime, 
                          openTime:openTime,
                          isSpeakerOn:(agentId === roomIdRef.current),
    
                         }})
                         
                        //.sort((a,b)=>a.verified-b.verified);
                    
                        setRowData(newData)
                       
                        //console.log(rowData.longCallsAlert)
                        //console.log(rowData.filter((x)=>x.name===user.name?((x.openTime/60)%60)>60?alert("hi"):"":""))
                      setLoadTime(new Date())
                  }else props.showAlert(repos.message || repos,"error");  
              }) 
              .catch((err) => props.showAlert((''+err),"error"));         
          }}

        //Live Button  
        const Refresh = async() =>{
          setFromDate(new Date().toISOString().substring(0,10))
          setToDate(new Date().toISOString().substring(0,10))
          
          LoadCurrentData()

        }
   
        function arrayBufferToBase64(buffer) {
          var binary = "";
          var bytes = [].slice.call(new Uint8Array(buffer));
          bytes.forEach((b) => (binary += String.fromCharCode(b)));
          var base64Flag = "data:image/jpeg;base64,";
          return base64Flag + window.btoa(binary);
        }
      
        const UpdateCallingStatus = () =>{
          if(isLoading)
          {
            return;
          }
              setIsLoading(true);
            // setIsCallingActive(!isCallingActive)
             if(isCallingActive.current !== true){
              startCalling()
            }else{
              stopCalling(user)
              
            }
          }
  
          const startCalling=()=>{
            let apiURL=process.env.REACT_APP_API_URL + "/usercallingstatus/userstarttime";
              let apiParams = {
                  method:"POST",  
                  headers:{
                    iscallingactive:true,
                    userid:user._id,
                    username:user.name,
                    authtoken: localStorage.getItem("loggedintoken")
                    
                  },
                 
              };
              fetch(apiURL, apiParams)
              .then((res)=> (res.status===200 ? res.json() : res.text()))
              .then((repos)=> {
                  if (repos.data) {
                setIsLoading(false);
                  setUserStatusId(repos.data._id)
                   isCallingActive.current=true;
                  //joinAudioRoom()
                  
                  }else {props.showAlert(""+(repos.message || repos),"error");setIsLoading(false);}
              }) 
              .catch((err) => {props.showAlert((''+err),"error");setIsLoading(false);});
              
          }
  
          const stopCalling = (activeUser)=>{
            if(activeUser){
         
            setIsLoading(true);
            setOpenStopModel(false)
            isCallingActive.current=false;
            let apiURL=process.env.REACT_APP_API_URL + "/usercallingstatus/userstoptime/"+userStatusId;
            let apiParams = {
                method:"POST",
                headers:{
                  iscallingactive:false,
                  userid:activeUser._id,
                  username:activeUser.name,
                  stopcomments:encodeURIComponent(stopComments),
                  authtoken: localStorage.getItem("loggedintoken")
                },
               
            };
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                 //socket.emit("exit_audio_room",user._id)
               
    props.showAlert("Calling is Stopped!!","success")
            setIsLoading(false);
                
                }else 
                {props.showAlert(""+(repos.message || repos),"error");setIsLoading(false);}
            }) 
            .catch((err) => {props.showAlert((''+err),"error");setIsLoading(false);});
            
                
          }
        }
          const setCloseStopModel = ()=>{
            setOpenStopModel(false);
          }
            
          const closeStopModel = () => setOpenStopModel(false);
  
          const OpenStopModal = ()=>{
            setOpenStopModel(true);
          }

        const handleCloseLoginHours = () => setLoginHours(false)
    
        const OpenLoginhoursGrid = (id,name,time)=>{
          
          setEmpName(name)
          setCallingTime(time)
          setLoginHours(true)
          getLoginDetails(id)
        }


        const selectFile = (e)=>{
          //setCurrentMessage(e.target.files[0].name);
            var file = e.target.files[0]; //the file
            var reader = new FileReader(); //this for convert to Base64
            reader.readAsDataURL(e.target.files[0]); //start conversion...
            reader.onload = function (e) {
              //.. once finished..
              var rawLog = reader.result.split(",")[1]; //extract only thee file data part
              var dataSend = {
                dataReq: { data: rawLog, name:file.name, type: file.type },
                fname: "uploadFilesToGoogleDrive",
              }; //preapre info to send to API
              fetch(
                process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
                { method: "POST", body: JSON.stringify(dataSend) }
              ) //send to Api
                .then((res) => res.json())
                .then((data) => {
                  // console.log(data.url)
                  // ioSocket.emit("send_message",{
                  //   userName : user.name,
                  //   message : "href:"+file.name+"@&@"+data.url+"</a>",
                  //   time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
                  // });
                  props.showAlert("Resume Uploaded Successfully", "success");
                })
                .catch((e) => props.showAlert((e),"error")); // Or Error 
            };
          };

//Send Whatsapp to Candidate
const sendWhatsappToCandidate = ()=>{
  // rowData.filter(x=> users.filter(user=> user.name === x.name)[0]
}
         
  return (
    <>
    {camAllowed === true && micAllowed === true?
  <div style={{width:"100%",height:"100%"}}> 

{settingFilter===false ?<div id="Empoyee_Dashi_Mobemployee_dashboard">
	<div id="Empoyee_Dashi_MobGroup_8" onClick={()=>setChattingView(true)}>
		<svg class="Empoyee_Dashi_Mobchat_btn">
			<ellipse id="Empoyee_Dashi_Mobchat_btn" rx="20" ry="20" cx="20" cy="20">
			</ellipse>
		</svg>
		<img id="Empoyee_Dashi_Mobchat_lbl" src="chat_lbl.png" srcset="chat_lbl.png 1x" />
			
	</div>
	<div id="Empoyee_Dashi_MobGroup_11">
		<div id="Empoyee_Dashi_MobWelcome_1">
			<span>Welcome</span>
		</div>
		<div id="Empoyee_Dashi_Mobuser_name_lbl">
			<span>{user.name}</span>
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_17">
		<svg class="Empoyee_Dashi_Mobcall_time_outer_line">
			<rect id="Empoyee_Dashi_Mobcall_time_outer_line" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<div id="Empoyee_Dashi_MobCallIdle_Time_lbl">
			<span>Call/Idle Time</span>
		</div>
		<img id="Empoyee_Dashi_Mobtime-call_img" src="time-call_img.png" srcset="time-call_img.png 1x" />
			
		<div id="Empoyee_Dashi_MobGroup_22">
			<svg class="Empoyee_Dashi_Mobcall_time_val">
				<rect id="Empoyee_Dashi_Mobcall_time_val" rx="18.5" ry="18.5" x="0" y="0" width="117" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn_">
				<span>{
  rowData && rowData.filter(x=>x.name === user.name)[0]?
  <div style={{backgroundColor:rowData.filter(x=>x.name === user.name)[0].openTime>300?"red":"white",color:rowData.filter(x=>x.name === user.name)[0].openTime>300?"white":rowData.filter(x=>x.name === user.name)[0].openTime>210?"red":"green",}}>
    {String(parseInt(rowData.filter(x=>x.name === user.name)[0].openTime/60/60)).padStart(2, '0')}:{String(parseInt((rowData.filter(x=>x.name === user.name)[0].openTime/60)%60)).padStart(2, '0')}:{String(rowData.filter(x=>x.name === user.name)[0].openTime%60).padStart(2, '0')}
    &nbsp;&nbsp;</div>:""}</span>
			</div>
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_18">
		<svg class="Empoyee_Dashi_Mobverified_outer_line">
			<rect id="Empoyee_Dashi_Mobverified_outer_line" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<div id="Empoyee_Dashi_MobVerified_lbl">
			<span>Verification Pending</span>
		</div>
		<img id="Empoyee_Dashi_Mobverified_img" src="verified_img.png" srcset="verified_img.png 1x" />
			
		<div id="Empoyee_Dashi_MobGroup_23">
			<svg class="Empoyee_Dashi_Mobverified_val">
				<rect id="Empoyee_Dashi_Mobverified_val" rx="18.5" ry="18.5" x="0" y="0" width="117" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn__">
				<span>{rowData && rowData.filter(x=>x.name === user.name)[0]?<a href={"/interviewdetails?selectedCell="+(["verificationPending" ,user._id , rowData.filter(x=>x.name === user.name)[0].name,fromDate,toDate ])}>{rowData.filter(x=>x.name === user.name)[0].verificationPending}</a>:""}</span>
			</div>
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_16">
		<svg class="Empoyee_Dashi_MobRectangle_10">
			<rect id="Empoyee_Dashi_MobRectangle_10" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<div id="Empoyee_Dashi_MobCalled">
			<span>Called</span>
		</div>
		<img id="Empoyee_Dashi_Mobcustomer-service1" src="customer-service1.png" srcset="customer-service1.png 1x" />
			
		<div id="Empoyee_Dashi_MobGroup_20">
			<svg class="Empoyee_Dashi_Mobcalled_val">
				<rect id="Empoyee_Dashi_Mobcalled_val" rx="18.5" ry="18.5" x="0" y="0" width="117" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn__bb">
				<span>{rowData && rowData.filter(x=>x.name === user.name)[0]?<a href={"/interviewdetails?selectedCell="+(["called",user._id , rowData.filter(x=>x.name === user.name)[0].name,fromDate,toDate ])}>{rowData.filter(x=>x.name === user.name)[0].called}</a>:""}</span>
			</div>
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_15">
		<svg class="Empoyee_Dashi_MobRectangle_11">
			<rect id="Empoyee_Dashi_MobRectangle_11" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<div id="Empoyee_Dashi_MobAvailable">
			<span>Available</span>
		</div>
		<img id="Empoyee_Dashi_Mobavailable" src="available.png" srcset="available.png 1x" />
			
		<div id="Empoyee_Dashi_MobGroup_21">
			<svg class="Empoyee_Dashi_Mobavailable_val">
				<rect id="Empoyee_Dashi_Mobavailable_val" rx="18.5" ry="18.5" x="0" y="0" width="117" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn__bi">
				<span>{rowData && rowData.filter(x=>x.name === user.name)[0]?rowData.filter(x=>x.name === user.name)[0].pending:""}</span>
			</div>
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_14">
		<svg class="Empoyee_Dashi_MobRectangle_8">
			<rect id="Empoyee_Dashi_MobRectangle_8" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<img id="Empoyee_Dashi_Mobfailed_img" src="failed_img.png" srcset="failed_img.png 1x" />
			
		<img id="Empoyee_Dashi_Mobpassed_img" src="passed_img.png" srcset="passed_img.png 1x" />
			
		<div id="Empoyee_Dashi_MobGroup_1">
			<svg class="Empoyee_Dashi_Mobpassed_val">
				<rect id="Empoyee_Dashi_Mobpassed_val" rx="18.5" ry="18.5" x="0" y="0" width="52" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn__bp">
				<span>{rowData && rowData.filter(x=>x.name === user.name)[0]?<a href={"/interviewdetails?selectedCell="+(["passed" ,user._id , rowData.filter(x=>x.name === user.name)[0].name,fromDate,toDate ])}>{rowData.filter(x=>x.name === user.name)[0].passed}</a>:""}</span>
			</div>
		</div>
		<div id="Empoyee_Dashi_MobGroup_2">
			<svg class="Empoyee_Dashi_Mobpassed_val_br">
				<rect id="Empoyee_Dashi_Mobpassed_val_br" rx="18.5" ry="18.5" x="0" y="0" width="52" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn__bs">
				<span>{rowData && rowData.filter(x=>x.name === user.name)[0]?<a href={"/interviewdetails?selectedCell="+(["failed",user._id  , rowData.filter(x=>x.name === user.name)[0].name,fromDate,toDate ])}>{rowData.filter(x=>x.name === user.name)[0].failed}</a>:""}</span>
			</div>
		</div>
		<div id="Empoyee_Dashi_MobHR_Screening_">
			<span>HR Screening </span>
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_13">
		<svg class="Empoyee_Dashi_MobRectangle_9">
			<rect id="Empoyee_Dashi_MobRectangle_9" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<div id="Empoyee_Dashi_MobVerification_">
			<span>Verification </span>
		</div>
		<img id="Empoyee_Dashi_Mobverification_failed_img" src="verification_failed_img.png" srcset="verification_failed_img.png 1x" />
			
		<img id="Empoyee_Dashi_Mobverification_pending_img" src="verification_pending_img.png" srcset="verification_pending_img.png 1x" />
			
		<div id="Empoyee_Dashi_MobGroup_3">
			<svg class="Empoyee_Dashi_Mobpassed_val_b">
				<rect id="Empoyee_Dashi_Mobpassed_val_b" rx="18.5" ry="18.5" x="0" y="0" width="52" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn__b">
				<span>{rowData && rowData.filter(x=>x.name === user.name)[0]?<a href={"/interviewdetails?selectedCell="+(["verified",user._id ,rowData.filter(x=>x.name === user.name)[0].name,fromDate,toDate ])}>{rowData.filter(x=>x.name === user.name)[0].verified}  </a>:""}</span>
			</div>
		</div>
		<div id="Empoyee_Dashi_MobGroup_4">
			<svg class="Empoyee_Dashi_Mobpassed_val_ca">
				<rect id="Empoyee_Dashi_Mobpassed_val_ca" rx="18.5" ry="18.5" x="0" y="0" width="52" height="37">
				</rect>
			</svg>
			<div id="Empoyee_Dashi_Mobn__ca">
				<span>{rowData && rowData.filter(x=>x.name === user.name)[0]?<a href={"/interviewdetails?selectedCell="+(["verificationFailed" ,user._id , rowData.filter(x=>x.name === user.name)[0].name,fromDate,toDate ])}>{rowData.filter(x=>x.name === user.name)[0].verificationFailed}</a>:""}</span>
			</div>
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_19">
		<svg class="Empoyee_Dashi_Mobdashboard_profile_outer_line">
			<rect id="Empoyee_Dashi_Mobdashboard_profile_outer_line" rx="20" ry="20" x="0" y="0" width="379" height="103">
			</rect>
		</svg>
		<div id="Empoyee_Dashi_MobGroup_7">


			{/* <img id="Empoyee_Dashi_Mobtelephone" src="telephone.png" srcset="telephone.png 1x" />
				
			<img id="Empoyee_Dashi_Mobwhatsapp_dashboard" src="whatsapp_dashboard.png" srcset="whatsapp_dashboard.png 1x" /> */}
				
      {isCallingActive.current === true ?<div>	{
    (rowData && rowData.filter(x=>x.name === user.name)[0] 
    ?<>
    <div >
    
   {/* {rowData.filter(x=>x.name === user.name)[0] && rowData.filter(x=>x.name === user.name)[0].currentCandidate? <img id="Empoyee_Dashi_Mobtelephone" src="Call_Disconnected.png" onClick={()=>{showCandidate(rowData.filter(x=>x.name === user.name)[0].currentCandidate?rowData.filter(x=>x.name === user.name)[0].interviewid:"new",rowData.filter(x=>x.name === user.name)[0].name)}} />:<div id="telephone_call">Message on<br/>WhatsApp</div>} */}
   {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && user.allowed.substring(3,2) ==="1" ? <img id="Empoyee_Dashi_Mobtelephone" src="Call_Connected.png" onClick={()=>{showCandidate(rowData.filter(x=>x.name === user.name)[0].currentCandidate?rowData.filter(x=>x.name === user.name)[0].interviewid:"new",rowData.filter(x=>x.name === user.name)[0].name)}} />:user.allowed.substring(1,0)==="1" && <img id="Empoyee_Dashi_Mobtelephone" src="Call_Connected.png" onClick={()=>{showCandidate(rowData.filter(x=>x.name === user.name)[0].currentCandidate?rowData.filter(x=>x.name === user.name)[0].interviewid:"new",rowData.filter(x=>x.name === user.name)[0].name)}} />}
   
     {/* <button id="Empoyee_Dashi_Mobtelephone" style={{color:"cornflowerblue",border:"2px solid cornflowerblue",padding:"2px 6px"}}  onClick={()=>{showCandidate(rowData.filter(x=>x.name === user.name)[0].currentCandidate?rowData.filter(x=>x.name === user.name)[0].interviewid:"new",rowData.filter(x=>x.name === user.name)[0].name)}}>{rowData.filter(x=>x.name === user.name)[0].currentCandidate?rowData.filter(x=>x.name === user.name)[0].currentCandidate:<CallIcon />}</button> */}
      </div>
      <div>
     {((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && (user.allowed.substring(4,3) ==="1")) ? <img id="Empoyee_Dashi_Mobwhatsapp_dashboard" src="whatsappdashboard.png" srcset="whatsappdashboard.png 1x, whatsappdashboard@2x.png 2x"  onClick={()=>{msgCandidate(rowData.filter(x=>x.name === user.name)[0].currentCandidate?rowData.filter(x=>x.name === user.name)[0].interviewid:"new",rowData.filter(x=>x.name === user.name)[0].name)}} />:user.allowed.substring(2,1)=== "1" && <div id="Empoyee_Dashi_Mobwhatsapp_dashboard">Use<br/>Mobile</div>}
     </div>
     </>:"")}
   </div>:<div className='stopComments_mob'>{stopComments?<marquee>{stopComments}</marquee>: rowData && rowData.filter(x=>x.name === user.name)[0]?rowData.filter(x=>x.name === user.name)[0].userStatus?<marquee>{rowData.filter(x=>x.name === user.name)[0].userStatus}</marquee>:"":""}</div>}



			<img id="Empoyee_Dashi_Mobsetting" src="setting.png" srcset="setting.png 1x" onClick={()=>{setSettingFilter(true);}}/>
				
		</div>
		<div id="Empoyee_Dashi_MobGroup_12">
			<div id="Empoyee_Dashi_MobCurrent_Candidate_lbl">
				<span>Candidate:{rowData && rowData.filter(x=>x.name === user.name)[0]? rowData.filter(x=>x.name === user.name)[0].currentCandidate?<marquee id="Empoyee_Dashi_MobCurrent_Candidate_val">{rowData.filter(x=>x.name === user.name)[0].currentCandidate}</marquee>:"None":"None"}</span>
			</div>

      {/* {!isLoading?<Button id='Empoyee_Dashi_Mobwhatsapp_dashboard_cd' size='small' variant="contained"  title={(isCallingActive.current === true?"Stop ":"Start ")+"Calling"} style={{textAlign:"center"}} onClick={()=>{
           isCallingActive.current!==true ?UpdateCallingStatus():OpenStopModal()}}>{(isCallingActive.current===true?<img id="Empoyee_Dashi_Mobwhatsapp_dashboard_cd" src="stop-button_mob.png" srcset="stop-button_mob.png 1x" />:<img id="Empoyee_Dashi_Mobwhatsapp_dashboard_cd" src="whatsapp_dashboard_cd.png" srcset="whatsapp_dashboard_cd.png 1x" />)}</Button>:""} */}

		{!isLoading?	<img id="Empoyee_Dashi_Mobwhatsapp_dashboard_cd" src={isCallingActive.current===true?"stop-button_mob.png":"whatsapp_dashboard_cd.png"}  onClick={()=>{
           isCallingActive.current!==true ?UpdateCallingStatus():OpenStopModal()}}/>:""}
				
		</div>
	</div>
	<div id="Empoyee_Dashi_MobGroup_10" onClick={()=>addApplicants()}>
		<svg class="Empoyee_Dashi_Mobchat_btn_cf">
			<ellipse id="Empoyee_Dashi_Mobchat_btn_cf" rx="20" ry="20" cx="20" cy="20">
			</ellipse>
		</svg>
		<img id="Empoyee_Dashi_Mobchat_lbl_cg" src="chat_lbl_cg.png" srcset="chat_lbl_cg.png 1x" />
			
	</div>

	<div id="Empoyee_Dashi_MobGroup_26">
	<select class="Empoyee_Dashi_MobRectangle_43" value={data} onChange={(e)=>setData(e.target.value)}>
  <option value="history">History</option>
  <option value="mobile">Mobile</option>
  <option value="name">Name</option>
  <option value="feedback">Feedback</option>
  <option value="language">Language</option>
  <option value="area">Location</option>
  <option value="education">Education</option>
			
		</select>

		<div id="Empoyee_Dashi_MobGroup_25">
    {data!=='history' ?<input class="Empoyee_Dashi_MobRectangle_44"  value={searchData}  onChange={(e)=>setSearchData(e.target.value)}/>:<><input type="date" className="inputfield"  value={fromDate}
      // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
         onChange={(value) => {
          setFromDate(value.target.value);
        }}/>&nbsp;
        <input type="date" className="inputfield"  value={toDate}
       // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
        onChange={(value) => {
          setToDate(value.target.value);
        }}/></>}
			
			<img id="Empoyee_Dashi_Mobchat_lbl_cl" src="chat_lbl_cl.png" srcset="chat_lbl_cl.png 1x" onClick={()=>data!=='history'?SearchCandidate():LoadPreviousData()}/>
				
		</div>
   
	</div>
	{user && parseInt(user.accesstype) > 1 ?

  <div id="Empoyee_Dashi_MobGroup_24">
		<div class="Empoyee_Dashi_MobRectangle_45">
   
  <Switch
      id="Empoyee_Dashi_MobRectangle_45"
      checked={activeAdmin}
      onChange={(e)=>{setActiveAdmin(e.target.checked);setTimeout(()=>{
        props.showHr("Admin")
      },[1000])}}
      inputProps={{ 'aria-label': 'controlled' }}
    />

		</div>
		<div id="Empoyee_Dashi_MobAdmin">
			<span>Supervisor</span>
		</div>
	</div>:""}
</div>:""}
 {
      settingFilter===true ?<FilterInterviewsMob  setfilter={true} filter={(value)=>{setSettingFilter(value);
     
        }}/>:""
 }
{
  chattingView===true ? <EmployeeChats  chatsopen={true}  chats={(e)=>{setChattingView(e)}}/>:""
}

{/* stop model */}
<Modal
        open={openStopModel}
        onClose={closeStopModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
          <Box sx={stopModalStyle}>
         
         <Typography id="modal-modal-description">
         <Container style={{padding:"0px"}}>
         <div className = "content6">
       <div style={{alignItems:"center"}}>
         <h2 className='fnt2' style={{left:0,top:0}}><b>Enter Stop Reason</b></h2>
         <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"white",backgroundColor:"red" }} onClick={()=>setCloseStopModel()} ><CloseIcon /></Button>   
         </div>
         <br/>
       <TextField  id="stopReason" multiline rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="Enter Stop Reason" variant="outlined"  value={stopComments} onChange={(e)=> {
       setStopComments(e.target.value);   
       
         }}/>
        
         <br/>
         
    <Button  variant="contained" sx={{ m: 1, width: '35ch',backgroundColor :"#14c871" }} onClick={()=>{;stopComments.trim().length>0 ? UpdateCallingStatus():props.showAlert("Please Enter Stop Reason!!!","error");}}>Submit</Button>   
      </div>
      </Container> 
          </Typography>
        </Box>
      </Modal>

{/* <ScheduleInterview open={openShedule} showAlert={props.showAlert}  interview={selectedRow} close={(openNext)=>{LoadCurrentData();setOpenScedule(false);alertStart.current = new Date();setTimeout(checkIdle,300000);if(openNext===true)showCandidate("new",user.name)}} /> */}
<ScheduleInterviewUI open={openShedule} showAlert={props.showAlert}  interview={selectedRow} close={(openNext)=>{LoadCurrentData();setOpenScedule(false);alertStart.current = new Date();setTimeout(checkIdle,300000);if(openNext===true)showCandidate("new",user.name)}} />
<ApplicantPopup open={openApplicant} showAlert={props.showAlert}  close={()=>{setOpenApplicant(false);}} />
  </div>:
    <div>You need to grant Camera and Microphone Permissions to use this Page!</div>
    }
    <br />
    {camAllowed !== true && <div style={{color:'yellow',backgroundColor:"red"}}>Please grant Camera Permissions!</div>}
    <br />
    {micAllowed !== true && <div style={{color:'yellow',backgroundColor:"red"}}>Please grant Microphone Permissions!</div>}
  </>
  )
}

export default EmployeeDashboardMob