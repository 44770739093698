import React,{useState,useEffect} from 'react'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import { Button, Divider} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';



 export const Timer = (props) => {

    const [openTime,setOpenTime] =useState(0);
   

var startTime = 0;
    useEffect(()=>{
      
          if(props.started)
          {
            startTime= props.initValue?parseInt(props.initValue):0;
          
            setInterval(()=>{
            setOpenTime(startTime++)
           
        
            }
            , 1000)

           }
    else {

        startTime=0;

      setOpenTime(0)
    }},[props.started])   
    useEffect(()=>{
      

  },[openTime])   





  return (
    <>
    <div style={{backgroundColor:openTime>300?"red":"cornflowerblue",color:openTime>300?"white":openTime>210?"red":"white"}}>{props.label?props.label+" - ":""}
    {String(parseInt(openTime/60/60)).padStart(2, '0')}:{String(parseInt((openTime/60)%60)).padStart(2, '0')}:{String(openTime%60).padStart(2, '0')}
    &nbsp;&nbsp;</div>
   
    </>
  )
}
export default Timer