import React, {useEffect, useState} from 'react'
import '../Styles/ClientsHRMainMob.css'
import InterviewDetails from '../InterviewDetails';
import MessagedDetails from '../MessagedDetails';
import Today from '../Today';
import Tomorrow from '../Tomorrow';
import SelectedCandidates from '../SelectedCandidates';
import JoinedCandidates from '../JoinedCandidates';
import EligibleCandidates from '../EligibleCandidates';
import InvoicedCandidates from '../InvoicedCandidates';
import SentEmailsToCandidates from '../SentEmailsToCandidates';

const ClientsHRMainMob = (props) => {

const [selectedInterviewTab,setSelectedInterviewTab] = useState("Selected");


  return (
	<div id="bizibees_mob_admin">
	<div id="screen_and_menu_grp_BBL">
		<div id="discplay_grp_BBl">
			<svg class="display_BBL">
				<rect id="display_BBL" rx="6" ry="6" x="0" y="0" width="100%" height="100%">
				</rect>
			</svg>
		{selectedInterviewTab=== "Selected"?<SelectedCandidates showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Joined"?<JoinedCandidates showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Eligible"?<EligibleCandidates showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Invoiced"?<InvoicedCandidates showAlert={props.showAlert} />:""}
		</div>

 <table id="ELOUUD_BBL">
	<tr style={{padding:0}}>
		<td class={selectedInterviewTab=== "Selected"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Selected");}}>
		<div >Selected</div>
		</td>
	</tr>
	<tr>
		<td class={selectedInterviewTab=== "Joined"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Joined");}}>
		<div >Joined</div>
		</td>
	</tr>
	<tr>
		<td class={selectedInterviewTab=== "Eligible"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Eligible");}}>
		<div >Eligible</div>
		</td>
	</tr>
	<tr>
		<td class={selectedInterviewTab=== "Invoiced"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Invoiced");}}>
		<div >Invoiced</div>
		</td>
	</tr>
	
</table> 

{/* 
		<div id="ELOUUD_BBL">
			<div id="lcoation_BBL">
				<span>Called</span>
			</div>
			<div id="OFL_BBL">
				<span>Messaged</span>
			</div>
			<div id="upload_BBL">
				<span>Emailed</span>
			</div>
			<div id="users_BBL">
				<span>Today's</span>
			</div>
			<div id="devices_BBL">
				<span>Tommorow's</span>
			</div>
			<div id="unline_and_email_grp">
				<div id="email_lbl_BBL">
					<span>Selected</span>
				</div>
				<svg class="email_underline" viewBox="0 0 1 39">
					<path id="email_underline" d="M 0 0 L 0 39">
					</path>
				</svg>
			</div>
		</div> */}
	</div>
		
</div>
  )
}

export default ClientsHRMainMob
