import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import "../Styles/AdminOthersData.css";
import { UserContext } from '../UserContext';


const AdminOthersData = (props) => {

  const { user, setUser} = useContext(UserContext);
  const alertStart = useRef(new Date());
  const [rowData,setRowData] = useState([])


  useEffect(()=>{
  if(props.rowData){
    setRowData(props.rowData)
  }
  
  },[props.rowData])
  
  useEffect(()=>{
  
  
  },[props.showOnlyHR,props.users,props.fromDate,props.toDate])


   //View Data 
 const viewData = (row) =>{

  window.location.href= "/interviewdetails?selectedCell=" + row;
  }

  return (

    <div id="BottmPartOttactivity">

       <div id="BottmPartOttGroup_36">
        <div id="BottmPartOttInterview" onClick={()=>props.selectedTab("interviews")}>
          <span>Interviews</span>
        </div>
        <div id="BottmPartOttVerification" onClick={()=>props.selectedTab("verifications")}>
          <span>Verifications</span>
        </div>
        <div id="BottmPartOttothers" onClick={()=>props.selectedTab("duration")}>
          <span>Duration</span>
        </div>
        <div id="BottmPartOttoth">
          <span>Others</span>
        </div>
        
          <div id="BottmPartOttActivity_w" onClick={()=>props.selectedTab("activity")}>
            <span>Activity</span>
          </div>
      </div> 
       
      <div id="BottmPartOttGroup_39">
        <svg class="BottmPartOttRectangle_46">
          <rect id="BottmPartOttRectangle_46" rx="24" ry="24" x="0" y="0" width="100%" height="100%">
          </rect>
        </svg>
  {rowData.map((row)=>(
        <div id="BottmPartOttGroup_37">
          <div id="BottmPartOttRoobi">
            <span>{row.name}</span>
          </div>
          
          <div id="BottmPartOttn_037">
            <span>{row.longCalls?row.longCalls:0}</span>
          </div>
          {/* <div id="BottmPartOttn_510545">
            <span>35/10545</span>
          </div> */}
      
          <div id="BottmPartOttMohammed" onClick={()=> (user.accesstype == 5 || row.name=== user.name) && viewData((["messaged",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.messaged}</span>
          </div>
          <img id="BottmPartOttspeaker-filled-audio-tool" src="speaker-filled-audio-tool.png" srcset="speaker-filled-audio-tool.png 1x" />
            
        </div>
        ))} 
        <div id="BottmPartOttGroup_38">
          <div id="BottmPartOttStatus">
            <span>Messaged</span>
          </div>
          <div id="BottmPartOttn_passed">
            <span>{(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.messaged,0)}</span>
          </div>
          <div id="BottmPartOttTime">
            <span>Long Call</span>
          </div>
          {/* <div id="BottmPartOttn_failed">
            <span>10/31</span>
          </div> */}
          {/* <div id="BottmPartOttQueue">
            <span>Idle Time</span>
          </div>
          <div id="BottmPartOttn_queue">
            <span>00/31</span>
          </div> */}
          <svg class="BottmPartOttLine_2" viewBox="0 0 225.87 1">
            <path id="BottmPartOttLine_2" d="M 0 0 L 225.86962890625 0">
            </path>
          </svg>
        </div>
      </div>
   
  </div>
  )
}

export default AdminOthersData
