import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import {useNavigate} from "react-router-dom";

import '../Styles/CandidateApplicationsTopBar.css'



const CandidateApplcationsTopBar = () => {
    var navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const handlelogout = () =>{
		localStorage.clear();
		setUser(null);
		window.location.href = "/";
	  }

  return (
    <div>
   {/* <div id="nav_app_pc_candi">
		<svg class="Path_3_vacan" viewBox="5356 -9.342 1398 93.436">
			<path id="Path_3_vacan" d="M 5356 33.05493545532227 L 5559.17333984375 33.05493545532227 C 5559.17333984375 33.05493545532227 5576.677734375 34.01589965820312 5576.677734375 50.17344665527344 C 5576.677734375 66.33099365234375 5576.677734375 69.73258209228516 5576.677734375 69.73258209228516 C 5576.677734375 69.73258209228516 5574.111328125 81.63815307617188 5592.07568359375 83.33894348144531 C 5610.0400390625 85.03973388671875 5732.30224609375 83.33894348144531 5732.30224609375 83.33894348144531 C 5732.30224609375 83.33894348144531 5748.2666015625 83.72677612304688 5748.2666015625 69.73258209228516 C 5748.2666015625 55.73838806152344 5748.2666015625 50.17344665527344 5748.2666015625 50.17344665527344 C 5748.2666015625 50.17344665527344 5745.541015625 33.208740234375 5770.4609375 33.05493545532227 C 5795.38037109375 32.90113067626953 5882.68896484375 33.05493545532227 5882.68896484375 33.05493545532227 C 5882.68896484375 33.05493545532227 5900.45654296875 32.31510162353516 5901.1142578125 50.17344665527344 C 5901.77197265625 68.03179168701172 5901.1142578125 69.73258209228516 5901.1142578125 69.73258209228516 C 5901.1142578125 69.73258209228516 5901.4326171875 83.30850219726562 5918.71240234375 83.33894348144531 C 5935.9921875 83.369384765625 6571.578125 83.33894348144531 6571.578125 83.33894348144531 C 6571.578125 83.33894348144531 6588.07373046875 85.09134674072266 6588.07373046875 67.31806182861328 C 6588.07373046875 49.54479217529297 6588.07373046875 46.52854537963867 6588.07373046875 46.52854537963867 C 6588.07373046875 46.52854537963867 6585.55322265625 34.83837127685547 6604.45849609375 33.84342193603516 C 6623.3642578125 32.84847259521484 6754 33.05493545532227 6754 33.05493545532227 L 6754 -9.341615676879883 L 5376 -9.341615676879883 L 5356 33.05493545532227 Z">
			</path>
		</svg>
		<div id="Applications_vacan">
			<span>Applications</span>
		</div>
		<img id="bizibeesjpg_vacan" src="bizibeesjpg_vacan.png" srcset="bizibeesjpg_vacan.png 1x" />
			
		<img title="Log Out" id="logout_vacan" src="logout_vacan.png" srcset="logout_vacan.png 1x" onClick={()=>handlelogout()}/>
			
		<img title="Profile" id="profile_vacan" src="profile_vacan.png" srcset="profile_vacan.png 1x" />
			
		<div onClick={()=>navigate("/vacancies")} id="Interviews_vacan">
			<span>Vacancies</span>
		</div>
	</div> */}

	<div id="applications_pc_candi_header">
	<div id="left_grp_pplication">
		<svg class="left_corner_application" viewBox="11429 2956.231 545.423 70.996">
			<path id="left_corner_application" d="M 11429 2977.013916015625 L 11429 2956.2314453125 L 11974.4228515625 2956.2314453125 L 11974.4228515625 3026.17724609375 L 11973.4423828125 3026.17724609375 C 11973.4423828125 3026.17724609375 11958.845703125 3027.49853515625 11958.267578125 3010.958251953125 C 11957.689453125 2994.418212890625 11958.267578125 2991.22607421875 11958.267578125 2991.22607421875 C 11958.267578125 2991.22607421875 11957.6884765625 2976.13671875 11939.7646484375 2976.13671875 C 11921.8408203125 2976.13671875 11822.9658203125 2976.13671875 11822.9658203125 2976.13671875 C 11822.9658203125 2976.13671875 11804.751953125 2978.45849609375 11804.751953125 2991.22607421875 C 11804.751953125 3003.99365234375 11804.751953125 3017.34228515625 11804.751953125 3017.34228515625 C 11804.751953125 3017.34228515625 11800.0810546875 3027.27294921875 11790.4248046875 3027.226318359375 C 11790.49609375 3027.230224609375 11640.25390625 3027.22265625 11640.0078125 3027.208251953125 C 11630.177734375 3026.62841796875 11622.9501953125 3019.37353515625 11623.2392578125 3017.34228515625 C 11623.5283203125 3015.311279296875 11623.2392578125 2991.22607421875 11623.2392578125 2991.22607421875 C 11623.2392578125 2991.22607421875 11622.083984375 2976.13671875 11605.8935546875 2976.13671875 C 11589.703125 2976.13671875 11429 2977.013916015625 11429 2977.013916015625 Z">
			</path>
		</svg>
		<a href="https://bizibees.in/" target="">
		<img id="bizibeesjpg_application" src="bizibeesjpg_application.png" srcset="bizibeesjpg_application.png 1x" alt="reload"/>
       </a>
		
			
		<div id="vacancies_application" onClick={()=>navigate("/vacancies")}>
			<span>Vacancies</span>
		</div>
		<div id="Applications_lbl">
			<span>Applications</span>
		</div>
	</div>
	<div id="right_grp_application">
		<svg class="right_corner_application" viewBox="12604.423 2956.948 175.078 71.411">
			<path id="right_corner_application" d="M 12604.4228515625 2958.037841796875 L 12604.4228515625 3028.342041015625 L 12618.232421875 3028.342041015625 C 12618.232421875 3028.342041015625 12635.0185546875 3029.3564453125 12635.0185546875 3015.6845703125 C 12635.0185546875 3002.01318359375 12635.0185546875 2988.84814453125 12635.0185546875 2988.84814453125 C 12635.0185546875 2988.84814453125 12636.486328125 2978.21484375 12648.7314453125 2977.708251953125 C 12660.9755859375 2977.20166015625 12779.5009765625 2977.708251953125 12779.5009765625 2977.708251953125 L 12779.5009765625 2956.94775390625 L 12766.078125 2958.037841796875 L 12604.4228515625 2958.037841796875 Z">
			</path>
		</svg>
		<img title="Log Out" id="logout_application" src="logout_application.png" srcset="logout_application.png 1x"  onClick={()=>handlelogout()}/>
			
		<img title="Profile" id="profile_application" src="profile_application.png" srcset="profile_application.png 1x" onClick={()=>navigate('/dashboard')}/>
			
	</div>
	<div id="centre_header_pc_grp_app">
		<svg class="centre_header_pc_app">
			<rect id="centre_header_pc_app" rx="0" ry="0" x="0" y="0" width="630" height="70">
			</rect>
		</svg>
	</div>
</div>
    </div>
  )
}

export default CandidateApplcationsTopBar
