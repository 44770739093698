import React, {
  createContext,
  useState,
  useContext,
  useRef,
  useEffect,
} from "react";
import { io } from "socket.io-client";
import Peer from "simple-peer";
import { UserContext } from "./Components/UserContext";
import { DevicePermissions } from "./DevicePermissions"
import { StreamVideo } from "./StreamVideo"
import * as process from 'process';

const SocketContext = createContext();

const socket = io(process.env.REACT_APP_API_URL, {
  reconnect: true,
  autoConnect: true,
  reconnectionDelay: 1000,
  reconnectionDelayMax: 5000,
  reconnectionAttempts: 20,
});


const ContextProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [name, setName] = useState("");
  const [mute, setMute] = useState(false);
  const [videoOff, setVideoOff] = useState(false);

  const hrId = useRef("");
  const me = useRef();
  const hearingSocket = useRef();
  const myVideo = useRef(new MediaStream());
  const remoteVideo = useRef(new MediaStream());
  const checkOnlineStatusTimer = useRef();
  const connectionRef = useRef();
  const activeStreamRef = useRef(new MediaStream());
  const call = useRef({});
  const offered = useRef(false);
  const Permissions = useRef();
  const isCallActive = useRef(false);
  const isInCallWith = useRef();


  useEffect(() => {

    Permissions.current = DevicePermissions(navigator.permissions);

  }, [navigator.permissions])

  useEffect(async () => {
    if (user) {

      // if(videoOff===false && Permissions.current.camAllowed === true)
      // {await StreamVideo({on:true,video:true,audio:true});}
      // StreamVideo({on:false});

      socket.off("calluser")
      socket.off("hrsocketid")
      socket.off("connect_error")

      socket.on("calluser", async ({ signal, from, name }) => {
        console.log("call coming", name);
        call.current = { isRecievedCall: true, signal, from, name };

        streamCall(signal, from);
      });

      socket.on("hrsocketid", async (data) => {
        if (user) {
          console.log("hrsocketid: ", data)
          hearingSocket.current = await data[0];
          if (hearingSocket.current && hearingSocket.current.socketId) {
            hearUser(hearingSocket.current.socketId, hearingSocket.current.userName);
          }
          else {
            console.log("Socket is not created!")
          }
        }
      });

      socket.on("connect_error", (err) => {
        // the reason of the error, for example "xhr poll error"
        console.log(err.message);

        // some additional description, for example the status code of the initial HTTP response
        console.log(err.description);

        // some additional context, for example the XMLHttpRequest object
        console.log(err.context);
      });
      return () => {
        // clearInterval(checkOnlineStatusTimer.current);
        socket.off("calluser")
        socket.off("hrsocketid")
        socket.off("connect_error")
        StreamVideo({ on: false })
      };
    }
  }, [user]);


  useEffect(() => {
    if (user) {
      socket.emit('setSocketId', { userId: user._id, userName: user.name, socketId: socket.id });
      console.log("My socket id changed", { userId: user._id, userName: user.name, socketId: socket.id })
    }
  }, [socket.id]);

  useEffect(async () => {

    console.log("Use Effect", videoOff, mute);

    activeStreamRef.current = await StreamVideo({ on: videoOff === false && Permissions.current.camAllowed === true, video: videoOff, audio: mute });

    connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getVideoTracks().forEach(track => track.enabled = !videoOff));

    connectionRef.current && connectionRef.current.streams.forEach(stm => stm.getAudioTracks().forEach(track => track.enabled = !mute));

  }
    , [videoOff, mute]);

  const getHrSocketId = (selectedHRId) => {
    hrId.current = selectedHRId;
    socket.emit("gethrsocketid", { hr: selectedHRId });
  };


  const streamCall = async (passedSignal, passedFrom) => {

    activeStreamRef.current = await StreamVideo({ on: true, video: !videoOff, audio: !mute });

    var peer = new Peer({
      initiator: false,
      trickle: false,
      stream: activeStreamRef.current
    });

    peer._debug = console.log;
    peer.on("connect", () => {
      console.log("HR CONNECTED to ADMIN ");
    });
    peer.on("disconnected", () => {
      console.log("HR DISCONNECTED with ADMIN ");
      peer.reconnect(5)
    });
    peer.on("error", (err) => {
      console.error(err);
    });

    peer.on("close", () => {
      console.log("CLOSED HR PEER ");
      // isCallActive.current = false;
      socket.off("userleft")
      socket.off("callended")
    });

    peer.on("signal", (data) => {
      isCallActive.current = true;
      console.log("Hear Request Received by HR!", data);
      // if (data.renegotiate || data.transceiverRequest) return;
      if (data.renegotiate) return;
      socket.emit("answercall", { signal: data, to: passedFrom });
      console.log("Answered Call!");
      // socket.off("calluser")
    });

    socket.on("userleft", () => {
      console.log("Admin Left Hearing from Socket!");
      isCallActive.current = false;
      socket.off("callended")
      socket.off("userleft")
      peer.destroy();
      StreamVideo({ on: false });
      if (connectionRef.current != null && connectionRef.current != undefined) { connectionRef.current.destroy(); connectionRef.current = null; }
    });

    socket.on("callended", () => {
      console.log("Admin Ended Hearing from Socket!");
      peer.reconnect(5)
      // socket.off("callended")
      // socket.off("userleft")
      // peer.destroy()
      // streamCall(call.current.signal, call.current.from);
    });

    connectionRef.current = peer;

    peer.signal(passedSignal);

  };

  const hearUser = (id, callName) => {

    isInCallWith.current = id;
    console.log("hear user");
    var peer = new Peer({
      initiator: true,
      trickle: false,
      stream: null,
    });

    peer._debug = console.log;

    peer.on("signal", (data) => {
      isCallActive.current = true;
      if (data.renegotiate) return console.log('Unexpected renegotiate signal');
      // if (data.type === 'offer' || data.type === 'answer' || data.type === 'transceiverRequest') {
        socket.emit("calluser", {
          userToCall: id,
          signalData: data,
          from: socket.id,
          name: user.name,
        });
        offered.current = true;
        console.log({
          userToCall: id,
          signalData: data,
          from: socket.id,
          name: user.name,
        })
      // }
      // else {
      //   console.log(data.type)
      // }

    });
    peer.on("disconnected", () => {
      console.log("ADMIN DISCONNECTED with HR");
      peer.reconnect(5)
    });

    peer.on("stream", (currentStream) => {
      console.log("Caller Stream!");
      socket.off("callaccepted");
      remoteVideo.current.srcObject = currentStream;
    });
    peer.on("track", (track) => {
      console.log("Track received!", track.kind);
      //socket.off("callaccepted");
    });

    socket.on("callaccepted", (signal) => {
      console.log("HR Accepted Call!", signal);
      if (peer.destroyed === true) {
        if (isCallActive.current === true) {
          console.log(id, "Reconnecting - admin peer is destroyed!!");
          getHrSocketId(hrId.current);
        }
      }
      else {
        // if (isCallActive.current === true ) {
        
   //   if (signal.renegotiate) return console.log('Unexpected renegotiate signal');
        if (signal.type === 'offer' ||signal.type === 'answer' || signal.type === 'transceiverRequest') {
          peer.signal(signal);
        }
        else {
          console.log('Unexpected signal', signal.type)
        }
      }
      // }
    });

    peer.on("error", (err) => {

      console.error("Error", err);

    });

    peer.on("close", () => {

      console.log("CLOSED PEER ");
      //isCallActive.current = false;

      socket.off("callended")
      socket.off("callaccepted")
      socket.off("userleft")
    });

    socket.on("userleft", () => {
      console.log("HR Left Call from Socket!");
      isCallActive.current = false;
      socket.off("callended")
      socket.off("callaccepted")
      socket.off("userleft")
      peer.destroy();
      if (connectionRef.current != null && connectionRef.current != undefined) { connectionRef.current.destroy(); connectionRef.current = null; }
    });
    socket.on("callended", () => {

      console.log("HR Ended Call from Socket!");
peer.reconnect(5)
      //   socket.off("callaccepted")
      //   socket.off("callended");
      //   socket.off("userleft")
      //   peer.destroy()
      // if (isCallActive.current === true) {
      //   getHrSocketId(hrId.current);
      // }
    });

    connectionRef.current = peer;
  };

  const leaveCall = () => {

    console.log("Inform Left Call to ", isInCallWith.current);

    socket.emit("leftcall", { to: isInCallWith.current });
    socket.off("callended")
    socket.off("callaccepted")
    socket.off("userleft")

    isCallActive.current = false;
    if (connectionRef.current != null && connectionRef.current != undefined) { connectionRef.current.destroy(); connectionRef.current = null; }
  };

  return (
    <SocketContext.Provider
      value={{
        remoteVideo,
        getHrSocketId,
        leaveCall,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
