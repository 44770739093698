import React, {useEffect, useState} from 'react'
import '../Styles/SetupPagePc.css'
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from '../UserContext';
import { useSearchParams, useLocation } from 'react-router-dom';
import ReAssign from '../ReAssign';
import BulkDataUpload from '../BulkDataUpload';
import Cities from '../Cities';
import Locations from '../Locations';
import OfficeLocations from '../OfficeLocations';
import FAQ from '../FAQ';
import Users from '../Users';

const SetupPagePc = (props) => {

const { user, setUser } = useContext(UserContext);
const [selectedSetupTab,setSelectedSetupTab] = useState((user.accesstype == 5) ? "Upload":"ReAssign");


  return (
    <div id="SetupPagePc_today">

	<div id="SetupPagePc_Group_12">
		<svg class="SetupPagePc_Rectangle_4">
			<rect id="SetupPagePc_Rectangle_4" rx="15" ry="15" x="0" y="0" width="100%" height="100%">
			</rect>
		</svg>
		{selectedSetupTab=== "Upload"?<BulkDataUpload showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "ReAssign"?<ReAssign showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "Cities"?<Cities showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "Locations"?<Locations showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "Office Locations"?<OfficeLocations showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "FAQs"?<FAQ showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "Users"?<Users showAlert={props.showAlert}/>:""}
		{/* {selectedSetupTab=== "Devices"?<InvoicedCandidates />:""} */}
	</div>
	<div id="SetupPagePc_Group_7">
		<svg class="SetupPagePc_Rectangle_5">
			<rect id="SetupPagePc_Rectangle_5" rx="10" ry="10" x="0" y="0" width="128" height="100%">
			</rect>
		</svg>
		<table id='SetupPagePc_Group_6'>
		{user && user.accesstype == 5 ?<tr><td class={selectedSetupTab=== "Upload"?'selectedSetupTab_pc':'setupTab_pc'} onClick={()=>{setSelectedSetupTab("Upload");}}>Upload</td></tr>:<></>}
		{user && user.accesstype > 2 ?<tr><td class={selectedSetupTab=== "ReAssign"?'selectedSetupTab_pc':'setupTab_pc'} onClick={()=>{setSelectedSetupTab("ReAssign");}}>ReAssign</td></tr>:<></>}
		{user && user.accesstype == 5 ?<tr><td class={selectedSetupTab=== "Cities"?'selectedSetupTab_pc':'setupTab_pc'} onClick={()=>{setSelectedSetupTab("Cities");}}>Cities</td></tr>:<></>}
		{user && user.accesstype > 2 ?<tr><td class={selectedSetupTab=== "Locations"?'selectedSetupTab_pc':'setupTab_pc'} onClick={()=>{setSelectedSetupTab("Locations");}}>Locations</td></tr>:<></>}
		{user && user.accesstype > 2 ?<tr><td class={selectedSetupTab=== "Office Locations"?'selectedSetupTab_pc':'setupTab_pc'} onClick={()=>{setSelectedSetupTab("Office Locations");}}>Office Locations</td></tr>:<></>}
		{user && user.accesstype > 2 ?<tr><td class={selectedSetupTab=== "FAQs"?'selectedSetupTab_pc':'setupTab_pc'} onClick={()=>{setSelectedSetupTab("FAQs");}}>FAQs</td></tr>:<></>}
		{user && user.accesstype == 5 ?<tr><td class={selectedSetupTab=== "Users"?'selectedSetupTab_pc':'setupTab_pc'} onClick={()=>{setSelectedSetupTab("Users");}}>Users</td></tr>:<></>}
		
		</table>

	
	</div>
</div>
  )
}

export default SetupPagePc
