import React, {useState, useEffect, useContext} from 'react'
    import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
    import { Button} from '@mui/material';
    import './InterviewDetails.css'
    import PersonSearchIcon from '@mui/icons-material/PersonSearch';
    import Modal from '@mui/material/Modal';
    import { UserContext } from './UserContext';
    import { useSearchParams } from 'react-router-dom';
    import FormControl from '@mui/material/FormControl';
    import InputLabel from '@mui/material/InputLabel';
    import  {MenuItem,Select} from '@mui/material';
    import ScheduleInterview from './ScheduleInterview';
    import AdapterDateFns from '@mui/lab/AdapterDateFns';
    import LocalizationProvider from '@mui/lab/LocalizationProvider';
    import DatePicker from '@mui/lab/DatePicker';
    import  {TextField,Typography,Box,Container,Autocomplete} from '@mui/material';


const ReAssign = (props) => {
 
    const today=() => {
      var date = new Date();
      const day = (date.getDate() < 10 ? '0' : '')+date.getDate();
      const month = (date.getMonth() + 1 < 10 ? '0' : '')+(date.getMonth() + 1);
      const year = date.getFullYear();
      return year+'-'+month+'-'+day;
    }
    const [searchParams] = useSearchParams();
    const [interviews, setInterviews] = useState([]);
    const [activity, setActivity] = useState("created");
    const [selectedData, setSelectedData] = useState();
    const [open, setOpen] = React.useState(false);
    const [interViewerName, setInterViewerName]= useState("");
    const [fromDate, setFromDate] = useState(today());
    const [selectedRow,setSelectedRow] = useState();
    const { user, setUser} = useContext(UserContext);
    const [interviewStatus,setInterviewStatus] = useState();
    const [OpenShedule, setOpenScedule ]= React.useState();
    const [hrNames,setHRNames]= useState([])
    const [hrId,setHRId]= useState("")
    const [hrName,setHRName]= useState("")
    const[assignHrId,setAssignHRId]=useState("")
    const[assignHRName,setAssignHRName]=useState("")
    const [applicantid, setApplicantId] = useState();
    const [toDate,setTodate] = useState(today());
    
    // const [companyName, setCompanyName] = useState();
    // const [jobTitle, setJobTitle] = React.useState();
    const [filterModel, setFilterModel] = React.useState({
      items: [
     
      ],
    });
    

const rounds = [
    { label: 'HR Screening' },
    { label: 'Verification' },
    { label: 'Client Round' },
  ]  
  
  const style3 = {
    position: 'absolute',
    top: '50%',
  
    transform: 'translate(87%, -50%)',
    height: 489,
    width: 533,
  
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 10,
  
  };
  
  const cols = [  
    {field:'action', headerName: '',width: 80, headerAlign:'center',
    renderCell: (params) => {
      return[
         <div>
          <Button size='small' title="Schedule"  onClick={()=>showApplicant(params.row)}><PersonSearchIcon/></Button>
          {/* <Button size='small' title="Approve" onClick={()=>ApproveApplicants(params.row)}><CheckIcon/></Button> */}
       {/* <Button size='small'  title="Reject" onClick={()=>RejectApplicants(params.row)}><CancelOutlinedIcon/></Button> */}
   </div>
      ];
     },
    },
    {field:'name', headerName: 'Name',width: 190 },
    {field:'createdbyname', headerName: 'Created By',width: 190,hide: true },
    {field:'mobile', headerName: 'Mobile No',width: 140},
    {field:'hrname', headerName: 'HR Name',width: 190},
    {field:'jobtitle', headerName: 'Job Title',width: 200},
    {field:'companyname', headerName: 'Company Name',width: 200},
    {field:'interviewername', headerName: 'Interviewer Name',width: 190},
    
    {field:'interviewdate', headerName: 'Interview Date',width: 140},
    // {field:'interviewtime', headerName: 'Interview Time',width: 140},
    {field:'rounds', headerName: 'Rounds',width: 150},
    {field:'interviewlocation', headerName: 'Interview Location',width: 200},
    {field:'status', headerName: 'Status',width: 110},
   
    ];
  

    //Get Users
    const getUsers = () =>{
        let apiURL = process.env.REACT_APP_API_URL + "/users/getoverallusers"
        let apiParams = {
          method: "GET",
          headers: {
            authtoken: localStorage.getItem("loggedintoken")
          }
        };
        fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  // console.log(repos.data)
                  setHRNames(repos.data)
                  //.filter(user=>user.accesstype != 5)
                }else props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
          }

  
     
    const scheduleinterview = (row) =>{
  
      setSelectedRow(row)
        // setName(row.name)
        // setJobId(row.jobid)
        // setMobile(row.mobile)
        // setJobTitle(row.jobtitle)
        // setApplicantId(row.id)
        // setMail(row.mail)
        // setCompanyName(row.companyname)
          setOpenScedule(true);
        //  alert(JSON.stringify(row))
        }
  
    useEffect(()=>{
    getUsers()
    },[interviewStatus])
    
      
    const handleCloseView = () => setOpen(false)
  
  
    const showApplicant = (row) =>{
      window.location.href="/applicants?applicantid="+row.applicantid;
    }

const LoadData = () =>{
  if(hrId.length===0){
    props.showAlert("Please Select Hr Name","error");
  }
  let apiURL=process.env.REACT_APP_API_URL + "/interview/loadinterviews"
  let apiParams = {
      method:"POST",
      headers:{
       activity:activity,
        hrid :hrId,
        fromdate:fromDate,
        todate:toDate,
        authtoken: localStorage.getItem("loggedintoken")
      },
  };
 
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       
        setInterviews(repos.data)
        
      }else props.showAlert(""+(repos.message || repos),"error");
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
  
}

const AssignData = (recordsIds) =>{
  recordsIds.map(interviewID =>{
  let apiURL="";
  if(activity ==="created")
  {
    apiURL=process.env.REACT_APP_API_URL + "/interview/assigndata/"+interviewID;
  }
  else if (activity ==="rejected")
  {
    apiURL=process.env.REACT_APP_API_URL + "/interview/assignrejected/"+interviewID;
  }
  else if (activity ==="cancelled")
  {
    apiURL=process.env.REACT_APP_API_URL + "/interview/assigncancelled/"+interviewID;
  }
  else if (activity ==="passed")
  {
    apiURL=process.env.REACT_APP_API_URL + "/interview/assignpassed/"+interviewID;
  }
  let apiParams = {
      method:"POST",
      headers:{
        createdbyid:assignHrId,
        createdbyname:assignHRName,
        interviewername:assignHRName,
        hrname:assignHRName,
        authtoken: localStorage.getItem("loggedintoken")
      },
  };
 console.log(apiParams)
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
        getUsers();
      if (repos.data) { 
      }else props.showAlert(""+(repos.message || repos),"error");
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
 })
//  alert("InterView Assign Successfully");
 props.showAlert("InterView Assign Successfully","success")
 setTimeout(() => {
  window.location.reload(false);
 }, 2000);

}

    const rowData = (interviews)?.map((interview) => {
  // alert(JSON.stringify(interview))
      return{
    id: interview?._id,  //applicant id
    applicantid:interview?.applicantId, 
    jobid:interview?.jobId,  //interview id
    name: interview?.name,
    mobile: interview?.mobile,
    jobtitle:interview?.jobtitle,
    companyname:interview?.companyname,
    interviewername:interview?.interviewername,
    hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
    interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
    interviewtime:interview?.interviewtime,
    interviewlocation:interview?.location,
    rounds:interview?.rounds,
    reference:interview?.reference,
    status:interview?.status,
    feedback:interview?.feedback,
    createdbyname:interview?.createdbyname,
    createdbyid:interview?.createdbyId,
      };
    });
    
  return (
        <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
        <br/>
    <FormControl variant="outlined" sx={{width: 250 }} size="small">
            <InputLabel id="demo-simple-select-label">Activity</InputLabel>
            <Select
              labelId="demo-simple-select-labell"
              id="demo-simple-select"
              
              value={activity} onChange={(e)=> {
              
                setActivity(e.target.value);   
               } } >
             
              <MenuItem value="created">Created By</MenuItem> 
              <MenuItem value="rejected">Rejected By</MenuItem>
              <MenuItem value="passed">Passed By</MenuItem>
              <MenuItem value="cancelled">Cancelled By</MenuItem>
            </Select>
          </FormControl> 
         
          <FormControl variant="outlined" sx={{width: 250 }} style={{marginLeft:"10px"}} size="small">
        <InputLabel id="demo-simple-select-label">HR Name</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={hrId} onChange={(e)=> {
          
            setHRId(e.target.value);  
            setHRName(hrNames.filter(hr=>hr._id===e.target.value)[0].name);   
           } } >
          {
           hrNames && hrNames.sort((a,b)=>a.name>b.name?1:-1).map(hr=>  <MenuItem value={hr._id}>{hr.name} - {hr.count}</MenuItem>)
          }
        </Select>
      </FormControl>
     
      <input type='date'
      style={{marginLeft:"10px"}}
        label="From Date"
        value={fromDate}
        onChange={(value) => {
          setFromDate(value.target.value);
        }}
      />
      <input type='date'
      style={{marginLeft:"10px"}}
        label="To Date"
        value={toDate}
        onChange={(value) => {
          setTodate(value.target.value);
        }}
      />
    <Button size='small' variant="contained" style={{marginLeft:"20px"}} title="Load" onClick={()=>LoadData(rowData)}>Load</Button>
        <br/>
        <div style={{height:"calc(100% - 150px)",width: '100%'}}>
          <DataGrid rows={rowData} columns={cols} checkboxSelection components={{
              Toolbar: GridToolbar,
            }}
            filterModel={filterModel}
            onSelectionModelChange ={(e) =>setSelectedData(e)}
            // onRowClick={(e)=>console.log(e)}
            onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
            onRowDoubleClick={(e)=>scheduleinterview(e.row)}
           
              />
     <FormControl variant="outlined" sx={{width: 250 }} style={{marginLeft:"20px",marginTop:"10px"}} size="small">
        <InputLabel id="demo-simple-select-label">HR Name</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={assignHrId} onChange={(e)=> {
          
            setAssignHRId(e.target.value);  
            setAssignHRName(hrNames.filter(hr=>hr._id===e.target.value)[0].name);   
           } } >
          {
           hrNames && hrNames.sort((a,b)=>a.name>b.name?1:-1).map(hr=>  <MenuItem value={hr._id}>{hr.name}</MenuItem>)
            
        }
        </Select>
      </FormControl>
     <Button size='small' variant="contained" style={{marginLeft:"20px",marginTop:"10px"}} title="Assign" onClick={()=>AssignData(selectedData)}>Assign</Button>
  
              
     </div>
     <ScheduleInterview open={OpenShedule} interview={selectedRow} showAlert={props.showAlert} close={()=>setOpenScedule(false)} />
  </div>
  )
}

export default ReAssign