import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { UserContext } from './UserContext';
import TimePicker from '@mui/lab/TimePicker';
import { useSearchParams } from 'react-router-dom';
import BulkDataUpload from './BulkDataUpload';
import Checkbox from '@mui/material/Checkbox';
import Timer from './Timer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ApplicantPopup from './ApplicantPopup';

// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Registrations = (props) => {
  
const [searchParams] = useSearchParams();
const [registrations, setRegistrations] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [name, setName] = useState();
const [mail, setMail] = useState();
const [mobile, setMobile] = useState();
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState();
const [applicantId,setApplicantId]= useState()
const [companyName, setCompanyName] = useState();
const [open, setOpen] = React.useState(false);
const [Rounds, setRounds] = useState();
const [InterViewerName, setInterViewerName]= useState("");
const [InterViewDate, setInterViewDate] = React.useState(new Date());
const [InterViewTime, setInterViewTime] = React.useState();
const [interviewlocation, setInterviewlocation] = useState();
const [selectedRow,setSelectedRow] = useState();
const [openApplicant,setOpenApplicant] = useState(false)
const [isLoading, setIsLoading] = useState(false);
const { user, setUser} = useContext(UserContext);


//UseEffect
useEffect (()=>{
 
   
  getRegistrationsDetails()

  
},[])

  const rowData = (registrations||[])?.map((reg) => {
  

    return{
  id: reg?._id,  
  registrationDate: (new Date(reg?.createdAt).toISOString()).slice(0,10) +" "+ (new Date(reg?.createdAt).toISOString()).slice(11,16),
  name: reg?.name,
  mobile: reg?.mobile,
  email: reg?.email,
  userType:reg?.userType,
  applicantId:reg?.applicantId,
  ip: reg?.ip,

  device: reg?.device,
 
   };
  });
  

const [filterModel, setFilterModel] = React.useState({
  items: user.accesstype == 5 ? [
    // {columnField: 'rounds', operatorValue: 'contains', value:"Verification"}
  ]: [
    // {columnField: 'hrname', operatorValue: 'contains', value:user.name}
  ],
});

const style3 = {
  position: 'absolute',
  top: '50%',

  transform: 'translate(87%, -50%)',
  height: 489,
  width: 533,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 10,

};

const cols = [

  {field:'id', headerName: '',width: 190,hide: true },
  {field:'registrationDate', headerName: 'Registration Date',width: 190 },
  {field:'name', headerName: 'Name',width: 190 },
  {field:'mobile', headerName: 'Mobile',width: 190 },
  {field:'email', headerName: 'Email',width: 180, headerAlign:'center', align:"center"},
  {field:'applicantId', headerName: 'Applicant ID',width: 210, headerAlign:'center', align:"center"},
  {field:'userType', headerName: 'User Type',width: 210, headerAlign:'center', align:"center"},
  {field:'ip', headerName: 'IP',width: 180, headerAlign:'center', align:"center"},
  {field:'device', headerName: 'Device',width: 800, headerAlign:'center', align:"center"},
 
  ];

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const getRegistrationsDetails = ()=>{
  
    let apiURL="";
  
    apiURL=process.env.REACT_APP_API_URL + "/applicants/getregistrations";
    let apiParams = {
        method:"GET", 
        headers: {
          authtoken: localStorage.getItem("loggedintoken")
        }
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          console.log(repos.data)
          setRegistrations(repos.data)
          
        }else props.showAlert(""+(repos.message || repos),"error");  
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  


const ResendWhatsapp = (row)=>{

let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+row.mobile.trim().replace(' ','').substring(row.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+row.name+",%0a%0aI am contacting you for a job vacancy. Please let me know the best time to call you.%0a%0aMy name is "+user.name+", I am from Bizi Bees Outsourcing Private Limited. You can call me on "+user.phone+" as well at your convenient time to discuss about the vacancy.%0a%0aPlease follow us on our LinkedIn page url below for latest updates.%0a%0ahttps://www.linkedin.com/company/bizi-bees-outsourcing";
const newWindow = window.open(apiURL, '_blank')
}
  
const editApplicants = (row) => {
// console.log(row)
  setApplicantId(row.applicantId|| "");
  setOpenApplicant(true);
}


  
  const scheduleinterview = (row) =>{

    setSelectedRow(row)
      // setName(row.name)
      // setJobId(row.jobid)
      // setMobile(row.mobile)
      // setJobTitle(row.jobtitle)
      // setApplicantId(row.id)
      // setMail(row.mail)
      // setCompanyName(row.companyname)
        setOpenScedule(true);
      //  alert(JSON.stringify(row))
      }
    
 

  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }



  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
    <div style={{height:'100%',width: '100%'}}>
      <DataGrid rows={rowData}
                        columns={cols}  
                        // checkboxSelection
               components={{
                  Toolbar: GridToolbar,
                  }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
               onRowDoubleClick={(e)=>editApplicants(e.row)}
           />

 </div>

 <ApplicantPopup openApp={openApplicant} showAlert={props.showAlert} id={applicantId} closeApp={()=>{setOpenApplicant(false);}} /> 
 {/* <ScheduleInterview open={OpenShedule} showAlert={props.showAlert}  interview={selectedRow}  close={()=>setOpenScedule(false)} /> */}
{/* <ScheduleInterviewUI openMsg={OpenShedule} showAlert={props.showAlert}  interviewMsg={selectedRow}  closeMsg={()=>{setOpenScedule(false);getRegistrationsDetails()}}  /> */}
</div>
  ) 
}

export default Registrations







