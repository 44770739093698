import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './Users.css'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import GppGoodIcon from '@mui/icons-material/GppGood';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Update } from '@mui/icons-material';


const Devices = (props) => {

  const style6 = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 500,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

  const label = { inputProps: { 'aria-label': 'Schedule Interview' } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [reportingToId,setReportingToId]= useState("");
  const [reportingToName,setReportingToName]= useState("");
  const [accessType,setAccessType] = useState("");
  const [active,setActive ]=useState(true);
  const [ password,setPassword] = useState("");
  const [selectedData,setSelectedData] = useState(true);
  const [hideMyNetwork,setHideMyNetwork] = useState(true);
  const { user} = useContext(UserContext);
  

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {columnField: '', operatorValue: 'contains', value:user.name}
    ],
  });
  
  const cols = [
  {field:'id', headerName: 'id', hide: true },
  {field:'createdAt', headerName: 'Using From',width: 190, renderCell: (params) =>{
    return[
      new Date(params.row.createdAt).getDate()+"/"+(new Date(params.row.createdAt).getMonth()+1)+"/"+new Date(params.row.createdAt).getFullYear()+" "+new Date(params.row.createdAt).toLocaleTimeString()
    ];
  } },
  {field:'name', headerName: 'Name',width: 190 },
  {field:'accesstype', headerName: 'AccessType',width: 100,
  renderCell: (params) =>{
     switch(params.row.accesstype){
      case "0":
      return["Guest"];
       
      case "1":
      return  ["Employee"]
     
      case "2":
        return ["Supervisor"]
     
      case "3":
        return  ["Manager"]
    
      case "4":
        return  ["Director"]
     
      case "5":
        return ["Admin"]
       
      default :
      return  ["Unknown"]
     
     }
  },
  }, 
  {field:'ip', headerName: 'IP',width: 140,renderCell: (params) =>{return[
    params.row.ip==="::1"?"Test User":params.row.ip===user.ip?"My Network":params.row.ip
  ];}},
  
  {field:'device', headerName: 'Device',width: 1000, resizable: false },
  
  {field:'allowed', headerName: 'Allowed',width: 100,
  headerAlign: 'center',
  align: 'center', renderCell: (params) =>{
    return[
      <input type="checkbox" style={{border:"1px solid"}} checked={params.row.allowed} onChange={(e)=>window.confirm("Are you sure you want to "+(params.row.allowed?"block ":"allow ")+params.row.name+" on this Device?")?UpdateDevice(params.row.id,!params.row.allowed):null} /> 
    ];
  },},
  
  {width: 100, renderCell: (params) =>{
    return[
      <input type="button" style={{border:"1px solid", padding:"2px 6px"}} value="Delete" onClick={(e)=>window.confirm("Are you sure you want to delete this Device?")?UpdateDevice(params.row.id,"delete"):null} /> 
    ];
  },},

];

useEffect(()=>{
  getEmployees()

},[])



const getEmployees = ()=>{
  let apiURL=process.env.REACT_APP_API_URL + "/users/getalldevices" 
  let apiParams = {
      method:"GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {

        console.log(repos.data)
        setEmployees(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

const UpdateDevice = (id,allowed)=>{
    let apiURL =  process.env.REACT_APP_API_URL + "/users/updatedevice/"+id;
    let apiParams ={
      method:"POST",
      headers:{
        allowed,
        authtoken: localStorage.getItem("loggedintoken")
    }
    };
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=>{
      if(repos.data){
        setOpen(false)
        setIsLoading(false)
        
        props.showAlert("Device Updated Successfully","success")
        setTimeout(()=>{
          window.location.reload(false);
        
        },2000)
      }

    })
    .catch((err) => props.showAlert((''+err),"error"));
    }



const permissions =(data,allowed)=>{
  
data.map((activeUserId)=>{
  let apiURL =  process.env.REACT_APP_API_URL + "/users/updatedevice/"+activeUserId;
  let apiParams ={
    method:"POST",
    headers:{
      allowed,
      authtoken: localStorage.getItem("loggedintoken")
  }
  };
  fetch(apiURL,apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=>{
    if(repos.data){
     
      setIsLoading(false)
      
      props.showAlert("Device Updated Successfully","success")
      setTimeout(()=>{
        getEmployees()
      
      },2000)
    }

  })
  .catch((err) => props.showAlert((''+err),"error"));
  })
}





const rowData = (employees)?.filter(device=> hideMyNetwork?device.ip!==user.ip:true ).map((employee) => {

return{
  id:employee?._id,
  createdAt:employee?.createdAt,
  name:employee?.name,
  accesstype:employee?.accesstype,
  ip:employee?.ip,
  device:employee?.device,
  allowed:employee?.allowed,
};
});

  return (
  <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
  <div> <input type="checkbox" checked={hideMyNetwork} onChange={()=>setHideMyNetwork(!hideMyNetwork)} style={{marginTop:"12px",marginLeft:"12px"}}/>&nbsp;Hide My Network ({user.ip})
   
  <Button size='small' variant="contained" className='intern' title={"Delete Devices"} startIcon={<DeleteIcon />} style={{margin:"2px"}} onClick={()=>permissions(selectedData,"delete")}>Delete</Button> &nbsp;
  <Button size='small' variant="contained" className='intern' title={"Block Devices"} startIcon={<BlockIcon />} style={{margin:"2px"}} onClick={()=>permissions(selectedData,false)}>Block</Button> &nbsp;
   <Button size='small' variant="contained" className='intern' title={"Allow Devices"} startIcon={<GppGoodIcon/>} style={{margin:"2px"}}  onClick={()=>permissions(selectedData,true)}>Allow</Button>
   </div> 
  <br/>
      <div style={{height:'calc(100% - 50px)',position:"absolute", top:"70px",width: '100%'}}>
      <DataGrid rows={rowData} columns={cols} checkboxSelection components={{
          Toolbar: GridToolbar,
        }}
        onSelectionModelChange ={(e) =>setSelectedData(e)}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}        
        
        initialState={{
        sorting: {
        sortModel: [{ field: 'ip', sort: 'desc' }],
         },
       }}
        //onRowClick={(e)=>window.confirm("Are you sure you want to "+(e.row.allowed?"block ":"allow ")+e.row.name+" on this Device?")?UpdateDevice(e.row.id,!e.row.allowed):null}
          />
      </div>
</div>
  
  )
}

export default Devices