import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
// import './Users.css'
import SearchIcon from '@mui/icons-material/Search';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import GppGoodIcon from '@mui/icons-material/GppGood';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Update } from '@mui/icons-material';
import Tooltip from '@mui/material/Tooltip';

import EmailTemplatesPopup from './EmailTemplatesPopup';


const RecruiterVacanciesVerifyUI = (props) => {

    const [recruiterVacancies,setRecruiterVacancies] = useState([]);
    const [approvalStatus,setApprovalStatus] = useState("");


    const [filterModel, setFilterModel] = React.useState({
        items: [
        //   {columnField: '', operatorValue: 'contains', value:""}
        ],
      });

useEffect(()=>{

    getAllRecruiterVacancies()

},[])


      const getAllRecruiterVacancies = ()=>{
        let apiURL=process.env.REACT_APP_API_URL + "/recruiters/getallrecruitervacancies"; 
        let apiParams = {
            method:"GET",
            headers:{
              authtoken: localStorage.getItem("loggedintoken")
            }
           
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
            
            console.log(repos.data)
            setRecruiterVacancies(repos.data);
           
             
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }

 
      const updateApprovalStatus = (row,value)=>{

        // if((row.status==="approved" || value === "rejected") && parseInt(user.accesstype)<2)
        // {
        //   return alert("You don't have access to change result, contact your Manager to update!!")
        // }
      
        let apiURL =  process.env.REACT_APP_API_URL + "/recruiters/updateApprovalstatus/"+row.id;
            let apiParams ={
              method:"POST",
              headers:{
               status:value,
               authtoken: localStorage.getItem("loggedintoken")
            }

            };
            fetch(apiURL,apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=>{
              if(repos.data){
                
                props.showAlert("Approval Status Updated Successfully","success")
                setTimeout(()=>{
                    getAllRecruiterVacancies()
                },2000)
              }
        
            })
            .catch((err) => props.showAlert((''+err),"error"));
        }
        

    const cols = [
        {field:'id', headerName: 'id', hide: true },
        {field:'companyName', headerName: 'Company Name',width: 220,headerAlign:'center',align: 'center'},
        {field:'officeName', headerName: 'Office Name',width: 220,headerAlign:'center',align: 'center'},
        {field:'recruiterName', headerName: 'Recruiter Name',width: 220,headerAlign:'center',align: 'center'},
        {field:'jobTitle', headerName: 'Job Title',width: 220,headerAlign:'center',align: 'center'},
        {field:'jobDescription', headerName: 'Job Description',width: 250,headerAlign:'center',align: 'center', 
         renderCell: (params) => {
            return [<Tooltip title={params.value} >
                <div >{params.value}</div>
              </Tooltip>];
          },},
        {field:'ctc', headerName: 'CTC',width: 220,headerAlign:'center',align: 'center'},
        {field:'maxexp', headerName: 'Max Experience',width: 220,headerAlign:'center',align: 'center'},
        {field:'minexp', headerName: 'Min Experience',width: 220,headerAlign:'center',align: 'center'},
        {field:'skills', headerName: 'Skills',width: 220,headerAlign:'center',align: 'center', renderCell: (params) => {
            return [<Tooltip size="18px" title={params.value} >
                <div >{params.value}</div>
              </Tooltip>];
          },},
        {field:'status', headerName: 'Interview Status',width: 300,headerAlign:'center',align: 'center',
      
        renderCell: (params) => {
         return[
            <div>
             <FormControl variant="outlined" sx={{width: "260px",marginTop:"2px" }} size="small"  >
              <InputLabel id="status-select"  ></InputLabel>
              <Select
                labelId="status"
                id="status-select"
                
                value={params.row.status ||"inreview"} onChange={(e)=> {
       
       setApprovalStatus(e.target.value); 
       updateApprovalStatus(params.row,e.target.value);
       
       } } >
    
      <MenuItem value="inreview">In Review</MenuItem>
      <MenuItem value="approved">Approved</MenuItem>
      <MenuItem value="rejected">Rejected</MenuItem>
      <MenuItem value="onhold">On Hold</MenuItem>
              </Select>
            </FormControl>
            
      </div>
         ];
        },
      },  
      
      ];


      const rowData = (recruiterVacancies||[])?.map((rv) => {

        return{
          id:rv?._id,
          companyName:rv?.companyName,
          officeName:rv?.officeName,
          recruiterName:rv?.recruiterName,
          jobTitle:rv?.jobTitle,
          jobDescription:rv?.jobDescription,
          ctc:rv?.minCTC+" to "+rv?.maxCTC,
          maxexp:rv?.maxExpMonths
          ? (rv.maxExpMonths - (rv.maxExpMonths % 12)) / 12 +
            " Yr " +
            (rv.maxExpMonths % 12) +
            " Mn"
          : "0 Yr 0 Mn",
          minexp:rv?.minExpMonths
          ? (rv.minExpMonths - (rv.minExpMonths % 12)) / 12 +
            " Yr " +
            (rv.minExpMonths % 12) +
            " Mn"
          : "0 Yr 0 Mn",
          spocName:rv?.spocName,
          recruiterId:rv?.recruiterId,
          status:rv?.status,
          skills:rv?.skills,
          
        };
        });

        // function CustomTooltip(props) {
        //     const { value } = props;
        //     return (
        //       <Tooltip title={value}>
        //         <span>{value}</span>
        //       </Tooltip>
        //     );
        //   }

           
      
  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
        <div style={{height:'100%',width: '100%'}}>
      <DataGrid rows={rowData} columns={cols}  components={{
         Toolbar: GridToolbar,
        }}

        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
      
        // onRowDoubleClick={(e)=>handleOpen(e.row)}
          />
      </div>
    </div>
  )
}

export default RecruiterVacanciesVerifyUI
