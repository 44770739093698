import React, { useState, useEffect, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Divider } from "@mui/material";
import "./Applicants.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EventIcon from "@mui/icons-material/Event";
import CheckIcon from "@mui/icons-material/Check";
import ApplicantPopup from './ApplicantPopup';
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
// import DatePicker from '@mui/lab/DatePicker';
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { setSeconds } from "date-fns";
import { NavLink } from "react-router-dom";
import { id } from "date-fns/locale";
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import SearchIcon from '@mui/icons-material/Search';

const Applicants = (props) => {
  const style2 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 450,
    scrollY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber, setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [address, setAddress] = useState("");
  const [Skills, setSkills] = useState("");
  const [jobId, setJobId] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [applicantId,setApplicantId] = useState("")
  const [openApplicant, setOpenApplicant] = React.useState(false);
  const [applicant, setApplicant] = useState([]);
  const [companies, setCompanies] = useState();
  const [applicants, setApplicants] = useState();
  const [selectedRow, setSelectedRow] = useState();
  const [resume, setResume] = useState();
  const [reference, setReference] = useState("");
  const [Dob, setDob] = React.useState(new Date());
  const [ExpYears, setExpYears] = useState(0);
  const [ExpMonth, setExpMonths] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const { user } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [city, setCity] = useState("");
  const [area, setArea] = useState("");
  const [scheduleInterview, setScheduleInterview] = useState(true);
  const [resumeUrl, setResumeUrl] = useState("");
  const [data,setData] = useState("mobile")
  const [searchData,setSearchData] = useState("")  
  const [filterFunction,setFilterFunction] = useState("Default")
  const [pageState,setPageState] = useState({
    isLoading:false,
    data:[],
    total:0,
    page:1,
    pageSize:100
  })
  const [filterModel, setFilterModel] = React.useState({
    items: [
      // {
      //   columnField: "createdbyname",
      //   operatorValue: "contains",
      //   value: user.name,
      // },
    ],
  });

  const City = [{ label: "Bangalore" }];
  const Reference = [
    { label: 'Job Portal' },
    { label: "Apna App" },
    { label: "Linked In" },
    { label: "Facebook" },
    { label: "Naukri.com" },
    { label: "Monster.com" },
    { label: "Quickr.com" },
    { label: "Fresher.com" },
    { label: "Shine.com" },
    { label: "Job Hai" },
    { label: "Work India" },
    { label: "Walk-In" },
  ];
  const cols = [
    {
      field: "search",
      headerName: "",
      renderCell: (params) => {
        return [
          <div>
            <Button
              size="small"
              title="View Applicant"
              disabled={params.row.active === false}
              onClick={() => scheduleinterview(params.row)}
            >
              <EventIcon />
            </Button>
          </div>,
        ];
      },
    },
    { field: "id", headerName: "id", hide: true },
    { field: "name", headerName: "Name", width: 190 },
    { field: "education", headerName: "Education", width: 200 },
    { field: "experience", headerName: "Experience", width: 100 },
    { field: "latestHrName", headerName: "Assigned HR", width: 190 },
    { field: "reference", headerName: "Reffered By", width: 190 },
    { field: "gender", headerName: "Gender", width: 100 },
    { field: "skills", headerName: "Skills", width: 290 },
    { field: "mobile", headerName: "Mobile No", width: 140 },
    {
      field: "Resume",
      headerName: "Resume",
      width: 130,
      headerAlign: "center",
      renderCell: (params) => {
        return [
          <div>
            {params.row.fileName && params.row.fileName.length > 0 && (
              <Button
                size="small"
                className="btn"
                title="Download Resume"
                onClick={() => downloadApplicant(params.id, params.row.name)}
              >
                <FileDownloadIcon />
              </Button>
            )}
            {/* <input type="file" id="actual-btn" hidden/>
<Button size='small' className='btn' for="actual-btn" title="Upload Resume" onClick={(e)=>uploadApplicant(e)}><FileUploadIcon/></Button> */}

            <FileUploadIcon
              style={{ marginLeft: "5px", color: "cornflowerblue" }}
              onClick={(e) =>
                document
                  .getElementById("candidateResume" + params.row.id)
                  .click()
              }
            />
            <input
              id={"candidateResume" + params.row.id}
              type="file"
              placeholder="Upload Resume"
              style={{ display: "none" }}
              name="Resume"
              onChange={(e) =>
                uploadApplicant(e, params.row.id, params.row.name)
              }
            ></input>

            {params.row.resumeUrl && params.row.resumeUrl !== "" ? (
              <FileDownloadIcon
                style={{ marginLeft: "5px", color: "cornflowerblue" }}
                onClick={(e) =>
                  document
                    .getElementById("candidateResumeDownload" + params.row.id)
                    .click()
                }
              />
            ) : (
              ""
            )}
            {params.row.resumeUrl && params.row.resumeUrl !== "" ? (
              <a
                target="_blank"
                id={"candidateResumeDownload" + params.row.id}
                style={{ display: "none" }}
                name="Resume"
                href={params.row.resumeUrl}
               
              >""</a>
            ) : (
              ""
            )}
          </div>,
        ];
      },
    },
    {
      field: "active",
      headerName: "Status",
      headerAlign: "center",

      renderCell: (params) => {
        // console.log(params)
        return [
          <div>
            <Button
              size="small"
              title="Make Active"
              style={{
                display: params.row.active !== false ? "none" : "inline",
              }}
              onClick={() => ActiveApplicants(params.row)}
            >
              <CheckIcon />
            </Button>
            <Button
              size="small"
              title="Make Inactive"
              style={{
                display: params.row.active === false ? "none" : "inline",
              }}
              onClick={() => InactiveApplicants(params.row)}
            >
              <CancelOutlinedIcon />
            </Button>
          </div>,
        ];
      },
    },
  ];

 

  useEffect(() => {
   
    if (searchParams.get("jobid")) {
      setFilterModel({
        items: [],
      });
    }
    if (searchParams.get("applicantid")) {
      setFilterModel({
        items: [],
      });
    }
  }, []);

  const getAllApplicants = () => {

    setPageState(old=>({...old,isLoading:true}))
    let apiURL = searchParams.get("jobid")
      ? process.env.REACT_APP_API_URL + "/applicants/getapplicants/" + searchParams.get("jobid")
      :searchParams.get("applicantid")
      ? process.env.REACT_APP_API_URL + "/applicants/getapplicant/" + searchParams.get("applicantid")
      :process.env.REACT_APP_API_URL +
        "/applicants/getallapplicants/" + user._id + `?page=${pageState.page}&limit=${pageState.pageSize}`;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          if(searchParams.get("applicantid") || searchParams.get("jobid"))
          {setApplicant(repos.data);
            setPageState(old=>({...old, isLoading:false,data:repos.data, total :repos.data.count }))}
          else{setApplicant(repos.data && repos.data.applicants)
          
          setPageState(old=>({...old, isLoading:false,data:repos.data, total :repos.data.pagination.count }))
        }
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) =>props.showAlert(('why:'+err),"error"));
  };
  
  useEffect (()=>{
 

    if(filterFunction === "Default")
    {
    getAllApplicants()
    }
else{
  
SearchCandidate();
}
  },[pageState.page,pageState.pageSize])



//Outside UseEffect
const SearchCandidate = ()=>{
  setPageState(old=>({...old, isLoading:true}))
  let apiURL = process.env.REACT_APP_API_URL + "/applicants/getapplicantbysearch/"+searchData+`?page=${pageState.page}&limit=${pageState.pageSize}`;
  let apiParams = {
    method: "GET",
    headers:{
      searchBy:data,
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
          
            setApplicant(repos.data && repos.data.result);
            setPageState(old=>({...old, isLoading:false,data:repos.data, total :repos.data.pagination.count }))
 }else props.showAlert(""+(repos.message || repos),"error"); 
}) 
.catch((err) => props.showAlert((''+err),"error"));

}

  const checkduplicate = () => {
    if (mobile && mobile.length >= 10) {
      console.log("hi")
      let phone = mobile.toString().replace("-", "").slice(-10);
      // console.log(phone)
      let apiURL = process.env.REACT_APP_API_URL + "/applicants/phone/" + phone;
      let apiParams = {
        method: "GET",
      };
      fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            if (user.accesstype == 5) {
              let confirmation = window.confirm(
                repos.data.name +
                  " registered with " +
                  repos.data.mobile +
                  ". Do you want to create another registration with same number."
              );
              if (!confirmation) {
                setMobile("");
              } else {
                return;
              }
            } else {
              // props.showAlert((repos.data.name +
              //   " registered with " +
              //   repos.data.mobile +
              //   ". You Cannot add Candidate Again, kindly check with Admin before Creating!!!"),"error");
              alert(
                repos.data.name +
                  " registered with " +
                  repos.data.mobile +
                  ". You Cannot add Candidate Again, kindly check with Admin before Creating!!!"
              );
              setMobile("");
            }
          }
        })
        .catch((err) => props.showAlert((''+err),"error"));
    }
  };

  //   const getInterviews = ()=>{

  //     let apiURL=process.env.REACT_APP_API_URL + "/interview/"
  //     let apiParams = {
  //         method:"GET",
  //     };

  //     fetch(apiURL, apiParams)
  //     .then((res)=> (res.status===200 ? res.json() : res.text()))
  //     .then((repos)=> {
  //         if (repos.data) {
  //           // console.log(repos.data)
  //           setInterview(repos.data)
  //         }else alert(repos.message || repos);
  //     })
  //     .catch((err) => alert(err));
  //   }
  //   // alert(JSON.stringify(interview))

  //   useEffect(()=>{
  //   getInterviews()

  //   },[])

  //Download Resume
  const downloadApplicant = (id, name) => {
    let apiURL = process.env.REACT_APP_API_URL + "/applicants/resume/" + id;
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // //console.log(repos.data);
          let type = repos.data.fileType;
          let bytes = new Uint8Array(repos.data.resume.data.data);
          let newLink = saveByteArray(
            name +
              "." +
              repos.data.fileName.split(".")[
                repos.data.fileName.split(".").length - 1
              ],
            bytes,
            type
          );
          newLink.link.click();
          // alert("File Downloaded");
          props.showAlert("File Downloaded Successfully", "success");
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };
  function saveByteArray(reportName, byte, type) {
    let blob = new Blob([byte], { type: type });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);

    let fileName = reportName;
    link.download = fileName;

    let newLink = {
      name: fileName,
      link: link,
    };
    return newLink;
  }

  //upload Resume
  // const uploadApplicant = (e) =>{
  //   let formData = new FormData();
  //   formData.append("resume",e.target.files[0]);
  //   setResume(formData);
  // }
  const uploadApplicant = async (e, applicantId, applicantName) => {
    var file = e.target.files[0]; //the file
    var reader = new FileReader(); //this for convert to Base64
    reader.readAsDataURL(e.target.files[0]); //start conversion...
    reader.onload = function (e) {
      //.. once finished..
      var rawLog = reader.result.split(",")[1]; //extract only thee file data part
      var dataSend = {
        dataReq: { data: rawLog, name: applicantName, type: file.type },
        fname: "uploadFilesToGoogleDrive",
      }; //preapre info to send to API
      fetch(
        process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }
      ) //send to Api
        .then((res) => res.json())
        .then((data) => {
          //See response
          RetrieveResume(data, applicantId);
          props.showAlert("Resume Uploaded Successfully", "success");
        })
        .catch((e) => props.showAlert((e),"error")); // Or Error 
    };
  };
  const RetrieveResume = (data, applicantId) => {
    let apiURL =
      process.env.REACT_APP_API_URL +
      "/applicants/saveResumeUrl/" +
      applicantId;
    let apiParams = {
      method: "POST",
      headers: {
        resumeurl: data.url,
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setResumeUrl(data.url);
        }
      })
      .catch((e) => props.showAlert((e),"error"));
  };

  function renameFile(originalFile, newName) {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  const scheduleinterview = (row) => {
    window.location.href = "/interviewdetails?applicantid=" + row.id;
  };

  // setSelectedRow({jobId:row.jobid,name:row.name,round:row.rounds,mobile:row.mobile,jobTitle:row.jobtitle,applicantId:row.id,email:row.mail,companyName:row.companyname,status:row.status})

  const handleCloseView = () => setOpenApplicant(false);

  //Accept Applicants
  const ActiveApplicants = (row) => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/interview/activeApplicant/" + row.id;
    let apiParams = {
      method: "POST",
      headers: {
        // applicantid:row.applicantid,
        active: true,
        authtoken: localStorage.getItem("loggedintoken")
      },
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // alert("Applicant Activated");
          props.showAlert("Applicant Activated", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };

  //Inactive Applicants
  const InactiveApplicants = (row) => {
    let apiURL =
      process.env.REACT_APP_API_URL + "/interview/inactiveapplicant/" + row.id;
    let apiParams = {
      method: "POST",
      headers: {
        // applicantid:row.applicantid,
        active: false,
        authtoken: localStorage.getItem("loggedintoken")
      },
    };

    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // alert("Applicant Inactive");
          props.showAlert("Applicant Inactived", "warning");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);

          //console.log(repos.data);
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };

  //Add Applicant Details
  const addApplicants = (job) => {
    setJobTitle(job.jobtitle);
    setJobId(job._id);
    setCompanyName(job.companyname);
    setApplicantId("")
    setOpenApplicant(true);
  };

  // Edit Applicant Details
  const editApplicants = (row) => {
    setId(row.id || "");
    setApplicantId(row.id|| "");
    // setName(row.name || "");
    // setMail(row.email || "");
    // setMobile(row.mobile || "");
    // setWhatsNumber(row.number || "");
    // setDob(row.dob);
    // setAddress(row.address || "");
    // setSkills(row.skills || "");
    // setGender(row.gender || "");
    // setExpYears(row.experience.split(" ")[0] || 0);
    // setExpMonths(row.experience.split(" ")[2] || 0);
    // setCity(row.city || "");
    // setArea(row.area || "");
    // setReference(row.reference || "");
    setOpenApplicant(true);
  };

  const resetApplicant = () => {
    setId("");
    setName("");
    setMail("");
    setMobile("");
    setWhatsNumber("");
    setDob(new Date());
    setAddress("");
    setCity("");
    setSkills("");
    setGender("");
    setExpYears(0);
    setExpMonths(0);
    setReference("");
  };

  const handleClose = () => {
    setOpenApplicant(false);
    resetApplicant();
  };

  const handleUpload = (e) => {
    let formData = new FormData();
    formData.append("resume", e.target.files[0]);
    setResume(formData);
  };

  //functions
  // const addApplicant = () => {
  //   if (gender.length == 0) {
  //     props.showAlert("Please Select Gender","error");
  //     return;
  //   }
  //   setIsLoading(true);
  //   let apiURL = process.env.REACT_APP_API_URL + "/applicants/addapplicant";
  //   let apiParams = {
  //     method: "POST",
  //     headers: {
  //       name: name,
  //       email: mail,
  //       mobile: mobile,
  //       number: whatsNumber,
  //       dob: Dob,
  //       city: city,
  //       area: area,
  //       experience: parseInt(ExpYears) * 12 + parseInt(ExpMonth),
  //       skill: encodeURIComponent(Skills),
  //       gender: gender,
  //       jobid: jobId,
  //       jobtitle: jobTitle,
  //       companyname: companyName,
  //       address: encodeURIComponent(address),
  //       reference: encodeURIComponent(reference),
  //       createdbyid: user._id,
  //       createdbyname: user.name,
  //     },
  //     body: resume,
  //   };
  //   // console.log(apiParams)
  //   fetch(apiURL, apiParams)
  //     .then((res) => (res.status === 200 ? res.json() : res.text()))
  //     .then((repos) => {
  //       if (repos.data) {
  //         setOpenApplicant(false);
  //         setIsLoading(false);
  //         props.showAlert("Applicant Added Successfully", "success");
  //         // alert("Applicant Added Successfull");
  //         if (scheduleInterview) {
  //           setTimeout(() => {
  //             window.location.href = "/jobs?applicantid=" + repos.data._id;
  //           }, 1000);
  //         } else {
  //           setTimeout(() => {
  //             window.location.reload(false);
  //             // getAllApplicants()
  //           }, 1500);
  //         }
  //         //console.log(repos.data);
  //       } else {
  //         props.showAlert(""+(repos.message || repos),"error");
  //         setIsLoading(false);
  //       }
  //     })
  //     .catch((err) => {
  //       props.showAlert((''+err),"error")
  //       setIsLoading(false);
  //     });
  // };

  //edit applicant
  const editApplicantDetails = (id) => {
    setIsLoading(true);
    let apiURL =
      process.env.REACT_APP_API_URL + "/applicants/editapplicant/" + id;
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        email: mail,
        mobile: mobile,
        number: whatsNumber,
        dob: Dob,
        city: city,
        area: area,
        experience: parseInt(ExpYears) * 12 + parseInt(ExpMonth),
        skill: encodeURIComponent(Skills),
        gender: gender,
        address: encodeURIComponent(address),
        reference: encodeURIComponent(reference),
        updatedById: user._id,
        updatedByName: user.name,
        authtoken: localStorage.getItem("loggedintoken")
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpenApplicant(false);
          setIsLoading(false);
          props.showAlert("Applicant Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
            // getAllApplicants()
          }, 2000);

          //console.log(repos.data);
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };

  //Get Companies
  const getCompanies = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/companies";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setCompanies(repos.data);
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };

 




  const rowData = applicant?.map((applicant) => {
    // alert(JSON.stringify(applicant))
    return {
      id: applicant?._id,
      resumeUrl: applicant?.resumeUrl,
      name: applicant?.name,
      education: applicant?.education || "",
      mobile: applicant?.mobile,
      city: applicant?.city,
      area: applicant?.area,
      number: applicant?.number,
      reference: applicant?.reference,
      address: applicant?.address,
      experience: applicant.experience
        ? (applicant.experience - (applicant.experience % 12)) / 12 +
          " Yr " +
          (applicant.experience % 12) +
          " Mn"
        : "0 Yr 0 Mn",
      skills: applicant.skill || "",
      dob: new Date(applicant?.dob).toLocaleDateString(),
      gender: applicant?.gender,
      jobtitle: applicant?.jobtitle,
      companyname: applicant?.companyname,
      rounds: applicant?.rounds,
      status: applicant?.status,
      active: applicant?.active,
      fileName: applicant?.fileName,
      jobsappllied: applicant?.jobsapplied,
      pendingInterviews: applicant?.pendinginterviews,
      latestHrName: applicant?.latestHrName,
      reference: applicant?.reference, 
    };
  });

  return (
    <div style={{position:"absolute",width:"100%",top:"100px"}}>
    <div style={{textAlign:"center",margin:"2px"}}>
     <select name="Search" id="search" style={{border:"1px solid", padding:"2px 6px"}} value={data} onChange={(e)=>setData(e.target.value)}>
  <option value="mobile">Mobile</option>
  <option value="name">Name</option>
  <option value="reference">Reference</option>
  <option value="language">Language</option>
 
  {/* <option value="bookmark">Bookmarked</option> */}
</select> &nbsp;
 <input type="search" style={{border:"1px solid", padding:"2px 6px"}} placeholder='Search' id="gsearch" name="gsearch" value={searchData}  onChange={(e)=>setSearchData(e.target.value)}/>&nbsp;
 <Button size='small' variant="contained" title="search" onClick={()=>{setFilterFunction("Search");setPageState({
    isLoading:false,
    data:[],
    total:0,
    page:1,
    pageSize:100
  });SearchCandidate()}}><SearchIcon/></Button> 
 &nbsp;
      <Button
        size="small"
        variant="contained"
        //className="intern"
        title="Add Applicant"
        onClick={() => addApplicants(rowData)}
      >
       <PersonAddIcon/>
      </Button>
      </div>
      <br />
     

      <div style={{ height: "calc(100vh - 160px)", width: "100%" }}>
        <DataGrid
          rows={rowData}
        
          components={{
            Toolbar: GridToolbar,
          }}
          rowCount = {pageState.total}
          loading = {pageState.isLoading}
          // rowsPerPageOptions={[30,50,80]}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          onPageChange={(newPage)=>{
          setPageState(old=>({...old,page: newPage +1 }))
          }}
          onPageSizeChange = {(newPageSize)=>setPageState(old=>({...old, pageSize:newPageSize}))}

          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          columns={cols}
          onRowDoubleClick={(e) => editApplicants(e.row)}
        />
      </div>
      {/* <ScheduleInterview openApplicant={OpenShedule} showAlert={props.showAlert} interview={selectedRow} close={()=>setOpenScedule(false)} companies={companies}/> */}
      {/* <Modal
        open={openApplicant}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ overflow: "scroll", textAlign: "center" }}
      >
        <Box sx={style2}>
          <div>
            <Typography id="modal-modal-description">
              <Container style={{ padding: "0px" }}>
                <div className="content1">
                  <div style={{ textAlign: "center" }}>
                    <h4 className="fnt3">
                      <b>{id.length > 0 ? name : "ADD APPLICANT DETAILS"}</b>{" "}
                      <Button
                        disabled={isLoading}
                        title="Close"
                        variant="Standard"
                        style={{
                          float: "right",
                          right: 0,
                          top: 0,
                          position: "absolute",
                          height: 28,
                          width: 5,
                        }}
                        onClick={() => handleClose()}
                      >
                        <CloseIcon />
                      </Button>
                    </h4>
                  </div>

                  <table border="0">
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        <TextField
                          id="mobile"
                          sx={{
                            width: 180,
                            marginRight: "auto",
                            marginTop: "10px",
                          }}
                          type="number"
                          size="small"
                          label="Mobile No"
                          variant="outlined"
                          value={mobile}
                          onBlur={() => checkduplicate()}
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                        />

                        <TextField
                          id="outlined-size-small"
                          sx={{
                            width: 180,
                            marginLeft: "auto",
                            marginTop: "10px",
                          }}
                          size="small"
                          label="Name"
                          variant="outlined"
                          value={name || ""}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        <FormControl
                          variant="outlined"
                          sx={{
                            minWidth: 180,
                            marginRight: "auto",
                            marginTop: "10px",
                          }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Gender
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-labell"
                            id="demo-simple-select"
                            sx={{ minWidth: 160 }}
                            value={gender}
                            onChange={(e) => {
                              setGender(e.target.value);
                            }}
                          >
                            <MenuItem selected disabled></MenuItem>

                            <MenuItem value="male">Male</MenuItem>
                            <MenuItem value="female">Female</MenuItem>
                            <MenuItem value="others">Others</MenuItem>
                          </Select>
                        </FormControl>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            width: 180,
                            marginLeft: "auto",
                            marginTop: "10px",
                          }}
                          size="small"
                          label="email"
                          variant="outlined"
                          value={mail || ""}
                          onChange={(e) => {
                            setMail(e.target.value);
                          }}
                        />{" "}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            width: 180,
                            marginRight: "auto",
                            marginTop: "10px",
                          }}
                          size="small"
                          label="Whatsapp No"
                          variant="outlined"
                          value={whatsNumber}
                          onChange={(e) => {
                            setWhatsNumber(e.target.value);
                          }}
                        />

                          <MobileDatePicker
                            sx={{
                              width: 180,
                              marginLeft: "auto",
                              marginTop: "10px",
                            }}
                            label="DOB"
                            inputFormat="MM/dd/yyyy"
                            value={Dob}
                            onChange={(value) => {
                              setDob(value);
                            }}
                            renderInput={(params) => (
                              <TextField
                                sx={{
                                  width: 180,
                                  marginLeft: "auto",
                                  marginTop: "10px",
                                }}
                                size="small"
                                {...params}
                              />
                            )}
                          />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        Experience :
                        <TextField
                          sx={{ m: 0.5, width: "10ch" }}
                          label="Years"
                          variant="outlined"
                          size="small"
                          value={ExpYears}
                          onChange={(e) => {
                            setExpYears(e.target.value);
                          }}
                        />
                        <TextField
                          sx={{ m: 0.5, width: "10ch" }}
                          label="Months"
                          variant="outlined"
                          size="small"
                          value={ExpMonth}
                          onChange={(e) => {
                            setExpMonths(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        <FormControl
                          variant="outlined"
                          sx={{
                            minWidth: 180,
                            marginRight: "auto",
                            marginTop: "10px",
                          }}
                          size="small"
                        >
                          <Autocomplete
                            disablePortal
                            id="txtCity"
                            options={City}
                            value={city || ""}
                            onChange={(event, value) => setCity(value.label)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                sx={{ width: 180, marginRight: "auto" }}
                                size="small"
                                label="City"
                                value={city || ""}
                                onChange={(e) => {
                                  setCity(e.target.value);
                                }}
                              />
                            )}
                          />
                        </FormControl>
                        <TextField
                          id="outlined-basic"
                          sx={{
                            width: 180,
                            marginLeft: "auto",
                            marginTop: "10px",
                          }}
                          size="small"
                          label="Area"
                          variant="outlined"
                          value={area}
                          onChange={(e) => {
                            setArea(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        <TextField
                          id="outlined-multiline-flexible"
                          sx={{ minWidth: 350 }}
                          size="small"
                          label="Address"
                          multiline
                          rows={2}
                          value={address || ""}
                          onChange={(e) => {
                            setAddress(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        <TextField
                          id="outlined-basic-normal"
                          multiline
                          rows={2}
                          sx={{ minWidth: 350 }}
                          size="small"
                          label="Skills"
                          variant="outlined"
                          value={Skills || ""}
                          onChange={(e) => {
                            setSkills(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                    {id.length > 0 ? null : (
                      <tr>
                        <td colSpan={1} style={{ textAlign: "center" }}>
                          <InputLabel>Resume Upload : </InputLabel>

                          <input
                            id="myFile"
                            type="file"
                            placeholder="Upload Resume"
                            name="Resume"
                            onChange={(e) => handleUpload(e)}
                          />
                        </td>
                      </tr>
                    )}
                    <tr>
                      <td colSpan={1} style={{ textAlign: "center" }}>
                        <Autocomplete
                          disablePortal
                          id="txtReference"
                          freeSolo
                          options={Reference}
                          sx={{ width: 300, marginLeft: "30px" }}
                          value={reference || ""}
                          onChange={(event, value) => setReference(value.label)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              sx={{ minWidth: 300, marginLeft: "30px" }}
                              size="small"
                              label="Reference"
                              value={reference || ""}
                              onChange={(e) => {
                                setReference(e.target.value);
                              }}
                            />
                          )}
                        />
                      </td>
                    </tr>
                    <Checkbox
                      {...label}
                      checked={scheduleInterview}
                      onChange={(e) => setScheduleInterview(e.target.checked)}
                    />
                    Schedule Interview
                    <div style={{ textAlign: "center" }}>
                      <Button
                        disabled={isLoading}
                        type="reset"
                        title={"Edit Add "}
                        variant="contained"
                        onClick={() =>
                          id.length > 0
                            ? editApplicantDetails(id)
                            : addApplicant()
                        }
                      >
                        {id.length > 0 ? "Edit " : "Add Applicant "}
                      </Button>{" "}
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {isLoading ? <CircularProgress /> : ""}
                    </div>
                  </table>
                </div>
              </Container>
            </Typography>
          </div>
        </Box>
      </Modal> */}
     <ApplicantPopup openApp={openApplicant} showAlert={props.showAlert} id={applicantId} closeApp={()=>{setOpenApplicant(false);}} /> 
    </div>
  );
};

export default Applicants;
