import React, { useState, useEffect, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Divider } from "@mui/material";
import "./OfficeLocations.css";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import EventIcon from "@mui/icons-material/Event";
import CheckIcon from "@mui/icons-material/Check";
import ScheduleInterview from "./ScheduleInterview";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
// import DatePicker from '@mui/lab/DatePicker';
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
import { id } from "date-fns/locale";
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import States from "./States.json";

const OfficeLocations = (props) => {
  const OffLoc = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 500,
    scrollY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  //UseStates

  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");

  const [open, setOpen] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [state, setState] = useState("Karnataka");
  const [city, setCity] = useState("Bangalore");
  const [area, setArea] = useState("");
  const [company, setCompany] = useState("");
  const [office, setOffice] = useState("");

  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locations, setLocations] = useState([]);
  const [mapUrl,setMapUrl] = useState("");
  const [clawPeriod,setClawPeriod] = useState(0);
  const [img,setImg] = useState("");
  const [profilePic,setProfilePic]= useState("");
  const { user } = useContext(UserContext);

  const [filterModel, setFilterModel] = React.useState({
    items: [{ columnField: "", operatorValue: "contains", value: user.name }],
  });

  const cols = [
    { field: "id", headerName: "id", hide: true },
    { field: "state", headerName: "State", width: 190 },
    { field: "city", headerName: "City", width: 120 },
    { field: "company", headerName: "Company", width: 200 },
    
    { field: "office", headerName: "Office", width: 200 },
   
    { field: "latitude", headerName: "Latitude", width: 120, headerAlign:'center',  align:"center"},
    { field: "longitude", headerName: "Longitude", width: 120 , headerAlign:'center', align:"center"},
    { field: "clawPeriod", headerName: "Claw Period", width: 100, align:"center", headerAlign:'center' },
    {
      field: "active",
      headerName: "Active",
      headerAlign:'center',
      align:"center",
  
      width: 100,
      renderCell: (params) => {
        return [<input type="checkbox" checked={params.row.active} />];
      },
    },
  ];

  useEffect(() => {
    getLocations();
  }, []);

  const getLocations = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/getallofficelocations";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //console.log(repos.data)
          setLocations(repos.data);
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };

  const handleImageUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    handleFileSelect(e.target.files[0]);

    setProfilePic(e.target.files[0]);
   
  };

   //Upload Image
   const UploadImageToDrive = async (officeLocationId) =>{
     
    var file = profilePic //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(profilePic) //start conversion...
    reader.onload = function (e) { //.. once finished..
      var rawLog = reader.result.split(',')[1]; //extract only thee file data part
      var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
     fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
        .then(res => res.json()).then((data) => {
          //See response
        
           SaveProfilePic(data,officeLocationId)
          props.showAlert("Office Logo Uploaded Successfully","success")
  
        }).catch(err => console.log(err)) // Or Error in console
    }
  }

  const SaveProfilePic = (data,officeLocationId)=>{
    if(officeLocationId){
    
            let apiURL=process.env.REACT_APP_API_URL + "/officelocations/saveOfficeLogoUrl/" + officeLocationId;
            let apiParams = {
                method:"POST",
                headers:{
                  officelogourl:data.url,
                  authtoken: localStorage.getItem("loggedintoken")
                }
          }
          fetch(apiURL, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
               
            
              }
            })
            .catch(e => console.log(e)) 
      } 
          }



  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

 

  const UpdateOfficeLocations = (id) => {

    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/updateOfficeLocations/" + id;
    let apiParams = {
      method: "POST",
      headers: {
        state: state,
        city: city,
        company:company,
        office:office,
        // area: area,
        latitude: latitude,
        longitude: longitude,
        mapurl:mapUrl,
        clawperiod:clawPeriod,
        active: active,
        authtoken: localStorage.getItem("loggedintoken")
      },
      body:profilePic,
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          profilePic && UploadImageToDrive(id)
          props.showAlert("Location Updated Successfully", "success");
          setTimeout(() => {
            getLocations();
          }, 2000);
        }
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };

  const addOfficeLocations = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/addofficeLocations";
    let apiParams = {
      method: "POST",
      headers: {
        state: state,
        city: city,
        // area: area,
        company:company,
        office:office,
        latitude: latitude,
        longitude: longitude,
        mapurl:mapUrl,
        clawperiod:clawPeriod,
        active: active,
        authtoken: localStorage.getItem("loggedintoken")

      },
      body:profilePic,
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          profilePic && UploadImageToDrive(repos.data._id)
          
          props.showAlert("Office Locations Added Successfully", "success");
          setTimeout(() => {
            getLocations();
          }, 2000);
        }
        else{
               props.showAlert(repos.message||repos||"Error","error");
        }
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };

  const addOffLoc = () => {
    setOpen(true);
  };

  const editLocations = (row) => {
     
    setId(row.id);
    setActive(row.active);
    setState(row.state);
    setCity(row.city);
    // setArea(row.area);
    setCompany(row.company);
    setOffice(row.office);
    setLongitude(row.longitude);
    setLatitude(row.latitude);
    setMapUrl(row.mapUrl);
    if(row.officeLogoUrl && row.officeLogoUrl.length>50){
      setImg("https://drive.google.com/uc?export=view&id="+row.officeLogoUrl.split('/')[5])
      // console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                    }
    
    setClawPeriod(parseInt(row.clawPeriod));
    setOpen(true);
  };


  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }


  const handleClose = () => {
    setOpen(false);
    resetApplicant();
  };

  const resetApplicant = () => {
    setId("");
  
    setState("");
    setCity("");
    
    setCompany("");
    setOffice("");
    setLongitude("");
    setLatitude("");
    setClawPeriod(0);
    setMapUrl("");
  };

  const rowData = locations?.map((loc) => {
    return {
      id: loc?._id,
      state: loc?.state,
      city: loc?.city,
   
      officeLogoUrl:loc?.officeLogoUrl,
      company:loc?.company,
      office:loc?.office,
      longitude: loc?.longitude,
      latitude: loc?.latitude,
      mapUrl:loc?.mapUrl,
      clawPeriod:loc.clawPeriod?loc.clawPeriod:0,
      active: loc?.active,
    };
  });

  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
      <Button
        size="small"
        variant="contained"
        className="intern"
        title={"Add Office Location"}
        onClick={() => addOffLoc()}
      >
        + Add Office Location
      </Button>
      <br />
      <br />
      <div style={{ height: 'calc(100% - 50px)', width: "100%" }}>
        <DataGrid
          rows={rowData}
          columns={cols}
          components={{
            Toolbar: GridToolbar,
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          onRowClick={(e) => editLocations(e.row)}
        />

        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={OffLoc}>
            <div>
              <Typography id="modal-modal-description">
                <Container style={{ padding: "0px" }}>
                  <div className="OffLoc">
                    <div>
                      {/* <h4  className="fnt"><b>{id.length>0?name:"USER DETAILS"}</b> <Button disabled={isLoading} title="Close" variant="Standard"  onClick={()=>handleClose()} ><CloseIcon /></Button></h4>  */}
                      <h4 className="fnt2">
                        <b>ADD OFFICE LOCATIONS</b>

                        <Button
                          title="Close"
                          size="small"
                          variant="Standard"
                          sx={{
                            bgcolor: "red",
                            float: "right",
                            right: 0,
                            top: 0,
                            position: "absolute",
                            height: 28,
                            width: 5,
                          }}
                          onClick={() => handleClose()}
                        >
                          {" "}
                          <CloseIcon
                            style={{ height: "25px", width: "25px" }}
                          />{" "}
                        </Button>
                      </h4>
                    </div>
                    <br />
                    <div style={{textAlignLast:"center"}}>
                        <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                      <img width="100" height="100" src={img?img:"default.jpg"} alt="Reload?" style={{borderRadius:"75px",border:"1px solid black",objectFit:"contain"}}/>
                      </label>
                    </div>
                    <br/>
                    <div style={{ textAlign: "center", margin: "2px" }}>
                      <table>
                        <tr>
                          <td>
                            <FormControl
                              variant="outlined"
                              sx={{ minWidth: 220 }}
                              size="small"
                            >
                              <InputLabel id="state-label">State</InputLabel>
                              <Select
                                labelId="state-name-labell"
                                id="State"
                                value={state}
                                // defaultValue={companyName}
                                onChange={(e) => {
                                  setState(e.target.value);
                                }}
                              >
                                {States &&
                                  States.map((state) => (
                                    <MenuItem value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </td>
                          <td>
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              label="City"
                              variant="outlined"
                              value={city || ""}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              label="Company"
                              variant="outlined"
                              value={company || ""}
                              onChange={(e) => {
                                setCompany(e.target.value);
                              }}
                            />
                    
                          </td>
                          <td>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              label="Office"
                              variant="outlined"
                              value={office || ""}
                              onChange={(e) => {
                                setOffice(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <TextField
                              id="outlined-basic"
                              size="small"
                              label="latitude"
                              variant="outlined"
                              value={latitude}
                              onChange={(e) => {
                                setLatitude(e.target.value);
                              }}
                            />
                           
                          </td>
                          <td>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              label="longitude"
                              variant="outlined"
                              value={longitude || ""}
                              onChange={(e) => {
                                setLongitude(e.target.value);
                              }}
                            />
                          </td>{" "}
                        </tr>
                       
                        <tr>
                        <td colSpan={2} style={{textAlign:"center"}}>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              sx={{ width: 440 }}
                              label="Location Link"
                              variant="outlined"
                              value={mapUrl || ""}
                              onChange={(e) => {
                                setMapUrl(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{textAlign:"center"}}>
                            Active:{" "}
                            <input
                              type="checkbox"
                              checked={active}
                              onChange={(e) => setActive(e.target.checked)}
                            />
                          </td>
                          <td colSpan={1} style={{textAlign:"center"}}>
                       
        <InputLabel id="demo-simple-select-outlined-label" >Claw Period</InputLabel>
        <TextField disabled={props.type==="View"}  id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small"  variant="outlined"  value={clawPeriod} onChange={(e)=> {
          
          setClawPeriod(e.target.value);
        }}/>
      
                          </td>
                        
                        </tr>
                      </table>
                    </div>
                    <br />

                    <div style={{ textAlign: "center", marginBottom: "5px" }}>
                      <Button
                        disabled={isLoading}
                        type="reset"
                        title={"Edit Add "}
                        variant="contained"
                        onClick={() =>
                          id.length > 0
                            ? UpdateOfficeLocations(id)
                            : addOfficeLocations()
                        }
                      >
                        {id.length > 0 ? "Edit" : "Add"}
                      </Button>{" "}
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {isLoading ? <CircularProgress /> : ""}
                    </div>
                  </div>
                </Container>
              </Typography>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
};

export default OfficeLocations;
