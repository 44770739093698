import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import './InterviewDetails.css'
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { UserContext } from './UserContext';
import { useSearchParams } from 'react-router-dom';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import  {MenuItem,Select,Switch} from '@mui/material';


const Today = (props) => {
  
const [searchParams] = useSearchParams();
const [interview, setInterview] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [open, setOpen] = React.useState(false);
const [cards, setCards] = React.useState(true);
const [Rounds, setRounds] = useState();
const [selectedRow,setSelectedRow] = useState();
const [offices,setOffices] = useState([]);
const { user, setUser} = useContext(UserContext);
const [companyName,setCompanyName] = useState("all");
const [selectedData, setSelectedData] = useState([]);
const [nameNumber,setNameNumber] = useState([]);

const [filterModel, setFilterModel] = React.useState({
  items: [
    // {columnField: 'createdbyname', operatorValue: 'contains', value:user.name}
  ],
});

const style3 = {
  position: 'absolute',
  top: '50%',

  transform: 'translate(87%, -50%)',
  height: 489,
  width: 533,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 10,

};

const cols = [
  {field:'action', headerName: '',width: 80, headerAlign:'center',

  renderCell: (params) => {
   return[
      <div>
       <Button size='small' title="Schedule"  onClick={()=>showApplicant(params.row)}><PersonSearchIcon/></Button>
      
</div>
   ];
  },
},  
  {field:'name', headerName: 'Name',width: 190 },
  {field:'createdbyname', headerName: 'Created By',width: 190,hide: true },
  {field:'mobile', headerName: 'Mobile No',width: 140},
  {field:'hrname', headerName: 'HR Name',width: 190},
  {field:'englishrating', headerName: 'English Rating',width: 180,
  renderCell: (params) =>{
    switch(params.row.englishrating){

     case "1":
     return  ["E - Pathetic"]
    
     case "2":
       return ["D - Bad"]
    
     case "3":
       return  ["C - Average"]
   
     case "4":
       return  ["B - Good"]
    
     case "5":
       return ["A - Excellent"]
      
     default :
     return  [""]
    
    }
 },
},
  {field:'communicationrating', headerName: 'Communication Rating',width: 180,renderCell: (params) =>{
    switch(params.row.communicationrating){
      case "1":
        return  ["E - Pathetic"]
       
        case "2":
          return ["D - Bad"]
       
        case "3":
          return  ["C - Average"]
      
        case "4":
          return  ["B - Good"]
       
        case "5":
          return ["A - Excellent"]   
      
     default :
     return  [""]
    
    }
 },
},

  {field:'jobtitle', headerName: 'Job Title',width: 200},
  {field:'companyname', headerName: 'Company Name',width: 200},
//   {field:'Resume', headerName: 'Resume',width: 150,
//   renderCell: (params) =>{

// return[
// <div>

// <Button size='small' className='btn' title="Reject" onClick={()=>downloadApplicant(params.id)}><FileDownloadIcon/></Button></div>
// ];
// },
// },  
  {field:'interviewername', headerName: 'Interviewer Name',width: 190},
  
  {field:'interviewdate', headerName: 'Interview Date',width: 140},
  // {field:'interviewtime', headerName: 'Interview Time',width: 140},
  {field:'rounds', headerName: 'Rounds',width: 150},
  {field:'interviewlocation', headerName: 'Interview Location',width: 200},
  {field:'status', headerName: 'Status',width: 110},
  {field:'bookmark', headerName: 'Bookmarked', renderCell: (params) =>{

    return[
     <div>
    
    <Checkbox {...label} disabled checked={params.row.bookmark} />{params.row.bookmarkcomment}
    </div>
    ];
     },
    }
  ];
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const label1 = { inputProps: { 'aria-label': 'Checkbox' } };

  // useEffect(()=>{
   
  //   getInterviewsByStatus(companyName)
  //   // getInterviews()
  //   },[companyName])
  
    useEffect(()=>{  
      getOffices();
      getInterviewsByStatus('all')
    },[])

  // const getInterviews = ()=>{
    
  //   let apiURL=process.env.REACT_APP_API_URL + "/interview/todayscandidate";
  //   let apiParams = {
  //       method:"GET", 
  //   };
    
  //   fetch(apiURL, apiParams)
  //   .then((res)=> (res.status===200 ? res.json() : res.text()))
  //   .then((repos)=> {
  //       if (repos.data) {
  //         // console.log(repos.data)
  //         setInterview(repos.data)
  //       }else props.showAlert(""+(repos.message || repos),"error");
  //   }) 
  //   .catch((err) => props.showAlert((''+err),"error"));
  // }



  
  const getInterviewsByStatus = (value)=>{
   
    let apiURL=process.env.REACT_APP_API_URL + "/interview/todayscandidatebystatus/"+value;
    let apiParams = {
        method:"GET", 
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        }
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // console.log(repos.data)
          setInterview(repos.data)
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const scheduleinterview = (row) =>{

    setSelectedRow(row)
        setOpenScedule(true);
      }
    
  const handleCloseView = () => setOpen(false)


  
  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }


  //Get Company names
  const getOffices = () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/getOfficelocations";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              //console.log(repos.data);
              if(repos.data.length>0)
              {
  
              setOffices(repos.data)
              
            }}else props.showAlert(""+(repos.message || repos),"error");  
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }


//Send to Whatsapp
const whatsappLinups = (company)=>{

let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+user.phone.trim().replace(' ','').substring(user.phone.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day All,%0a%0aPlease find the lineups for today for "+company+".%0a%0a"+rowData.filter(row=>row.companyname===company).map(candidate=> candidate.name+' - '+candidate.mobile+'%0a('+candidate.interviewtime+(parseInt(candidate.interviewtime.split(':')[0])<12?' am':' pm')+')').join('%0a')+"%0a%0aWe will add more candidates to the list once candidates confirm."

    
const newWindow = window.open(apiURL, '_blank')

}


  const rowData = (interview)?.map((interview) => {
let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);

  return{
  id: interview?._id,  //applicant id
  applicantid:interview?.applicantId, 
  jobid:interview?.jobId._id,  //interview id
  name: interview?.name,
  mobile: interview?.mobile,
  jobtitle:interview?.jobtitle,
  companyname:interview?.companyname+" - "+interview?.jobId.officeName,
  interviewername:interview?.interviewername,
  hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
  interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
  interviewtime:  IntvwTime,
  interviewlocation:interview?.location,
  rounds:interview?.rounds,
  reference:interview?.reference,
  status:interview?.status,
  feedback:interview?.feedback,
  bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
  bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
  englishrating:interview?.englishRating,
  communicationrating:interview?.communicationRating,
  createdbyname:interview?.createdbyname,
  createdbyid:interview?.createdbyId,
    };
  });

  return (
    <div style={{position:"absolute" ,width:'100%',height:"calc(100% - 5px)",overflowX:"hidden",overflowY:"scroll"}}>
    <div style={{float:"right", marginRight:"20px"}}>List<Switch onChange={(e)=>setCards(e.target.checked)} checked={cards} />Cards</div>

    {cards===true?<div style={{height:"100%",width: '100%',display:"block",flexDirection:"row",textAlign:"center"}}>{offices &&  ([...(new Set(offices.map((cpy) =>cpy.company + " - "+ cpy.office)))])
    .map(company=> <div style={{height:'500px',width: '350px',display:"inline-block",verticalAlign:"top",margin:"5px",border:"2px solid cornflowerblue",textAlign:"left"}}><div style={{textAlign:"center",height:"25px",backgroundColor:"cornflowerblue",color:"white",fontWeight:"bolder"}}>{company}({rowData.filter(row=>row.companyname===company).length})</div> 
    <div style={{height:"400px",margin:"15px",overflowY:"auto"}}>{rowData.filter(row=>row.companyname===company).map(candidate=> <div onClick={(e)=>scheduleinterview(candidate)}>{candidate.name+' - '+candidate.mobile}<br/>{'('+candidate.interviewtime+((parseInt(candidate.interviewtime.split(':')[0])<12?' am':' pm')+')')} </div>)}</div><div style={{height:"25px",textAlign:"center"}}> <Button size='small' variant="contained" title="Whatsapp Lineups" onClick={()=>{whatsappLinups(company)}}>Whatsapp Lineups</Button>
   </div> </div>)}</div>:
    <div style={{height:'100%',width: '100%'}}>
    <FormControl variant="outlined" sx={{width: 250 }} size="small">
            <InputLabel id="demo-simple-select-label">Company Name</InputLabel>
            <Select
              labelId="demo-simple-select-labell"
              id="demo-simple-select"
              
              value={companyName} onChange={(e)=> {
              
                setCompanyName(e.target.value);
               
               } } >
             <MenuItem value="all">{"All"}</MenuItem>
             { 
            
            offices && ([...(new Set(offices.map((cpy) =>cpy.company +" - "+cpy.office)))]).map((company) =><MenuItem value={company}>{company}</MenuItem>
  
             )} 
        </Select>
          </FormControl> 
          <Button size='small' variant="contained" style={{marginLeft:"20px"}} title="Whatsapp Lineups" onClick={()=>{whatsappLinups(companyName)}}>Whatsapp Lineups</Button>
   
      <DataGrid 
      rows={companyName==='all'?rowData:rowData.filter(row=>row.companyname===companyName)} columns={cols}  components={{
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}

        onSelectionModelChange ={(e) =>{ 
        var selectedIDs = new Set(e);
        var selectedRowData = rowData.filter((row) =>
        selectedIDs.has(row.id.toString()));
        
        selectedRowData.map((row)=>{return setSelectedData([...selectedData,{name:row.name,mobile:row.mobile}])});
        }}  

        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowDoubleClick={(e)=>scheduleinterview(e.row)}
          />
 </div>}
 {/* <ScheduleInterview open={OpenShedule} showAlert={props.showAlert} interview={selectedRow} close={()=>setOpenScedule(false)} /> */}
 <ScheduleInterviewUI open={OpenShedule} showAlert={props.showAlert} interview={selectedRow} close={()=>setOpenScedule(false)} />

</div>
  ) 
}

export default Today







