import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './Users.css'
import SearchIcon from '@mui/icons-material/Search';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import GppGoodIcon from '@mui/icons-material/GppGood';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const FAQ = (props) => {

  const FAQModal = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 500,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

  const label = { inputProps: { 'aria-label': 'Schedule Interview' } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [faqId, setFaqId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [allFaqs, setAllFaqs] = useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [reportingToId,setReportingToId]= useState("");
  const [reportingToName,setReportingToName]= useState("");
  const [accessType,setAccessType] = useState("");
  const [active,setActive ]=useState(true);
  const [ password,setPassword] = useState("");
  const [selectedData,setSelectedData] = useState(true);
  const [openEmailTemplate,setOpenEmailTemplate] = useState(false);
  const [emailData,setEmailData] = useState(false);
  const [fromDate, setFromDate] = useState(new Date().toISOString().substring(0,10));
  const [toDate, setToDate] = useState(new Date().toISOString().substring(0,10));
  const {user} = useContext(UserContext);
  const [interviewStatus,setInterviewStatus] = useState("test");
  const [ questions,setQuestions] = useState("");
  const [ answer,setAnswer] = useState("");
  const [order,setOrder] = useState(0);

//   const [openComments, setOpenComments] = React.useState(false);
  const [openFAQ, setOpenFAQ] = React.useState(false);
  const [emailStatusId,setEmailStatusId] = useState("");
  const [prevComments,setPrevComments] = useState("");
  const [searchStatus,setSearchStatus] = useState("all");

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {columnField: '', operatorValue: 'contains', value:user.name}
    ],
  });
  
  const cols = [
  {field:'id', headerName: 'id', hide: true },
  {field:'questions', headerName: 'Questions',width:500,headerAlign:'center',align: 'center',  },
 
  {field:'answers', headerName: 'Answers',width: 600 ,headerAlign:'center',align: 'center'},
  {field:'order', headerName: 'Rank',width: 90 ,headerAlign:'center',align: 'center',hide:user.accesstype!=="5" ?true:false,},
    {field:'active', headerName: 'Active',width: 90 ,headerAlign:'center',align: 'center', hide:user.accesstype!=="5" ?true:false, renderCell: (params) =>{
      return[
        <input type="checkbox" checked={params.row.active} /> 
      ];
    } },  

  {field:'copy', headerName:'Copy Answers',width: 150,headerAlign:'center',align: 'center',

  renderCell: (params) => {
   return[
    <div>
      <Button size="small" title="Copy Answer  " variant="contained" onClick={() => MyCopyFunction(params.row.answers)} ><ContentCopyIcon /></Button>
       
      
      </div>
   ];
  },
},  

];

useEffect(()=>{
    getAllFaqs();

},[])

const updateEmailStatus = (row,value)=>{
if((row.status ==="pass" || row.status==="fail" || value === "pass" || value === "fail" ) && parseInt(user.accesstype)<2)
{
  return alert("You don't have access to change result, contact your Manager to update!!")
}
let apiURL =  process.env.REACT_APP_API_URL + "/users/updateemailstatus/"+row.id;
    let apiParams ={
      method:"POST",
      headers:{
       status:value,
    }
    };
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=>{
      if(repos.data){
       
        setIsLoading(false)
        
        props.showAlert("Email Status Updated Successfully","success")
        setTimeout(()=>{
         
        },2000)
      }

    })
    .catch((err) => props.showAlert((''+err),"error"));
}


const MyCopyFunction = async (copy)=>{
    // setCopiedText(heading,jd)

     await navigator.clipboard.writeText(copy);
    //alert("Copied the text: " + jd);
    //   alert("Copied the text");
    }

// const UpdateCommmentStatus = ()=>{
// if(emailStatusId.length<1){
//   return 
// }else{
// setOpenComments(false)
// let apiURL =  process.env.REACT_APP_API_URL + "/users/updateemailcomments/"+emailStatusId;
//     let apiParams ={
//       method:"POST",
//       headers:{
//        comments:encodeURIComponent(" ["+new Date().toLocaleString()+"]:\n"+comments+"\n"+(prevComments||"")+"\n")
//     }
//     };
//     fetch(apiURL,apiParams)
//     .then((res)=> (res.status===200 ? res.json() : res.text()))
//     .then((repos)=>{
//       if(repos.data){
       
//         setIsLoading(false)
//         // setComments("")
//         props.showAlert("Email Comments Updated Successfully","success")
//         setTimeout(()=>{
//         //   LoadPreviousData()
//         },2000)
//       }

//     })
//     .catch((err) => props.showAlert((''+err),"error"));
// }
// }

const getAllFaqs = ()=>{
  let apiURL=process.env.REACT_APP_API_URL + "/faqs/getallfaqs/"+user._id; 
  let apiParams = {
      method:"GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {

        setAllFaqs(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

// const UpdateDevice = (id,allowed)=>{
//     let apiURL =  process.env.REACT_APP_API_URL + "/users/updatedevice/"+id;
//     let apiParams ={
//       method:"POST",
//       headers:{
//         allowed
//     }
//     };
//     fetch(apiURL,apiParams)
//     .then((res)=> (res.status===200 ? res.json() : res.text()))
//     .then((repos)=>{
//       if(repos.data){
//         setOpen(false)
//         setIsLoading(false)
        
//         props.showAlert("Device Updated Successfully","success")
//         setTimeout(()=>{
//           window.location.reload(false);
        
//         },2000)
//       }

//     })
//     .catch((err) => props.showAlert((''+err),"error"));
//     }

const handleOpen = (row)=>{
    setFaqId(row.id)
    setQuestions(row.questions);
    setAnswer(row.answers);
    setOrder(row.order);
    setActive(row.active);
// setOpenEmailTemplate(true)
setOpenFAQ(true);
}

  
const handleCloseFAQ = () =>{
   setFaqId("") 
   setQuestions("");
   setAnswer("");
   setOrder(0);
  setOpenFAQ(false);

}

const UpdateFAQquery = (faqid)=>{
  
    if(questions.length===0){
        return alert("Enter the Query!!")
       }
       if(answer.length===0){
        return alert("Enter the Answer !!")
       }
    
        let apiURL =  process.env.REACT_APP_API_URL + "/faqs/updatefaqqueries/"+faqid;
        let apiParams ={
          method:"POST",
          headers:{
            question:encodeURIComponent(questions),
            answer:encodeURIComponent(answer),
            active:active,
            order:order,
            authtoken: localStorage.getItem("loggedintoken")
        }
        };

        fetch(apiURL,apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=>{
          if(repos.data){
           setOpenFAQ(false);
            setIsLoading(false)
          
            props.showAlert("FAQ Added Successfully!!","success")
            setTimeout(()=>{
                getAllFaqs()
            },2000)
          }
    
        })
        .catch((err) => props.showAlert((''+err),"error"));
}


const AddFAQquery = ()=>{

    if(questions.length===0){
        return alert("Enter the Query!!")
       }
       if(answer.length===0){
        return alert("Enter the Answer !!")
       }
    
        let apiURL =  process.env.REACT_APP_API_URL + "/faqs/addfaqqueries";
        let apiParams ={
          method:"POST",
          headers:{
            question:encodeURIComponent(questions),
            answer:encodeURIComponent(answer),
            active:active,
            order:order,
            authtoken: localStorage.getItem("loggedintoken")
        }
        };
        fetch(apiURL,apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=>{
          if(repos.data){
            setOpenFAQ(false);
            setIsLoading(false)
          
            props.showAlert("FAQ Added Successfully!!","success")
            setTimeout(()=>{
                getAllFaqs()
            },2000)
          }
    
        })
        .catch((err) => props.showAlert((''+err),"error"));
    
} 


const sendWhatsapp = (row,value)=>{

 if(value==="pass"){

  let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+row.mobile.trim().replace(' ','').substring(row.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Congratulations "+row.applicantname[0].toUpperCase() + row.applicantname.slice(1)+"!%0a%0aYou have cleared the "+row.subject+" for the "+row.jobTitle+" position. We are scheduling your face-to-face interview tomorrow at 5:30 pm.%0a%0aPlease confirm me your availability to attend, else suggest your convenient time."

  const newWindow = window.open(apiURL, '_blank')
 } 
}

const addFAQ = ()=>{
setOpenFAQ(true)
}


const rowData = (allFaqs)?.map((se) => {

return{
  id:se?._id,
  questions:se?.question,
  answers:se?.answer,
  active:se?.active,
  order:se?.order,
 
};
});

  return (
  <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
  <div style={{textAlign:"center"}}>
  {user.accesstype!=="5" ?"":<Button size='small' variant="contained" className='intern' title={"Add FAQ"}  onClick={()=>addFAQ()}>+ Add FAQ</Button>}
  <br/>
   </div> 
  <br/>
      <div style={{height:'calc(100% - 50px)',width: '100%'}}>
      <DataGrid rows={rowData} columns={cols}  components={{
          Toolbar: GridToolbar,
        }}
        initialState={{
        sorting: {
        sortModel: [{ field: 'order', sort: 'asc' }],
    },
  }}
        onSelectionModelChange ={(e) =>setSelectedData(e)}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        // onCellClick={(cell)=>
        //   (cell.field=="comments" ) ? OpenCommentsModal(cell.row) : null

        //   }
        onRowDoubleClick={(e)=>user.accesstype!=="5" ?"":handleOpen(e.row)}
          />
      </div>
    {/* Modal for Updating Sent Email Comments */}
      <Modal
        open={openFAQ}
        onClose={handleCloseFAQ}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
      
        <Box sx={FAQModal}>
        <div>
          <Typography id="modal-modal-description">
          <Container style={{padding:"0px"}}>
          <div className = "content6">
          
        <div>
          {/* <h4  className="fnt"><b>{id.length>0?name:"USER DETAILS"}</b> <Button disabled={isLoading} title="Close" variant="Standard"  onClick={()=>handleClose()} ><CloseIcon /></Button></h4>  */}
          <h4 className="fnt2"><b>{faqId.length>0?"Update FAQ":"Add FAQ"}</b>
        
        <Button title="Close" size="small" variant="Standard" sx={{ bgcolor: "red",float:"right",right:0,top:0,position:"absolute",height:33,width:5 }} onClick={()=>handleCloseFAQ()} > <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
       </h4>
         </div>
         <br/>
         <div style={{textAlign:"center",margin:"2px"}}>
        <table>
       <tr>
       <td style={{textAlign:"center"}}>
       <TextField 
          id="outlined-multiline-flexible"
          sx={{width: 400}}
          size="small"
          label="Enter Question"
          multiline
          rows={3} 
          value={questions || "" }
           onChange={(e)=> {
            setQuestions(e.target.value);
        }} 
        /> 
       </td>
       </tr>
       <tr>
        <td style={{textAlign:"center"}}>
        <TextField 
          id="outlined-multiline-flexible"
          sx={{width: 400}}
        
        //   style={{marginRight:"20px"}}
          size="small"
          label="Enter Answer"
          multiline
          rows={3} 
          value={answer || "" }
           onChange={(e)=> {
            setAnswer(e.target.value);
        }} 
        /> 
        </td>
       </tr>
       <tr>
        <td colSpan={2} style={{textAlign:"center"}}>
        Active User: <input type="checkbox"  checked={active} onChange={(e)=>setActive(e.target.checked)}/>&nbsp;&nbsp;&nbsp;
        <TextField id="outlined-basic" sx={{width: 100 }} size="small" label="Order No" variant="outlined"  value={order} onChange={(e)=> {
        setOrder(e.target.value);     
      }}/>
        </td>
       
       </tr>
      </table>
      </div>
      <br/>
      
        <div style={{textAlign:"center",marginBottom:"5px"}}>
     
      <Button disabled={isLoading} type="reset" title={faqId.length>0?"Update":"Add"}  variant="contained" onClick={(e)=>{faqId.length>0?UpdateFAQquery(faqId):AddFAQquery()}}>{faqId.length>0?"Update":"Add"}</Button> &nbsp; &nbsp;
      &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
     </div>

      </div>
      </Container> 
          </Typography>  
           </div>
        </Box>
      </Modal>

    
</div>
  
  )
}

export default FAQ