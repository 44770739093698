import React, {useEffect, useState} from 'react'
import '../Styles/InterviewsMain.css'
import InterviewDetails from '../InterviewDetails';
import MessagedDetails from '../MessagedDetails';
import Today from '../Today';
import Tomorrow from '../Tomorrow';
import SelectedCandidates from '../SelectedCandidates';
import JoinedCandidates from '../JoinedCandidates';
import EligibleCandidates from '../EligibleCandidates';
import InvoicedCandidates from '../InvoicedCandidates';
import SentEmailsToCandidates from '../SentEmailsToCandidates';

const ClientsHRMain = (props) => {

const [selectedInterviewTab,setSelectedInterviewTab] = useState("Selected");


  return (
    <div id="Interviews_mainPctoday">

	<div id="Interviews_mainPcGroup_12">
		<svg class="Interviews_mainPcRectangle_4">
			<rect id="Interviews_mainPcRectangle_4" rx="15" ry="15" x="0" y="0" width="100%" height="100%">
			</rect>
		</svg>
		{/* {selectedInterviewTab=== "Called"?<InterviewDetails showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Messaged"?<MessagedDetails showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Emailed"?<SentEmailsToCandidates showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Today"?<Today showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Tommorow"?<Tomorrow showAlert={props.showAlert} />:""} */}
		{selectedInterviewTab=== "Selected"?<SelectedCandidates showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Joined"?<JoinedCandidates showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Eligible"?<EligibleCandidates showAlert={props.showAlert} />:""}
		{selectedInterviewTab=== "Invoiced"?<InvoicedCandidates showAlert={props.showAlert} />:""}
	</div>
	<div id="Interviews_mainPcGroup_7">
		<svg class="Interviews_mainPcRectangle_5">
			<rect id="Interviews_mainPcRectangle_5" rx="10" ry="10" x="0" y="0" width="128" height="100%">
			</rect>
		</svg>
		<table id='Interviews_mainPcGroup_6'>
		{/* <tr><td class={selectedInterviewTab=== "Called"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Called");}}>Called</td></tr>
		<tr><td class={selectedInterviewTab=== "Messaged"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Messaged");}}>Messaged</td></tr>
		<tr><td class={selectedInterviewTab=== "Emailed"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Emailed");}}>Emailed</td></tr>
		<tr><td class={selectedInterviewTab=== "Today"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Today");}}>Today's</td></tr>
		<tr><td class={selectedInterviewTab=== "Tommorow"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Tommorow");}}>Tommorow's</td></tr> */}
		<tr><td class={selectedInterviewTab=== "Selected"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Selected");}}>Selected</td></tr>
		<tr><td class={selectedInterviewTab=== "Joined"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Joined");}}>Joined</td></tr>
		<tr><td class={selectedInterviewTab=== "Eligible"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Eligible");}}>Eligible</td></tr>
		<tr><td class={selectedInterviewTab=== "Invoiced"?'selectedInterviewTab_pc':'interviewtabs_pc'} onClick={()=>{setSelectedInterviewTab("Invoiced");}}>Invoiced</td></tr>
		{/* <tr><td>Tommorow</td></tr>
		<tr><td>Tommorow</td></tr> */}
		</table>

		{/* <div id="Interviews_mainPcGroup_6">
			<div  id="Interviews_mainPcTommorow">
				<span>Tommorow</span>
			</div>
			<div  id="Interviews_mainPcSelected">
				<span>Selected</span>
			</div>
			<div  id="Interviews_mainPcJoined">
				<span>Joined</span>
			</div>
			<div  id="Interviews_mainPcEligible">
				<span>Eligible</span>
			</div>
			<div  id="Interviews_mainPcInvoice">
				<span>Invoice</span>
			</div>
			<div id="Interviews_mainPcGroup_5">
				<div id="Interviews_mainPcToday_bv">
					<span>Today</span>
				</div>
				<svg class="Interviews_mainPcLine_2" viewBox="0 0 44 1">
					<path id="Interviews_mainPcLine_2" d="M 0 0 L 44 0">
					</path>
				</svg>
			</div>
		</div> */}
	</div>
</div>
  )
}

export default ClientsHRMain
