import React, {useState, useEffect, useContext} from 'react'
import '../Styles/AddUserPopUpUI.css'
import Modal from '@mui/material/Modal';
import { UserContext } from '../UserContext';

const AddUserPopUpUI = (props) => {
    const [open,setOpen] = useState(false)
    const [id, setId] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [whatsNumber,setWhatsNumber] = useState("");
    const [gender, setGender] = useState("male");
    const [employees, setEmployees] = useState([]);
    const [isLoading,setIsLoading]=useState(false);
    const [reportingToId,setReportingToId]= useState("");
    const [reportingToName,setReportingToName]= useState("");
    const [accessType,setAccessType] = useState("1");
    const [active,setActive ]=useState(true);
    const [ password,setPassword] = useState("");
    const [reportingToMobile,setReportingToMobile] = useState("");
    const [isHr,setIsHr] = useState(true);
    const [canCallPC,setCanCallPC] = useState(false);
    const [canMsgPC,setCanMsgPC] = useState(false);
    const [canCallMob,setCanCallMob] = useState(false);
    const [canMsgMob,setCanMsgMob] = useState(false);
    const { user} = useContext(UserContext);
    const [dob,setDob] = useState(new Date().toISOString().substring(0,10))

    var CryptoJS = require("crypto-js");


    useEffect(()=>{
    if(user){
        if(props.open){
            setOpen(props.open)  
       
            if(props.data){
               console.log(props.data)
               setId(props.data.id);
               setName(props.data.name)
               setGender(props.data.gender)
               setReportingToId(props.data.reportingToId)
               setReportingToName(props.data.reportingToName)
               setReportingToMobile(props.data.reportingToMobile)
               setAccessType(props.data.accesstype)
               setMobile(props.data.mobile)
               setEmail(props.data.email)
               setActive(props.data.active)
               setIsHr(props.data.ishr)
               setCanCallPC(props.data.canCallInPc)
               setCanMsgPC(props.data.canWhatsAppInPc)
               setCanCallMob(props.data.canCallInMob)
               setCanMsgMob(props.data.canWhatsAppInMob)
               setDob(props.data.dob?props.data.dob.substring(0,10):new Date())
            } 
    }    
   
    }

    },[props.open])

    useEffect(()=>{
        getEmployees()
      
      },[])
      
      
      
  async function encrypt(plainText) {
    return await CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
}

      const getEmployees = ()=>{
        let apiURL=process.env.REACT_APP_API_URL + "/users/getallusers" 
        let apiParams = {
            method:"GET",
            headers: {
              authtoken: localStorage.getItem("loggedintoken")
            }
           
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
      
              setEmployees(repos.data)
             
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }

const handleClose = ()=>{
   
    setOpen(false)
    resetApplicant()
    props.close();
}

const resetApplicant = ()=>{

    setId("");
    setName("");
    setEmail("");
    setMobile("");
    setGender("");
    setReportingToId("")
    setReportingToName("")
    setReportingToMobile("")
    setAccessType("")
    setDob("")
    // setActive(false)
  
    
  }


const addEmployeedetails = async () =>{
    if(email.length===0){
      props.showAlert("Please Enter Email !!","error")
    }
  let apiURL = process.env.REACT_APP_API_URL + "/users/addEmployee";
  let apiParams = {
    method:"POST",
    headers:{
      name:name,
      gender:gender, 
      reportingtoid:reportingToId,
      reportingtoname:reportingToName,
      reportingtomobile:reportingToMobile,
      accesstype:accessType,
      mobile:mobile,
      email:email,
      dob:dob,
      password:encodeURIComponent(await encrypt(password)),
      active:active,
      ishr:isHr,
      cancallinpc:canCallPC,
      canwhatsappinpc:canMsgPC,
      cancallinmob:canCallMob,
      canwhatsappinmob:canMsgMob,
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  fetch(apiURL,apiParams)
  .then((res)=>(res.status===200 ? res.json() :res.text()))
  .then((repos)=>{
    if(repos.data){
        handleClose()
      setIsLoading(false)
      
      props.showAlert("User Added Successfully","success")
      setTimeout(()=>{
        getEmployees()
      },2000)
    }
  })
  .catch((err)=>props.showAlert((''+err),"error"))
  }


  const UpdateEmployeesDetails = (id)=>{
    let apiURL =  process.env.REACT_APP_API_URL + "/users/updateEmployees/"+id;
    let apiParams ={
      method:"POST",
      headers:{
      name:name,
      gender:gender,
      reportingtoid:reportingToId,
      reportingtoname:reportingToName,
      reportingtomobile:reportingToMobile,
      accesstype:accessType,
      mobile:mobile,
      email:email,
      dob:dob,
      //password:encodeURIComponent(password),
      active:active,
      ishr:isHr,
      cancallinpc:canCallPC,
      canwhatsappinpc:canMsgPC,
      cancallinmob:canCallMob,
      canwhatsappinmob:canMsgMob,
      authtoken: localStorage.getItem("loggedintoken")
    }
    };
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=>{
      if(repos.data){
        handleClose()
        setIsLoading(false)
        
        props.showAlert("User Updated Successfully","success")
        setTimeout(()=>{
        
            getEmployees()
        },2000)
      }

    })
    .catch((err) => props.showAlert((''+err),"error"));
    }


  return (
    <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    
  >
<div id="UDP_user_detail_pop">
	<svg class="UDP_Rectangle_55_r">
		<linearGradient id="UDP_Rectangle_55_r" spreadMethod="pad" x1="0.705" x2="-1.352" y1="1" y2="-0.638">
			<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#bbc0ff" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="UDP_Rectangle_55_r" rx="10" ry="10" x="0" y="0" width="303" height="697">
		</rect>
	</svg>
	
	<div id="UDP_User_Details">
		<span>User Details</span>
	</div>
	<img id="UDP_close" src="close.png" srcset="close.png 1x" onClick={()=>handleClose()}/>
		
	<div id="UDP_Scroll_Group_1">
		<div id="UDP_name_grp_prof">
			<div id="UDP_name_lbl_pfp">
				<span>Name</span>
			</div>
			<input type="name" class="UDP_name_input_pfp" label="Name"  value={name || ""} onChange={(e)=> {
          setName(e.target.value);
      }}/>
				
		</div>
		<div id="UDP_mobile_grp_prof">
			<div id="UDP_Mobile_Number_lbl_pfp">
				<span>Mobile Number</span>
			</div>
			<input type="number" class="UDP_mob_input_pfp" label="Mobile No"  value={mobile} 
           onChange={(e)=> {
            setMobile(e.target.value);  
        }} />
				
		</div>

		<div id="UDP_gender_grp_proof">
			<div id="UDP_Gender_lbl_pfp">
				<span>Gender</span>
			</div>
			<select class="UDP_gen_input_pfp" value={gender} onChange={(e)=> {setGender(e.target.value);}} >
				<option value="male">Male</option>
                <option value="female">Female</option>
                <option value="others">Others</option>

			</select>
		</div>
		<div id="UDP_dob_grp_proof">
			<div id="UDP_DOB_lbl_pfp">
				<span>Access Type</span>
			</div>
			<select class="UDP_dob_input_pfp" value={accessType} onChange={(e)=> {
          setAccessType(e.target.value);   
         } }>
		  <option value="0">Guest/Client/Vendor</option>
          <option value="1">Executive</option>
          <option value="2">Supervisor</option>
          <option value="3">Manager</option>
          <option value="4">Director</option>
          <option value="5">Admin</option>

			</select>
		</div>
		<div id="UDP_emmail_grp_proof">
			<div id="UDP_Email_lbl">
				<span>Email</span>
			</div>
			<input type="email" class="UDP_email_input" value={email || ""} onChange={(e)=> {
        setEmail(e.target.value);     
      }} />
				
		</div>
        {user.name !== name && id && id.length > 0 ?"":<div id="UDP_mobile_grp_prof_bg">
			<div id="UDP_Mobile_Number_lbl_pfp_bh">
				<span>Password Update</span>
			</div>
			<input type="password" class="UDP_mob_input_pfp_bi"  value={password || ""} onChange={(e)=> {
        setPassword(e.target.value); }}/>
				
		</div>}
		<div id="UDP_emmail_grp_proof_bj">
			<div id="UDP_Email_lbl_bk">
				<span>Reporting To</span>
			</div>
			<select class="UDP_email_input_bl" value={reportingToId} onChange={(e)=> {
          
          setReportingToId(e.target.value);  
          setReportingToName(employees.filter(hr=>hr._id===e.target.value)[0].name); 
          setReportingToMobile(employees.filter(hr=>hr._id===e.target.value)[0].phone); 
        
         } }>
				{
            employees && employees.map(hr=>  <option value={hr._id}>{hr.name}</option>)
        }
			</select>
		</div>
		<div id="UDP_mobile_grp_prof_bm">
			<div id="UDP_Mobile_Number_lbl_pfp_bn">
				<span>DOB</span>
			</div>
			<input type="date" class="UDP_mob_input_pfp_bo" value={dob}
        onChange={(e) => {
          setDob(e.target.value);
        }}/>
			
		</div>
		<div id="UDP_Group_43">
			<div id="UDP_Is_HR_lbl">
				<span>Is HR?</span>
			</div>
			<input type="checkbox" class="UDP_Rectangle_49" checked={isHr} onChange={(e)=>setIsHr(e.target.checked)}/>
				
		</div>
		<div id="UDP_Group_42">
			<div id="UDP_Is_HR_lbl_bt">
				<span>Active</span>
			</div>
			<input type="checkbox" class="UDP_Rectangle_50" checked={active} onChange={(e)=>setActive(e.target.checked)} />
				
		</div>
		<div id="UDP_Group_46">
			<svg class="UDP_Rectangle_59">
				<rect id="UDP_Rectangle_59" rx="10" ry="10" x="0" y="0" width="222" height="40">
				</rect>
			</svg>
			<div id="UDP_Group_44">
				<input type="checkbox" class="UDP_Rectangle_53" checked={canCallPC} onChange={(e)=>setCanCallPC(e.target.checked)}/>
					
				<img id="UDP_telephone" src="telephone.png" srcset="telephone.png 1x" />
					
				<img id="UDP_whatsapp_dashboard" src="whatsapp_dashboard.png" srcset="whatsapp_dashboard.png 1x" />
					
				<input type="checkbox" class="UDP_Rectangle_56" checked={canMsgPC} onChange={(e)=>setCanMsgPC(e.target.checked)}/>
					
			</div>
			<img id="UDP_whatsapp_dashboard_b" src="whatsapp_dashboard_b.png" srcset="whatsapp_dashboard_b.png 1x" />
				
		</div>
		<div id="UDP_Group_47">
			<svg class="UDP_Rectangle_59_b">
				<rect id="UDP_Rectangle_59_b" rx="10" ry="10" x="0" y="0" width="222" height="40">
				</rect>
			</svg>
			<div id="UDP_Group_44_b">
				<input type="checkbox" class="UDP_Rectangle_53_b" checked={canCallMob} onChange={(e)=>setCanCallMob(e.target.checked)}/>
					
				<img id="UDP_telephone_b" src="telephone_b.png" srcset="telephone_b.png 1x" />
					
				<img id="UDP_whatsapp_dashboard_ca" src="whatsapp_dashboard_ca.png" srcset="whatsapp_dashboard_ca.png 1x" />
					
				<input type="checkbox" class="UDP_Rectangle_56_b" checked={canMsgMob} onChange={(e)=>setCanMsgMob(e.target.checked)}/>
					
			</div>
			<img id="UDP_whatsapp_dashboard_cb" src="whatsapp_dashboard_cb.png" srcset="whatsapp_dashboard_cb.png 1x" />
				
		</div>
        <button id="UDP_update_profile_grp" onClick={()=>id.length>0?UpdateEmployeesDetails(id):addEmployeedetails()}>
		<svg class="UDP_Rectangle_34_w">
			<rect id="UDP_Rectangle_34_w" rx="8" ry="8" x="0" y="0" width="136" height="39">
			</rect>
		</svg>
		<div id="UDP_Add_User_x">
			<span>{id.length>0?"Edit User":"Add User "}</span>
		</div>
	</button>
	</div>
</div>
  </Modal>
  )
}

export default AddUserPopUpUI
