import React, {useEffect, useState} from 'react'
import '../Styles/NewNavbarUI.css'
import { AppBar, Tabs, Toolbar,Typography,Tab, Button, useMediaQuery,useTheme, } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout';
import DrawerComp from '../DrawerComp';
import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom';
import { useContext } from "react";
import { UserContext } from '../UserContext';
import { useSearchParams, useLocation } from 'react-router-dom';


const PAGES = [""]

const NewNavbarUI = (props) => {
  const location = useLocation();
  var navigate = useNavigate();
  const [value,setValue]=useState(0);
  const [img,setImg] = useState("");
  const theme = useTheme();
  const { user, setUser } = useContext(UserContext);
  const [searchParams] = useSearchParams();
  const [selectedTab,setSelectedTab] = useState("");
// console.log(theme)
  const isMatched = useMediaQuery(theme.breakpoints.down('lg'))
  // console.log(isMatched)
  
  useEffect(()=>{
    if(user && (user.accesstype!=="c")){
      if(user.accesstype!=="candidate" && user.accesstype!=="recruiter" )
      {
        getUserProfile()
      }
    }
    if(user && user.accesstype > 2){
    switch (location.pathname)
    {
      case "/jobs":
        setSelectedTab("vacancies")
        break;
          case "/messageddetails":
            setValue(2)
            break;
          case "/interviewdetails":
            setSelectedTab("internal")
            break;
          case "/clientdetails":
            setSelectedTab("client")
            break;
            case "/today":
              setValue(4)
              break;
              case "/tomorrow":
                setValue(5)
                break;
                case "/selected":
                  setValue(6)
                  break;
                  case "/joined":
                    setValue(7)
                    break;
                    case "/eligible":
                      setValue(8)
                      break;
                    case "/invoiced":
                      setValue(9)
                      break;
                      case "/bookmarked":
                        setSelectedTab("bookmark")
                        break;
                        case "/applicants":
                          setSelectedTab("applicant")
                          break;
                          case "/applicantstatus":
                            setSelectedTab("rounds")
                            break;
                            case "/reassign":
                              setSelectedTab("reassign");
                              break;
                            case "/monitor":
                              setSelectedTab("monitor");
                              break;
                            case "/setup":
                              setSelectedTab("setup");
                              break;
                              case "/recruiterstab":
                                setSelectedTab("recruiters");
                                break;
                                case "/faqs":
                                  setValue(15)
                                  break;
                                case "/locations":
                                  setValue(16)
                                  break;
                                case "/officelocations":
                                  setValue(17)
                                  break;
                                case "/recruiterofficelocations":
                                  setValue(18)
                                  break;
                                case "/recruitervacancies":
                                  setValue(19)
                                  break;
                                case "/cities":
                                  setValue(20)
                                  break;
                                case "/dataupload":
                                  setValue(21)
                                  break;

                                case "/registrations":
                                  setValue(22)
                                  break;
                                  
                                  case "/users":
                                    setValue(23)
                                    break;
                                    case "/devices":
                                      setValue(24)
                                      break;
                                      case "/newapplicants":
                                        setValue(25)
                                        break;
                               
        default:
          setValue(0)
          break;
    }}else if(user && user.accesstype < 3){
      switch (location.pathname)
    {
      case "/jobs":
        setSelectedTab("vacancies")
        break;
        case "/messageddetails":
          setValue(2)
          break;
          case "/interviewdetails":
            setSelectedTab("internal")
            break;
          case "/clientdetails":
            setSelectedTab("client")
            break;
            case "/today":
              setValue(4)
              break;
              case "/tomorrow":
                setValue(5)
                break;
                case "/selected":
                  setValue(6)
                  break;
                  case "/joined":
                    setValue(7)
                    break;
                    case "/eligible":
                      setValue(8)
                      break;
                    case "/invoiced":
                      setValue(9)
                      break;
                      case "/bookmarked":
                        setSelectedTab("bookmark")
                        break;
                        case "/applicants":
                          setSelectedTab("applicant")
                          break;
                          case "/applicantstatus":
                            setSelectedTab("rounds")
                            break;
                            case "/reassign":
                              setSelectedTab("reassign");
                              break; 
                              case "/monitor":
                              setSelectedTab("monitor");
                              break;
                              case "/newapplicants":
                              setSelectedTab("monitor");
                              break;
                              case "/setup":
                                setSelectedTab("setup");
                                break;
                                case "/recruiterstab":
                              setSelectedTab("recruiters");
                              break;
                                case "/faqs":
                                  setValue(15)
                                  break;
                                case "/locations":
                                  setValue(16)
                                  break;
                        
        default:
          setValue(0)
          break;
    }}else if(user &&  user.accesstype === "c"){
      switch (location.pathname)
    {
       case "/apply":
            setValue(0)
       break;
      case "/jobs":
        setSelectedTab("vacancies")
        break;
      case "/candidateApplications":
        setValue(2)
        break;
          
    default:
    setValue(0)
    break;       
    }
  }
  
  },[isMatched]

  )

  const handlelogout = () =>{
    localStorage.clear();
    setUser(null);
    window.location.href = "/";
  }

  const getUserProfile = () => {

    let apiURL = process.env.REACT_APP_API_URL + "/users/getUserProfile/"+user._id;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {

          setImg(repos.data.image && arrayBufferToBase64(repos.data.image.data.data));
          
        } else console.log(""+(repos.message || repos),repos.status!==200?"error":"success");
      })
      .catch((err) => console.log((''+err),"error")); 
  };

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }


  return (
    <div id="NAP_Van_pCnav_admin_portal">
    <div id="NAP_Van_pCGroup_3">
    
    {user && (user.accesstype>=0 || user.accesstype==="c"||user.accesstype ==="r")?   <table id='NAP_Van_pCGroup_4'>
      <tr>
      {user && user.accesstype > 0 ? <td class={selectedTab=== "vacancies"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("vacancies");navigate("/jobs")}}>Vacancies</td>:<></>}
      {user && user.accesstype > 0 ?<td class={selectedTab=== "applicant"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("applicant");navigate("/applicants")}}>Applicants</td>:<></>}
      {user && user.accesstype > 0 ?<td class={selectedTab=== "internal"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("internal");navigate("/interviewdetails")}}>Internal HR</td>:<></>}
      {user && user.accesstype > 0 ?<td class={selectedTab=== "client"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("client");navigate("/clientdetails")}}>Client HR</td>:<></>}
      {user && user.accesstype > 2 ?<td class={selectedTab=== "rounds"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("rounds");navigate("/applicantstatus")}}>Rounds</td>:<></>}
      {user && user.accesstype >= 0 ?<td class={selectedTab=== "bookmark"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("bookmark");navigate("/bookmarked")}}>Bookmarks</td>:<></>}
      {user && (user.accesstype == 5) ?<td class={selectedTab=== "recruiters"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("recruiters");navigate("/recruiterstab")}}>Recruiters</td>:<></>}
      {user && user.accesstype == 5 ?<td class={selectedTab=== "monitor"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("monitor");navigate("/monitor")}}>Monitor</td>:<></>}
      {user && user.accesstype > 2 ?<td class={selectedTab=== "setup"?'selectedTab_pc_dashboard':'tabs_pc_dashbaord'}  onClick={()=>{setSelectedTab("setup");navigate("/setup")}}>Setup</td>:<></>}
    
      </tr> </table>:""}
      {/* <div id="NAP_Van_pCGroup_4">
         <div id="NAP_Van_pCVacancies">
          <span>Vacancies</span>
        </div> 
        <div id="NAP_Van_pCInterview">
          <span>Interview</span>
        </div>
        <div id="NAP_Van_pCBookmark">
          <span>Bookmark</span>
        </div>
        <div id="NAP_Van_pCRounds">
          <span>Rounds</span>
        </div>
        <div id="NAP_Van_pCReassign">
          <span>Reassign</span>
        </div>
        <div id="NAP_Van_pCSettings">
          <span>Settings</span>
        </div>
        <div id="NAP_Van_pCrecruiters">
          <span>recruiters</span>
        </div>
      </div> */}
    </div>
    <div id="NAP_Van_pCright_grp_setup_pc">
      <svg class="NAP_Van_pCright_cornersetup_pc" viewBox="12604.423 2956.948 195.078 84.411">
        <path id="NAP_Van_pCright_cornersetup_pc" d="M 12604.4228515625 2958.037841796875 L 12604.4228515625 3041.342041015625 L 12618.232421875 3041.342041015625 C 12618.232421875 3041.342041015625 12635.0185546875 3042.3564453125 12635.0185546875 3028.6845703125 C 12635.0185546875 3015.01318359375 12635.0185546875 2988.84814453125 12635.0185546875 2988.84814453125 C 12635.0185546875 2988.84814453125 12636.486328125 2978.21484375 12648.7314453125 2977.708251953125 C 12660.9755859375 2977.20166015625 12799.5009765625 2977.708251953125 12799.5009765625 2977.708251953125 L 12799.5009765625 2956.94775390625 L 12786.078125 2958.037841796875 L 12604.4228515625 2958.037841796875 Z">
        </path>
      </svg>
      <img title="Log Out" id="NAP_Van_pClogout_setup_pc" src="logout_setup_pc.png" srcset="logout_setup_pc.png 1x" onClick={()=>{handlelogout()}}/>
        
      <img title="Profile" id="NAP_Van_pCprofile_setup_pc" src={img?img:"default.jpg"} style={{border:"1px solid",padding:"5px"}} onClick={()=>{setSelectedTab("");navigate("/edituser")}}/>
        
    </div>
    <div id="NAP_Van_pCGroup_2">
      <img id="NAP_Van_pCbizibeesjpg" src="bizibees.png" srcset="bizibees.png 1x" onClick={()=>{setSelectedTab("");navigate("/dashboard")}}/>
        
      <svg class="NAP_Van_pCright_cornersetup_pc_b" viewBox="12554.423 2956.948 225.078 85.411">
        <path id="NAP_Van_pCright_cornersetup_pc_b" d="M 12779.5009765625 2958.037841796875 L 12779.5009765625 2979.876708984375 L 12779.5009765625 3042.342041015625 L 12765.69140625 3042.342041015625 C 12765.69140625 3042.342041015625 12748.9052734375 3043.3564453125 12748.9052734375 3029.6845703125 C 12748.9052734375 3016.01318359375 12748.9052734375 2988.84814453125 12748.9052734375 2988.84814453125 C 12748.9052734375 2988.84814453125 12747.4375 2978.21484375 12735.1923828125 2977.708251953125 C 12722.9482421875 2977.20166015625 12554.4228515625 2977.708251953125 12554.4228515625 2977.708251953125 L 12554.4228515625 2956.94775390625 L 12617.845703125 2958.037841796875 L 12750.0263671875 2958.037841796875 L 12779.5009765625 2958.037841796875 Z">
        </path>
      </svg>
    </div>
  </div>
  )
}

export default NewNavbarUI