import React, { useState, useEffect, useRef, useContext } from 'react'
// import { ReactInternetSpeedMeter } from 'react-internet-meter'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Autocomplete, Select } from '@mui/material';
import { UserContext } from './UserContext';
import './bulkDataUpload.css';
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import Counter from './Counter';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import { width } from '@mui/system';



const rounds = [
  { label: 'HR Screening' },
  { label: 'Verification' },
  { label: 'Client Round' },
]


const referenceList = [
  { label: 'Job Portal' },
  { label: 'Apna App' },
  { label: 'Linked In' },
  { label: 'Facebook' },
  { label: 'Naukri.com' },
  { label: 'Monster.com' },
  { label: 'Quickr.com' },
  { label: "Fresher.com" },
  { label: "Shine.com" },
  { label: "Job Hai" },
  { label: "Work India" },
  { label: 'Walk-In' },
]

const BulkDataUpload = (props) => {
  const [jobTitle, setJobTitle] = useState("");
  const [uploadData, setUploadData] = useState();
  const [uploadDataCount, setUploadDataCount] = useState(0);
  const [companyName, setCompanyName] = useState("");
  const [round, setRound] = useState("HR Screening");
  const [interViewerName, setInterViewerName] = useState("");
  const [jobId, setJobId] = React.useState("");
  const [vacancies, setVacancies] = useState();
  const [companies, setCompanies] = useState();
  const [city, setCity] = useState();
  const [area, setArea] = useState();
  const [reference, setReference] = useState("Job Portal");
  const { user } = useContext(UserContext);
  const [hrNames, setHRNames] = useState([])
  const [hrId, setHRId] = useState("")
  const [hrName, setHRName] = useState("")
  const uploadedCount = useRef(0);
  const processedCount = useRef(0);
  const [rowData, setRowData] = useState([]);
  const [wifiSpeed, setWifiSpeed] = useState(0);
  const [username, setUserName] = useState([]);

  var cols = [

    { field: 'id', headerName: 'id', hide: true, headerClassName: 'super-app-theme--header', },
    { field: 'name', headerName: 'Name', width: 200, headerClassName: 'super-app-theme--header', },
    { field: 'total', headerName: 'Total - ' + rowData.reduce((prv, curr) => prv + curr.total, 0), width: 120, headerClassName: 'super-app-theme--header', },
    { field: 'called', headerName: 'Called - ' + rowData.reduce((prv, curr) => prv + curr.called, 0), width: 120, headerClassName: 'super-app-theme--header', },
    { field: 'messaged', headerName: 'Messaged - ' + rowData.reduce((prv, curr) => prv + curr.messaged, 0), width: 120, headerClassName: 'super-app-theme--header', },
    { field: 'pending', headerName: 'Available - ' + rowData.reduce((prv, curr) => prv + parseInt(curr.pending.split('/')[0]), 0) + '/' + rowData.reduce((prv, curr) => prv + parseInt(curr.pending.split('/')[1]), 0), width: 180, headerClassName: 'super-app-theme--header', },

  ];


  useEffect(() => {

    getUsers();
    getCompanies()
  }, []);


  useEffect(() => {
    hrNames && getDashboardValues()

  }, [hrNames]);
  

  const getVacancies = async (company) => {
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/vacancies?company=" + company;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
   await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setVacancies(repos.data)
          setJobId(repos.data[0]._id)
          setJobTitle(repos.data[0].jobtitle)
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }
  const getOfficeVacancies = async (company,office) => {
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/vacancies?company=" + company+"&office"+office;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
   await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setVacancies(repos.data)
          setJobId(repos.data[0]._id)
          setJobTitle(repos.data[0].jobtitle)
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }
  
  //Get Users
  const getUsers = async () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setUserName((repos.data).filter(res => res.accesstype == 0))
          //console.log(repos.data)
          setHRNames(repos.data)
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }

  const getCompanies = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/companies"
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then(async (repos) => {
        if (repos.data) {

          setCompanyName(repos.data[0])
          await getOfficeVacancies(repos.data[0].split(" - ")[0],repos.data[0].split(" - ")[1])
          setCompanies(repos.data)

        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }


  const UploadDataToDB = () => {
    let records = uploadData.split("\n");
    setUploadDataCount(records.length)
    processedCount.current = 0;
    uploadedCount.current = 0;
    let result = records.map(async line => {

      let name = line.split(",")[0].trim()
      // name = name||"".split(' ').map(portion=>portion[0].toUpperCase() + portion.slice(1)).toString().replace(',',' ')

      let mobile = line.split(",")[1].trim()
      let gender = line.split(",").length > 2 ? line.split(",")[2].trim() : "male"
      let city = line.split(",").length > 3 ? line.split(",")[3].trim() : ""
      let area = line.split(",").length > 4 ? line.split(",")[4].trim() : ""
      let currentSalary = line.split(",").length > 5 ? line.split(",")[5].trim() : ""
      let education = line.split(",").length > 6 ? line.split(",")[6].trim() : ""
      let success = await checkduplicate(name, mobile, gender, city, area, currentSalary, education)

      if (success === true) {
        // uploadedCount.current= uploadedCount.current+1
      }
      // processedCount.current= processedCount.current+1
    });
    Promise.all(result).then(() => {
      setUploadData("")
      getDashboardValues()

    });

  }



  const checkduplicate = async (name, mobile, gender, city, area, currentSalary, education) => {

    if (mobile.length >= 10) {
      let phone = mobile.toString().replace('-', '').slice(-10);
      let apiURL = process.env.REACT_APP_API_URL + "/applicants/phone/" + phone;
      let apiParams = {
        method: "GET",
      };
      return await fetch(apiURL, apiParams)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            processedCount.current = processedCount.current + 1;
            return false;

          }
          else {
            processedCount.current = processedCount.current + 1;
            addApplicant(name, mobile, gender, city, area, currentSalary, education);
            return true;
          }

        })
        .catch((err) => { props.showAlert(('' + err), "error"); processedCount.current = processedCount.current + 1; return false; });
    }
    else {
      processedCount.current = processedCount.current + 1;
    }

  }


  const addApplicant = (name, mobile, gender, city, area, currentSalary, education) => {

    let apiURL = process.env.REACT_APP_API_URL + "/applicants/addapplicant";
    let apiParams = {
      method: "POST",
      headers: {
        name: name,
        mobile: mobile,
        gender: gender,
        city: city,
        area: area,
        education: education,
        jobid: jobId,
        jobtitle: jobTitle,
        companyname: companyName.split(" - ")[0],
        currentsalary: currentSalary,
        reference: encodeURIComponent(reference),
        createdbyid: user._id,
        createdbyname: user.name,
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    // console.log(apiParams)
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          uploadedCount.current = uploadedCount.current + 1;
          applyJob(repos.data)
        } else { props.showAlert("" + (repos.message || repos), "error"); }
      })
      .catch((err) => { props.showAlert(('' + err), "error") })
  }
  const applyJob = (applicant) => {
    // console.log(job)
    let applicantid = applicant._id

    let apiURL = process.env.REACT_APP_API_URL + "/interview/applyinterview/" + applicantid;
    let apiParams = {
      method: "POST",
      headers: {
        hrname: hrName,
        name: applicant.name,
        mobile: applicant.mobile,
        jobid: jobId,
        jobtitle: jobTitle,
        companyname: companyName.split(" - ")[0],
        round: round,
        interviewername: hrName,
        createdbyid: hrId,
        createdbyname: hrName,
        reference: encodeURIComponent(reference),
        authtoken: localStorage.getItem("loggedintoken")
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //setOutputMsg([...outputMsg,"\nInterviwer assigned successfully!"]);
        } else {
          //setOutputMsg([...outputMsg,"\nFailed to assign interviewer!"]);
        };
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }

  //Data grid values
  const getDashboardValues = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/dashboard/"
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //console.log(repos.data)
          var passed = repos.data.passed;
          var messaged = repos.data.messaged;
          var failed = repos.data.failed;
          var pending = repos.data.pending;
          var applied = repos.data.applied;
          var verified = repos.data.verified;
          let namesCol = max_of_three(passed, failed, pending, verified)
          // console.log(applied)
          var names = (namesCol).filter(x => hrNames.filter(user => user.name === x._id)[0]);

          let dataOfGrid = names.map((name, id) => {
            return {
              id,
              name: name._id,
              verified: (verified.filter(rec => rec._id === name._id)[0] || { count: 0 }).count,
              passed: (passed.filter(rec => rec._id === name._id)[0] || { count: 0 }).count,
              messaged: (messaged.filter(rec => rec._id === name._id)[0] || { count: 0 }).count,
              failed: (failed.filter(rec => rec._id === name._id)[0] || { count: 0 }).count,
              called: (passed.filter(rec => rec._id === name._id)[0] || { count: 0 }).count + (failed.filter(rec => rec._id === name._id)[0] || { count: 0 }).count,
              pending: (applied.filter(rec => rec._id === name._id)[0] || { count: 0 }).count + "/" + (pending.filter(rec => rec._id === name._id)[0] || { count: 0 }).count,
              total: (passed.filter(rec => rec._id === name._id)[0] || { count: 0 }).count + (failed.filter(rec => rec._id === name._id)[0] || { count: 0 }).count + (pending.filter(rec => rec._id === name._id)[0] || { count: 0 }).count,
              applied: (applied.filter(rec => rec._id === name._id)[0] || { count: 0 }).count
            }
          }).sort((a, b) => b.applied - a.applied);

          setRowData(dataOfGrid);
          var lowestDataName = dataOfGrid.reduce((a, b) => (a.pending ? parseInt(a.pending.split('/')) : 0) < parseInt((a.pending ? b.pending.split('/') : 0)) ? a : b, 0).name

          lowestDataName && Promise.all(lowestDataName).then(() => {
            var lowestDataId = hrNames.filter(hr => hr.name === lowestDataName)[0]._id;

            setHRName(lowestDataName);

            setHRId(lowestDataId)
          })


        }
        else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));

  }
  function max_of_three(x, y, z, a) {
    let max_val;
    if (x.length > y.length) {
      max_val = x;
    }
    else {
      max_val = y;
    }
    if (z.length > max_val.length) {
      max_val = z;
    }
    if (a.length > max_val.length) {
      max_val = a;
    }
    return max_val;
  }


  return (
    <div>
      <table style={{ width: "1200px" }}>
        <tr>
          <td style={{ width: "740px" }}>
            <Box
              sx={{
                height: 300,
                width: 1,
                '& .super-app-theme--header': {
                  backgroundColor: 'cornflowerblue',
                  color: "white",
                },

              }}
            >
              <div style={{ position: "absolute", height: "calc(100vh - 130px)", width: 740, textAlign: "center", verticalAlign: "top", top: "0px" }}>
                <DataGrid
                  initialState={{
                    sorting: {
                      sortModel: [{ field: 'applied', sort: 'desc' }],
                    },
                  }}
                  rows={rowData}
                  columns={cols}
                  onRowClick={(cell) => {

                    setHRName(hrNames.filter(hr => hr.name === cell.row.name)[0].name);
                    setHRId(hrNames.filter(hr => hr.name === cell.row.name)[0]._id);

                  }}

                />

              </div>

            </Box>

          </td>
          <td style={{ textAlign: "left", verticalAlign: "top", top: "0px", width: "400px" }}>
            <div className="border" style={{ width: "400px" }}>
              <h3 className='fnt2'><b>Upload Data</b></h3>
              <table>
                <tr><td colSpan={1} style={{ textAlign: "center" }}>
                  <FormControl variant="outlined" sx={{ width: 300 }} size="small">
                    <InputLabel id="demo-simple-select-label">HR Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-labell"
                      id="demo-simple-select"

                      value={hrId} onChange={(e) => {

                        setHRId(e.target.value);
                        setHRName(hrNames.filter(hr => hr._id === e.target.value)[0].name);
                      }} >
                      {
                        hrNames && hrNames.sort((a, b) => a.name > b.name ? 1 : -1).map(hr => <MenuItem value={hr._id}>{hr.name}</MenuItem>)

                      }
                    </Select>
                  </FormControl>
                </td></tr>

                <tr><td colSpan={1} style={{ textAlign: "center" }}>
                  <FormControl variant="outlined" sx={{ width: 300 }} size="small">
                    <Autocomplete
                      disablePortal
                      id="txtRounds"
                      freeSolo
                      options={rounds}
                      sx={{ width: 300 }}
                      value={round || ""}

                      onChange={(event, value) => setRound(value.label)}
                      renderInput={(params) => <TextField {...params}

                        size="small" label="Interview Round"
                        value={round || ""}
                        onChange={(e) => {
                          setRound(e.target.value);
                        }} />}
                    /></FormControl>
                </td></tr>
                <tr><td colSpan={1} style={{ textAlign: "center" }}>
                  <FormControl variant="outlined" sx={{ width: 300 }} size="small">
                    <InputLabel id="companyName-label">Company Name</InputLabel>
                    <Select
                      labelId="company-name-labell"
                      id="companyName"
                      value={companyName}
                      // defaultValue={companyName}
                      onChange={async (e) => {

                        await getOfficeVacancies(e.target.value.split(" - ")[0],e.target.value.split(" - ")[1]);
                        setCompanyName(e.target.value);
                      }} >


                      {
                        companies && companies.map((company) => <MenuItem value={company}>{company}</MenuItem>
                        )}
                    </Select>
                  </FormControl>
                </td></tr>
                <tr><td colSpan={1} style={{ textAlign: "center" }}>

                  <FormControl variant="outlined" sx={{ width: 300 }} size="small" >
                    <InputLabel id="vacancies-label">Available Jobs</InputLabel>
                    <Select
                      labelId="vacancies-labell"
                      id="vacancies"

                      value={jobId} onChange={(e) => {

                        setJobId(e.target.value);
                        setJobTitle(vacancies.filter(vacancy => vacancy._id === e.target.value)[0].jobtitle);

                      }} >

                      {
                        vacancies && vacancies.map((vacancy) => <MenuItem value={vacancy._id}>{vacancy.jobtitle}</MenuItem>
                        )}
                    </Select>
                  </FormControl>
                </td></tr>

                <tr><td colSpan={1} style={{ textAlign: "center" }}>
                  <FormControl variant="outlined" sx={{ width: 300 }} size="small">
                    <Autocomplete
                      id="txtReference"
                      freeSolo
                      options={referenceList}
                      sx={{ width: 300 }}
                      value={reference || ""}
                      onChange={(event, value) => setReference(value.label)}
                      renderInput={(params) => <TextField {...params}
                        size="small" label="Reference"
                        value={reference || ""}
                        onChange={(e) => {
                          setReference(e.target.value);
                        }} />}
                    /> </FormControl>
                </td></tr>
                <tr><td colSpan={1} style={{ textAlign: "center" }}>
                  <div>
                    <TextField className="" id="outlined-multiline-static"
                      multiline
                      sx={{ m: 3, width: '320px' }}
                      size="small"
                      rows={5}
                      label="Enter Name,Mobile,Gender,City,Area,CurrentSalary,Education" variant="outlined" value={uploadData} onChange={(e) => {
                        let records = [...new Set(e.target.value.split("\n"))].join("\n");
                        setUploadData(records);
                        setUploadDataCount(records.split("\n").length);
                      }} /> </div>
                </td></tr>

                <tr><td colSpan={1} style={{ textAlign: "center" }}>
                  {/* <ReactInternetSpeedMeter
                    txtSubHeading="Internet is too slow"
                    outputType="empty"
                    customClassName={null}
                    txtMainHeading="Opps..."
                    pingInterval={15000} // milliseconds 
                    thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
                    threshold={10}
                    imageUrl="/bizibees.jpeg"
                    downloadSize="18800"  //bytes
                    callbackFunctionOnNetworkDown={(speed) => console.log(`Internet speed is down ${speed}`)}
                    callbackFunctionOnNetworkTest={(speed) => setWifiSpeed(speed)}

                  /> */}
                  <div style={{ zIndex: "10", position: "relative", color: wifiSpeed < 10 ? "red" : "cornflowerblue" }}>Internet speed: {wifiSpeed} mb/s</div>
                  <Button variant="contained" sx={{ width: '35ch' }} onClick={() => UploadDataToDB()}>Upload</Button>
                  <div style={{ border: "1px solid red", position: "relative", textAlign: "center", display: 'flex', justifyContent: 'center' }}><div style={{ height: '20px', display: 'contents' }}>Uploaded:&nbsp;<div style={{ color: 'red', display: "contents" }}><Counter count={uploadedCount.current} /></div>&nbsp;records&nbsp;successfully!</div><br /><div style={{ height: '20px', display: 'contents' }}>Processed:&nbsp;<Counter count={processedCount.current} />&nbsp;of&nbsp;{uploadDataCount}</div></div>
                </td></tr>
              </table>
            </div>
          </td>
        </tr>
      </table>

    </div>

  );
}


export default BulkDataUpload