import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './EmailTemplatesPopup.css';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setSeconds } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { setSelectionRange } from '@testing-library/user-event/dist/utils';




const EmailTemplatesPopup = (props) => {
   
  const styleEmailTemplates = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    width: 650,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    };
  // const [interview, setInterview] = useState([]);
  const [id, setId] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [cc, setCc] = useState("");
  const [bcc,setBcc] = useState("adeeb@bizibees.in,shobha.s@focusedumatics.com");
  const [attachments,setAttachments]= useState("");
  const [subject,setSubject]= useState("Online Grammar Test");
  const [template, setTemplate] = useState("");
  const [body, setBody] = useState("");
  const [applicantName,setApplicantName] = React.useState("");
  // const [applicantId,setApplicantId]= useState()
  const [openPopup, setOpenPopup] = React.useState(false);
  const { user} = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(false);
  
  useEffect(()=>{
    setOpenPopup(props.open)
setApplicantName(props.applicantName)
    
    if(props.open)
      {
       if(props.applicantId){
        // setTo(props.toEmail)
        setCc(user.email)
       }
        
      }else{
        props.close()
      }
  
    },[props.open])

    useEffect(()=>{
      
      if(props.openPopup)
       {
      setOpenPopup(props.openPopup)
      setApplicantName(props.data.applicantname)
      setFrom(props.data.from)
      setTo(props.data.to)
      setCc(props.data.cc)
      setBcc(props.data.bcc)
      //setAttachments(props.data.attachment)
      setSubject(props.data.subject)
      setBody(props.data.text)

         
       }else{
         props.close()
       }
   
     },[props.openPopup])

    useEffect(()=>{
      if(props.jobTitle==="Support Executive")
      {
        setSubject("Online Grammar Test")
        setAttachments("")
        setBody("Congratulations "+props.applicantName+"!\n\nYou have cleared the HR Screening round for Support Executive position.\n\nNext round of interview is an online test. Kindly visit the below URL to take up the test.\n\nhttps://rebrand.ly/campusdrive-Grammar\n\nDuration of the test is 30 mins, please do not close the browser or move to another page after starting the test, as it will fail the test and you will not be able to attempt it again.\n\nAll the best!!\n\nWarm Regards,\n"+user.name+"\n\nFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing")
      }
      else
      {
        setSubject("MIS Executive - Skills assessment")
        setAttachments("https://jobs.bizibees.in/MIS_Skills_Test.xlsx")
      setBody("Congratulations "+props.applicantName+"!\n\nYou have cleared the HR Screening round for MIS Executive position.\n\nFor next round of interview you have to download the attached excel file and create the reports as instructed in each worksheet of the excel.\n\nAfter completing the tasks, attach your resume along with the completed excel file and email it to shobha.s@focusedumatics.com, keeping me in cc.\n\nAll the best!!\n\nWarm Regards,\n"+user.name+"\n\nFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing")
}},[props.jobTitle])
  
  const handleClose = () =>{
  
    props.close();
    setOpenPopup(false);
    
    }
  
const SendEmail= ()=>{
  if (!to.includes('.') ||to.split('@').length!=2) {
    alert("Please Enter Valid Email Id")
    return;
  }
  if (subject.length === 0) {
    alert("Please Enter Email Subject")
    return;
  }
  
  
  setOpenPopup(false);
  let apiURL=process.env.REACT_APP_API_URL + "/users/sendemail";
  let apiParams = {
      method:"POST",
      headers:{
          userid:user._id,
          username:user.name,
          applicantname:props.applicantName=="undefined"?"":props.applicantName,
          mobile:props.applicant.mobile=="undefined"?"":props.applicant.mobile,
          location:props.applicant.area=="undefined"?"":props.applicant.area,
          currentctc:props.applicant.previousCurrent=="undefined"?"":props.applicant.previousCurrent,
          expectedctc:props.applicant.expectedSalary=="undefined"?"":props.applicant.expectedSalary,
          experience:props.applicant.totalExperience=="undefined"?"":props.applicant.totalExperience,
          noticeperiod:props.applicant.noticePeriod=="undefined"?"":props.applicant.noticePeriod,
          jobtitle:props.jobTitle,
          to: to,
          cc: cc,
          bcc: bcc,
          subject: subject,
          attachments: attachments,
          body:encodeURIComponent(body),
      },
  };
   //console.log(apiParams)
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        
     
      setTimeout(() => {
        props.showAlert(repos.data,"success")
      }, 2000);
       
        
      
      }else props.showAlert((repos.message || repos|| "Error while sending email!"),"error");
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}
  return (
    
    <Modal
    open={openPopup}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
    sx={{overflow:'scroll',textAlign:"center",width:"100%"}}
  >
  
    <Box sx={styleEmailTemplates}>
    <div>
      <Typography id="modal-modal-description">
      <Container style={{padding:"0px"}}>
      <div className = "EmailTemplateContainer">
    <div style={{textAlign:"center"}}>
      <h4 className="fnt3"><b>{props.jobTitle?props.jobTitle:props.data && props.data.applicantname}</b> <Button disabled={isLoading} title="Close" variant="Standard" style={{float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>handleClose()} ><CloseIcon /></Button></h4> 
     </div>
   
     <table border="0">
    {/* <tr><td style={{textAlign:"center"}}>
   <FormControl variant="outlined" sx={{width: "100%",marginRight:"auto",marginTop:"10px"}} size="medium" >
    <InputLabel id="lblEmailTemplates" >Email Templates</InputLabel>
    <Select
      labelId="lblEmailTemplates"
      id="ddlEmailTemplates"
      sx={{minWidth: 160}}
      value={template} onChange={(e)=> {
      
        setTemplate(e.target.value);   

       } } >
    
      <MenuItem selected value="FEGrammarTest">Focus Edumatics - Grammar Test</MenuItem>
      <MenuItem value="FEMISTest">Focus Edumatics - MIS Test</MenuItem>
    </Select>
  </FormControl> 
</td></tr> */}
    <tr>
    <td style={{textAlign:"center"}}>  
  <TextField id="txtFrom" sx={{width: "100%",marginLeft:"auto",marginTop:"10px" }} size="small" label="From Email" variant="outlined"  defaultValue={"info@bizibees.in"} onChange={(e)=> {
    setFrom(e.target.value);     
  }}/> </td></tr>
    <tr>
    <td style={{textAlign:"center"}}>  
    

  <TextField id="txtTo" sx={{width: "100%",marginLeft:"auto",marginTop:"10px" }} size="small" label="To Email" variant="outlined"  value={to || ""} onChange={(e)=> {
    setTo(e.target.value);     
  }}/> </td></tr>
    <tr>
    <td style={{textAlign:"center"}}>  
  <TextField  id="txtCc"  sx={{ width: "100%", marginLeft:"auto",marginTop:"10px" }}  size="small" label="Cc" variant="outlined"  value={cc || ""} onChange={(e)=> {
      setCc(e.target.value);
  }}/>
    </td>
    </tr>
    <tr>
    <td style={{textAlign:"center"}}>     
     <TextField id="txtBcc"  sx={{ width: "100%",marginRight:"auto",marginTop:"10px" }}  type="text" size="small" label="Bcc" variant="outlined"  value={bcc}
       onChange={(e)=> {
        setBcc(e.target.value);  
    }}/> 
    
    </td>
    </tr>
    <tr>
    <td>  
    Attachments: {attachments.split(',').map(attachment=><><a id="link" href={attachment} target="_blank" download>{attachment}</a><br/></>
    )}</td>
    </tr>
    <tr>
    <td style={{textAlign:"center"}}>     
     <TextField id="txtSubject"  sx={{ width: "100%",marginRight:"auto",marginTop:"10px" }}  type="text" size="small" label="Subject" variant="outlined"  value={subject} 
       onChange={(e)=> {
        setSubject(e.target.value);  
    }}/> 
    
    </td>
    </tr>
    
     <tr><td style={{textAlign:"center"}}>
      <TextField
      id="outlined-multiline-flexible"
      sx={{width: "100%" }}
      size="small"
      label="Email Body"
      multiline
      rows={10} value={body || ""} 
      onChange={(e)=> {
      setBody(e.target.value);
    }} />
    </td></tr>
     
    <div style={{textAlign:"center"}}>
 
 {props.data ?"": <Button disabled={isLoading} type="reset" title={"Send Email"}  variant="contained" onClick={SendEmail}>Send Email</Button>} &nbsp; &nbsp;
  &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
 </div>
 </table>
</div>
 
  </Container> 
      </Typography>  
       </div>
    </Box>
  </Modal>
  
  )
  
}

export default EmailTemplatesPopup
