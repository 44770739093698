import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import "../Styles/AdminVerificationData.css";
import { UserContext } from '../UserContext';


const AdminVerificationData = (props) => {

   
  const { user, setUser} = useContext(UserContext);
  const alertStart = useRef(new Date());
  const [rowData,setRowData] = useState([])

useEffect(()=>{
if(props.rowData){
  setRowData(props.rowData)
}

},[props.rowData])

useEffect(()=>{


},[props.showOnlyHR,props.users,props.fromDate,props.toDate])


//View Data 
const viewData = (row) =>{

  window.location.href= "/interviewdetails?selectedCell=" + row;
  }

  return (

    <div id="BottmPartVeriactivity">
       <div id="BottmPartVeriGroup_36">
        <div id="BottmPartVeriInterview" onClick={()=>props.selectedTab("interviews")}>
          <span>Interviews</span>
        </div>
        <div id="BottmPartVeriVerification">
          <span>Verifications</span>
        </div>
        <div id="BottmPartVeriothers" onClick={()=>props.selectedTab("duration")}>
          <span>Duration</span>
        </div>
        
        <div id="BottmPartVerioth" onClick={()=>props.selectedTab("others")}>
          <span>Others</span>
        </div>
        
          <div id="BottmPartVeriActivity_w" onClick={()=>props.selectedTab("activity")}>
            <span>Activity</span>
          </div>
      </div> 
       
      <div id="BottmPartVeriGroup_39">
        <svg class="BottmPartVeriRectangle_46">
          <rect id="BottmPartVeriRectangle_46" rx="24" ry="24" x="0" y="0" width="100%" height="100%">
          </rect>
        </svg>
        {rowData.map((row)=>(
        <div id="BottmPartVeriGroup_37">
          <div id="BottmPartVeriRoobi">
            <span>{row.name}</span>
          </div>
          
          <div id="BottmPartVerin_037" onClick={()=>(user.accesstype == 5 || row.name=== user.name) && viewData((["verificationFailed",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.verificationFailed}</span>
          </div>
          <div id="BottmPartVerin_510545" onClick={()=>(user.accesstype == 5 || row.name=== user.name) && viewData((["verificationPending",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.verificationPending}</span>
          </div>
          <div id="BottmPartVeriMohammed" onClick={()=>(user.accesstype == 5 || row.name=== user.name) && viewData((["verified",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.verified}</span>
          </div>
          <img id="BottmPartVerispeaker-filled-audio-tool" src="speaker-filled-audio-tool.png" srcset="speaker-filled-audio-tool.png 1x" />
            
        </div>
        ))} 
        <div id="BottmPartVeriGroup_38">
          <div id="BottmPartVeriStatus">
            <span>Passed</span>
          </div>
          <div id="BottmPartVerin_passed">
            <span>{(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.verified,0)}</span>
          </div>
          <div id="BottmPartVeriTime">
            <span>Failed</span>
          </div>
          <div id="BottmPartVerin_failed">
            <span>{(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.verificationFailed,0)}</span>
          </div>
          <div id="BottmPartVeriQueue">
            <span>Pending</span>
          </div>
          <div id="BottmPartVerin_queue">
            <span>{(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.verificationPending.split('/')[0]),0)+"/"+(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.verificationPending.split('/')[1]),0)}</span>
          </div>
          <svg class="BottmPartVeriLine_2" viewBox="0 0 225.87 1">
            <path id="BottmPartVeriLine_2" d="M 0 0 L 225.86962890625 0">
            </path>
          </svg>
        </div>
      </div>
   
  </div>
  )
}

export default AdminVerificationData
