import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import './ShareJDPopUpUI.css'


const ShareJDPopUpUI = (props) => {
    const { user, setUser} = useContext(UserContext);
    const [openShareJD,setOpenShareJD ]= useState(false);
    const [jobTitle,setJobTitle ]= useState("");


    useEffect(()=>{
      
            if(props.openShareJD){
                setOpenShareJD(props.openShareJD)
                if(props.vacancy){
                  if(props.type === "Recruiter"){
                    setJobTitle(props.vacancy.jobTitle)
                  }else{
                    setJobTitle(props.vacancy)
                  }
                        
      
              }
            }
        
        
         },[props.openShareJD]) 

    const handleClose = ()=>{
        props.closeShareJD()
        setOpenShareJD(false)
    }


    const SendRecruiterWhatsapp = ()=>{
   
      handleClose()
      let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?text=Good Day,%0a%0aI am hiring "+jobTitle+". View the job details and apply here:%0a%0ahttps://jobs.bizibees.in/vacancies";
      const newWindow = window.open(apiURL, '_blank')
      }

    const SendCandidateWhatsapp = ()=>{
 
      handleClose()
      let apiURL="https://"+(user && user.device === "mobile"?"api":"web")+".whatsapp.com/send/?text=Good Day,%0a%0aI would like to refer you, "+jobTitle+" vacancy. View the job details and apply here:%0a%0ahttps://jobs.bizibees.in/vacancies";
      const newWindow = window.open(apiURL, '_blank')
      }

      const CopyUrl =async () =>{
        await navigator.clipboard.writeText("https://jobs.bizibees.in/vacancies");
        props.showAlert("Copied To ClipBoard","success")
      }
        

  return (
    <div>
      <Modal
        open={openShareJD}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
 <div id="share_popup" >
	<img id="bg_img_share_" src="bg_img_share_.png" srcset="bg_img_share_.png 1x" />
		
	<div id="Share_lbl">
		<span>Share</span>
	</div>
	<div id="whatsapp_grp_share" onClick={()=>props.type==="Recruiter"?SendRecruiterWhatsapp():SendCandidateWhatsapp()}>
		<div id="WhatsApp_lbl">
			<span>WhatsApp</span>
		</div>
		<svg class="bdr">
			<ellipse id="bdr" rx="30" ry="30" cx="30" cy="30">
			</ellipse>
		</svg>
		<img id="whatsapp_img" src="whatsapp_img_share_jd.png" srcset="whatsapp_img_share_jd.png 1x" />
	</div>

	<div id="grp_copy" onClick={()=>CopyUrl()}>
		<div id="Copy_lbl">
			<span>Copy</span>
		</div>
		<svg class="btn_copy">
			<ellipse id="btn_copy" rx="30" ry="30" cx="30" cy="30">
			</ellipse>
		</svg>
		<img id="copy" src="copy_share_jd.png" srcset="copy_share_jd.png 1x" />
			
	</div>
	<img id="close1_btn_pop_art_share" src="close1_btn_pop_art_share.png" srcset="close1_btn_pop_art_share.png 1x" onClick={()=>handleClose()}/>
		
</div>
</Modal>
    </div>
  )
}

export default ShareJDPopUpUI
