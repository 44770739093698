import React, { useState, useEffect, useContext } from "react";
import { Button } from "@mui/material";
import "./Users.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
// import { id } from 'date-fns/locale';
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { autocompleteClasses } from "@material-ui/core";

var CryptoJS = require("crypto-js");


const UserProfile = (props) => {
  const style6 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 445,
    maxWidth: 500,
    scrollY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const style7 = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 250,
    maxWidth: 350,
    margin: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  const label = { inputProps: { "aria-label": "Schedule Interview" } };

  const [OpenShedule, setOpenScedule] = React.useState();
  const [searchParams] = useSearchParams();
  
  const { user, setUser } = useContext(UserContext);
  var navigate = useNavigate();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState(new Date());
  const [img,setImg]= useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [country, setCountry] = useState("");
  const [hourlyRate,setHourlyRate] = useState("");
  const [currency, setCurrency] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [profession,setProfession] = useState("");
  const [active, setActive] = useState(true);
  const [confirmPass, setConfirmPass] = useState("");
  const [password, setPassword] = useState("");
  const [pincode, setPincode] = useState("");
  const [introduction,setIntroduction] = useState("");
  const [openChgPwd, setOpenChgPwd] = React.useState(false);
  const [previousPass,setPreviousPass]= useState("");
  const [isHr,setIsHr] = useState(false);


  
  useEffect(() => {
   if(user){
    getUserProfile()
   }

  }, [user]);

  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }


  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

  const handleImageUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    handleFileSelect(e.target.files[0]);
    let formdata = new FormData();
    formdata.append("image", e.target.files[0], e.target.value);
    updateProfilePic(formdata);
  };
  const updateProfilePic = (formdata) => {
    const apiurl = process.env.REACT_APP_API_URL + "/users/updateprofilepic/"+user._id;
    var apiparam = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      },
      body:formdata,
    };

    fetch(apiurl, apiparam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
        props.showAlert("Image Uploaded Successfully!!", "success");  
          //alert("Image Uploaded Successfully!!");
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };

  const getUserProfile = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/getUserDetails/"+user._id;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setEmployees(repos.data);
          setAddress(repos.data.address && repos.data.address);
          setDob(repos.data.dob ?repos.data.dob.substring(0,10):"");
          setEmail(repos.data.email && repos.data.email);
          setName(repos.data.name && repos.data.name);
          setGender(repos.data.gender && repos.data.gender);
          setMobile(repos.data.phone && repos.data.phone);
          setIsHr(repos.data.isHr && repos.data.isHr);
          setImg(repos.data.image && arrayBufferToBase64(repos.data.image.data.data));
          
        } else props.showAlert(""+(repos.message || repos),"error");
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };

  const UpdateEmployeesDetails = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/updateProfile/" + user._id;
    let apiParams = {
      method: "POST",
      headers: {
              mobile: mobile,
              dob:dob,
              authtoken: localStorage.getItem("loggedintoken")
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          //alert("User Updated Successfully")
           props.showAlert("User Updated Successfully", "success");
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
        }
      })
      .catch((err) => props.showAlert((''+err),"error"));
  };

      
  async function encrypt(plainText) {
    return await CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
}
const PasswordChange = async ()=>{
  if (password !== confirmPass) {
    alert("Password and Confirm Password don't match!!");
    return;
  }
  setOpenChgPwd(false);
  let apiURL = process.env.REACT_APP_API_URL + "/users/updatePassword/" + user._id;
  let apiParams = {
    method: "POST",
    headers: {
           previouspass:encodeURIComponent(await encrypt(previousPass)),
           newpassword:encodeURIComponent(await encrypt(password)),
          authtoken: localStorage.getItem("loggedintoken")
    },
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
        setIsLoading(false);
        //alert("Password Changed Successfully!!!")
        props.showAlert("Password Changed Successfully!!!", "success");
        handlelogout()
      } else props.showAlert(""+(repos.message || repos),"error");;
    })
    .catch((err) => props.showAlert((''+err),"error"));

}

const handlelogout = () =>{
  localStorage.clear();
  setUser(null);
  window.location.href = "/";
 
}

 
  const handleClosePwd = () => {
    setOpenChgPwd(false);
   
  };
  

  // const rowData = (employees)?.map((employee) => {

  // return{
  //   id:employee?._id,
  //   name:employee?.name,
  //   reportingToId:employee?.reportingToId,
  //   reportingToName:employee?.reportingToName,
  //   email:employee?.email,
  //   mobile:employee?.phone,
  //   gender:employee?.gender,
  //   accesstype:employee?.accesstype,
  //   active:employee?.active,
  // };
  // });

  return (
    <div>
      {/* <Button size='small' variant="contained" className='intern' title={"Add User"}  onClick={()=>addEmployee()}>+ Add User</Button> */}
      <br />
      <br />
      <br />
      <div style={{ height: 750, width: "100%" }}>

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        > */}
          <Box sx={style6}>
            <div>
              <Typography id="modal-modal-description">
                <Container style={{ padding: "0px" }}>
                  <div className="content7">
                    <div>
                      <h4 className="fnt2">
                        <b>My Profile</b>

                      </h4>
                    </div>
                    <br/>
                    <div style={{textAlignLast:"center"}}>
                        <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                      <img width="100" height="100" src={img?img:"default.jpg"} alt="Reload?" style={{borderRadius:"75px",border:"1px solid black"}}/>
                      </label>
                    </div>
                    <br/>
                    <div style={{ textAlign:"-webkit-center", margin: "2px" }}>
                      <table>
                        <tr>
                          <td>
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              label="Name"
                              disabled
                              variant="outlined"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                            />
                          </td>
                          <td>
                            <TextField
                              id="outlined-basic"
                              type="number"
                              size="small"
                              label="Mobile No"
                              variant="outlined"
                              value={mobile}
                              onChange={(e) => {
                                setMobile(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <FormControl
                              variant="outlined"
                              sx={{ minWidth: 220 }}
                              disabled
                              size="small"
                            >
                              <InputLabel id="demo-simple-select-label">
                                Gender
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-labell"
                                id="demo-simple-select"
                                value={gender}
                                onChange={(e) => {
                                  setGender(e.target.value);
                                }}
                              >
                                <MenuItem value="male">Male</MenuItem>
                                <MenuItem value="female">Female</MenuItem>
                                <MenuItem value="others">Others</MenuItem>
                              </Select>
                            </FormControl>
                          </td>
                          <td>
                            <div >
                              DOB:{" "}
                              <input
                              style={{ width: 170,height:30,fontSize:18 }}
                                type="date"
                                value={dob}
                                disabled 
                                // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
                                onChange={(value) => {
                                  setDob(value.target.value);
                                }}
                              />
                              &nbsp;
                            </div>
                          </td>
                        </tr>
                        
                        <tr>
                        
                          <td>
                          
                            <TextField
                              id="outlined-basic"
                              size="small"
                              label="Email"
                              disabled
                              variant="outlined"
                              value={email || ""}
                              onChange={(e) => {
                                setEmail(e.target.value);
                              }}
                            />

                          </td>
                          <td>
                          Is HR? {(isHr == true)?"Yes":"No"}  
                          </td>
                        </tr>
                      </table>
                    </div>
                    <br />

                    <div style={{ textAlign: "center", margin: "5px" }}>
                      <Button
                        disabled={isLoading}
                        type="reset"
                        title={"Update"}
                        variant="contained"
                        style={{marginRight:"5px"}}
                        onClick={() => UpdateEmployeesDetails()}
                      > 
                        Update Profile
                      </Button>
                      <Button
                        disabled={isLoading}
                        title={"Change Password"}
                        variant="contained"
                        style={{marginLeft:"5px"}}
                        onClick={() => setOpenChgPwd(true)}
                      >
                        Change Password
                      </Button>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {isLoading ? <CircularProgress /> : ""}
                    </div>
                  </div>
                </Container>
              </Typography>
            </div>
          </Box>
        {/* </Modal> */}

        <Modal
          open={openChgPwd}
          onClose={handleClosePwd}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
         
        > 
          <Box sx={style7}>
            <div>
              <Typography id="modal-modal-description">
                <Container style={{ padding: "0px" }}>
                  <div className="content8">
                    <div>
                      <h4 className="fnt2">
                        <b>Change Password</b>

                        <Button
                          title="Close"
                          size="small"
                          variant="Standard"
                          sx={{
                            bgcolor: "red",
                            float: "right",
                            right: 0,
                            top: 0,
                            position: "absolute",
                            height: 35,
                            width: 5,
                          }}
                          onClick={() => handleClosePwd()}
                        >
                          <CloseIcon
                            style={{ height: "25px", width: "25px" }}
                          />{" "}
                        </Button>
                      </h4>
                    </div>
                
                    <div style={{ textAlign:"-webkit-center", margin: "10px" }}>
                      <table>
                      <tr>
                        <td colSpan={2} style={{textAlign:"center"}}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              label="Enter Previous Password"
                              variant="outlined"
                              type="password"
                              value={previousPass || ""}
                              onChange={(e) => {
                                setPreviousPass(e.target.value);
                              }}
                             
                            />
              
     
                          </td>
                        </tr>
                  
                        <tr>
                          <td colSpan={2} style={{textAlign:"center"}}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              label="Enter Password"
                              variant="outlined"
                               type="password"
                              value={password || ""}
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                            />
                          </td>
                         
                        </tr>
                        <tr>
                        <td colSpan={2} style={{textAlign:"center"}}>
                            <TextField
                              id="outlined-basic"
                              size="small"
                              label="Confirm Password"
                              variant="outlined"
                              type="password"
                              value={confirmPass || ""}
                              onChange={(e) => {
                                setConfirmPass(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                      
                      </table>
                    </div>
                    <br />

                    <div style={{ textAlign: "center", marginBottom: "5px" }}>
                      <Button
                        disabled={isLoading}
                        title={"Update"}
                        variant="contained"
                        //style={{ backgroundColor: "#14c871" }}
                        onClick={() => PasswordChange()}
                      >
                        Change
                      </Button>
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {isLoading ? <CircularProgress /> : ""}
                    </div>
                  </div>
                </Container>
              </Typography>
            </div>
          </Box>
        </Modal>

      </div>
     
    </div>
  );
};

export default UserProfile;
