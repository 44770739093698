import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { UserContext } from './UserContext';
import TimePicker from '@mui/lab/TimePicker';
import { useSearchParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Autocomplete, Select } from '@mui/material';
import BulkDataUpload from './BulkDataUpload';
import Checkbox from '@mui/material/Checkbox';
import Timer from './Timer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ApplicantPopup from './ApplicantPopup';

// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

const countList = [
  { label: '10' },
  { label: '25' },
  { label: '50' },
  { label: '100' },
]
const NewApplicants = (props) => {
  
const [searchParams] = useSearchParams();
const [messages, setMessages] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [name, setName] = useState();
const [mail, setMail] = useState();
const [mobile, setMobile] = useState();
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState();
const [applicantId,setApplicantId]= useState()
const [companyName, setCompanyName] = useState();
const [open, setOpen] = React.useState(false);
const [Rounds, setRounds] = useState();
const [InterViewerName, setInterViewerName]= useState("");
const [InterViewDate, setInterViewDate] = React.useState(new Date());
const [InterViewTime, setInterViewTime] = React.useState();
const [interviewlocation, setInterviewlocation] = useState();
const [selectedRow,setSelectedRow] = useState();
const [openApplicant,setOpenApplicant] = useState(false)
const [hrNames, setHRNames] = useState([])
const [hrId, setHRId] = useState("")
const [hrName, setHRName] = useState("")
const [count, setCount] = useState("")
const [username, setUserName] = useState([]);
const [vacancies, setVacancies] = useState();
const [companies, setCompanies] = useState();
const [uploadData, setUploadDataCount] = useState();
const [isLoading, setIsLoading] = useState(false);
const { user, setUser} = useContext(UserContext);


//UseEffect
useEffect (()=>{
  getUsers();
  getCompanies();
  getNewApplicants()
},[])

useEffect (()=>{
},[jobId])
const getNewApplicants = async ()=>{
  
    let apiURL="";
  
    apiURL=process.env.REACT_APP_API_URL + "/applicants/getnewapplicants";
    let apiParams = {
        method:"GET", 
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
    
   await fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          console.log(repos.data.length + " Records Found!")
          setMessages(repos.data);
          getUsers();
          
        }else props.showAlert(""+(repos.message || repos),"error");  
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const getVacancies = async (company) => {
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/vacancies?company=" + company;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
   await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // console.log(repos.data)
          setVacancies(repos.data)
          setJobTitle(repos.data[0].jobtitle)
          setJobId(repos.data[0]._id)
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }
  const getOfficeVacancies = async (company,office) => {
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/vacancies?company=" + company+"&office="+office;
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
   await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // console.log(repos.data)
          setVacancies(repos.data)
          setJobTitle(repos.data[0].jobtitle)
          setJobId(repos.data[0]._id)
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }
  //Get Users
  const getUsers = async () => {
    let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setUserName((repos.data).filter(res => res.accesstype == 0))
          //console.log(repos.data)
          setHRNames(repos.data)
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }

  const getCompanies = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/companies"
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then(async (repos) => {
        if (repos.data) {
          setCompanies(repos.data)
          await getOfficeVacancies(repos.data[0].split(" - ")[0],repos.data[0].split(" - ")[1])
          setCompanyName(repos.data[0])

        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }





  const rowData = (messages||[])?.map((msg) => {
  

    return{
  id: msg?._id,  
  name: msg?.name,
  mobile: msg?.mobile,
  gender: msg?.gender,
  city: msg?.city,
  area: msg?.area,
  education: msg?.education,
  currentSalary : msg?.currentSalary,
  reference: msg?.reference,
 
   };
  });
  

const [filterModel, setFilterModel] = React.useState({
  items: user.accesstype == 5 ? [
    // {columnField: 'rounds', operatorValue: 'contains', value:"Verification"}
  ]: [
    // {columnField: 'hrname', operatorValue: 'contains', value:user.name}
  ],
});



const cols = [

  {field:'id', headerName: '',width: 190,hide: true },
  {field:'name', headerName: 'Name',width: 190 },
  {field:'mobile', headerName: 'Mobile',width: 190 },
  {field:'gender', headerName: 'Gender',width: 180, headerAlign:'center', align:"center"},
  {field:'city', headerName: 'City',width: 180, headerAlign:'center', align:"center"},
  {field:'area', headerName: 'Area',width: 180, headerAlign:'center', align:"center"},
  {field:'education', headerName: 'Education',width: 180, headerAlign:'center',},
 
  {field:'currentSalary', headerName: 'Current Salary',width: 180, headerAlign:'center', align:"center"},
  {field:'reference', headerName: 'Reference',width: 180, headerAlign:'center', align:"center"},
  ];

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  
  


const ResendWhatsapp = (row)=>{

let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+row.mobile.trim().replace(' ','').substring(row.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+row.name+",%0a%0aI am contacting you for a job vacancy. Please let me know the best time to call you.%0a%0aMy name is "+user.name+", I am from Bizi Bees Outsourcing Private Limited. You can call me on "+user.phone+" as well at your convenient time to discuss about the vacancy.%0a%0aPlease follow us on our LinkedIn page url below for latest updates.%0a%0ahttps://www.linkedin.com/company/bizi-bees-outsourcing";
const newWindow = window.open(apiURL, '_blank')
}
  
const editApplicants = (row) => {
// console.log(row)
  setApplicantId(row.applicantId|| "");
  setOpenApplicant(true);
}


  
  const scheduleinterview = (row) =>{

    setSelectedRow(row)
    
        setOpenScedule(true);
    
      }
    

      const Refresh = () => {
        getNewApplicants();
      }

  const UploadDataToDB = async () => {
    setIsLoading(true);
    if(!companyName || companyName.length < 2)
    {
      alert("Please select Company!")
      return;
    }
    if(!jobId || jobId.length < 2 || vacancies.filter(rec=>rec.companyname === companyName.split(" - ")[0] && rec._id === jobId).length === 0)
    {
      alert("Please select correct Vacancy!")
      return;
    }
    if(!hrId || hrId.length < 10)
    {
      alert("Please select HR!")
      return;
    }
    if(isNaN(parseInt(count)) || count < 1)
    {
      alert("Please enter valid Count!")
      return;
    }
    let apiURL = process.env.REACT_APP_API_URL + "/applicants/assign"
    let apiParams = {
      method: "POST",
      headers: {
        authtoken: localStorage.getItem("loggedintoken"),
        hrid: hrId,
        hrname: hrName,
        companyname:companyName.split(" - ")[0],
        jobid:jobId,
        jobtitle: jobTitle,
        count:count
      }
    };
    await fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        RefreshList()
      })
      .catch((err) => props.showAlert(('' + err), "error"));
  }
 

  const RefreshList = () =>{
    setTimeout(async ()=>
      {
        const count = messages.length
        await getNewApplicants();
        let newCount = messages.length;
        if (count === newCount)
        {
          setTimeout(()=>setIsLoading(false),2000)
        }
        else
        {
          RefreshList();
        }
      }
        ,5000)
  }

  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }



  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
                  <FormControl variant="outlined" sx={{ width: 300 }} size="small">
                    <InputLabel id="companyName-label">Company Name</InputLabel>
                    <Select
                      labelId="company-name-labell"
                      id="companyName"
                      value={companyName}
                      // defaultValue={companyName}
                      onChange={async (e) => {

                        await getOfficeVacancies(e.target.value.split(" - ")[0],e.target.value.split(" - ")[1])
                        setCompanyName(e.target.value);
                      }} >


                      {
                        companies && companies.map((company) => <MenuItem value={company}>{company}</MenuItem>
                        )}
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" sx={{ width: 300 }} size="small" >
                    <InputLabel id="vacancies-label">Available Jobs</InputLabel>
                    <Select
                      labelId="vacancies-labell"
                      id="vacancies"

                      value={jobId} onChange={(e) => {
                        setJobTitle(vacancies.filter(vacancy => vacancy._id === e.target.value)[0].jobtitle);

                        setJobId(e.target.value);

                      }} >

                      {
                        vacancies && vacancies.map((vacancy) => <MenuItem value={vacancy._id}>{vacancy.jobtitle}</MenuItem>
                        )}
                    </Select>
                  </FormControl> 
       <FormControl variant="outlined" sx={{ width: 300 }} size="small">
                    <InputLabel id="demo-simple-select-label">HR Name</InputLabel>
                    <Select
                      labelId="demo-simple-select-labell"
                      id="demo-simple-select"

                      value={hrId} onChange={(e) => {

                        setHRId(e.target.value);
                        setHRName(hrNames.filter(hr => hr._id === e.target.value)[0].name);
                      }} >
                      {
                        hrNames && hrNames.sort((a, b) => a.name > b.name ? 1 : -1).map(hr => <MenuItem value={hr._id}>{hr.name} - {hr.count}</MenuItem>)

                      }
                    </Select>
                  </FormControl>
                  <FormControl variant="outlined" sx={{ width: 110 }} size="small">
                    <Autocomplete
                      id="txtCount"
                      freeSolo
                      options={countList}
                      sx={{ width: 100 }}
                      value={count || "0"}
                      onChange={(event, value) => setCount(value.label)}
                      renderInput={(params) => <TextField {...params}
                        size="small" label="Count"
                        value={count || "0"}
                        onChange={(e) => {
                          setCount(e.target.value);
                        }} />}
                    /> </FormControl>
                  <FormControl variant="outlined" sx={{ width: 90 }} size="small"><Button variant="contained" sx={{ width: '10ch' }} disabled={isLoading} onClick={async() => {await UploadDataToDB();setIsLoading(false);}}>Assign</Button></FormControl>
                  <FormControl variant="outlined" sx={{ width: 30 }} size="small"><Button variant="contained" sx={{ width: '10ch' }} onClick={() => Refresh()}>Refresh</Button></FormControl>
               <div style={{height: "calc(100% - 35px)",width: '100%'}}>
      <DataGrid rows={rowData}
                        columns={cols}  
                        // checkboxSelection
               components={{
                  Toolbar: GridToolbar,
                  }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
            //    onRowDoubleClick={(e)=>editApplicants(e.row)}
           />

 </div>

 {/* <ApplicantPopup openApp={openApplicant} showAlert={props.showAlert} id={applicantId} closeApp={()=>{setOpenApplicant(false);}} />  */}
 
</div>
  ) 
}

export default NewApplicants







