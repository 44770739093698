import React, {useState, useEffect} from 'react'

const Counter = (props) => {
    const [count,setCount]=useState(0);


    useEffect(() => {
setCount(props.count);
    },[props.count])

    return (
        <>{count.toString()}</>
    )
}
export default Counter