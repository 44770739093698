import React,{useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import './UserPasswordResetUI.css';
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from './UserContext';
import { useSearchParams } from 'react-router-dom';
var CryptoJS = require("crypto-js");


const UserPasswordResetUI = (props) => {
    var navigate = useNavigate();
    const [ email,setEmail] = useState("");
    // const [ applicantID,setApplicantID] = useState("");
    const [otp,setOtp] = useState("");
    const [searchParams] = useSearchParams();
    const [inputField,setInputField] = useState({
        password:"",
        cpassword:"",
    });
    const [errField,setErrField] = useState({
        // otpCodeErr:"",
        passwordErr:"",
        cpasswordErr:"",
    });

    async function encrypt(plainText) {
        return CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
      }

    useEffect(() => {

        if(searchParams.get('otp')){
      
        setOtp(searchParams.get('otp'))

       }
       if(otp){
        getEmailID()
       }
    }, [otp])
      
   
    const validForm = () =>{
        let formIsValid = true;
        setErrField({
        //otpCodeErr:"",
        passwordErr:"",
        cpasswordErr:"",
        })
        // if(inputField.otpCode == ''){
        //    formIsValid = false
        //     setErrField(prevstate => ({
        //         ...prevstate,otpCodeErr:"Please Enter OTP"
        //     }))
        // }
        if(inputField.password == ''){
           formIsValid = false
            setErrField(prevstate => ({
                ...prevstate,passwordErr:"Please Enter Password"
            }))
        } 
       
        if(inputField.password != inputField.cpassword){
           formIsValid = false
            setErrField(prevstate=>({
                ...prevstate,cpasswordErr:'Password are Not Matched'
            }))
        }
        
        return formIsValid

    }

    const inputHandler = (name,value)=>{
        setInputField({...inputField,[name]:value})
    }

    const sendUpdatedPassword = async()=>{
        if(validForm()){   
        
            let apiUrl = process.env.REACT_APP_API_URL + "/users/setapplicantpassword";
            let apiParam = {
                  method: 'POST',
                    headers:{
                         email:email,
                        //  applicantid:applicantID,
                         otp:otp,
                         password:encodeURIComponent(await encrypt(inputField.password)),
                         cpassword:encodeURIComponent(await encrypt(inputField.cpassword)),
                    },
         
                };
            
        fetch(apiUrl, apiParam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.status === 200) {
            
            props.showAlert("Password has been reset successfully","success")
        navigate('/')
        
          } else {
            props.showAlert("Invalid OTP ","error")
          }
        })
        .catch((err) => props.showAlert((''+err),"error"));
        
        }
        else{
            props.showAlert("Form Invalid ","error")
        }
        }
//Get applicant id through search params

const getEmailID = ()=>{

    let apiURL = process.env.REACT_APP_API_URL + "/users/getemail/"+otp;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
                console.log(repos.data)
         
            setEmail(repos.data.email)
            }else  props.showAlert(""+JSON.stringify(repos.message || repos),"error");  
        }) 
        .catch((err) =>  props.showAlert(""+JSON.stringify(err),"error"));
 

}


const backtoLogin = () =>{
    navigate("/");
  }
  

  return (
    <div>
   <div id="reset_password_pc">
	<div id="overall_grp">
		<img id="bg_design_reset_pas" src="bg_design_reset_pas_pc.png" srcset="bg_design_reset_pas_pc.png 1x, bg_design_reset_pas_pc@2x.png 2x" />
			
		<svg class="top_design_reset_j">
			<linearGradient id="top_design_reset_j" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
				<stop offset="0" stop-color="#95c3ff" stop-opacity="1"></stop>
				<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
			</linearGradient>
			<rect id="top_design_reset_j" rx="25" ry="25" x="0" y="0" width="409" height="56">
			</rect>
		</svg>
		<div id="Reset_Password_lbl_res">
			<span>Reset Password</span>
		</div>
		<input placeholder="New Password" class="field_1_new" type="password"  value={inputField.password} onChange={e=>
    inputHandler("password",e.target.value)}/>{
        errField.passwordErr.length > 0 && <span className ='field_1_new_error'><br/>{errField.passwordErr}</span>
     }
			
		<input placeholder="Confirm Password" class="field_2_confirm" type="password"  value={inputField.cpassword} onChange={e=>
     inputHandler("cpassword",e.target.value)}/>{
        errField.cpasswordErr.length > 0 && <span className ='field_2_new_error'><br/>{errField.cpasswordErr}</span>
     }
			
		<div id="set_pass_btn_reset"  onClick={()=>sendUpdatedPassword()}>
			<svg class="set_pass_reset_t">
				<linearGradient id="set_pass_reset_t" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
					<stop offset="0" stop-color="#b3d4ff" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#bbcfec" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="set_pass_reset_t" rx="11" ry="11" x="0" y="0" width="151" height="42">
				</rect>
			</svg>
			<div id="Set_Password_reset">
				<span>Set Password</span>
			</div>
		</div>
		<div id="back_btn_reset" onClick={(e)=>backtoLogin()}>
			<svg class="back_reset_btn_">
				<linearGradient id="back_reset_btn_" spreadMethod="pad" x1="0.591" x2="0.596" y1="1" y2="0">
					<stop offset="0" stop-color="#bbcfec" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#b3d4ff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="back_reset_btn_" rx="11" ry="11" x="0" y="0" width="151" height="42">
				</rect>
			</svg>
			<div id="Back_reset">
				<span>Back</span>
			</div>
		</div>
	</div>
</div>
    </div>
  )
}

export default UserPasswordResetUI