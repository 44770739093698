import React, {useEffect, useState} from 'react'
import '../Styles/RecuitersPagePc.css'

import WebsiteEnquiries from '../WebsiteEnquiries';
import RecruiterOfficeLocations from '../RecruiterOfficeLocations';
import Devices from '../Devices';
import RecruiterVacanciesVerifyUI from '../RecruiterVacanciesVerifyUI';

const RecuitersPagePc = (props) => {

const [selectedRecuitersTab,setSelectedRecuitersTab] = useState("Recruiter Office Locations");


  return (

    <div id="RecuitersPagePc_today">

	<div id="RecuitersPagePc_Group_12">
		<svg class="RecuitersPagePc_Rectangle_4">
			<rect id="RecuitersPagePc_Rectangle_4" rx="15" ry="15" x="0" y="0" width="100%" height="100%">
			</rect>
		</svg>
		{selectedRecuitersTab=== "Recruiter Office Locations"?<RecruiterOfficeLocations showAlert={props.showAlert}/>:""}
		{selectedRecuitersTab=== "Recruiter Vacancies"?<RecruiterVacanciesVerifyUI showAlert={props.showAlert}/>:""}
		{/* {selectedRecuitersTab=== "Devices"?<Devices />:""} */}
	
	</div>
	<div id="RecuitersPagePc_Group_7">
		<svg class="RecuitersPagePc_Rectangle_5">
			<rect id="RecuitersPagePc_Rectangle_5" rx="10" ry="10" x="0" y="0" width="128" height="100%">
			</rect>
		</svg>
		<table id="RecuitersPagePc_Group_6">
		<tr><td class={selectedRecuitersTab=== "Recruiter Office Locations"?'selectedRecuitersTab_pc':'recuitersTab_pc'} onClick={()=>{setSelectedRecuitersTab("Recruiter Office Locations");}}>Recruiter Office Locations</td></tr>
		<tr><td class={selectedRecuitersTab=== "Recruiter Vacancies"?'selectedRecuitersTab_pc':'recuitersTab_pc'} onClick={()=>{setSelectedRecuitersTab("Recruiter Vacancies");}}>Recruiter Vacancies</td></tr>
		{/* <tr><td class={selectedRecuitersTab=== "Devices"?'selectedRecuitersTab_pc':'recuitersTab_pc'} onClick={()=>{setSelectedRecuitersTab("Devices");}}>Devices</td></tr> */}
		
		</table>

	
	</div>
</div>
  )
}

export default RecuitersPagePc
