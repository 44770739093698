import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './Users.css'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import AddUserPopUpUI from './PC/AddUserPopUpUI';

var CryptoJS = require("crypto-js");


const Users = (props) => {

  const style6 = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 500,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

  const label = { inputProps: { 'aria-label': 'Schedule Interview' } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [employees, setEmployees] = useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [reportingToId,setReportingToId]= useState("");
  const [reportingToName,setReportingToName]= useState("");
  const [accessType,setAccessType] = useState("");
  const [active,setActive ]=useState(true);
  const [ password,setPassword] = useState("");
  const [reportingToMobile,setReportingToMobile] = useState("");
  const [isHr,setIsHr] = useState(true);
  const { user} = useContext(UserContext);
  const [dob,setDob] = useState(new Date())
  const [row,setRow] = useState([]);

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {columnField: '', operatorValue: 'contains', value:user.name}
    ],
  });
  
  const cols = [
  {field:'id', headerName: 'id', hide: true },
  {field:'name', headerName: 'Name',width: 190 },
  {field:'mobile', headerName: 'Mobile No',width: 140},
  {field:'email', headerName: 'Email',width: 200},
  {field:'gender', headerName: 'Gender',width: 100, renderCell: (params) =>{
    return[
      params.row.gender ?  params.row.gender.substring(0,1).toUpperCase()+ params.row.gender.substring(1).toLowerCase():""
    ];
  },
  },
  {field:'reportingToName', headerName: 'Reporting To',width: 190, },
  {field:'reportingToMobile', headerName: 'Supervisor Mobile',width: 190, },
  {field:'accesstype', headerName: 'AccessType',width: 100,
  renderCell: (params) =>{
     switch(params.row.accesstype){
      case "0":
      return["Guest"];
       
      case "1":
      return  ["Employee"]
     
      case "2":
        return ["Supervisor"]
     
      case "3":
        return  ["Manager"]
    
      case "4":
        return  ["Director"]
     
      case "5":
        return ["Admin"]
       
      default :
      return  [""]
     
     }
  },
  }, 
 
  
  {field:'ishr', headerName: 'Is HR',width: 100, renderCell: (params) =>{
    return[
      <input type="checkbox" checked={params.row.ishr} /> 
    ];
  },},

  {field:'active', headerName: 'Active',width: 100, renderCell: (params) =>{
    return[
      <input type="checkbox" checked={params.row.active} /> 
    ];
  },},

];

useEffect(()=>{
  getEmployees()

},[])



const getEmployees = ()=>{
  let apiURL=process.env.REACT_APP_API_URL + "/users/getallusers" 
  let apiParams = {
      method:"GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {

        setEmployees(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

      
async function encrypt(plainText) {
  return await CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
}
const UpdateEmployeesDetails = (id)=>{
    let apiURL =  process.env.REACT_APP_API_URL + "/users/updateEmployees/"+id;
    let apiParams ={
      method:"POST",
      headers:{
      name:name,
      gender:gender,
      reportingtoid:reportingToId,
      reportingtoname:reportingToName,
      reportingtomobile:reportingToMobile,
      accesstype:accessType,
      mobile:mobile,
      email:email,
      dob:dob,
      //password:encodeURIComponent(password),
      active:active,
      ishr:isHr,
      authtoken: localStorage.getItem("loggedintoken")
    }
    };
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=>{
      if(repos.data){
        setOpen(false)
        setIsLoading(false)
        
        props.showAlert("User Updated Successfully","success")
        setTimeout(()=>{
          window.location.reload(false);
        
        },2000)
      }

    })
    .catch((err) => props.showAlert((''+err),"error"));
    }

const addEmployeedetails = async () =>{
  if(email.length===0){
    props.showAlert("Please Enter Email !!","error")
  }
let apiURL = process.env.REACT_APP_API_URL + "/users/addEmployee";
let apiParams = {
  method:"POST",
  headers:{
    name:name,
    gender:gender, 
    reportingtoid:reportingToId,
    reportingtoname:reportingToName,
    reportingtomobile:reportingToMobile,
    accesstype:accessType,
    mobile:mobile,
    email:email,
    dob:dob,
    password:encodeURIComponent(await encrypt(password)),
    active:active,
    ishr:isHr,
    authtoken: localStorage.getItem("loggedintoken")
  }
};
fetch(apiURL,apiParams)
.then((res)=>(res.status===200 ? res.json() :res.text()))
.then((repos)=>{
  if(repos.data){
    setOpen(false)
    setIsLoading(false)
    
    props.showAlert("User Added Successfully","success")
    setTimeout(()=>{
      window.location.reload(false);
    
    },2000)
  }
})
.catch((err)=>props.showAlert((''+err),"error"))
}

const addEmployee =()=>{
  setRow();
  setOpen(true);
}

const editEmployees = (row) =>{
  setRow(row)
  setId(row.id);
  setName(row.name)
  setGender(row.gender)
  setReportingToId(row.reportingToId)
  setReportingToName(row.reportingToName)
  setReportingToMobile(row.reportingToMobile)
  setAccessType(row.accesstype)
  setMobile(row.mobile)
  setEmail(row.email)
  setActive(row.active)
  setIsHr(row.ishr)
  setDob(row.dob?row.dob:new Date())
  setOpen(true);
}

const handleClose = () =>{
  setOpen(false);
  resetApplicant()
}

const resetApplicant = ()=>{

  setId("");
  setName("");
  setEmail("");
  setMobile("");
  setGender("");
  setReportingToId("")
  setReportingToName("")
  setReportingToMobile("")
  setAccessType("")
  setDob("")
  // setActive(false)

  
}

const rowData = (employees)?.map((employee) => {

return{
  id:employee?._id,
  name:employee?.name,
  reportingToId:employee?.reportingToId,
  reportingToName:employee?.reportingToName,
  reportingToMobile:employee?.reportingToMobile,
  email:employee?.email,
  mobile:employee?.phone,
  gender:employee?.gender,
  dob:employee?.dob,
  accesstype:employee?.accesstype,
  active:employee?.active,
  ishr:employee?.isHr,
  canCallInPc:employee?.canCallInPc,
  canWhatsAppInPc:employee?.canWhatsAppInPc,
  canCallInMob:employee?.canCallInMob,
  canWhatsAppInMob:employee?.canWhatsAppInMob,
};
});

  return (
  <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
  <Button size='small' variant="contained" className='intern' title={"Add User"}  onClick={()=>addEmployee()}>+ Add User</Button>
  <br/>
  <br/>
      <div style={{height:'calc(100% - 50px)',width: '100%'}}>
      <DataGrid rows={rowData} columns={cols} components={{
          Toolbar: GridToolbar,
        }}
        initialState={{
        sorting: {
        sortModel: [{ field: 'active', sort: 'desc' }],
         },
       }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowClick={(e)=>editEmployees(e.row)}
          />

    
      </div>
      <AddUserPopUpUI open={open} data={row} close={()=>{setOpen(false);getEmployees()}} />
</div>
  
  )
}

export default Users