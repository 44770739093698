import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import "../Styles/AdminInterviewData.css";
import { UserContext } from '../UserContext';

const AdminInterviewData = (props) => {

  
  const { user, setUser} = useContext(UserContext);
  const alertStart = useRef(new Date());
  const [rowData,setRowData] = useState([])

useEffect(()=>{
if(props.rowData){
  setRowData(props.rowData)
}

},[props.rowData])

useEffect(()=>{


},[props.showOnlyHR,props.users,props.fromDate,props.toDate])


 //View Data 
 const viewData = (row) =>{

  window.location.href= "/interviewdetails?selectedCell=" + row;
  }


  return (

    <div id="BottmPartInterviewactivity">
       <div id="BottmPartInterviewGroup_36">
        <div id="BottmPartInterviewInterview">
          <span>Interviews</span>
        </div>
        <div id="BottmPartInterviewVerification" onClick={()=>props.selectedTab("verifications")}>
          <span>Verifications</span>
        </div>
        <div id="BottmPartInterviewothers" onClick={()=>props.selectedTab("duration")}>
          <span>Duration</span>
        </div>
        <div id="BottmPartInterviewoth" onClick={()=>props.selectedTab("others")}>
          <span>Others</span>
        </div>
        
          <div id="BottmPartInterviewActivity_w" onClick={()=>props.selectedTab("activity")}>
            <span>Activity</span>
          </div>
      </div> 

      <div id="BottmPartInterviewGroup_39">
        <svg class="BottmPartInterviewRectangle_46">
          <rect id="BottmPartInterviewRectangle_46" rx="24" ry="24" x="0" y="0" width="100%" height="100%">
          </rect>
        </svg>
        {rowData.map((row)=>(
        <div id="BottmPartInterviewGroup_37">

          <div id="BottmPartInterviewRoobi">
            <span>{row.name}</span>
          </div>
          <div id="BottmPartInterviewn_037" onClick={()=>(user.accesstype == 5 || row.name=== user.name) && viewData((["failed",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.failed}</span>
          </div>
          <div id="BottmPartInterviewn_510545" onClick={()=>(user.accesstype == 5 || row.name=== user.name) && viewData((["called",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.called}</span>
          </div>
          <div id="BottmPartInterviewMohammed" onClick={()=>(user.accesstype == 5 || row.name=== user.name) && viewData((["passed",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.passed}</span>
          </div>
          <img id="BottmPartInterviewspeaker-filled-audio-tool" src="speaker-filled-audio-tool.png" srcset="speaker-filled-audio-tool.png 1x" />
            
        </div>
        ))} 
        <div id="BottmPartInterviewGroup_38">
          <div id="BottmPartInterviewStatus">
            <span>Passed</span>
          </div>
          <div id="BottmPartInterviewn_passed">
            <span>{(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.passed,0)}</span>
          </div>
          <div id="BottmPartInterviewTime">
            <span>Failed</span>
          </div>
          <div id="BottmPartInterviewn_failed">
            <span>{(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.failed,0)}</span>
          </div>
          <div id="BottmPartInterviewQueue">
            <span>Called</span>
          </div>
          <div id="BottmPartInterviewn_queue">
            <span>{(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.called,0)}</span>
          </div>
          <svg class="BottmPartInterviewLine_2" viewBox="0 0 225.87 1">
            <path id="BottmPartInterviewLine_2" d="M 0 0 L 225.86962890625 0">
            </path>
          </svg>
        </div>
      </div>
   
  </div>
  )
}

export default AdminInterviewData
