import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import {useNavigate} from "react-router-dom";

import '../Styles/TopBarMobUI.css'

const TopBarMobUI = () => {
	var navigate = useNavigate();
	const { user, setUser } = useContext(UserContext);

	const handlelogout = () =>{
		localStorage.clear();
		setUser(null);
		window.location.href = "/";
	  }

  return (
    <div>
      <div id="top_nav_bar_rec">
	{/* <img id="bizibeesjpg_img_app_can_mob" src="bizibeesjpg_img_app_can_mob.png" srcset="bizibeesjpg_img_app_can_mob.png 1x" />	 */}
	<a href="https://bizibees.in/" target="">
	<img id="bizibeesjpg_img_app_can_mob" src="bizibeesjpg_img_app_can_mob.png" srcset="bizibeesjpg_img_app_can_mob.png 1x" />
       </a>
   </div>

<nav role="navigation">
	<div id="menuToggle">
	
	  <input type="checkbox" />
	
	  <span></span>
	  <span></span>
	  <span></span>
	
	  <ul id="menu">
		<li onClick={()=>navigate('/dashboard')}>Dashboard</li>
		{user.accesstype === "r" ?<li onClick={()=>navigate('/officelocations')}>Location</li>:""}
		{user.accesstype === "c" ?<li onClick={()=>navigate('/vacancies')}>Vacancies</li>:""}
		{user.accesstype === "r" ?<li onClick={()=>navigate('/recruitervacancies')}>Vacancies</li>:""}
		{user.accesstype === "c" ?<li onClick={()=>navigate('/candidateApplications')}>Applications</li>:""}
		
		<li onClick={()=>handlelogout()}>Log Out</li>
	    {/* <a href="rounds.html" target=""><li>Rounds</li></a>
		<a href="reassign.html" target=""><li>Reassign</li></a>
		<a href="recruiter page/city.html" target=""><li>Recruiter</li></a>
		<a href="settings/emails.html" target=""><li>settings</li></a>  */}
	  </ul>
	</div>
  </nav>

    </div>
  )
}

export default TopBarMobUI
