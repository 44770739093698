import React, {useEffect, useState} from 'react'
import '../Styles/SetupPageMob.css'
import { useContext } from "react";
import { UserContext } from '../UserContext';
import SentEmailsToCandidates from '../SentEmailsToCandidates';
import RecruiterOfficeLocations from '../RecruiterOfficeLocations';
import RecruiterVacanciesVerifyUI from '../RecruiterVacanciesVerifyUI';
import WebsiteEnquiries from '../WebsiteEnquiries';
import Registrations from '../Registrations';
import Devices from '../Devices';
import BulkDataUpload from '../BulkDataUpload';
import ReAssign from '../ReAssign';
import Cities from '../Cities';
import Locations from '../Locations';
import OfficeLocations from '../OfficeLocations';
import FAQ from '../FAQ';
import Users from '../Users';

const SetupPageMob = (props) => {

const { user, setUser } = useContext(UserContext);
const [selectedSetupTab,setSelectedSetupTab] = useState((user.accesstype == 5) ? "Upload":"ReAssign");


  return (
	<div id="Setup_Mob_bizibees_mob_admin">
	<div id="Setup_Mob_screen_and_menu_grp_BBL">
		<div id="Setup_Mob_discplay_grp_BBl">
			<svg class="Setup_Mob_display_BBL">
				<rect id="Setup_Mob_display_BBL" rx="6" ry="6" x="0" y="0" width="100%" height="100%">
				</rect>
			</svg>
		{selectedSetupTab=== "Upload"?<BulkDataUpload showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "ReAssign"?<ReAssign showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "Cities"?<Cities showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "Locations"?<Locations showAlert={props.showAlert}/>:""}
		{selectedSetupTab=== "Office Locations"?<OfficeLocations showAlert={props.showAlert}/>:""}
		{/* {selectedSetupTab=== "FAQs"?<FAQ showAlert={props.showAlert}/>:""} */}
		{selectedSetupTab=== "Users"?<Users showAlert={props.showAlert}/>:""}
		</div>

 <table id="Setup_Mob_ELOUUD_BBL">
 {user && user.accesstype == 5 ?<tr style={{padding:0}}>
		<td class={selectedSetupTab=== "Upload"?'selectedSetUpTab_mob':'setuptabs_mob'} onClick={()=>{setSelectedSetupTab("Upload");}}>
		<div >Upload</div>
		</td>
	</tr>:""}
	{user && user.accesstype > 2 ?<tr>
		<td class={selectedSetupTab=== "ReAssign"?'selectedSetUpTab_mob':'setuptabs_mob'} onClick={()=>{setSelectedSetupTab("ReAssign");}}>
		<div >ReAssign</div>
		</td>
	</tr>:""}
	{user && user.accesstype == 5 ?<tr>
		<td class={selectedSetupTab=== "Cities"?'selectedSetUpTab_mob':'setuptabs_mob'} onClick={()=>{setSelectedSetupTab("Cities");}}>
		<div >Cities</div>
		</td>
	</tr>:""}
	{user && user.accesstype > 2 ?<tr>
		<td class={selectedSetupTab=== "Locations"?'selectedSetUpTab_mob':'setuptabs_mob'} onClick={()=>{setSelectedSetupTab("Locations");}}>
		<div >Locations</div>
		</td>
	</tr> :""}
	{user && user.accesstype > 2 ?<tr>
		<td class={selectedSetupTab=== "Office Locations"?'selectedSetUpTab_mob':'setuptabs_mob'} onClick={()=>{setSelectedSetupTab("Office Locations");}}>
		<div >Office Locations</div>
		</td>
	</tr>:""}
	 {/* <tr>
		<td class={selectedSetupTab=== "FAQs"?'selectedSetUpTab_mob':'setuptabs_mob'} onClick={()=>{setSelectedSetupTab("FAQs");}}>
		<div >FAQs</div>
		</td>
	</tr> */}
	{user && user.accesstype == 5 ? <tr>
		<td class={selectedSetupTab=== "Users"?'selectedSetUpTab_mob':'setuptabs_mob'} onClick={()=>{setSelectedSetupTab("Users");}}>
		<div style={{textAlign:"right"}}>Users</div>
		</td>
	</tr>:""}
	
</table> 


	</div>
		
</div>
  )
}

export default SetupPageMob
