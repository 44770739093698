import './App.css';
import React, {useState,useMemo, useEffect} from 'react'
import LoginUIMobile from './Components/LoginUIMobile';
//import ResponsiveAppBar from './Components/ResponsiveAppBar';
import ApplicantDetails from './Components/ApplicantDetails';
import Jobs from './Components/Jobs';
import { BrowserRouter as Router, Route, Routes,useLocation} from "react-router-dom";
import InterviewDetails from './Components/InterviewDetails';
import MessagedDetails from './Components/MessagedDetails';
import Nav from './Components/Nav';
import Applicants from './Components/Applicants';
import NewApplicants from './Components/NewApplicants';
import LoginUI from './Components/LoginUI';
import Register from './Components/Register';
import { UserContext } from "./Components/UserContext";
import BulkDataUpload from './Components/BulkDataUpload';
import Registrations from './Components/Registrations';
import ApplicantStatus from './Components/ApplicantStatus';
import SelectedCandidates from './Components/SelectedCandidates';
import JoinedCandidates from './Components/JoinedCandidates';
import InvoicedCandidates from './Components/InvoicedCandidates';
import Dashboard from './Components/Dashboard';
import ReAssign from './Components/ReAssign';
import AlertPage from './Components/AlertPage'
import SnackBar from './Components/SnackBar';
import Today from './Components/Today';
import Bookmarked from './Components/Bookmarked';
import Tomorrow from './Components/Tomorrow';
import NewNav from './Components/NewNav';
import Users from './Components/Users';
import Locations from './Components/Locations';
import Devices from './Components/Devices';
import EmailSend from './Components/EmailSend';
import ChangePassword from './Components/ChangePassword';
import UserProfile from './Components/UserProfile';
import UserReport from './Components/UserReport';
import Apply from './Components/Apply';
import SentEmailsToCandidates from './Components/SentEmailsToCandidates';
import OfficeLocations from './Components/OfficeLocations';
import MyProfile from './Components/MyProfile';
import CandidatePasswordReset from './Components/CandidatePasswordReset';
import CandidateApplications from './Components/CandidateApplications';
import EligibleCandidates from './Components/EligibleCandidates';
import VacanciesUI from './Components/VacanciesUI';
import EmailSendUI from './Components/EmailSendUI';
import ScheduleInterview from './Components/ScheduleInterviewUI';
import FAQ from './Components/FAQ';
import UserPasswordResetUI from './Components/UserPasswordResetUI';
import RecruitmentVacancyPageUI from './Components/RecruitmentVacancyPageUI';
import RecruitmentVacancyPageMobUI from './Components/MobUI/RecruitmentVacancyPageMobUI';

import RecruiterRegistrationPageUI from './Components/RecruiterRegistrationPageUI';
import RecruiterOfficeLocations from './Components/RecruiterOfficeLocations';
import RecruiterVacanciesVerifyUI from './Components/RecruiterVacanciesVerifyUI';
import RecruiterJobApplications from './Components/RecruiterJobApplications';
import VacanciesMobUI from './Components/MobUI/VacanciesMobUI';
import Cities from './Components/Cities';
import TopBarMobUI from './Components/MobUI/TopBarMobUI';
import FooterMobUI from './Components/MobUI/FooterMobUI';
import FooterUI from './Components/PC/FooterUI';
import TermsandConditions from './Components/PC/TermsandConditions';
import PrivacyPolicy from './Components/PC/PrivacyPolicy';
import WebsiteEnquiries from './Components/WebsiteEnquiries';
import RecruiterRegistrationPageMobUI from './Components/MobUI/RecruiterRegistrationPageMobUI';
import NewNavbarUI from './Components/PC/NewNavbarUI';
import InterviewsMain from './Components/PC/InterviewsMain';
import SetupPagePc from './Components/PC/SetupPagePc';
import MonitorPagePc from './Components/PC/MonitorPagePc';
import RecuitersPagePc from './Components/PC/RecuitersPagePc';
import NewTopBarMobUI from './Components/MobUI/NewTopBarMobUI';
import InterviewsMainMob from './Components/MobUI/InterviewsMainMob';
import ClientsHRMainMob from './Components/MobUI/ClientsHRMainMob';
import RecuitersPagePcMob from './Components/MobUI/RecuitersPagePcMob';
import MonitorPageMob from './Components/MobUI/MonitorPageMob';
import SetupPageMob from './Components/MobUI/SetupPageMob';
import ClientsHRMain from './Components/PC/ClientsHRMain';
import {ContextProvider} from './SocketContext';




const App = () => {

const [user, setUser] = useState(null);
const [alert, setAlert] = useState(null);
const [open, setOpen] = React.useState(false);

const showAlert=(message,type)=>{
  
  setAlert({
    msg:message,
    type:type
  })
  setTimeout(() => {
    setAlert(null)
  }, 3000);
}

//snack bar
const handleClick = () => {
    setOpen(true);
  };

  //snackbar
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

const value = useMemo(
() => ({
  user,
  setUser,
}),
[
  user,
  setUser,
]
);

useEffect(()=>{
  let storedUser = localStorage.getItem("loggedInUser");
  if(storedUser){
   
    setUser(JSON.parse(storedUser));
  
    
  }
 
},[]);

// useEffect(()=>{
// clearInterval();
// setInterval(()=>checkUserValidity(),300000);
// },[user]);


const checkUserValidity = ()=>{
if(user != null)
{
  let apiURL = process.env.REACT_APP_API_URL + "/users/checkuservalidity/"+user._id;
  let apiParams = {
    method: "GET",
    headers: {
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  fetch(apiURL, apiParams)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {
             if ((repos.data.active !== true) || (repos.data.accesstype !== user.accesstype))
                     {
                      handlelogout();
                     }
                               } else console.log(JSON.stringify(repos));
          })
          .catch((err) => console.log(JSON.stringify(err)));
        }
};


const handlelogout = () =>{
    localStorage.clear();
    setUser(null);
    window.location.href = "/";
  }




  return (
    <UserContext.Provider value={value}>
   <Router>
  
   {(window.location.pathname === "/vacancies" || user) && ((!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && ((user && user.accesstype !=="c") && (user && user.accesstype !=="r")))) ?<><NewNavbarUI showAlert={showAlert}/>
   
   <br/>
   <br/>
   <br/> 
    </>:user && ((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && (user.accesstype === "c" || user.accesstype === "r")) ?<><TopBarMobUI/><FooterMobUI/></>:user && (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && (user.accesstype === "c" || user.accesstype === "r")) ?<><FooterUI/></>:((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && ((user && user.accesstype !=="c") && (user && user.accesstype !=="r"))?<><NewTopBarMobUI /></> :"")}
    

    {alert===null? "":<AlertPage alert={alert}/>}

    {/* <SnackBar handleClick={setOpen(true)} handleClose={handleClose} /> */}
     {/* <ResponsiveAppBar/> */}<ContextProvider>
     <Routes>
    
       <Route path="/dashboard" element={user ?(user.accesstype!=="c" && user.accesstype!=="candidate" && user.accesstype!=="recruiter" && user.accesstype!=="r")?<Dashboard showAlert={showAlert}/>:user.accesstype==="r"?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<RecruiterRegistrationPageMobUI />:<RecruiterRegistrationPageUI  showAlert={showAlert}/>:<MyProfile showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>
       <Route path="/applicantdetails" element={user ?user.accesstype >2?<ApplicantDetails showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       {/* <Route  path="/" element={<Admin/>}/> */}
       <Route path="/interviewdetails" element={user ? user.accesstype > 0?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<InterviewsMainMob showAlert={showAlert} />: <InterviewsMain showAlert={showAlert} /> :user.accesstype!=="c"?<Dashboard showAlert={showAlert}/>:<MyProfile showAlert={showAlert}/> : <LoginUI showAlert={showAlert}/>}/>
       <Route path="/clientdetails" element={user ? user.accesstype > 0?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<ClientsHRMainMob showAlert={showAlert} />:<ClientsHRMain showAlert={showAlert}/>:user.accesstype!=="c"?<Dashboard showAlert={showAlert}/>:<MyProfile showAlert={showAlert}/> : <LoginUI showAlert={showAlert}/>}/>

       <Route path="/messageddetails" element={user ? user.accesstype > 0? <MessagedDetails showAlert={showAlert} />:user.accesstype!=="c"?<Dashboard showAlert={showAlert}/>:<MyProfile showAlert={showAlert}/> : <LoginUI showAlert={showAlert}/>}/>
       <Route path="/jobs" element={user ? <Jobs showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>
       <Route path="/applicants" element={user ?user.accesstype > 0 ?<Applicants showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
        <Route path="/newapplicants" element={user ?user.accesstype > 0 ?<NewApplicants showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>

       {/* <Route exact path="/" element={user ? ((user.accesstype==="c"||user.accesstype==""))?<MyProfile showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/> */}
       <Route path="/dataupload" element={user ?user.accesstype == 5? <BulkDataUpload showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/registrations" element={user ?user.accesstype == 5? <Registrations showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/applicantstatus" element={user ?user.accesstype > 2?<ApplicantStatus showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       <Route path="/selected" element={user ?user.accesstype > 0? <SelectedCandidates showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/joined" element={user ?user.accesstype > 0?<JoinedCandidates showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       <Route path="/eligible" element={user ?user.accesstype > 0?<EligibleCandidates showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       <Route path="/invoiced" element={user ?user.accesstype > 0? <InvoicedCandidates showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/today" element={user ?user.accesstype > 0? <Today showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       <Route path="/tomorrow" element={user ?user.accesstype > 0? <Tomorrow showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/bookmarked" element={user ?<Bookmarked showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       <Route path="/reassign" element={user ?user.accesstype > 2 ? <ReAssign showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/locations" element={user ?user.accesstype > 2 ? <Locations showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/officelocations" element={user ?(user.accesstype > 2) ? <OfficeLocations showAlert={showAlert}/>:user.accesstype=="r" ?<RecruiterOfficeLocations showAlert={showAlert}/> :<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/recruiterofficelocations" element={user ?(user.accesstype == 5) ?<RecruiterOfficeLocations showAlert={showAlert}/> :<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/verifyrecruitervacancies" element={user ?(user.accesstype == 5) ?<RecruiterVacanciesVerifyUI showAlert={showAlert}/> :<Dashboard showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
    
       <Route  path="/sentEmailsToCandidates" element={user ?user.accesstype > 0 ? <SentEmailsToCandidates showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       {/* <Route  path="/register" element={user ? <Register/>: <LoginUI/>}/> */}  
       <Route path="/users" element={user ?user.accesstype == 5?<Users showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>

       <Route path="/setup" element={user ?user.accesstype > 2? (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<SetupPageMob showAlert={showAlert}/> :<SetupPagePc showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>
       <Route path="/monitor" element={user ?user.accesstype == 5?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))? <MonitorPageMob showAlert={showAlert}/>: <MonitorPagePc showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>
       <Route path="/recruiterstab" element={user ?user.accesstype == 5?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<RecuitersPagePcMob  showAlert={showAlert}/>:<RecuitersPagePc showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>

       <Route path="/edituser" element={user ? (user.accesstype!=="c" && user.accesstype!=="candidate" && user.accesstype!=="recruiter" && user.accesstype!=="r" )?<UserProfile showAlert={showAlert}/>:<MyProfile showAlert={showAlert}/>: <LoginUI showAlert={showAlert}/>}/>
       <Route path="/devices" element={user ?user.accesstype == 5?<Devices showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>
       <Route path="/websiterenquiries" element={user ?user.accesstype == 5?<WebsiteEnquiries showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/> :<LoginUI showAlert={showAlert}/>}/>
       {/* <Route path="/usersreports" element={user ?user.accesstype == 5?<UserReport showAlert={showAlert}/>:<Dashboard showAlert={showAlert}/> : <LoginUI/>}/> */}
    
       <Route path="/forgotpassword" element={<EmailSendUI showAlert={showAlert}/>}/>
       <Route path="/faqs" element={user ?<FAQ showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       <Route path="/ChangePassword" element={<ChangePassword showAlert={showAlert}/>}/>
       <Route path="/apply" element={<MyProfile showAlert={showAlert}/>}/>
       {/* <Route path="/schedule" element={<ScheduleInterview showAlert={showAlert}/>}/> */}

       <Route path="/vacancies" element={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<VacanciesMobUI showAlert={showAlert}/>:<VacanciesUI showAlert={showAlert}/>}/>
       <Route path="/candidateApplications" element={<CandidateApplications showAlert={showAlert}/>}/>
       <Route path="/setpassword" element={<UserPasswordResetUI showAlert={showAlert}/>}/>
       {/* <Route path="/test" element={<UserPasswordResetUI showAlert={showAlert}/>}/> */}
       <Route path="/recruitervacancies" element={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<RecruitmentVacancyPageMobUI showAlert={showAlert}/>:<RecruitmentVacancyPageUI showAlert={showAlert}/>}/>
       <Route path="/recruiterRegistration" element={(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<RecruiterRegistrationPageMobUI />:<RecruiterRegistrationPageUI showAlert={showAlert}/>}/>
       
       <Route path="/jobvacancydetails" element={user? <RecruiterJobApplications showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>
       <Route path="/cities" element={user? <Cities showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>}/>

       <Route path="/termsandcondition" element={<TermsandConditions showAlert={showAlert}/>}/>
       <Route path="/privacypolicy" element={<PrivacyPolicy showAlert={showAlert}/>}/>

       <Route path="/" element={!user || !user.accesstype?(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<LoginUIMobile showAlert={showAlert}/>:<LoginUI showAlert={showAlert}/>:(user.accesstype!=="c" && user.accesstype!=="candidate" && user.accesstype!=="recruiter" && user.accesstype!=="r" )?<Dashboard showAlert={showAlert}/>:user.accesstype==="r"?<RecruiterRegistrationPageUI  showAlert={showAlert}/>:<MyProfile showAlert={showAlert}/>}/>
       {/* <Route path="/appplicant" element={user ?<ApplicantPopup showAlert={showAlert}/>: <Login/>}/> */}
       
    
     </Routes></ContextProvider>
   </Router>
   </UserContext.Provider>
  )
}

export default App;
