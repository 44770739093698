import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import WorkPreferences from './WorkPreferences';
import './AddJobVacancyPopUpUI.css'
import WorkPreferencesRecVacancyUI from './WorkPreferencesRecVacancyUI';

const AddJobVacancyPopUpUI = (props) => {

//useState
const [openJobs, setOpenJobs] = React.useState(false); 
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState("");
const [ Skills,setSkills]= useState("");
const [companyName, setCompanyName] = useState("");

const [jobDescription, setJobDescription] = useState("");
const [minYears, setMinYears] = useState(0);
const [minMonths, setMinMonths] = useState(0);
const [maxYears, setMaxYears] = useState(0);
const [maxMonths, setMaxMonths] = useState(0);
const [offices,setOffices] = useState([]);
const [searchParams] = useSearchParams();
const [officeName,setOfficeName]= useState("");
    const [mapUrl,setMapUrl] = useState("");
    const [latitude,setLatitude] = useState("");
    const [longitude,setLongitude]= useState("");
    const [officeLogoUrl,setOfficeLogoUrl] = useState("");
    const[resumeName,setResumeName]=useState();
    const [experience,setExperience]=useState();
    const [voiceProcess,setVoiceProcess] = useState(true)
    const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
    const [workFromHome,setWorkFromHome] = useState(false)
    const [workFromOffice,setWorkFromOffice] = useState(true)
    const [partTime,setPartTime] = useState(false)
    const [fullTime,setFullTime] = useState(true)
    const [nightShift,setNightShift] = useState(true)
    const [dayShift,setDayShift] = useState(true)
    const [companyTransportDay,setCompanyTransportDay] = useState(true)
    const [companyTransportNight,setCompanyTransportNight] = useState(true)
    const [ownTransport,setOwnTransport] = useState(true)
    const [minCTC,setMinCTC] = useState(0);
    const [maxCTC,setMaxCTC] = useState(0);
    const [hrNames,setHRNames]= useState([])
    const { user, setUser} = useContext(UserContext);
    const [spocId,setSpocId]= useState(user?user._id:"");
    const [spocName,setSpocName]= useState(user?user.name:"");
    const [recruiterOffices,setRecruiterOffices] = useState([]) 
    const [city,setCity] = useState("");
    const [state,setState] = useState("");

  //UseEffect     
 useEffect(()=>{
  if(user){
    if(user.accesstype!=="r" && user.accesstype!=="recruiter"){
      getUsers();
      getOffices();
    }
    if(user.accesstype=== "r"){
      getRecruiterOffices()
    }
  }
    
  
   },[user,companyName])

 useEffect(()=>{
    if(props.openJobs){
        setOpenJobs(props.openJobs)
        if(user.accesstype!=="r" && props.type!=="Add"){
        
            console.log(props.jobsData)
            setJobTitle(props.jobsData && props.jobsData.jobtitle);
            setJobId(props.jobsData && props.jobsData._id);
            setCompanyName(props.jobsData && props.jobsData.companyname);
            setMapUrl(props.jobsData && props.jobsData.mapUrl)
            setLatitude(props.jobsData && props.jobsData.latitude)
            setLongitude(props.jobsData && props.jobsData.longitude)
            setOfficeLogoUrl(props.jobsData && props.jobsData.officeLogoUrl);
            setState(props.jobsData && props.jobsData.state)
            setCity(props.jobsData && props.jobsData.city)
            setJobDescription(props.jobsData && props.jobsData.jobdescription);
            setOfficeName(props.jobsData && props.jobsData.officeName);
            setSkills(props.jobsData && props.jobsData.skills); 
            setMinYears(props.jobsData && props.jobsData.minexpmonths ? ((props.jobsData.minexpmonths)-((props.jobsData.minexpmonths)%12))/12:0);
            setMinMonths(props.jobsData && props.jobsData.minexpmonths ? (props.jobsData.minexpmonths)%12:0);
            setMaxYears(props.jobsData && props.jobsData.maxexpmonths ? ((props.jobsData.maxexpmonths)-((props.jobsData.maxexpmonths)%12))/12:0);
            setMaxMonths(props.jobsData && props.jobsData.maxexpmonths ? (props.jobsData.maxexpmonths)%12:0);
            setVoiceProcess(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).voiceProcess:false)
            setNonVoiceProcess(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).nonVoiceProcess:false)
            setWorkFromHome(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).workFromHome:false)
            setWorkFromOffice(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).workFromOffice:false)
            setPartTime(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).partTime:false)
            setFullTime(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).fullTime:false)
            setNightShift(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).nightShift:false)
            setDayShift(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).dayShift:false)
            setCompanyTransportDay(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).companyTransportDay:false)
            setCompanyTransportNight(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).companyTransportNight:false)
            setOwnTransport(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).ownTransport:false)
            setSpocId(props.jobsData && props.jobsData.spocId ? props.jobsData.spocId:user._id);
            setSpocName(props.jobsData && props.jobsData.spocName ? props.jobsData.spocName:user.name);
            setMaxCTC(props.jobsData && props.jobsData.maxCTC ? props.jobsData.maxCTC:0);
            setMinCTC(props.jobsData && props.jobsData.minCTC ? props.jobsData.minCTC:0);
           

        }else if(user.accesstype==="r" && props.type!=="AddRecVac" && props.jobsData ) {
         
          console.log(props.jobsData)
          setJobTitle(props.jobsData && props.jobsData.jobTitle);
          setJobId(props.jobsData && props.jobsData._id);
          setCompanyName(props.jobsData && props.jobsData.companyName);
          setState(props.jobsData && props.jobsData.state)
          setCity(props.jobsData && props.jobsData.city)
          setMapUrl(props.jobsData && props.jobsData.mapUrl)
          setLatitude(props.jobsData && props.jobsData.latitude)
          setLongitude(props.jobsData && props.jobsData.longitude)
          setOfficeLogoUrl(props.jobsData && props.jobsData.officeLogoUrl);
          setJobDescription(props.jobsData && props.jobsData.jobDescription);
          setOfficeName(props.jobsData && props.jobsData.officeName);
          setSkills(props.jobsData && props.jobsData.skills); 
          setMinYears(props.jobsData && props.jobsData.minExpMonths ? ((props.jobsData.minExpMonths)-((props.jobsData.minExpMonths)%12))/12:0);
          setMinMonths(props.jobsData && props.jobsData.minExpMonths ? (props.jobsData.minExpMonths)%12:0);
          setMaxYears(props.jobsData && props.jobsData.maxExpMonths ? ((props.jobsData.maxExpMonths)-((props.jobsData.maxExpMonths)%12))/12:0);
          setMaxMonths(props.jobsData && props.jobsData.maxExpMonths ? (props.jobsData.maxExpMonths)%12:0);
          setVoiceProcess(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).voiceProcess:false)
          setNonVoiceProcess(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).nonVoiceProcess:false)
          setWorkFromHome(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).workFromHome:false)
          setWorkFromOffice(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).workFromOffice:false)
          setPartTime(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).partTime:false)
          setFullTime(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).fullTime:false)
          setNightShift(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).nightShift:false)
          setDayShift(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).dayShift:false)
          setCompanyTransportDay(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).companyTransportDay:false)
          setCompanyTransportNight(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).companyTransportNight:false)
          setOwnTransport(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).ownTransport:false)
          setSpocId(props.jobsData && props.jobsData.spocId ? props.jobsData.spocId:user._id);
          setSpocName(props.jobsData && props.jobsData.spocName ? props.jobsData.spocName:user.name);
          setMaxCTC(props.jobsData && props.jobsData.maxCTC ? props.jobsData.maxCTC:0);
          setMinCTC(props.jobsData && props.jobsData.minCTC ? props.jobsData.minCTC:0);
         

        }else
        {
            setVoiceProcess(true)
            setNonVoiceProcess(false)
            setWorkFromHome(false)
            setWorkFromOffice(true)
            setPartTime(false)
            setFullTime(true)
            setNightShift(true)
            setDayShift(true)
            setCompanyTransportDay(false)
            setCompanyTransportNight(false)
            setOwnTransport(true)   
        }
    }
   
  
   },[props.openJobs])

//Functions
const handleClose = () => {
    if(props.openJobs===true){
        resetApplicant()
        props.close()
        setOpenJobs(false)
      }
   
   
  }
  

  const resetApplicant = ()=>{
  
    setJobTitle("");
    setJobId("");
    setCompanyName("");
    setJobDescription("");
    setOfficeName("");
    setSkills("");
    setMinYears(0);
    setMinMonths(0);
    setMaxYears(0);
    setMaxMonths(0); 
    setVoiceProcess(false)
    setNonVoiceProcess(false)
    setWorkFromHome(false)
    setWorkFromOffice(false)
    setPartTime(false)
    setFullTime(false)
    setNightShift(false)
    setDayShift(false)
    setCompanyTransportDay(false)
    setCompanyTransportNight(false)
    setOwnTransport(false)
    setSpocId("");
    setSpocName("");
    setMaxCTC(0);
    setMinCTC(0);
  }

  const setPreference=(key,value)=>
  {
switch (key) {
  case "voiceProcess":
    setVoiceProcess(value)
    break;
  case "nonVoiceProcess":
    setNonVoiceProcess(value)
    break;
  case "workFromHome":
    setWorkFromHome(value)
    break;
  case "workFromOffice":
    setWorkFromOffice(value)
    break;
  case "partTime":
    setPartTime(value)
      break;
  case "fullTime":
    setFullTime(value)
      break;
  case "nightShift":
    setNightShift(value)
      break;
  case "dayShift":
    setDayShift(value)
      break;
  case "companyTransportDay":
    setCompanyTransportDay(value)
        break;
  case "companyTransportNight":
    setCompanyTransportNight(value)
          break;
  case "ownTransport":
    setOwnTransport(value)
        break;
  default:
    break;
}
  }

  const getOffices = () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/getOfficelocations";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              // console.log(repos.data);
              if(repos.data.length>0)
              {
  
              setOffices(repos.data)
              
            }}else props.showAlert(""+(repos.message || repos),"error");  
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }


        const getRecruiterOffices = () =>{

          let apiURL = process.env.REACT_APP_API_URL + "/recruiterofficelocations/getrecruiterofficelocations/"+user._id;
          let apiParams = {
            method: "GET",
            headers:{
              authtoken: localStorage.getItem("loggedintoken")
            }
          };
          fetch(apiURL, apiParams)
              .then((res)=> (res.status===200 ? res.json() : res.text()))
              .then((repos)=> {
                  if (repos.data) {
                    // console.log(repos.data);
                    if(repos.data.length>0)
                    {
                    console.log(repos.data)
                    setRecruiterOffices(repos.data)
                    
                  }}else props.showAlert(""+(repos.message || repos),"error");  
                }) 
                .catch((err) => props.showAlert((''+err),"error"));
              }

  //Get Users
const getUsers = async () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    await fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             
            setHRNames(repos.data)
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }

  const addjob = ()=>{
  
    if(companyName.length===0){
   
     props.showAlert("Please Select the Comapany Name!!","error")
    }
    if(jobTitle.length===0){
     
     props.showAlert("Please Enter Job Title!!","error")
    }
 let apiURL=process.env.REACT_APP_API_URL + "/jobs/addJobs";
 let apiParams = {
     method:"POST",
     headers:{
       companyname:companyName,
       jobtitle: jobTitle,
       jobdescription:encodeURIComponent(jobDescription),
       minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
       maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
       preferences:JSON.stringify({

         voiceProcess:voiceProcess,
         nonVoiceProcess:nonVoiceProcess,
         workFromHome:workFromHome,
         workFromOffice:workFromOffice,
         partTime:partTime,
         fullTime:fullTime,
         nightShift:nightShift,
         dayShift:dayShift,
         companyTransportDay:companyTransportDay,
         companyTransportNight:companyTransportNight,
         ownTransport:ownTransport,
        
         }),
       officename:officeName,
       mapurl:mapUrl,
       latitude:latitude,
       longitude:longitude,
       state:state,
       city:city, 
       minctc:minCTC,
       maxctc:maxCTC,
       skills: encodeURIComponent(Skills),
       spocid:spocId,
       spocname:spocName,
       active:true,
       officelogourl:officeLogoUrl,
       authtoken: localStorage.getItem("loggedintoken")
     },
    
 };
 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
      // alert("Job Added Successfully");
      handleClose()
      props.showAlert("Job Added Successfully","success")
    //   setTimeout(() => {
    //    window.location.reload(false);
    //  }, 2000);
     
     }else props.showAlert(""+(repos.message || repos),"error");
 }) 
 .catch((err) => props.showAlert((''+err),"error"));
}

  const Updatejob = ()=>{
  
    let apiURL=process.env.REACT_APP_API_URL + "/jobs/updateJobs/"+jobId;
    let apiParams = {
       method:"POST",
       headers:{
         companyname:companyName,
         jobtitle: jobTitle,
         jobdescription:encodeURIComponent(jobDescription),
         minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
         maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
         preferences:JSON.stringify({
    
           voiceProcess:voiceProcess,
           nonVoiceProcess:nonVoiceProcess,
           workFromHome:workFromHome,
           workFromOffice:workFromOffice,
           partTime:partTime,
           fullTime:fullTime,
           nightShift:nightShift,
           dayShift:dayShift,
           companyTransportDay:companyTransportDay,
           companyTransportNight:companyTransportNight,
           ownTransport:ownTransport,
          
           }),
         officename:officeName,
         mapurl:mapUrl,
         latitude:latitude,
         longitude:longitude,
         state:state,
         city:city, 
         minctc:minCTC,
         maxctc:maxCTC,
         spocid:spocId,
         spocname:spocName,
         skills: encodeURIComponent(Skills),
         active:true,
         officelogourl:officeLogoUrl,
         authtoken: localStorage.getItem("loggedintoken")
       },
      
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
       if (repos.data) {
        // alert("Job Added Successfully");
        handleClose()
        // getJobsData()
        props.showAlert("Job Updated Successfully","success")
      //   setTimeout(() => {
      //    window.location.reload(false);
      //  }, 2000);
       
       }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => {props.showAlert((''+err),"error");handleClose()})
    }
    


    const addRecruiterVacancy = ()=>{
      if(companyName.length===0){
     
       props.showAlert("Please Enter Comapany Name!!","error")
      }
      if(jobTitle.length===0){
       
       props.showAlert("Please Enter Job Title!!","error")

      }
   let apiURL=process.env.REACT_APP_API_URL + "/recruiters/addRecruiterVacancy";
   let apiParams = {
       method:"POST",
       headers:{
         companyname:companyName,
         jobtitle: jobTitle,
         jobdescription:encodeURIComponent(jobDescription),
         minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
         maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
         preferences:JSON.stringify({
           voiceProcess:voiceProcess,
           nonVoiceProcess:nonVoiceProcess,
           workFromHome:workFromHome,
           workFromOffice:workFromOffice,
           partTime:partTime,
           fullTime:fullTime,
           nightShift:nightShift,
           dayShift:dayShift,
           companyTransportDay:companyTransportDay,
           companyTransportNight:companyTransportNight,
           ownTransport:ownTransport,
           }),
         officename:officeName,
          mapurl:mapUrl,
          latitude:latitude,
          longitude:longitude,
          state:state,
          city:city, 
         minctc:minCTC,
         maxctc:maxCTC,
         skills: encodeURIComponent(Skills),
        //  spocid:spocId,
         spocname:spocName,
         recruiterid:user._id,
         recruitername:user.name,  
         active:true,
         officelogourl:officeLogoUrl,
         authtoken: localStorage.getItem("loggedintoken")
       },
   };
   fetch(apiURL, apiParams)
   .then((res)=> (res.status===200 ? res.json() : res.text()))
   .then((repos)=> {
       if (repos.data) {
       
        handleClose()
        props.showAlert("Vacancy Added Successfully","success")
       
       }else props.showAlert(""+(repos.message || repos),"error");
   }) 
   .catch((err) => props.showAlert((''+err),"error"));
  }

  const UpdateRecruiterVacancy = (jobid)=>{
  
    if(companyName.length===0){
   
     props.showAlert("Please Enter Comapany Name!!","error")
    }
    if(jobTitle.length===0){
     
     props.showAlert("Please Enter Job Title!!","error")
    }
 let apiURL=process.env.REACT_APP_API_URL + "/recruiters/updateRecruiterVacancy/"+jobid;
 let apiParams = {
     method:"POST",
     headers:{
       companyname:companyName,
       jobtitle: jobTitle,
       jobdescription:encodeURIComponent(jobDescription),
       minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
       maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
       preferences:JSON.stringify({
         voiceProcess:voiceProcess,
         nonVoiceProcess:nonVoiceProcess,
         workFromHome:workFromHome,
         workFromOffice:workFromOffice,
         partTime:partTime,
         fullTime:fullTime,
         nightShift:nightShift,
         dayShift:dayShift,
         companyTransportDay:companyTransportDay,
         companyTransportNight:companyTransportNight,
         ownTransport:ownTransport,
         }),
       officename:officeName,
        mapurl:mapUrl,
        latitude:latitude,
        longitude:longitude,
        state:state,
        city:city, 
       minctc:minCTC,
       maxctc:maxCTC,
       skills: encodeURIComponent(Skills),
      //  spocid:spocId,
       spocname:spocName,
      //  recruiterid:user._id,
      //  recruitername:user.name,  
       active:true,
        officelogourl:officeLogoUrl,
        authtoken: localStorage.getItem("loggedintoken")
     },
 };
 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
     
      handleClose()
      props.showAlert("Vacancy Added Successfully","success")
     
     }else props.showAlert(""+(repos.message || repos),"error");
 }) 
 .catch((err) => props.showAlert((''+err),"error"));
}


  return (
    <div>

      <Modal
        open={openJobs}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
      
  <div id="vacancy_page">

	<img id="bg_vacancy" src="bg_vacancy.png" srcset="bg_vacancy.png 1x" alt='reload'/>
		
	<svg class="top_design_" viewBox="-4.454 -2.673 440.084 89.086">
		<path id="top_design_" d="M 0 75.66648864746094 C 1.562436819076538 74.54087829589844 0.9816954135894775 71.70684814453125 2.807228803634644 72.45954895019531 C 8.380990982055664 74.72618103027344 15.19260597229004 86.99711608886719 19.65060234069824 86.39158630371094 C 33.59748840332031 84.49110412597656 40.0886116027832 75.66648864746094 40.0886116027832 75.66648864746094 C 40.0886116027832 75.66648864746094 61.46920013427734 53.28389358520508 81.95893859863281 75.66648864746094 C 102.4486770629883 98.04908752441406 117.59326171875 75.66648864746094 117.59326171875 75.66648864746094 C 117.59326171875 75.66648864746094 143.4281463623047 54.52737426757812 156.791015625 75.66648864746094 C 170.1538848876953 96.80560302734375 190.6436157226562 75.66648864746094 190.6436157226562 75.66648864746094 C 190.6436157226562 75.66648864746094 208.4607696533203 54.52737426757812 222.7145080566406 75.66648864746094 C 236.9682464599609 96.80560302734375 256.5671081542969 75.66648864746094 256.5671081542969 75.66648864746094 C 256.5671081542969 75.66648864746094 271.7117004394531 55.77085876464844 289.5288696289062 75.66648864746094 C 307.3460388183594 95.56211853027344 324.2723388671875 75.66648864746094 324.2723388671875 75.66648864746094 C 324.2723388671875 75.66648864746094 343.8712463378906 58.2578125 356.3432312011719 75.66648864746094 C 368.8152160644531 93.07518005371094 389.3049621582031 75.66648864746094 389.3049621582031 75.66648864746094 C 389.3049621582031 75.66648864746094 403.5586853027344 61.98823547363281 416.0307006835938 75.66648864746094 C 428.5027160644531 89.34474182128906 435.6295776367188 75.66648864746094 435.6295776367188 75.66648864746094 L 435.6295776367188 -2.672574281692505 L -4.454290390014648 -2.672574281692505 L 0 75.66648864746094 Z">
		</path>
	</svg>

	<div id="ADD_VACANCY_lbl">
		<span>{jobId?"EDIT VACANCY":"ADD VACANCY"}</span>
	</div>


	
 {user && (user.accesstype==="recruiter"||user.accesstype==="r")?
 <div id="com_name_grp_">
 <select placeholder="Company Name" class="comopany_name_" readOnly={props.type==="View"}
          value={companyName} onChange={(e)=> {
            
            setCompanyName(e.target.value);   
            console.log(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0]);
            setState(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0].state)
            setCity(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0].city);
            setOfficeName(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0].office);   
            setMapUrl(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0].mapUrl);   
            setLatitude(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0].latitude);   
            setLongitude(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0].longitude);   
            setOfficeLogoUrl(recruiterOffices.filter(ofc=>ofc.company===e.target.value)[0].officeLogoUrl);
            //console.log(offices.filter(ofc=>ofc.company===e.target.value)[0].officeLogoUrl);
           } } >
           <option></option>

          { 
            
            recruiterOffices && ([...(new Set(recruiterOffices.filter((st)=>st.status==="approved").map((cpy) =>cpy.company)))]).map((company) =><option value={company}>{company}</option>
  
        )} 
		</select>

		
		<div id="job_lbl_">
			<span>Company Name</span>
		</div>
	</div>:<div id="com_name_grp_"> 	
   <select placeholder="Company Name" class="comopany_name_" readOnly={props.type==="View"}
          value={companyName} onChange={(e)=> {
            
            setCompanyName(e.target.value);   
            setState(offices.filter(ofc=>ofc.company===e.target.value)[0].state)
            setCity(offices.filter(ofc=>ofc.company===e.target.value)[0].city);
            setOfficeName(offices.filter(ofc=>ofc.company===e.target.value)[0].office);   
            setMapUrl(offices.filter(ofc=>ofc.company===e.target.value)[0].mapUrl);   
            setLatitude(offices.filter(ofc=>ofc.company===e.target.value)[0].latitude);   
            setLongitude(offices.filter(ofc=>ofc.company===e.target.value)[0].longitude);   
            setOfficeLogoUrl(offices.filter(ofc=>ofc.company===e.target.value)[0].officeLogoUrl);
            //console.log(offices.filter(ofc=>ofc.company===e.target.value)[0].officeLogoUrl);
           } } >
           <option></option>

          { 
            
            offices && ([...(new Set(offices.map((cpy) =>cpy.company)))]).map((company) =><option value={company}>{company}</option>
  
        )} 

	
		</select>

		 <div id="job_lbl_">
			<span>Company Name</span>
		</div> 
	</div> }
  

  {user && (user.accesstype==="recruiter"||user.accesstype==="r")?
  <div id="off_name_grp">
  <select placeholder="Office Name" class="office_name_lbl" readOnly={props.type==="View"}
          value={officeName} onChange={(e)=> {
            
            setOfficeName(e.target.value);   
            setMapUrl(recruiterOffices.filter(ofc=>ofc.office===e.target.value)[0].mapUrl);   
            setLatitude(recruiterOffices.filter(ofc=>ofc.office===e.target.value)[0].latitude);   
            setLongitude(recruiterOffices.filter(ofc=>ofc.office===e.target.value)[0].longitude); 
            setOfficeLogoUrl(recruiterOffices.filter(ofc=>ofc.office===e.target.value)[0].officeLogoUrl);
            setState(recruiterOffices.filter(ofc=>ofc.office===e.target.value)[0].state)
            setCity(recruiterOffices.filter(ofc=>ofc.office===e.target.value)[0].city);

         
           } } >
            <option></option>
          { 
            
            recruiterOffices && recruiterOffices.filter(cmpy=>cmpy.company===companyName && cmpy.status==="approved").map((ofc) =><option value={ofc.office}>{ofc.office}</option>
  
        )} 
		
		</select>

			
		<div id="job_lbl_b">
			<span>Office Name</span>
		</div>
	</div>
  	:<div id="off_name_grp">
		<select placeholder="Office Name" class="office_name_lbl" readOnly={props.type==="View"}
          value={officeName} onChange={(e)=> {
            
            setOfficeName(e.target.value);   
            setMapUrl(offices.filter(ofc=>ofc.office===e.target.value)[0].mapUrl);   
            setLatitude(offices.filter(ofc=>ofc.office===e.target.value)[0].latitude);   
            setLongitude(offices.filter(ofc=>ofc.office===e.target.value)[0].longitude); 
            setOfficeLogoUrl(offices.filter(ofc=>ofc.office===e.target.value)[0].officeLogoUrl);
            setState(offices.filter(ofc=>ofc.office===e.target.value)[0].state)
            setCity(offices.filter(ofc=>ofc.office===e.target.value)[0].city);
         
           } } >
            <option></option>
          { 
            
            offices && offices.filter(cmpy=>cmpy.company===companyName).map((ofc) =><option value={ofc.office}>{ofc.office}</option>
  
        )} 
		
		</select>

		<div id="job_lbl_b">
			<span>Office Name</span>
		</div>
	</div>}

	{user && (user.accesstype==="recruiter"||user.accesstype==="r")?
  <div id="spoc_name_gro">
		<input class="spoc_name_lbl" disabled value={spocName} onChange={(e)=> {
          setSpocName(e.target.value);   
         } } />
		
		<div id="job_lbl_cc">
			<span>SPOC Name</span>
		</div>
    </div>
    :
    <div id="spoc_name_gro">
		<select placeholder="Spoc Name" class="spoc_name_lbl" readOnly={props.type==="View"}          
          value={spocId} onChange={(e)=> {
          
            setSpocId(e.target.value);  
            setSpocName(hrNames.filter(hr=>hr._id===e.target.value)[0].name);   
           } } >
           <option></option>
          {
           hrNames && hrNames.map(hr=>  <option value={hr._id}>{hr.name}</option>)
            
        }
		
		</select>
		<div id="job_lbl_cc">
			<span>SPOC Name</span>
		</div>

	</div>}

	<div id="job_title_grp">
		<input  class="job_title_lbl" disabled={props.type==="View"} value={jobTitle} onChange={(e)=> {
      setJobTitle(e.target.value);
        }}/>
			
		<div id="job_lbl_cf">
			<span>Job TItle</span>
		</div>
	</div>
	<div id="jd_grp">
		<textarea  class="vacancy_job" value={jobDescription} onChange={(e)=> {
        setJobDescription(e.target.value); 

      }}/>
		
		<div id="job_decs_lbl">
			<span>Job Description</span>
		</div>
	</div>

	<div id="ks_grp">
		<textarea  class="vacancy_req_skill" value={Skills} onChange={(e)=> {
        setSkills(e.target.value);     
      }}/>
			
		<div id="ks_lbl">
			<span>Key Skill</span>
		</div>
	</div>

	<div id="Categories_lbl">
		<span>Preferences</span>
	</div>

  <WorkPreferencesRecVacancyUI type={props.type} preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} setPreference={(key,value)=>setPreference(key,value)}/>


  <div id="Experience_lbl_">
		<span>Experience</span>
	</div>
	<div id="Min_Experience_vacancy">
		<span>Min</span>
	</div>
	<div id="Max_Experience_vacancy">
		<span>Max</span>
	</div>

	<div id="exp_min_grp">
		<input placeholder="Years" class="vacancy_ex_max_1"  value={minYears} onChange={(e)=> {
          setMinYears(e.target.value);
        }}/>
			
		<input placeholder="Months" class="vacancy_ex_max" value={minMonths} onChange={(e)=> {
          setMinMonths(e.target.value);
        }}/>
			
	</div>

  

	<div id="exp_max_grp">
		<input placeholder="Years" class="vacancy_ex_max_1_b"   value={maxYears} onChange={(e)=> {
          setMaxYears(e.target.value);
        }}/>
		
		<input placeholder="Months" class="vacancy_ex_max_b" value={maxMonths} onChange={(e)=> {
          setMaxMonths(e.target.value);
        }}/>
			
	</div>


	<div id="Salary_Range_vacancy">
		<span>Salary Range</span>
	</div>

	<div id="Min_Experience_vacancy_b">
		<span>Min</span>
	</div>
	<div id="Max_Experience_vacancy_b">
		<span>Max</span>
	</div>

	<input placeholder="Min CTC" class="vacancy_min" value={minCTC} onChange={(e)=> {
          setMinCTC(e.target.value);
        }}/>
		
	<input placeholder="Max CTC" class="vacancy_max" value={maxCTC} onChange={(e)=> {
          setMaxCTC(e.target.value);
        }}/>

	<img id="close_vacancy_pc" src="close_vacancy_page.png" alt='reload' onClick={()=>handleClose()}/>

   {props.type!=="View"?<button id="btn_vacancy" onClick={()=>{
    if(user.accesstype!=="r"){
      if(jobId){
        Updatejob()
      }else{
        addjob()
      }
    }
    if(user.accesstype==="r"){
      if(jobId){
        UpdateRecruiterVacancy(jobId)
      }else{
        addRecruiterVacancy()
      }
      
    }
    
 }}
   >
		<svg class="vacancy_btn">
			<rect id="vacancy_btn" rx="8" ry="8" x="0" y="0" width="166" height="62">
			</rect>
		</svg>
		<div id="SUBMIT_vacancy">
			<span>{jobId?"Edit":"Submit"}</span>
		</div>
	</button>:""}
</div>
      </Modal>
    </div>
  )
}

export default AddJobVacancyPopUpUI
