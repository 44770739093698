import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import './WorkPreferencesUI.css'
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';


const WorkPreferencesUI = (props) => {

//useState

const [voiceProcess,setVoiceProcess] = useState(true)
const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
const [workFromHome,setWorkFromHome] = useState(false)
const [workFromOffice,setWorkFromOffice] = useState(true)
const [partTime,setPartTime] = useState(false)
const [fullTime,setFullTime] = useState(true)
const [temporary,setTemporary] = useState(true)
const [nightShift,setNightShift] = useState(true)
const [dayShift,setDayShift] = useState(true)
const [companyTransportDay,setCompanyTransportDay] = useState(true)
const [companyTransportNight,setCompanyTransportNight] = useState(true)
const [ownTransport,setOwnTransport] = useState(true)
const [hightlight,setHighlight] = useState({})

//UseEffect     
useEffect(()=>{
  let preferences = props.preferences;

  if(props.highlight)
{ 
   setHighlight(JSON.parse(props.highlight.candidatePreference))
   //console.log(JSON.parse(props.highlight.candidatePreference))
}

if(preferences){
        setVoiceProcess(preferences.voiceProcess)
        setNonVoiceProcess(preferences.nonVoiceProcess)
        setWorkFromHome(preferences.workFromHome)
        setWorkFromOffice(preferences.workFromOffice)
        setPartTime(preferences.partTime)
        setFullTime(preferences.fullTime)
        setNightShift(preferences.nightShift)
        setDayShift(preferences.dayShift)
        setCompanyTransportDay(preferences.companyTransportDay)
        setCompanyTransportNight(preferences.companyTransportNight)
        setOwnTransport(preferences.ownTransport)
}

},[props.preferences,props.highlight])

  return (
    <div>
      
    <div className='chkVoice'>
    <input disabled={props.type==="View"}  type="checkbox" id="chkVoice" name="chkVoice" checked={voiceProcess} onChange={()=>props.setPreference("voiceProcess",!voiceProcess)}/>
    </div>
    <label id='WPVoice' for="chkVoice" style={{color:voiceProcess?"red":"black",backgroundColor:(hightlight.voiceProcess===true)?"yellow":"transparent"}}> Voice </label>


	<div className='chkCompanyTransportNight'>
    <input disabled={props.type==="View"} type="checkbox" id="chkCompanyTransportNight" name="chkCompanyTransportNight" checked={companyTransportNight} onChange={()=>props.setPreference("companyTransportNight",!companyTransportNight)}/>
    </div>
    
    <label id='WPCompany_Night_PC' for="chkCompanyTransportNight" style={{color:companyTransportNight?"red":"black",backgroundColor:(hightlight.companyTransportNight===true)?"yellow":"transparent"}}> Company Night</label>       



    <div className='chkCompanyTransportDay'>
    <input disabled={props.type==="View"} type="checkbox" id="chkCompanyTransportDay" name="chkCompanyTransportDay" checked={companyTransportDay} onChange={()=>props.setPreference("companyTransportDay",!companyTransportDay)}/>
    </div>
    <label id='WPCompany_Day' for="chkCompanyTransportDay" style={{color:companyTransportDay?"red":"black",backgroundColor:(hightlight.companyTransportDay===true)?"yellow":"transparent"}}> Company Day</label>       



    <div className='chkOwnTransport'>
    <input disabled={props.type==="View"} type="checkbox" id="chkOwnTransport" name="chkOwnTransport" checked={ownTransport} onChange={()=> props.setPreference("ownTransport",!ownTransport)}/>
    </div>
    <label id='WPOwn' for="chkOwnTransport" style={{color:ownTransport?"red":"black",backgroundColor:(hightlight.ownTransport===true)?"yellow":"transparent"}}>Own</label>

	<div className='chkHybrid'>
    <input disabled={props.type==="View"} type="checkbox" id="chkHybrid" name="chkHybrid" checked={workFromOffice===true && workFromHome===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("workFromHome",true)
          props.setPreference("workFromOffice",true)
          setWorkFromOffice(true)
          setWorkFromHome(true)
         }
         
        }}/>
    </div>

        <label id='WPHybrid' for="chkHybrid" style={{color:workFromOffice===true && workFromHome===true?"red":"black",backgroundColor:(hightlight.workFromOffice===true)&& (hightlight.workFromHome===true)?"yellow":"transparent"}}>Hybrid</label>



    <div className='chkWorkfromhome'>
        <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromhome" name="chkWorkfromhome" checked={workFromHome} onChange={()=> props.setPreference("workFromHome",!workFromHome)}/>
    </div>
        <label id='WPHome' for="chkWorkfromhome" style={{color:workFromHome?"red":"black",backgroundColor:(hightlight.workFromHome===true)?"yellow":"transparent"}}>Home</label>


    <div className='chkWorkfromoffcice'>
    <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromoffcice" name="chkWorkfromoffcice" checked={workFromOffice} onChange={()=> props.setPreference("workFromOffice",!workFromOffice)}/>
    </div>
    <label id='WPOffice' for="chkWorkfromoffcice" style={{color:workFromOffice?"red":"black",backgroundColor:(hightlight.workFromOffice===true)?"yellow":"transparent"}}>Office</label>
	


    <div className='chkTemporary'>
    <input disabled={props.type==="View"} type="checkbox" id="chkTemporary" name="chkPartTime" checked={temporary} onChange={()=>props.setPreference("temporary",!temporary)}/>     
    </div>
    <label id='WPTemporary' for="chkPartTime" style={{color:temporary?"red":"black",backgroundColor:(hightlight.temporary===true)?"yellow":"transparent"}}> Temporary</label>

    

    <div className='chkPartTime'>
    <input disabled={props.type==="View"} type="checkbox" id="chkPartTime" name="chkPartTime" checked={partTime} onChange={()=>props.setPreference("partTime",!partTime)}/>     
    </div>
    <label id='WPPart_Time' for="chkPartTime" style={{color:partTime?"red":"black",backgroundColor:(hightlight.partTime===true)?"yellow":"transparent"}}> Part Time </label>


    <div className='chkFullTime'>
        <input disabled={props.type==="View"} type="checkbox" id="chkFullTime" name="chkFullTime" checked={fullTime} onChange={()=> props.setPreference("fullTime",!fullTime)}/>
    </div>
    <label id='WPFull_Time' for="chkFullTime" style={{color:fullTime?"red":"black",backgroundColor:(hightlight.fullTime===true)?"yellow":"transparent"}}> Full Time </label>


    <div className='chkSemiVoice'>
    <input disabled={props.type==="View"} type="checkbox" id="chkSemiVoice" name="chkSemiVoice" checked={nonVoiceProcess===true && voiceProcess===true} onChange={(e)=>{
        
        if(e.target.checked===true){
          props.setPreference("voiceProcess",true)
          props.setPreference("nonVoiceProcess",true)
        }
        
        }}/>
	</div>
    <label id='WPSemiVoice' for="chkSemiVoice" style={{color:(nonVoiceProcess===true && voiceProcess===true?"red":"black"),backgroundColor:(hightlight.nonVoiceProcess===true) && (hightlight.voiceProcess===true) ?"yellow":"transparent"}}> Semi Voice </label>

<div className='chkNonvoice'>
<input disabled={props.type==="View"} type="checkbox" id="chkNonvoice" name="chkNonvoice" checked={nonVoiceProcess} onChange={()=>props.setPreference("nonVoiceProcess",!nonVoiceProcess)}/>
</div>
 <label id='WPNonVoice' for="chkNonvoice" style={{color:nonVoiceProcess?"red":"black",backgroundColor:(hightlight.nonVoiceProcess===true)?"yellow":"transparent"}}> Non-Voice </label>

	

	<div id="WPWork_Mode">
		<span>Work Mode</span>
	</div>

	<div id="WPTransportation">
		<span>Transportation</span>
	</div>
	
    <div id="WPShifts">
		<span>Shifts</span>
	</div>
	
    <div className='chkRotaionalShift'>
    <input disabled={props.type==="View"} type="checkbox" id="chkRotaionalShift" name="chkRotaionalShift" checked={nightShift===true && dayShift===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("nightShift",true)
          props.setPreference("dayShift",true)
         }
          }}/>
    </div>
        <label id='WPRotational' for="chkRotationalShift" style={{color:nightShift===true && dayShift===true?"red":"black",backgroundColor:(hightlight.nightShift===true)  && (hightlight.dayShift===true)?"yellow":"transparent"}}>Rotational</label>


    <div className='chkNightShift'>
    <input disabled={props.type==="View"} type="checkbox" id="chkNightShift" name="chkNightShift" checked={nightShift} onChange={()=> props.setPreference("nightShift",!nightShift)}/>
    </div>
    <label id='WPNight' for="chkNightShift" style={{color:nightShift?"red":"black",backgroundColor:(hightlight.nightShift===true)?"yellow":"transparent"}}>Night</label>


    <div className='chkDayShift'>
    <input disabled={props.type==="View"} type="checkbox" id="chkDayShift" name="chkDayShift" checked={dayShift} onChange={()=> props.setPreference("dayShift",!dayShift)}/>
    </div>
    <label id='WPDay' for="chkDayShift" style={{color:dayShift?"red":"black",backgroundColor:(hightlight.dayShift===true)?"yellow":"transparent"}}>Day</label>


    <div id="WPEmployment_Type">
		<span>Employment Type</span>
	</div>

    </div>
  )
}

export default WorkPreferencesUI
