import React, { useEffect, useState, useContext} from 'react'
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import '../Styles/RecruiterRegistrationPageMobUI.css';
import States from "../States.json";
import RecruiterProfileTopBar from '../PC/RecruiterProfileTopBar';



const RecruiterRegistrationPageMobUI = (props) => {

    const [recruiterName,setRecruiterName] = useState("");
    const [recruiterEmail,setRecruiterEmail] = useState("");
    const [recruiterMobile,setRecruiterMobile] = useState("");
    const [recruiterGender,setRecruiterGender] = useState("");
    const [companyName,setCompanyName] = useState("");
    const [companyState,setCompanyState] = useState("");
    const [companyCity,setCompanyCity] = useState("");
    const [companyAddress,setCompanyAddress] = useState("");
    const [gstNumber,setGstNumber] = useState("");
    const [companyPanNumber,setCompanyPanNumber] = useState("");
    const [userPanNummber,setUserPanNummber] = useState("");
    const [userAadhaarNumber,setUserAadhaarNumber] = useState("");
    const [companyRegistrationNumber,setCompanyRegistrationNumber] = useState("");
    const [companyPinCode,setCompanyPinCode] = useState("");
    const [companyType,setCompanyType] = useState("");
    const [img,setImg]= useState("");
    const [profilePic,setProfilePic] = useState("")
    const [cRDName,setCRDName] = useState("")
    const [cRDdocument,setCRDdocument] = useState("")
    const [gSTDName,setGSTDName] = useState("")
    const [gSTdocument,setGSTdocument] = useState("")
    const [cPANDName,setCPANDName] = useState("")
    const [cPANdocument,setCPANdocument] = useState("")
    const [uPANDName,setUPANDName] = useState("")
    const [uPANdocument,setUPANdocument] = useState("")
    const [uADDName,setUADDName] = useState("")
    const [uADdocument,setUADdocument] = useState("")
    const [companyPanDocumentUrl,setCompanyPanDocumentUrl] = useState("");
    const [userAadhaarDocumentUrl,setUserAadhaarDocumentUrl] = useState("");
    const [gstRegistrationDocumentUrl,setGstRegistrationDocumentUrl] = useState("");
    const [companyRegistrationDocumentUrl,setCompanyRegistrationDocumentUrl] = useState("");
    const [userPanDocumentUrl,setUserPanDocumentUrl] = useState("");
    const [recruiterId,setRecruiterId] = useState("")
    const [page2,setPage2] = useState(false)
    const { user, setUser} = useContext(UserContext);
   

useEffect(()=>{
if(user){
    if(user.accesstype==="recruiter"){
      setRecruiterName(user.name);
      setRecruiterEmail(user.email);
      setRecruiterMobile(user.phone);
    }else{
      getRecruiterDatails()
    }
    
}
},[user])    


const getRecruiterDatails = () => {
  let apiURL = process.env.REACT_APP_API_URL + "/recruiters/getrecruiterdetails/"+user._id;
  let apiParams = {
    method: "GET",
    headers:{
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
       
        setRecruiterId(repos.data._id)
        setRecruiterName(repos.data.name)
        setRecruiterEmail(repos.data.email)
        setRecruiterMobile(repos.data.mobile)
        setRecruiterGender(repos.data.gender)
        setCompanyName(repos.data.companyName)
        setCompanyState(repos.data.companyState)
        setGstNumber(repos.data.gstNumber)
        setCompanyAddress(repos.data.companyAddress);
        setCompanyCity(repos.data.companyCity)
        setCompanyPanNumber(repos.data.companyPanNumber)
        setUserPanNummber(repos.data.userPanNumber)
        setUserAadhaarNumber(repos.data.userAadhaarNumber)
        setCompanyRegistrationNumber(repos.data.companyRegistrationNumber)
        setCompanyPinCode(repos.data.companyPINcode)
        setCompanyType(repos.data.companyType)
        if(repos.data.profilePicUrl && repos.data.profilePicUrl.length>50){
          setImg("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
        }
        setCompanyPanDocumentUrl(repos.data.companyPanDocumentUrl)
        setUserAadhaarDocumentUrl(repos.data.userAadhaarDocumentUrl)
        setGstRegistrationDocumentUrl(repos.data.gstRegistrationDocumentUrl)
        setCompanyRegistrationDocumentUrl(repos.data.companyRegistrationDocumentUrl)
        setUserPanDocumentUrl(repos.data.userPanDocumentUrl)
      } else props.showAlert("" + (repos.message || repos), "error");
    })
    .catch((err) => props.showAlert("" + err, "error"));
};


const handleSaveRegistration = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/users/addRecruiterRegistration";
    let apiParams = {
        method:"POST",
        headers:
        {
          recruitername:recruiterName,  
          recruiteremail:recruiterEmail,
          recruitermobile:recruiterMobile,
          recruitergender:recruiterGender,
          companyname:companyName,
          companystate:companyState,
          companycity:companyCity,
          companyaddress:companyAddress,

          gstnumber:gstNumber,
          companypannumber:companyPanNumber,
          userpannummber:userPanNummber,
          useraadhaarnumber:userAadhaarNumber,
          companyregistrationnumber:companyRegistrationNumber,
          companypincode:companyPinCode,
          companytype:companyType,
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
//company logo
         profilePic && UploadImageToDrive(repos.data._id,"logo",profilePic);
//company registration document
         cRDdocument && UploadImageToDrive(repos.data._id,"CRD",cRDdocument);
//gst document
         gSTdocument && UploadImageToDrive(repos.data._id,"GST",gSTdocument);
//company pan document
         cPANdocument && UploadImageToDrive(repos.data._id,"CPAN",cPANdocument);
//user pan document
         uPANdocument && UploadImageToDrive(repos.data._id,"UPAN",uPANdocument);
//user adhar document
         uADdocument && UploadImageToDrive(repos.data._id,"UAD",uADdocument);

         props.showAlert("Recruiters Details Added Successfully!!","success")
        
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
}



const handleImageUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    handleFileSelect(e.target.files[0]);

    setProfilePic(e.target.files[0]);
   
  };

//Upload Image
  const UploadImageToDrive = async (recruiterId,value,Document) =>{

    var file = Document //the file
    var reader = new FileReader() //this for convert to Base64 
    reader.readAsDataURL(Document) //start conversion...
    reader.onload = function (e) { //.. once finished..
      var rawLog = reader.result.split(',')[1]; //extract only thee file data part
      var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
     fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
        { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
        .then(res => res.json()).then((data) => {
          //See response
          
           SaveDocumentsURL(data,recruiterId,value)
          props.showAlert("Document Uploaded Successfully","success")
  
        }).catch(err => console.log(err)) // Or Error in console
    }
  }


const SaveDocumentsURL = (data,recruiterId,value)=>{
if(recruiterId){
   
  let apiURL= process.env.REACT_APP_API_URL + "/users/saveRecProfileUrl/" + recruiterId;
  let apiParams = {
      method:"POST",
      headers:{
        profilepicurl:data.url,
        value:value,
      }
}
fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
    if (repos.data) {
     
  
    }
  })
  .catch(e => console.log(e)) 
} 
}


  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

//Company Registration Document  upload
const CRDDocUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    // handleCRDFileSelect(e.target.files[0]);
    alert("File Selected is "+e.target.files[0].name)
    setCRDName(e.target.files[0].name)
    setCRDdocument(e.target.files[0]);
   
  };

 //GST Document Upload 

const GSTDocUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    // handleCRDFileSelect(e.target.files[0]);
    alert("File Selected is "+e.target.files[0].name)
    setGSTDName(e.target.files[0].name)
    setGSTdocument(e.target.files[0]);
   
  };


 //Company Pan Document Upload 

const CPANDocUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    // handleCRDFileSelect(e.target.files[0]);
    alert("File Selected is "+e.target.files[0].name)
    setCPANDName(e.target.files[0].name)
    setCPANdocument(e.target.files[0]);
   
  };


 //User Pan Document Upload 

const UPANDocUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    // handleCRDFileSelect(e.target.files[0]);
    alert("File Selected is "+e.target.files[0].name)
    setUPANDName(e.target.files[0].name)
    setUPANdocument(e.target.files[0]);
   
  };

 //User aadhaar Document Upload 

const UAddDocUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    // handleCRDFileSelect(e.target.files[0]);
    alert("File Selected is "+e.target.files[0].name)
    setUADDName(e.target.files[0].name)
    setUADdocument(e.target.files[0]);
   
  };
  
const handleUpdateRegistration = ()=>{

  let apiURL=process.env.REACT_APP_API_URL + "/recruiters/updateRecruiterRegistration/"+user._id;
  let apiParams = {
      method:"POST",
      headers:
      {
        // recruitername:recruiterName,  
        // recruiteremail:recruiterEmail,
        // recruitermobile:recruiterMobile,
        recruitergender:recruiterGender,
        companyname:companyName,
        companystate:companyState,
        companycity:companyCity,
        companyaddress:companyAddress,

        gstnumber:gstNumber,
        companypannumber:companyPanNumber,
        userpannummber:userPanNummber,
        useraadhaarnumber:userAadhaarNumber,
        companyregistrationnumber:companyRegistrationNumber,
        companypincode:companyPinCode,
        companytype:companyType,
        authtoken: localStorage.getItem("loggedintoken")
      },
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
//company logo
       profilePic && UploadImageToDrive(repos.data._id,"logo",profilePic);
//company registration document
       cRDdocument && UploadImageToDrive(repos.data._id,"CRD",cRDdocument);
//gst document
       gSTdocument && UploadImageToDrive(repos.data._id,"GST",gSTdocument);
//company pan document
       cPANdocument && UploadImageToDrive(repos.data._id,"CPAN",cPANdocument);
//user pan document
       uPANdocument && UploadImageToDrive(repos.data._id,"UPAN",uPANdocument);
//user adhar document
       uADdocument && UploadImageToDrive(repos.data._id,"UAD",uADdocument);

       props.showAlert("Recruiters Details Added Successfully!!","success")
      
      }else props.showAlert(""+(repos.message || repos),"error");
  }) 
  .catch((err) => props.showAlert((''+err),"error"));

}



  return (
    <div>
  <div id="reg_page">
	<img id="bg_design_reg" src="bg_design_reg.png" srcset="bg_design_reg.png 1x" alt=''/>
		
	<svg class="top_design">
		<ellipse id="top_design" rx="229.5" ry="64.5" cx="229.5" cy="64.5">
		</ellipse>
	</svg>
                     <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                      <img id="registration_img_mob" width="100" height="100" src={img?img:"registration_pfp_mob_img.png"}  alt="Reload?" />
                      </label>
	
    {/* Page 1 */}
       {page2 === false ?<div id="reg_page_1_details">
		<div id="rec_page_scrool_mob">
			<div id="name_grp">
				<input class="name_input" disabled value={recruiterName} onChange={(e)=> {
            
            setRecruiterName(e.target.value);}}/>
				
				<div id="Name_lbl_rec">
					<span>Name</span>
				</div>
			</div>
			<div id="mob_no_grp">
				<input type="number" class="mob_input" disabled value={recruiterMobile} onChange={(e)=> {
            
            setRecruiterMobile(e.target.value);}}/>
					
				<div id="Mobile_Number_lbl_rec">
					<span>Mobile Number</span>
				</div>
			</div>
			<div id="com_name_grp_mob">
				<input class="com_name_input"  value={companyName} onChange={(e)=> {
            
            setCompanyName(e.target.value);}}/>
					
				<div id="Company_Name_lbl_rec">
					<span>Company Name</span>
				</div>
			</div>
			<div id="com_state_grp">
				<select class="com_state_input" value={companyState} onChange={(e)=> {
            
            setCompanyState(e.target.value);}}>
        <option></option>
			  {States &&
                                    States.map((state) => (
                                    <option value={state.name}>
                                      {state.name}
                                    </option>
                                  ))}
				</select>
				<div id="Company_State_lbl_rec">
					<span>Company State</span>
				</div>
			</div>
			<div id="com_city_grp">
				<input class="com_city_input" value={companyCity} onChange={(e)=> {
            
            setCompanyCity(e.target.value);}}/>
				
				<div id="Company_City_lbl_rec">
					<span>Company City</span>
				</div>
			</div>
			<div id="email_grp">
				<input class="email_input"  disabled value={recruiterEmail} onChange={(e)=> {
            
            setRecruiterEmail(e.target.value);}}/>
				
				<div id="Email_ID_lbl_rec">
					<span>Email ID</span>
				</div>
			</div>
			<div id="gen_grp">
				<select class="geninput" value={recruiterGender} onChange={(e)=> {
            
            setRecruiterGender(e.target.value);}}>
            <option></option>
			      <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="others">Others</option>

				</select>
				<div id="Gender_lbl_rec">
					<span>Gender</span>
				</div>
			</div>
			<div id="com_add_grp">
				<textarea class="com_add_input" value={companyAddress} onChange={(e)=> {
            
            setCompanyAddress(e.target.value);}} />
					
				<div id="Company_Address_lbl_rec">
					<span>Company Address</span>
				</div>
			</div>
			<div id="com_type_grp_mob">
				<select class="com_typ_lbl_rec" value={companyType} onChange={(e)=> {
            setCompanyType(e.target.value);}}>

          <option></option>
					<option>Proprietorship</option>
          <option>Partnership</option>
          <option>LLP</option>
          <option>Government</option>
          <option>Other</option>
          <option>Private Limited</option>
          <option>Public Limited</option>
				</select>
				<div id="Company_Type_lbl_rec">
					<span>Company Type</span>
				</div>
			</div>
			<div id="pin_grp">
				<input class="Pin_lbl_rec"  value={companyPinCode} onChange={(e)=> {
            
            setCompanyPinCode(e.target.value);}}/>
				
				<div id="Company_PIN_code_lbl_rec">
					<span>Company PIN code</span>
				</div>
			</div>
		</div>
		<div onClick={()=>setPage2(true)} id="next_grp_btn">
			<svg class="btn_bdr_reg_bj">
				<linearGradient id="btn_bdr_reg_bj" spreadMethod="pad" x1="0.575" x2="1.074" y1="0.144" y2="1.128">
					<stop offset="0" stop-color="#ffaeae" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="btn_bdr_reg_bj" rx="6" ry="6" x="0" y="0" width="142" height="49">
				</rect>
			</svg>
			<div id="Next_lbl">
				<span>Next</span>
			</div>
		</div>
	     </div>:""}

   {/* Page 2 */}
 {page2 === true? <div id="reg_page_2_uploads">
	
		<div id="over_upload_grp_mob">
			<div id="gst_grp_mob">
				<div id="GST_No_lbl_rec_mob">
					<span>GST No.</span>
				</div>
				<input class="gst_pan_input_mob"  value={gstNumber} onChange={(e)=> {
            
            setGstNumber(e.target.value);}}/>
					{/* <rect id="gst_pan_input_mob" rx="0" ry="0" x="0" y="0" width="233.117" height="43">
					</rect>
				</input> */}
			</div>
			<div id="com_pan_grp_mob">
				<div id="Company_PAN_Number_mob">
					<span>Company PAN Number</span>
				</div>
				<input class="com_pan_input_mob"  value={companyPanNumber} onChange={(e)=> {
            
            setCompanyPanNumber(e.target.value);}}/>
					{/* <rect id="com_pan_input_mob" rx="0" ry="0" x="0" y="0" width="233.117" height="43">
					</rect>
				</input> */}
			</div>
			<div id="pan_grp_mob">
				<div id="User_PAN_Number_mob">
					<span>User PAN Number</span>
				</div>
				<input class="pan_number_input_mob" value={userPanNummber} onChange={(e)=> {
            
            setUserPanNummber(e.target.value);}}/>
					{/* <rect id="pan_number_input_mob" rx="0" ry="0" x="0" y="0" width="233.117" height="43">
					</rect>
				</input> */}
			</div>
			<div id="aadhar_grp_mob">
				<div id="User_Aadhaar_Number_mob">
					<span>User Aadhaar Number</span>
				</div>
				<input class="aadhar_lbl_rec_mob"  value={userAadhaarNumber} onChange={(e)=> {
            
            setUserAadhaarNumber(e.target.value);}}/>
					{/* <rect id="aadhar_lbl_rec_mob" rx="0" ry="0" x="0" y="0" width="233.117" height="43">
					</rect>
				</input> */}
			</div>
			<div id="com_reg_grp_mob">
				<div id="Company_Registration_mob">
					<span>Company Registration Number</span>
				</div>
				<input class="com_reg_imput_mob"   value={companyRegistrationNumber} onChange={(e)=> {
            
            setCompanyRegistrationNumber(e.target.value);}}/>
					{/* <rect id="com_reg_imput_mob" rx="0" ry="0" x="0" y="0" width="232" height="43">
					</rect>
				</input> */}
			</div>
       {/*Company Registration Document Upload and Download*/}

    <label for="crd">
                        <input id="crd" type="file" name="image"  hidden accept="image/*" onChange={(e) => CRDDocUpload(e)}/>
                      <img id="reg_no_upload1_mob" width="100" height="100" src={"reg_no_upload1_mob.png"} title={cRDName?cRDName:"Upload Image!!"}  alt="Reload?" />
     </label>
		
 {companyRegistrationDocumentUrl && companyRegistrationDocumentUrl!=="" ?<img id="reg_no_down_mob"  src="reg_no_down_mob.png" srcset="reg_no_down_mob.png 1x" alt='reload' title="Download" onClick={(e)=>document.getElementById('CompanyRegDownload').click()}/>:""}

{companyRegistrationDocumentUrl && companyRegistrationDocumentUrl!=="" ?  <a  id={"CompanyRegDownload"}  style={{display:"none"}} name="Document" href={companyRegistrationDocumentUrl}  target="_blank" ></a>:""}

	 {/*GST Document Upload and Download*/}	

   <label for="gstd">
                        <input id="gstd" type="file" name="image"  hidden accept="image/*" onChange={(e) => GSTDocUpload(e)}/>
                      <img id="gst_up_mob" width="100" height="100" src="gst_up_mob.png" title={gSTDName?gSTDName:"Upload Image!!"}  alt="Reload?" />
     </label>


{gstRegistrationDocumentUrl && gstRegistrationDocumentUrl!=="" ?<img  id="gst_down_mob" src="gst_down_mob.png" srcset="gst_down_mob.png 1x" alt='reload' title="Download" onClick={(e)=>document.getElementById('GSTRegistrationDownload').click()}/>:""}

{gstRegistrationDocumentUrl && gstRegistrationDocumentUrl!=="" ?  <a  id="GSTRegistrationDownload"  style={{display:"none"}} name="Document" href={gstRegistrationDocumentUrl}  target="_blank" ></a>:""}
				
         {/*Company PAN Document Upload and Download*/}  

      <label for="cpand">
                        <input id="cpand" type="file" name="image"  hidden accept="image/*" onChange={(e) => CPANDocUpload(e)}/>
                      <img id="com_pan_up_mob" width="100" height="100" src="com_pan_up_mob.png" title={cPANDName?cPANDName:"Upload Image!!"}  alt="Reload?" />
     </label>
		
{companyPanDocumentUrl && companyPanDocumentUrl!=="" ?<img id="com_pan_down_mob"  src="com_pan_down_mob.png" srcset="com_pan_down_mob.png 1x" alt='reload' title="Download" onClick={(e)=>document.getElementById('CompanyPanDownload').click()}/>:""}

{companyPanDocumentUrl && companyPanDocumentUrl!=="" ?  <a  id={"CompanyPanDownload"}  style={{display:"none"}} name="Document" href={companyPanDocumentUrl}  target="_blank" ></a>:""}

			 {/*user PAN Document Upload and Download*/}	
       <label for="upand">
                        <input id="upand" type="file" name="image"  hidden accept="image/*" onChange={(e) => UPANDocUpload(e)}/>
                      <img id="pan_up_img_mob" width="100" height="100" src="pan_up_img_mob.png" title={uPANDName?uPANDName:"Upload Image!!"}  alt="Reload?" />
     </label>
		
{userPanDocumentUrl && userPanDocumentUrl!=="" ?<img id="pan_img_rec_mob" src="pan_img_rec_mob.png" srcset="pan_img_rec_mob.png 1x" alt='reload' title="Download" onClick={(e)=>document.getElementById('UserPanDownload').click()}/>:""}

{userPanDocumentUrl && userPanDocumentUrl!=="" ?  <a  id={"UserPanDownload"}  style={{display:"none"}} name="Document" href={userPanDocumentUrl}  target="_blank" ></a>:""}
	
			 {/*user aadhaar Upload and Download*/}
       <label for="uad">
                        <input id="uad" type="file" name="image"  hidden accept="image/*" onChange={(e) => UAddDocUpload(e)}/>
                      <img id="upload1_img_rec_mob" width="100" height="100" src="upload1_img_rec_mob.png" title={uADDName?uADDName:"Upload Image!!"}  alt="Reload?" />
     </label>
		
     {userAadhaarDocumentUrl && userAadhaarDocumentUrl!=="" ?<img id="download1_img_rec_mob" src="download1_img_rec_mob.png" srcset="download1_img_rec_mob.png 1x" alt='reload' title="Download" onClick={(e)=>document.getElementById('UserAadhaarDownload').click()}/>:""}

{userAadhaarDocumentUrl && userAadhaarDocumentUrl!=="" ?  <a  id={"UserAadhaarDownload"}  style={{display:"none"}} name="Document" href={userAadhaarDocumentUrl}  target="_blank" ></a>:""}


				
		</div>

    <div id="register_btn_mob" onClick={()=>{recruiterId?handleUpdateRegistration():handleSaveRegistration()}}>
			<svg class="reg_btn_mob_bt">
				<linearGradient id="reg_btn_mob_bt" spreadMethod="pad" x1="0.575" x2="1.074" y1="0.144" y2="1.128">
					<stop offset="0" stop-color="#ffaeae" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#fff" stop-opacity="1"></stop>
				</linearGradient>
				<rect id="reg_btn_mob_bt" rx="6" ry="6" x="0" y="0" width="142" height="49">
				</rect>
			</svg>
			<div id="Register_mob">
				<span>{recruiterId?"Update":"Register"}</span>
			</div>
		</div>
	</div>:""}
  {page2 === true?	<img onClick={()=>setPage2(false)} id="previous_lbl_img_mob" src="previous_lbl_img_mob.png" srcset="previous_lbl_img_mob.png 1x" alt='' />:""}
		

</div>
    </div>
  )
}

export default RecruiterRegistrationPageMobUI
