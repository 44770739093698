import React, { useState, useEffect, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Divider } from "@mui/material";
import "./RecruiterOfficeLocations.css";
import ScheduleInterview from "./ScheduleInterview";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
import { id } from "date-fns/locale";
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import States from "./States.json";

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import AddOfficeLocationPopUpUI from "./AddOfficeLocationPopUpUI";
import AddRecCitiesPopUpUI from "./AddRecCitiesPopUpUI";

const Cities = (props) => {
  const OffLoc = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 500,
    scrollY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  //UseStates

  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");

  const [open, setOpen] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [state, setState] = useState("Karnataka");
  const [city, setCity] = useState("Bangalore");
  const [area, setArea] = useState("");
  const [company, setCompany] = useState("");
  const [office, setOffice] = useState("");

  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locations, setLocations] = useState([]);
  const [mapUrl,setMapUrl] = useState("");
  const [clawPeriod,setClawPeriod] = useState(0);
  const [img,setImg] = useState("");
  const [profilePic,setProfilePic]= useState("");
  const [selectedRowData,setSelectedRowData] = useState();
  const [aldocument,setALdocument] = useState("");
  const [alDName,setAlDName] = useState("");
  const [approvalStatus,setApprovalStatus] = useState("")
  
  const { user } = useContext(UserContext);

  const [filterModel, setFilterModel] = React.useState({
    items: [{ columnField: "", operatorValue: "contains", value: user.name }],
  });

  const cols = [
    { field: "id", headerName: "id", hide: true },
    { field: "state", headerName: "State", width: 190 },
    { field: "city", headerName: "City", width: 120 },
   
   
    { field: "latitude", headerName: "Latitude", width: 120, headerAlign:'center',  align:"center"},
    { field: "longitude", headerName: "Longitude", width: 120 , headerAlign:'center', align:"center"},
  
     { field: "status", headerName: "Approval Status", width: 260 , headerAlign:'center', align:"center", renderCell: (params) => {
      return [<div>
        <FormControl variant="outlined" disabled={user.accesstype== 5 ? false : true} sx={{width: "200px",marginTop:"2px",textAlign:"center" }} size="small"  >
         <InputLabel id="status-select"  ></InputLabel>
         <Select
           labelId="status"
           id="status-select"
           
           value={params.row.status ||"inreview"} onChange={(e)=> {
       
            setApprovalStatus(e.target.value); 
            updateApprovalStatus(params.row,e.target.value);
            //  sendWhatsapp(params.row,e.target.value)  
 
            } } >
         
           <MenuItem value="inreview">In Review</MenuItem>
           <MenuItem value="approved">Approved</MenuItem>
           <MenuItem value="rejected">Rejected</MenuItem>
           <MenuItem value="onhold">On Hold</MenuItem>
         </Select>
       </FormControl>
       
 </div>];
    },},
    {
      field: "active",
      headerName: "Active",
      headerAlign:'center',
      align:"center",
  
      width: 100,
      renderCell: (params) => {
        return [<input type="checkbox" checked={params.row.active} />];
      },
    },
  ];


  useEffect(() => {
    if(user){
      if(user.accesstype==="r"){
        // getLocations();
      }
      if(user.accesstype==5){
        getAllRecuitersLocation()
      }
    }
  
  }, [user]);



  const getAllRecuitersLocation = ()=>{
    let apiURL = process.env.REACT_APP_API_URL + "/cities/getallrecruitercities";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      },
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //console.log(repos.data)
          setLocations(repos.data);
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert("" + err, "error"));
  } 
  
  
const updateApprovalStatus = (row,value)=>{

  // if((row.status==="approved" || value === "rejected") && parseInt(user.accesstype)<2)
  // {
  //   return alert("You don't have access to change result, contact your Manager to update!!")
  // }

  let apiURL =  process.env.REACT_APP_API_URL + "/cities/updatecityApprovalstatus/"+row.id;
      let apiParams ={
        method:"POST",
        headers:{
         status:value,
         authtoken: localStorage.getItem("loggedintoken")
      }
      };
      fetch(apiURL,apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=>{
        if(repos.data){
         
          setIsLoading(false)
          
          props.showAlert("Approval Status Updated Successfully","success")
          setTimeout(()=>{
            getAllRecuitersLocation()
          },1000)
        }
  
      })
      .catch((err) => props.showAlert((''+err),"error"));
  }
  

  const addRecCities = () => {
    setOpen(true);
  };

  const editLocations = (row) => {
    setSelectedRowData(row)  
    setId(row.id);
    setActive(row.active);
    setState(row.state);
    setCity(row.city);
    // setArea(row.area);
    setCompany(row.company);
    setOffice(row.office);
    setLongitude(row.longitude);
    setLatitude(row.latitude);
    setMapUrl(row.mapUrl);
    if(row.officeLogoUrl && row.officeLogoUrl.length>50){
      setImg("https://drive.google.com/uc?export=view&id="+row.officeLogoUrl.split('/')[5])
      // console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                    }
    
    setClawPeriod(parseInt(row.clawPeriod));
    setOpen(true);
  };
  

  const rowData = locations?.map((loc) => {
    return {
      id: loc?._id,
      state: loc?.state,
      city: loc?.city,
      citiesLogoUrl:loc?.citiesLogoUrl,
      longitude: loc?.longitude,
      latitude: loc?.latitude,
      mapUrl:loc?.mapUrl,
      status:loc?.status,
      active: loc?.active,
    };
  });

  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
      <Button
        size="small"
        variant="contained"
        className="intern"
        title={"Add Office Locations"}
        onClick={() => addRecCities()}
      >
        + Cities
      </Button>
      <br />
      <br />
      <div style={{ height: "calc(100% - 50px)", width: "100%" }}>
        <DataGrid
          rows={rowData}
          columns={cols}
          components={{
            Toolbar: GridToolbar,
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          onCellClick={(cell)=>cell.field!=="authorizationLetterUrl" && cell.field !=="status"?editLocations(cell.row):null
          }
          // onRowClick={(e) =>{console.log(e); editLocations(e.row)}}
        />

            <AddRecCitiesPopUpUI open={open} selectedRowData={selectedRowData} showAlert={props.showAlert} close={()=>{setOpen(false);setSelectedRowData();(getAllRecuitersLocation())}}/>
      </div>
    </div>
  );
};

export default Cities;
