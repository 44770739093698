import React from 'react'
import Alert from '@mui/material/Alert';
import { SnackbarProvider, useSnackbar } from 'notistack';

const AlertPage = (props) => {
  const capitalize = (word)=> {
    const lower = word.toLowerCase();
    return lower.charAt(0).toUpperCase() + lower.slice(1); 
        }
  return (
    props.alert &&  <div>
    <Alert style={{zIndex:"10000px !important"}} variant='filled' severity={`${props.alert.type}`}> <b>{capitalize(props.alert.type)}</b>: {props.alert.msg}</Alert>

    </div>
  )
}

export default AlertPage