import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import './InterviewDetails.css'
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { UserContext } from './UserContext';
import TimePicker from '@mui/lab/TimePicker';
import { useSearchParams } from 'react-router-dom';
import BulkDataUpload from './BulkDataUpload';
import Checkbox from '@mui/material/Checkbox';
import Timer from './Timer';
import ApplicantPopup from './ApplicantPopup';

// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

const InterviewDetails = (props) => {
  
const [searchParams] = useSearchParams();
const [interview, setInterview] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [name, setName] = useState();
const [mail, setMail] = useState();
const [mobile, setMobile] = useState();
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState();
const [applicantId,setApplicantId]= useState()
const [companyName, setCompanyName] = useState();
const [open, setOpen] = React.useState(false);
const [Rounds, setRounds] = useState();
const [InterViewerName, setInterViewerName]= useState("");
const [InterViewDate, setInterViewDate] = React.useState(new Date());
const [InterViewTime, setInterViewTime] = React.useState();
const [interviewlocation, setInterviewlocation] = useState();
const [selectedRow,setSelectedRow] = useState();
const [isLoading, setIsLoading] = useState(false);
const { user, setUser} = useContext(UserContext);

const [pageState,setPageState] = useState({
  isLoading:false,
  data:[],
  total:0,
  page:1,
  pageSize:100
})

  const rowData = (interview)?.map((interview) => {
    let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);

    return{
  id: interview?._id,  //applicant id
  applicantid:interview?.applicantId, 
  jobid:interview?._id,  //interview id
  name: interview?.name,
  mobile: interview?.mobile,
  jobtitle:interview?.jobtitle,
  companyname:interview?.companyname + " - " +interview?.officeName,
  interviewername:interview?.interviewername,
  timespent:interview?.timeSpent,
  hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
  interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
  interviewtime:IntvwTime,
  interviewlocation:interview?.location,
  rounds:interview?.rounds,
  reference:interview?.reference,
  status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
  feedback:interview?.feedback,
  bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
  bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
  languages:interview?interview.languages:"",
  englishrating:interview?parseInt(interview.englishRating):"",
  communicationrating:interview?parseInt(interview.communicationRating):"",
  location:interview?interview.location:"Mobile Call",
  createdbyname:interview?.createdbyname,
  createdbyid:interview?.createdbyId,
  
    };
  });
  

const [filterModel, setFilterModel] = React.useState({
  items: user.accesstype == 5 ? [
    {columnField: 'rounds', operatorValue: 'contains', value:"Verification"}
  ]: [
    {columnField: 'interviewername', operatorValue: 'contains', value:user.name}
  ],
});

const style3 = {
  position: 'absolute',
  top: '50%',

  transform: 'translate(87%, -50%)',
  height: 489,
  width: 533,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 10,

};

const cols = [
  {field:'action', headerName: '',width: 80, headerAlign:'center',

  renderCell: (params) => {
   return[
      <div>
       <Button size='small' title="Schedule"  onClick={()=>showApplicant(params.row)}><PersonSearchIcon/></Button>
      
</div>
   ];
  },
},  
  {field:'name', headerName: 'Name',width: 190 },
  {field:'createdbyname', headerName: 'Created By',width: 190, hide: true },
  {field:'mobile', hide: user.accesstype != 5, headerName: 'Mobile No',width: 140},
  // {field:'location', headerName: 'Location',width: 190},
  // {field:'languages', headerName: 'Languages',width: 190},
  {field:'hrname', headerName: 'HR Name',width: 190},
  {field:'timespent', headerName: 'Time Spent - '+ (((rowData.reduce((prv,curr)=>(curr.timespent && parseInt(curr.timespent)>0)?prv+parseInt(curr.timespent):prv,0)>0))? String(parseInt(rowData.reduce((prv,curr)=>(curr.timespent && parseInt(curr.timespent)>0)?prv+parseInt(curr.timespent):prv,0)/60/60)).padStart(2, '0')+":"+String(parseInt((rowData.reduce((prv,curr)=>(curr.timespent && parseInt(curr.timespent)>0)?prv+parseInt(curr.timespent):prv,0)/60)%60)).padStart(2, '0')+":"+String(rowData.reduce((prv,curr)=>(curr.timespent && parseInt(curr.timespent)>0)?prv+parseInt(curr.timespent):prv,0)%60).padStart(2, '0'):"00:00:00")
  ,width: 190,renderCell: (params) => {
   // console.log(params.row)
    return[
     (parseInt(params.row.timespent) && parseInt(params.row.timespent)>0)? String(parseInt(parseInt(params.row.timespent)/60/60)).padStart(2, '0')+":"+String(parseInt((parseInt(params.row.timespent)/60)%60)).padStart(2, '0')+":"+String(parseInt(params.row.timespent)%60).padStart(2, '0'):"00:00:00"];
  },},
  {field:'englishrating', headerName: 'English Rating',width: 180,
  renderCell: (params) =>{
    switch(params.row.englishrating){

     case "1":
     return  ["E - Pathetic"]
    
     case "2":
       return ["D - Bad"]
    
     case "3":
       return  ["C - Average"]
   
     case "4":
       return  ["B - Good"]
    
     case "5":
       return ["A - Excellent"]
      
     default :
     return  [""]
    
    }
 },
},
  {field:'communicationrating', headerName: 'Communication Rating',width: 180,renderCell: (params) =>{
    switch(params.row.communicationrating){
      case "1":
        return  ["E - Pathetic"]
       
        case "2":
          return ["D - Bad"]
       
        case "3":
          return  ["C - Average"]
      
        case "4":
          return  ["B - Good"]
       
        case "5":
          return ["A - Excellent"]   
      
     default :
     return  [""]
    
    }
 },
},
  {field:'jobtitle', headerName: 'Job Title',width: 200},
  {field:'companyname', headerName: 'Company Name',width: 200},
//   {field:'Resume', headerName: 'Resume',width: 150,
//  
  {field:'interviewername', headerName: 'Interviewer Name',width: 190},
 
  {field:'interviewdate', headerName: 'Interview Date',type: 'date',
  valueGetter: ({ value }) => value && new Date(value).toDateString(), width: 140},
  // {field:'interviewtime', headerName: 'Interview Time',width: 140},
  {field:'rounds', headerName: 'Rounds',width: 150},
  {field:'interviewlocation', headerName: 'Interview Location',width: 200},
  
  {field:'status', headerName: 'Status',width: 110},
  {field:'bookmark', headerName: 'Bookmarked', renderCell: (params) =>{

    return[
     <div>
    
    <Checkbox {...label} disabled checked={params.row.bookmark} />{params.row.bookmarkcomment}
    </div>
    ];
     },
    }
 
  ];
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const getInterviews = ()=>{
  
    let apiURL="";
    if(searchParams.get('applicantid'))
    {
      apiURL=process.env.REACT_APP_API_URL + "/interview/applicantinterview/"+searchParams.get('applicantid')+`?page=${pageState.page}&limit=${pageState.pageSize}`;
    }
    else if (searchParams.get('jobid'))
    {
      apiURL=process.env.REACT_APP_API_URL + "/interview/getjobinterviews/"+searchParams.get('jobid')+`?page=${pageState.page}&limit=${pageState.pageSize}`;
    }
    else 
    {
      setPageState(old=>({...old,isLoading:true}))
    apiURL=process.env.REACT_APP_API_URL + "/interview/"+user._id+`?page=${pageState.page}&limit=${pageState.pageSize}`;
    }
    let apiParams = {
        method:"GET", 
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        }
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          
          setInterview(repos.data.interview)
          setPageState(old=>({...old, isLoading:false,data:repos.data.interview, total :repos.data.pagination.count }))
        }else props.showAlert(""+(repos.message || repos),"error");  
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }


  useEffect(()=>{
    // if(searchParams.get('selectedCell')){
    
    //   getInterviewBySelectedCell()
    // }else{
    //   getInterviews()
    // }
  
  if(searchParams.get('applicantid'))
  {
   
    setFilterModel({
    items: [
     
    ],
  });}
  if(searchParams.get('selectedCell'))
  
  {
    
    setFilterModel({
    items: [
     
    ],
  });}
  if(searchParams.get('jobid'))
  {setFilterModel({
    items: [
     
    ],
  });}

  },[])
  
  useEffect (()=>{
 
    if(searchParams.get('selectedCell')){
    
      getInterviewBySelectedCell()
    }else{
      getInterviews()
    }
    
  },[pageState.page,pageState.pageSize])


const getInterviewBySelectedCell = ()=>{
  setIsLoading(true);
  let apiURL=process.env.REACT_APP_API_URL + "/interview/dashboardValue/"+searchParams.get('selectedCell');
  let apiParams = {
    method:"GET",
    headers:{
      authtoken: localStorage.getItem("loggedintoken")
    }
};

fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
    if (repos.data) {
    //console.log(repos.data)
    setIsLoading(false);
      console.log(repos.data)
      setInterview(repos.data)
      
    }else{
       props.showAlert(""+(repos.message || repos),"error"); 
       setIsLoading(false);
    } 
}) 
.catch((err) => {
  props.showAlert((''+err),"error");
   setIsLoading(false);
  })

}

  


  
  const scheduleinterview = (row) =>{

    setSelectedRow(row)
      // setName(row.name)
      // setJobId(row.jobid)
      // setMobile(row.mobile)
      // setJobTitle(row.jobtitle)
      // setApplicantId(row.id)
      // setMail(row.mail)
      // setCompanyName(row.companyname)
        setOpenScedule(true);
      //  alert(JSON.stringify(row))
      }
    
  const handleCloseView = () => setOpen(false)


  //Select Applicants
 const ApproveApplicants = (row) =>{
 
  let apiURL=process.env.REACT_APP_API_URL + "/interview/select applicant/" +row.id;
  let apiParams = {
      method:"POST",
      headers:{
        // applicantid:row.applicantid,
        status:"Selected",
        authtoken: localStorage.getItem("loggedintoken")
      }
}

fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
  if (repos.data) {
   
    props.showAlert("Applicant Selected","success");
    window.location.reload(false);
  
    // console.log(repos.data);
  }else props.showAlert(""+(repos.message || repos),"error");
}) 
.catch((err) => props.showAlert((''+err),"error"));
}
  
  
  //Reject Applicants
  const RejectApplicants = (row) =>{
    // alert(JSON.stringify(row))
    let apiURL=process.env.REACT_APP_API_URL + "/interview/rejectapplicant/" +row.id;
    let apiParams = {
        method:"POST",
        headers:{
          // applicantid:row.applicantid,
          status:"Rejected",
          authtoken: localStorage.getItem("loggedintoken")
        }
  }
  
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
    if (repos.data) {
     
      props.showAlert("Applicant Rejected","success")
      window.location.reload(false);
    
      // console.log(repos.data);
    }else props.showAlert(""+(repos.message || repos),"error");
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
  }

  //Redirect To Job Page
  const addInterview = () =>{
    window.location.href="/jobs?applicantid="+searchParams.get('applicantid');
  }
 

  //Schedule Interview 
  const ScheduleApplicant = () =>{
    ApproveApplicants(applicantId)
    let apiURL=process.env.REACT_APP_API_URL + "/interview/scheduleinterview/"
    let apiParams = {
        method:"POST",
        headers:{
          applicantid:applicantId,
          name:name,
          email:mail,
          mobile:mobile,
          jobtitle:jobTitle,
          interviewername : InterViewerName,
          interiewdate: InterViewDate, 
          interviewtime: InterViewTime,
          rounds: Rounds,
          jobid:jobId,
          companyname:companyName,
          interviewlocation:interviewlocation,
          status:"Scheduled",
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         // alert("InterView Scheduled Successfull");
         props.showAlert("InterView Scheduled Successfully","success")
         setTimeout(() => {
          window.location.reload(false);
        }, 2000);
          
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
    
  } 

  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }



  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
   {searchParams.get('applicantid')? <Button size='small' variant="contained"  style={{position:"absolute",right:"0px",zIndex:10}} title={"Add Interview"} disabled={searchParams.get('applicantid')?false:true} onClick={()=>addInterview()}>+ Add Interview</Button>:""}
    
    <div style={{height:'100%',width: '100%'}}>
     {(!searchParams.get('selectedCell')) ?
     <DataGrid rows={rowData}
       rowCount = {pageState.total}
          loading = {pageState.isLoading}
          // rowsPerPageOptions={[30,50,80]}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          onPageChange={(newPage)=>{
          setPageState(old=>({...old,page: newPage +1 }))
          }}
          onPageSizeChange = {(newPageSize)=>setPageState(old=>({...old, pageSize:newPageSize}))}

       columns={cols}  
    
       components={{
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowDoubleClick={(e)=>scheduleinterview(e.row)}
          />:  <DataGrid rows={rowData}
                        columns={cols}  
                        checkboxSelection
               components={{
                  Toolbar: GridToolbar,
                  }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
              onRowDoubleClick={(e)=>scheduleinterview(e.row)}
           />}

 </div>
 {/* <ScheduleInterview open={OpenShedule} showAlert={props.showAlert}  interview={selectedRow}  close={()=>setOpenScedule(false)} /> */}
<ScheduleInterviewUI open={OpenShedule} showAlert={props.showAlert}  interview={selectedRow}  close={()=>setOpenScedule(false)}  />
</div>
  ) 
}

export default InterviewDetails







