import React, {useEffect, useState} from 'react'
import '../Styles/RecuitersPagePcMob.css'
import InterviewDetails from '../InterviewDetails';
import MessagedDetails from '../MessagedDetails';
import Today from '../Today';
import Tomorrow from '../Tomorrow';
import SelectedCandidates from '../SelectedCandidates';
import JoinedCandidates from '../JoinedCandidates';
import EligibleCandidates from '../EligibleCandidates';
import InvoicedCandidates from '../InvoicedCandidates';
import SentEmailsToCandidates from '../SentEmailsToCandidates';
import RecruiterOfficeLocations from '../RecruiterOfficeLocations';
import RecruiterVacanciesVerifyUI from '../RecruiterVacanciesVerifyUI';

const RecuitersPagePcMob = (props) => {

const [selectedRecuitersTab,setSelectedInterviewTab] = useState("Recruiter Office Locations");


  return (
	<div id="Recruiter_Mob_bizibees_mob_admin">
	<div id="Recruiter_Mob_screen_and_menu_grp_BBL">
		<div id="Recruiter_Mob_discplay_grp_BBl">
			<svg class="Recruiter_Mob_display_BBL">
				<rect id="Recruiter_Mob_display_BBL" rx="6" ry="6" x="0" y="0" width="100%" height="100%">
				</rect>
			</svg>
			{selectedRecuitersTab=== "Recruiter Office Locations"?<RecruiterOfficeLocations showAlert={props.showAlert}/>:""}
		{selectedRecuitersTab=== "Recruiter Vacancies"?<RecruiterVacanciesVerifyUI showAlert={props.showAlert}/>:""}
		</div>

 <table id="Recruiter_Mob_ELOUUD_BBL">
	<tr style={{padding:0}}>
		<td class={selectedRecuitersTab=== "Recruiter Office Locations"?'selectedRecruiterTab_pc':'recruitertabs_pc'} onClick={()=>{setSelectedInterviewTab("Recruiter Office Locations");}}>
		<div >Recruiter Office Locations</div>
		</td>
	</tr>
	<tr>
		<td class={selectedRecuitersTab=== "Recruiter Vacancies"?'selectedRecruiterTab_pc':'recruitertabs_pc'} onClick={()=>{setSelectedInterviewTab("Recruiter Vacancies");}}>
		<div >Recruiter Vacancies</div>
		</td>
	</tr>
	{/* <tr>
		<td class={selectedInterviewTab=== "Eligible"?'selectedRecruiterTab_pc':'recruitertabs_pc'} onClick={()=>{setSelectedInterviewTab("Eligible");}}>
		<div >Eligible</div>
		</td>
	</tr> */}
	{/* <tr>
		<td class={selectedInterviewTab=== "Invoiced"?'selectedRecruiterTab_pc':'recruitertabs_pc'} onClick={()=>{setSelectedInterviewTab("Invoiced");}}>
		<div >Invoiced</div>
		</td>
	</tr> */}
	
</table> 

{/* 
		<div id="Recruiter_Mob_ELOUUD_BBL">
			<div id="Recruiter_Mob_lcoation_BBL">
				<span>Called</span>
			</div>
			<div id="Recruiter_Mob_OFL_BBL">
				<span>Messaged</span>
			</div>
			<div id="Recruiter_Mob_upload_BBL">
				<span>Emailed</span>
			</div>
			<div id="Recruiter_Mob_users_BBL">
				<span>Today's</span>
			</div>
			<div id="Recruiter_Mob_devices_BBL">
				<span>Tommorow's</span>
			</div>
			<div id="Recruiter_Mob_unline_and_email_grp">
				<div id="Recruiter_Mob_email_lbl_BBL">
					<span>Selected</span>
				</div>
				<svg class="Recruiter_Mob_email_underline" viewBox="0 0 1 39">
					<path id="Recruiter_Mob_email_underline" d="M 0 0 L 0 39">
					</path>
				</svg>
			</div>
		</div> */}
	</div>
		
</div>
  )
}

export default RecuitersPagePcMob
