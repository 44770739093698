import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import "../Styles/AdminDurationData.css";
import { UserContext } from '../UserContext';

const AdminDurationData = (props) => {  
   
  const { user, setUser} = useContext(UserContext);
  const alertStart = useRef(new Date());
  const [rowData,setRowData] = useState([])

useEffect(()=>{
if(props.rowData){
  setRowData(props.rowData)
}

},[props.rowData])

useEffect(()=>{


},[props.showOnlyHR,props.users])


  return (

    <div id="BottmPartDuractivity">
       <div id="BottmPartDurGroup_36">
        <div id="BottmPartDurInterview" onClick={()=>props.selectedTab("interviews")}>
          <span>Interviews</span>
        </div>
        <div id="BottmPartDurVerification" onClick={()=>props.selectedTab("verifications")}>
          <span>Verifications</span>
        </div>
        <div id="BottmPartDurothers">
          <span>Duration</span>
        </div>
        <div id="BottmPartDurothr" onClick={()=>props.selectedTab("others")}>
          <span>Others</span>
        </div>
        
          <div id="BottmPartDurActivity_w" onClick={()=>props.selectedTab("activity")}>
            <span>Activity</span>
          </div>
      </div> 
       
      <div id="BottmPartDurGroup_39">
        <svg class="BottmPartDurRectangle_46">
          <rect id="BottmPartDurRectangle_46" rx="24" ry="24" x="0" y="0" width="100%" height="100%">
          </rect>
        </svg>
        {rowData.map((row)=>(
        <div id="BottmPartDurGroup_37">
          <div id="BottmPartDurRoobi">
            <span>{row.name}</span>
          </div>
          
          <div id="BottmPartDurn_037">
            <span>{row.calledTime}</span>
          </div>
          <div id="BottmPartDurn_510545">
            <span>{row.hrIdleTime}</span>
          </div>
          <div id="BottmPartDurMohammed" >
            <span>{row.loggedTime}</span>
          </div>
          <img id="BottmPartDurspeaker-filled-audio-tool" src="speaker-filled-audio-tool.png" srcset="speaker-filled-audio-tool.png 1x" />
            
        </div>
        ))} 
        <div id="BottmPartDurGroup_38">
          <div id="BottmPartDurStatus">
            <span>Login Time</span>
          </div>
          {/* <div id="BottmPartDurn_passed">
            <span>18/31</span>
          </div> */}
          <div id="BottmPartDurTime">
            <span>Call Time</span>
          </div>
          {/* <div id="BottmPartDurn_failed">
            <span>10/31</span>
          </div> */}
          <div id="BottmPartDurQueue">
            <span>Idle Time</span>
          </div>
          {/* <div id="BottmPartDurn_queue">
            <span>00/31</span>
          </div> */}
          <svg class="BottmPartDurLine_2" viewBox="0 0 225.87 1">
            <path id="BottmPartDurLine_2" d="M 0 0 L 225.86962890625 0">
            </path>
          </svg>
        </div>
      </div>
   
  </div>
  )
}

export default AdminDurationData
