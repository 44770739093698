import React, { useState, useEffect, useContext } from "react";
import Image from "./bizibees.jpeg";
// import { useNavigate } from "react-router-dom";
// import Menu from "../Components/Menu";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import "../App.css";
import { UserContext } from "./UserContext";
import {TextareaAutosize} from "@mui/base/TextareaAutosize";
import { MenuItem } from "@mui/material";
import { Button } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";

var CryptoJS = require("crypto-js");


function Register(props) {
  const { user } = useContext(UserContext);
//   const history = useNavigate();
  const [uniqueId, setUniqueId] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState(new Date());
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");
  const [userAddress, setUserAddress] = useState("");
  const [userdob, setUserdob] = useState(new Date());
  const [userGender, setUserGender] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [userAccesstype, setUserAccessType] = useState("");
  const [userDesignation, setUserDesignation] = useState("");
  const [userConfirmpassword, setUserConfirmPassword] = useState("");
  const [userPhoto, setUserPhoto] = useState();
  const [data, setData] = useState([]);
  const [reportingToId, setReportingToId] = useState("");
  const [img, setImg] = useState();
  const [shiftStartHours, setShiftStartHours] = useState(0);
  const [shiftStartMinutes, setShiftStartMinutes] = useState(0);
  const [shiftStopHours, setShiftStopHours] = useState(0);
  const [shiftStopMinutes, setShiftStopMinutes] = useState(0);
  const [userPAN, setUserPAN] = useState("");
  const [userAadhar, setUserAadhar] = useState("");
  const [userPassport, setUserPassport] = useState("");
  const [userPassportExpiry, setUserPassportExpiry] = useState(new Date());
  const [userEmergencyContact, setUserEmergencyContact] = useState("");
  const [userEmergencyNo, setUserEmergencyNo] = useState("");
  const [userBankAccount, setUserBankAccount] = useState("");
  const [active, setActive] = useState(true);

  const handleImageUpload = (e) => {
    if (e.target.files[0].size > 71680) {
      alert("Please choose a file with size below 70kb!");
      return;
    }
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (e) => {
      setImg(e.target.result);
    };
    let formData = new FormData();
    formData.append("image", e.target.files[0], e.target.value);
    setUserPhoto(formData);
  };
  const redirected =async () => {
    if (!img) {
      alert("Please upload image!");
      return;
    } else if (userPassword === userConfirmpassword) {
      let shiftStart = shiftStartHours + shiftStartMinutes;
      let shiftEnd = shiftStopHours + shiftStopMinutes;
      const apiurl = process.env.REACT_APP_API_URL + "/api/user/register";
      var apiparam = {
        method: "POST",
        headers: {
          uid: uniqueId,
          doj: dateOfJoining,
          email: userEmail,
          name: userName,
          phone: userPhone,
          address: encodeURIComponent(userAddress),
          dob: userdob,
          gender: userGender,
          password: encodeURIComponent(await encrypt(userPassword)),
          accesstype: userAccesstype,
          designation: userDesignation,
          reportingtoid: reportingToId,
          reportingtoname: data.filter((item) => item._id === reportingToId)[0]
            .name,
          shiftStart: shiftStart,
          shiftEnd: shiftEnd,
          pannumber: userPAN,
          aadharnumber: userAadhar,
          passportnumber: userPassport,
          passportexpiry: userPassportExpiry,
          emergencycontactname: userEmergencyContact,
          emergencycontactnumber: userEmergencyNo,
          bankaccountdetails: encodeURIComponent(userBankAccount),
          active: active,
        },
        body: userPhoto,
      };
      fetch(apiurl, apiparam)
        .then((res) => (res.status === 200 ? res.json() : res.text()))
        .then((repos) => {
          if (repos.data) {
            console.log(repos);
            alert("Employee added!");
            window.location.href = "/";
          } else alert(repos.message || repos);
        })
        .catch((err) => alert(err));
    } else {
      alert("passwords do not match");
    }
  };
      
  async function encrypt(plainText) {
    return await CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
}
  useEffect(() => {
    setShiftStartHours(540);
    setShiftStartMinutes(0);
    setShiftStopHours(1080);
    setShiftStopMinutes(0);
    const apiurl = process.env.REACT_APP_API_URL + "/users/employeesdropdown?empid=" +
      user._id;
    fetch(apiurl)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setData(repos.data);
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  }, []);

  return (
    <div className=" rowseProfile no-gutters card-1 ">
      <form method="post" encType="multipart/form-data">
        <div class="form-group">
          <div
            style={{
              position: "absolute",
              float: "right",
              width: "600px",
            }}
          >
            
          </div>

          <div class="img" style={{ marginBottom: "15px" }}>
            <Tooltip title="Upload Profile Picture">
              <label for="image">
                <input
                  id="image"
                  type="file"
                  onChange={(e) => handleImageUpload(e)}
                  hidden
                  accept="image/*"
                />
                <img
                  src={img || Image}
                  alt=""
                  width="100"
                  height="100"
                  className="img"
                />
              </label>
            </Tooltip>
          </div>

          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                style={{ width: "100%" }}
                label="Unique Id"
                value={uniqueId}
                onChange={(e) => setUniqueId(e.target.value)}
                required
              />
            </div>
            <div class="col">
                <DatePicker
                  label="Date of Joining"
                  value={new Date(dateOfJoining).toISOString().split("T")[0]}
                  onChange={(newValue) => {
                    setDateOfJoining(newValue);
                  }}
                  required
                  renderInput={(params) => <TextField {...params} />}
                />
            </div>
          </div>
          <div class="row g-3">
            <div class="col">
              <TextField
                value={userName}
                label="Name"
                onChange={(e) => setUserName(e.target.value)}
                required
                style={{ width: "100%" }}
              />
            </div>
            <div class="col">
              <TextField
                select
                label="Gender"
                style={{ width: "100%" }}
                value={userGender}
                onChange={(e) => setUserGender(e.target.value)}
                required
              >
                <MenuItem value="" selected disabled>
                  Gender
                </MenuItem>
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </TextField>
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                style={{ width: "100%" }}
                label="Mobile"
                value={userPhone}
                onChange={(e) => setUserPhone(e.target.value)}
                required
              />
            </div>
            <div class="col">
              <TextField
                style={{ width: "100%" }}
                label="Email"
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
                <DatePicker
                  label="Date of Birth"
                  value={new Date(userdob).toISOString().split("T")[0]}
                  onChange={(newValue) => {
                    setUserdob(newValue);
                  }}
                  required
                  renderInput={(params) => <TextField {...params} />}
                />
            </div>
            <div class="col">
              <TextField
                style={{ width: "100%" }}
                label="Designation"
                select
                value={userDesignation}
                onChange={(e) => setUserDesignation(e.target.value)}
                required
              >
                <MenuItem value="" selected disabled>
                  Designation
                </MenuItem>

                <MenuItem value="Manager">Manager</MenuItem>
                <MenuItem value="employee">Employee</MenuItem>
                <MenuItem value="client">Client</MenuItem>
              </TextField>
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                select
                style={{ width: "100%" }}
                value={reportingToId}
                label="Reporting to"
                onChange={(e) => setReportingToId(e.target.value)}
                required
              >
                {data.map((employee) => {
                  return (
                    <MenuItem value={employee._id}>{employee.name}</MenuItem>
                  );
                })}
              </TextField>
            </div>
            <div class="col">
              <TextField
                select
                sx={{ width: "100%" }}
                label="Access Type"
                value={userAccesstype}
                onChange={(e) => setUserAccessType(e.target.value)}
                required
              >
                <MenuItem value="" selected disabled>
                  Access Type
                </MenuItem>

                <MenuItem value="1">Manager</MenuItem>
                <MenuItem value="0">Employee</MenuItem>
                <MenuItem value="5">Admin</MenuItem>
              </TextField>
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                label="Password"
                type="password"
                value={userPassword}
                sx={{
                  width: "100%",
                }}
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </div>
            <div class="col">
              <TextField
                label="Confirm Password"
                type="password"
                value={userConfirmpassword}
                sx={{
                  width: "100%",
                }}
                onChange={(e) => setUserConfirmPassword(e.target.value)}
              />
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                id="outlined-basic"
                label="PAN Number"
                variant="outlined"
                value={userPAN}
                onChange={(e) => setUserPAN(e.target.value)}
                InputLabelProps={{ shrink: true }}
                required
                disabled={user.accesstype != 5}
                style={{ width: "100%" }}
              />
            </div>
            <div class="col">
              <TextField
                id="outlined-basic"
                label="Aadhar Number"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={userAadhar}
                onChange={(e) => setUserAadhar(e.target.value)}
                required
                disabled={user.accesstype != 5}
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                id="outlined-basic"
                label="Passport Number"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={userPassport}
                onChange={(e) => setUserPassport(e.target.value)}
                style={{ width: "100%" }}
              />
            </div>
            <div class="col">
                <DatePicker
                  label="Passport Expiry Date"
                  value={
                    new Date(userPassportExpiry).toISOString().split("T")[0]
                  }
                  onChange={(newValue) => {
                    setUserPassportExpiry(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  style={{ width: "100%" }}
                  required
                />
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                id="outlined-basic"
                label="Emergency Contact Person"
                variant="outlined"
                value={userEmergencyContact}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setUserEmergencyContact(e.target.value)}
                required
                style={{ width: "100%" }}
              />
            </div>
            <div class="col">
              <TextField
                id="outlined-basic"
                label="Emergency Contact Number"
                variant="outlined"
                value={userEmergencyNo}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setUserEmergencyNo(e.target.value)}
                required
                style={{ width: "100%" }}
              />
            </div>
          </div>
          <div class="row g-3 mb-2">
            <div class="col">
              <TextareaAutosize
                aria-label="Address"
                placeholder="Address"
                style={{ width: "100%" }}
                value={userAddress}
                minRows={3}
                onChange={(e) => setUserAddress(e.target.value)}
              />
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col">
              <TextField
                style={{ width: "100%" }}
                id="bankAccount"
                label="Bank Account Details"
                variant="outlined"
                multiline={true}
                rows={3}
                InputLabelProps={{ shrink: true }}
                value={userBankAccount}
                onChange={(e) => setUserBankAccount(e.target.value)}
                required
              />
            </div>
          </div>
          <div class="row g-3 mt-2 mb-2">
            <div class="col-5">
              <TextField
                select
                style={{ width: "50%" }}
                value={parseInt(shiftStartHours)}
                label="Shift Start hours"
                onChange={(e) => {
                  setShiftStartHours(e.target.value);
                }}
              >
                <MenuItem value={0}>00</MenuItem>
                <MenuItem value={60}>01</MenuItem>
                <MenuItem value={120}>02</MenuItem>
                <MenuItem value={180}>03</MenuItem>
                <MenuItem value={240}>04</MenuItem>
                <MenuItem value={300}>05</MenuItem>
                <MenuItem value={360}>06</MenuItem>
                <MenuItem value={420}>07</MenuItem>
                <MenuItem value={480}>08</MenuItem>
                <MenuItem value={540}>09</MenuItem>
                <MenuItem value={600}>10</MenuItem>
                <MenuItem value={660}>11</MenuItem>
                <MenuItem value={720}>12</MenuItem>
                <MenuItem value={780}>13</MenuItem>
                <MenuItem value={840}>14</MenuItem>
                <MenuItem value={900}>15</MenuItem>
                <MenuItem value={960}>16</MenuItem>
                <MenuItem value={1020}>17</MenuItem>
                <MenuItem value={1080}>18</MenuItem>
                <MenuItem value={1140}>19</MenuItem>
                <MenuItem value={1200}>20</MenuItem>
                <MenuItem value={1260}>21</MenuItem>
                <MenuItem value={1320}>22</MenuItem>
                <MenuItem value={1380}>23</MenuItem>
              </TextField>
              <TextField
                select
                style={{ width: "50%" }}
                value={parseInt(shiftStartMinutes)}
                label="Shift Start minutes"
                onChange={(e) => setShiftStartMinutes(e.target.value)}
              >
                <MenuItem value={0}>00</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={45}>45</MenuItem>
              </TextField>
            </div>
            <div class="col-5">
              <TextField
                select
                style={{ width: "50%" }}
                value={shiftStopHours}
                label="Shift Stop hours"
                onChange={(e) => setShiftStopHours(e.target.value)}
              >
                <MenuItem value={0}>00</MenuItem>
                <MenuItem value={60}>01</MenuItem>
                <MenuItem value={120}>02</MenuItem>
                <MenuItem value={180}>03</MenuItem>
                <MenuItem value={240}>04</MenuItem>
                <MenuItem value={300}>05</MenuItem>
                <MenuItem value={360}>06</MenuItem>
                <MenuItem value={420}>07</MenuItem>
                <MenuItem value={480}>08</MenuItem>
                <MenuItem value={540}>09</MenuItem>
                <MenuItem value={600}>10</MenuItem>
                <MenuItem value={660}>11</MenuItem>
                <MenuItem value={720}>12</MenuItem>
                <MenuItem value={780}>13</MenuItem>
                <MenuItem value={840}>14</MenuItem>
                <MenuItem value={900}>15</MenuItem>
                <MenuItem value={960}>16</MenuItem>
                <MenuItem value={1020}>17</MenuItem>
                <MenuItem value={1080}>18</MenuItem>
                <MenuItem value={1140}>19</MenuItem>
                <MenuItem value={1200}>20</MenuItem>
                <MenuItem value={1260}>21</MenuItem>
                <MenuItem value={1320}>22</MenuItem>
                <MenuItem value={1380}>23</MenuItem>
              </TextField>
              <TextField
                select
                style={{ width: "50%" }}
                value={shiftStopMinutes}
                label="Shift Stop minutes"
                onChange={(e) => setShiftStopMinutes(e.target.value)}
              >
                <MenuItem value={0}>00</MenuItem>
                <MenuItem value={15}>15</MenuItem>
                <MenuItem value={30}>30</MenuItem>
                <MenuItem value={45}>45</MenuItem>
              </TextField>
            </div>
            <div class="col-2">
              <span>
                <input
                  type="checkbox"
                  onChange={(e) => setActive(e.target.checked)}
                  checked={active}
                />
                <label>Active</label>
              </span>
            </div>
          </div>
          <div style={{ textAlign: "center" }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => {
                redirected();
              }}
            >
              Register
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Register;
