import React,{useState} from 'react'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import emailSendUI from './emailSendUI.css';
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from './UserContext';
import ChangePassword from './ChangePassword';
import ReplyIcon from '@mui/icons-material/Reply';

const EmailSendUI = (props) => {
var navigate = useNavigate();
const [ email,setEmail] = useState("");



const sendOtp = async () =>{
// let apiUrl = process.env.REACT_APP_API_URL + "/users/emailsend";
// let apiParam = {
//   method: 'POST',
//   headers: {
//      email: email,
   
//    },
 
// };
// fetch(apiUrl, apiParam)
//   .then((res) => (res.status === 200 ? res.json() : res.text()))
//   .then((repos) => {
//     if (repos.statusText ==="success") {
   
//       props.showAlert("If the entered email is registered, OTP has been sent to eMail!","success")
//       setTimeout(() => {
//         navigate("/ChangePassword?email="+email);
       
//       }, 3000);

//     } else {
//       props.showAlert("If the entered email is registered, OTP has been sent to eMail! ","success")
//     }
//   })
//   .catch((err) => props.showAlert((JSON.stringify(err)),"error"));
if(email.length===0){
  props.showAlert("Email Cannot Be Empty","error")
}
  if(email){
  let apiURL=process.env.REACT_APP_API_URL + "/users/sendemailtoapplicant";
  let apiParams = {
    method:"POST",
    headers:{
        email: email,
       // name:name,
     }
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.message) {
       
          props.showAlert("Email Has been sent Successfully","success")
          // setTimeout(() => {
          //            navigate("/ChangePassword?email="+email);
                   
          //      }, 3000);
          
        }else{ props.showAlert(""+(repos.message || repos),"error");
       
      }
  
    }) 
    .catch((err) =>{props.showAlert((''+err),"error")})
  }
 
};



const backtoLogin = () =>{
  navigate("/");
}

  return (
    <div>
    
    {/* <div className='child'>
    <h3 className='reset' ><b>Reset Password</b></h3>
   <br/>
    <TextField id="standard-basic" sx={{ m: 2, width: '35ch' }} label="Enter Your Email ID" size="small" variant="outlined"  value={email} onChange={(e)=> {
     setEmail(e.target.value)
     }}/>
  <br/>
  <br/>
  <Button  variant="contained" sx={{ m: 1, width: '15ch' }} onClick={(e)=>sendOtp()}>Send OTP</Button>
  <Button variant="contained" sx={{m:1, width: '12ch' }}   style={{float:"right"}} onClick={(e)=>backtoLogin()}>Back</Button>
    </div>
     */}
     <div id="sendEmail">
	<img id="bizibeesLogo" src="bizibees.png" srcset="bizibees.png 1x, bizibees@2x.png 2x" />
		
	<div id="Enter_the_email_address_associ">
		<span>Enter the email address associated with your account<br/>and we'll send you a link to reset your password.</span>
	</div>
	{/* <div id="Email">
		<span>Email</span>
	</div> */}
	{/* <svg className="Rectangle_1">
		<rect id="Rectangle_1" rx="0" ry="0" x="0" y="0" width="480" height="76">
		</rect>
	</svg> */}
	<svg className="Rectangle_2_l">
		<linearGradient id="Rectangle_2_l" spreadMethod="pad" x1="0.5" x2="-0.344" y1="1" y2="-2.147">
			<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
			<stop offset="0.4307" stop-color="#e9e9e9" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#808080" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#000" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="Rectangle_2_l" rx="3" ry="3" x="0" y="0" width="480" height="45">
		</rect>
	</svg>
	<div id="Dont_have_an_account_Sign_up">
		<span>Don't have an account? </span><a  href='/apply' style={{color:"blue"}}>Sign Up</a>
	</div>
	<div id="Continue">
  <Button  variant="transparent" sx={{m:0,height:'4ch' , width: '48ch',fontSize:"large" }} onClick={(e)=>sendOtp()}>Continue</Button>
	</div>
	<div id="Back_Btn">
  <Button  variant="transparent" sx={{m:0,height:'4ch' , width: '10ch',fontSize:"large" }} startIcon={<ReplyIcon style={{fontSize:"large"}}/>} onClick={(e)=>backtoLogin()}>Back</Button>
	</div>
	<div id="Enter_email_address">
  <TextField id="standard-basic-email" sx={{ m: 0, width: '44ch',border:"2px solid" }} label="Enter Your Email / Mobile" size="small" variant="outlined"  value={email} onChange={(e)=> {
     setEmail(e.target.value)
     }}/>
	</div>
	{/* <svg className="Line_1" viewBox="0 0 174 1">
		<path id="Line_1" d="M 0 0 L 174 0">
		</path>
	</svg> */}
	<svg className="EmailPath_1" viewBox="1106.719 150.94 260.616 160.165">
		<path id="EmailPath_1" d="M 1106.718994140625 245.1532287597656 L 1109.500366210938 311.1045532226562 L 1367.334716796875 220.0757598876953 L 1367.334716796875 150.9395446777344 L 1106.718994140625 245.1532287597656 Z">
		</path>
	</svg>
	<svg className="Path_2" viewBox="1230.339 -8.122 137.074 83.79">
		<path id="Path_2" d="M 1230.338989257812 75.66712951660156 L 1230.338989257812 40.45851898193359 L 1354.740966796875 -8.12246036529541 L 1367.138427734375 -0.8549958467483521 L 1367.4130859375 26.84274482727051 L 1230.338989257812 75.66712951660156 Z">
		</path>
	</svg>
	<svg className="Path_3" viewBox="1083.29 150.902 284.045 160.202">
		<path id="Path_3" d="M 1367.334838867188 251.9999847412109 L 1341.938598632812 311.1045532226562 L 1083.289916992188 220.0543212890625 L 1083.289916992188 150.9024963378906 L 1367.334838867188 251.9999847412109 Z">
		</path>
	</svg>
	<svg className="Path_4" viewBox="1217.914 -8.93 149.499 84.597">
		<path id="Path_4" d="M 1356.298217773438 75.66712188720703 L 1367.4130859375 42.74979019165039 L 1231.896240234375 -8.12246036529541 C 1231.896240234375 -8.12246036529541 1222.323486328125 -9.939326286315918 1219.22412109375 -8.12246036529541 C 1216.124755859375 -6.305594444274902 1219.498779296875 -0.8549967408180237 1219.498779296875 -0.8549967408180237 L 1219.22412109375 26.84273910522461 L 1356.298217773438 75.66712188720703 Z">
		</path>
	</svg>
	<svg className="Path_5" viewBox="1277.994 95.944 95.817 59.109">
		<path id="Path_5" d="M 1277.994018554688 127.1390762329102 L 1289.892700195312 155.0538787841797 L 1373.810791015625 123.9319610595703 L 1365.97314453125 95.94441986083984 L 1277.994018554688 127.1390762329102 Z">
		</path>
	</svg>
	<svg className="Path_6" viewBox="1192.394 155.054 128.795 92.469">
		<path id="Path_6" d="M 1308.87451171875 155.0538787841797 L 1321.189331054688 185.7214813232422 L 1192.394165039062 247.5224609375 L 1192.394165039062 211.8781890869141 L 1308.87451171875 155.0538787841797 Z">
		</path>
	</svg>
	<svg className="Path_7" viewBox="0 102.6 214.177 131.242">
		<path id="Path_7" d="M 0 102.5998382568359 L 214.1771545410156 178.6947174072266 L 214.1771545410156 233.8421325683594 L 0 155.1822509765625 L 0 102.5998382568359 Z">
		</path>
	</svg>
	<svg className="Path_8" viewBox="142.621 206.739 155.26 98.368">
		<path id="Path_8" d="M 142.6212158203125 206.7387390136719 L 142.6212158203125 254.5331878662109 L 297.8816223144531 305.1063842773438 L 297.8816223144531 263.9809265136719 L 142.6212158203125 206.7387390136719 Z">
		</path>
	</svg>
	<div id="Reset_Password">
		<span>Reset Password</span>
	</div>
</div>




    </div>
  )
}

export default EmailSendUI