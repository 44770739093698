import React, { useEffect, useState, useContext} from 'react';
import AddJobVacancyPopUpUI from './AddJobVacancyPopUpUI';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import './RecruitmentVacancyPageUI.css';
import JdKsPopUpUI from './JdKsPopUpUI';
import JdKsPopUpMobUI from './MobUI/JdKsPopUpMobUI';
import ShareJDPopUpUI from './ShareJDPopUpUI';
import RecruiterVacancyTopBar from './PC/RecruiterVacancyTopBar';

const RecruitmentVacancyPageUI = (props) => {
const [vacOpen,setVacOpen]= useState(false);
const { user, setUser} = useContext(UserContext);
const [vacancies,setVacancies]= useState([]);
const [vacancyDetails,setVacancyDetails]= useState("");
const [vacancyData,setVacancyData]= useState("");

const [openShareJD,setOpenShareJD] = useState(false)
const [openJdKs,setOpenJdKs] = useState(false);
const [jobsDataProps,setJobsDataProps] = useState([]);

    const handleOpen = ()=>{
        setVacOpen(true)
    }


useEffect(()=>{
if(user){
	getRecruiterVacancies()
}
},[user])


	const getRecruiterVacancies = () =>{
		let apiURL = process.env.REACT_APP_API_URL + "/recruiters/getrecruitervacancies/"+user._id;
		let apiParams = {
		  method: "GET",
		  headers:{
			authtoken: localStorage.getItem("loggedintoken")
		  }
		};
		fetch(apiURL, apiParams)
			.then((res)=> (res.status===200 ? res.json() : res.text()))
			.then((repos)=> {
				if (repos.data) {
				  // console.log(repos.data);
				  if(repos.data.length>0)
				  {
				  setVacancies(repos.data) 
				}
			}else props.showAlert(""+(repos.message || repos),"error");  
			  }) 
			  .catch((err) => props.showAlert((''+err),"error"));
			}


			const handleOpenJd =(vac)=>{
				setVacancyDetails(vac)
				setOpenJdKs(true)

			}



			
//Disable Job
 const updateIsActive = (job) =>{
	let confirmation=window.confirm("Are you sure ?")
	if(!confirmation){
	  return
	}
	let apiURL=process.env.REACT_APP_API_URL + "/recruiters/updateisactive/" +job._id;
	let apiParams = {
	  method:"POST",
	  headers:{
		active:false,
		authtoken: localStorage.getItem("loggedintoken")
	  },
   };
  
   fetch(apiURL, apiParams)
   .then((res)=> (res.status===200 ? res.json() : res.text()))
   .then((repos)=> {
	   if (repos.data) {
		// alert("Job Removed Successfully");
		props.showAlert("Job Removed Successfully","success")
		setTimeout(() => {
			getRecruiterVacancies()
		}, 2000);
		 
	   }else props.showAlert(""+(repos.message || repos),"error");
   }) 
   .catch((err) => props.showAlert((''+err),"error"));
   
  }

  const handleOpenEdit = (vac) =>{
	setJobsDataProps(vac)
	setVacOpen(true)

  } 

  const handleShare = (vac) => {
	setVacancyData(vac)
	setOpenShareJD(true)
	
  }


const handleViewJobData = (vac) =>{
	window.location.href="/jobvacancydetails?jobid="+vac._id;
}

  



  return (
    <div >

{(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))? "":<RecruiterVacancyTopBar />}

<div id="Company_wise_pc">
	<div id="top_grp_overall">
		<svg class="vacancy_bdr_">
			<rect id="vacancy_bdr_" rx="15" ry="15" x="0" y="0" width="90%" height="152">
			</rect>
		</svg>
		<img id="vaccine_page_img" src="vacancy_page_img_rec.png" srcset="vacancy_page_img_rec.png 1x" />
			
		<div id="Find_Right_Candidates_bl">
			<span>Find Right Candidates</span>
		</div>
		<div id="vacancy_btn_grp" onClick={()=>handleOpen()}>
			<svg class="lbl_vac_img_btn_cx">
				<linearGradient id="lbl_vac_img_btn_cx" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
					<stop offset="0" stop-color="#ffff" stop-opacity="1"></stop>
					<stop offset="1" stop-color="#e9e9e9" stop-opacity="2"></stop>
				</linearGradient>
				<rect id="lbl_vac_img_btn_cx" rx="8" ry="8" x="0" y="0" width="137" height="46">
				</rect>
			</svg>
			<img id="add_vac_img" src="add_vac_img_rec.png" srcset="add_vac_img_rec.png 1x" />
				
			<div id="Vacancy_lbl">
				<span>Vacancy</span>
			</div>
		</div>
		<div id="Find_Right_Candidates_portal_restriction">
			<span>*This portal is strictly for free Recruitment. Asking any amount from candidate is not allowed.</span>
		</div>
	</div>
	
	<div style={{display:"block",width:"90%",textAlign:"center"}}>
	{vacancies.map((vac)=>(
		<div style={{display:"inline-block",padding:"5px",verticalAlign:"top",height:"100%"}}>
	<div id="overall_card_grp">
		<svg class="caard_bg_lbl_vac">
			<rect id="caard_bg_lbl_vac" rx="23" ry="23" x="0" y="0" width="349" height="276">
			</rect>
		</svg>
		<div id="Grassroots_BPO_lbl_vac">
			<span>{vac.jobTitle?vac.jobTitle:""}</span>
		</div>
		<div id="Diamond_District_lbl_vac">
			<span>{vac.officeName?vac.officeName:""}</span>
		</div>
		<div id="Office_Name_lbl_vac">
			<span>Office Name</span>
		</div>
		<div id="company_name_lbl">
			<span>Company</span>
		</div>
		<div id="Customer_Care_Executive_lbl">
			<span>{vac.companyName?vac.companyName:""}</span>
		</div>
		<div id="lbl_vac_exp">
			<span>Experience</span>
		</div>
		<div id="exp_yr_llbl_vac">
			<span> {((vac.minExpMonths - (vac.minExpMonths%12))/12)} Yr {vac.minExpMonths%12} Mn to {((vac.maxExpMonths - ( vac.maxExpMonths%12))/12)} Yr {vac.maxExpMonths%12} Mn</span>
		</div>
		<div id="Salary_Range_lbl_vac">
			<span>Salary Range</span>
		</div>
		<div id="n_2000_lbl">
			<span>{vac.minCTC} to {vac.maxCTC} INR</span>
		</div>
		<button id="share_grp_btn" onClick={()=>handleShare(vac)}>
			<svg class="image_bdr_bb">
				<ellipse id="image_bdr_bb" rx="20" ry="20" cx="20" cy="20">
				</ellipse>
			</svg>
			<img id="share_img_bc" src="share_img_rec.png" srcset="share_img_rec.png 1x" alt='reload'/>
				
		</button>
		<div id="view_grp" onClick={()=>handleViewJobData(vac)}>
			<svg class="bdr_eye">
				<rect id="bdr_eye" rx="9" ry="9" x="0" y="0" width="69" height="39">
				</rect>
			</svg>
			<img id="eye_img" src="eye_img_rec.png" srcset="eye_img_rec.png 1x" alt='reload'/>
				
			<div id="n_22_bk">
				<span>222</span>
			</div>
		</div>
		<div id="edit_grp" onClick={()=>handleOpenEdit(vac)}>
			<svg class="bdr_">
				<rect id="bdr_" rx="9" ry="9" x="0" y="0" width="58" height="39">
				</rect>
			</svg>
			<img id="edit3_img" src="edit3_img_rec.png" srcset="edit3_img_rec.png 1x" alt='reload'/>
				
		</div>
		<div id="del_grp" onClick={() => updateIsActive(vac)}>
			<svg class="del_bdr">
				<rect id="del_bdr" rx="9" ry="9" x="0" y="0" width="50" height="31">
				</rect>
			</svg>
			<img id="delete_img" src="delete_img_rec.png" srcset="delete_img_rec.png 1x" alt='reload'/>
		</div>
		<div id="view_more_JD" onClick={()=>handleOpenJd(vac)}>
			<svg class="jdks_bdr">
				<rect id="jdks_bdr" rx="9" ry="9" x="0" y="0" width="59" height="39">
				</rect>
			</svg>
			<div id="JDKS_lbl">
				<span>JD/KS</span>
			</div>
		</div>
		<svg class="margine_line" viewBox="0 0 295 1">
			<path id="margine_line" d="M 0 0 L 295 0">
			</path>
		</svg>
		<div id="status_lbl_com_rec">
			<span>Status</span>
		</div>
		<div id="approved_lbl_rec">
			<span>{vac.status && (vac.status).substring(0, 1).toUpperCase() + (vac.status).substring(1)}</span>
		</div>
	</div>

	</div>
	))}
	
	</div>
</div>
 
<JdKsPopUpUI  openJdKs={openJdKs} vacancy={vacancyDetails} showAlert={props.showAlert} closeJdKs={()=>setOpenJdKs(false)}/>
<AddJobVacancyPopUpUI type={jobsDataProps._id?"EditRecVac":"AddRecVac"}  jobsData={jobsDataProps}  openJobs={vacOpen} showAlert={props.showAlert} close={()=>{setVacOpen(false);setJobsDataProps([]);getRecruiterVacancies()}}/>
<ShareJDPopUpUI openShareJD={openShareJD} vacancy={vacancyData} type="Recruiter" showAlert={props.showAlert} closeShareJD={()=>setOpenShareJD(false)}/>
    </div>
  )
}

export default RecruitmentVacancyPageUI
