import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import "../Styles/AdminActivityData.css";
import { UserContext } from '../UserContext';

const AdminActivityData = (props) => {

  const { user, setUser} = useContext(UserContext);
  const alertStart = useRef(new Date());
  const [rowData,setRowData] = useState([])

useEffect(()=>{
if(props.rowData){
 
  setRowData(props.rowData)
}

},[props.rowData])

useEffect(()=>{

},[props.showOnlyHR,props.users,props.fromDate,props.toDate])


 //View Data 
 const viewData = (row) =>{

  window.location.href= "/interviewdetails?selectedCell=" + row;
  }


  return (

    <div id="BottmPartAdminactivity">
       <div id="BottmPartAdminGroup_36">
        <div id="BottmPartAdminInterview" onClick={()=>props.selectedTab("interviews")}>
          <span>Interviews</span>
        </div>
        <div id="BottmPartAdminVerification" onClick={()=>props.selectedTab("verifications")}>
          <span>Verifications</span>
        </div>
        <div id="BottmPartAdminothers" onClick={()=>props.selectedTab("duration")}>
          <span>Duration</span>
        </div>
        <div id="BottmPartAdminott" onClick={()=>props.selectedTab("others")}>
          <span>Others</span>
        </div>
        <div id="BottmPartAdminGroup_35">
          <div id="BottmPartAdminActivity_w">
            <span>Activity</span>
          </div>
          <svg class="BottmPartAdminLine_1" viewBox="0 0 1 48">
            <path id="BottmPartAdminLine_1" d="M 0 0 L 0 48">
            </path>
          </svg>
        </div>
      </div>

  
    <div id="BottmPartAdminGroup_39">
        <svg class="BottmPartAdminRectangle_46">
          <rect id="BottmPartAdminRectangle_46" rx="24" ry="24" x="0" y="0" width="100%" height="100%">
          </rect>
        </svg>
        {rowData.map((row)=>(

        <div id="BottmPartAdminGroup_37">
          <div id="BottmPartAdminRoobi">
            <span>{row.name}</span>
          </div>

          <div id="BottmPartAdminn_037" style={{backgroundColor:(row.isCallingActive === true && row.openTime>300)?"red":"white",color:(row.isCallingActive === true && row.openTime>300)?"white":(row.isCallingActive === true && row.openTime>210)?"red":"green",}}>
            <span> {String(parseInt(row.openTime/60/60)).padStart(2, '0')}:{String(parseInt((row.openTime/60)%60)).padStart(2, '0')}:{String(row.openTime%60).padStart(2, '0')}</span>
          </div>
          <div id="BottmPartAdminn_510545" onClick={()=>(user.accesstype == 5 || row.name=== user.name) && viewData((["pending",user._id , row.name,props.fromDate,props.toDate ]))}>
            <span>{row.pending}</span>
          </div>
          <div id="BottmPartAdminMohammed" style={{backgroundColor:row.isCallingActive===false?"red":"white",color:row.isCallingActive!==true?"white":row.currentCandidate?"black":"red"}}>
            <span>{row.isCallingActive===false?<marquee>{row.userStatus}</marquee>:
            user.name ===row.name||user.accesstype == 5?
            user.name ===row.name && user.accesstype == 5?
           <button style={{border:"1px solid", padding:"2px 6px"}} onClick={()=>{props.showCandidate("new",row.name)}} >{"Verify Candidate"}</button>:
          !row.currentCandidate && user.accesstype == 5 ?"Idle":
          <button style={{border:"1px solid", padding:"2px 6px"}} onClick={()=>{ alertStart.current = new Date();setTimeout(()=>props.checkIdle(),300000);
            props.showCandidate(row.currentCandidate?row.interviewid:"new",row.name)}} >{row.currentCandidate?row.currentCandidate:"Call Candidate"}</button>:
         row.currentCandidate?row.currentCandidate:""
         }</span>
          </div>
          <img id="BottmPartAdminspeaker-filled-audio-tool" src="speaker-filled-audio-tool.png" srcset="speaker-filled-audio-tool.png 1x" />
            
        </div>
      ))} 
        <div id="BottmPartAdminGroup_38">
          <div id="BottmPartAdminStatus">
            <span>Status</span>
          </div>
          <div id="BottmPartAdminTime">
            <span>Time</span>
          </div>
          <div id="BottmPartAdminQueue">
            <span>Queue</span>
          </div>
          <svg class="BottmPartAdminLine_2" viewBox="0 0 225.87 1">
            <path id="BottmPartAdminLine_2" d="M 0 0 L 225.86962890625 0">
            </path>
          </svg>
          <div id="BottmPartAdminn_80031258">
            <span>{(props.showOnlyHR?rowData.filter(x=>props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.pending.split('/')[0]),0)+'/'+(props.showOnlyHR?rowData.filter(x=> props.users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.pending.split('/')[1]),0)}</span>
          </div>
        </div>
      </div>
  
  </div>
  )
}

export default AdminActivityData
