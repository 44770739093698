import React , { useState,useEffect, useContext } from 'react'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import './LoginUIMobile.css'
import {Link, useNavigate,useLocation} from "react-router-dom";
import { UserContext } from './UserContext';
import CryptoJS from 'crypto-js';

const LoginMobileUI = (props) => {
var navigate = useNavigate();
const [userName, setUserName] = useState ("");
const [password, setPassword] = useState ("");
const { user, setUser} = useContext(UserContext);

const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const encryptedEmail = decodeURIComponent(queryParams.get("email"));
  const encryptedPassword = decodeURIComponent(queryParams.get("password"));


  const fetchData = async (encryptedEmail, encryptedPassword) => {
    let decryptedEmailString = await CryptoJS.AES.decrypt(
      encryptedEmail.toString(),
      "Bizi Bees"
    ).toString(CryptoJS.enc.Utf8);
    let decryptPasswordString = await CryptoJS.AES.decrypt(
      encryptedPassword.toString(),
      "Bizi Bees"
    ).toString(CryptoJS.enc.Utf8);
    const decodeEmail = await decryptedEmailString.replace(/['"]+/g, "");
    const decodePassword = await decryptPasswordString.replace(/['"]+/g, "");

    const apiUrl = process.env.REACT_APP_API_URL + "/users/login";
    var apiParam = {
      method: 'POST',
      headers: { email: decodeEmail, password: encodeURIComponent(encrypt(decodePassword)) },
      credentials: 'same-origin'
    };
    fetch(apiUrl, apiParam)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          localStorage.setItem("loggedintoken", repos.data);
          localStorage.setItem("loggedInUser", JSON.stringify({...JSON.parse(atob(repos.data.split(".")[1])),device:"laptop"}));

         
          setUser({...JSON.parse(atob(repos.data.split(".")[1])),device:"laptop"})
          if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="c")
          {
            navigate("/vacancies")
          } else if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="candidate"){
            navigate("/apply")
          } else if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="recruiter"){
            navigate("/recruiterRegistration")
          }else if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="r"){
            navigate("/recruitervacancies")
          }else{
            navigate("/")
          }
          

        } else console.log("error",repos);
      })
      .catch((err) => console.log("error",err));
  }

useEffect(() => {
  if (encryptedEmail && encryptedPassword) {
    fetchData(encryptedEmail, encryptedPassword);
  }
}, []);


useEffect(()=>{
  if(!user || !user.accesstype){
  if(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))){
    window.location.href = "https://jobs.bizibees.in"
  }}
},[])

      
 function encrypt(plainText) {
  return CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
}
    const redirect = async () =>{
        const apiUrl = process.env.REACT_APP_API_URL + "/users/login";
        var apiParam = {
          method: 'POST',
          headers: { email: userName, password: encodeURIComponent(encrypt(password)) },
          credentials: 'same-origin'
        };
        fetch(apiUrl, apiParam)
          .then((res) => (res.status === 200 ? res.json() : res.text()))
          .then((repos) => {
            if (repos.data) {


              localStorage.setItem("loggedintoken", repos.data);
              localStorage.setItem("loggedInUser", JSON.stringify({...JSON.parse(atob(repos.data.split(".")[1])),device:"mobile"}));
              //setUser(JSON.parse(atob(repos.data.split(".")[1])))
              setUser({...JSON.parse(atob(repos.data.split(".")[1])),device:"mobile"})
              if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="c")
              {
                navigate("/vacancies")
              } else if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="candidate"){
                navigate("/apply")
              } else if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="recruiter"){
                navigate("/recruiterRegistration")
              }else if(JSON.parse(atob(repos.data.split(".")[1])).accesstype==="r"){
                navigate("/recruitervacancies")
              }else{
                navigate("/")
              }

            } else props.showAlert(('1'+repos),"error");
          })
          .catch((err) => props.showAlert(('2'+err),"error"));
      };  

     
    

  return ( 
    <div>
  <div id="BizibeesMobileLogin">

	 {/* <svg class="LoginMobileOuterBox">
		<rect id="LoginMobileOuterBox" rx="27" ry="27" x="0" y="0" width="491" height="543">
		</rect>
	</svg>   */}

		{/* <div id="LOGIN" tabindex="3" onClick={()=>redirect()}>
		<span>LOGIN</span>
	</div> */}

  <div className='LoginMobileButton'>
  <Button variant="contained"   sx={{ m: 1, width: '36ch',backgroundColor:"rgba(90,98,236,1)" }}   onClick={(e)=>redirect()}>Login</Button>
  </div>
 
  <div>
	{/* <div id="Password">
		<span>Password</span>
	</div> */}
    <TextField className="LoginMobilePassword" id="LoginMobilePassword" inputProps={{ tabIndex: "2" }} d="M 0 0 L 313.9754333496094 0" type="password"  label="Password" size="small" value={password} onChange={(e)=> {
     setPassword(e.target.value)
     }}/>
  </div>
	{/* <svg class="Line_4" viewBox="0 0 313.975 1">
		<path id="Line_4" d="M 0 0 L 313.9754333496094 0">
		</path>
	</svg> */}
 <div>
	{/* <div id="Email_Address">
		<span>Email Address</span>
	</div> */}
  <TextField className="LoginMobileUserName" inputProps={{ tabIndex: "1" }} d="M 0 0 L 313.9754333496094 0" label="Email"  size="small" value={userName} onChange={(e)=> {
     setUserName(e.target.value)
     }}/></div>

	{/* <svg class="Line_5" viewBox="0 0 313.975 1">
		<path id="Line_5" d="M 0 0 L 313.9754333496094 0">
		</path>
	</svg> */}
  
	{/* <div id="Please_login_to_your_account_bizibees">
		<span>Please login to your account.</span>
	</div> */}
	<div id="REGISTER_Mobile_BiziBees">
		<a  href='/apply'>REGISTER NOW</a>
	</div>
	<div id="FORGET_PASSWORD_Mobile_BiziBees">
		<a  href='/forgotpassword'>Forgot Password ?</a>
	</div>
	<img id="bizibeesMobile" src="bizibees.png" srcset="bizibees.png 1x, bizibees@2x.png 2x"/>
		
	<img id="MobileLoginPic" src="kisspng-recruiter-human-resour.png" />
		
	
</div>
   </div>
  )
}

export default LoginMobileUI