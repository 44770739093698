import { wait } from "@testing-library/user-event/dist/utils";

export const DevicePermissions = async (props) => {
    var camAllowed = false;
    var micAllowed = false;
    
       await props
        .query({ name: "camera" })
        .then((permissionStatus) => {
          camAllowed = permissionStatus.state==="granted";
          permissionStatus.onchange = () => {
            camAllowed = permissionStatus.state==="granted";
          };
        });
        
       await props
        .query({ name: "microphone" })
        .then((permissionStatus) => {
          micAllowed = permissionStatus.state==="granted";
          permissionStatus.onchange = () => {
            micAllowed = permissionStatus.state==="granted";
          };
        });
     

    return ({micAllowed,camAllowed});
   }