import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { UserContext } from './UserContext';
import TimePicker from '@mui/lab/TimePicker';
import { useSearchParams } from 'react-router-dom';
import BulkDataUpload from './BulkDataUpload';
import Checkbox from '@mui/material/Checkbox';
import Timer from './Timer';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ApplicantPopup from './ApplicantPopup';

// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';

const InterviewDetails = (props) => {
  
const [searchParams] = useSearchParams();
const [interview, setInterview] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [name, setName] = useState();
const [mail, setMail] = useState();
const [mobile, setMobile] = useState();
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState();
const [applicantId,setApplicantId]= useState()
const [companyName, setCompanyName] = useState();
const [open, setOpen] = React.useState(false);
const [Rounds, setRounds] = useState();
const [InterViewerName, setInterViewerName]= useState("");
const [InterViewDate, setInterViewDate] = React.useState(new Date());
const [InterViewTime, setInterViewTime] = React.useState();
const [interviewlocation, setInterviewlocation] = useState();
const [selectedRow,setSelectedRow] = useState();
const [isLoading, setIsLoading] = useState(false);
const { user, setUser} = useContext(UserContext);

const [pageState,setPageState] = useState({
  isLoading:false,
  data:[],
  total:0,
  page:1,
  pageSize:100
})

  const rowData = (interview||[])?.map((interview) => {
    let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);

    return{
  id: interview?._id,  //applicant id
  applicantid:interview?._id, 
  latestinterviewid:interview?.latestInterviewId,
  city:interview?.city, 
  name: interview?.name,
  mobile: interview?.mobile,
  area:interview?.area,
  address:interview?.address,
  education:interview?.education,
  preferences:interview?.preferences,
  hrname:(interview.latestHrName && interview.latestHrName !== "undefined")?interview.latestHrName:"",
  reference:interview?.reference,
  // interviewtime:IntvwTime,
  languages:interview?.languages,
  latestinterviewround:interview?.latestInterviewRound,
  // reference:interview?.reference,
  status:interview?.latestInterviewStatus,
  
  // englishrating:interview?parseInt(interview.englishRating):"",
  // communicationrating:interview?parseInt(interview.communicationRating):"",
  updatedat:(new Date(interview?.updatedAt)).toLocaleString(),
  
    };
  });
  

const [filterModel, setFilterModel] = React.useState({
  items: user.accesstype == 5 ? [
    // {columnField: 'rounds', operatorValue: 'contains', value:"Verification"}
  ]: [
    // {columnField: 'hrname', operatorValue: 'contains', value:user.name}
  ],
});

const style3 = {
  position: 'absolute',
  top: '50%',

  transform: 'translate(87%, -50%)',
  height: 489,
  width: 533,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 10,

};

const cols = [
//   {field:'action', headerName: '',width: 80, headerAlign:'center', hide: user.accesstype== 5 ?false:true,

//   renderCell: (params) => {
//    return[
//       <div>
//        <Button size='small' title="Schedule"  onClick={()=>showApplicant(params.row)}><PersonSearchIcon/></Button>
      
// </div>
//    ];
//   },
// },  
  {field:'id', headerName: '',width: 190,hide: true },
  {field:'name', headerName: 'Name',width: 190 },
  {field:'createdbyname', headerName: 'Created By',width: 190, hide: true },
  {field:'mobile', headerName: 'Mobile No',width: 140, headerAlign:'center', align:"center"},
  {field:'hrname', headerName: 'HR Name',width: 190, headerAlign:'center', align:"center"},
 
//   {field:'englishrating', headerName: 'English Rating',width: 180,
//   renderCell: (params) =>{
//     switch(params.row.englishrating){

//      case "1":
//      return  ["E - Pathetic"]
    
//      case "2":
//        return ["D - Bad"]
    
//      case "3":
//        return  ["C - Average"]
   
//      case "4":
//        return  ["B - Good"]
    
//      case "5":
//        return ["A - Excellent"]
      
//      default :
//      return  [""]
    
//     }
//  },
// },
//   {field:'communicationrating', headerName: 'Communication Rating',width: 180,renderCell: (params) =>{
//     switch(params.row.communicationrating){
//       case "1":
//         return  ["E - Pathetic"]
       
//         case "2":
//           return ["D - Bad"]
       
//         case "3":
//           return  ["C - Average"]
      
//         case "4":
//           return  ["B - Good"]
       
//         case "5":
//           return ["A - Excellent"]   
      
//      default :
//      return  [""]
    
//     }
//  },
// },
  // {field:'jobtitle', headerName: 'Job Title',width: 200},
  // {field:'companyname', headerName: 'Company Name',width: 200}, 
 
  {field:'latestInterviewRound', headerName:'Rounds',width: 150, headerAlign:'center', align:"center"},
  
  
  {field:'status', headerName: 'Status',width: 110, headerAlign:'center', align:"center"},
  {field:'updatedat', headerName:'Last Updated At',width: 250, headerAlign:'center', align:"center"},
  {field:'whatsappsend', headerName:'WhatsApp Resend',width: 150, headerAlign:'center', align:"center",
  renderCell: (params) => {
        return[
           <div>
            <Button size='small' title="Resend"  onClick={()=>ResendWhatsapp(params.row)}><WhatsAppIcon/></Button>  
           </div>
        ];
       },
      },
 
 
  ];

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const getMessagedDetails = ()=>{
  
    let apiURL="";
    setPageState(old=>({...old,isLoading:true}))
    apiURL=process.env.REACT_APP_API_URL + "/interview/messaged/"+user.name+"/"+user._id+`?page=${pageState.page}&limit=${pageState.pageSize}`;
    let apiParams = {
        method:"GET", 
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        }
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          console.log(repos.data)
          setInterview(repos.data.messaged)
          setPageState(old=>({...old, isLoading:false,data:repos.data.interview, total :repos.data.pagination.count }))
        }else props.showAlert(""+(repos.message || repos),"error");  
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  
  useEffect (()=>{
 
   
    getMessagedDetails()
  
    
  },[pageState.page,pageState.pageSize])


const ResendWhatsapp = (row)=>{

let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+row.mobile.trim().replace(' ','').substring(row.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+row.name+",%0a%0aI am contacting you for a job vacancy. Please let me know the best time to call you.%0a%0aMy name is "+user.name+", I am from Bizi Bees Outsourcing Private Limited. You can call me on "+user.phone+" as well at your convenient time to discuss about the vacancy.%0a%0aPlease follow us on our LinkedIn page url below for latest updates.%0a%0ahttps://www.linkedin.com/company/bizi-bees-outsourcing";
const newWindow = window.open(apiURL, '_blank')
}
  


  
  const scheduleinterview = (row) =>{

    setSelectedRow(row)
      // setName(row.name)
      // setJobId(row.jobid)
      // setMobile(row.mobile)
      // setJobTitle(row.jobtitle)
      // setApplicantId(row.id)
      // setMail(row.mail)
      // setCompanyName(row.companyname)
        setOpenScedule(true);
      //  alert(JSON.stringify(row))
      }
    
 

  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }



  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
   
    <div style={{height:'100%',width: '100%'}}>
     {(!searchParams.get('selectedCell')) ?
     <DataGrid rows={rowData}
       rowCount = {pageState.total}
          loading = {pageState.isLoading}
          // rowsPerPageOptions={[30,50,80]}
          pagination
          page={pageState.page - 1}
          pageSize={pageState.pageSize}
          paginationMode="server"
          onPageChange={(newPage)=>{
          setPageState(old=>({...old,page: newPage +1 }))
          }}
          onPageSizeChange = {(newPageSize)=>setPageState(old=>({...old, pageSize:newPageSize}))}

       columns={cols}  
    
       components={{
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
         onRowDoubleClick={(e)=>scheduleinterview(e.row)}
          />:  <DataGrid rows={rowData}
                        columns={cols}  
                        checkboxSelection
               components={{
                  Toolbar: GridToolbar,
                  }}
              filterModel={filterModel}
              onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
               onRowDoubleClick={(e)=>scheduleinterview(e.row)}
           />}

 </div>
 {/* <ScheduleInterview open={OpenShedule} showAlert={props.showAlert}  interview={selectedRow}  close={()=>setOpenScedule(false)} /> */}
<ScheduleInterviewUI openMsg={OpenShedule} showAlert={props.showAlert}  interviewMsg={selectedRow}  closeMsg={()=>{setOpenScedule(false);getMessagedDetails()}}  />
</div>
  ) 
}

export default InterviewDetails







