import React, {useEffect, useState} from 'react'
import '../Styles/MonitorPageMob.css'

import SentEmailsToCandidates from '../SentEmailsToCandidates';
import RecruiterOfficeLocations from '../RecruiterOfficeLocations';
import RecruiterVacanciesVerifyUI from '../RecruiterVacanciesVerifyUI';
import WebsiteEnquiries from '../WebsiteEnquiries';
import Registrations from '../Registrations';
import Devices from '../Devices';
import FAQ from '../FAQ';

const MonitorPageMob = (props) => {

const [selectedMonitorTab,setSelectedMonitorTab] = useState("Enquiries");


  return (
	<div id="Monitor_Mob_bizibees_mob_admin">
	<div id="Monitor_Mob_screen_and_menu_grp_BBL">
		<div id="Monitor_Mob_discplay_grp_BBl">
			<svg class="Monitor_Mob_display_BBL">
				<rect id="Monitor_Mob_display_BBL" rx="6" ry="6" x="0" y="0" width="100%" height="100%">
				</rect>
			</svg>
			{selectedMonitorTab=== "Enquiries"?<WebsiteEnquiries showAlert={props.showAlert}/>:""}
		{selectedMonitorTab=== "Registrations"?<Registrations showAlert={props.showAlert}/>:""}
		{selectedMonitorTab=== "FAQs"?<FAQ showAlert={props.showAlert}/>:""}
		{selectedMonitorTab=== "Devices"?<Devices showAlert={props.showAlert}/>:""}
		</div>

 <table id="Monitor_Mob_ELOUUD_BBL">
	<tr style={{padding:0}}>
		<td class={selectedMonitorTab=== "Enquiries"?'selectedMonitorTab_mob':'monitortabs_mob'} onClick={()=>{setSelectedMonitorTab("Enquiries");}}>
		<div >Enquiries</div>
		</td>
	</tr>
	<tr>
		<td class={selectedMonitorTab=== "Registrations"?'selectedMonitorTab_mob':'monitortabs_mob'} onClick={()=>{setSelectedMonitorTab("Registrations");}}>
		<div >Registrations</div>
		</td>
	</tr>
	<tr>
		<td class={selectedMonitorTab=== "FAQs"?'selectedMonitorTab_mob':'monitortabs_mob'} onClick={()=>{setSelectedMonitorTab("FAQs");}}>
		<div >FAQs</div>
		</td>
	</tr>

	<tr>
		<td class={selectedMonitorTab=== "Devices"?'selectedMonitorTab_mob':'monitortabs_mob'} onClick={()=>{setSelectedMonitorTab("Devices");}}>
		<div >Devices</div>
		</td>
	</tr>
	{/* <tr>
		<td class={selectedInterviewTab=== "Eligible"?'selectedRecruiterTab_pc':'recruitertabs_pc'} onClick={()=>{setSelectedInterviewTab("Eligible");}}>
		<div >Eligible</div>
		</td>
	</tr> */}
	{/* <tr>
		<td class={selectedInterviewTab=== "Invoiced"?'selectedRecruiterTab_pc':'recruitertabs_pc'} onClick={()=>{setSelectedInterviewTab("Invoiced");}}>
		<div >Invoiced</div>
		</td>
	</tr> */}
	
</table> 


	</div>
		
</div>
  )
}

export default MonitorPageMob
