import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './Users.css'
import SearchIcon from '@mui/icons-material/Search';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import GppGoodIcon from '@mui/icons-material/GppGood';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Update } from '@mui/icons-material';
import EmailTemplatesPopup from './EmailTemplatesPopup';


const SentEmailsToCandidates = (props) => {

  const style6 = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 500,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

  const label = { inputProps: { 'aria-label': 'Schedule Interview' } };

  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("");
  const [open, setOpen] = React.useState(false);
  const [sentEmails, setSentEmails] = useState([]);
  const [isLoading,setIsLoading]=useState(false);
  const [reportingToId,setReportingToId]= useState("");
  const [reportingToName,setReportingToName]= useState("");
  const [accessType,setAccessType] = useState("");
  const [active,setActive ]=useState(true);
  const [selectedData,setSelectedData] = useState(true);
  const [openEmailTemplate,setOpenEmailTemplate] = useState(false);
  const [emailData,setEmailData] = useState(false);
  const [fromDate, setFromDate] = useState(new Date().toISOString().substring(0,10));
  const [toDate, setToDate] = useState(new Date().toISOString().substring(0,10));
  const {user} = useContext(UserContext);
  const [interviewStatus,setInterviewStatus] = useState("test");
  const [ comments,setComments] = useState("");
  const [openComments, setOpenComments] = React.useState(false);
  const [emailStatusId,setEmailStatusId] = useState("");
  const [prevComments,setPrevComments] = useState("");
  const [searchStatus,setSearchStatus] = useState("all");

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {columnField: '', operatorValue: 'contains', value:user.name}
    ],
  });
  
  const cols = [
  {field:'id', headerName: 'id', hide: true },
  {field:'name', headerName: 'Name',width: 220,headerAlign:'center',align: 'center', hide: true  },
 
  {field:'createdAt', headerName: 'Sent On',width: 190,headerAlign:'center',align: 'center', hide: true , renderCell: (params) =>{
    return[
      new Date(params.row.createdAt).getDate()+"/"+(new Date(params.row.createdAt).getMonth()+1)+"/"+new Date(params.row.createdAt).getFullYear()+" "+new Date(params.row.createdAt).toLocaleTimeString()
    ];
  } },
  {field:'applicantname', headerName: 'Candidate Name',width: 190 ,headerAlign:'center',align: 'center'},
  {field:'mobile', headerName: 'Mobile No',width: 190 ,headerAlign:'center',align: 'center'},
  {field:'experience', headerName: 'Experience',width: 190 ,headerAlign:'center',align: 'center', renderCell: (params) =>{
    return[
      params.row.experience && parseInt(parseInt(params.row.experience)/12) + " years " + parseInt(parseInt(params.row.experience) - parseInt(parseInt(params.row.experience)/12)*12) + " months"
    ];
  } },
 {field:'currentctc', headerName: 'Current CTC',width: 190 ,headerAlign:'center',align: 'center'},
  {field:'expectedctc', headerName: 'Expected CTC',width: 190 ,headerAlign:'center',align: 'center'},
    {field:'noticeperiod', headerName: 'Notice Period',width: 190 ,headerAlign:'center',align: 'center', renderCell: (params) =>{
      return[
        params.row.noticeperiod && params.row.noticeperiod + " days"
      ];
    } },
 {field:'location', headerName: 'Location',width: 190 ,headerAlign:'center',align: 'center'},
  // {field:'from', headerName: 'From',width: 230,headerAlign:'center',align: 'center'},
  
  // {field:'to', headerName: 'To',width: 230,headerAlign:'center',align: 'center' },
  
  // {field:'cc', headerName: 'Cc',width: 230,headerAlign:'center',align: 'center'},

  // {field:'bcc', headerName: 'Bcc',width: 230,headerAlign:'center',align: 'center'},

  {field:'jobTitle', headerName: 'Job Role',width: 250,headerAlign:'center',align: 'center'},

  {field:'status', headerName: 'Interview Status',width: 300,headerAlign:'center',align: 'center',

  renderCell: (params) => {
   return[
      <div>
       <FormControl variant="outlined" sx={{width: "260px",marginTop:"2px" }} size="small"  >
        <InputLabel id="status-select"  ></InputLabel>
        <Select
          labelId="status"
          id="status-select"
          
          value={params.row.status ||"test"} onChange={(e)=> {
      
            setInterviewStatus(e.target.value); 
            updateEmailStatus(params.row,e.target.value);
            sendWhatsapp(params.row,e.target.value)  

           } } >
        
          <MenuItem value="test">Candidate to complete test</MenuItem>
          <MenuItem value="result">Awaiting test results</MenuItem>
          <MenuItem value="pass">Passed</MenuItem>
          <MenuItem value="fail">Failed</MenuItem>
        </Select>
      </FormControl>
      
</div>
   ];
  },
},  

  {field:'comments', headerName: 'Comments',width: 300,headerAlign:'center',align: 'center',

  renderCell: (params) => {
   return[
      <div style={{textAlign:"left",overflowY:"scroll",width:"100%"}} title={params.row.comments}>

     {params.row.comments && params.row.comments.split('\n').map(line => <div>{line}</div> )}
      
      </div>
   ];
  },
},  
  
  // {field:'attachments', headerName: 'Attachments',width: 230,headerAlign:'center',align: 'center', renderCell: (params) =>{
  //   return[
  //     //(params.row.attachment||[]).map(h=>h.href)
  //     // params.row.attachments
  //   ];
  // } },

];

useEffect(()=>{
  getSentEmails();

},[])

const updateEmailStatus = (row,value)=>{
if((row.status ==="pass" || row.status==="fail" || value === "pass" || value === "fail" ) && parseInt(user.accesstype)<2)
{
  return alert("You don't have access to change result, contact your Manager to update!!")
}
let apiURL =  process.env.REACT_APP_API_URL + "/users/updateemailstatus/"+row.id;
    let apiParams ={
      method:"POST",
      headers:{
       status:value,
       authtoken: localStorage.getItem("loggedintoken")
    }
    };
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=>{
      if(repos.data){
       
        setIsLoading(false)
        
        props.showAlert("Email Status Updated Successfully","success")
        setTimeout(()=>{
          LoadPreviousData()
        },2000)
      }

    })
    .catch((err) => props.showAlert((''+err),"error"));
}

const UpdateCommmentStatus = ()=>{
if(emailStatusId.length<1){
  return 
}else{
setOpenComments(false)
let apiURL =  process.env.REACT_APP_API_URL + "/users/updateemailcomments/"+emailStatusId;
    let apiParams ={
      method:"POST",
      headers:{
       comments:encodeURIComponent(" ["+new Date().toLocaleString()+"]:\n"+comments+"\n"+(prevComments||"")+"\n"),
       authtoken: localStorage.getItem("loggedintoken")
    }
    };
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=>{
      if(repos.data){
       
        setIsLoading(false)
        setComments("")
        props.showAlert("Email Comments Updated Successfully","success")
        setTimeout(()=>{
          LoadPreviousData()
        },2000)
      }

    })
    .catch((err) => props.showAlert((''+err),"error"));
}
}

const getSentEmails = ()=>{
  let apiURL=process.env.REACT_APP_API_URL + "/users/getsentemails"; 
  let apiParams = {
      method:"GET",
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {

        setSentEmails(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

// const UpdateDevice = (id,allowed)=>{
//     let apiURL =  process.env.REACT_APP_API_URL + "/users/updatedevice/"+id;
//     let apiParams ={
//       method:"POST",
//       headers:{
//         allowed
//     }
//     };
//     fetch(apiURL,apiParams)
//     .then((res)=> (res.status===200 ? res.json() : res.text()))
//     .then((repos)=>{
//       if(repos.data){
//         setOpen(false)
//         setIsLoading(false)
        
//         props.showAlert("Device Updated Successfully","success")
//         setTimeout(()=>{
//           window.location.reload(false);
        
//         },2000)
//       }

//     })
//     .catch((err) => props.showAlert((''+err),"error"));
//     }

const handleOpen = (row)=>{
setEmailData(row);
setOpenEmailTemplate(true)
}

  //Search Data By  date
  const LoadPreviousData = () =>{

    let apiParams;
      apiParams = {
        method: "GET",
        headers:{
          fromdate:fromDate,
          todate:toDate,
          searchby:searchStatus,
        }
      };
    
    let apiURL = process.env.REACT_APP_API_URL + "/users/searchsentemailbydate"
   
   fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              // console.log(repos.data) 
              setSentEmails(repos.data)
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));   
    }

const OpenCommentsModal = (row)=>{
  
  setPrevComments(row.comments)
  setEmailStatusId(row.id)
  setOpenComments(true)
}

const handleCloseComments = () =>{
  setComments("");
  setOpenComments(false);

}


const sendWhatsapp = (row,value)=>{

 if(value==="pass"){

  let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+row.mobile.trim().replace(' ','').substring(row.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Congratulations "+row.applicantname[0].toUpperCase() + row.applicantname.slice(1)+"!%0a%0aYou have cleared the "+row.subject+" for the "+row.jobTitle+" position. We are scheduling your face-to-face interview tomorrow at 5:30 pm.%0a%0aPlease confirm me your availability to attend, else suggest your convenient time."

  const newWindow = window.open(apiURL, '_blank')
 } 
}



const rowData = (sentEmails)?.map((se) => {

return{
  id:se?._id,
  createdAt:se?.createdAt,
  name:se?.userName,
  applicantname:se?.applicantName,
  from:se?.from,
  to:se?.to,
  cc:se?.cc,
  bcc:se?.bcc,
  subject:se?.subject,
  text:se?.text,
  mobile:se?.mobile,
  location:se?.location,
  noticeperiod:se?.noticePeriod,
  experience:se?.experience,
  currentctc:se?.currentCTC,
  expectedctc:se?.expectedCTC,
  jobTitle:se?.jobTitle,
  status:se?.status,
  comments:se?.comments,
  //attachments:JSON.stringify(se?.attachments),
};
});

  return (
  <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
  <div style={{textAlign:"center"}}>
 <font style={{fontSize:"20px"}}> History : </font>
  &nbsp;<input type="date" style={{height:"40px",fontSize:"18px",border:"1px solid"}} value={fromDate} 
      // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
         onChange={(value) => {
          setFromDate(value.target.value);
        }}/>&nbsp;
        <input type="date" style={{height:"40px",fontSize:"18px",border:"1px solid"}} value={toDate}
       // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
        onChange={(value) => {
          setToDate(value.target.value);
        }}/>
        &nbsp;<FormControl variant="outlined" sx={{width: "160px",marginTop:"2px" }} size="small"  >
        <InputLabel id="status-select"  ></InputLabel>
        <Select
          labelId="status"
          id="status-select"
          
          value={searchStatus} onChange={(e)=> {
      
            setSearchStatus(e.target.value); 
            

           } } >
          <MenuItem value="all">All Status</MenuItem>
          <MenuItem value="test">Candidate to complete test</MenuItem>
          <MenuItem value="result">Awaiting test results</MenuItem>
          <MenuItem value="pass">Passed</MenuItem>
          <MenuItem value="fail">Failed</MenuItem>
        </Select>
      </FormControl>&nbsp;

   <Button size='small' variant="contained" title="search" onClick={()=>LoadPreviousData()}><SearchIcon/></Button> 
   </div> 
  <br/>
      <div style={{height:"calc(100% - 60px)",width: '100%'}}>
      <DataGrid rows={rowData} columns={cols}  components={{
          Toolbar: GridToolbar,
        }}
        onSelectionModelChange ={(e) =>setSelectedData(e)}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onCellClick={(cell)=>
          (cell.field=="comments" ) ? OpenCommentsModal(cell.row) : null

          }
        onRowDoubleClick={(e)=>handleOpen(e.row)}
          />
      </div>
    {/* Modal for Updating Sent Email Comments */}
      <Modal
        open={openComments}
        onClose={handleCloseComments}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
      
        <Box sx={style6}>
        <div>
          <Typography id="modal-modal-description">
          <Container style={{padding:"0px"}}>
          <div className = "content6">
          
        
        <div>
          {/* <h4  className="fnt"><b>{id.length>0?name:"USER DETAILS"}</b> <Button disabled={isLoading} title="Close" variant="Standard"  onClick={()=>handleClose()} ><CloseIcon /></Button></h4>  */}
          <h4 className="fnt2"><b>Enter Comments</b>
        
        <Button title="Close" size="small" variant="Standard" sx={{ bgcolor: "red",float:"right",right:0,top:0,position:"absolute",height:33,width:5 }} onClick={()=>handleCloseComments()} > <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
       </h4>
         </div>
         <br/>
         <div style={{textAlign:"center",margin:"2px"}}>
        <table>
       <tr>
       <td style={{textAlign:"center"}}>
       <TextField 
          id="outlined-multiline-flexible"
          sx={{width: 400}}
        
          style={{marginRight:"20px"}}
          size="small"
          label="Comments"
          multiline
          rows={3} 
          value={comments || "" }
           onChange={(e)=> {
          setComments(e.target.value);
        }} 
        /> 
       </td>
       </tr>
      </table>
      </div>
      <br/>
      
        <div style={{textAlign:"center",marginBottom:"5px"}}>
     
      <Button disabled={isLoading} type="reset" title={"Update"}  variant="contained" onClick={(e)=>UpdateCommmentStatus()}>{"Update"}</Button> &nbsp; &nbsp;
      &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
     </div>

      </div>
      </Container> 
          </Typography>  
           </div>
        </Box>
      </Modal>

      <EmailTemplatesPopup openPopup={openEmailTemplate} showAlert={props.showAlert} data={emailData}  close={()=>setOpenEmailTemplate(false)}/>
</div>
  
  )
}

export default SentEmailsToCandidates