import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import './JobCategory.css';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea, CardActions } from '@mui/material';



const JobCategory = (props) => {
  return (
    <>{props.count>0 &&
    /* <div style={{padding:"5px",height:"100%"}}>
          <Card className='Rectangle_54' >

<CardActionArea>
  <CardContent>
  
  
 <img id={"companyProfile"+props.idx} style={{width:"150px",height:"150px",objectFit:"contain"}} src={props.image?"https://drive.google.com/uc?export=view&id="+(props.image.split('/')[5]):"index-removebg-preview.png"}  alt='reload' /> 

 <div id="Rectangle_54">
   
    <h5><b>{props.name}</b></h5>
   
  </div>
   
    <div >
      <h4> Jobs:{props.count}</h4>
    </div>
  </CardContent>
</CardActionArea>
</Card>
    </div> */
    <div id="card_company_name_grp" >
		<svg class="bg_esign_card_lbl_com">
			<rect id="bg_esign_card_lbl_com" rx="12" ry="12" x="0" y="0" width="171" height="194">
			</rect>
		</svg>
		<img id={"index-companyProfile_lbl_com"+props.idx} style={{width:"150px",height:"150px",objectFit:"contain"}} className='index-companyProfile_lbl_com' src={props.image?"https://drive.google.com/uc?export=view&id="+(props.image.split('/')[5]):"index-removebg-preview.png"} alt='reload' />
			
		<div id="Jobs__3_lbl_com">
			<span>Jobs : {props.count} </span>
		</div>
		<div id="Grassroots_BP0_lbl_com">
			<span>{props.name}</span>
		</div>
	</div>
    }
    </>
  )
}

export default JobCategory
