import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import './InterviewDetails.css'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Modal from '@mui/material/Modal';
import { UserContext } from './UserContext';
import { useSearchParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import  {MenuItem,Select} from '@mui/material';
import ScheduleInterview from './ScheduleInterview';
import Checkbox from '@mui/material/Checkbox';
const ApplicantStatus = (props) => {
  
const [searchParams] = useSearchParams();
const [interview, setInterview] = useState([]);
const [name, setName] = useState();
const [mail, setMail] = useState();
const [mobile, setMobile] = useState();
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState();
const [applicantId,setApplicantId]= useState();
const [companies,setCompanies] = useState();
const [companyName, setCompanyName] = useState();
const [open, setOpen] = React.useState(false);
const [Rounds, setRounds] = useState();
const [InterViewerName, setInterViewerName]= useState("");
const [InterViewDate, setInterViewDate] = React.useState(new Date());
const [InterViewTime, setInterViewTime] = React.useState();
const [interviewlocation, setInterviewlocation] = useState();
const [selectedRow,setSelectedRow] = useState();
const { user, setUser} = useContext(UserContext);
const [interviewStatus,setInterviewStatus] = useState();
const [OpenShedule, setOpenScedule ]= React.useState();
// const [companyName, setCompanyName] = useState();
// const [jobTitle, setJobTitle] = React.useState();
const [filterModel, setFilterModel] = React.useState({
  items: [
    {columnField: 'createdbyname', operatorValue: 'contains', value:user.name}
  ],
});


const rounds = [
  { label: 'HR Screening' },
  { label: 'Verification' },
  { label: 'Client Round' },
]  

const style3 = {
  position: 'absolute',
  top: '50%',

  transform: 'translate(87%, -50%)',
  height: 489,
  width: 533,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 10,

};

const cols = [  
  {field:'action', headerName: '',width: 80, headerAlign:'center',
  renderCell: (params) => {
    return[
       <div>
        <Button size='small' title="Schedule"  onClick={()=>showApplicant(params.row)}><PersonSearchIcon/></Button>
        {/* <Button size='small' title="Approve" onClick={()=>ApproveApplicants(params.row)}><CheckIcon/></Button> */}
     {/* <Button size='small'  title="Reject" onClick={()=>RejectApplicants(params.row)}><CancelOutlinedIcon/></Button> */}
 </div>
    ];
   },
  },
  {field:'name', headerName: 'Name',width: 190 },
  {field:'createdbyname', headerName: 'Created By',width: 190,hide: true },
  {field:'mobile', headerName: 'Mobile No',width: 140},
  {field:'hrname', headerName: 'HR Name',width: 190},
  {field:'jobtitle', headerName: 'Job Title',width: 200},
  {field:'companyname', headerName: 'Company Name',width: 200},
  {field:'interviewername', headerName: 'Interviewer Name',width: 190},
  {field:'interviewdate', headerName: 'Interview Date',width: 140},
  // {field:'interviewtime', headerName: 'Interview Time',width: 140},
  {field:'rounds', headerName: 'Rounds',width: 150},
  {field:'interviewlocation', headerName: 'Interview Location',width: 200},
  {field:'status', headerName: 'Status',width: 110},
  {field:'bookmark', headerName: 'Bookmarked', renderCell: (params) =>{

    return[
     <div>
    
    <Checkbox {...label} disabled checked={params.row.bookmark} />{params.row.bookmarkcomment}
    </div>
    ];
     },
    }
 
  ];
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  const getInterviews = ()=>{
    
    let apiURL=process.env.REACT_APP_API_URL + "/interview/applicantstatus";
    let apiParams = {
        method:"GET", 
        headers:{
          interviewstatus:interviewStatus,
          authtoken: localStorage.getItem("loggedintoken")
        }
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          // console.log(repos.data)
          setInterview(repos.data)
        }else props.showAlert(""+(repos.message || repos),"error"); 
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }


   
  const scheduleinterview = (row) =>{

    setSelectedRow(row)
      // setName(row.name)
      // setJobId(row.jobid)
      // setMobile(row.mobile)
      // setJobTitle(row.jobtitle)
      // setApplicantId(row.id)
      // setMail(row.mail)
      // setCompanyName(row.companyname)
        setOpenScedule(true);
      //  alert(JSON.stringify(row))
      }

  useEffect(()=>{
  getInterviews()
  },[interviewStatus])
  
    
  const handleCloseView = () => setOpen(false)


  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }


  const rowData = (interview)?.map((interview) => {
    let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
// alert(JSON.stringify(interview))
    return{
  id: interview?._id,  //applicant id
  applicantid:interview?.applicantId, 
  jobid:interview?.jobId,  //interview id
  name: interview?.name,
  mobile: interview?.mobile,
  jobtitle:interview?.jobtitle,
  companyname:interview?.companyname,
  interviewername:interview?.interviewername,
  hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
  interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
  interviewtime:IntvwTime,
  interviewlocation:interview?.location,
  rounds:interview?.rounds,
  status:interview?.status,
  feedback:interview?.feedback,
  bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
  bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
  createdbyname:interview?.createdbyname,
    };
  });

  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 60px)",top:"90px"}}>
<FormControl variant="outlined" sx={{width: 300 }} size="small">
        <InputLabel id="demo-simple-select-label">Rounds</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={interviewStatus} onChange={(e)=> {
          
            setInterviewStatus(e.target.value);   
            // getVacancies(e.target.value);
           } } >
          <MenuItem value="Applied">Applied</MenuItem>
          <MenuItem value="HR Screening">HR Screening</MenuItem>
          <MenuItem value="Verification">Verification</MenuItem>
          <MenuItem value="Client Round">Client Round</MenuItem>
        
       
        </Select>
      </FormControl> 
    <br/>
    <div style={{height:'calc(100vh - 130px)',width: '100%'}}>
      <DataGrid 
       initialState={{
        sorting: {
        sortModel: [{ field: 'interviewdate', sort: 'desc' }],
    },
  }}
      rows={rowData} columns={cols}  components={{
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowDoubleClick={(e)=>scheduleinterview(e.row)}
          />
 </div>
 <ScheduleInterview open={OpenShedule} showAlert={props.showAlert} interview={selectedRow} close={()=>setOpenScedule(false)} />

</div>
  ) 
}

export default ApplicantStatus







