import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import {useNavigate} from "react-router-dom";

import '../Styles/RecruiterSetUPTopBar.css'



const RecruiterSetUpTopBar = () => {
    var navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

    const handlelogout = () =>{
		localStorage.clear();
		setUser(null);
		window.location.href = "/";
	  }


  return (
    <div>
  <div id="setup_pc_header">
	<div id="left_grp_setup_pc">
		<svg class="left_corner_setup_pc" viewBox="11429 2956.231 545.423 70.996">
			<path id="left_corner_setup_pc" d="M 11429 2977.013916015625 L 11429 2956.2314453125 L 11974.4228515625 2956.2314453125 L 11974.4228515625 3026.17724609375 L 11973.4423828125 3026.17724609375 C 11973.4423828125 3026.17724609375 11958.845703125 3027.49853515625 11958.267578125 3010.958251953125 C 11957.689453125 2994.418212890625 11958.267578125 2991.22607421875 11958.267578125 2991.22607421875 C 11958.267578125 2991.22607421875 11957.6884765625 2976.13671875 11939.7646484375 2976.13671875 C 11921.8408203125 2976.13671875 11822.9658203125 2976.13671875 11822.9658203125 2976.13671875 C 11822.9658203125 2976.13671875 11804.751953125 2978.45849609375 11804.751953125 2991.22607421875 C 11804.751953125 3003.99365234375 11804.751953125 3017.34228515625 11804.751953125 3017.34228515625 C 11804.751953125 3017.34228515625 11800.0810546875 3027.27294921875 11790.4248046875 3027.226318359375 C 11790.49609375 3027.230224609375 11640.25390625 3027.22265625 11640.0078125 3027.208251953125 C 11630.177734375 3026.62841796875 11622.9501953125 3019.37353515625 11623.2392578125 3017.34228515625 C 11623.5283203125 3015.311279296875 11623.2392578125 2991.22607421875 11623.2392578125 2991.22607421875 C 11623.2392578125 2991.22607421875 11622.083984375 2976.13671875 11605.8935546875 2976.13671875 C 11589.703125 2976.13671875 11429 2977.013916015625 11429 2977.013916015625 Z">
			</path>
		</svg>
		<a href="https://bizibees.in">
		<img id="bizibeesjpg_setup_pc" src="bizibeesjpg_setup_pc.png" srcset="bizibeesjpg_setup_pc.png 1x" alt=""/>
		</a>
		<div id="setup_pc" onClick={()=>navigate('/recruitervacancies')}>
			<span>Vacancies</span>
		</div>
		<div id="setup_setup_pc">
			<span>Setup</span>
		</div>
	</div>
	<div id="right_grp_setup_pc">
		<svg class="right_cornersetup_pc" viewBox="12604.423 2956.948 175.078 71.411">
			<path id="right_cornersetup_pc" d="M 12604.4228515625 2958.037841796875 L 12604.4228515625 3028.342041015625 L 12618.232421875 3028.342041015625 C 12618.232421875 3028.342041015625 12635.0185546875 3029.3564453125 12635.0185546875 3015.6845703125 C 12635.0185546875 3002.01318359375 12635.0185546875 2988.84814453125 12635.0185546875 2988.84814453125 C 12635.0185546875 2988.84814453125 12636.486328125 2978.21484375 12648.7314453125 2977.708251953125 C 12660.9755859375 2977.20166015625 12779.5009765625 2977.708251953125 12779.5009765625 2977.708251953125 L 12779.5009765625 2956.94775390625 L 12766.078125 2958.037841796875 L 12604.4228515625 2958.037841796875 Z">
			</path>
		</svg>

		<img title="Log Out" id="logout_setup_pc" src="logout_setup_pc.png" srcset="logout_setup_pc.png 1x" alt=""  onClick={()=>handlelogout()}/>
			
		<img title="Profile" id="profile_setup_pc" src="profile_setup_pc.png" srcset="profile_setup_pc.png 1x" alt=""  onClick={()=>navigate('/dashboard')}/>
			
	</div>
	<div id="centre_header_pc_grp_setup_pc">
		<svg class="centre_header_pc_setup_pc">
			<rect id="centre_header_pc_setup_pc" rx="0" ry="0" x="0" y="0" width="630" height="70">
			</rect>
		</svg>
	</div>
</div>
    </div>
  )
}

export default RecruiterSetUpTopBar
