import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import './JobSummary.css';
import CardContent from '@mui/material/CardContent';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Button, CardActionArea, CardActions } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import JdKsPopUpUI from './JdKsPopUpUI';
import JdKsPopUpMobUI from './MobUI/JdKsPopUpMobUI';
import ShareJDPopUpUI from './ShareJDPopUpUI';
import QuickRegisterPopUp from './QuickRegisterPopUp';


const JobSummary = (props) => {

    const [expandDes,setExpandDes]= useState(false);
    const [expandSkill,setExpandSkill]= useState(false);
    const [vacancyDetails, setVacancyDetails] = useState("");
    const [openJdKs, setOpenJdKs] = useState(false);
	const [officeName,setOfficeName] = useState("");
	const [companyName,setCompanyName]=useState("");
	const [minExp,setMinExp] = useState("");
	const [maxExp,setMaxExp] = useState("");
	const [minCTC,setMinCTC] = useState("");
	const [maxCTC,setMaxCTC] = useState("");
	const [jobTitle,setJobTitle] = useState("");
	const [state,setState] = useState("");
	const [city,setCity] = useState("");

    // const [mobile, setMobile] = useState("");
    const [searchParams] = useSearchParams();
	const [openShareJD,setOpenShareJD] = useState(false);
    const { user, setUser} = useContext(UserContext);
	const [openQR,setOpenQR] = useState(false)

//UseEffect

useEffect(()=>{
if(props.vacancy){
setCompanyName(props.vacancy.companyName?props.vacancy.companyName:"");
setOfficeName(props.vacancy.officeName?props.vacancy.officeName:"");
setMinExp(props.vacancy.minExpMonths?props.vacancy.minExpMonths:0);
setMaxExp(props.vacancy.maxExpMonths?props.vacancy.maxExpMonths:0);
setMinCTC(props.vacancy.minCTC?props.vacancy.minCTC:0);
setMaxCTC(props.vacancy.maxCTC?props.vacancy.maxCTC:0);
setJobTitle(props.vacancy.jobTitle?props.vacancy.jobTitle:"");
setState(props.vacancy.state?props.vacancy.state:"");
setCity(props.vacancy.city?props.vacancy.city:"");
}
if(props.job){
	setCompanyName(props.job.companyname?props.job.companyname:"");
	setOfficeName(props.job.officeName?props.job.officeName:"");
	setMinExp(props.job.minexpmonths?props.job.minexpmonths:0);
	setMaxExp(props.job.maxexpmonths?props.job.maxexpmonths:0);
	setMinCTC(props.job.minCTC?props.job.minCTC:0);
	setMaxCTC(props.job.maxCTC?props.job.maxCTC:0);
	setJobTitle(props.job.jobtitle?props.job.jobtitle:"");
	setState(props.job.state?props.job.state:"");
    setCity(props.job.city?props.job.city:"");
}
},[props.vacancy,props.job])

//Functions
 

    const applyJob=(job)=>{
		if(user){
        let JobTitle=job.jobtitle?job.jobtitle:job.jobTitle;
        let JobId=job._id;
        let CompanyName=job.companyname?job.companyname:job.companyName;
        let applicantid = searchParams.get('applicantid')?searchParams.get('applicantid'):user.accesstype=== "c"?user._id:"";
        
        let apiURL=process.env.REACT_APP_API_URL + "/interview/"+(props.job?"applyinterview":"applyrecruiterinterview")+"/" + applicantid;
        let apiParams = {
            method:"POST",
            headers:{
                name: user.name,
                email: user.email,
                mobile: user.phone,
                feedback:"",
                round:"HR Screening",
                jobid: JobId,
                jobtitle: JobTitle,
                companyname:CompanyName,
                interviewername:job.spocName,
                hrname:job.spocName,
                createdbyid:job.spocId?job.spocId:job.recruiterId,
                createdbyname:job.spocId?job.spocName:job.recruiterName,
                // address: encodeURIComponent(address),
                reference:"Self Registered",
				authtoken: localStorage.getItem("loggedintoken")
            },
        };
    
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             // alert("Job applied successfully!");
              props.showAlert("Job applied successfully!","success")
            //   setTimeout(() => {
            //       window.location.href="/interviewdetails?applicantid="+applicantid;
            //   }, 1000);
              
      
            }else props.showAlert((repos.message || repos|| "Already applied for this job!"),"error");
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
	}else{
		setOpenQR(true)
	}
    }


    const applyRecJob=(job)=>{
       
        let JobTitle=job.jobtitle?job.jobtitle:job.jobTitle;
        let JobId=job._id;
        let CompanyName=job.companyname?job.companyname:job.companyName;
        let applicantid = searchParams.get('applicantid')?searchParams.get('applicantid'):user.accesstype=== "c"?user._id:"";
        
        let apiURL=process.env.REACT_APP_API_URL + "/interview/applyrecruiterinterview/" + applicantid;
        let apiParams = {
            method:"POST",
            headers:{
                name: user.name,
                email: user.email,
                mobile: user.phone,
                feedback:"",
                round:"HR Screening",
                jobid: JobId,
                jobtitle: JobTitle,
                companyname:CompanyName,
                interviewername:job.spocName,
                hrname:job.spocName,
                createdbyid:job.spocId?job.spocId:job.recruiterId,
                createdbyname:job.spocId?job.spocName:job.recruiterName,
                // address: encodeURIComponent(address),
                reference:"Self Registered",
				authtoken: localStorage.getItem("loggedintoken")
            },
        };
    
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             // alert("Job applied successfully!");
              props.showAlert("Job applied successfully!","success")
            //   setTimeout(() => {
            //       window.location.href="/interviewdetails?applicantid="+applicantid;
            //   }, 1000);
              
      
            }else props.showAlert((repos.message || repos|| "Already applied for this job!"),"error");
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
    }
    
	const handleOpenJd =(vac)=>{
		if(user){
			setVacancyDetails(vac)
			setOpenJdKs(true)
		}else{
			setOpenQR(true)
		}
		

	}


	
	const handleShare = () => {
	
		setOpenShareJD(true)
	  }

  return (
    <>
<div id="overall_card_grp_candi">
		<svg class="caard_bg_lbl_candi">
			<rect id="caard_bg_lbl_candi" rx="23" ry="23" x="0" y="0" width="349" height="276">
			</rect>
		</svg>
		<div id="Diamond_District_lbl_candi">
			<span>{officeName}</span>
		</div>
		<div id="Customer_Care_candi">
			<span>{companyName}</span>
		</div>
		<div id="exp_yr_llbl_vac_candi">
			<span>{((minExp - ( minExp%12))/12)} Yr {minExp%12} Mn to {((maxExp - ( maxExp%12))/12)} Yr {maxExp%12} Mn</span>
		</div>
		<div id="n_2000_lbl_candi">
			<span>{minCTC} to {maxCTC} INR</span>
		</div>
		<div id="Office_Name_lbl_vac_candi">
			<span>Office Name</span>
		</div>
		<div id="company_name_lbl_candi">
			<span>Company</span>
		</div>
		<div id="lbl_vac_exp_candi">
			<span>Experience</span>
		</div>
		<div id="Salary_Range_lbl_candi">
			<span>Salary Range</span><span style={{fontSize:"6px"}}> </span>
		</div>
		<button id="share_grp_btn_candi" onClick={()=>handleShare()}>
			<svg class="image_bdr_ba">
				<ellipse id="image_bdr_ba" rx="20" ry="20" cx="20" cy="20">
				</ellipse>
			</svg>
			<img id="share_img_bb" src="share_img_b_can.png" srcset="share_img_b_can.png 1x" alt='reload'/>
				
		</button>
		<div id="view_more_JD_candi" onClick={()=>handleOpenJd(props.job?props.job:props.vacancy)}>
			<svg class="jd_bdr">
				<rect id="jd_bdr" rx="9" ry="9" x="0" y="0" width="59" height="39">
				</rect>
			</svg>
			<div id="JDKS_lbl_cus">
				<span>JD/KS</span>
			</div>
		</div>
		<div id="apply_btn_grp_candi" onClick={()=>applyJob(props.job?props.job:props.vacancy)}>
			<svg class="Rectangle_114_bs">
				<rect id="Rectangle_114_bs" rx="9" ry="9" x="0" y="0" width="156" height="39">
				</rect>
			</svg>
			<div id="Group_apply_button">
				<img id="success_bu_can" src="success_bu_can.png" srcset="success_bu_can.png 1x" alt='reload'/>
					
				<div id="Apply_bv">
					<span>Apply</span>
				</div>
			</div>
		</div>
		<div id="Grassroots_BPO_lbl_candi">
			<span>{jobTitle}</span>
		</div>
		<svg class="n_candi_line" viewBox="0 0 295 1">
			<path id="n_candi_line" d="M 0 0 L 295 0">
			</path>
		</svg>
		<div id="report_btn_grp_candi">
			<svg title="Report" class="report_btn_rec">
				<rect id="report_btn_rec" rx="9" ry="9" x="0" y="0" width="52" height="39">
				</rect>
			</svg>
			<img title="Report" id="warning_report" src="warning_report_can.png" srcset="warning_report_can.png 1x" alt='reload'/>
		</div>
		<div id="city_name_state">
			<span>{city+","+state}</span>
		</div>
		<div id="city_lbl_cce">
			<span>City</span>
		</div>
	</div>

	{(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<JdKsPopUpMobUI openJdKs={user ?openJdKs:false} availableVacancy={vacancyDetails} showAlert={props.showAlert} closeJdKs={()=>setOpenJdKs(false)}/>:<JdKsPopUpUI  openJdKs={user ?openJdKs:false} availableVacancy={vacancyDetails} showAlert={props.showAlert} closeJdKs={()=>setOpenJdKs(false)}/>}
	<QuickRegisterPopUp openQR={openQR} showAlert={props.showAlert} closeQR={()=>setOpenQR(false)}/>
	<ShareJDPopUpUI openShareJD={openShareJD} vacancy={jobTitle} showAlert={props.showAlert} type="Candidate" closeShareJD={()=>setOpenShareJD(false)}/>
</>

  )
}

export default JobSummary
