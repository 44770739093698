
import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import  {MenuItem, Select,Button,TextField,InputLabel,FormControl,Typography,Modal,Box,Container,Autocomplete} from '@mui/material';
import { PinDropSharp } from '@mui/icons-material'
import userEvent from '@testing-library/user-event';
import { UserContext } from './UserContext';
import "./FilterInterviewsMob.css"

const FilterInterviewsMob = (props) => {
//UseState

const [filterCallsByCity,setFilterCallsByCity]=useState(false);
const [cityFilter,setCityFilter] = useState("");
const [filterCallsByArea,setFilterCallsByArea]=useState(false);
const [areaFilter,setAreaFilter]=useState("");
const [filterCallsByEducation,setFilterCallsByEducation]=useState(false);
const [educationFilter,setEducationFilter]=useState("");
const [filterCallsByLanguage,setFilterCallsByLanguage]=useState(false);
const [languageFilter,setLanguageFilter]=useState("");
const [filterCallsBySalary,setFilterCallsBySalary]=useState(false);
const [salaryFilter,setSalaryFilter]=useState("");
const [filterCallsByExperience,setFilterCallsByExperience]=useState(false);
const [experienceFilter,setExperienceFilter]=useState("");
const [filterCallsByNoticePeriod,setFilterCallsByNoticePeriod]=useState(false);
const [noticePeriodFilter,setNoticePeriodFilter]=useState("");
const [filterCallsByReferal,setFilterCallsByReferal]=useState(false);
const [referalFilter,setReferalFilter]=useState("");
const [filterCount,setFilterCount] = useState(0);

const { user, setUser} = useContext(UserContext);


//UseEffect
useEffect (()=>{
var filters =  JSON.parse(localStorage.getItem("filters"))
if(filters){
    setCityFilter(filters.city);
    setFilterCallsByCity(filters.city?true:false);

    setAreaFilter(filters.area);
    setFilterCallsByArea(filters.area?true:false);

    setEducationFilter(filters.education);
    setFilterCallsByEducation(filters.education?true:false);

    setLanguageFilter(filters.language);
    setFilterCallsByLanguage(filters.language?true:false);

    setSalaryFilter(filters.salary);
    setFilterCallsBySalary(filters.salary?true:false);

    setExperienceFilter(filters.experience);
    setFilterCallsByExperience(filters.experience?true:false);

    setNoticePeriodFilter(filters.noticePeriod);
    setFilterCallsByNoticePeriod(filters.noticePeriod?true:false);

    setReferalFilter(filters.referal);
    setFilterCallsByReferal(filters.referal?true:false);
   
}
CountNumberOfApplicants()

},[user])



//Functions
const applyFilter = ()=>{

  var selectedFilter = {}
  if(filterCallsByCity===true){
    selectedFilter={...selectedFilter,"city":cityFilter}
  }
  if(filterCallsByArea===true)
    {
      selectedFilter={...selectedFilter,"area":areaFilter}
    }
  if(filterCallsByEducation===true)
    {
      selectedFilter={...selectedFilter,"education":educationFilter}
    }
  if(filterCallsByLanguage===true)
    {
      selectedFilter={...selectedFilter,"language":languageFilter}
    }
  if(filterCallsBySalary===true)
    {
      selectedFilter={...selectedFilter,"salary":salaryFilter}
    }
    if(filterCallsByExperience===true)
    {
      selectedFilter={...selectedFilter,"experience":experienceFilter}
    }
   
    if(filterCallsByNoticePeriod===true)
    {
      selectedFilter={...selectedFilter,"noticePeriod":noticePeriodFilter}
    }
    if(filterCallsByReferal===true)
    {
      selectedFilter={...selectedFilter,"referal":referalFilter}
    }
    localStorage.setItem('filters', JSON.stringify(selectedFilter))
    CountNumberOfApplicants()
}

const CountNumberOfApplicants = ()=>{
   var filters =  localStorage.getItem("filters")
  let apiURL=process.env.REACT_APP_API_URL + "/interview/newinterviewfiltercount/"+user.name;
  let apiParams = { 
    method: "GET",
    headers:{
      filters:filters,
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
           
          setFilterCount(repos.data)
          }
        });
}

  return (
    <div>
      <div id="filter">
	<div id="Welcome_ftr">
		<span>Welcome </span>
	</div>
	<div id="user_name_lbl_ftr">
		<span>{user.name}</span>
	</div>
	<div onClick={()=>props.filter(false)} id="Group_fltr">
		<svg class="dashboard_profile_outer_line_fltr" >
			<rect id="dashboard_profile_outer_line_fltr" rx="29" ry="29" x="0" y="0" width="369" height="163">
			</rect>
		</svg>
		<svg class="call_time_outer_line_fltr">
			<rect id="call_time_outer_line_fltr" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<div id="CallIdle_Time_lbl_fltr">
			<span>Call/Idle Time</span>
		</div>
		<svg class="Hr_Screening_fltr">
			<rect id="Hr_Screening_fltr" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<img id="passed_img_fltr" src="passed_img.png" srcset="passed_img.png 1x, passed_img@2x.png 2x" />
			
		<svg class="Called_fltr">
			<rect id="Called_fltr" rx="29" ry="29" x="0" y="0" width="163" height="163">
			</rect>
		</svg>
		<div id="Called">
			<span>Called</span>
		</div>
		<img id="customer-service1" src="customer-service1.png" srcset="customer-service1.png 1x, customer-service1@2x.png 2x" />
			
		<img id="time-call_img" src="time-call_img.png" srcset="time-call_img.png 1x, time-call_img@2x.png 2x" />
			
		<div id="Current_Candidate_lbl_fltr">
			<span>Candidate : </span>
		</div>
		<svg class="call_time_val_fltr">
			<rect id="call_time_val_fltr" rx="18.5" ry="18.5" x="0" y="0" width="117" height="37">
			</rect>
		</svg>
		<svg class="called_val_fltr">
			<rect id="called_val_fltr" rx="18.5" ry="18.5" x="0" y="0" width="117" height="37">
			</rect>
		</svg>
		<div id="n_">
			<span>1</span>
		</div>
		<div id="Group_1">
			<svg class="passed_val_fltr">
				<rect id="passed_val_fltr" rx="18.5" ry="18.5" x="0" y="0" width="52" height="37">
				</rect>
			</svg>
			<div id="n__">
				<span>5</span>
			</div>
		</div>
		<div id="n__ba">
			<span>7</span>
		</div>
		<img onClick={()=>props.filter(false)} id="setting" src="setting.png" srcset="setting.png 1x, setting@2x.png 2x" alt='reload'/>
			
		<div id="HR_Screening_fltr">
			<span>HR Screening </span>
		</div>
	</div>
	
    <div className='CityFltr_chck'>
    <input type="checkbox" id='CityFltr_chck' checked={filterCallsByCity} onChange={()=>setFilterCallsByCity(!filterCallsByCity)} />
    </div>
    
    <div className='Cityfltr_txt'>
      <input id="Cityfltr_txt" placeholder="City" disabled={filterCallsByCity===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={cityFilter} type="text" onChange={(e)=>setCityFilter(e.target.value)}/>
    </div>

    <div className='AreaFltr_chck'>
    <input type="checkbox" id='AreaFltr_chck' checked={filterCallsByArea} onChange={()=>setFilterCallsByArea(!filterCallsByArea)} />
    </div>


    <div className='Areafltr_txt'>
      <input id="Areafltr_txt" placeholder="Area" disabled={filterCallsByArea===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={areaFilter} type="text" onChange={(e)=>setAreaFilter(e.target.value)}/>
    </div>
   
    
    <div className='EducationFltr_chck'>
  <input type="checkbox" id='EducationFltr_chck' checked={filterCallsByEducation} onChange={()=>setFilterCallsByEducation(!filterCallsByEducation)} />
    </div>
	
	
    <div className='Educationfltr_txt'>
      <input id="Educationfltr_txt" placeholder="Education" disabled={filterCallsByEducation===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={educationFilter} type="text" onChange={(e)=>setEducationFilter(e.target.value)}/>
    </div>
  

    <div className='Languagefltr_chck'>
    <input type="checkbox" id='Languagefltr_chck' checked={filterCallsByLanguage} onChange={()=>setFilterCallsByLanguage(!filterCallsByLanguage)} />
    </div>


    <div className='Languagefltr_txt'>
      <input id="Languagefltr_txt" disabled={filterCallsByLanguage===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={languageFilter} placeholder="Language" type="text" onChange={(e)=>setLanguageFilter(e.target.value)}/>
    </div>
    

    <div className='Salaryfltr_chck'>
    <input type="checkbox" id='Salaryfltr_chck' checked={filterCallsBySalary} onChange={()=>setFilterCallsBySalary(!filterCallsBySalary)} />
    </div>

    <div className='Salaryfltr_txt'>
      <input id="Salaryfltr_txt" placeholder="Salary not exceeding" disabled={filterCallsBySalary===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={salaryFilter} type="text" onChange={(e)=>setSalaryFilter(e.target.value)}/>
    </div>
    
	

    <div className='Experiencechck_fltr'>
    <input type="checkbox" id='Experiencechck_fltr' checked={filterCallsByExperience} onChange={()=>setFilterCallsByExperience(!filterCallsByExperience)} />
    </div>

    <div className='Experiencefltr_txt'>
      <input id="Experiencefltr_txt" placeholder="Experience greater than" disabled={filterCallsByExperience===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={experienceFilter} type="text" onChange={(e)=>setExperienceFilter(e.target.value)}/>
    </div>
	

    <div className='NoticePeriodfltr_chck'>
    <input type="checkbox" id='NoticePeriodfltr_chck' checked={filterCallsByNoticePeriod} onChange={()=>setFilterCallsByNoticePeriod(!filterCallsByNoticePeriod)} />
    </div>

    <div className='NoticePeriodfltr_txt'>
      <input id="NoticePeriodfltr_txt" placeholder="Notice period below" disabled={filterCallsByNoticePeriod===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={noticePeriodFilter} type="text" onChange={(e)=>setNoticePeriodFilter(e.target.value)}/>
    </div>
   

    <div className='Refferedby_fltrchck'>
    <input type="checkbox" id='Refferedby_fltrchck' checked={filterCallsByReferal} onChange={()=>setFilterCallsByReferal(!filterCallsByReferal)} />
    </div>


    <div className='Refferedbyfltr_txt'>
      <input id="Refferedbyfltr_txt" placeholder="Referred by" disabled={filterCallsByReferal===false} style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={referalFilter} type="text" onChange={(e)=>setReferalFilter(e.target.value)}/>
    </div>
	
    <div className='FilterBtn_fltr'>
    <Button size='small' id='FilterBtn_fltr' variant="contained"  title="Apply Filter" style={{textAlign:"center",width:"130px"}} onClick={()=>applyFilter()}>Apply Filter</Button>
    </div>

    <div style={{marginTop:"50px",fontSize:"25px"}}>
    {/* <input id="Salaryfltr_txt" placeholder="Salary not exceeding" style={{border:"2px solid black",width:"250px",height:"40px",fontSize:"20px"}} value={filterCount} type="label" /> */}
    Total Numbers : {filterCount}
    </div>
	
	<svg class="Rectangle_42_fltr" onClick={()=>props.filter(false)}>
		<linearGradient id="Rectangle_42_fltr" spreadMethod="pad" x1="0.5" x2="0.5" y1="0" y2="1">
			<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#043343" stop-opacity="1"></stop>
		</linearGradient>
		<rect  id="Rectangle_42_fltr" rx="0" ry="0" x="0" y="0" width="53" height="903">
		</rect>
	</svg>
</div>
    </div>
  )
}

export default FilterInterviewsMob
