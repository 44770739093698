import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './Applicantdetails.css'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setSeconds } from 'date-fns';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';

const ApplicantDetails = (props) => {
  
  
  const style2 = {
    position: 'absolute',
    top: '50%',
  
    transform: 'translate(36%, -49%)',
    height: 560,
    width: 800,
  
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
   
  };

  const today=() => {
    var date = new Date();
    const day = (date.getDate() < 10 ? '0' : '')+date.getDate();
    const month = (date.getMonth() + 1 < 10 ? '0' : '')+(date.getMonth() + 1);
    const year = date.getFullYear();
    return year+'-'+month+'-'+day;
  }
// const [interview, setInterview] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [searchParams] = useSearchParams();
const [id, setId] = useState("");
const [name, setName] = useState("");
const [mail, setMail] = useState("");
const [mobile, setMobile] = useState("");
const [whatsNumber,setWhatsNumber] = useState("");
const [gender, setGender] = useState("male");
const [address, setAddress] = useState("");
const [ Skills,setSkills]= useState("");
const [jobId, setJobId] = React.useState("");
const [jobTitle, setJobTitle] = React.useState("");
// const [applicantId,setApplicantId]= useState()
const [open, setOpen] = React.useState(false);
const [applicant, setApplicant] = useState([]);
const [companies,setCompanies] = useState();
const [applicants,setApplicants] = useState();
const [selectedRow,setSelectedRow] = useState();
const [resume, setResume ] = useState();
const [reference, setReference]= useState("");
const [Dob, setDob ]= React.useState(today());
const [ExpYears, setExpYears]= useState(0);
const [ExpMonth, setExpMonths]= useState(0);
const [companyName, setCompanyName] = useState("");
const { user} = useContext(UserContext);
const [isLoading,setIsLoading]=useState(false);
const [city ,setCity]= useState("");


const [filterModel, setFilterModel] = React.useState({
  items: [
    {columnField: 'createdbyname', operatorValue: 'contains', value:user.name}
  ],
});

const City = [
  { label: 'Bangalore' },
]  
const cols = [
  {field:'search', headerName: '', renderCell: (params) =>{

    return[
    <div>
     <Button size='small' title="View Applicant" disabled={params.row.active===false} onClick={()=>scheduleinterview(params.row)}><EventIcon/></Button></div>
    ];
    },
 },
  {field:'id', headerName: 'id', hide: true },
  {field:'name', headerName: 'Name',width: 190 },
  {field:'createdbyname', headerName: 'Created By',width: 190, },
  {field:'email', headerName: 'Email',width: 200},
  {field:'mobile', headerName: 'Mobile No',width: 140},
  {field:'dob', headerName: 'DOB',width: 100},
  {field:'gender', headerName: 'Gender',width: 100},
  {field:'experience', headerName: 'Experience',width: 100},
  {field:'skills', headerName: 'Skills',width: 290},
//   {field:'Resume', headerName: 'Resume',width: 80},
//   {field:'jobtitle', headerName: 'Job Title',width: 200},
//   {field:'companyname', headerName: 'Company Name',width: 200},
  {field:'Resume', headerName: 'Resume',width: 130,headerAlign:'center',
  renderCell: (params) =>{

return[
<div>
<Button size='small' className='btn' title="Download Resume" onClick={()=>downloadApplicant(params.id,params.row.name)}><FileDownloadIcon/></Button>
<input type="file" id="actual-btn" hidden/>
<Button size='small' className='btn' for="actual-btn" title="Upload Resume" onClick={(e)=>uploadApplicant(e)}><FileUploadIcon/></Button></div>
];
},
},  


//   {field:'interviewername', headerName: 'Interviewer Name',width: 190},
//   {field:'interviewdate', headerName: 'Interview Date',width: 140},
//   {field:'interviewtime', headerName: 'Interview Time',width: 140},
//   {field:'rounds', headerName: 'Rounds',width: 150},
//   {field:'status', headerName: 'Status',width: 110},
{field:'jobsappllied', headerName: 'Applied'},
{field:'pendingInterviews', headerName: 'Interviews'},
{field:'active', headerName: 'Status', headerAlign:'center',

  renderCell: (params) => {
    // console.log(params)
   return[
      <div>
     
    <Button size='small' title="Make Active" style={{display:params.row.active!==false ?"none":"inline"}}  onClick={()=>ActiveApplicants(params.row)}><CheckIcon/></Button>
    <Button size='small'  title="Make Inactive" style={{display:params.row.active ===false ?"none":"inline"}} onClick={()=>InactiveApplicants(params.row)}><CancelOutlinedIcon/></Button>
</div>
   ];
  },
},  
  ];

  const getAllApplicants = ()=>{
    let apiURL=searchParams.get('jobid')?process.env.REACT_APP_API_URL + "/applicants/getapplicants/"+searchParams.get('jobid'):process.env.REACT_APP_API_URL + "/applicants/getallapplicants"; 
    let apiParams = {
        method:"GET",
        headers: {
          authtoken: localStorage.getItem("loggedintoken")
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
  
          setApplicant(repos.data)
        }else alert(repos.message || repos);  
    }) 
    .catch((err) => alert(err));
  }
 
 
  
  useEffect(()=>{
    getAllApplicants()
    if(searchParams.get('jobid'))
    {setFilterModel({
      items: [
       
      ],
    });}
  if(searchParams.get('applicantid'))
    {setFilterModel({
      items: [
        {columnField: 'id', operatorValue: 'contains', value:searchParams.get('applicantid')}
      ],
    });}
  },[])

//   const getInterviews = ()=>{
    
//     let apiURL=process.env.REACT_APP_API_URL + "/interview/"
//     let apiParams = {
//         method:"GET", 
//     };
    
//     fetch(apiURL, apiParams)
//     .then((res)=> (res.status===200 ? res.json() : res.text()))
//     .then((repos)=> {
//         if (repos.data) {
//           // console.log(repos.data)
//           setInterview(repos.data)
//         }else alert(repos.message || repos);  
//     }) 
//     .catch((err) => alert(err));
//   }
//   // alert(JSON.stringify(interview))

//   useEffect(()=>{
//   getInterviews()
  
//   },[])
 

  //Download Resume
  const downloadApplicant = (id,name) => {

    let apiURL = process.env.REACT_APP_API_URL + "/applicants/resume/" + id;
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          // //console.log(repos.data);
          let type = repos.data.fileType;
          let bytes = new Uint8Array(repos.data.resume.data.data);
          let newLink = saveByteArray(name+"."+repos.data.fileName.split('.')[repos.data.fileName.split('.').length-1], bytes, type);
          newLink.link.click();
          props.showAlert("File Downloaded Successfully","success")
        } else alert(repos.message || repos);
      })
      .catch((err) => alert(err));
  };
  function saveByteArray(reportName, byte, type) {
    let blob = new Blob([byte], { type: type });
    let link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
  
    let fileName = reportName;
    link.download = fileName;
  
    let newLink = {
      name: fileName,
      link: link,
    };
    return newLink;
  }
   

  //upload Resume
  const uploadApplicant = (e) =>{
    let formData = new FormData();
    formData.append("resume",e.target.files[0]);
    setResume(formData);
  }
  
  
  const scheduleinterview = (row) =>{
    window.location.href="/interviewdetails?applicantid="+row.id;
  }

// setSelectedRow({jobId:row.jobid,name:row.name,round:row.rounds,mobile:row.mobile,jobTitle:row.jobtitle,applicantId:row.id,email:row.mail,companyName:row.companyname,status:row.status})


  const handleCloseView = () => setOpen(false)


  //Accept Applicants
 const ActiveApplicants = (row) =>{
  let apiURL=process.env.REACT_APP_API_URL + "/interview/activeApplicant/" +row.id;
  let apiParams = {
      method:"POST",
      headers:{
        // applicantid:row.applicantid,
        active:true,
        authtoken: localStorage.getItem("loggedintoken")
      }
}

fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
  if (repos.data) {
    alert("Applicant Activated");
    window.location.reload(false);
  
    //console.log(repos.data);
  }else alert(repos.message || repos);
}) 
.catch((err) => alert(err));
}
  
  
  //Inactive Applicants
  const InactiveApplicants = (row) =>{
    let apiURL=process.env.REACT_APP_API_URL + "/interview/inactiveapplicant/" +row.id;
    let apiParams = {
        method:"POST", 
        headers:{
          // applicantid:row.applicantid,
          active:false,
          authtoken: localStorage.getItem("loggedintoken")
        }
  }
  
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
    if (repos.data) {
      alert("Applicant Inactive");
      window.location.reload(false);
    
      //console.log(repos.data);
    }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
  }

  //Add Applicant Details
const addApplicants = (job) =>{
  setJobTitle(job.jobtitle);
  setJobId(job._id);
  setCompanyName(job.companyname);
  setOpen(true);
}

// Edit Applicant Details
const editApplicants = (row)=>{
  console.log(row)
  setId(row.id||"");
  setName(row.name||"");
  setMail(row.email||"");
  setMobile(row.mobile||"");
  setWhatsNumber(row.number||"");
  setDob(row.dob);
  setAddress(row.address||"");
  setSkills(row.skills||"");
  setGender(row.gender||"");
  setExpYears(row.experience.split(' ')[0]||0);
  setExpMonths(row.experience.split(' ')[2]||0);
  setCity(row.city||"");
  setReference(row.reference||"");

  setOpen(true);
}

const resetApplicant = ()=>{
  setId("");
  setName("");
  setMail("");
  setMobile("");
  setWhatsNumber("");
  setDob(today());
  setAddress("");
  setCity("");
  setSkills("");
  setGender("");
  setExpYears(0);
  setExpMonths(0);
  setReference("");
}


const handleClose = () =>{setOpen(false);
  resetApplicant()}

const handleUpload = (e) =>{
  let formData = new FormData();
  formData.append("resume",e.target.files[0]);
  setResume(formData);
}

 //functions
 const addApplicant=()=>{
  setIsLoading(true)
  let apiURL=process.env.REACT_APP_API_URL + "/applicants/addapplicant";
  let apiParams = {
      method:"POST",
      headers:{
          name: name,
          email: mail,
          mobile: mobile,
          number:whatsNumber,
          dob:Dob,
          city:city,
          experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
          skill:encodeURIComponent(Skills),
          gender: gender,
          jobid: jobId,
          jobtitle: jobTitle,
          companyname:companyName,
          address: encodeURIComponent(address),
          reference:encodeURIComponent(reference),
          createdbyid:user._id,
          createdbyname:user.name,
          authtoken: localStorage.getItem("loggedintoken")
      },
     body: resume,
  };
  // console.log(apiParams)
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        setOpen(false)
        setIsLoading(false)
        
        alert("Applicant Added Successfull");
        window.location.reload(false);
        
          //console.log(repos.data);
      }else{ alert(repos.message || repos);
      setIsLoading(false)}
  }) 
  .catch((err) =>{alert(err)
  setIsLoading(false)})
}


//edit applicant
const editApplicantDetails=(id)=>{
  setIsLoading(true)
  let apiURL=process.env.REACT_APP_API_URL + "/applicants/editapplicant/"+id;
  let apiParams = {
      method:"POST",
      headers:{
          name: name,
          email: mail,
          mobile: mobile,
          number:whatsNumber,
          dob:Dob,
          city:city,
          experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
          skill:encodeURIComponent(Skills),
          gender: gender,
          address: encodeURIComponent(address),
          reference:encodeURIComponent(reference),
          updatedById:user._id,
          updatedByName:user.name,
          authtoken: localStorage.getItem("loggedintoken")

      }
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        setOpen(false)
        setIsLoading(false)
        
        alert("Applicant Updated Successfull");
        window.location.reload(false);
        
          //console.log(repos.data);
      }else alert(repos.message || repos);
  }) 
  .catch((err) => alert(err));
}

  //Get Companies
  const getCompanies = () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/jobs/companies"
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              setCompanies(repos.data)
            }else alert(repos.message || repos);  
        }) 
        .catch((err) => alert(err));
      }

      
  useEffect(()=>{
    getCompanies()
  },[])


  const rowData = (applicant)?.map((applicant) => {
// alert(JSON.stringify(applicant))
    return{
    id:applicant?._id,
    name:applicant?.name,
    email:applicant?.email,
    mobile:applicant?.mobile,
    city:applicant?.city,
    number:applicant?.number,
    reference:applicant?.reference,
    address:applicant?.address,
    experience:(((applicant.experience - (applicant.experience%12))/12) +" Yr "+(applicant.experience%12))+" Mn",
    skills:applicant?.skill,
    dob:(new Date((applicant?.dob)).toLocaleDateString()),
    gender:applicant?.gender,
  jobtitle:applicant?.jobtitle,
  companyname:applicant?.companyname,
  rounds:applicant?.rounds,
  status:applicant?.status,
  active:applicant?.active,
  jobsappllied:applicant?.jobsapplied,
  pendingInterviews:applicant?.pendinginterviews,
  createdbyname:applicant?.createdbyname,
    };
  });

  return (
    <>
   
    <Button size='small' variant="contained" className='intern' title="Add Applicant" onClick={()=>addApplicants(rowData)}>+ Add Applicant</Button>
    <br/>
    <br/>
    <div style={{height:750,width: '100%'}}>
      <DataGrid rows={rowData} columns={cols}  components={{
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowDoubleClick={(e)=>editApplicants(e.row)}
          />
 </div>
<ScheduleInterview open={OpenShedule} showAlert={props.showAlert} interview={selectedRow} close={()=>setOpenScedule(false)} companies={companies}/>
<Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
      
        <Box sx={style2}>
        <div>
          <Typography id="modal-modal-description" sx={{ mt: 4 }}>
          <Container>
          <div className = "content1">
          
        
        <div>
          <h4  className="fnt"><b>{id.length>0?name:"ADD APPLICANT DETAILS"}</b></h4> 
         </div><div >
         <br/>
         &nbsp;&nbsp;&nbsp;
         <TextField className="BOX1" id="outlined-size-small"  size="small" label="Name" variant="outlined"  value={name || ""} onChange={(e)=> {
          setName(e.target.value);
        }}/>
    	&nbsp;&nbsp;&nbsp;
       <TextField className="BOX1" id="outlined-basic" size="small" label="Email" variant="outlined"  value={mail || ""} onChange={(e)=> {
        setMail(e.target.value);     
      }}/>
      &nbsp;&nbsp;&nbsp;
          <TextField className="BOX1"  id="outlined-basic" type="number" size="small" label="Mobile No" variant="outlined"  value={mobile} onChange={(e)=> {
            setMobile(e.target.value);  
      }}/></div>
       
      <br/><div>
      &nbsp;&nbsp;&nbsp;
            <TextField className="BOX1"  id="outlined-basic"  size="small" label="Whatsapp No" variant="outlined"  value={whatsNumber} onChange={(e)=> {
            setWhatsNumber(e.target.value);  }}/>
          &nbsp;&nbsp;&nbsp;  
           <input type='date'
           className="BOX1"
          label="DOB"
          value={(Dob)}
          onChange={(e) => {
          setDob(e.target.value);
        }}
        />

    &nbsp;&nbsp;&nbsp;
       <FormControl variant="outlined" sx={{minWidth: 220 }} size="small" className="BOX1" >
        <InputLabel id="demo-simple-select-label"  >Gender</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={gender} onChange={(e)=> {
          
            setGender(e.target.value);   

           } } >
             <MenuItem selected disabled>
           
          </MenuItem>
        
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="others">Others</MenuItem>
        </Select>
      </FormControl> 
      <br/> <br/>
    </div><div style={{left:"8%",position:"absolute"}}>
    &nbsp;&nbsp;&nbsp;Experience : 
      <TextField className="BOX1" sx={{ m: 0.5, width: '10ch' }} label="Years" variant="outlined" size="small" value={ExpYears} onChange={(e)=> {
          
          setExpYears(e.target.value);
        }}/>
      <TextField className="BOX1" sx={{ m:0.5, width: '10ch' }} label="Months" variant="outlined" size="small" value={ExpMonth} onChange={(e)=> {
          
          setExpMonths(e.target.value);
        }}/></div><div>
    
           <Autocomplete
      disablePortal
      style={{right:"12%",position:"absolute"}}
      id="txtCity"
      freeSolo
      options={City}
      sx={{ width: 280 }}
      value={city || ""}
      onChange={(event,value)=>setCity(value.label)}
      renderInput={(params) => <TextField {...params}  
          className="BOX1"
          sx={{minWidth: 270 }}
          size="small" label="City" 
          value={city || ""}
          onChange={(e)=> {;
            setCity(e.target.value);
          }}  />}
         /> 
         <br/> </div>
         <br/>
         <br/><div>
          <TextField
          id="outlined-multiline-flexible"
          className="BOX1"
          sx={{minWidth: 350 }}
          size="small"
          label="Address"
          multiline
          rows={2}value={address || ""} 
          onChange={(e)=> {
          setAddress(e.target.value);
        }} />
   &nbsp;&nbsp;&nbsp;
           <TextField className="BOX1" id="outlined-basic-normal" multiline rows={2}  sx={{minWidth: 350 }} size="small" label="Skills" variant="outlined"  value={Skills || ""} onChange={(e)=> {
        setSkills(e.target.value);     
      }}/></div>
   <br/> 
   <div style={{position:"relative",left:"150px"}}>
     <TextField className="BOX1" id="outlined-basic-normal" multiline rows={1}  sx={{minWidth: 400 }} size="small" label="Reference" variant="outlined"  value={reference || ""} onChange={(e)=> {
        setReference(e.target.value);  
      }}/></div>
      <br/>
        {id.length>0?null:
         <div class="mb-2" style={{position:"relative",left:"70px"}}>
         <InputLabel  style={{top:20,left:100}} >Resume Upload : </InputLabel>
         <input className="BOX1" id="myFile" type="file" placeholder="Upload Resume" style={{position:"absolute",left:"230px"}}  name="Resume" onChange={(e) => handleUpload(e)}/>
         <br/></div>} 
         
         <br/>
        <div style={{textAlign:"center"}}>
     
      <Button disabled={isLoading} type="reset" title={"Edit Add "}  variant="contained" onClick={()=>id.length>0?editApplicantDetails(id):addApplicant()}>{id.length>0?"Edit ":"Add "}</Button> &nbsp; &nbsp;
      <Button disabled={isLoading} title="Close" variant="contained" onClick={()=>handleClose()} > Close </Button> &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
     </div>

      </div>
      </Container> 
          </Typography>   </div>
       
        </Box>
      </Modal>
</>
  ) 
}

export default ApplicantDetails












