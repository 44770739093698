import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import {useNavigate} from "react-router-dom";

import '../Styles/NewTopBarMobUI.css'

const NewTopBarMobUI = () => {
	var navigate = useNavigate();
	const { user, setUser } = useContext(UserContext);

	const handlelogout = () =>{
		localStorage.clear();
		setUser(null);
		window.location.href = "/";
	  }

  return (
    <div>
      <div id="admin_top_nav_bar_rec">

	<a href="https://bizibees.in/" target="">
	<img id="admin_bizibeesjpg_img_mob" src="bizibeesjpg_img_app_can_mob.png" srcset="bizibeesjpg_img_app_can_mob.png 1x" />
       </a>
   </div>


<nav role="navigation">
	<div id="menuToggle">
	
	  <input type="checkbox" />
	
	  <span></span>
	  <span></span>
	  <span></span>
	
	  {user && (user.accesstype>=0 || user.accesstype==="c"||user.accesstype ==="r")?  	  <ul id="menu_admin">
		<li onClick={()=>navigate('/dashboard')}>Dashboard</li>
		
		{user && user.accesstype > 0 ?<li onClick={()=>navigate("/jobs")}>Vacancies</li>:""}
		{user && user.accesstype > 2 ?<li onClick={()=>navigate("/applicants")}>Applicants</li>:""}
		{user && user.accesstype > 0 ?<li onClick={()=>navigate('/interviewdetails')}>Internal HR</li>:""}
		{user && user.accesstype > 0 ?<li onClick={()=>navigate('/clientdetails')}>Client HR</li>:""}
		{user && user.accesstype > 2 ?<li onClick={()=>navigate('/applicantstatus')}>Rounds</li>:""}
		{user && user.accesstype >= 0 ?<li onClick={()=>navigate('/bookmarked')}>Bookmarks</li>:""}
		{user && user.accesstype == 5 ?<li onClick={()=>navigate('/recruiterstab')}>Recruiters</li>:""}
		{user && user.accesstype == 5 ?<li onClick={()=>navigate('/monitor')}>Monitor</li>:""}
		{user && user.accesstype > 2 ?<li onClick={()=>navigate('/setup')}>Setup</li>:""}
		
		<li onClick={()=>handlelogout()}>Log Out</li>
	
	  </ul>:""}
	</div>
  </nav>

    </div>
  )
}

export default NewTopBarMobUI
