import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import { ReactInternetSpeedMeter } from 'react-internet-meter'

import "./dashboard.css"
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from "@material-ui/styles";
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { UserContext } from './UserContext';
import { color } from '@mui/system';
import { Button} from '@mui/material';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import  {TextField,Container,Autocomplete} from '@mui/material';
import OnlinePredictionSharpIcon from '@mui/icons-material/OnlinePredictionSharp';
import BackupIcon from '@mui/icons-material/Backup';
import Timer from './Timer';
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import SearchIcon from '@mui/icons-material/Search';
import ApplicantPopup from './ApplicantPopup';
import { width } from '@material-ui/system';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Peer from 'simple-peer';
import { parse, set } from 'date-fns';
import { clear } from '@testing-library/user-event/dist/clear';
import {io} from 'socket.io-client';
import CallIcon from '@mui/icons-material/Call';
import EmployeeDashboardMob from './EmployeeDashboardMob';
import EmployeeDashboardPC from './EmployeeDashboardPC';
import EmployeeChats from './EmployeeChats';
import AdminDashboardMob from './MobUI/AdminDashboardMob';
import { SocketContext } from '../SocketContext';


const stopModalStyle = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const opentimer = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 500,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

const Login = {
  position: 'relative',
  margin:"auto",
  height: "1000px",
  width:"800px",

  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
};
const useStyles = makeStyles((theme) => ({
  video: {
    width: "350px",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        width: "250px",
      },
    },
  },
  gridContainer: {
    justifyContent: "center",
    "@media (max-width: 1440px)": {
      mobileMenuStyle: {
        flexDirection: "column",
      },
    },
  },
  paper: {
    padding: "10px",
    border: "2px solid black",
    margin: "0px",
  },
}));



const Dashboard = (props) => {
  
  const classes = useStyles();
  const {remoteVideo,getHrSocketId,leaveCall} = useContext(SocketContext);
//Socket
//const ioSocket = io(process.env.REACT_APP_API_URL);
// ioSocket.on('connection',(socket)=>{
 

// })  

    //useStates
     const [users,setUsers]=useState([]);
    const [status,setStatus]=useState();
    const [rowData,setRowData]=useState([]);
    const [progress, setProgress] = React.useState(0);
    const [fromDate, setFromDate] = useState(new Date().toISOString().substring(0,10));
    const [toDate, setToDate] = useState(new Date().toISOString().substring(0,10));
    const [ loadTime, setLoadTime] = useState(new Date());
    const [showOnlyHR,setShowOnlyHR]=useState(true);
    const [selectedRow,setSelectedRow] = useState();
    const { user, setUser} = useContext(UserContext);
    const [searchData, setSearchData] = useState("");
    const [openShedule, setOpenScedule ]= React.useState();
    const [openApplicant,setOpenApplicant] = useState();
    const [open,setOpen] = useState(false);
    const [data ,setData] = useState("mobile");
    const isCallingActive = useRef(false);
    const [stopComments,setStopComments] = useState("");
    const [openStopModel,setOpenStopModel] = useState(false);
    const [userStatusId,setUserStatusId]= useState("");
    const [result,setResult] = useState([]);
    const [openLoginHours, setLoginHours] = React.useState(false);
    const [loginDetails,setLoginDetails] = useState([]);
    const isLoading = useRef(true);
    const alertStart = useRef(new Date());
    const [empName,setEmpName] = useState("")
    const [empCallingTime,setCallingTime] = useState("")
    const [room,setRoom] = useState("")
    const [currentMessage,setCurrentMessage] = useState("")
    const [callFilterText,setCallFilterText] = useState("Graduate");
    const [filterCallsByEducation,setFilterCallsByEducation] = useState(false);
    const [showHRDasboard,setShowHRDasboard] = useState(false);
    const [messageList,setMessageList] = useState([]);
    const [wifiSpeed,setWifiSpeed] = useState(0)
    const longCallMsg = "I am on Call from long time!!";
    const longTimeIdleMsg = "I am idle since long time!";
   
    //const [stream,setStream] = useState(null);
    const [socketID,setSocketID] = useState("");
  const roomIdRef = useRef("");  
  const cols = useRef([]);  
  const audio = useRef();  
//const agentAudio = useRef(null)

    cols.current = [
       
        {field:'name', headerName: 'Name',width: 180, headerClassName: 'super-app-theme--header',renderCell: (params) => {
          return[<>
          
            {params.row.name?params.row.name:""}  
            {user.accesstype==5?
            <Button
                size="small"
                className="btn"
                title="Speaker"
                onClick={() => {
                 
                 if(params.row.isSpeakerOn===true )
                  {
                    // console.log(params.row.agentId+":SpeakerOff")
                    // console.log(roomIdRef.current+":SpeakerOff")
                    //ioSocket.emit("exit_audio_room",params.row.agentId)
                    //ioSocket.emit("exit_audio_room", params.row.agentId)
                    params.row.isSpeakerOn=false;
                    roomIdRef.current="";
                    leaveCall()
                  }
                  else
                  {
                    // console.log(params.row.agentId+":SpeakerOn")
                    // console.log(roomIdRef.current+":SpeakerOn")
                  //HearConversation(params.row.agentId); 
                    //ioSocket.emit("exit_audio_room",params.row.agentId)
                    //ioSocket.emit("join_audio_room",params.row.agentId)
                    params.row.isSpeakerOn=true;            
                    roomIdRef.current=(users.filter(rec=>rec.name===params.row.name)[0]||{_id:""})._id;
                    // console.log(params.row.agentId)
                    getHrSocketId((users.filter(rec=>rec.name===params.row.name)[0]||{_id:""})._id);
                   // hearUser("",(params.row.name ?params.row.name:""))
                  }
                }}
              >
                {params.row.isCallingActive!==true || (users.filter(rec=>rec.name===params.row.name)[0]||{_id:""})._id==="" ?"":params.row.isSpeakerOn===true?<VolumeUpIcon/>:<VolumeOffIcon/>}
            
              </Button>:""}
            
            </> ];},},
        {field:'currentCandidate', headerName: 'Candidate/Activity',width: 150, headerClassName: 'super-app-theme--header',renderCell: (params) => {
        return[<div style={{backgroundColor:params.row.isCallingActive===false?"red":"white",color:params.row.isCallingActive!==true?"white":params.row.currentCandidate?"black":"red",width:"100%"}}>
         { params.row.isCallingActive===false?<marquee>{params.row.userStatus}</marquee>:
            user.name === params.row.name||user.accesstype == 5?
            user.name === params.row.name && user.accesstype == 5?
           <button style={{border:"1px solid", padding:"2px 6px"}} onClick={()=>{showCandidate("new",params.row.name)}} >{"Verify Candidate"}</button>:
          !params.row.currentCandidate && user.accesstype == 5 ?"Idle":
          <button style={{border:"1px solid", padding:"2px 6px"}} onClick={()=>{ alertStart.current = new Date();setTimeout(()=>checkIdle(),300000);
            showCandidate(params.row.currentCandidate?params.row.interviewid:"new",params.row.name)}} >{params.row.currentCandidate?params.row.currentCandidate:"Call Candidate"}</button>:
          params.row.currentCandidate?params.row.currentCandidate:""
         } </div>];},},
        {field:'openTime', headerName: 'Call/Idle/Stopped',width: 120, headerClassName: 'super-app-theme--header',renderCell: (params) => {
           return[
            <div style={{backgroundColor:(params.row.isCallingActive === true && params.row.openTime>300)?"red":"white",color:(params.row.isCallingActive === true && params.row.openTime>300)?"white":(params.row.isCallingActive === true && params.row.openTime>210)?"red":"green",}}>
    {String(parseInt(params.row.openTime/60/60)).padStart(2, '0')}:{String(parseInt((params.row.openTime/60)%60)).padStart(2, '0')}:{String(params.row.openTime%60).padStart(2, '0')}
    {/* {params.row.isCallingActive === true && params.row.openTime>1200?setStopComments("Stopped due to inactivity!!"):""} 
     {params.row.isCallingActive === true && params.row.openTime>1200?stopCalling({_id:params.row.agentId,name:params.row.name}):""}  */}
    </div>
          ];
         },
       },  
        {field:'verified', headerName: 'Verified - ' +(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.verified,0),width: 90, headerClassName: 'super-app-theme--header',},
        {field:'verificationFailed', headerName: 'Ver Failed - '+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.verificationFailed,0),width: 100, headerClassName: 'super-app-theme--header',},
        {field:'verificationPending', headerName: 'Ver Pending - '+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.verificationPending.split('/')[0]),0)+"/"+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.verificationPending.split('/')[1]),0),width: 130, headerClassName: 'super-app-theme--header',},
        {field:'passed', headerName: 'Passed - '+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.passed,0),width: 90, headerClassName: 'super-app-theme--header',},
        {field:'failed', headerName: 'Failed - '+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.failed,0),width: 90, headerClassName: 'super-app-theme--header',},
        {field:'called', headerName: 'Interviews - '+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.called,0),width: 120, headerClassName: 'super-app-theme--header',},
        {field:'messaged', headerName: 'Messaged - '+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+curr.messaged,0),width: 120, headerClassName: 'super-app-theme--header',},
        {field:'loggedTime', headerName: 'Logged Time',width: 95, headerClassName: 'super-app-theme--header',renderCell: (params) => {
          return[<>
          
            {params.row.loggedTime ?params.row.loggedTime:0}
            </> ];},},
        {field:'calledTime', headerName: 'Calls Time',width: 80, headerClassName: 'super-app-theme--header',renderCell: (params) => {
          return[<>
          
            {params.row.calledTime ?params.row.calledTime:0}
            </> ];},},
        {field:'hrIdleTime', headerName: 'Idle Time',width: 80, headerClassName: 'super-app-theme--header',renderCell: (params) => {
          return[<div style={{width:"100%",backgroundColor:params.row.idleAlert ?"red":"white",color:params.row.idleAlert ?"white":"black",fontWeight:params.row.idleAlert ?"bold":"normal"}}>
          
            {params.row.hrIdleTime ?params.row.hrIdleTime:0}
            </div> ];},},
        {field:'longCalls', headerName: 'Long Calls',width: 80, headerClassName: 'super-app-theme--header',renderCell: (params) => {
          return[<div style={{width:"100%",backgroundColor:params.row.longCallsAlert ?"red":"white",color:params.row.longCallsAlert ?"white":"black",fontWeight:params.row.longCallsAlert ?"bold":"normal"}}>
          
            {params.row.longCalls ?params.row.longCalls:0}
            </div> ];},},
       
        {field:'pending', headerName: 'Available - '+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.pending.split('/')[0]),0)+'/'+(showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData).reduce((prv,curr)=>prv+parseInt(curr.pending.split('/')[1]),0),width: 160, headerClassName: 'super-app-theme--header', },
        {field:'id', headerName: 'id', hide: true},
    ];
    

    const cols1 = [
      {field:'id', headerName: 'id', hide: true },
      {field:'name', headerName: 'Name', hide: true },
      // {field:'starttime', headerName: 'Start Time',width: 100, renderCell: (params) =>{
      //   return[
      //     params.row.gender ?  params.row.gender.substring(0,1).toUpperCase()+ params.row.gender.substring(1).toLowerCase():""
      //   ];
      // },
      // },
      {width: 790, renderCell: (params) =>{
        return[  
          <div style={{ width:"100%",textAlign:"center"}}>
          <div style={{ borderColor:"blue",width:"100%",fontSize:"22px"}}>{params.row.stoptime?"Called for ":"Calling Since "} <font style={{ backgroundColor:"yellow",color:"red",width:"100%",fontSize:"22px",fontWeight:"bold"}}>{parseInt(((params.row.stoptime?new Date(params.row.stoptime):new Date()) - new Date(params.row.starttime))/3600000)} Hr {parseInt(((params.row.stoptime?new Date(params.row.stoptime):new Date()) - new Date(params.row.starttime))/60000)-parseInt(((params.row.stoptime?new Date(params.row.stoptime):new Date()) - new Date(params.row.starttime))/3600000)*60} Mn</font> from {params.row.starttime ? (new Date(params.row.starttime).toLocaleTimeString()):""}{params.row.stoptime?" to ":""}{params.row.stoptime ? (new Date(params.row.stoptime).toLocaleTimeString()):""}</div>
          <div style={{backgroundColor:"red", color:"white", width:"100%",fontSize:"22px"}}>{params.row.comments}</div></div>
        ];
      }, },
    
    ];

    // const [count, setCount] = useState(0);

  //useEffects
// useEffect(()=>{
//  if( call.isRecievedCall && !(callAccepted || callRejected))
//   {
//     streamCall();
//   }
// },[call.isRecievedCall])


  useEffect(() => {
   
    interviewStatus()
    LoadCurrentData()
    // clearInterval()
    // setInterval(() =>{ 
    // LoadCurrentData();
    
    // if(roomIdRef.current && roomIdRef.current!=="")
    // {     
    //   // ioSocket.emit("join_audio_room",roomIdRef.current)
    // }
    // },15000);
    if(openStopModel !== true)
    {
    setStopComments("")
    }
if(isCallingActive.current === true)
{
  navigator.mediaDevices.getUserMedia ({audio:true})
 .then((stm)=>{
  
alertStart.current = new Date()
// ioSocket.emit("create_audio_room",user._id)
setTimeout(()=>checkIdle(),300000);
props.showAlert("Calling Started!!","success")
 })
 .catch(err=>{
  alert("Your microphone is not accessible!")
  setStopComments("Stopped since microphone is not accessible!")
  // ioSocket.emit("delete_audio_room", user._id)
  stopCalling(user)
  props.showAlert("Calling is Stopped!!","success")
 })
}
else if(isCallingActive.current === false){
  // ioSocket.emit("delete_audio_room", user._id)
  props.showAlert("Calling is Stopped!!","success")
}
isLoading.current = false;
}, [isCallingActive.current])

useEffect(()=>{

result.map((res)=> {
  if(res.user_id===user._id){
    isCallingActive.current = Boolean(res.isCallingActive)  
  
  setUserStatusId(res.status_id)
}
}
  )

},[result])


useEffect(()=>{},[remoteVideo])
// //Socket UseEffect

// useEffect (()=>{
  
//   if(user.accesstype==="5"){
 
//   ioSocket.on("init_message",(data)=>{
//   // console.log(data)
//   setMessageList((list)=>data)
//   const element = document.getElementById("chatBody");
//     element.scrollTop = element.scrollHeight;
// })

// ioSocket.on("recieve_message",(data)=>{
//   // console.log(data)
//   setMessageList((list)=>[...list,data])
//   const element = document.getElementById("chatBody");
//     element.scrollTop = element.scrollHeight;
// })   
// }

// // if(user.accesstype==="5")
// // {
// //   ioSocket.on('audiostream', function(arrayBuffer) {
// //   //console.log(arrayBuffer)
// //   if(roomIdRef.current && roomIdRef.current!=="")
// //   {
// //     var blob = new Blob([arrayBuffer], { 'type' : 'audio/ogg; codecs=opus' });
// //     audio.current = document.createElement('audio');
// //     audio.current.src = window.URL.createObjectURL(blob);
// //     audio.current.play();
// //   }
// //   else{
// //     audio.current.stop();
// //   }
// //   });
// // }
// //  ioSocket.on("audio",(data)=>{
// //   navigator.mediaDevices.getUserMedia ({audio:true})
// //   .then((currentStream)=>{
// //   //stream.current = currentStream.getAudioTracks().forEach(track => track.enabled = true);
// //   var mediaRecorder = new MediaRecorder(currentStream);
// //   mediaRecorder.onstart = function(e) {
// //       this.chunks = [];
// //   };
// //   mediaRecorder.ondataavailable = function(e) {
// //       this.chunks.push(e.data);
// //       // if(isCallingActive.current === true)
// //       // {
// //       // ioSocket.emit("stream",{room:user._id,audio:e.data});
// //       // }
// //       // else
// //       // {
// //       //   mediaRecorder.stop();
// //       // }
// //   };
// //   mediaRecorder.onstop = function(e) {
// //     var blob = new Blob(this.chunks, { 'type' : 'audio/ogg; codecs=opus' });
// //   //  console.log(blob)
// //   //  ioSocket.emit("stream",{room:user._id,audio:blob});
// //    if(isCallingActive.current === true)
// //    {
// //    //mediaRecorder.start();
// //   //  setTimeout(function() {
// //   //   mediaRecorder.stop()
// //   //  }, 1000);
// //     } 
// //   };

// // // Start recording
// // //mediaRecorder.start();
// // // setTimeout(function() {
// // //  mediaRecorder.stop()
// // // }, 1000);
// // }).catch(
// // err=>console.log(err)
// // );

// // });
// },[ioSocket])



useEffect (()=>{
  
  getUsers()
  //joinRoom()
  return () => {
    clearInterval()
}
  },[])

useEffect (()=>{
  
 
  },[user])

  // useEffect (()=>{
 
  //   if(open === true)
  //   {  
  //     ioSocket.emit("send_message",{
  //     userName : user.name,
  //     message : openShedule? longCallMsg:longTimeIdleMsg,
  //     time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
  //   });
  // }
  //   },[open])

//sending messages from socket 

// const sendMessage = async ()=>{
 
//   if(currentMessage!=="")
//   { 
//     const messageData = {
//       userName : user.name,
//       message : currentMessage,
//       time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
//     };

//     ioSocket.emit("send_message",messageData);
//      //setMessageList((list)=>[...list,messageData]);
//      setCurrentMessage("");
//   }
// }


// //Join Room from socket 

// const joinRoom = ()=>{

//   ioSocket.emit("join_room",room)

// }


//get Chats status
const getAllChats = () =>{
      
  let apiURL=process.env.REACT_APP_API_URL + "/getchats"; 
  let apiParams = {
      method:"GET",
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        setMessageList((list)=>[...list,repos.data])
      }else props.showAlert(""+(repos.message || repos),"error"); 
  }) 
  .catch((err) =>props.showAlert((''+err),"error"));
}


  //Variables
  var checkIdle=()=>
  {
     //let date = ("Wed Nov 02 2022 18:57:25 GMT+0530 (India Standard Time)")
    
   if( (isCallingActive.current === true) && ((new Date() - alertStart.current) > 300000))
  {
    setOpen(true);
    setTimeout(()=>checkIdle(),30000)  
  }
  }
     

const addApplicants = () =>{
  setOpenApplicant(true)
}


const SearchCandidate = () =>{
  let apiURL="";
//   if(candidate === "new" && user.accesstype == 5)
// {
//    apiURL = process.env.REACT_APP_API_URL + "/interview/newverification/"+hr
// }
// else if(candidate === "new" && user.accesstype != 5)
// {
//    apiURL = process.env.REACT_APP_API_URL + "/interview/newinterview/"+hr
// }
// else
// {

     apiURL = process.env.REACT_APP_API_URL + "/interview/getinterviewbysearch/"+searchData;
// }
    let apiParams = {
      method: "GET",
      headers:{
        searchBy:data,
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             if (repos.data.length == 1){        
                const rowData = ([repos.data[0]])?.map((interview) => {
                let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
             
              //alert(JSON.stringify(interview))
                return{
              id: interview?._id,  //applicant id
              applicantid:interview?.applicantId, 
              jobid:interview?.jobId,  //interview id
              name: interview?.name,
              mobile: interview?.mobile,
              jobtitle:interview?.jobtitle,
              companyname:interview?.companyname,
              interviewername:interview?.interviewername,
              timespent:interview?.timeSpent,
              hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
              interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
              interviewtime:IntvwTime,
              interviewlocation:interview?.location,
              rounds:interview?.rounds,
              reference:interview?.reference,
              status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
              feedback:interview?.feedback,
              bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
              bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
              languages:interview?.languages,
              englishrating:interview?.englishRating,
              communicationrating:interview?.communicationRating,
              createdbyname:interview?.createdbyname,
              createdbyid:interview?.createdbyId,
                };
              });
              
               setSelectedRow(rowData[0])

               setOpenScedule(true)
            }else{
              window.location.href= "/interviewdetails?selectedCell=" +([data,user._id,searchData]);
            }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));

}

const showCandidate=(candidate,hr)=>{
  //setTimeout(alert("You are taking too long for the call!",30000))
  
  var filters =  localStorage.getItem("filters")
  let apiURL="";
if(candidate === "new" && user.accesstype == 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newverification/"+hr
}
else if(filterCallsByEducation===true && candidate === "new" && user.accesstype != 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr+"/"+callFilterText;
}
else if(candidate === "new" && user.accesstype != 5 )
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr
}
else
{
     apiURL = process.env.REACT_APP_API_URL + "/interview/getinterview/"+candidate;
}
    let apiParams = {
      method: "GET",
      headers:{
        filters:filters,
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              //  console.log(repos.data)
               const rowData = ([repos.data])?.map((interview) => {
                let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
              
              //alert(JSON.stringify(interview))
                return{
              id: interview?._id,  //applicant id
              applicantid:interview?.applicantId, 
              jobid:interview?.jobId,  //interview id
              name: interview?.name,
              mobile: interview?.mobile,
              jobtitle:interview?.jobtitle,
              companyname:interview?.companyname,
              interviewername:interview?.interviewername,
              timespent:interview?.timeSpent,
              hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
              interviewdate:interview?.interviewdate && (new Date(interview?.interviewdate)),
              interviewtime:IntvwTime,
              interviewlocation:interview?.location,
              rounds:interview?.rounds,
              reference:interview?.reference,
              status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
              feedback:interview?.feedback,
              bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
              bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
              languages:interview?.languages,
              englishrating:interview?.englishRating,
              communicationrating:interview?.communicationRating,
              createdbyname:interview?.createdbyname,
              createdbyid:interview?.createdbyId,
                };
              });
              
               setSelectedRow(rowData[0])

               setOpenScedule(true)
            }else props.showAlert("No data available for selected filters!","error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
}

const msgCandidate=(candidate,hr)=>{
  //setTimeout(alert("You are taking too long for the call!",30000))
  
  var filters =  localStorage.getItem("filters")
  let apiURL="";
if(candidate === "new" && user.accesstype == 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newverification/"+hr
}
else if(filterCallsByEducation===true && candidate === "new" && user.accesstype != 5)
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr+"/"+callFilterText;
}
else if(candidate === "new" && user.accesstype != 5 )
{
   apiURL = process.env.REACT_APP_API_URL + "/interview/newinterviewfilter/"+hr
}
else
{
     apiURL = process.env.REACT_APP_API_URL + "/interview/getinterview/"+candidate;
}
    let apiParams = {
      method: "GET",
      headers:{
        filters:filters,
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              //  console.log(repos.data)
               const rowData = ([repos.data])?.map((interview) => {
                let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
              
              //alert(JSON.stringify(interview))
                return{
              id: interview?._id,  //applicant id
              applicantid:interview?.applicantId, 
              jobid:interview?.jobId,  //interview id
              name: interview?.name,
              mobile: interview?.mobile,
              jobtitle:interview?.jobtitle,
              companyname:interview?.companyname,
              interviewername:interview?.interviewername,
              timespent:interview?.timeSpent,
              hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
              interviewdate:interview?.interviewdate && (new Date(interview?.interviewdate)),
              interviewtime:IntvwTime,
              interviewlocation:interview?.location,
              rounds:interview?.rounds,
              reference:interview?.reference,
              status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
              feedback:interview?.feedback,
              bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
              bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
              languages:interview?.languages,
              englishrating:interview?.englishRating,
              communicationrating:interview?.communicationRating,
              createdbyname:interview?.createdbyname,
              createdbyid:interview?.createdbyId,
                };
              });
              
              let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+rowData[0].mobile.trim().replace(' ','').substring(rowData[0].mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+rowData[0].name+",%0a%0aI am contacting you for a job vacancy (Work from Office). Please let me know the best time to call you.%0a%0aMy name is "+user.name+", I am from Bizi Bees Outsourcing Private Limited. You can call me on "+user.phone+" as well at your convenient time to discuss about the vacancy.%0a%0aPlease follow us on our LinkedIn page url below for latest updates.%0a%0ahttps://www.linkedin.com/company/bizi-bees-outsourcing";
              const newWindow = window.open(apiURL, '_blank')
              //  setSelectedRow(rowData[0])
              UpdateMessagedStatus(rowData[0].id)
              //  setOpenScedule(true)
            }else props.showAlert("No data available for selected filters!","error");  
          }) 
        .catch((err) => props.showAlert((''+err),"error"));

}
    //functions
    const getUsers = () =>{
        let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
        let apiParams = {
          method: "GET",
          headers: {
            authtoken: localStorage.getItem("loggedintoken")
          }
        };
        fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  
                    setUsers(repos.data)
                }else props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
        }

            function max_of_three(x, y, z,a,b,c,d,e) 
            {
                let max_val;
                if (x.length > y.length)
            {
                max_val = x;
            } 
            else
            {
                max_val = y;
            }
            if (z.length > max_val.length) 
            {
                max_val = z;
            }
            if (a.length > max_val.length) 
            {
                max_val = a;
            }
            if (b.length > max_val.length) 
            {
                max_val = b;
            }
            if (c.length > max_val.length) 
            {
                max_val = c;
            }
             if (d.length > max_val.length) 
            {
                max_val = d;
             }
             if (e.length > max_val.length) 
            {
                max_val = e;
             }
            return max_val;
            }

    //View Data 
    const viewData = (row) =>{

    window.location.href= "/interviewdetails?selectedCell=" + row;
    }
  

    function LinearProgressWithLabel(props) {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%', mr: 1 }}>
              <LinearProgress variant="determinate" {...props} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
              <Typography variant="body2" color="text.secondary" >{`${Math.round(
                props.value,
              )}%`}</Typography>
            </Box>
          </Box>
        );
      }
      
      LinearProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate and buffer variants.
         * Value between 0 and 100.
         */
        value: PropTypes.number.isRequired,
      };
   

//get interview status
      const interviewStatus = () =>{
      
            let apiURL=process.env.REACT_APP_API_URL + "/interview/status"; 
            let apiParams = {
                method:"GET",
                headers:{
                  authtoken: localStorage.getItem("loggedintoken")
                },
            };
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
            let percentage=(repos.data/50)*100
                setStatus(percentage)
                 
                }else 
                props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
      }


      const UpdateMessagedStatus = (intwID)=>{
        let apiURL=process.env.REACT_APP_API_URL + "/interview/messagedStatus/"+intwID; 
        let apiParams = {
            method:"POST",
            headers:{
              updatedbyid:user._id,
              updatedbyname:user.name,
              authtoken: localStorage.getItem("loggedintoken")
            }
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
       
            }else 
            props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }

//Open alert popup
      const handleClose = () =>{
      
        setOpen(false);
        
      }

//get interview status
      const getLoginDetails = (id) =>{
      
            let apiURL=process.env.REACT_APP_API_URL + "/usercallingstatus/logindetails/"+id; 
            let apiParams = {
                method:"GET",
                headers:{
                  authtoken: localStorage.getItem("loggedintoken")
                }
            };
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  // console.log(repos.data);
                  setLoginDetails(repos.data);
                }else props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
      }

      
      
     //Search Data By  date
      const LoadPreviousData = () =>{
        let apiParams;
          apiParams = {
            method: "GET",
            headers:{
              fromdate:fromDate,
              todate:toDate,
              authtoken: localStorage.getItem("loggedintoken")
            
            }
          };
        
        let apiURL = process.env.REACT_APP_API_URL + "/dashboard/searchbydate/"
       
       fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  console.clear()
                  //  console.log(repos.data)
                  var passed = repos.data.passed;
                    var failed = repos.data.failed;
                    var messaged = repos.data.messaged;
                    var pending = repos.data.pending;
                    var applied = repos.data.applied;
                    var verified = repos.data.verified;
                    var verificationFailed = repos.data.verificationFailed;
                    var verificationPending = repos.data.verificationPending;
                    var verificationAvailable = repos.data.verificationAvailable;
                    var currentCandidate = repos.data.currentCandidate;
                    var idleTimeRec = repos.data.idleTime;
                    let namesCol = max_of_three(passed,failed,pending,verified,verificationFailed,verificationPending,currentCandidate,idleTimeRec)
                   console.log(namesCol)
                    setRowData(namesCol.map((name,id)=>{
                    let idleTime = Math.abs(new Date().getTime() - new Date((idleTimeRec.filter(rec=>rec._id===name._id)[0]||{closedOn:new Date().toISOString()}).closedOn).getTime())/1000;
                    let openTime =(Math.abs(new Date().getTime() - new Date((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{openedOn:new Date().toISOString()}).openedOn).getTime())/1000) + parseInt((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{timeSpent:0}).timeSpent);
                    openTime=parseInt(openTime)>0?parseInt(openTime):parseInt(idleTime);
                    return {id,
                        name:name._id,
                        verified:(verified.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        verificationFailed:(verificationFailed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        verificationPending:(verificationAvailable.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(verificationPending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        passed:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        messaged:(messaged.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        failed:(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        called:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count+(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        pending:(applied.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(pending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                        currentCandidate:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""}).name,
                        interviewid:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""})._id,
                        //openTime:openTime==0?"":String(parseInt(openTime/60/60)).padStart(2, '0')+":"+String(parseInt(openTime/60)).padStart(2, '0')+":"+String(parseInt(openTime%60)).padStart(2, '0'),
                        //idleTime:parseInt(idleTime),
                        openTime:openTime,
                      }})
                      //.sort((a,b)=>a.verified-b.verified)
                      )
                      //console.log(rowData)
                    setLoadTime(new Date())
                }else props.showAlert(repos.message || repos,"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));   
        }

        const LoadCurrentData = () =>{
          isLoading.current = true;
          
          if(fromDate === new Date().toISOString().substring(0,10) && toDate===new Date().toISOString().substring(0,10))
{
          let apiParams
            apiParams = {
              method: "GET",
              headers:{
                fromdate:new Date(),
                todate:new Date(),
                authtoken: localStorage.getItem("loggedintoken")
              }
            };
          
          let apiURL = process.env.REACT_APP_API_URL + "/dashboard/searchbydate/"
         
         fetch(apiURL, apiParams)
              .then((res)=> (res.status===200 ? res.json() : res.text()))
              .then(async (repos)=> {
                  if (repos.data) {
                  
                    
                    setResult(repos.data.userStatus)
                    
                      var passed = repos.data.passed;
                      var messaged = repos.data.messaged;
                      var failed = repos.data.failed;
                      var pending = repos.data.pending;
                      var applied = repos.data.applied;
                   
                      var verificationAvailable = repos.data.verificationAvailable;
                      var verified = repos.data.verified;
                      var verificationFailed = repos.data.verificationFailed;
                      var verificationPending = repos.data.verificationPending;
                      var currentCandidate = repos.data.currentCandidate;
                      var userStatus = repos.data.userStatus;
                      var loggedTime = repos.data.loggedinTime;
                      var calledTime = repos.data.callingTime;
                      var idleTimeRec = repos.data.idleTime;
                      var longCalls = repos.data.longCalls;
                      //let namesCol = max_of_three(passed,failed,pending,verified,verificationFailed,verificationPending,currentCandidate,idleTimeRec)
                      let namesCol = repos.data.users;
                      let newData = namesCol.map((name,id)=>{
                      let idleTime = Math.abs(new Date(new Date().toISOString()).getTime() - new Date((idleTimeRec.filter(rec=>rec._id===name._id)[0]||{closedOn:new Date().toISOString()}).closedOn).getTime())/1000;
                      idleTime = parseInt(idleTime);
                      let openTime =(Math.abs(new Date(new Date().toISOString()).getTime() - new Date((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{openedOn:new Date().toISOString()}).openedOn).getTime())/1000) + parseInt(((currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{timeSpent:0}).timeSpent));
                      openTime =  parseInt(openTime)>0 ?parseInt(openTime): parseInt(idleTime)
                      
                      let logTimeValue =(loggedTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;
                      //console.log(loggedTime)
                      let agentId = (users.filter(rec=>rec.name===name._id)[0]||{_id:""})._id;
                      let newLogTimeValue = parseInt(logTimeValue * 0.65);
                      //console.log(parseInt(newLogTimeValue))

                      let calTimeValue = (calledTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;
                      calTimeValue = parseInt(calTimeValue)
                      //console.log(calTimeValue)  

                      let callVol = ((passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count+(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count);
                      let newCalVol = parseInt(callVol * 0.15)
                     

                      let longCallVol = (longCalls||[].filter(rec=>rec.name===name._id)[0]||{name:""}).count;
                       longCallVol = parseInt(longCallVol)
                      
                      //Loggedin Time
                      let time = (loggedTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;

                      let num = (Math.round(time)*100)/100;
                      let hours = (num/60);
                      let rhours = Math.floor(num/60);
                      let minutes = ( hours-rhours)*60;
                      let rminutes = Math.round(minutes);
                     
                      //Called Time
                      let cTime = (calledTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime;
                      let cNum = (Math.round(cTime)*100)/100;
                      let cHours = (cNum/60);
                      let cRhours = Math.floor(cNum/60);
                      let cMinutes = ( cHours-cRhours)*60;
                      let cRminutes = Math.round(cMinutes);
                     
                      //Idle Time
                      let iTime = parseInt(time) - parseInt(cTime);
                      //console.log(name,parseInt(time))
                      //console.log(name,parseInt(cTime))

                     // console.log(name,iTime)
                      let iNum = (Math.round(iTime)*100)/100;
                      let iHours = (iNum/60);
                      let iRhours = Math.floor(iHours/60);
                      let iMinutes = ( iHours-iRhours)*60;
                      let iRminutes = Math.round(iMinutes);
                     
                      let loginid =(loggedTime.filter((req)=>req._id.name ===name._id)).map(r=>r._id.id)[0];
// let idleAlert = (parseInt(repos.data.loggedinTime) * 0.7)>parseInt(repos.data.callingTime);
// let longCallsAlert = ((parseInt(repos.data.passed)+parseInt(repos.data.failed)) * 0.7)>parseInt(repos.data.longCalls);

                      return {id,

                          name:name._id,
                          verified:(verified.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          verificationFailed:(verificationFailed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          verificationPending:(verificationAvailable.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(verificationPending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          passed:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          messaged:(messaged.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          failed:(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          called:(passed.filter(rec=>rec._id===name._id)[0]||{count:0}).count+(failed.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          pending:(applied.filter(rec=>rec._id===name._id)[0]||{count:0}).count+"/"+(pending.filter(rec=>rec._id===name._id)[0]||{count:0}).count,
                          currentCandidate:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""}).name,
                          userStatus:(userStatus.filter(rec=>rec.name===name._id)[0]||{name:""}).message,
                          longCalls:(longCalls||[].filter(rec=>rec.name===name._id)[0]||{name:""}).count,
                          idleAlert : (newLogTimeValue > calTimeValue),
                          longCallsAlert :(newCalVol < longCallVol),
                          //calledTime:(calledTime.filter(rec=>rec._id.name===name._id)[0]||{name:""}).TotalTime,
                          loggedTime:("00" +(rhours?rhours:0)).slice(-2)+":"+("00" +(rminutes?rminutes:0)).slice(-2)+":00",
                          calledTime:("00" +(cRhours?cRhours:0)).slice(-2)+":"+("00" +(cRminutes?cRminutes:0)).slice(-2)+":00",
                          hrIdleTime:("00" +(iTime>60?parseInt(iTime/60):0)).slice(-2)+":"+("00" +(iTime>=1?parseInt(iTime-(iTime>60?parseInt(iTime/60):0)*60):0)).slice(-2)+":00",//+iTime>0?(iTime-(iTime>60?iTime/60:0)*60):0,
                          loginId:loginid,
                          agentId:agentId,
                          
                          isCallingActive:Boolean((userStatus.filter(rec=>rec.name===name._id)[0]||{name:""}).isCallingActive),
                          interviewid:(currentCandidate.filter(rec=>rec.createdbyname===name._id)[0]||{_id:""})._id,

                          //openTime:openTime==0?"":String(parseInt(openTime/60/60)).padStart(2, '0')+":"+String(parseInt(openTime/60)).padStart(2, '0')+":"+String(parseInt(openTime%60)).padStart(2, '0'),
                           //idleTime:parseInt(idleTime),
                          idleTime:idleTime, 
                          openTime:openTime,
                          isSpeakerOn:((users.filter(rec=>rec.name===name._id)[0]||{_id:null})._id === roomIdRef.current),
    
                         }})
                         
                        //.sort((a,b)=>a.verified-b.verified);
                    
                        setRowData(newData)
                       
                        //console.log(rowData.longCallsAlert)
                        //console.log(rowData.filter((x)=>x.name===user.name?((x.openTime/60)%60)>60?alert("hi"):"":""))
                      setLoadTime(new Date())
                  }else props.showAlert(repos.message || repos,"error");  
              }) 
              .catch((err) => props.showAlert((''+err),"error"));         
          }
          isLoading.current = false;
        }

        //Live Button  
        const Refresh = async() =>{
          setFromDate(new Date().toISOString().substring(0,10))
          setToDate(new Date().toISOString().substring(0,10))
          
          LoadCurrentData()

        }
   
        function arrayBufferToBase64(buffer) {
          var binary = "";
          var bytes = [].slice.call(new Uint8Array(buffer));
          bytes.forEach((b) => (binary += String.fromCharCode(b)));
          var base64Flag = "data:image/jpeg;base64,";
          return base64Flag + window.btoa(binary);
        }
      
        
        const UpdateCallingStatus = () =>{
        if(isLoading.current === true)
        {
          return;
        }
        isLoading.current = true;
          // setIsCallingActive(!isCallingActive)
           if(isCallingActive.current !== true){
            startCalling()
          }else{
            stopCalling(user)
            
          }
          isLoading.current = false;
        }

        const startCalling=()=>{
          let apiURL=process.env.REACT_APP_API_URL + "/usercallingstatus/userstarttime";
            let apiParams = {
                method:"POST",  
                headers:{
                  iscallingactive:true,
                  userid:user._id,
                  username:user.name,
                  authtoken: localStorage.getItem("loggedintoken")
                  
                },
               
            };
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                setUserStatusId(repos.data._id)
                 isCallingActive.current=true;
                //joinAudioRoom()
                
                }else {props.showAlert(""+(repos.message || repos),"error");}
            }) 
            .catch((err) => {props.showAlert((''+err),"error");});
            
        }

        const stopCalling = (activeUser)=>{
          if(activeUser){
       
          setOpenStopModel(false)
          isCallingActive.current=false;
          let apiURL=process.env.REACT_APP_API_URL + "/usercallingstatus/userstoptime/"+userStatusId;
          let apiParams = {
              method:"POST",
              headers:{
                iscallingactive:false,
                userid:activeUser._id,
                username:activeUser.name,
                stopcomments:encodeURIComponent(stopComments),
                authtoken: localStorage.getItem("loggedintoken")
              },
             
          };
          fetch(apiURL, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
               //socket.emit("exit_audio_room",user._id)
             
              
              }else 
              {props.showAlert(""+(repos.message || repos),"error");}
          }) 
          .catch((err) => {props.showAlert((''+err),"error");});
          
              
        }
      }
        const setCloseStopModel = ()=>{
          setOpenStopModel(false);
        }
          
        const closeStopModel = () => setOpenStopModel(false);

        const OpenStopModal = ()=>{
          setOpenStopModel(true);
        }

        const handleCloseLoginHours = () => setLoginHours(false)
    
        const OpenLoginhoursGrid = (id,name,time)=>{
          
          setEmpName(name)
          setCallingTime(time)
          setLoginHours(true)
          getLoginDetails(id)
        }


        const selectFile = (e)=>{
          //setCurrentMessage(e.target.files[0].name);
            var file = e.target.files[0]; //the file
            var reader = new FileReader(); //this for convert to Base64
            reader.readAsDataURL(e.target.files[0]); //start conversion...
            reader.onload = function (e) {
              //.. once finished..
              var rawLog = reader.result.split(",")[1]; //extract only thee file data part
              var dataSend = {
                dataReq: { data: rawLog, name:file.name, type: file.type },
                fname: "uploadFilesToGoogleDrive",
              }; //preapre info to send to API
              fetch(
                process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
                { method: "POST", body: JSON.stringify(dataSend) }
              ) //send to Api
                .then((res) => res.json())
                .then((data) => {
                  // console.log(data.url)
                  // ioSocket.emit("send_message",{
                  //   userName : user.name,
                  //   message : "href:"+file.name+"@&@"+data.url+"</a>",
                  //   time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
                  // });
                  props.showAlert("Resume Uploaded Successfully", "success");
                })
                .catch((e) => props.showAlert((e),"error")); // Or Error 
            };
          };

//Send Whatsapp to Candidate
const sendWhatsappToCandidate = ()=>{
  // rowData.filter(x=> users.filter(user=> user.name === x.name)[0]
}
         
  


      
//Gird for Login Hours
        const rowData1 = (loginDetails)?.map((emp) => {

          return{
            id:emp?._id,
            name:emp?.userName,
            starttime:emp?.startTime,
            stoptime:emp?.stopTime,
            comments:emp?.stopComments,
          
          };
         
          });






  return (
  <div className={!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?'dashboard_pc_module':'dashboard_mob_module'}>

{(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && user.accesstype > 1) ? 
<div style={{textAlign:"center"}} >

   {user.accesstype > 1 ?
    <>Loaded Time: {loadTime.toLocaleTimeString()} <OnlinePredictionSharpIcon style={{color:"red"}}/><Button size='small' variant="contained" style={{color:"white",backgroundColor:"red"}} title="Live" onClick={()=>Refresh()}>Live</Button>
  </>:""}
    {/* History: 
   
    <Button size='small' variant="contained" style={{marginLeft:"10px"}} title="Load" onClick={()=>LoadPreviousData()}>Load</Button> */}
   {user.accesstype > 1? <> &nbsp;<input type="checkbox" checked={showOnlyHR} onChange={()=>setShowOnlyHR(!showOnlyHR)} />&nbsp;Show only HR</>:""}
    </div>:""}

    {(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && user.accesstype > 1)?
     <div style={{textAlign:"center"}}>
   
    {/* <label for="gsearch">Search:</label>&nbsp; */}
    <div>
    <Button size='small' variant="contained"  title="Add Applicant" style={{textAlign:"center"}} onClick={()=>addApplicants()}><PersonAddIcon/></Button>   &nbsp;
 {!isLoading.current?<Button size='small' variant="contained"  title={(isCallingActive.current === true?"Stop ":"Start ")+"Calling"} style={{textAlign:"center"}} onClick={()=>{
           isCallingActive.current!==true ?UpdateCallingStatus():OpenStopModal()}}>{(isCallingActive.current===true?<StopCircleIcon variant="contained" />:<PlayCircleIcon/>)}</Button>:""}&nbsp;
    <select name="Search" id="search" className="dropdown" value={data} onChange={(e)=>setData(e.target.value)}>
  <option value="history">History</option>
  <option value="mobile">Mobile</option>
  <option value="name">Name</option>
  <option value="feedback">Feedback</option>
  <option value="language">Language</option>
  <option value="area">Location</option>
  <option value="education">Education</option>
 
  {/* <option value="bookmark">Bookmarked</option> */}
</select> &nbsp;
{data!=='history' ?
   <input type="search"  placeholder='Search' id="gsearch" className="inputfield" name="gsearch" value={searchData}  onChange={(e)=>setSearchData(e.target.value)}/>
   :<><input type="date" className="inputfield"  value={fromDate}
      // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
         onChange={(value) => {
          setFromDate(value.target.value);
        }}/>&nbsp;
        <input type="date" className="inputfield"  value={toDate}
       // max={new Date().getFullYear()+"-"+(new Date().getMonth()+1).toString().padStart(2, '0')+"-"+new Date().getDay().toString().padStart(2, '0')}
        onChange={(value) => {
          setToDate(value.target.value);
        }}/></>}
&nbsp;
   <Button size='small' variant="contained" title="search" onClick={()=>data!=='history'?SearchCandidate():LoadPreviousData()}><SearchIcon/></Button> 
   {/* <ReactInternetSpeedMeter  
            txtSubHeading="Internet is too slow"
            outputType="empty"
            customClassName={null}
            txtMainHeading="Opps..." 
            pingInterval={15000} // milliseconds 
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
            threshold={10}
            imageUrl="/bizibees.jpeg"
            downloadSize="18800"  //bytes
            callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is down ${speed}`)}
            callbackFunctionOnNetworkTest={(speed)=>setWifiSpeed(speed)}

          /> */}
   </div>
  
 
    </div>:""}

   {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) && user.accesstype > 1 ? showHRDasboard==="Employee"?<EmployeeDashboardMob showAlert={props.showAlert} rowData={rowData} showHr={(e)=>setShowHRDasboard(e)}/> : <AdminDashboardMob showAlert={props.showAlert} showHr={(e)=>setShowHRDasboard(e)}/>: 
    <Box
      sx={{
        height: 300,
        '& .super-app-theme--header': {
          backgroundColor: 'cornflowerblue',
          color:"white",
        },
      }}
    >
     <div style={{display:"block",textAlign:"center"}}>

     {/* <div style={{zIndex:"10",position:"relative",color:wifiSpeed<10?"red":"lightblue"}}>Internet speed: {wifiSpeed} mb/s</div>  */}

   <div style={{width:'80%',textAlign:"-webkit-center",verticalAlign:"top",display:"inline-block"}}>
  
   {user.accesstype > 1?

      <DataGrid 
      style={{height:750}}
       initialState={{
        sorting: {
        sortModel: [{ field: 'name', sort: 'asc' }],
    },
  }}
      rows={showOnlyHR?rowData.filter(x=> users.filter(user=> user.name === x.name)[0]  ) :rowData}
      columns={cols.current} 
        
        onCellClick={(cell)=>
          (cell.field!="currentCandidate" && cell.field!="openTime" && cell.field!="pending" && cell.field!="loggedTime"  && cell.field!="calledTime"  && cell.field!="hrIdleTime" && cell.field!="longCalls" && cell.field!="name" && cell.row.name == user.name) || (user.accesstype == 5  && cell.field!="currentCandidate" && cell.field!="openTime" && cell.field!="loggedTime" && cell.field!="calledTime"  && cell.field!="hrIdleTime" && cell.field!="longCalls" && cell.field!="name")  ?
          viewData(([cell.field,user._id , cell.row.name,fromDate,toDate ])):cell.field=="loggedTime"?OpenLoginhoursGrid(cell.row.loginId,cell.row.name,cell.row.loggedTime):null
          }
          />
        :
<>
{(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?
      <EmployeeDashboardMob showAlert={props.showAlert} rowData={rowData} /> :
      <EmployeeDashboardMob showAlert={props.showAlert} rowData={rowData} />
   }
</>
  
        
   

   }
  </div>
   {user.accesstype==5 ?!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?  <div style={{width:"360px",display:"inline-block",verticalAlign:"top",textAlign:"center"}}>
    <div className='chat-window'>
    {user.accesstype == 5 &&
 <video
                id="user-video"
                playsInline
                ref={remoteVideo}
                autoPlay
                className={classes.video}
                controls
              />}
     <div id="chatBody" style={{height:500,width:"360px",border:"2px solid black",overflowY:"scroll"}} className='chat-body'>
     {messageList.map((content)=>{
     return (
      <div className='message' id={user.name === content.userName ? 'you':'other'}>
      <div className='message-body'>
      <div className='message-content' style={{border:(((new Date()-new Date(new Date(new Date().setHours(parseInt(content.time.split(':')[0]))).setMinutes(parseInt(content.time.split(':')[1]))))/60000<2)?"2px solid red":"0px")}}>
        <p id={user.name === content.userName ? 'you':'other'}>{content.message.substring(0,5)==="href:"?<a  href={content.message.split('@&@')[1]} target="_blank"  style={{color:"white",fontWeight:"bold"}}>{content.message.split('@&@')[0].split(':')[1]}</a>:content.message}</p>
      </div>
      <div className='message-meta'>
      <p id={user.name === content.userName ? 'you':'other'}>{content.time}</p>
      <p id='author' style={{backgroundColor:(content.message===longCallMsg||content.message===longTimeIdleMsg?"red":"white"),color:(content.message===longCallMsg||content.message===longTimeIdleMsg?"white":"black")}}>{content.userName}</p>
      </div>
      </div>
      </div>
     );

     })
     }
    </div>
    </div>
    <div >
{/* 
     <input className='container inputfield' type="text" style={{width:"auto",marginRight:"5px"}}  placeholder="Enter the Text Here!" onKeyPress={(event)=>{event.key==="Enter" && sendMessage() }} value={currentMessage} onChange={(e)=>setCurrentMessage(e.target.value)}/>
   <input type="button" value="Send" className="inputfield" style={{fontSize:"large"}} onClick={()=>sendMessage()}/> */}
    <AttachFileIcon
              style={{ marginLeft: "2px",marginRight: "3px", color: "cornflowerblue" }}
              onClick={(e) =>
                document
                  .getElementById("candidateResume"+user._id)
                  .click()
              }
            />
            <input
              id={"candidateResume"+user._id}
              type="file"
              placeholder="Upload Resume"
              style={{ display: "none" }}
              name="Resume"
              onChange={(e) =>{
                selectFile(e)
              }}
            ></input>
    </div>
     </div>:"":""}
     {/* <EmployeeChats /> */}
 </div>
 </Box>}

 <Modal
        open={openStopModel}
        onClose={closeStopModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
          <Box sx={stopModalStyle}>
         
         <Typography id="modal-modal-description">
         <Container style={{padding:"0px"}}>
         <div className = "content6">
       <div style={{alignItems:"center"}}>
         <h2 className='fnt2' style={{left:0,top:0}}><b>Enter Stop Reason</b></h2>
         <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"white",backgroundColor:"red" }} onClick={()=>setCloseStopModel()} ><CloseIcon /></Button>   
         </div>
         <br/>
       <TextField  id="stopReason" multiline rows={3}  sx={{ m: 1, width: '35ch' }} size="small" label="Enter Stop Reason" variant="outlined"  value={stopComments} onChange={(e)=> {
       setStopComments(e.target.value);   
       
         }}/>
        
         <br/>
         
    <Button  variant="contained" sx={{ m: 1, width: '35ch',backgroundColor :"#14c871" }} onClick={()=>{;stopComments.trim().length>0 ? UpdateCallingStatus():props.showAlert("Please Enter Stop Reason!!!","error");}}>Submit</Button>   
      </div>
      </Container> 
          </Typography>
        </Box>
      </Modal>

      {/* Grid for login Hours */}
      <Modal
        open={openLoginHours}
        onClose={handleCloseLoginHours}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={Login}>

        <div className = "content2">
        <div style={{alignItems:"center"}}>
        <h2 className='fnt2' style={{left:0,top:0}}><b>{empName} - {empCallingTime}</b></h2>
        <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"white",backgroundColor:"red" }} onClick={()=>setLoginHours(false)} ><CloseIcon /></Button>   
        </div>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
         
          <div style={{height:1000,width:"100%"}}>
        
      <DataGrid rows={rowData1} columns={cols1} rowHeight={65} height={1000} components={{
     Header: () => null,Footer: () => null,
   }}/>
      </div>  
          </Typography>
          </div>
          </Box>
      </Modal>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
      
        <Box sx={opentimer}>
        <div>
          <Typography id="modal-modal-description">
          <Container style={{padding:"0px"}}>
          <div className = "timeout">
          
        <div>
          {/* <h4  className="fnt"><b>{id.length>0?name:"USER DETAILS"}</b> <Button disabled={isLoading} title="Close" variant="Standard"  onClick={()=>handleClose()} ><CloseIcon /></Button></h4>  */}
          <h4 className="fnt2"><b>Warning!!!!</b>
        
        <Button title="Close" size="small" variant="Standard" sx={{ bgcolor: "red",float:"right",right:0,top:0,position:"absolute",height:33,width:5 }} onClick={()=>handleClose()} > <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
       </h4>
         </div>
         <br/>
         <div style={{textAlign:"center",margin:"2px"}}>
        <table>
       
      <tr>
      <td>
      <div>
      {
      openShedule?
      "You are taking too long time on the Call. Wrap up the call soon!!":"You are Idle since too long Time. Start Calling!!"
      }
      </div>
     </td>
     </tr>

    
      </table>
      </div>
      <br/>
      
        <div style={{textAlign:"center",marginBottom:"5px"}}>
     
      <Button type="button" title={"OK"}  variant="contained" onClick={()=>handleClose()}>OK</Button>
     
     </div>

      </div>
      </Container> 
          </Typography>  
           </div>
        </Box>
      </Modal>
 {/* <ScheduleInterview open={openShedule} showAlert={props.showAlert}  interview={selectedRow} close={(openNext)=>{LoadCurrentData();setOpenScedule(false);alertStart.current = new Date();setTimeout(checkIdle,300000);if(openNext===true)showCandidate("new",user.name)}} /> */}
 <ScheduleInterviewUI open={openShedule} showAlert={props.showAlert}  interview={selectedRow} close={(openNext)=>{LoadCurrentData();setOpenScedule(false);alertStart.current = new Date();setTimeout(checkIdle,300000);if(openNext===true)showCandidate("new",user.name)}} />

 <ApplicantPopup open={openApplicant} showAlert={props.showAlert}  close={()=>{setOpenApplicant(false);}} />
  </div>
  )
}

export default Dashboard