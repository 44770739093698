import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import {useNavigate} from "react-router-dom";
import '../Styles/TermsandConditions.css'
import CandidateProfileTopBar from './CandidateProfileTopBar'
import RecruiterProfileTopBar from "./RecruiterProfileTopBar";

const TermsandConditions = () => {
  var navigate = useNavigate();
    const { user, setUser } = useContext(UserContext);

  return (
    <div>
   {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))? "":user && user.accesstype === "c" ?<CandidateProfileTopBar/>:user && user.accesstype === "r" ? <RecruiterProfileTopBar /> :""}
      <div id="terms_and_conditions">
	<div id="Terms_and_conditions_These_ter">
		<span>Terms and conditions</span>
        <br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>These terms and conditions ("Terms", "Agreement") are an agreement between Mobile Application Developer ("Mobile Application Developer", <br/>"Us", "we" or "our") and you ("User", "you" or "your"). This Agreement sets forth the general terms and conditions of your use of the<br/> Bizi Bees Outsourcing Private Limited Web Portal, Mobile application and any of its products or services (collectively, "Web Portal”,<br/> “Mobile Application" or "Services").
        <br/>
        </span>
        <br/>
        <span>Accounts and membership</span>
        <br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>
        If you create an account in the Mobile Application, you are responsible for maintaining the security of your account and you are fully responsible <br/>For all activities that occur under the account and any other actions taken in connection with it. We may monitor and review new accounts before <br/>You may sign in and use our Services. Providing false contact information of any kind may result in the termination of your account. You must <br/>Immediately notify us of any unauthorized uses of your account or any other breaches of security. We will not be liable for any acts or omissions <br/>By you, including any damages of any kind incurred as a result of such acts or omissions. We may suspend, disable, or delete your account (or <br/>Any part thereof) if we determine that you have violated any provision of this Agreement or that your conduct or content would tend to damage <br/>Our reputation and goodwill. If we delete your account for the foregoing reasons, you may not re-register for our Services. We may block your <br/>Email address and Internet protocol address to prevent further registration.<br/></span>
        <br/><span>User content</span>
        <br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>
        We do not own any data, information or material ("Content") that you submit in the Mobile Application in the course of using the Service. You <br/>Shall have sole responsibility for the accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right <br/>To use of all submitted Content. We may monitor and review Content in the Mobile Application submitted or created using our Services by you. <br/>Unless specifically permitted by you, your use of the Mobile Application does not grant us the license to use, reproduce, adapt, modify, and publish <br/>Or distribute the Content created by you or stored in your user account for commercial, marketing or any similar purpose. But you grant us <br/>permission to access, copy, distribute, store, transmit, reformat, display and perform the Content of your user account solely as required for the <br/>Purpose of providing the Services to you. Without limiting any of those representations or warranties, we have the right, though not the <br/>Obligation, to, in our own sole discretion, refuse or remove any Content that, in our reasonable opinion, violates any of our policies or is in any <br/>Way harmful or objectionable.<br/></span><br/><span>Backups
        </span>
        <br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>
        We perform regular backups of the Content, however, these backups are for our own administrative purposes only and are in no way <br/>Guaranteed. You are responsible for maintaining your own backups of your data. We do not provide any sort of compensation for lost or <br/>Incomplete data in the event that backups do not function properly. We will do our best to ensure complete and accurate backups, but assume <br/>No responsibility for this duty.<br/></span><br/><span>Links to other mobile applications</span><br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>
        Although this Mobile Application may link to other mobile applications, we are not, directly or indirectly, implying any approval, association, <br/>Sponsorship, endorsement, or affiliation with any linked mobile application, unless specifically stated herein. We are not responsible for <br/>Examining or evaluating, and we do not warrant the offerings of, any businesses or individuals or the content of their mobile applications. We do <br/>Not assume any responsibility or liability for the actions, products, services, and content of any other third-parties. You should carefully review <br/>The legal statements and other conditions of use of any mobile application which you access through a link from this Mobile Application. Your <br/>Linking to any other off-site mobile applications is at your own risk.<br/></span><br/><span>Intellectual property rights</span><br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>

        This Agreement does not transfer to you any intellectual property owned by Mobile Application Developer or third-parties, and all rights, titles, <br/>And interests in and to such property will remain (as between the parties) solely with Mobile Application Developer. All trademarks, service <br/>Marks, graphics and logos used in connection with our Mobile Application or Services, are trademarks or registered trademarks of Mobile <br/>Application Developer or Mobile Application Developer licensors. Other trademarks, service marks, graphics and logos used in connection with <br/>Our Mobile Application or Services may be the trademarks of other third-parties. Your use of our Mobile Application and Services grants you no <br/>Right or license to reproduce or otherwise use any Mobile Application Developer or third-party trademarks.<br/></span><br/><span>Changes and amendments</span><br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>

        We reserve the right to modify this Agreement or its policies relating to the Mobile Application or Services at any time, effective upon posting of <br/>An updated version of this Agreement in the Mobile Application. When we do, we will revise the updated date at the bottom of this page. <br/>Continued use of the Mobile Application after any such changes shall constitute your consent to such changes. Policy was created with Website <br/>Policies.<br/></span><br/><span>Acceptance of these terms</span><br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>
        You acknowledge that you have read this Agreement and agree to all its terms and conditions. By using the Mobile Application or its Services <br/>You agree to be bound by this Agreement. If you do not agree to abide by the terms of this Agreement, you are not authorized to use or access <br/>The Mobile Application and its Services.<br/></span><br/><span>Contacting us</span><br/>
        <span style={{fontStyle:"normal",fontWeight:"normal"}}>
        If you would like to contact us to understand more about this Agreement or wish to contact us concerning any matter relating to it, you may do <br/>So via the contact form<br/><br/>This document was last updated on Jan 01, 2023</span>
	</div>
</div>
    </div>
  )
}

export default TermsandConditions
