import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import WorkPreferences from './WorkPreferences';


const style4 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 325,
    maxWidth: 385,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  };
const EditJobs = (props) => {

//useState
const [openJobs, setOpenJobs] = React.useState(false); 
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState("");
const [ Skills,setSkills]= useState("");
const [companyName, setCompanyName] = useState("");

const [jobDescription, setJobDescription] = useState("");
const [minYears, setMinYears] = useState(0);
const [minMonths, setMinMonths] = useState(0);
const [maxYears, setMaxYears] = useState(0);
const [maxMonths, setMaxMonths] = useState(0);
const [offices,setOffices] = useState([]);
const [searchParams] = useSearchParams();
const [officeName,setOfficeName]= useState("");
    const [mapUrl,setMapUrl] = useState("");
    const [latitude,setLatitude] = useState("");
    const [longitude,setLongitude]= useState("");
    const [officeLogoUrl,setOfficeLogoUrl] = useState("");
    const[resumeName,setResumeName]=useState();
    const [experience,setExperience]=useState();
    const [voiceProcess,setVoiceProcess] = useState(true)
    const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
    const [workFromHome,setWorkFromHome] = useState(false)
    const [workFromOffice,setWorkFromOffice] = useState(true)
    const [partTime,setPartTime] = useState(false)
    const [fullTime,setFullTime] = useState(true)
    const [nightShift,setNightShift] = useState(true)
    const [dayShift,setDayShift] = useState(true)
    const [companyTransportDay,setCompanyTransportDay] = useState(true)
    const [companyTransportNight,setCompanyTransportNight] = useState(true)
    const [ownTransport,setOwnTransport] = useState(true)
    const [minCTC,setMinCTC] = useState(0)
    const [maxCTC,setMaxCTC] = useState(0)
    const [hrNames,setHRNames]= useState([])
    const { user, setUser} = useContext(UserContext);
    const [spocId,setSpocId]= useState(user?user._id:"")
    const [spocName,setSpocName]= useState(user?user.name:"")
  



  //UseEffect     
 useEffect(()=>{
    getUsers();
    getOffices();
  
   },[companyName])

 useEffect(()=>{
    if(props.openJobs){
        setOpenJobs(props.openJobs)
        if(props.type!=="Add"){
            console.log(props.jobsData)
            setJobTitle(props.jobsData && props.jobsData.jobtitle);
            setJobId(props.jobsData && props.jobsData._id);
            setCompanyName(props.jobsData && props.jobsData.companyname);
            setMapUrl(props.jobsData && props.jobsData.mapUrl)
            setLatitude(props.jobsData && props.jobsData.latitude)
            setLongitude(props.jobsData && props.jobsData.longitude)
            setOfficeLogoUrl(props.jobsData && props.jobsData.officeLogoUrl);
            setJobDescription(props.jobsData && props.jobsData.jobdescription);
            setOfficeName(props.jobsData && props.jobsData.officeName);
            setSkills(props.jobsData && props.jobsData.skills);
            setMinYears(props.jobsData.minexpmonths ? ((props.jobsData.minexpmonths)-((props.jobsData.minexpmonths)%12))/12:0);
            setMinMonths(props.jobsData.minexpmonths ? (props.jobsData.minexpmonths)%12:0);
            setMaxYears(props.jobsData.maxexpmonths ? ((props.jobsData.maxexpmonths)-((props.jobsData.maxexpmonths)%12))/12:0);
            setMaxMonths(props.jobsData.maxexpmonths ? (props.jobsData.maxexpmonths)%12:0);
            setVoiceProcess(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).voiceProcess:false)
            setNonVoiceProcess(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).nonVoiceProcess:false)
            setWorkFromHome(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).workFromHome:false)
            setWorkFromOffice(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).workFromOffice:false)
            setPartTime(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).partTime:false)
            setFullTime(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).fullTime:false)
            setNightShift(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).nightShift:false)
            setDayShift(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).dayShift:false)
            setCompanyTransportDay(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).companyTransportDay:false)
            setCompanyTransportNight(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).companyTransportNight:false)
            setOwnTransport(props.jobsData && props.jobsData.preferences?JSON.parse(props.jobsData.preferences).ownTransport:false)
            setSpocId(props.jobsData.spocId ? props.jobsData.spocId:user._id);
            setSpocName(props.jobsData.spocName ? props.jobsData.spocName:user.name);
            setMaxCTC(props.jobsData.maxCTC ? props.jobsData.maxCTC:0);
            setMinCTC(props.jobsData.minCTC ? props.jobsData.minCTC:0);
           

        }
        else
        {
            setVoiceProcess(true)
            setNonVoiceProcess(false)
            setWorkFromHome(false)
            setWorkFromOffice(true)
            setPartTime(false)
            setFullTime(true)
            setNightShift(true)
            setDayShift(true)
            setCompanyTransportDay(false)
            setCompanyTransportNight(false)
            setOwnTransport(true)   
        }
    }
   
  
   },[props.openJobs])

//Functions
const handleClose = () => {
    if(props.openJobs===true){
        resetApplicant()
        props.close()
        setOpenJobs(false)
      }
   
   
  }
  

  const resetApplicant = ()=>{
  
    setJobTitle("");
    setJobId("");
    setCompanyName("");
    setJobDescription("");
    setOfficeName("");
    setSkills("");
    setMinYears(0);
    setMinMonths(0);
    setMaxYears(0);
    setMaxMonths(0); 
    setVoiceProcess(false)
    setNonVoiceProcess(false)
    setWorkFromHome(false)
    setWorkFromOffice(false)
    setPartTime(false)
    setFullTime(false)
    setNightShift(false)
    setDayShift(false)
    setCompanyTransportDay(false)
    setCompanyTransportNight(false)
    setOwnTransport(false)
    setSpocId("");
    setSpocName("");
    setMaxCTC(0);
    setMinCTC(0);
  }

  const setPreference=(key,value)=>
  {
switch (key) {
  case "voiceProcess":
    setVoiceProcess(value)
    break;
  case "nonVoiceProcess":
    setNonVoiceProcess(value)
    break;
  case "workFromHome":
    setWorkFromHome(value)
    break;
  case "workFromOffice":
    setWorkFromOffice(value)
    break;
  case "partTime":
    setPartTime(value)
      break;
  case "fullTime":
    setFullTime(value)
      break;
  case "nightShift":
    setNightShift(value)
      break;
  case "dayShift":
    setDayShift(value)
      break;
  case "companyTransportDay":
    setCompanyTransportDay(value)
        break;
  case "companyTransportNight":
    setCompanyTransportNight(value)
          break;
  case "ownTransport":
    setOwnTransport(value)
        break;
  default:
    break;
}
  }

  const getOffices = () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/getOfficelocations";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              // console.log(repos.data);
              if(repos.data.length>0)
              {
  
              setOffices(repos.data)
              
            }}else props.showAlert(""+(repos.message || repos),"error");  
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }

  //Get Users
const getUsers = async () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
    let apiParams = {
      method: "GET",
      headers: {
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    await fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             
            setHRNames(repos.data)
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }

  const addjob = ()=>{
   
    if(companyName.length===0){
   
     props.showAlert("Please Select the Comapany Name!!","error")
    }
    if(jobTitle.length===0){
     
     props.showAlert("Please Enter Job Title!!","error")
    }
 let apiURL=process.env.REACT_APP_API_URL + "/jobs/addJobs";
 let apiParams = {
     method:"POST",
     headers:{
       companyname:companyName,
       jobtitle: jobTitle,
       jobdescription:encodeURIComponent(jobDescription),
       minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
       maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
       preferences:JSON.stringify({

         voiceProcess:voiceProcess,
         nonVoiceProcess:nonVoiceProcess,
         workFromHome:workFromHome,
         workFromOffice:workFromOffice,
         partTime:partTime,
         fullTime:fullTime,
         nightShift:nightShift,
         dayShift:dayShift,
         companyTransportDay:companyTransportDay,
         companyTransportNight:companyTransportNight,
         ownTransport:ownTransport,
        
         }),
       officename:officeName,
       mapurl:mapUrl,
       latitude:latitude,
       longitude:longitude,
       minctc:minCTC,
       maxctc:maxCTC,
       skills: encodeURIComponent(Skills),
       spocid:spocId,
       spocname:spocName,
       active:true,
       officelogourl:officeLogoUrl,
       authtoken: localStorage.getItem("loggedintoken")
     },
    
    
 };
 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
      // alert("Job Added Successfully");
      handleClose()
      props.showAlert("Job Added Successfully","success")
    //   setTimeout(() => {
    //    window.location.reload(false);
    //  }, 2000);
     
     }else props.showAlert(""+(repos.message || repos),"error");
 }) 
 .catch((err) => props.showAlert((''+err),"error"));
}

  const Updatejob = ()=>{
   
    let apiURL=process.env.REACT_APP_API_URL + "/jobs/updateJobs/"+jobId;
    let apiParams = {
       method:"POST",
       headers:{
         companyname:companyName,
         jobtitle: jobTitle,
         jobdescription:encodeURIComponent(jobDescription),
         minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
         maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
         preferences:JSON.stringify({
    
           voiceProcess:voiceProcess,
           nonVoiceProcess:nonVoiceProcess,
           workFromHome:workFromHome,
           workFromOffice:workFromOffice,
           partTime:partTime,
           fullTime:fullTime,
           nightShift:nightShift,
           dayShift:dayShift,
           companyTransportDay:companyTransportDay,
           companyTransportNight:companyTransportNight,
           ownTransport:ownTransport,
          
           }),
         officename:officeName,
         mapurl:mapUrl,
         latitude:latitude,
         longitude:longitude,
         minctc:minCTC,
         maxctc:maxCTC,
         spocid:spocId,
         spocname:spocName,
         skills: encodeURIComponent(Skills),
         active:true,
         officelogourl:officeLogoUrl,
         authtoken: localStorage.getItem("loggedintoken")
       },
      
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
       if (repos.data) {
        // alert("Job Added Successfully");
        handleClose()
        // getJobsData()
        props.showAlert("Job Updated Successfully","success")
      //   setTimeout(() => {
      //    window.location.reload(false);
      //  }, 2000);
       
       }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => {props.showAlert((''+err),"error");handleClose()})
    }
    


  return (
    <div>

      <Modal
        open={openJobs}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
        <Box sx={style4}>
         
          <Typography id="modal-modal-description">
          <Container style={{padding:"0px"}}>
          <div className = "content5">
        <div style={{alignItems:"center"}}>
          <h2 className="fnt1"><b>{props.type+ " Vacancy"}</b></h2>
          <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"white" }} onClick={()=>handleClose()} ><CloseIcon /></Button>   
          </div>
          <FormControl variant="outlined" sx={{ m: 1, width: '35ch' }} size="small" >
        <InputLabel id="Office-label">Company Name</InputLabel>
        <Select
          labelId="Office-labell"
          id="Companylbl" 
          sx={{ width: '35ch' }}
          readOnly={props.type==="View"}
          value={companyName} onChange={(e)=> {
            
            setCompanyName(e.target.value);   
            setOfficeName(offices.filter(ofc=>ofc.company===e.target.value)[0].office);   
            setMapUrl(offices.filter(ofc=>ofc.company===e.target.value)[0].mapUrl);   
            setLatitude(offices.filter(ofc=>ofc.company===e.target.value)[0].latitude);   
            setLongitude(offices.filter(ofc=>ofc.company===e.target.value)[0].longitude);   
            setOfficeLogoUrl(offices.filter(ofc=>ofc.company===e.target.value)[0].officeLogoUrl);
            //console.log(offices.filter(ofc=>ofc.company===e.target.value)[0].officeLogoUrl);

           } } >

          { 
            
            offices && ([...(new Set(offices.map((cpy) =>cpy.company)))]).map((company) =><MenuItem value={company}>{company}</MenuItem>
  
        )} 
        </Select>        
      </FormControl>
        <FormControl variant="outlined" sx={{ m: 1, width: '35ch' }} size="small" >
        <InputLabel id="Office-label">Office Name</InputLabel>
        <Select
          labelId="Office-labell"
          id="Officelbl" 
          sx={{ width: '35ch' }}
          readOnly={props.type==="View"}
          value={officeName} onChange={(e)=> {
            
            setOfficeName(e.target.value);   
            setMapUrl(offices.filter(ofc=>ofc.office===e.target.value)[0].mapUrl);   
            setLatitude(offices.filter(ofc=>ofc.office===e.target.value)[0].latitude);   
            setLongitude(offices.filter(ofc=>ofc.office===e.target.value)[0].longitude); 
            setOfficeLogoUrl(offices.filter(ofc=>ofc.office===e.target.value)[0].officeLogoUrl);

         
           } } >

          { 
            
            offices && offices.filter(cmpy=>cmpy.company===companyName).map((ofc) =><MenuItem value={ofc.office}>{ofc.office}</MenuItem>
  
        )} 
        </Select>        
      </FormControl> 

    
      <TextField disabled={props.type==="View"} className="" id="standard-basic" sx={{ m: 2, width: '35ch' }}  label="Job Title" size="small" variant="outlined"  value={jobTitle} onChange={(e)=> {
      setJobTitle(e.target.value);
        }}/>

         <FormControl variant="outlined" sx={{ m: 2, width: '35ch' }} size="small">
        <InputLabel id="demo-simple-select-label">Spoc Name</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          readOnly={props.type==="View"}          
          value={spocId} onChange={(e)=> {
          
            setSpocId(e.target.value);  
            setSpocName(hrNames.filter(hr=>hr._id===e.target.value)[0].name);   
           } } >
          {
           hrNames && hrNames.map(hr=>  <MenuItem value={hr._id}>{hr.name}</MenuItem>)
            
        }
        </Select>
      </FormControl>
           {/* <table border="0">
         <tr>
     <td style={{textAlign:"center"}}>
        <input disabled={props.type==="View"} type="checkbox" id="chkVoice" name="chkVoice" checked={voiceProcess} onChange={()=>setVoiceProcess(!voiceProcess)}/>
        &nbsp;
        <label  for="chkVoice" style={{color:voiceProcess?"red":"black"}}> Voice </label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkNonvoice" name="chkNonvoice" checked={nonVoiceProcess} onChange={()=>setNonVoiceProcess(!nonVoiceProcess)}/>
        &nbsp;
        <label  for="chkNonvoice" style={{color:nonVoiceProcess?"red":"black"}}> Non Voice </label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkSemiVoice" name="chkSemiVoice" checked={nonVoiceProcess===true && voiceProcess===true} onChange={(e)=>{
          if(e.target.checked===true){
            setNonVoiceProcess(true)
            setVoiceProcess(true)
          }
          
          }}/>
        &nbsp;
        <label  for="chkSemiVoice" style={{color:(nonVoiceProcess===true && voiceProcess===true?"red":"black")}}> Semi Voice </label>
        &nbsp;
       
        </td>
        </tr>
        <tr><td style={{textAlign:"center"}}>
        <input disabled={props.type==="View"} type="checkbox" id="chkFullTime" name="chkFullTime" checked={fullTime} onChange={()=>setFullTime(!fullTime)}/>
        &nbsp;
        <label  for="chkFullTime" style={{color:fullTime?"red":"black"}}> Full Time </label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkPartTime" name="chkPartTime" checked={partTime} onChange={()=>setPartTime(!partTime)}/>
        &nbsp;
        <label  for="chkPartTime" style={{color:partTime?"red":"black"}}> Part Time </label>
        </td></tr>
        <tr><td style={{textAlign:"center"}}>
        <input disabled={props.type==="View"} type="checkbox" id="chkDayShift" name="chkDayShift" checked={dayShift} onChange={()=>setDayShift(!dayShift)}/>
        &nbsp;
        <label  for="chkDayShift" style={{color:dayShift?"red":"black"}}>Day Shift </label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkNightShift" name="chkNightShift" checked={nightShift} onChange={()=>setNightShift(!nightShift)}/>
        &nbsp;
        <label  for="chkNightShift" style={{color:nightShift?"red":"black"}}>Night Shift</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkRotaionalShift" name="chkRotaionalShift" checked={nightShift===true && dayShift===true} onChange={(e)=>{
         if(e.target.checked===true){
          setNightShift(true);
          setDayShift(true)
         }
          }}/>
        &nbsp;
        <label  for="chkRotationalShift" style={{color:nightShift===true && dayShift===true?"red":"black"}}>Rotational Shift</label>
        &nbsp;
        </td></tr>
        <tr><td style={{textAlign:"center"}}>
        Work from &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromoffcice" name="chkWorkfromoffcice" checked={workFromOffice} onChange={()=>setWorkFromOffice(!workFromOffice)}/>
        &nbsp;
        <label  for="chkWorkfromoffcice" style={{color:workFromOffice?"red":"black"}}>Office</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromhome" name="chkWorkfromhome" checked={workFromHome} onChange={()=>setWorkFromHome(!workFromHome)}/>
        &nbsp;
        <label  for="chkWorkfromhome" style={{color:workFromHome?"red":"black"}}>Home</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkHybrid" name="chkHybrid" checked={workFromOffice===true && workFromHome===true} onChange={(e)=>{
         if(e.target.checked===true){
          setWorkFromOffice(true)
          setWorkFromHome(true)
         }
         
        }}/>
        &nbsp;
        <label  for="chkHybrid" style={{color:workFromOffice===true && workFromHome===true?"red":"black"}}>Hybrid</label>
        </td></tr>
        <tr><td style={{textAlign:"center"}}>
        <input disabled={props.type==="View"} type="checkbox" id="chkOwnTransport" name="chkOwnTransport" checked={ownTransport} onChange={()=>setOwnTransport(!ownTransport)}/>
        &nbsp;
        <label  for="chkOwnTransport" style={{color:ownTransport?"red":"black"}}>Own Transport</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkCabRequired" name="chkCabRequired" checked={cabRequired} onChange={()=>setCabRequired(!cabRequired)}/>
        &nbsp;
        <label  for="chkCabRequired" style={{color:cabRequired?"red":"black"}}> Cab Provided </label>       
        </td></tr>
      
</table> */}
<WorkPreferences type={props.type} preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} setPreference={(key,value)=>setPreference(key,value)}/>
      <TextField className="" id="outlined-multiline-static"
       multiline disabled={props.type==="View"}
        sx={{ m: 2, width: '35ch' }}
        size="small"
          rows={3} 
          label="Job Description" variant="outlined" value={jobDescription} onChange={(e)=> {
        setJobDescription(e.target.value); 

      }}/>
     
      <TextField disabled={props.type==="View"} className="" id="outlined-basic-normal" multiline rows={3}  sx={{ m: 2, width: '35ch' }} size="small" label="Required Skills" variant="outlined"  value={Skills} onChange={(e)=> {
        setSkills(e.target.value);     
      }}/>
          <br/>
          
           <InputLabel id="demo-simple-select-outlined-label" >Min Experience</InputLabel>
      <TextField disabled={props.type==="View"} id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Years" variant="outlined"    value={minYears} onChange={(e)=> {
          
          setMinYears(e.target.value);
        }}/>
      <TextField disabled={props.type==="View"} id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Months" variant="outlined"    value={minMonths} onChange={(e)=> {
          
          setMinMonths(e.target.value);
        }}/>
      <InputLabel id="demo-simple-select-outlined-label" >Max Experience</InputLabel>

        <TextField disabled={props.type==="View"}  id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Years" variant="outlined"    value={maxYears} onChange={(e)=> {
          
          setMaxYears(e.target.value);
        }}/>
      <TextField disabled={props.type==="View"} id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Months" variant="outlined"    value={maxMonths} onChange={(e)=> {
          
          setMaxMonths(e.target.value);
        }}/>
        <div>
      <InputLabel id="demo-simple-select-outlined-label" >Salary Range</InputLabel>
      <TextField disabled={props.type==="View"} id="standard-basic" sx={{ m: 1, width: '15ch' }} size="small" label="Min CTC" variant="outlined"    value={minCTC} onChange={(e)=> {
          
          setMinCTC(e.target.value);
        }}/>
      <TextField disabled={props.type==="View"}  id="standard-basic" sx={{ m: 1, width: '15ch' }} size="small" label="Max CTC" variant="outlined"    value={maxCTC} onChange={(e)=> {
          
          setMaxCTC(e.target.value);
        }}/>
        </div>
        
   
    <br/>
    {props.type!=="View"?
    <Button  variant="contained" sx={{ m: 1, width: '35ch' }} onClick={()=>jobId?Updatejob():addjob()}>{jobId?"Edit":"Submit"}</Button>  
   :""}
      </div>
      </Container> 
          </Typography>
        </Box>
      </Modal>
    </div>
  )
}

export default EditJobs
