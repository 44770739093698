import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import '../Styles/JdKsPopUpMobUI.css';

const JdKsPopUpUI = (props) => {
    const { user, setUser} = useContext(UserContext);
    const [openJdKs,setOpenJdKs]= useState(false);
    const [jd,setJd] = useState("");
    const [ks,setKs] = useState("");
    const [jobTitle,setJobTitle] = useState("");


 useEffect(()=>{

    if(props.openJdKs){
        setOpenJdKs(props.openJdKs)
        if(props.vacancy){
           
            setJd(props.vacancy.jobDescription)
            setKs(props.vacancy.skills)
            setJobTitle(props.vacancy.jobTitle)
			

        }
		if(props.availableVacancy){
			setJd(props.availableVacancy.jobdescription?props.availableVacancy.jobdescription:props.availableVacancy.jobDescription)
            setKs(props.availableVacancy.skills)
            setJobTitle(props.availableVacancy.jobtitle?props.availableVacancy.jobtitle:props.availableVacancy.jobTitle)
		}
    
}

 },[props.openJdKs])   


const handleClose = ()=>{
    props.closeJdKs()
    setOpenJdKs(false)
}

const MyCopyFunction = async (jd)=>{
    // setCopiedText(heading,jd)

     await navigator.clipboard.writeText(jd);
      //alert("Copied the text: " + jd);
      props.showAlert("Copied To ClipBoard","success")
    }

  return (
    <div>
      <Modal
        open={openJdKs}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
    <div id="JD_mob">
	<img id="bg_overall_mob" src="bg_overall_mob.png" srcset="bg_overall_mob.png 1x" alt=''/>
		
	<svg class="card_2_bottom_mob">
		<rect id="card_2_bottom_mob" rx="7.5" ry="7.5" x="0" y="0" width="278" height="15">
		</rect>
	</svg>
	<svg class="card_2_top_mob">
		<rect id="card_2_top_mob" rx="10" ry="10" x="0" y="0" width="278" height="257">
		</rect>
	</svg>
	<svg class="card_1_bottom_mob">
		<rect id="card_1_bottom_mob" rx="7.5" ry="7.5" x="0" y="0" width="278" height="15">
		</rect>
	</svg>
	<svg class="card_1_top_mob">
		<rect id="card_1_top_mob" rx="10" ry="10" x="0" y="0" width="278" height="257">
		</rect>
	</svg>
	<div id="Job_Over_grp_mob">
		<div id="JD_desc_grp_1_mob">
			<div id="blabla_jd_mob">
				<span>{jd}</span>
			</div>
		</div>
		<div id="Job_Description_lbl_mob">
			<span>Job Description :</span>
		</div>
	</div>
	<div id="ksovergrp_mob">
		<div id="Key_Skills_lbl_mob">
			<span>Key Skills :</span>
		</div>
		<div id="ks_grp_mob">
			<div id="blablabla_mob">
				<span>{ks}</span>
			</div>
		</div>
	</div>
	<div id="jd_copy_btn_mob" onClick={() => MyCopyFunction("Job Description: \n"+jd)}>
		<svg class="bdr_btn_jd_mob">
			<rect id="bdr_btn_jd_mob" rx="9" ry="9" x="0" y="0" width="30" height="30">
			</rect>
		</svg>
		<img id="file_jd_img_mob" src="file_jd_img_mob.png" srcset="file_jd_img_mob.png 1x" alt=''/>
			
	</div>
	<div id="ks_copy_btn_mob" onClick={()=>MyCopyFunction("key Skills: \n"+ks)}>
		<svg class="btn_mob_ks">
			<rect id="btn_mob_ks" rx="9" ry="9" x="0" y="0" width="30" height="30">
			</rect>
		</svg>
		<img id="file_mob" src="file_mob.png" srcset="file_mob.png 1x" alt=''/>
	</div>
	<img id="close1_pop_mob" src="close1_pop_mob.png" srcset="close1_pop_mob.png 1x" alt='' onClick={()=>handleClose()}/>
		
	<div id="Grassroots_BPO_lbl_mob">
		<span>{jobTitle}</span>
	</div>
</div>
</Modal>
    </div>
  )
}

export default JdKsPopUpUI
