import React, { useState, useEffect, useContext } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { Button, Divider } from "@mui/material";
import "./RecruiterOfficeLocations.css";
import ScheduleInterview from "./ScheduleInterview";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import TextField from "@mui/material/TextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import CloseIcon from "@mui/icons-material/Close";
import { useSearchParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { NavLink } from "react-router-dom";
import { id } from "date-fns/locale";
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import States from "./States.json";

import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import AddOfficeLocationPopUpUI from "./AddOfficeLocationPopUpUI";
import RecruiterSetUpTopBar from "./PC/RecruiterSetUPTopBar";

const RecruiterOfficeLocations = (props) => {
  const OffLoc = {
    position: "relative",
    margin: "auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 500,
    scrollY: "auto",
    bgcolor: "background.paper",
    border: "2px solid #000",
  };

  //UseStates

  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");

  const [open, setOpen] = React.useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [state, setState] = useState("Karnataka");
  const [city, setCity] = useState("Bangalore");
  const [area, setArea] = useState("");
  const [company, setCompany] = useState("");
  const [office, setOffice] = useState("");

  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locations, setLocations] = useState([]);
  const [mapUrl,setMapUrl] = useState("");
  const [clawPeriod,setClawPeriod] = useState(0);
  const [img,setImg] = useState("");
  const [profilePic,setProfilePic]= useState("");
  const [selectedRowData,setSelectedRowData] = useState();
  const [aldocument,setALdocument] = useState("");
  const [alDName,setAlDName] = useState("");
  const [approvalStatus,setApprovalStatus] = useState("")
  
  const { user } = useContext(UserContext);

  const [filterModel, setFilterModel] = React.useState({
    items: [{ columnField: "", operatorValue: "contains", value: user.name }],
  });

  const cols = [
    { field: "id", headerName: "id", hide: true },
    { field: "state", headerName: "State", width: 190 },
    { field: "city", headerName: "City", width: 120 },
    { field: "company", headerName: "Company", width: 200 },
    
    { field: "office", headerName: "Office", width: 200 },
   
    { field: "latitude", headerName: "Latitude", width: 120, headerAlign:'center',  align:"center"},
    { field: "longitude", headerName: "Longitude", width: 120 , headerAlign:'center', align:"center"},
   
    {
      field: "authorizationLetterUrl",
      headerName: "Authorization Letter",
      headerAlign:'center',
      align:"center",
      width: 150,
      renderCell: (params) => {
        return [<>
  <FileUploadIcon
              style={{ marginLeft: "5px", color: "cornflowerblue" }}
              onClick={(e) =>
                document
                  .getElementById("candidateResume" + params.row.id)
                  .click()
              }
            />
            <input
              id={"candidateResume" + params.row.id}
              type="file"
              placeholder="Upload Authroization Letter"
              style={{ display: "none" }}
              name="Resume"
              onChange={(e) =>
                alDocUpload(e, params.row.id,"")
              }
            ></input>

 {params.row.authorizationLetterUrl && params.row.authorizationLetterUrl!=="" ?<FileDownloadIcon   style={{ marginLeft: "5px", color: "cornflowerblue" }} title="Download" onClick={(e)=>document.getElementById('candidateResumeDownload' + params.row.id).click()}/>:""}

{params.row.authorizationLetterUrl && params.row.authorizationLetterUrl!=="" ?  <a  id={"candidateResumeDownload"+ params.row.id}  style={{display:"none"}} name="Document" href={params.row.authorizationLetterUrl}  target="_blank" ></a>:""}
</>];
      },
    },
  
     { field: "status", headerName: "Approval Status", width: 260 , headerAlign:'center', align:"center", renderCell: (params) => {
      return [<div>
        <FormControl variant="outlined" disabled={user.accesstype== 5 ? false : true} sx={{width: "200px",marginTop:"2px",textAlign:"center" }} size="small"  >
         <InputLabel id="status-select"  ></InputLabel>
         <Select
           labelId="status"
           id="status-select"
           
           value={params.row.status ||"inreview"} onChange={(e)=> {
       
            setApprovalStatus(e.target.value); 
            updateApprovalStatus(params.row,e.target.value);
            //  sendWhatsapp(params.row,e.target.value)  
 
            } } >
         
           <MenuItem value="inreview">In Review</MenuItem>
           <MenuItem value="approved">Approved</MenuItem>
           <MenuItem value="rejected">Rejected</MenuItem>
           <MenuItem value="onhold">On Hold</MenuItem>
         </Select>
       </FormControl>
       
 </div>];
    },},
    {
      field: "active",
      headerName: "Active",
      headerAlign:'center',
      align:"center",
  
      width: 100,
      renderCell: (params) => {
        return [<input type="checkbox" checked={params.row.active} />];
      },
    },
  ];


  useEffect(() => {
    if(user){
      if(user.accesstype==="r"){
        getLocations();
      }
      if(user.accesstype==5){
        getAllRecuitersLocation()
      }
    }
  
  }, [user]);

  const getLocations = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/recruiterofficelocations/getrecruiterofficelocations/"+user._id;
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //console.log(repos.data)
          setLocations(repos.data);
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };

  const getAllRecuitersLocation = ()=>{
    let apiURL = process.env.REACT_APP_API_URL + "/recruiterofficelocations/getallrecruiterofficelocations";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          //console.log(repos.data)
          setLocations(repos.data);
        } else props.showAlert("" + (repos.message || repos), "error");
      })
      .catch((err) => props.showAlert("" + err, "error"));
  } 
  
  
const updateApprovalStatus = (row,value)=>{

  // if((row.status==="approved" || value === "rejected") && parseInt(user.accesstype)<2)
  // {
  //   return alert("You don't have access to change result, contact your Manager to update!!")
  // }

  let apiURL =  process.env.REACT_APP_API_URL + "/recruiterofficelocations/updateApprovalstatus/"+row.id;
      let apiParams ={
        method:"POST",
        headers:{
         status:value,
         authtoken: localStorage.getItem("loggedintoken")
      }
      };
      fetch(apiURL,apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=>{
        if(repos.data){
         
          setIsLoading(false)
          
          props.showAlert("Approval Status Updated Successfully","success")
          setTimeout(()=>{
            getAllRecuitersLocation()
          },2000)
        }
  
      })
      .catch((err) => props.showAlert((''+err),"error"));
  }
  

  const alDocUpload = async (e,id,name) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    // handleCRDFileSelect(e.target.files[0]);
    // alert("File Selected is "+e.target.files[0].name)
    UploadImageToDrive(id,"AuthorisationLetter",e.target.files[0])
    // setALdocument(e.target.files[0]);
   
  };
 

 //Upload Image
 const UploadImageToDrive = async (officeLocationId,value,document) =>{
     
  var file = document //the file
  var reader = new FileReader() //this for convert to Base64 
  reader.readAsDataURL(document) //start conversion...
  reader.onload = function (e) { //.. once finished..
    var rawLog = reader.result.split(',')[1]; //extract only thee file data part
    var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
   fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
      { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
      .then(res => res.json()).then((data) => {
        //See response
      
         SaveProfilePic(data,officeLocationId,value)
        props.showAlert("Documents Uploaded Successfully","success")

      }).catch(err => console.log(err)) // Or Error in console
  }
}

const SaveProfilePic = (data,officeLocationId,value)=>{
  if(officeLocationId){
  
          let apiURL=process.env.REACT_APP_API_URL + "/recruiterofficelocations/saveRecruiterOfficeLogoUrl/" + officeLocationId;
          let apiParams = {
              method:"POST",
              headers:{
                officelogourl:data.url,
                value:value,
                authtoken: localStorage.getItem("loggedintoken")
              }
        }
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
             
          
            }
          })
          .catch(e => console.log(e)) 
    } 
        }

 

  const UpdateOfficeLocations = (id) => {

    let apiURL = process.env.REACT_APP_API_URL + "/recruiterofficelocations/updaterecruiterOfficeLocations/" + id;
    let apiParams = {
      method: "POST",
      headers: {
        state: state,
        city: city,
        company:company,
        office:office,
        latitude: latitude,
        longitude: longitude,
        mapurl:mapUrl,
        active: active,
        authtoken: localStorage.getItem("loggedintoken")
      },
      body:profilePic,
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          // profilePic && UploadImageToDrive(id)
          props.showAlert("Location Updated Successfully", "success");
          setTimeout(() => {
            getLocations();
          }, 2000);
        }
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };

  const addOfficeLocations = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/addofficeLocations";
    let apiParams = {
      method: "POST",
      headers: {
        state: state,
        city: city,
        // area: area,
        company:company,
        office:office,
        latitude: latitude,
        longitude: longitude,
        mapurl:mapUrl,
        clawperiod:clawPeriod,
        active: active,
        authtoken: localStorage.getItem("loggedintoken")

      },
      body:profilePic,
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
          setOpen(false);
          setIsLoading(false);
          // profilePic && UploadImageToDrive(repos.data._id)
          
          props.showAlert("Office Locations Added Successfully", "success");
          setTimeout(() => {
            getLocations();
          }, 2000);
        }
        else{
               props.showAlert(repos.message||repos||"Error","error");
        }
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };

  const addOffLoc = () => {
    setOpen(true);
  };

  const editLocations = (row) => {
     setSelectedRowData(row)  
    setId(row.id);
    setActive(row.active);
    setState(row.state);
    setCity(row.city);
    // setArea(row.area);
    setCompany(row.company);
    setOffice(row.office);
    setLongitude(row.longitude);
    setLatitude(row.latitude);
    setMapUrl(row.mapUrl);
    if(row.officeLogoUrl && row.officeLogoUrl.length>50){
      setImg("https://drive.google.com/uc?export=view&id="+row.officeLogoUrl.split('/')[5])
      // console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                    }
    
    setClawPeriod(parseInt(row.clawPeriod));
    setOpen(true);
  };


  function arrayBufferToBase64(buffer) {
    var binary = "";
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => (binary += String.fromCharCode(b)));
    var base64Flag = "data:image/jpeg;base64,";
    return base64Flag + window.btoa(binary);
  }


  

  const resetApplicant = () => {
    setId("");
  
    setState("");
    setCity("");
    
    setCompany("");
    setOffice("");
    setLongitude("");
    setLatitude("");
    setClawPeriod(0);
    setMapUrl("");
  };

  const rowData = locations?.map((loc) => {
    return {
      id: loc?._id,
      state: loc?.state,
      city: loc?.city,
      officeLogoUrl:loc?.officeLogoUrl,
      company:loc?.company,
      office:loc?.office,
      longitude: loc?.longitude,
      latitude: loc?.latitude,
      mapUrl:loc?.mapUrl,
      authorizationLetterUrl:loc?.authorizationLetterUrl,
      status:loc?.status,
      active: loc?.active,
    };
  });

  return (
    <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
    {((/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent)) || (user && user.accesstype == 5))? "":<RecruiterSetUpTopBar />}
    <div style={{position:"absolute",height:"100%",width:"100%"}}>
      <Button
        size="small"
        variant="contained"
        className="intern"
        title={"Add Office Locations"}
        onClick={() => addOffLoc()}
      >
        + Office Locations
      </Button>
      <br />
      <br />
      <div style={{ height: 'calc(100% - 50px)', width: "100%" }}>
        <DataGrid
          rows={rowData}
          columns={cols}
          components={{
            Toolbar: GridToolbar,
          }}
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          onCellClick={(cell)=>cell.field!=="authorizationLetterUrl" && cell.field !=="status"?editLocations(cell.row):null
          }
          // onRowClick={(e) =>{console.log(e); editLocations(e.row)}}
        />

        {/* <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={OffLoc}>
            <div>
              <Typography id="modal-modal-description">
                <Container style={{ padding: "0px" }}>
                  <div className="OffLoc">
                    <div>
                      
                      <h4 className="fnt2">
                        <b>ADD OFFICE LOCATIONS</b>

                        <Button
                          title="Close"
                          size="small"
                          variant="Standard"
                          sx={{
                            bgcolor: "red",
                            float: "right",
                            right: 0,
                            top: 0,
                            position: "absolute",
                            height: 28,
                            width: 5,
                          }}
                          onClick={() => handleClose()}
                        >
                          {" "}
                          <CloseIcon
                            style={{ height: "25px", width: "25px" }}
                          />{" "}
                        </Button>
                      </h4>
                    </div>
                    <br />
                    <div style={{textAlignLast:"center"}}>
                        <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                      <img width="100" height="100" src={img?img:"default.jpg"} alt="Reload?" style={{borderRadius:"75px",border:"1px solid black",objectFit:"contain"}}/>
                      </label>
                    </div>
                    <br/>
                    <div style={{ textAlign: "center", margin: "2px" }}>
                      <table>
                        <tr>
                          <td>
                            <FormControl
                              variant="outlined"
                              sx={{ minWidth: 220 }}
                              size="small"
                            >
                              <InputLabel id="state-label">State</InputLabel>
                              <Select
                                labelId="state-name-labell"
                                id="State"
                                value={state}
                                // defaultValue={companyName}
                                onChange={(e) => {
                                  setState(e.target.value);
                                }}
                              >
                                {States &&
                                  States.map((state) => (
                                    <MenuItem value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
                          </td>
                          <td>
                            <TextField
                              id="outlined-size-small"
                              size="small"
                              label="City"
                              variant="outlined"
                              value={city || ""}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              label="Company"
                              variant="outlined"
                              value={company || ""}
                              onChange={(e) => {
                                setCompany(e.target.value);
                              }}
                            />
                    
                          </td>
                          <td>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              label="Office"
                              variant="outlined"
                              value={office || ""}
                              onChange={(e) => {
                                setOffice(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>
                          <TextField
                              id="outlined-basic"
                              size="small"
                              label="latitude"
                              variant="outlined"
                              value={latitude}
                              onChange={(e) => {
                                setLatitude(e.target.value);
                              }}
                            />
                           
                          </td>
                          <td>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              label="longitude"
                              variant="outlined"
                              value={longitude || ""}
                              onChange={(e) => {
                                setLongitude(e.target.value);
                              }}
                            />
                          </td>{" "}
                        </tr>
                       
                        <tr>
                        <td colSpan={2} style={{textAlign:"center"}}>
                          <TextField
                              id="outlined-size-small"
                              size="small"
                              sx={{ width: 440 }}
                              label="Location Link"
                              variant="outlined"
                              value={mapUrl || ""}
                              onChange={(e) => {
                                setMapUrl(e.target.value);
                              }}
                            />
                          </td>
                        </tr>
                        <tr>
                          <td colSpan={1} style={{textAlign:"center"}}>
                            Active:{" "}
                            <input
                              type="checkbox"
                              checked={active}
                              onChange={(e) => setActive(e.target.checked)}
                            />
                          </td>
                          <td colSpan={1} style={{textAlign:"center"}}>
                       
        <InputLabel id="demo-simple-select-outlined-label" >Claw Period</InputLabel>
        <TextField disabled={props.type==="View"}  id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small"  variant="outlined"  value={clawPeriod} onChange={(e)=> {
          
          setClawPeriod(e.target.value);
        }}/>
      
                          </td>
                        
                        </tr>
                      </table>
                    </div>
                    <br />

                    <div style={{ textAlign: "center", marginBottom: "5px" }}>
                      <Button
                        disabled={isLoading}
                        type="reset"
                        title={"Edit Add "}
                        variant="contained"
                        onClick={() =>
                          id.length > 0
                            ? UpdateOfficeLocations(id)
                            : addOfficeLocations()
                        }
                      >
                        {id.length > 0 ? "Edit" : "Add"}
                      </Button>{" "}
                      &nbsp; &nbsp; &nbsp; &nbsp;
                      {isLoading ? <CircularProgress /> : ""}
                    </div>
                  </div>
                </Container>
              </Typography>
            </div>
          </Box>
        </Modal> */}


        <AddOfficeLocationPopUpUI  open={open} selectedRowData={selectedRowData} showAlert={props.showAlert} close={()=>{setOpen(false);setSelectedRowData();(user.accesstype!= 5 ? getLocations() :getAllRecuitersLocation())}}/>
      </div>
    </div>
    </div>
  );
};

export default RecruiterOfficeLocations;
