import React,{useState,useEffect, useContext,useRef} from 'react'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InputAdornment from '@mui/material/InputAdornment';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WorkIcon from '@mui/icons-material/Work';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'; 
import TimePicker from '@mui/lab/TimePicker';
import CloseIcon from '@mui/icons-material/Close';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import Timer from './Timer';
import './ScheduleInterviewUI.css';

import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { UserContext } from './UserContext';

import  {MenuItem, Select,Button,TextField,InputLabel,FormControl,Typography,Modal,Box,Container,Autocomplete} from '@mui/material';
import ApplicantPopup from './ApplicantPopup';
import InterviewApplicantPopup from './InterviewApplicantPopup';
import EmailTemplatesPopup from './EmailTemplatesPopup';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import EditJobs from './EditJobs';
import WorkPreferencesScheduleInterviewUI from './WorkPreferencesScheduleInterviewUI';
//import { google } from "googleapis";

const style4 = {

  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 400,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const NearbyLocations = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 600,
  maxWidth: 600,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

  const JdModal = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 250,
    maxWidth: 400,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  };
  

  const rounds = [
    { label: 'HR Screening' },
    { label: 'Verification' },
    { label: 'Client Round' },
  ]  
  
  const locations = [
    { label: 'Mobile Call' },
    { label: 'Video Call' },
    { label: 'Diamond District' },
    { label: 'Nagawara' },
    { label: 'Indiranagar' },
  ]  
const ScheduleInterviewUI = (props) => {
  
  const [interviewId, setInterviewId]= useState("");
  const [msgInterviewId, setMsgInterviewId]= useState("");
    const [appliedJob ,setAppliedJob] = useState();
    const [vacancies,setVacancies] = useState();
    const [jobsDataProps,setJobsDataProps] = useState();
    const [round, setRound] = useState("");
    const [interViewerName, setInterViewerName]= useState("");
    const [interViewDate, setInterViewDate] = React.useState(new Date().toLocaleDateString());
    const [interViewTime, setInterViewTime] = React.useState(new Date().toLocaleTimeString().slice(0,5));
    const [ timeDate, setTimeDate] = React.useState(new Date().toLocaleDateString())
    const [interViewLocation, setInterViewLocation] = useState("Mobile Call");
    const [companyName, setCompanyName] = useState();
    const [name, setName] = useState();
    const [status, setStatus] = useState();
    const [applicantId,setApplicantId]= useState();
    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState();
    const [ mobile,setMobile] = useState();
    const [ result, setResult] = useState();
    const[ feedback ,setFeedback] = useState("");
    const { user, setUser} = useContext(UserContext);
    const [companies,setCompanies] = useState();
    const [prevfeedback,setPrevFeedback] = useState("");
    const [language, setLanguage ] = useState("");
    const [bookMark,setBookMark]= useState (false);
    const [bookMarkCmts, setBookMarkCmts ] =useState("");
    const [ hrName,setHRName] = useState("");
    const [candidateRounds,setCandidateRounds]= useState([])
    const [nextinterviewDate ,setNextInterviewDate] = useState(React.useState(new Date().toLocaleDateString()));    
    const [roundResult,setRoundResult]= useState("");
    const [ openTime,setOpenTime] = useState(0);
      const [previousCurrent,setPreviousCurrent ]= useState(0)
      const [expectedSalary,setExpectedSalary ] = useState(0)
      const [referalName,setReferalName ]= useState("")
      const [referalNumber,setReferalNumber ] = useState("")
      const [referalGender,setReferalGender] = useState("male")
      const [createdbyid,setCreatedbyId] = useState("")
      const [createdbyname,setCreatedbyName] = useState("")
      const [mobileStatus,setMobileStatus]=useState("")
      const [mail, setMail] = useState("");
      const [whatsappStatus,setWhatsappStatus] = useState(true);
      const [sendEmail,setSendEmail] = useState(false);
      const [openJDModel, setOpenJDModel] = useState(false);
      const [ city,setCity]= useState("");
      const [area,setArea] = useState("");
      const [education,setEducation] = useState("");
      const [areasOfinterview,setAreasOfinterview]= useState([])
      const [Dob, setDob ]= React.useState(new Date());
      const [expYears, setExpYears]= useState(0);
      const [openApplicant,setOpenApplicant] = useState(false);
      const [openEmailTemplate,setOpenEmailTemplate] = useState(false);
      const [expMonth, setExpMonths]= useState(0);
      const [openScheduler,setOpenScheduler]= useState(false)
      const  uploadedCount = useRef(0);
      const [intervalId,setIntervalId] = useState(0);
      const [ resumeUrl,setResumeUrl] = useState("");
      const [noticePeriod,setNoticePeriod] = useState(0)
      const [totalExperience,setTotalExperience]= useState(0);
      const [egr,setEgr] = useState("");
      const [cmr,setCmr] = useState("");
      const [hRNames,setHRNames] = useState([]);
      const [jobDescription,setJobDescription] = useState("");
      const [keySkills,setKeySkills] = useState("");
      const [openLocationModal, setOpenLocationModal] = React.useState(false);
      const [companyCoordinates,setCompanyCoordinates] = useState([]);
      const [openNext,setOpenNext] = useState(true)
      const [voiceProcess,setVoiceProcess] = useState(true)
      const [nonVoiceProcess,setNonVoiceProcess] = useState(true)
      const [workFromHome,setWorkFromHome] = useState(true)
      const [workFromOffice,setWorkFromOffice] = useState(true)
      const [partTime,setPartTime] = useState(true)
      const [fullTime,setFullTime] = useState(true)
      const [nightShift,setNightShift] = useState(true)
      const [dayShift,setDayShift] = useState(true)
      const [companyTransportDay,setCompanyTransportDay] = useState(true)
      const [companyTransportNight,setCompanyTransportNight] = useState(true)
      const [ownTransport,setOwnTransport] = useState(true)
      const [willingToRelocate,setWillingToRelocate] = useState(true)
      const [mapUrl,setMapUrl] = useState("");
      const [candidatePreference,setCandidatePreference] = useState({});
      const [jobExperience,setJobExperience] = useState(0);
      const [wifiSpeed,setWifiSpeed] = useState(0);
      
      useEffect(async ()=>{
    //props.interview && getScheduledInterview(props.interview.applicantId)
   
    await getCompanies()
   
},[])

useEffect(()=>applicantId && getInterviewRounds(),[applicantId])

useEffect(async()=>{
    if (props.interview){
      //console.log(props.interview)
       setApplicantId(props.interview.applicantid)
       setJobId(props.interview.jobid)
       setInterviewId(props.interview.id);
       setCreatedbyId(props.interview.createdbyid)
       setCreatedbyName(props.interview.createdbyname)
      setName(props.interview.name)
    setInterViewerName(props.interview.interviewername===""?user.name:props.interview.interviewername);
      setInterViewDate(props.interview.interviewdate || new Date().toLocaleDateString());
    props.interview.interviewtime?((props.interview.interviewtime).length < 6 ? setInterViewTime((props.interview.interviewtime)):setInterViewTime(new Date((props.interview.interviewtime)).toTimeString().slice(0,5))):setInterViewTime(new Date(new Date()).toTimeString().slice(0,5)) ;

    if(props.interview.interviewdate && props.interview.interviewtime)
    {
     
    let time = parseInt(parseInt(props.interview.interviewtime.split(':')[0])*60+parseInt(props.interview.interviewtime.split(':')[1])+330);
    let interviewDateTime = new Date(new Date(props.interview.interviewdate.toDateString()).setMinutes(time)).toISOString().slice(0,16);
  
    setTimeDate(interviewDateTime)
    }
    else
    {
      setTimeDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
    }
      setRound(props.interview.rounds);
      setInterViewLocation(props.interview.interviewlocation ==="undefined"?"":props.interview.interviewlocation );
      // setStatus(props.interview.status);
      setFeedback("")
      setPrevFeedback(props.interview.feedback === "undefined"?"":props.interview.feedback)
      setBookMark(props.interview.bookmark===undefined?false:props.interview.bookmark)
      setBookMarkCmts(props.interview.bookmarkcomment==="undefined"?"":props.interview.bookmarkcomment)
      setResult(props.interview.status)
      setMobile(props.interview.mobile)
      setMail(props.interview.mail)
      setCompanyName(props.interview.companyname)
       await getOfficeVacancies(props.interview.companyname.split(" - ")[0],props.interview.companyname.split(" - ")[1])
        setJobTitle(props.interview.jobtitle)
        setCmr(props.interview.communicationrating === "undefined"?"":parseInt(props.interview.communicationrating))
        setEgr(props.interview.englishrating === "undefined"?"":parseInt(props.interview.englishrating))
        setHRName(props.interview.hrname)
        if(props.interview.rounds === "Client Round")
        {
          setNextInterviewDate(new Date((new Date(new Date().toDateString())).setMinutes(930)).toISOString().slice(0,16))
        }
        else if(user.accesstype == 5)
        {       
          setNextInterviewDate(new Date(new Date(new Date().toDateString()).setMinutes(2490)).toISOString().slice(0,16))
        }
          else{
             setNextInterviewDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
        }
    
      }
},[companies,props.interview])

useEffect(()=>{
  setOpenScheduler(props.open)
  setCompanyName("");
  if (intervalId>0) {
    clearInterval(intervalId);
    setIntervalId(0)
  }
  if(props.open)
  {
    getLanguages()
  }
    if(user.accesstype != 5)
    {
      if(props.interview && props.interview._id)
      {
        if(props.open)
        {
          timer("start")
          setIntervalId(setInterval(()=>{timer("active10")},10000))
        }
        else
        {
          timer("stop")
        }
    }
 }


  },[props.open])

  useEffect(()=>{},[openTime])

useEffect(()=>{
    if (props.openMsg){
     
      setOpenScheduler(props.openMsg)
      if(props.interviewMsg){
        console.log(props.interviewMsg)
        setApplicantId(props.interviewMsg._id)
        setRound(!props.interviewMsg.latestinterviewround||props.interviewMsg.latestinterviewround=="undefined"?"":props.interviewMsg.latestinterviewround);

        setLanguage(!props.interviewMsg.languages||props.interviewMsg.languages=="undefined"?"":props.interviewMsg.languages)
        setPreviousCurrent(!props.interviewMsg.currentSalary||props.interviewMsg.currentSalary=="undefined"?"":props.interviewMsg.currentSalary)
        setExpectedSalary(!props.interviewMsg.expectedSalary||props.interviewMsg.expectedSalary=="undefined"?"":props.interviewMsg.expectedSalary)
        setCity(!props.interviewMsg.city||props.interviewMsg.city=="undefined"?"":props.interviewMsg.city)
        setArea(!props.interviewMsg.area||props.interviewMsg.area=="undefined"?"":props.interviewMsg.area)
        setEducation(!props.interviewMsg.education||props.interviewMsg.education=="undefined"?"":props.interviewMsg.education)
        setResumeUrl(!props.interviewMsg.resumeUrl||props.interviewMsg.resumeUrl=="undefined"?"":props.interviewMsg.resumeUrl)
        setExpYears(!props.interviewMsg.experience||props.interviewMsg.experience=="undefined"?0:((props.interviewMsg.experience)-((props.interviewMsg.experience)%12))/12);
        setExpMonths(!props.interviewMsg.experience||props.interviewMsg.experience=="undefined"?0:(props.interviewMsg.experience)%12);
        setNoticePeriod(!props.interviewMsg.noticePeriod||props.interviewMsg.noticePeriod=="undefined"?0:props.interviewMsg.noticePeriod)
        setVoiceProcess(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).voiceProcess:true)
        setNonVoiceProcess(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).nonVoiceProcess:true)
        setWorkFromHome(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).workFromHome:true)
        setWorkFromOffice(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).workFromOffice:true)
        setPartTime(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).partTime:true)
        setFullTime(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).fullTime:true)
        setNightShift(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).nightShift:true)
        setDayShift(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).dayShift:true)
        setCompanyTransportDay(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).companyTransportDay:true)
        setCompanyTransportNight(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).companyTransportNight:true)
        setOwnTransport(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).ownTransport:true)
        setWillingToRelocate(props.interviewMsg.preferences?JSON.parse(props.interviewMsg.preferences).willingToRelocate:true)
      }
     
      if(props.interviewMsg.latestinterviewid){
        console.log(props.interviewMsg)
        setInterviewId(props.interviewMsg.latestinterviewid);
        // setMsgInterviewId(props.interviewMsg.latestinterviewid)
        getMessagedInterview(props.interviewMsg.latestinterviewid)
      }
        
        }
 
},[props.interviewMsg])

useEffect(()=>{
  getUsers()
  getLocations()

},[props.interview])

const setPreference=(key,value)=>
{
switch (key) {
case "voiceProcess":
  setVoiceProcess(value)
  break;
case "nonVoiceProcess":
  setNonVoiceProcess(value)
  break;
case "workFromHome":
  setWorkFromHome(value)
  break;
case "workFromOffice":
  setWorkFromOffice(value)
  break;
  case "partTime":
    setPartTime(value)
    break;
  case "fullTime":
    setFullTime(value)
    break;
  case "nightShift":
    setNightShift(value)
    break;
  case "dayShift":
    setDayShift(value)
    break;
    case "companyTransportDay":
      setCompanyTransportDay(value)
      break;
      case "companyTransportNight":
        setCompanyTransportNight(value)
        break;
    case "ownTransport":
      setOwnTransport(value)
      break;
default:
  break;
}
}

const getMessagedInterview = (latestintwID)=>{
  
  let apiURL=process.env.REACT_APP_API_URL + "/interview/getlatestinterview/"+ latestintwID;
  let apiParams = {
      method:"GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then(async (repos)=> {
      if (repos.data) {
        console.log(repos.data)
        setInterviewId(repos.data._id);
        setName(repos.data.name)
        setInterViewerName(repos.data.interviewername===""?user.name:repos.data.interviewername);
          setInterViewDate(repos.data.interviewdate || new Date().toLocaleDateString());
        repos.data.interviewtime?((repos.data.interviewtime).length < 6 ? setInterViewTime((repos.data.interviewtime)):setInterViewTime(new Date((repos.data.interviewtime)).toTimeString().slice(0,5))):setInterViewTime(new Date(new Date()).toTimeString().slice(0,5)) ;
    
        if(repos.data.interviewdate && repos.data.interviewtime)
        {
         
        let time = parseInt(parseInt(repos.data.interviewtime.split(':')[0])*60+parseInt(repos.data.interviewtime.split(':')[1])+330);
        let interviewDateTime = new Date(new Date(new Date(repos.data.interviewdate).toDateString()).setMinutes(time)).toISOString().slice(0,16);
      
        setTimeDate(interviewDateTime)
        }
        else
        {
          setTimeDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
        }
          setRound(repos.data.rounds);
          setInterViewLocation(repos.data.location ==="undefined"?"":repos.data.location );
          // setStatus(repos.data.status);
          setFeedback("")
          setPrevFeedback(repos.data.feedback === "undefined"?"":repos.data.feedback)
          setBookMark(repos.data.bookmark===undefined?false:repos.data.bookmark)
          setBookMarkCmts(repos.data.bookmarkcomment==="undefined"?"":repos.data.bookmarkcomment)
          setResult(repos.data.status)
          console.log(repos.data.status)
          setRoundResult(repos.data.status)
          setMobile(repos.data.mobile)
          setCompanyName(repos.data.companyname)
          await getOfficeVacancies(repos.data.companyname.split(" - ")[0],repos.data.companyname.split(" - ")[1])
            setCmr(repos.data.communicationRating === "undefined"?"":parseInt(repos.data.communicationRating))
            setEgr(repos.data.englishRating === "undefined"?"":parseInt(repos.data.englishRating))
            setJobId(repos.data.jobId)
            setJobTitle(repos.data.jobtitle)
            setHRName(repos.data.hrname)
            if(repos.data.rounds === "Client Round")
            {
              setNextInterviewDate(new Date((new Date(new Date().toDateString())).setMinutes(930)).toISOString().slice(0,16))
            }
            else if(user.accesstype == 5)
            {       
              setNextInterviewDate(new Date(new Date(new Date().toDateString()).setMinutes(2490)).toISOString().slice(0,16))
            }
              else{
                 setNextInterviewDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
            }
        
     
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));

}

const getLocations = ()=>{
  let apiURL=process.env.REACT_APP_API_URL + "/locations/getlocations" 
  let apiParams = {
      method:"GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
 
       setAreasOfinterview(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

const handleClose = () =>{
  setOpenLocationModal(false);
  
}

const closeJDModel = () => setOpenJDModel(false);

const OpenLocationModal = (value)=>{

  setOpenLocationModal(true)
 
  let apiURL=process.env.REACT_APP_API_URL + "/officelocations/getCompanyCoordinates/" + value.latitude +"/"+ value.longitude; 
  let apiParams = {
      method:"GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
      // console.log(repos.data)
      setCompanyCoordinates(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));

}

//Google Drive File upload functions: Start
// const authenticateGoogle = () => {
//   const auth = new google.auth.GoogleAuth({
//     keyFile: `${__dirname}/service-account-key-file.json`,
//     scopes: "https://www.googleapis.com/auth/drive",
//   });
//   return auth;
// };

// const uploadToGoogleDrive = async (file, auth) => {
//   const fileMetadata = {
//     name: file.originalname,
//     parents: ["1T8smHRI3g1Or9pSjxsV_BDFvR4LepqZu"], // Change it according to your desired parent folder id
//   };
  
//   // const media = {
//   //   mimeType: file.mimetype,
//   //   body: fs.createReadStream(file.path),
//   // };

//   const driveService = google.drive({ version: "v3", auth });

//   const response = await driveService.files.create({
//     requestBody: fileMetadata,
//     media: file,
//     fields: "id",
//   });
//   return response;
// };

// const deleteFile = (filePath) => {
//   fs.unlink(filePath, () => {
//     console.log("file deleted");
//   });
// };

//Google Drive File upload functions: End


const changeRound = async (intvwId) =>{
//  console.log(intvwId)
 let apiURL = process.env.REACT_APP_API_URL + "/interview/getinterview/"+intvwId;

    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    await fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then(async (repos)=> {
            if (repos.data) {
            //console.log(repos.data)
              setInterviewId(repos.data._id);
              setName(repos.data.name)
              setInterViewerName(repos.data.interviewername===""?user.name:repos.data.interviewername);
                setInterViewDate(repos.data.interviewdate || new Date().toLocaleDateString());
              repos.data.interviewtime?((repos.data.interviewtime).length < 6 ? setInterViewTime((repos.data.interviewtime)):setInterViewTime(new Date((repos.data.interviewtime)).toTimeString().slice(0,5))):setInterViewTime(new Date(new Date()).toTimeString().slice(0,5)) ;
          
              if(repos.data.interviewdate && repos.data.interviewtime)
              {
               
              let time = parseInt(parseInt(repos.data.interviewtime.split(':')[0])*60+parseInt(repos.data.interviewtime.split(':')[1])+330);
              let interviewDateTime = new Date(new Date(new Date(repos.data.interviewdate).toDateString()).setMinutes(time)).toISOString().slice(0,16);
            
              setTimeDate(interviewDateTime)
              }
              else
              {
                setTimeDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
              }
                setRound(repos.data.rounds);
                setInterViewLocation(repos.data.location ==="undefined"?"":repos.data.location );
                // setStatus(repos.data.status);
                setFeedback("")
                setPrevFeedback(repos.data.feedback === "undefined"?"":repos.data.feedback)
                setBookMark(repos.data.bookmark===undefined?false:repos.data.bookmark)
                setBookMarkCmts(repos.data.bookmarkcomment==="undefined"?"":repos.data.bookmarkcomment)
                setResult(repos.data.status)
                setRoundResult(repos.data.status)
                setMobile(repos.data.mobile)
                setCompanyName(repos.data.companyname + " - "+repos.data.jobId.officeName)
                  await getOfficeVacancies(repos.data.companyname + " - "+repos.data.jobId.officeName)
                  setCmr(repos.data.communicationRating === "undefined"?"":parseInt(repos.data.communicationRating))
                  setEgr(repos.data.englishRating === "undefined"?"":parseInt(repos.data.englishRating))
                  setJobId(repos.data.jobId._id)
                  setJobTitle(repos.data.jobtitle)
                  setHRName(repos.data.hrname)
                  if(repos.data.rounds === "Client Round")
                  {
                    setNextInterviewDate(new Date((new Date(new Date().toDateString())).setMinutes(930)).toISOString().slice(0,16))
                  }
                  else if(user.accesstype == 5)
                  {       
                    setNextInterviewDate(new Date(new Date(new Date().toDateString()).setMinutes(2490)).toISOString().slice(0,16))
                  }
                    else{
                       setNextInterviewDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
                  }
              
               //console.log(repos.data)
                         }else props.showAlert(""+(repos.message || repos),"error"); 
        }) 
        .catch((err) => props.showAlert((''+err),"error"));

} 

const updateLanguage = (lang) =>{

var newLanguageValue = (((language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language)+lang);
setLanguage(((newLanguageValue.length>0 && newLanguageValue.indexOf(",")===0)?newLanguageValue.substring(1,newLanguageValue.length-1):newLanguageValue));
}

  const getLanguages = ()=>{
    let apiURL=process.env.REACT_APP_API_URL + "/applicants/languages/" +props.interview.applicantid;
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
      if (repos.data) {
        setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
        setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
        setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
        setCity(!repos.data.city||repos.data.city=="undefined"?"":repos.data.city)
        setArea(!repos.data.area||repos.data.area=="undefined"?"":repos.data.area)
        setEducation(!repos.data.education||repos.data.education=="undefined"?"":repos.data.education)
        setResumeUrl(!repos.data.resumeUrl||repos.data.resumeUrl=="undefined"?"":repos.data.resumeUrl)
        setExpYears(!repos.data.experience||repos.data.experience=="undefined"?0:((repos.data.experience)-((repos.data.experience)%12))/12);
        setExpMonths(!repos.data.experience||repos.data.experience=="undefined"?0:(repos.data.experience)%12);
        setNoticePeriod(!repos.data.noticePeriod||repos.data.noticePeriod=="undefined"?0:repos.data.noticePeriod)
        setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
        setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
        setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
        setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
        setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
        setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
        setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
        setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
        setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
        setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
        setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
        setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
      
      }else {
      setLanguage("");
      setPreviousCurrent("");
      setExpectedSalary("");
      setCity("");
      setArea("");
      setEducation("")
      setResumeUrl("");
      setExpYears(0);
      setExpMonths(0);
      setNoticePeriod(0);
    }
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const timer = (action) =>{
    let apiURL = process.env.REACT_APP_API_URL + "/interview/timer/"+interviewId+"/"+action;
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
        })
        .catch((err) => props.showAlert((''+err),"error"));
      }

  
    const getInterviewRounds = ()=>{
      let apiURL=process.env.REACT_APP_API_URL + "/interview/getscheduleinterview/" +applicantId +"/"+ jobId;
      let apiParams = {
        method: "GET",
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        }
      
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
        if (repos.data) {
         
          setCandidateRounds(repos.data)
         
          if (repos.data.length > 0)
          {changeRound(repos.data[repos.data.length-1]._id)}
          
        }
      }) 
      .catch((err) => props.showAlert((''+err),"error"));
    }

    const getOfficeVacancies = async (company,office) =>{
        let apiURL = process.env.REACT_APP_API_URL + "/jobs/vacancies?company=" +company+"&office=" +office;
        let apiParams = {
          method: "GET",
          headers:{
            authtoken: localStorage.getItem("loggedintoken")
          },
        };  
        await fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  
                  if(repos.data.length>0)
                  {
                 
                  setJobId(repos.data && repos.data[0]._id);
                  setJobTitle(repos.data && repos.data[0].jobtitle);
                  setJobDescription(repos.data && repos.data[0].jobdescription);
                  setCandidatePreference(repos.data && repos.data[0].preferences);
                  setKeySkills(repos.data && repos.data[0].skills);
                  setJobExperience(repos.data && parseInt(repos.data[0].minexpmonths))
                  setMapUrl(repos.data && repos.data[0].mapUrl);
                  setVacancies(repos.data && repos.data);
                  setJobsDataProps(repos.data && repos.data[0])
                  setCompanyName(repos.data && repos.data[0].companyname + " - "+ repos.data[0].officeName);
                  
                }
                }else props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
          }
          
          const today = new Date()
          const tomorrow = new Date().getDay() == 6? new Date(today+ 24*60*60) : new Date (new Date(today))
       

          const nextRound=()=>{
            let apiURL=process.env.REACT_APP_API_URL + "/interview/applyinterview/" + props.interview.applicantid;
            let apiParams = {
                method:"POST",
                headers:{
                    name: props.interview.name,
                    email: props.interview.mail,
                    mobile: props.interview.mobile,
                    jobid: jobId,
                    jobtitle:jobTitle,
                    hrname:props.interview.hrname,
                    feedback: encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+feedback+"\n"+(props.interview.feedback||"")+"\n"),
                    companyname:companyName && companyName.split(" - ")[0],
                    reference:props.interview.reference=="undefined"?"":props.interview.reference,
                    interviewername: user.accesstype == 5?"Client Interviewer":"",
                    round: user.accesstype == 5?"Client Round":"Verification",
                    interviewdate:nextinterviewDate,
                    interviewtime:nextinterviewDate.split('T')[1],
                    location: user.accesstype == 5?"":"Mobile Call",
                    createdbyid:user._id,
                    createdbyname:user.name,
                    authtoken: localStorage.getItem("loggedintoken")
                },
            };
            // console.log(apiParams)
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  if(whatsappStatus===true){
                    WhatsappMsg()
                  }
                  if(sendEmail===true){
                    SendEmail()
                  }
                 
                setTimeout(() => {
                  props.showAlert("Interview round created for applicant","info")
                }, 2000);
                 
                  
                
                }else props.showAlert((repos.message || repos|| "already applied for this job"),"error"); 
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
        }

const AddData = async () =>{
    
let success = await checkduplicate(referalName,referalNumber,referalGender)

    if (success===true)
    {
      uploadedCount.current= uploadedCount.current+1
    }
};


const checkduplicate = async (name,mobile,gender)=>{
  if(mobile.length>=10){
  let phone = mobile.toString().replace('-','').slice(-10);
  let apiURL=process.env.REACT_APP_API_URL + "/applicants/phone/" +phone;
    let apiParams = {
        method:"GET",
    };
  return await fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          //alert(repos.data.name+" registered with "+repos.data.mobile+" already!");
          props.showAlert((repos.data.name+" registered with "+repos.data.mobile+" already!"),"info")
          setReferalName("")
          setReferalNumber("")
          setReferalGender("male")
          return false;
        } 
        else{
          addApplicant(name,mobile,gender);
          return true;
        }
      
    }) 
    .catch((err) => {props.showAlert((''+err),"error"); return false;});
    }
    else{
      props.showAlert("Mobile number length is below 10 digits!","error")
    }
  }



  const addApplicant=(name,mobile,gender)=>{
 
      let apiURL=process.env.REACT_APP_API_URL + "/applicants/addapplicant";
      let apiParams = {
          method:"POST",
          headers:{
              name: name,
              mobile: mobile,
              gender: gender,
              jobid: jobId,
              jobtitle: jobTitle,
              companyname:companyName && companyName.split(" - ")[0],
              reference:encodeURIComponent("Candidate Referal: "+ name+" - "+mobile),
              createdbyid:user._id,
              createdbyname:user.name,
              authtoken: localStorage.getItem("loggedintoken")
          }
      };
      // console.log(apiParams)
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
              //setOutputMsg([...outputMsg,"\n"+name+" - "+mobile+" added successfully!\n"]);
           applyJob(repos.data)
          }else{ props.showAlert((repos.message || repos),"error")}
      }) 
      .catch((err) =>{props.showAlert((''+err),"error")})
    }


const handleUpload = async (e) =>{
  var file = e.target.files[0] //the file
  var reader = new FileReader() //this for convert to Base64 
  reader.readAsDataURL(e.target.files[0]) //start conversion...
  reader.onload = function (e) { //.. once finished..
    var rawLog = reader.result.split(',')[1]; //extract only thee file data part
    var dataSend = { dataReq: { data: rawLog, name:name[0].toUpperCase() + name.slice(1), type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
    fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
      { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
      .then(res => res.json()).then((data) => {
        //See response
        RetrieveResume(data)
        props.showAlert("Resume Uploaded Successfully","success")

      }).catch(e => console.log(e)) // Or Error in console
  }

}
const RetrieveResume = (data)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveResumeUrl/" + applicantId;
  let apiParams = {
      method:"POST",
      headers:{
        resumeurl:data.url,
        authtoken: localStorage.getItem("loggedintoken")
      }
}
fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
    if (repos.data) {
    setResumeUrl(data.url)
    }
  })
  .catch(e => console.log(e)) 
}

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
  });
}
    const applyJob=(applicant)=>{
      // console.log(job)
      let applicantid = applicant._id
      let apiURL=process.env.REACT_APP_API_URL + "/interview/applyinterview/" + applicantid;
      let apiParams = {
          method:"POST",
          headers:{
              hrname:hrName,
              name: applicant.name,
              mobile: applicant.mobile,
              jobid: jobId,
              jobtitle: jobTitle,
              companyname:companyName && companyName.split(" - ")[0],
              round:round,
              interviewername:hrName,
              createdbyid:createdbyid,
              createdbyname:createdbyname,
              reference:encodeURIComponent("Candidate Referal: "+ name+" - "+mobile),
              authtoken: localStorage.getItem("loggedintoken")
          },
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
            setReferalName("")
            setReferalNumber("")
            setReferalGender("male")
            // alert("Referral added Sucessfully!")
            props.showAlert("Referral added Sucessfully!","success")
              //setOutputMsg([...outputMsg,"\nInterviwer assigned successfully!"]);
          }else {
              //setOutputMsg([...outputMsg,"\nFailed to assign interviewer!"]);
            };
      }) 
      .catch((err) => props.showAlert((''+err),"error"));
  }
const LoadSelectedJob= (job)=>
{
 
    setOpenApplicant(false);
  if(job)
  {
    setJobId(job);
    setOpenScheduler(true);
    getInterviewRounds()
  }
  else
  {
    setOpenScheduler(false);
    
    props.close(openNext);}
  }


  const sendWhatsapp = () =>{
  
    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+name[0].toUpperCase() + name.slice(1)+",%0a%0aI am trying to reach you for a job vacancy. "+mobileStatus+"%0a%0aKindly call me back if interested to apply.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing"

    const newWindow = window.open(apiURL, '_blank')
  
  }

  const WhatsappMsg = () =>{
  
    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Congratulations "+name[0].toUpperCase() + name.slice(1)+",%0a%0aYou have cleared the HR Screening round.%0a%0aPlease stay in touch for further updates.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing";

    
    const newWindow = window.open(apiURL, '_blank')
    //WhatsappMsgSupervisor();

  
  }

  const WhatsappRejectedCandidates = () =>{
  
    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Dear "+name[0].toUpperCase() + name.slice(1)+",%0a%0aIt was a pleasure talking to you!%0a%0aPlease stay updated on the latest vacancies by following us on our LinkedIn Page by clicking https://www.linkedin.com/company/bizi-bees-outsourcing";

    
    const newWindow = window.open(apiURL, '_blank')
    //WhatsappMsgSupervisor();

  
  }

  const WhatsappMsgSupervisor = () =>{

    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+user.reportingmobile+"&type=phone_number&app_absent=0&text="+name[0].toUpperCase() + name.slice(1)+" has cleared the HR Screening round.%0a%0aPlease call the candidate on "+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+" and conduct verification round if applicable.";
   
    
    const newWindow = window.open(apiURL, '_blank')
   
  }
  
  const SendMapLocation = () =>{
    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+name[0].toUpperCase() + name.slice(1)+",%0a%0aYour interview will be conducted at the below location. %0a%0a "+mapUrl+" %0a%0aKindly carry your resume hard copy and mention \"Bizi Bees\" on top of your resume without fail.%0a%0aPlease call me back on "+user.phone+" if any queries.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing"

    const newWindow = window.open(apiURL, '_blank')

  }

  const SendJD = async () =>{

    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+name[0].toUpperCase() + name.slice(1)+",%0a%0aBelow is the Job Description and Skill Sets Required for the job discussed. %0a%0aJob Description:%0a"+jobDescription.split('\n').map(line=>line).join('%0a')+"%0a%0aSkills Required:%0a"+keySkills.split('\n').map(line=>line).join('%0a')+"%0a%0aPlease call me back on "+user.phone+" if any queries.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing"

    const newWindow = window.open(apiURL.replace('%0a,','%0a'), '_blank')

  }
 
  const SendEmail = () =>{
   setTotalExperience(parseInt(expYears*12)+parseInt(expMonth));
          setOpenEmailTemplate(true)
   
  }
 
       
        const showApplicant = () =>{
          setOpenScheduler(false)
          setOpenApplicant(true)
        }
    

       const joinedRound =() =>{
        let apiURL=process.env.REACT_APP_API_URL + "/interview/applyinterview/" + props.interview.applicantid;
        let apiParams = {
            method:"POST",
            headers:{
                name: props.interview.name,
                email: props.interview.mail,
                mobile: props.interview.mobile,
                jobid: jobId,
                jobtitle:jobTitle,
                hrname:props.interview.hrname,
                reference:props.interview.reference=="undefined"?"":props.interview.reference,
                feedback: encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+feedback+"\n"+(props.interview.feedback||"")+"\n"),
                companyname:companyName && companyName.split(" - ")[0],
                interviewername: user.accesstype == 5?"Client Interviewer":"",
                round:"Joined",
                interviewdate:nextinterviewDate,
                interviewtime:nextinterviewDate.split('T')[1],
                location:"",
                status:"Joined",
                createdbyid:user._id,
                createdbyname:user.name,
                authtoken: localStorage.getItem("loggedintoken")
            },
        };
        // console.log(apiParams)
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              
           
            setTimeout(() => {
              props.showAlert("Applicant has been Marked as Joined","info")
            }, 2000);
             
              
            
            }else props.showAlert((repos.message || repos|| "already applied for this job"),"error");
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
    }
       
          
          const EditInterview=() =>{
          
            if(result === "Next Round" && language.length<2)
            {
           // alert("Please enter Candidate Languages!")
            props.showAlert("Please enter Candidate Languages!","error")
            return;
          }
         
          if ((result==="Next Round" || result==="Rejected" || result==="Cancelled") && egr.length === 0) {
            // alert("Select English Rating");
             props.showAlert("Select English Rating","error")
             return;
           }
          
          if ((result==="Next Round" || result==="Rejected" || result==="Cancelled") && cmr.length === 0) {
            //alert("Select Communication Rating");
            props.showAlert("Select Communication Rating","error")
            return;
          }
        
            let bookMarkObj;
            let feedbackObj
           if(props.interview){
           
            props.close(openNext)
             bookMarkObj = (props.interview.bookmark!==bookMark)?bookMarkCmts:(props.interview.bookmarkcomment && props.interview.bookmarkcomment !=="undefined")?props.interview.bookmarkcomment:"";
             feedbackObj = encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+feedback+"\n"+(props.interview.bookmark!==bookMark?bookMark?"Bookmark added with comments: "+bookMarkObj+"\n":"Bookmark removed with comments: "+bookMarkObj+"\n":"")+(props.interview.feedback && props.interview.feedback !== "undefined"?props.interview.feedback:"")+"\n"); 
           }else{
          
            props.closeMsg()
           
           }
           
            let apiURL=process.env.REACT_APP_API_URL + "/interview/editinterview/"
            let apiParams = {
                method:"POST",
                headers:{
                  applicantid:props.interview?props.interview.applicantid:props.interviewMsg._id,
                  interviewid:interviewId,
                  interviewername :interViewerName,
                  hrname:hrName,
                  interiewdate:timeDate,
                  interviewtime:timeDate.split("T")[1],
                  rounds: round,
                  location:encodeURIComponent(interViewLocation),
                  companyname:companyName && companyName.split(" - ")[0],
                  jobid: jobId,
                  jobtitle: jobTitle,
                  createdbyid:user._id,
                  createdbyname:user.name,
                  feedback: feedbackObj,
                  bookmark:bookMark,
                  bookmarkcomments:bookMarkObj,
                  result:result,
                  languages: ((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
                  currentsalary:previousCurrent,
                  expectedsalary:expectedSalary,
                  
           preferences:JSON.stringify({
            voiceProcess:voiceProcess,
            nonVoiceProcess:nonVoiceProcess,
            workFromHome:workFromHome,
            workFromOffice:workFromOffice,
            partTime:partTime,
            fullTime:fullTime,
            nightShift:nightShift,
            dayShift:dayShift,
            companyTransportDay:companyTransportDay,
            companyTransportNight:companyTransportNight,
            ownTransport:ownTransport,
            willingToRelocate:willingToRelocate,
            }),
                  city:city,
                  area:area,
                  education:education,
                  experience:parseInt(expYears) * 12 + parseInt(expMonth),
                  noticeperiod:noticePeriod?parseInt(noticePeriod):0,
                  communicationrating:cmr,
                  englishrating:egr,
                  authtoken: localStorage.getItem("loggedintoken")
                },
              };
             
            
              fetch(apiURL, apiParams)
              .then((res)=> (res.status===200 ? res.json() : res.text()))
              .then((repos)=> {
                  if (repos.data) {
                   
                    if(result ==="Next Round" && roundResult!=="Next Round")
                    {
                    nextRound();

                  }else if(result ==="Joined" && round!=="Joined" ) {

                    joinedRound();

                  }else if( whatsappStatus===true && result==="Not Reachable") {
                      sendWhatsapp();
                  }
                  else if( whatsappStatus===true && (result==="Next Round" ||result==="Passed")) {
                    WhatsappMsg();
                  }          
                  else if( whatsappStatus===true && (result==="Rejected" || result==="Cancelled")) {
                  WhatsappRejectedCandidates();
                  }
                      props.showAlert("InterView Updated Successfully","success")
                   
                 
                    //  setTimeout(() => {
                    //    window.location.reload(false);
                    //  }, 3000);
                    
                    
                  }else props.showAlert(""+(repos.message || repos),"error");
              }) 
              .catch((err) => props.showAlert((''+err),"error"));
              
            } 
          
        

              const getCompanies = async () =>{
                let apiURL = process.env.REACT_APP_API_URL + "/jobs/companies"
                let apiParams = {
                  method: "GET",
                  headers:{
                    authtoken: localStorage.getItem("loggedintoken")
                  },
                };
                await fetch(apiURL, apiParams)
                    .then((res)=> (res.status===200 ? res.json() : res.text()))
                    .then(async (repos)=> {
                        if (repos.data) {
                        
                          setCompanies(repos.data)
                          setCompanyName(repos.data[0])
                          await getOfficeVacancies(repos.data[0].split(" - ")[0],repos.data[0].split(" - ")[1])
                         
                        }else props.showAlert(""+(repos.message || repos),"error");  
                    }) 
                    .catch((err) => props.showAlert((''+err),"error"));
                  }

                  const getUsers = async () =>{
                    let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
                    let apiParams = {
                      method: "GET",
                      headers: {
                        authtoken: localStorage.getItem("loggedintoken")
                      }
                    };
                    await fetch(apiURL, apiParams)
                        .then((res)=> (res.status===200 ? res.json() : res.text()))
                        .then((repos)=> {
                            if (repos.data) {
                            
                              //console.log(repos.data)
                              setHRNames(repos.data)
                            }else props.showAlert(""+(repos.message || repos),"error");  
                        }) 
                        .catch((err) => props.showAlert((''+err),"error"));
                      }

      const openJdModal = ()=>{
        setOpenJDModel(true)
      }


  //Getting Locations from Location Tab and mapping to get area
    const areaLoc = (areasOfinterview)?.map((loc) =>loc.area);
    const hrnames = (hRNames)?.map((hr)=>hr.name);


    // const rowData = [];      

    var cols = [

      {field:'id', headerName: 'id', hide: true },
      {field:'company', headerName: 'Company',width: 150, },
      {field:'office', headerName: 'Office',width: 150 },
      {field:'distance', headerName: 'Distance(km)',width: 150,headerAlign:'center',align:"center" },
      
  ];


const rowData = (companyCoordinates||[]).map((co) => {
       
  return{
 
  id: co._id,
  company:co.company,
  office:co.office,
  distance:parseInt(co.distance),
  
  };
});


           
  return (
    <> 
    <Modal
        open={openScheduler}
        onClose={props.handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
        >
<div id="schedule_interviews_Modal">
<img id="popUpBackground1" src="n_291450.png" srcset="n_291450.png 1x, n_291450@2x.png 2x"/>
		
	<img id="popUpBackground2" src="n_7973908.png" srcset="n_7973908.png 1x, n_7973908@2x.png 2x"/>
<div className="ScheduleInterviewBackground">
		<rect id="ScheduleInterviewBackground" rx="0" ry="0" x="0" y="0" width="539" height="100">
		</rect>
    <Timer style={{width:"50px",height:"5%"}} class="ScheduleInterviewBackground" started={props.open}/>
    <ReactInternetSpeedMeter  
            txtSubHeading="Internet is too slow"
            outputType="empty"
            customClassName={null}
            txtMainHeading="Opps..." 
            pingInterval={15000} // milliseconds 
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
            threshold={10}
            imageUrl="/bizibees.jpeg"
            downloadSize="18800"  //bytes
            callbackFunctionOnNetworkDown={(speed)=>`Internet speed is down ${speed}`}
            callbackFunctionOnNetworkTest={(speed)=>setWifiSpeed(speed)}

          />
  <div style={{zIndex:"10",position:"relative",color:wifiSpeed<10?"red":"white"}}>Internet speed: {wifiSpeed} mb/s</div>
	</div>
	<svg class="ScheduleTitle_PC">
		<linearGradient id="ScheduleTitle_PC" spreadMethod="pad" x1="0.436" x2="-0.378" y1="1" y2="-1.052">
			<stop offset="0" stop-color="#fff" stop-opacity="1"></stop>
			<stop offset="1" stop-color="#808080" stop-opacity="1"></stop>
		</linearGradient>
		<rect id="ScheduleTitle_PC" rx="17" ry="17" x="0" y="0" width="400" height="65">
		</rect>
	</svg>
  <div className='Schedule_CancelBtn'>
  <Button title="Close" id='Schedule_CancelBtn' size="small" variant="Standard" sx={{ bgcolor: "white",float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>{
    if(props.open===true){
      props.close(openNext); setOpenScheduler(false);
    }else if(props.openMsg===true){
      props.closeMsg();
      setOpenScheduler(false);
    }
   } 
    }> <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
  </div>
  
	<div id="Candidate_Username_">
		<span><marquee>{name}</marquee></span>
	</div>
  <div id="Candidate_Mobile_">
		<span>{<a href={"tel:"+mobile}>{mobile}</a>}</span>
	</div>

  {status==="Applied" && props.interview.rounds === undefined?
  <div className='Interview_Rounds_Hr'>
   <Autocomplete
      disablePortal
      id="txtRounds"
      freeSolo
      options={rounds}
      sx={{ width: 250 }}
      value={round || ""} 
      onChange={(event,value)=>setRound(value.label)}
      renderInput={(params) => <TextField {...params}  
          //className="BOXLEFT"
          //sx={{minWidth: 420 }}
          size="small" label="Interview Round" 
          value={round || ""} 
          onChange={(e)=> 
            setRound(e.target.value) 
          }  />}
         />
          </div>
          :
          <div className='Hr_Rounds_1' style={{textAlign:"center", fontFamily: 'Times New Roman'}}>{[...new Set(candidateRounds)].map(rnd=>rnd._id === interviewId?<b>{round}</b>:<input type="button" style={{border:"1px solid", top: "231px" ,padding:"2px 6px",margin:"5px",borderRadius:"10px"}} onClick={()=>{changeRound(rnd._id);}} value={rnd.rounds}/>)}</div> 
          }
<div className='Interview_Hr_Names'>
  <Autocomplete
      disablePortal
      id="Interview_Hr_Names"
      freeSolo
      options={hrnames}
      sx={{ width: 250 }}
      disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
      value={hrName || ""}
      onChange={(event,value)=>setHRName(value)}
      renderInput={(params) => <TextField {...params}
      InputProps={{
            startAdornment: 
	<InputAdornment position="start"><AccountBoxIcon/></InputAdornment>,
          }}   
          sx={{width: 250}}
          size="small" label="HR Name" 
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={hrName || ""}
          onChange={(e)=> {
          setHRName(e.target.value);
        }} />}
         />
         </div>

         <div className='Interview_Date_Time'>
  <small>{round!=="Joined" ?"Interview Date & Time: ":"Joined Date: "}</small><input type="datetime-local" format="dd-mm-yyyy" disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}  style={{width:"250px",height:"50%",backgroundColor:"transparent",border:"1px solid", padding:"2px 6px"}} value={timeDate}  onChange={(e) =>setTimeDate(e.target.value)}/>
  </div>
	
<div className='Schedule_CompanyName'>
<FormControl variant="outlined" 
        
        sx={{width: 250 }} size="small">
        <InputLabel id="companyName-label"><ApartmentIcon/> &nbsp; Company Name</InputLabel>
        <Select
          labelId="company-name-labell"
          id="Schedule_CompanyName"
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={companyName} 
          // defaultValue={companyName}
          onChange={async (e)=> {
            setCompanyName(e.target.value)
            await getOfficeVacancies(e.target.value.split(" - ")[0],e.target.value.split(" - ")[1]);
           } } >

          
          {
      companies && companies.map((company) =>  <MenuItem  value={company}>{company}</MenuItem>
        )}
        
        </Select>
      </FormControl> 

</div>
 <div className='Resume_Upload_'>
  <FileUploadIcon style={{marginLeft:"5px",color:"cornflowerblue"}}  onClick={(e)=>document.getElementById('candidateResume').click()}/>
         <input  id="candidateResume" type="file" placeholder="Upload Resume" style={{display:"none"}} name="Resume" onChange={(e) => handleUpload(e)}></input>
         {resumeUrl && resumeUrl!=="" ? <FileDownloadIcon style={{marginLeft:"5px",color:"cornflowerblue"}}  onClick={(e)=>document.getElementById('candidateResumeDownload').click()}/>:""}
         {resumeUrl && resumeUrl!=="" ?  <a  id="candidateResumeDownload"  style={{display:"none"}} name="Resume" href={resumeUrl}  target="_blank" ></a>:""}
  </div>
  
<div className='Schedule_Available_Job'>
<FormControl variant="outlined" sx={{width: 250 }} size="small" >
        <InputLabel id="vacancies-label"><WorkIcon/> &nbsp; Available Jobs</InputLabel>
        <Select
          labelId="vacancies-labell"
          id="Schedule_Available_Job" 
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={jobId} onChange={(e)=> {
            
            setJobId(e.target.value);   
            setCandidatePreference(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].preferences)
            setJobTitle(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].jobtitle);   
            setMapUrl(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].mapUrl);
            setCandidatePreference(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].preferences)
            
           } } >

          { 
            
       vacancies && vacancies.map((vacancy) =><MenuItem value={vacancy._id}>{vacancy.jobtitle}</MenuItem>
  
        )} 
        </Select>        
      </FormControl> 
      
</div>



  <div>
  <img id="_Office_location" src="location.png" srcset="location.png 1x, location@2x.png 2x" />
  <a href="#" id='Company_Send_location' onClick={()=>SendMapLocation()}>Send Location</a>
	</div>
	

  <div>
  <img id="document" src="document.png" srcset="document.png 1x, document@2x.png 2x" />
		 <a href="#" id="Sending_Company_JD" onClick={()=>{SendJD();}}>Send JD</a>
  </div>



		
	<div >
  <img id="view" src="view.png" srcset="view.png 1x, view@2x.png 2x" />
  <a href="#" id="View_Job_bg" onClick={()=>openJdModal()}>View</a>
	</div>

  <div className='Interview_Locations'>
  <Autocomplete
      disablePortal
      id="Interview_Locations"
      freeSolo
      options={locations}
      sx={{ width: 250 }}
      disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
      value={interViewLocation ||"Mobile Call"}
      onChange={(event,value)=>setInterViewLocation(value.label)}
      renderInput={(params) => <TextField {...params}  
      InputProps={{
            startAdornment: 
	      <InputAdornment position="start"> <LocationOnIcon/></InputAdornment>,
          }}
         
          sx={{minWidth: 250 }}
          size="small"
          label={round!=="Joined" ?"Interview Location":"Joined Location"}
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          multiline
         value={interViewLocation || ""}
          onChange={(e)=> {
          setInterViewLocation(e.target.value);
        }}/>}
         />
  </div>
 
<div className='Interviews_Candidate_City'>
<TextField 
      InputProps={{
            startAdornment: 
	<InputAdornment position="start"><LocationCityIcon/></InputAdornment>,
          }}
      sx={{width: 250 }}  id="Interviews_Candidate_City"  size="small" label="City" variant="outlined"  value={city} onChange={(e)=> {
                setCity(e.target.value);

      }} /> 

      <br/>
          {(areasOfinterview||[]).filter(rec=>(rec.area||"").toLowerCase().trim() === (area||"").toLowerCase().trim()).length > 0?
                <div>
                <a href="#" onClick={()=>OpenLocationModal((areasOfinterview||[]).filter(rec=>(rec.area||"").toLowerCase().trim() === (area||"").toLowerCase().trim())[0])}>Show Office Distances</a>
                </div>  :""}


</div>
 
<div className='Candidate_Area'>
<Autocomplete
      disablePortal
      id="Candidate_Area"
      freeSolo
      options={areaLoc}
      sx={{width: 250 }}
      value={area || ""}
      
      onChange={(event,value)=>
      {
        setArea(value); 
      }}
      renderInput={(params) => <TextField {...params}  
      
          sx={{width: 250}}
          size="small" label="Area"
          value={area || ""} 
          onChange={(e)=> { 
            setArea(e.target.value);
        }} />}
         />
</div>
 

  <div className='Candidate_Education'>
  <TextField 
      sx={{width: "300px",marginTop:"2px", }}  id="Candidate_Education"  size="small" label="Education" variant="outlined"  value={education} onChange={(e)=> {
      setEducation(e.target.value)
       }} /> 
  </div>

 

  <div className='Candidate_Languages'>
  <TextField 

sx={{width: "350px",marginTop:"2px" }}  id="Candidate_Languages"  size="small" label="Languages" variant="outlined"  value={language} onChange={(e)=> {
          setLanguage(e.target.value.length>0&&e.target.value.slice(0,1)===","?language.substring(1,e.target.value.length):e.target.value);

 }} /> 
       <div style={{textAlign:"center",fontSize:"small",marginTop:"3px"}}>
      
    {(language||"").includes("English")?"":  <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",English,")}>English</button>}
    {(language||"").includes("Hindi")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Hindi,")}>Hindi</button>}
    {(language||"").includes("Kannada")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Kannada,")}>Kannada</button>}
    {(language||"").includes("Urdu")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Urdu,")}>Urdu</button>}
    {(language||"").includes("Tamil")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Tamil,")}>Tamil</button>}
    {(language||"").includes("Telugu")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Telugu,")}>Telugu</button>}
    {(language||"").includes("Malayalam")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Malayalam,")}>Malayalam</button>}
    {(language||"").includes("Marathi")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Marathi,")}>Marathi</button>}
    {(language||"").includes("Punjabi")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Punjabi,")}>Punjabi</button>}
    {(language||"").includes("Bangla")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Bangla,")}>Bangla</button>}
    {(language||"").includes("Arabic")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Arabic,")}>Arabic</button>}
    {(language||"").includes("French")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",French,")}>French</button>}
    {(language||"").includes("Spanish")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Spanish,")}>Spanish</button>}
    {(language||"").includes("Russian")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Russian,")}>Russian</button>}
    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setLanguage("")}>Clear</button>
      <br/></div>
  </div>

 

  <WorkPreferencesScheduleInterviewUI type={props.type} preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} highlight={{candidatePreference}} setPreference={(key,value)=>setPreference(key,value)}/>
	
  
    <div className='Willing_to_Relocate_Preference'>
  <input type="checkbox" id="Willing_to_Relocate_Preference" name="Willing_to_Relocate_Preference" checked={willingToRelocate} onChange={()=>setWillingToRelocate(!willingToRelocate)}/>
  </div>
  <label id='Willing_to_Relocate_lbl_' for="chkWillingtorelocate" style={{color:willingToRelocate?"red":"black"}}>Willing To Relocate</label>


<div className='Refer_Name_txt'>
<TextField 
    sx={{width: "125px",marginTop:"2px" }}  id="Refer_Name_txt"  size="small" label="Refer Name" variant="outlined"  value={referalName} onChange={(e)=> {
         setReferalName(e.target.value)
      }} />
</div>


  <div className='Refer_Mobile_txt'>
  <TextField 
    sx={{width: "125px",marginTop:"2px" }}  id="Refer_Mobile_txt"  size="small" label="Refer Mobile" variant="outlined"  value={referalNumber} onChange={(e)=> {
         setReferalNumber(e.target.value)
         
 }} />
  </div>

 <div className='Candidate_Gender_txt'>
 <FormControl variant="outlined" sx={{width: 125,marginTop:"2px" }} size="small"  >
        <InputLabel id="demo-Candidate_Gender_txt-select-label"  >Gender</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="Candidate_Gender_txt"
          
          value={referalGender} onChange={(e)=> {
          
            setReferalGender(e.target.value);   

           } } >
        
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="others">Others</MenuItem>
        </Select>
      </FormControl> 
 </div>

 <div className="Add_Referal_Candidate">
	<input type="button" style={{width:"30px",height:"45px"}} onClick={AddData} value="+" />
	</div>
	<div className='Interviewer_Name_txt'>
    <Autocomplete
      disablePortal
      id="Interviewer_Name_txt"
      freeSolo
      options={hrnames}
      sx={{ width: 270 }}
      value={interViewerName || ""}
      disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
      onChange={(event,value)=>setInterViewerName(value)}
      renderInput={(params) => <TextField {...params}  
           
          sx={{width: 270 }}
          size="small" label="Interviewer Name"
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={interViewerName || ""} 
          onChange={(e)=> { 
          setInterViewerName(e.target.value);
        }} />}
         />
         </div>

         {user.accesstype != 5 && (status==="Selected"||status==="Joined"||status==="Joined & Left"||status==="Invoiced") ?<div  className='Applied_lbl' style={{textAlign:"center",color:"cornflowerblue", fontWeight:"bold"}}><h4>{status}</h4></div>:
        <div style={{textAlign:"center",color:"cornflowerblue", fontWeight:"bold"}} className='Interview_result_txt'><FormControl variant="outlined" sx={{minWidth: 270 }} size="small"  >
        {(roundResult && (roundResult==="Next Round"||roundResult==="Passed"))?"Interview Result:Passed":
        <><InputLabel id="demo-simple-select-label">Interview Result</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
         
          id="demo-simple-select"
         
          value={result} 
          onChange={(e)=> {
            setResult(e.target.value);   
     } } >
         {round!=="Joined" ?   <MenuItem value="Applied">Applied</MenuItem> :<></>}
         {round!=="Joined" ? <MenuItem value="Scheduled">Scheduled</MenuItem>:<></>} 
         {round!=="Joined" ?  <MenuItem value="Not Reachable">Not Reachable</MenuItem>:<></>}
         {round!=="Joined" ?  <MenuItem value="Rejected">Failed</MenuItem>:<></>}
         {round!=="Joined" && round!=="Client Round" ? <MenuItem value="Next Round">Passed</MenuItem>:<></>}
         {round!=="Joined" ? <MenuItem value="Cancelled">Cancelled</MenuItem>:<></>}
        {round==="Client Round" ? <MenuItem value="Selected">Selected</MenuItem>:<></>}
        {round==="Client Round" ? <MenuItem value="Not Joined">Selected & Not Joined</MenuItem>:<></>}
        {round==="Client Round" ? <MenuItem value="Joined">Joined</MenuItem>:<></>}
        {round==="Joined" ?   <MenuItem value="Joined & Left">Joined & Left</MenuItem>:<></>}
        {round==="Joined" ?   <MenuItem value="Invoiced">Invoiced</MenuItem>:<></>}

        </Select></>}
      </FormControl>
      </div>
      }


 {result==="Next Round"? <div id="Next_Round_On_lbl">
		<span>{result==="Next Round"?"Next Round On: ":(result==="Joined" && props.interview.status!=="Joined")?"Joined Date":""}</span>
	</div>:""}
  <div className='NextInterviewDateTime'>
  {result==="Next Round" ||(result==="Joined" && props.interview.status!=="Joined")?<input type="datetime-local" id='NextInterviewDateTime' format="dd-mm-yyyy" style={{width:"180px",height:"35px",backgroundColor:"transparent",border:"1px solid", padding:"2px 6px"}} disabled={(roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"} value={nextinterviewDate}  onChange={(e) =>setNextInterviewDate(e.target.value)}/>:""}
  </div>
  
<div className='Suggestion_for_Interview'>
  {result==="Not Reachable" ?<button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}}  onClick={e=>{setFeedback("Switched Off");setMobileStatus("Your number is switched off.");}}>Switched Off</button>:null}
      {result==="Not Reachable" ?<button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Not received call");setMobileStatus("Your number is not reachable.");}}>Not received</button>:null}
      {result==="Not Reachable" ?<button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Out of network");setMobileStatus("Your number is out of network.");}}>Out of network</button>:null} 
      {result==="Not Reachable" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("On another call");setMobileStatus("Your number is busy on another call.");}}>Another call</button>:null}
      {result==="Not Reachable" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Disconnecting");setMobileStatus("We are unable to connect with you.");}}>Disconnecting</button>:null}
      {result==="Not Reachable" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Some other person received the call.");setMobileStatus("Some other person received our call.");}}>Other Person Picked</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Worst Communication")}>Worst Comm</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Poor Communication")}>Poor Comm</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs work from home")}>WFH</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs Part Time Job")}>Part Time</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs non Voice Job")}>Non Voice</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs High salary")}>High salary</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Not interested")}>Not interested</button>:null}
      {result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>feedback.includes("Selected for Voice Process.")?setFeedback(feedback.replace(" Selected for Voice Process.",'').replace("Selected for Voice Process.",'')): setFeedback((feedback==''?'':feedback+' ')+"Selected for Voice Process.")}>Voice</button>:null}
      {result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>feedback.includes("Selected for Non-Voice Process.")?setFeedback(feedback.replace(" Selected for Non-Voice Process.",'').replace("Selected for Non-Voice Process.",'')): setFeedback((feedback==''?'':feedback+' ')+"Selected for Non-Voice Process.")}>Non-Voice</button>:null}
      {result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>feedback.includes("Selected for work from home Process.")?setFeedback(feedback.replace(" Selected for work from home Process.",'').replace("Selected for work from home Process.",'')): setFeedback((feedback==''?'':feedback+' ')+"Selected for work from home Process.")}>WFH</button>:null}
     
      {result==="Rejected" ||result==="Not Reachable" ||result==="Cancelled" ||result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("")}>Clear</button>:null}
      {result==="Cancelled"? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Not interested")}>Not Interested</button>:null}
      {result==="Cancelled"? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Duplicate Record")}>Duplicate</button>:null}
      </div>
  <div className='English_Rating_txt'>
  <FormControl variant="outlined" sx={{ width: 270,marginTop:"2px" }} size="small"  >
        <InputLabel id="English_Rating_txt-label" > English Ratings</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="English_Rating_txt"
          
          value={egr} onChange={(e)=> {
          
            setEgr(e.target.value);   

           } } >
        
          <MenuItem value={5}>A - Excellent</MenuItem>
          <MenuItem value={4}>B - Good</MenuItem>
          <MenuItem value={3}>C - Average</MenuItem>
          <MenuItem value={2}>D - Bad</MenuItem>
          <MenuItem value={1}>E - Pathetic</MenuItem>
        </Select>
      </FormControl> 
  
  </div>

    <div className='Communication_Rating_txt'>
    <FormControl variant="outlined" sx={{ width: 270,marginTop:"2px" }} size="small"  >
        <InputLabel id="Communication_Rating_txt-label"  >Communication Ratings</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="Communication_Rating_txt"
          
          value={cmr} onChange={(e)=> {
          
            setCmr(e.target.value);   

           } } >
        
          <MenuItem value={5}>A - Excellent</MenuItem>
          <MenuItem value={4}>B - Good</MenuItem>
          <MenuItem value={3}>C - Average</MenuItem>
          <MenuItem value={2}>D - Bad</MenuItem>
          <MenuItem value={1}>E - Pathetic</MenuItem>
        </Select>
      </FormControl>
    </div>
	
  
<div className='Previous_Feedback_txt'>
<TextField
          id="outlined-multiline-flexible"
          //className="BOXLEFT"
          sx={{width: 290}}
          style={{marginRight:"20px"}}
          size="small"
          label="Previous Feedback"
          multiline
          rows={4} 
          value={prevfeedback || "" }
        //    onChange={(e)=> {
        //   setPrevFeedback(e.target.value);
        // }} 
        /> 
</div>
	
<div className='Enter_your_feedback_txt'>
<TextField
          id="Enter_your_feedback_txt"
          sx={{width: 290,marginBottom:"0px" }}
          size="small"
          label="Enter Your Feedback"
          multiline
          rows={2} 
          value={feedback || "" }
           onChange={(e)=> {
          setFeedback(e.target.value);
        }} />
</div>

<div className='Bookmark_Chck'>
<input type="checkbox" id="Bookmark_Chck" name="bookmark" checked={bookMark} onChange={()=>setBookMark(!bookMark)}/>
</div>
	<div id="Bookmark_lbl">
		<span>Bookmark</span>
	</div>

<div className='Schedule_Bookmarkcmts'>
<input type="text" style={{width:"175px",height:"50px",border:"1px solid", padding:"2px 6px"}} id="Schedule_Bookmarkcmts" name="bookmarkComments" placeholder="Bookmark Comments" disabled={(!props.interview && !bookMark)|| (!props.interview.bookmark&& !bookMark) || props.interview.bookmark===bookMark} value={bookMarkCmts} onChange={(e)=>setBookMarkCmts(e.target.value)} />
</div>


<div className='Open_Next_txt'>
<input type="checkbox" id="Open_Next_txt" name="chkOpenNext" checked={openNext} onChange={()=>setOpenNext(!openNext)}/>
</div>
<div id="Open_Next_lbl">
		<span>Open Next</span>
	</div> 

  {(result ==='Not Reachable'||result ==='Next Round' || result ==='Rejected'||result ==='Cancelled')? <div className='WhatsApp_Status_Chck'>
<input type="checkbox" id="WhatsApp_Status_Chck" name="chkWhatsapp" checked={whatsappStatus} onChange={()=>setWhatsappStatus(!whatsappStatus)}/>
</div>:""}

{(result ==='Not Reachable'||result ==='Next Round' || result ==='Rejected'||result ==='Cancelled')?<div id="WhatsApp_Status_lbl">
		<span>WhatsApp Status</span>
	</div>:""} 

<div className='Send_Email_txt'>
{companyName && companyName.split(" - ")[0]==="Focus Edumatics"? <input type="button" id="Send_Email_txt" name="chkEmail" style={{border:"1px solid", padding:"2px 6px"}} value="Send Email" onClick={()=>{setSendEmail(!sendEmail);SendEmail()}}/>:""}
</div>

<div className='Update_btn_1'> 
{(user.accesstype != 5 && (status==="Selected"||status==="Joined"||status==="Joined & Left"||status==="Invoiced"))||(user.accesstype != 5 && (round == "Verification" || hrName!=user.name)) ?null:<Button type="reset" title="Update" disabled={!result}  variant="contained" onClick={()=>EditInterview()} >Update</Button> }
</div>
{/* <svg class="Update_btn_1">
		<rect id="Update_btn_1" rx="11" ry="11" x="0" y="0" width="172" height="53">
		</rect>
	</svg>
  <div id="Update_lbl">
		<span>Update</span>
	</div> */}
 
	
  
	<svg class="ScheduleInterviewPointer1">
		<ellipse id="ScheduleInterviewPointer1" rx="7" ry="7" cx="7" cy="7">
		</ellipse>
	</svg>

	<svg class="ScheduleInterviewProgress1" viewBox="0 0 234 1">
		<path id="ScheduleInterviewProgress1" d="M 0 0 L 234 0">
		</path>
	</svg>

	<svg class="ScheduleInterviewApplicantPointer">
		<ellipse closeFull={()=>{setOpenScheduler(false);props.close(openNext)}} onClick={()=>showApplicant()} id="ScheduleInterviewApplicantPointer" rx="7" ry="7" cx="7" cy="7">
		</ellipse>
	</svg>

	<svg class="SchedulePath_1" viewBox="18.881 193.052 389.968 2677.099">
		<path id="SchedulePath_1" d="M 85.56208801269531 193.6184234619141 L 87.39723968505859 193.6184234619141 L 103.7058486938477 225.7629699707031 L 408.8490905761719 225.7629699707031 L 408.8490905761719 2870.150390625 L 213.97705078125 2870.150390625 L 19.56823921203613 2870.150390625 L 18.88072967529297 226.8946838378906 L 71.920654296875 225.7629699707031 L 86.46845245361328 193.0517578125">
		</path>
	</svg>
	
	<img id="ScheduleInterviewcalendar1" src="calendar1.png" srcset="calendar1.png 1x, calendar1@2x.png 2x" />
		
	<img closeFull={()=>{setOpenScheduler(false);props.close(openNext)}} onClick={()=>showApplicant()} id="ScheduleInterviewuserPage" src="user4.png" srcset="user4.png 1x, user4@2x.png 2x" />
		
</div>
      </Modal>





      {/* Modal for nearby locations */}
      <Modal
        open={openLocationModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
      >
        <Box sx={NearbyLocations}>
        <div>
         
          <h4 className="fnt2"><b>NearBy Locations</b>
        
        <Button title="Close" size="small" variant="Standard" sx={{ bgcolor: "red",float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>handleClose()} > <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
       </h4>
         </div>
    
       <div style={{height:750,width: '100%',margin:"0px"}}>
       <DataGrid 
        initialState={{
        sorting: {
        sortModel: [{ field: 'distance', sort: 'asc' }],
        },
       }}
        rows={rowData} 
        columns={cols}
       components={{
          Toolbar: GridToolbar,
        }}

          />
       </div>
      
        </Box>
      </Modal>

    {/* <ApplicantPopup open={openApplicant} showAlert={props.showAlert} applicantId={applicantId} close={job=>{
     
      setJobId(job);
      if(jobId===job){
        LoadSelectedJob(jobId)
      }
     }}  
    /> */}

    <InterviewApplicantPopup open={openApplicant} highlight={{candidatePreference}} showAlert={props.showAlert} applicantId={applicantId} close={job=>{
     setJobId(job);
     if(jobId===job){
       LoadSelectedJob(jobId)
     }
    }}/>

   <EditJobs type={"View"} openJobs = {openJDModel} jobsData={jobsDataProps} showAlert={props.showAlert} close={closeJDModel}/>
   
    {/* Model for top Request cards*/}
    {/* <Modal
        open={openJDModel}
        onClose={closeJDModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
          <Box sx={JdModal}>
        <br/>
      <div style={{display:"block",width:"100%"}}>
     {/* <h2 style={{backgroundColor:"white",width:"95%",color:"#14c871",top:0,textAlign:"left",padding:"5px",position:"absolute",border:"1px solid #14c871"}}></h2>  */}
     {/* <Button title="Close" variant="contained" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,backgroundColor:"red"}} onClick={()=>closeJDModel()} ><CloseIcon/></Button>   
        
     <div style={{display:"inline-block",padding:"5px"}}>
     
      <Card className="Box" sx={{ maxWidth: 340, height:"auto",padding:"0px"}}>
      <CardActionArea>
        <CardContent>
        <div style={{backgroundColor:"cornflowerblue",color:"white"}}>
        <Typography className="data" gutterBottom variant="h7" component="div">
          <h5><b>{companyName?companyName:""}</b></h5>
          </Typography>
        </div>
          <Typography className="data" gutterBottom variant="h8" component="div">
           <b>Job Title:</b> {jobTitle}
          </Typography>
          <div style={{overflowY:"scroll", maxHeight:"450px"}}>
          <Typography gutterBottom variant="h8" component="div"> */}
           {/* <b>Experience:</b> {((job.minexpmonths - ( job.minexpmonths%12))/12)} Yr {job.minexpmonths%12} Mn to {((job.maxexpmonths - ( job.maxexpmonths%12))/12)} Yr {job.maxexpmonths%12} Mn */}
          {/* </Typography>

          <Typography  id="copyDes" gutterBottom variant="h8">
         <b>Job Description:</b>{ jobDescription?jobDescription:""} 
          </Typography>
         
         
          <Typography gutterBottom variant="h8" component="div">
         <b>Key Skill:</b>{keySkills?keySkills:""} 
          </Typography>
          </div>
        </CardContent>
      </CardActionArea>
        </Card>
         */}
       {/* <div style={{textAlign:"-webkit-center"}}>
         <CardActions style={{display:"table-cell", textAlign:"center",width:"100%"}}>
      
        {user.name!=user1name ?<Button size="small" title="Connect" variant="contained" style={{backgroundColor:"#14c871"}} onClick={(e) =>{ConnectionReq(reqId,"Connected")}}>
          Connect
        </Button>:""}
        {user.name==user1name ? <Button size="small" title="unfollow" variant="contained" style={{backgroundColor:"#14c871"}} onClick={(e) =>{ConnectionReq(reqId,"Cancelled")}}>
          Cancel
        </Button>:""}
        {user.name!=user1name ? <Button size="small" title="Ignore" variant="contained" style={{backgroundColor:"#14c871"}}  onClick={(e) =>{ConnectionReq(reqId,"Ignored")}}>
          Ignore
        </Button>:""}
        {user.name!=user1name ? <Button size="small" title="Block" variant="contained" style={{backgroundColor:"#14c871"}}  onClick={(e) =>{ConnectionReq(reqId,"Blocked")}}>
          Block
        </Button>:""}
       </CardActions>
       </div>      */}
       {/* </div>
    </div>
          </Box>
      </Modal> */} 
    <EmailTemplatesPopup jobTitle={jobTitle} toEmail={mail} open={openEmailTemplate} showAlert={props.showAlert} applicantId={applicantId} applicantName={name} applicant={{area,previousCurrent,expectedSalary,mobile,noticePeriod,totalExperience}} close={()=>setOpenEmailTemplate(false)}
      
    />
    </>
  )
}

export default ScheduleInterviewUI