import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import './InterviewDetails.css'
import ScheduleInterview from './ScheduleInterview';
import ScheduleInterviewUI from './ScheduleInterviewUI';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { UserContext } from './UserContext';
import TimePicker from '@mui/lab/TimePicker';
import { useSearchParams } from 'react-router-dom';
import BulkDataUpload from './BulkDataUpload';
import Checkbox from '@mui/material/Checkbox';
import Timer from './Timer';
import ApplicantPopup from './ApplicantPopup';


const RecruiterJobApplications = (props) => {
    const [searchParams] = useSearchParams();
    const { user, setUser} = useContext(UserContext);
    const [interview,setInterview] = useState([])
    
    useEffect(()=>{
        if(user){
            if(searchParams.get('jobid')){
                getInterviews()
            }
           
        }
      
    },[user])

    const getInterviews = ()=>{
  
        let apiURL=process.env.REACT_APP_API_URL + "/interview/getrecruiterinterviews/"+searchParams.get('jobid');
       
        let apiParams = {
            method:"GET", 
            headers:{
              authtoken: localStorage.getItem("loggedintoken")
            }
        };
        
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              console.log(repos.data)
              setInterview(repos.data)
             
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }
    

    const cols = [
          
        {field:'name', headerName: 'Name',width: 190 },
        {field:'createdbyname', headerName: 'Created By',width: 190, hide: true },
        {field:'mobile', headerName: 'Mobile No',width: 140},
        // {field:'location', headerName: 'Location',width: 190},
        // {field:'languages', headerName: 'Languages',width: 190},
        {field:'hrname', headerName: 'HR Name',width: 190},
       
        {field:'jobtitle', headerName: 'Job Title',width: 200},
        {field:'companyname', headerName: 'Company Name',width: 200},
      //   {field:'Resume', headerName: 'Resume',width: 150,
      //  
        {field:'interviewername', headerName: 'Interviewer Name',width: 190},
       
        {field:'interviewdate', headerName: 'Interview Date',type: 'date',
        valueGetter: ({ value }) => value && new Date(value).toDateString(), width: 140},
        // {field:'interviewtime', headerName: 'Interview Time',width: 140},
        {field:'rounds', headerName: 'Rounds',width: 150},
        {field:'interviewlocation', headerName: 'Interview Location',width: 200},
        
        {field:'status', headerName: 'Status',width: 110},
       
        ];

    const rowData = (interview||[])?.map((interview) => {
    let IntvwTime = interview.interviewtime?((interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);
    
        return{
      id: interview?._id,  //applicant id
      applicantid:interview?.applicantId, 
      jobid:interview?.jobId,  //interview id
      name: interview?.name,
      mobile: interview?.mobile,
      jobtitle:interview?.jobtitle,
      companyname:interview?.companyname,
      interviewername:interview?.interviewername,
      timespent:interview?.timeSpent,
      hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
      interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
      interviewtime:IntvwTime,
      interviewlocation:interview?.location,
      rounds:interview?.rounds,
      reference:interview?.reference,
      createdbyname:interview?.createdbyname,
      createdbyid:interview?.createdbyId,
      status:interview?.status,
      
        };
      });
      
  return (
   
     <div>

    <div style={{height:750,width: '100%'}}>
    
     <DataGrid rows={rowData}
      columns={cols}  
    
       components={{
          Toolbar: GridToolbar,
        }}
          />

 </div>

    </div>
  )
}

export default RecruiterJobApplications
