import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid,} from '@mui/x-data-grid'
import '../Styles/VacanciesMobUI.css';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container,Switch  } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';  
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeIcon from '@mui/icons-material/Mode';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import EditJobs from '../EditJobs';
import { set } from 'date-fns';
import JobCategory from '../JobCategory';
import JobSummary from '../JobSummary';
import JobLocations from '../JobLocations';
import JobCities from '../JobCities';




const VacanciesMobUI = (props) => {

  //usestate
    const [jobsData,setJobsData]=useState([]);
    const [recJobsData,setRecJobsData]=useState([]);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [mobile, setMobile] = useState("");
    const [WhatsNumber,setWhatsNumber] = useState("");
    const [gender, setGender] = useState("");
    const [address, setAddress] = useState("");
    const [applicant, setApplicant] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openApplicant, setOpenApplicant] = React.useState(false);
    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState("");
    const [openView, setOpenView] = React.useState(false);
    const [resume, setResume ] = useState();
    const [OpenShedule, setOpenScedule ]= React.useState();
    const [ApplicantId,setApplicantId]= useState()
    const [interviewlocation, setInterviewlocation] = useState();
    const [ExpYears, setExpYears]= useState();
    const [ExpMonth, setExpMonths]= useState();
    const [Dob, setDob ]= React.useState(new Date());
    const [ Skills,setSkills]= useState("");
    const [companyName, setCompanyName] = useState("");
    const [Reference, setReference]= useState();
    const [jobDescription, setJobDescription] = useState("");
    const [minYears, setMinYears] = useState(0);
    const [minMonths, setMinMonths] = useState(0);
    const [maxYears, setMaxYears] = useState(0);
    const [maxMonths, setMaxMonths] = useState(0);
    const [offices,setOffices] = useState([]);
    const [searchParams] = useSearchParams();
    const [officeName,setOfficeName]= useState("");
    const [mapUrl,setMapUrl] = useState("");
    const [latitude,setLatitude] = useState("");
    const [longitude,setLongitude]= useState("");
    const[resumeName,setResumeName]=useState();
    const [experience,setExperience]=useState();
    const [voiceProcess,setVoiceProcess] = useState(true)
    const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
    const [workFromHome,setWorkFromHome] = useState(false)
    const [workFromOffice,setWorkFromOffice] = useState(true)
    const [partTime,setPartTime] = useState(false)
    const [fullTime,setFullTime] = useState(true)
    const [nightShift,setNightShift] = useState(true)
    const [dayShift,setDayShift] = useState(true)
    const [cabRequired,setCabRequired] = useState(true)
    const [ownTransport,setOwnTransport] = useState(true)
    const [minCTC,setMinCTC] = useState(0)
    const [maxCTC,setMaxCTC] = useState(0)
    const [hrNames,setHRNames]= useState([])
    const { user, setUser} = useContext(UserContext);
    const [spocId,setSpocId]= useState(user?user._id:"")
    const [spocName,setSpocName]= useState(user?user.name:"")
    const [jobsDataProps,setJobsDataProps] = useState([]);
    const [toggle,setToggle]= useState(true);
    const [showJobs,setShowJobs] = useState(false);
    const [selectedJob,setSelectedJob] = useState([]);
    const [applicantData,setApplicantData] = useState([])
    const [selectedCategory,setSelectedCategory] = useState("");
    const [selectedSubCategory,setSelectedSubCategory] = useState("");
    const [cities,setCities] = useState([])

   
 useEffect(()=>{

  getJobsData()
  getRecruiterJobsData()
  getDistinctCities()
  },[])

  const getJobsData = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/jobs/getallvacancies";
    let apiParams = {
        method:"GET",
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            setJobsData(repos.data)
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const getRecruiterJobsData = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/recruiters/getallrecvacancies";
    let apiParams = {
        method:"GET",
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          console.log(repos.data)
            setRecJobsData(repos.data)
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }
  
  const getDistinctCities = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/cities/getallCities";
    let apiParams = {
        method:"GET",
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          console.log(repos.data)
            setCities(repos.data)
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }
  
   
 const handleClose = () => {
  resetApplicant()
  setOpenApplicant(false)
}

const resetApplicant = ()=>{
  console.log("jobsreset")
  setJobTitle("");
  setJobId("");
  setCompanyName("");
  setJobDescription("");
  setOfficeName("");
  setSkills("");
  setMinYears("");
  setMinMonths("");
  setMaxYears("");
  setMaxMonths("");
  setVoiceProcess(false)
    setNonVoiceProcess(false)
    setWorkFromHome(false)
    setWorkFromOffice(false)
    setPartTime(false)
    setFullTime(false)
    setNightShift(false)
    setDayShift(false)
    setCabRequired(false)
    setOwnTransport(false)
    setSpocId("");
    setSpocName("");
    setMaxCTC(0);
    setMinCTC(0);
}




function saveByteArray(reportName, byte, type) {
  let blob = new Blob([byte], { type: type });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);

  let fileName = reportName;
  link.download = fileName;

  let newLink = {
    name: fileName,
    link: link,
  };
  return newLink;
}
 

//Get Users
const getUsers = async () =>{
  let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
  let apiParams = {
    method: "GET",
    headers: {
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  await fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
          setHRNames(repos.data)
          }else props.showAlert(""+(repos.message || repos),"error");  
      }) 
      .catch((err) => props.showAlert((''+err),"error"));
    }


//Disable Job
 const updateIsActive = (job) =>{
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/jobs/updateisactive/" +job._id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false,    
      authtoken: localStorage.getItem("loggedintoken")
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
      // alert("Job Removed Successfully");
      props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      
       
     }else props.showAlert(""+(repos.message || repos),"error");
 }) 
 .catch((err) => props.showAlert((''+err),"error"));
 
}

  const getOffices = () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/getOfficelocations";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              //console.log(repos.data);
              if(repos.data.length>0)
              {
  
              setOffices(repos.data)
              
            }}else props.showAlert(""+(repos.message || repos),"error");  
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }


  const downloadResume=()=>{
    let type = resume.data.type;
    let bytes = new Uint8Array(resume.data.data);
    let newLink = saveByteArray(resumeName, bytes, type);
    newLink.link.click();
  }
  


  const MyCopyFunction = async (jd)=>{
    // setCopiedText(heading,jd)

     await navigator.clipboard.writeText(jd);
      //alert("Copied the text: " + jd);
      props.showAlert("JD Copied To ClipBoard","success")
    }

    const backButton=()=>{
      if(selectedCategory !== "" && selectedSubCategory === ""){
        setSelectedSubCategory("")
        setSelectedCategory("")
      }else if(selectedSubCategory !== ""){
        setSelectedSubCategory("")
      }
     
    }


    const getUniqueListBy=(arr, key) => {
      return [...new Map(arr.map(item => [item[key], item])).values()]
  }

  return (
    <div >
     
     <div id="mid_bar_rec">
	{/* <svg class="mid_deisgn_can_mob" viewBox="4712 94.843 375.876 653.157">
		<path id="mid_deisgn_can_mob" d="M 4712 112.312141418457 C 4712 112.312141418457 4714.06201171875 94.84273529052734 4732.60986328125 94.84273529052734 C 4751.15771484375 94.84273529052734 5087.87646484375 94.84273529052734 5087.87646484375 94.84273529052734 L 5087.87646484375 747.9999389648438 L 4712 747.9999389648438 L 4712 112.312141418457 Z">
		</path>
	</svg> */}
  <div id="Company_wise_mob">
  
  <div id="dream_job_grp">
		<svg class="dream_job_bd">
			<rect id="dream_job_bd" rx="15" ry="15" x="0" y="0" width="100%" height="150">
			</rect>
		</svg>
		<img id="dream_job_im" src="n_1_Success-1-removebg.png" srcset="n_1_Success-1-removebg.png 1x" alt='reload' />
			
		<div id="Find_Your_Dream_Job_lbl_">
			<span>Find Your Dream Job</span>
		</div>
    {(selectedCategory !== "" || selectedSubCategory !=="") ? <div className='dream_job_back' onClick={()=>backButton()}>
    <img  id="dream_job_back" src="undo.png" srcset="undo.png 1x,undo@2x.png 2x"/>
    </div>:""}

		<marquee id="Find_bl_marqu">
			<span>*This portal is strictly for free Recruitment. Please report to us if any recruiter asks any amount from you.</span>
		</marquee>
	</div>

  <div style={{display:"block",width:"100%",textAlign:"center"}}>
    {/* {console.log(getUniqueListBy(jobsData.map(row=>{return {companyname:row.companyname,logo:row.officeLogoUrl}}),"companyname"))} */}
    {selectedCategory === ""? (cities.map((c,idx)=>(
    <div style={{display:"inline-block"}} onClick={()=>{setSelectedCategory(c.city);}}>
{
  <JobCities city={c?c.city:""} idx={idx} image={c?c.logo:""} count={c?c.count:0}/>
}
    </div>
    ))):""}
  <div>
         {(selectedCategory !=="" && selectedSubCategory === "")?(getUniqueListBy(jobsData.map(row=>{return {companyname:row.companyname,logo:row.officeLogoUrl}}),"companyname")).map((job,idx)=>( 
        <div style={{display:"inline-block",padding:"5px",height:"100%",verticalAlign:"top"}} onClick={()=>{setSelectedSubCategory(job.companyname);}}>
         { 
        <JobCategory name={job?job.companyname:""} idx={idx} image={job?job.logo:""} count={jobsData.filter(item=>item.companyname===job.companyname && item.city===selectedCategory).length}/>
         }
         </div>
        ))
:""}

  {selectedSubCategory !== ""? jobsData.filter(item=>item.companyname===selectedSubCategory && item.city===selectedCategory).map((job,idx)=>(
      <div style={{display:"inline-block",padding:"5px",height:"100%",verticalAlign:"top"}}>
      <JobSummary  job={job} idx={idx} showAlert={props.showAlert}/>
      </div>
      )):""}
</div> 
<br/>
{/* Other Recruiters */}
 <div>
         {(selectedCategory !=="" && selectedSubCategory === "")?(getUniqueListBy(recJobsData.map(row=>{return {companyname:row.companyName,logo:row.officeLogoUrl}}),"companyname")).map((job,idx)=>( 
        <div style={{display:"inline-block",padding:"5px",height:"100%"}} onClick={()=>{setSelectedSubCategory(job.companyname);}}>
         { 
        <JobCategory name={job?job.companyname:""} idx={idx} image={job?job.logo:""} count={recJobsData.filter(item=>item.companyName===job.companyname && item.city===selectedCategory).length}/>
         }
         </div>
        ))
      :""}

     {selectedSubCategory !== ""?  recJobsData.filter(item=>item.companyName===selectedSubCategory && item.city===selectedCategory).map((job,idx)=>(

      <div style={{display:"inline-block",padding:"5px",height:"100%",verticalAlign:"top"}}>
      <JobSummary  vacancy={job} idx={idx}  showAlert={props.showAlert}/>
      </div>
      )):"" }
         </div>  
    </div> 
    {/* <div>
     <JobLocations/>
    </div> }		 */}
</div>
</div>
</div>

  );
}

export default VacanciesMobUI
