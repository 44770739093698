import React, { useEffect, useState, useContext, useRef,Fragment } from 'react'
import "./EmployeeChats.css"
import { DataGrid } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { UserContext } from './UserContext';
import { Button} from '@mui/material';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import  {TextField,Container,Autocomplete} from '@mui/material';
import OnlinePredictionSharpIcon from '@mui/icons-material/OnlinePredictionSharp';
import BackupIcon from '@mui/icons-material/Backup';
import Timer from './Timer';
import ScheduleInterview from './ScheduleInterview';
import SearchIcon from '@mui/icons-material/Search';
import ApplicantPopup from './ApplicantPopup';
import Modal from '@mui/material/Modal';
import Peer from 'simple-peer';
import { parse, set } from 'date-fns';
import { clear } from '@testing-library/user-event/dist/clear';
import {io} from 'socket.io-client';
import CallIcon from '@mui/icons-material/Call';
import EmployeeDashboard from './EmployeeDashboardMob';


const Dashboard = (props) => {
  
//Socket
const ioSocket = io(process.env.REACT_APP_API_URL);
// ioSocket.on('connection',(socket)=>{
 

// })  

    //useStates
     const [users,setUsers]=useState([]);
    const [status,setStatus]=useState();
    const [rowData,setRowData]=useState([]);
    const [progress, setProgress] = React.useState(0);
    const [fromDate, setFromDate] = useState(new Date().toISOString().substring(0,10));
    const [toDate, setToDate] = useState(new Date().toISOString().substring(0,10));
    const [ loadTime, setLoadTime] = useState(new Date());
    const [showOnlyHR,setShowOnlyHR]=useState(true);
    const [selectedRow,setSelectedRow] = useState();
    const { user, setUser} = useContext(UserContext);
    const [searchData, setSearchData] = useState("");
    const [openShedule, setOpenScedule ]= React.useState();
    const [openApplicant,setOpenApplicant] = useState();
    const [open,setOpen] = useState(false);
    const [data ,setData] = useState("mobile");
    const isCallingActive = useRef(false);
    const [stopComments,setStopComments] = useState("");
    const [openStopModel,setOpenStopModel] = useState(false);
    const [userStatusId,setUserStatusId]= useState("");
    const [result,setResult] = useState([]);
    const [openLoginHours, setLoginHours] = React.useState(false);
    const [loginDetails,setLoginDetails] = useState([]);
    const [isLoading,setIsLoading] = useState(true);
    const alertStart = useRef(new Date());
    const [empCallingTime,setCallingTime] = useState("");
    const [room,setRoom] = useState("");
    const [currentMessage,setCurrentMessage] = useState("")
    const [callFilterText,setCallFilterText] = useState("Graduate");
    const [filterCallsByEducation,setFilterCallsByEducation] = useState(false);
    const [messageList,setMessageList] = useState([]);
    const longCallMsg = "I am on Call from long time!!";
    const longTimeIdleMsg = "I am idle since long time!";
    //const [stream,setStream] = useState(null);
    const [socketID,setSocketID] = useState("");
  const roomIdRef = useRef("");  
  const audio = useRef();  
//const agentAudio = useRef(null)




 

//Socket UseEffect

useEffect (()=>{

    ioSocket.on("init_message",(data)=>{
  // console.log(data)
  setMessageList((list)=>data)
  const element = document.getElementById("chatBody");
    element.scrollTop = element.scrollHeight;
})


  ioSocket.on("recieve_message",(data)=>{
    // console.log(data)
    setMessageList((list)=>[...list,data])
    const element = document.getElementById("chatBody");
      element.scrollTop = element.scrollHeight;
      
  })




},[ioSocket])



useEffect (()=>{
  
  joinRoom()
  
 
  },[])

  // useEffect (()=>{
 
  //   if(open === true)
  //   {  
  //     ioSocket.emit("send_message",{
  //     userName : user.name,
  //     message : openShedule? longCallMsg:longTimeIdleMsg,
  //     time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
  //   });
  // }
  //   },[open])

//sending messages from socket 

const sendMessage = async ()=>{
 
  if(currentMessage!=="")
  { 
    const messageData = {
      userName : user.name,
      message : currentMessage,
      time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
    };

    ioSocket.emit("send_message",messageData);
     //setMessageList((list)=>[...list,messageData]);
     setCurrentMessage("");
  }
}


//Join Room from socket 

const joinRoom = ()=>{

  ioSocket.emit("join_room",room)

}


//get Chats status
const getAllChats = () =>{
      
  let apiURL=process.env.REACT_APP_API_URL + "/getchats"; 
  let apiParams = {
      method:"GET",
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
        setMessageList((list)=>[...list,repos.data])
      }else props.showAlert(""+(repos.message || repos),"error"); 
  }) 
  .catch((err) =>props.showAlert((''+err),"error"));
}

  //Variables
  var checkIdle=()=>
  {
     //let date = ("Wed Nov 02 2022 18:57:25 GMT+0530 (India Standard Time)")
    
   if( (isCallingActive.current === true) && ((new Date() - alertStart.current) > 300000))
  {
    setOpen(true);
    setTimeout(()=>checkIdle(),30000)  
  }
  }
     

const addApplicants = () =>{
  setOpenApplicant(true)
}





    

    //View Data 
    const viewData = (row) =>{

    window.location.href= "/interviewdetails?selectedCell=" + row;
    }
  

   
   




    

//Open alert popup
      const handleClose = () =>{
      
        setOpen(false);
        
      }

//get interview status
      const getLoginDetails = (id) =>{
      
            let apiURL=process.env.REACT_APP_API_URL + "/usercallingstatus/logindetails/"+id; 
            let apiParams = {
                method:"GET",
                headers:{
                  authtoken: localStorage.getItem("loggedintoken")
                }
            };
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  // console.log(repos.data);
                  setLoginDetails(repos.data);
                }else props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
      }


      
 
   
   
        function arrayBufferToBase64(buffer) {
          var binary = "";
          var bytes = [].slice.call(new Uint8Array(buffer));
          bytes.forEach((b) => (binary += String.fromCharCode(b)));
          var base64Flag = "data:image/jpeg;base64,";
          return base64Flag + window.btoa(binary);
        }
      
      


        const selectFile = (e)=>{
          //setCurrentMessage(e.target.files[0].name);
            var file = e.target.files[0]; //the file
            var reader = new FileReader(); //this for convert to Base64
            reader.readAsDataURL(e.target.files[0]); //start conversion...
            reader.onload = function (e) {
              //.. once finished..
              var rawLog = reader.result.split(",")[1]; //extract only thee file data part
              var dataSend = {
                dataReq: { data: rawLog, name:file.name, type: file.type },
                fname: "uploadFilesToGoogleDrive",
              }; //preapre info to send to API
              fetch(
                process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
                { method: "POST", body: JSON.stringify(dataSend) }
              ) //send to Api
                .then((res) => res.json())
                .then((data) => {
                  // console.log(data.url)
                  ioSocket.emit("send_message",{
                    userName : user.name,
                    message : "href:"+file.name+"@&@"+data.url+"</a>",
                    time : ("000"+new Date(Date.now()).getHours()).slice(-2) + ":" + ("000"+new Date(Date.now()).getMinutes()).slice(-2),
                  });
                  props.showAlert("Resume Uploaded Successfully", "success");
                })
                .catch((e) => props.showAlert((e),"error")); // Or Error 
            };
          };



      







  return (
  <div>

  <br/>
     {/* <Box
      sx={{
        height: 300,
        '& .super-app-theme--header': {
          backgroundColor: 'cornflowerblue',
          color:"white",
        },
      }}
    >
    <div style={{display:"block",textAlign:"center"}}>
     <div style={{width:"360px",display:"inline-block",verticalAlign:"top",textAlign:"center"}}>
    <div className='chat-window'>
     <div id="chatBody" style={{height:600,width:"360px",border:"2px solid black",overflowY:"scroll"}} className='chat-body'>
     {messageList.map((content)=>{
     return (
      <div className='message' id={user.name === content.userName ? 'you':'other'}>
      <div className='message-body'>
      <div className='message-content' style={{border:(((new Date()-new Date(new Date(new Date().setHours(parseInt(content.time.split(':')[0]))).setMinutes(parseInt(content.time.split(':')[1]))))/60000<2)?"2px solid red":"0px")}}>
        <p id={user.name === content.userName ? 'you':'other'}>{content.message.substring(0,5)==="href:"?<a  href={content.message.split('@&@')[1]} target="_blank"  style={{color:"white",fontWeight:"bold"}}>{content.message.split('@&@')[0].split(':')[1]}</a>:content.message}</p>
      </div>
      <div className='message-meta'>
      <p id={user.name === content.userName ? 'you':'other'}>{content.time}</p>
      <p id='author' style={{backgroundColor:(content.message===longCallMsg||content.message===longTimeIdleMsg?"red":"white"),color:(content.message===longCallMsg||content.message===longTimeIdleMsg?"white":"black")}}>{content.userName}</p>
      </div>
      </div>
      </div>
     );
     })
     }
    </div>
    </div>
    <div >

     <input className='container inputfield' type="text" style={{width:"auto",marginRight:"5px"}}  placeholder="Enter the Text Here!" onKeyPress={(event)=>{event.key==="Enter" && sendMessage() }} value={currentMessage} onChange={(e)=>setCurrentMessage(e.target.value)}/>
   <input type="button" value="Send" className="inputfield" style={{fontSize:"large"}} onClick={()=>sendMessage()}/>
    <AttachFileIcon
              style={{ marginLeft: "2px",marginRight: "3px", color: "cornflowerblue" }}
              onClick={(e) =>
                document
                  .getElementById("candidateResume"+user._id)
                  .click()
              }
            />
            <input
              id={"candidateResume"+user._id}
              type="file"
              placeholder="Upload Resume"
              style={{ display: "none" }}
              name="Resume"
              onChange={(e) =>{
                selectFile(e)
              }}
            ></input>
    </div>
     </div>
 </div>

 
 </Box> */}
 {/* Chat Modal */}

{/* <Modal
        open={openChatsModal}
        onClose={handleChatsClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      > */}
      
<div id="chat_box_new">
	<div id="Group_5_new">
		{/* <svg class="ChatsPath_new" viewBox="13.052 143.31 406.521 774.64">
			<path id="ChatsPath_new" d="M 356.8659973144531 179.0000305175781 L 378.2976379394531 143.3096160888672 L 396.372802734375 179.0000305175781 L 419.1752319335938 179.0000305175781 L 419.5724792480469 917.9495849609375 L 17.73760223388672 917.3720092773438 L 13.05157566070557 178.8830261230469 L 29.18402099609375 178.8830261230469 L 356.8659973144531 179.0000305175781">
			</path>
		</svg> */}
    <div className='ChatsPath_new'>
    <div style={{display:"block",textAlign:"center"}}>
     <div style={{width:"360px",display:"inline-block",verticalAlign:"top",textAlign:"center"}}>
    <div className='chat-window'>
     <div id="chatBody" style={{height:700,width:"360px",border:"2px solid black",backgroundColor:"white",overflowY:"scroll"}} className='chat-body'>
     {messageList.map((content)=>{
     return (
      <div className='message' id={user.name === content.userName ? 'you':'other'}>
      <div className='message-body'>
      <div className='message-content' style={{border:(((new Date()-new Date(new Date(new Date().setHours(parseInt(content.time.split(':')[0]))).setMinutes(parseInt(content.time.split(':')[1]))))/60000<2)?"2px solid red":"0px")}}>
        <p id={user.name === content.userName ? 'you':'other'}>{content.message.substring(0,5)==="href:"?<a  href={content.message.split('@&@')[1]} target="_blank"  style={{color:"white",fontWeight:"bold"}}>{content.message.split('@&@')[0].split(':')[1]}</a>:content.message}</p>
      </div>
      <div className='message-meta'>
      <p id={user.name === content.userName ? 'you':'other'}>{content.time}</p>
      <p id='author' style={{backgroundColor:(content.message===longCallMsg||content.message===longTimeIdleMsg?"red":"white"),color:(content.message===longCallMsg||content.message===longTimeIdleMsg?"white":"black")}}>{content.userName}</p>
      </div>
      </div>
      </div>
     );
     })
     }
    </div>
    </div>
    <div >

     <input className='container inputfield' type="text" style={{width:"auto",marginRight:"5px"}}  placeholder="Enter the Text Here!" onKeyPress={(event)=>{event.key==="Enter" && sendMessage() }} value={currentMessage} onChange={(e)=>setCurrentMessage(e.target.value)}/>
   <input type="button" value="Send" className="inputfield" style={{fontSize:"large"}} onClick={()=>sendMessage()}/>
    <AttachFileIcon
              style={{ marginLeft: "2px",marginRight: "3px", color: "cornflowerblue" }}
              onClick={(e) =>
                document
                  .getElementById("candidateResume"+user._id)
                  .click()
              }
            />
            <input
              id={"candidateResume"+user._id}
              type="file"
              placeholder="Upload Resume"
              style={{ display: "none" }}
              name="Resume"
              onChange={(e) =>{
                selectFile(e)
              }}
            ></input>
    </div>
     </div>
 </div>
    </div>
    </div>

    <div id="user_name_lbl_b">
		<span>{user.name}</span>
	</div>
	<div id="Welcome_1_b">
		<span>Welcome </span>
	</div>
	<svg class="chat_btn_b">
		<ellipse onClick={()=>props.chats(false)} id="chat_btn_b" rx="31" ry="31" cx="31" cy="31">
		</ellipse>
	</svg>
	<img onClick={()=>props.chats(false)} id="chat_lbl_b" src="chat_lbl_b.png" srcset="chat_lbl_b.png 1x, chat_lbl_b@2x.png 2x" />
		

	</div>


 

 </div>
  )
}

export default Dashboard