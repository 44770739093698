// Not in use

import React,{useState,useEffect} from 'react'
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import emailSend from './emailSend.css';
import {Link, useNavigate} from "react-router-dom";
import { UserContext } from './UserContext';
import { useSearchParams } from 'react-router-dom';
var CryptoJS = require("crypto-js");


const CandidatePasswordReset = (props) => {
    var navigate = useNavigate();
    const [ email,setEmail] = useState("");
    const [ applicantID,setApplicantID] = useState("");
    const [otp,setOtp] = useState("");
    const [searchParams] = useSearchParams();
    const [inputField,setInputField] = useState({
        password:"",
        cpassword:"",
    });
    const [errField,setErrField] = useState({
        // otpCodeErr:"",
        passwordErr:"",
        cpasswordErr:"",
    });


    useEffect(() => {
        if(searchParams.get('otp')){
      
        setOtp(searchParams.get('otp'))
       }
       if(otp){
        getapplicantId()
       }
    }, [otp])
      
   
    const validForm = () =>{
        let formIsValid = true;
        setErrField({
        //otpCodeErr:"",
        passwordErr:"",
        cpasswordErr:"",
        })
        // if(inputField.otpCode == ''){
        //    formIsValid = false
        //     setErrField(prevstate => ({
        //         ...prevstate,otpCodeErr:"Please Enter OTP"
        //     }))
        // }
        if(inputField.password == ''){
           formIsValid = false
            setErrField(prevstate => ({
                ...prevstate,passwordErr:"Please Enter Password"
            }))
        } 
       
        if(inputField.password != inputField.cpassword){
           formIsValid = false
            setErrField(prevstate=>({
                ...prevstate,cpasswordErr:'Password are Not Matched'
            }))
        }
        
        return formIsValid

    }
    async function encrypt(plainText) {
      return CryptoJS.AES.encrypt(plainText,"$P@$$B1z!");
    }

    const inputHandler = (name,value)=>{
        setInputField({...inputField,[name]:value})
    }

const sendUpdatedPassword = async()=>{
if(validForm()){   

    let apiUrl = process.env.REACT_APP_API_URL + "/users/setapplicantpassword";
    let apiParam = {
          method: 'POST',
            headers:{
                 email:email,
                //  applicantid:applicantID,
                 otp:otp,
                 password:encodeURIComponent(await encrypt(inputField.password)),
                 cpassword:encodeURIComponent(await encrypt(inputField.cpassword)),
            },
 
        };
    
fetch(apiUrl, apiParam)
.then((res) => (res.status === 200 ? res.json() : res.text()))
.then((repos) => {
  if (repos.status === 200) {
    
    props.showAlert("Password has been reset successfully","success")
navigate('/')

  } else {
    props.showAlert("Invalid OTP ","error")
  }
})
.catch((err) => props.showAlert((''+err),"error"));

}
else{
    props.showAlert("Form Invalid ","error")
}
}

//Get applicant id through search params

const getapplicantId = ()=>{

    let apiURL = process.env.REACT_APP_API_URL + "/users/getemail/"+otp;
    let apiParams = {
      method: "GET",
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
                console.log(repos.data)
            //  setApplicantID(repos.data.applicantId)
            setEmail(repos.data.email)
            }else  props.showAlert(""+JSON.stringify(repos.message || repos),"error");  
        }) 
        .catch((err) =>  props.showAlert(""+JSON.stringify(err),"error"));
 

}


const backtoLogin = () =>{
    navigate("/");
  }
  

  return (
    <div>
        <div className='child'>
    <h3 className='reset' ><b>Set Password</b></h3>
   <br/>
      <TextField id="" sx={{ m: 2, width: '35ch' }} label="Enter New Password" size="small" variant="outlined" type="password"  value={inputField.password} onChange={e=>
    inputHandler("password",e.target.value)
     }/>{
        errField.passwordErr.length > 0 && <span className ='error'><br/>{errField.passwordErr}</span>
     }
     <br/>
      <TextField id="" sx={{ m: 2, width: '35ch' }} label="Confirm Password" size="small" variant="outlined" type="password"  value={inputField.cpassword} onChange={e=>
     inputHandler("cpassword",e.target.value)
     }/>{
        errField.cpasswordErr.length > 0 && <span className ='error'><br/>{errField.cpasswordErr}</span>
     }
  <br/>
  <br/>
  <Button variant="contained" sx={{ m: 1, width: '25ch' }} onClick={(e)=>sendUpdatedPassword()}>Set Password</Button>
  <Button variant="contained" sx={{m:1, width: '12ch' }}   style={{float:"right"}} onClick={(e)=>backtoLogin()}>Back</Button>
    </div>
    </div>
  )
}

export default CandidatePasswordReset