import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import './JobCities.css';
import CardContent from '@mui/material/CardContent';
import { Button, CardActionArea, CardActions } from '@mui/material';



const JobCities = (props) => {

  return (
    <>{props.count > 0 && 
    <div style={{padding:"5px",height:"100%"}}>
          {/* <Card id='card_city_grp' >

<CardActionArea>
  <CardContent>
  
  
 <img id={"img_city"+props.idx} style={{width:"150px",height:"150px",objectFit:"contain"}} src={props.image?"https://drive.google.com/uc?export=view&id="+(props.image.split('/')[5]):"img_city.png"}  alt='reload' /> 

 <div id="Jobs__7__lbl">
   
    <b>{props.city?props.city:"Bangalore"}</b>
   
  </div>
   
    <div id='Bangalore_lbl_city'>
     Company:{props.count?props.count:"7"}
    </div>
  </CardContent>
</CardActionArea>
</Card> */}
 <div id="card_city_grp">
 <svg class="bg_bdr_new">
			<rect id="bg_bdr_new" rx="12" ry="12" x="0" y="0" width="171" height="194">
			</rect>
	</svg>
		{/* <img id="img_city" src="img_city.png" srcset="img_city.png 1x" /> */}
    <img id={"img_city"+props.idx} className='img_city' style={{width:"130px",height:"130px",objectFit:"cover"}} src={props.image?"https://drive.google.com/uc?export=view&id="+(props.image.split('/')[5]):"img_city.png"}  alt='reload' />
			
		{/* <div id="Jobs__7__lbl">
			<span>{props.count} </span>
		</div> */}
		<div id="Bangalore_lbl_city">
			<span>{props.city} - {props.count}</span>
		</div>
	</div> 
    </div>
    }
    

    </>
  )
}

export default JobCities
