import React, { useState, useEffect, useContext } from "react";
import { UserContext } from '../UserContext';
import {useNavigate} from "react-router-dom";
import '../Styles/FooterMobUI.css'

const FooterMobUI = () => {
  return (
    <div>

{/* <div id="footer_bar_rec__1">
	<svg class="footer_can_mob_1">
		<rect id="footer_can_mob_1" rx="0" ry="0" x="0" y="0" width="100%" height="64">
		</rect>
	</svg>
	<div id="foot_rec_bar_1">
		<a href="https://operate.live" target="_blank">
		<div id="All_rights_reserved__2022_Oper_bw">
			<span>All rights reserved © 2022 Operate Live (OPC) Private Limited.</span>
		</div>
		</a>
		<a href="https://www.linkedin.com/company/bizi-bees-outsourcing" target="_blank">
		<img id="linkedin_lbl_vac_can_mob_1" src="linkedin_lbl_vac_can_mob_1.png" srcset="linkedin_lbl_vac_can_mob_1.png 1x" />
			
		</a>
		<a href="https://jobs.bizibees.in/termsandcondition" target="_blank">
		<div id="Terms_and_Conditions_lbl_vac_c_by">
			<span>Terms and Conditions</span>
		</div>
		</a>
		<a href="https://jobs.bizibees.in/privacy" target="_blank">
		<div id="Private_Policy_lbl_vac_can_mob_bz">
			<span>Private Policy</span>
		</div>
		</a>
		<svg class="devider_can_mob_1" viewBox="0 0 1 16">
			<path id="devider_can_mob_1" d="M 0 0 L 0 16">
			</path>
		</svg>
		<a href="https://api.whatsapp.com/send?phone=919902247787" target="_blank">
		<img id="whatapp_btn_img_can_mob_1" src="whatapp_btn_img_can_mob_1.png" srcset="whatapp_btn_img_can_mob_1.png 1x" />
			
		</a>
	</div>
</div> */}



<div id="footer_bar_can__1">
	<svg class="footer_can_mob_1">
		<rect id="footer_can_mob_1" rx="0" ry="0" x="0" y="0" width="100%" height="64">
		</rect>
	</svg>
	<div id="foot_rec_bar_1">
		<a href="https://operate.live" target="_blank">
		<div id="All_rights_reserved__2022_Oper_mob">
			<span>All rights reserved © 2022 Operate Live (OPC) Private Limited.</span>
		</div>
		</a>
		<a href="https://www.linkedin.com/company/bizi-bees-outsourcing" target="_blank">
		<img id="linkedin_lbl_vac_can_mob_1" src="linkedin_lbl_vac_can_mob_1.png" srcset="linkedin_lbl_vac_can_mob_1.png 1x" />
			
		</a>
		<a href="https://jobs.bizibees.in/termsandcondition" target="_blank">
		<div id="Terms_and_Conditions_lbl_vac_mob">
			<span>Terms and Conditions |</span>
		</div>
		</a>
        <a href="https://jobs.bizibees.in/privacypolicy" target="_blank">
		<div id="privacy_policy_mob">
			<span>Privacy Policy</span>
		</div>
		</a>
        
		<a href="https://api.whatsapp.com/send?phone=919902247787" target="_blank">
		<img id="whatapp_btn_img_can_mob_1" src="whatapp_btn_img_can_mob_1.png" srcset="whatapp_btn_img_can_mob_1.png 1x" />
			
		</a>
	</div>
</div>
    </div>
  )
}

export default FooterMobUI
