import React, {useEffect, useState} from 'react'
import '../Styles/MonitorPagePc.css'
import ReAssign from '../ReAssign';
import BulkDataUpload from '../BulkDataUpload';
import Cities from '../Cities';
import Locations from '../Locations';
import OfficeLocations from '../OfficeLocations';
import FAQ from '../FAQ';
import NewApplicants from '../NewApplicants';
import WebsiteEnquiries from '../WebsiteEnquiries';
import Registrations from '../Registrations';
import Devices from '../Devices';

const MonitorPagePc = (props) => {

const [selectedMonitorTab,setSelectedMonitorTab] = useState("NewApplicants");


  return (
    <div id="MonitorPagePc_today">

	<div id="MonitorPagePc_Group_12">
		<svg class="MonitorPagePc_Rectangle_4">
			<rect id="MonitorPagePc_Rectangle_4" rx="15" ry="15" x="0" y="0" width="100%" height="100%">
			</rect>
		</svg>
		{selectedMonitorTab=== "NewApplicants"?<NewApplicants showAlert={props.showAlert}/>:""}
		{selectedMonitorTab=== "Enquiries"?<WebsiteEnquiries showAlert={props.showAlert}/>:""}
		{selectedMonitorTab=== "Registrations"?<Registrations showAlert={props.showAlert}/>:""}
		{selectedMonitorTab=== "Devices"?<Devices showAlert={props.showAlert}/>:""}
	
	</div>
	<div id="MonitorPagePc_Group_7">
		<svg class="MonitorPagePc_Rectangle_5">
			<rect id="MonitorPagePc_Rectangle_5" rx="10" ry="10" x="0" y="0" width="128" height="100%">
			</rect>
		</svg>
		<table id="MonitorPagePc_Group_6">
		<tr><td class={selectedMonitorTab=== "NewApplicants"?'selectedMonitorTab_pc':'monitorTab_pc'} onClick={()=>{setSelectedMonitorTab("NewApplicants");}}>New Applicants</td></tr>
		<tr><td class={selectedMonitorTab=== "Enquiries"?'selectedMonitorTab_pc':'monitorTab_pc'} onClick={()=>{setSelectedMonitorTab("Enquiries");}}>Enquiries</td></tr>
		{/* <tr><td class={selectedMonitorTab=== "FAQs"?'selectedMonitorTab_pc':'monitorTab_pc'} onClick={()=>{setSelectedMonitorTab("FAQs");}}>FAQs</td></tr> */}
		<tr><td class={selectedMonitorTab=== "Registrations"?'selectedMonitorTab_pc':'monitorTab_pc'} onClick={()=>{setSelectedMonitorTab("Registrations");}}>Registrations</td></tr>
		<tr><td class={selectedMonitorTab=== "Devices"?'selectedMonitorTab_pc':'monitorTab_pc'} onClick={()=>{setSelectedMonitorTab("Devices");}}>Devices</td></tr>
		
		</table>

	
	</div>
</div>
  )
}

export default MonitorPagePc
