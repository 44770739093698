import React,{useState,useEffect, useContext,useRef} from 'react'
import { ReactInternetSpeedMeter } from 'react-internet-meter'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker'; 
import TimePicker from '@mui/lab/TimePicker';
import CloseIcon from '@mui/icons-material/Close';
import Timer from './Timer'
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { UserContext } from './UserContext';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import  {MenuItem, Select,Button,TextField,InputLabel,FormControl,Typography,Modal,Box,Container,Autocomplete} from '@mui/material';
import ApplicantPopup from './ApplicantPopup';
import EmailTemplatesPopup from './EmailTemplatesPopup';
import { DataGrid, GridToolbar} from '@mui/x-data-grid';
import PlaceIcon from '@mui/icons-material/Place';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DescriptionIcon from '@mui/icons-material/Description';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import {CardActionArea, CardActions } from '@mui/material';
import EditJobs from './EditJobs';
import WorkPreferences from './WorkPreferences';
//import { google } from "googleapis";

const style4 = {

  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 400,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};

const NearbyLocations = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 600,
  maxWidth: 600,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

  const JdModal = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 250,
    maxWidth: 400,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
  
  };
  

  const rounds = [
    { label: 'HR Screening' },
    { label: 'Verification' },
    { label: 'Client Round' },
  ]  
  
  const locations = [
    { label: 'Mobile Call' },
    { label: 'Video Call' },
    { label: 'Diamond District' },
    { label: 'Nagawara' },
    { label: 'Indiranagar' },
  ]  
const ScheduleInterview = (props) => {
  
  const [interviewId, setInterviewId]= useState("");
    const [appliedJob ,setAppliedJob] = useState();
    const [vacancies,setVacancies] = useState();
    const [jobsDataProps,setJobsDataProps] = useState();
    const [round, setRound] = useState("");
    const [interViewerName, setInterViewerName]= useState("");
    const [interViewDate, setInterViewDate] = React.useState(new Date().toLocaleDateString());
    const [interViewTime, setInterViewTime] = React.useState(new Date().toLocaleTimeString().slice(0,5));
    const [ timeDate, setTimeDate] = React.useState(new Date().toLocaleDateString())
    const [interViewLocation, setInterViewLocation] = useState("Mobile Call");
    const [companyName, setCompanyName] = useState();
    const [name, setName] = useState();
    const [status, setStatus] = useState();
    const [applicantId,setApplicantId]= useState();
    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState();
    const [ mobile,setMobile] = useState();
    const [ result, setResult] = useState();
    const[ feedback ,setFeedback] = useState("");
    const { user, setUser} = useContext(UserContext);
    const [companies,setCompanies] = useState();
    const [prevfeedback,setPrevFeedback] = useState("");
    const [language, setLanguage ] = useState("");
    const [bookMark,setBookMark]= useState (false);
    const [bookMarkCmts, setBookMarkCmts ] =useState("");
    const [ hrName,setHRName] = useState("");
    const [candidateRounds,setCandidateRounds]= useState([])
    const [nextinterviewDate ,setNextInterviewDate] = useState(React.useState(new Date().toLocaleDateString()));    
    const [roundResult,setRoundResult]= useState("");
    const [ openTime,setOpenTime] = useState(0);
      const [previousCurrent,setPreviousCurrent ]= useState(0)
      const [expectedSalary,setExpectedSalary ] = useState(0)
      const [referalName,setReferalName ]= useState("")
      const [referalNumber,setReferalNumber ] = useState("")
      const [referalGender,setReferalGender] = useState("male")
      const [createdbyid,setCreatedbyId] = useState("")
      const [createdbyname,setCreatedbyName] = useState("")
      const [mobileStatus,setMobileStatus]=useState("")
      const [mail, setMail] = useState("");
      const [whatsappStatus,setWhatsappStatus] = useState(true);
      const [sendEmail,setSendEmail] = useState(false);
      const [openJDModel, setOpenJDModel] = useState(false);
      const [ city,setCity]= useState("");
      const [area,setArea] = useState("");
      const [education,setEducation] = useState("");
      const [areasOfinterview,setAreasOfinterview]= useState([])
      const [Dob, setDob ]= React.useState(new Date());
      const [expYears, setExpYears]= useState(0);
      const [openApplicant,setOpenApplicant] = useState(false);
      const [openEmailTemplate,setOpenEmailTemplate] = useState(false);
      const [expMonth, setExpMonths]= useState(0);
      const [openScheduler,setOpenScheduler]= useState(false)
      const  uploadedCount = useRef(0);
      const [intervalId,setIntervalId] = useState(0);
      const [ resumeUrl,setResumeUrl] = useState("");
      const [noticePeriod,setNoticePeriod] = useState(0)
      const [totalExperience,setTotalExperience]= useState(0);
      const [egr,setEgr] = useState("");
      const [cmr,setCmr] = useState("");
      const [hRNames,setHRNames] = useState([]);
      const [jobDescription,setJobDescription] = useState("");
      const [keySkills,setKeySkills] = useState("");
      const [openLocationModal, setOpenLocationModal] = React.useState(false);
      const [companyCoordinates,setCompanyCoordinates] = useState([]);
      const [openNext,setOpenNext] = useState(true)
      const [voiceProcess,setVoiceProcess] = useState(true)
      const [nonVoiceProcess,setNonVoiceProcess] = useState(true)
      const [workFromHome,setWorkFromHome] = useState(true)
      const [workFromOffice,setWorkFromOffice] = useState(true)
      const [partTime,setPartTime] = useState(true)
      const [fullTime,setFullTime] = useState(true)
      const [nightShift,setNightShift] = useState(true)
      const [dayShift,setDayShift] = useState(true)
      const [companyTransportDay,setCompanyTransportDay] = useState(true)
      const [companyTransportNight,setCompanyTransportNight] = useState(true)
      const [ownTransport,setOwnTransport] = useState(true)
      const [willingToRelocate,setWillingToRelocate] = useState(true)
      const [mapUrl,setMapUrl] = useState("");
      const [candidatePreference,setCandidatePreference] = useState({});
      const [jobExperience,setJobExperience] = useState(0);
      const [wifiSpeed,setWifiSpeed] = useState(0);
      
      useEffect(()=>{
    //props.interview && getScheduledInterview(props.interview.applicantId)
   
    getCompanies()
   
},[])

useEffect(()=>{getInterviewRounds()},[applicantId])

useEffect(()=>{
    if (props.interview){
      //console.log(props.interview)
       setApplicantId(props.interview.applicantid)
       setJobId(props.interview.jobid)
       setInterviewId(props.interview.id);
       setCreatedbyId(props.interview.createdbyid)
       setCreatedbyName(props.interview.createdbyname)
      setName(props.interview.name)
    setInterViewerName(props.interview.interviewername===""?user.name:props.interview.interviewername);
      setInterViewDate(props.interview.interviewdate || new Date().toLocaleDateString());
    props.interview.interviewtime?((props.interview.interviewtime).length < 6 ? setInterViewTime((props.interview.interviewtime)):setInterViewTime(new Date((props.interview.interviewtime)).toTimeString().slice(0,5))):setInterViewTime(new Date(new Date()).toTimeString().slice(0,5)) ;

    if(props.interview.interviewdate && props.interview.interviewtime)
    {
     
    let time = parseInt(parseInt(props.interview.interviewtime.split(':')[0])*60+parseInt(props.interview.interviewtime.split(':')[1])+330);
    let interviewDateTime = new Date(new Date(props.interview.interviewdate.toDateString()).setMinutes(time)).toISOString().slice(0,16);
  
    setTimeDate(interviewDateTime)
    }
    else
    {
      setTimeDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
    }
      setRound(props.interview.rounds);
      setInterViewLocation(props.interview.interviewlocation ==="undefined"?"":props.interview.interviewlocation );
      // setStatus(props.interview.status);
      setFeedback("")
      setPrevFeedback(props.interview.feedback === "undefined"?"":props.interview.feedback)
      setBookMark(props.interview.bookmark===undefined?false:props.interview.bookmark)
      setBookMarkCmts(props.interview.bookmarkcomment==="undefined"?"":props.interview.bookmarkcomment)
      setResult(props.interview.status)
      setMobile(props.interview.mobile)
      setMail(props.interview.mail)
      setCompanyName(props.interview.companyname)
        getVacancies(props.interview.companyname)
        setJobTitle(props.interview.jobtitle)
        setCmr(props.interview.communicationrating === "undefined"?"":parseInt(props.interview.communicationrating))
        setEgr(props.interview.englishrating === "undefined"?"":parseInt(props.interview.englishrating))
        setHRName(props.interview.hrname)
        if(props.interview.rounds === "Client Round")
        {
          setNextInterviewDate(new Date((new Date(new Date().toDateString())).setMinutes(930)).toISOString().slice(0,16))
        }
        else if(user.accesstype == 5)
        {       
          setNextInterviewDate(new Date(new Date(new Date().toDateString()).setMinutes(2490)).toISOString().slice(0,16))
        }
          else{
             setNextInterviewDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
        }
    
      }
},[companies,props.interview])

useEffect(()=>{
  setOpenScheduler(props.open)
  
  if (intervalId>0) {
    clearInterval(intervalId);
    setIntervalId(0)
  }
  if(props.open)
  {
    getLanguages()
  }
    if(user.accesstype != 5)
    {
      if(props.interview)
      {
        if(props.open)
        {
          timer("start")
          setIntervalId(setInterval(()=>{timer("active10")},10000))
        }
        else
        {
          timer("stop")
        }
    }
 }


  },[props.open])

  useEffect(()=>{},[openTime])

// useEffect(()=>{
//     if (props.interview){
      
//           //setJobId(props.interview.jobid)
//           //setJobTitle(props.interview.jobtitle)
        
//         }
 
// },[vacancies])

useEffect(()=>{
  getUsers()
  getLocations()

},[props.interview])

const setPreference=(key,value)=>
{
switch (key) {
case "voiceProcess":
  setVoiceProcess(value)
  break;
case "nonVoiceProcess":
  setNonVoiceProcess(value)
  break;
case "workFromHome":
  setWorkFromHome(value)
  break;
case "workFromOffice":
  setWorkFromOffice(value)
  break;
  case "partTime":
    setPartTime(value)
    break;
  case "fullTime":
    setFullTime(value)
    break;
  case "nightShift":
    setNightShift(value)
    break;
  case "dayShift":
    setDayShift(value)
    break;
    case "companyTransportDay":
      setCompanyTransportDay(value)
      break;
      case "companyTransportNight":
        setCompanyTransportNight(value)
        break;
    case "ownTransport":
      setOwnTransport(value)
      break;
default:
  break;
}
}

const getLocations = ()=>{
  let apiURL=process.env.REACT_APP_API_URL + "/locations/getlocations" 
  let apiParams = {
      method:"GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
 
       setAreasOfinterview(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

const handleClose = () =>{
  setOpenLocationModal(false);
  
}

const closeJDModel = () => setOpenJDModel(false);

const OpenLocationModal = (value)=>{

  setOpenLocationModal(true)
 
  let apiURL=process.env.REACT_APP_API_URL + "/officelocations/getCompanyCoordinates/" + value.latitude +"/"+ value.longitude; 
  let apiParams = {
      method:"GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
      // console.log(repos.data)
      setCompanyCoordinates(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));

}

//Google Drive File upload functions: Start
// const authenticateGoogle = () => {
//   const auth = new google.auth.GoogleAuth({
//     keyFile: `${__dirname}/service-account-key-file.json`,
//     scopes: "https://www.googleapis.com/auth/drive",
//   });
//   return auth;
// };

// const uploadToGoogleDrive = async (file, auth) => {
//   const fileMetadata = {
//     name: file.originalname,
//     parents: ["1T8smHRI3g1Or9pSjxsV_BDFvR4LepqZu"], // Change it according to your desired parent folder id
//   };
  
//   // const media = {
//   //   mimeType: file.mimetype,
//   //   body: fs.createReadStream(file.path),
//   // };

//   const driveService = google.drive({ version: "v3", auth });

//   const response = await driveService.files.create({
//     requestBody: fileMetadata,
//     media: file,
//     fields: "id",
//   });
//   return response;
// };

// const deleteFile = (filePath) => {
//   fs.unlink(filePath, () => {
//     console.log("file deleted");
//   });
// };

//Google Drive File upload functions: End


const changeRound = (intvwId) =>{
 console.log(intvwId)
 let apiURL = process.env.REACT_APP_API_URL + "/interview/getinterview/"+intvwId;

    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
            //console.log(repos.data)
              setInterviewId(repos.data._id);
              setName(repos.data.name)
              setInterViewerName(repos.data.interviewername===""?user.name:repos.data.interviewername);
                setInterViewDate(repos.data.interviewdate || new Date().toLocaleDateString());
              repos.data.interviewtime?((repos.data.interviewtime).length < 6 ? setInterViewTime((repos.data.interviewtime)):setInterViewTime(new Date((repos.data.interviewtime)).toTimeString().slice(0,5))):setInterViewTime(new Date(new Date()).toTimeString().slice(0,5)) ;
          
              if(repos.data.interviewdate && repos.data.interviewtime)
              {
               
              let time = parseInt(parseInt(repos.data.interviewtime.split(':')[0])*60+parseInt(repos.data.interviewtime.split(':')[1])+330);
              let interviewDateTime = new Date(new Date(new Date(repos.data.interviewdate).toDateString()).setMinutes(time)).toISOString().slice(0,16);
            
              setTimeDate(interviewDateTime)
              }
              else
              {
                setTimeDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
              }
                setRound(repos.data.rounds);
                setInterViewLocation(repos.data.location ==="undefined"?"":repos.data.location );
                // setStatus(repos.data.status);
                setFeedback("")
                setPrevFeedback(repos.data.feedback === "undefined"?"":repos.data.feedback)
                setBookMark(repos.data.bookmark===undefined?false:repos.data.bookmark)
                setBookMarkCmts(repos.data.bookmarkcomment==="undefined"?"":repos.data.bookmarkcomment)
                setResult(repos.data.status)
                setRoundResult(repos.data.status)
                setMobile(repos.data.mobile)
                setCompanyName(repos.data.companyname)
                  getVacancies(repos.data.companyname)
                  setCmr(repos.data.communicationRating === "undefined"?"":parseInt(repos.data.communicationRating))
                  setEgr(repos.data.englishRating === "undefined"?"":parseInt(repos.data.englishRating))
                  setJobId(repos.data.jobId)
                  setJobTitle(repos.data.jobtitle)
                  setHRName(repos.data.hrname)
                  if(repos.data.rounds === "Client Round")
                  {
                    setNextInterviewDate(new Date((new Date(new Date().toDateString())).setMinutes(930)).toISOString().slice(0,16))
                  }
                  else if(user.accesstype == 5)
                  {       
                    setNextInterviewDate(new Date(new Date(new Date().toDateString()).setMinutes(2490)).toISOString().slice(0,16))
                  }
                    else{
                       setNextInterviewDate(new Date((new Date()).setMinutes(((new Date()).getMinutes()+330))).toISOString().slice(0,16))
                  }
              
               //console.log(repos.data)
                         }else props.showAlert(""+(repos.message || repos),"error"); 
        }) 
        .catch((err) => props.showAlert((''+err),"error"));

} 

const updateLanguage = (lang) =>{

var newLanguageValue = (((language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language)+lang);
setLanguage(((newLanguageValue.length>0 && newLanguageValue.indexOf(",")===0)?newLanguageValue.substring(1,newLanguageValue.length-1):newLanguageValue));
}
 //Schedule Interview 
 const ScheduleApplicant = () =>{

    let apiURL=process.env.REACT_APP_API_URL + "/interview/scheduleinterview/"
    let apiParams = {
        method:"POST",
        headers:{
         interviewid:interviewId,
          interviewername :interViewerName,
          interiewdate:interViewDate, 
          interviewtime:interViewTime,
          rounds: encodeURIComponent(round),
          interviewlocation:encodeURIComponent(interViewLocation),
          feedback:encodeURI(feedback),
          createdbyid:user._id,
          createdbyname:user.name,
          status:"Scheduled",
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          //alert("InterView Scheduled Successfully");
          props.showAlert("InterView Scheduled Successfully","success")
          window.location.reload(false);
          
            // //console.log(repos.data);;
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
    
  } 

  const getLanguages = ()=>{
    let apiURL=process.env.REACT_APP_API_URL + "/applicants/languages/" +props.interview.applicantid;
    fetch(apiURL)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
      if (repos.data) {
        setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
        setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
        setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
        setCity(!repos.data.city||repos.data.city=="undefined"?"":repos.data.city)
        setArea(!repos.data.area||repos.data.area=="undefined"?"":repos.data.area)
        setEducation(!repos.data.education||repos.data.education=="undefined"?"":repos.data.education)
        setResumeUrl(!repos.data.resumeUrl||repos.data.resumeUrl=="undefined"?"":repos.data.resumeUrl)
        setExpYears(!repos.data.experience||repos.data.experience=="undefined"?0:((repos.data.experience)-((repos.data.experience)%12))/12);
        setExpMonths(!repos.data.experience||repos.data.experience=="undefined"?0:(repos.data.experience)%12);
        setNoticePeriod(!repos.data.noticePeriod||repos.data.noticePeriod=="undefined"?0:repos.data.noticePeriod)
        setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
        setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
        setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
        setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
        setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
        setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
        setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
        setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
        setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
        setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
        setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
        setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
      
      }else {
      setLanguage("");
      setPreviousCurrent("");
      setExpectedSalary("");
      setCity("");
      setArea("");
      setEducation("")
      setResumeUrl("");
      setExpYears(0);
      setExpMonths(0);
      setNoticePeriod(0);
    }
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const timer = (action) =>{
    let apiURL = process.env.REACT_APP_API_URL + "/interview/timer/"+interviewId+"/"+action;
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
        })
        .catch((err) => props.showAlert((''+err),"error"));
      }

  
    const getInterviewRounds = ()=>{
     //console.log(jobId)
      let apiURL=process.env.REACT_APP_API_URL + "/interview/getscheduleinterview/" +applicantId +"/"+ jobId; 
      let apiParams = {
        method: "GET",
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        }
      
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
        if (repos.data) {
          console.log(repos.data)
          setCandidateRounds(repos.data)
         
          if (repos.data.length > 0)
          {changeRound(repos.data[repos.data.length-1]._id)}
          
        }
      }) 
      .catch((err) => props.showAlert((''+err),"error"));
    }

    const getVacancies = (company) =>{
        let apiURL = process.env.REACT_APP_API_URL + "/jobs/vacancies?company=" +company;
        let apiParams = {
          method: "GET",
          headers:{
            authtoken: localStorage.getItem("loggedintoken")
          },
        };  
        fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  
                  if(repos.data.length>0)
                  {
                 
                  setJobId(repos.data && repos.data[0]._id);
                  setJobTitle(repos.data && repos.data[0].jobtitle);
                  setJobDescription(repos.data && repos.data[0].jobdescription);
                  setCompanyName(repos.data && repos.data[0].companyname);
                  setCandidatePreference(repos.data && repos.data[0].preferences);
                  setKeySkills(repos.data && repos.data[0].skills);
                  setJobExperience(repos.data && parseInt(repos.data[0].minexpmonths))
                  setMapUrl(repos.data && repos.data[0].mapUrl);
                  setVacancies(repos.data && repos.data);
                  setJobsDataProps(repos.data && repos.data[0])
                  
                }
                }else props.showAlert(""+(repos.message || repos),"error");  
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
          }
          
          const today = new Date()
          const tomorrow = new Date().getDay() == 6? new Date(today+ 24*60*60) : new Date (new Date(today))
       

          const nextRound=()=>{
            let apiURL=process.env.REACT_APP_API_URL + "/interview/applyinterview/" + props.interview.applicantid;
            let apiParams = {
                method:"POST",
                headers:{
                    name: props.interview.name,
                    email: props.interview.mail,
                    mobile: props.interview.mobile,
                    jobid: jobId,
                    jobtitle:jobTitle,
                    hrname:props.interview.hrname,
                    feedback: encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+feedback+"\n"+(props.interview.feedback||"")+"\n"),
                    companyname:companyName,
                    reference:props.interview.reference=="undefined"?"":props.interview.reference,
                    interviewername: user.accesstype == 5?"Client Interviewer":"",
                    round: user.accesstype == 5?"Client Round":"Verification",
                    interviewdate:nextinterviewDate,
                    interviewtime:nextinterviewDate.split('T')[1],
                    location: user.accesstype == 5?"":"Mobile Call",
                    createdbyid:user._id,
                    createdbyname:user.name,
                    authtoken: localStorage.getItem("loggedintoken")
                },
            };
            // console.log(apiParams)
            fetch(apiURL, apiParams)
            .then((res)=> (res.status===200 ? res.json() : res.text()))
            .then((repos)=> {
                if (repos.data) {
                  if(whatsappStatus===true){
                    WhatsappMsg()
                  }
                  if(sendEmail===true){
                    SendEmail()
                  }
                 
                setTimeout(() => {
                  props.showAlert("Interview round created for applicant","info")
                }, 2000);
                 
                  
                
                }else props.showAlert((repos.message || repos|| "already applied for this job"),"error"); 
            }) 
            .catch((err) => props.showAlert((''+err),"error"));
        }

const AddData = async () =>{
    
let success = await checkduplicate(referalName,referalNumber,referalGender)

    if (success===true)
    {
      uploadedCount.current= uploadedCount.current+1
    }
};


const checkduplicate = async (name,mobile,gender)=>{
  if(mobile.length>=10){
  let phone = mobile.toString().replace('-','').slice(-10);
  let apiURL=process.env.REACT_APP_API_URL + "/applicants/phone/" +phone;
    let apiParams = {
        method:"GET",
    };
  return await fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          //alert(repos.data.name+" registered with "+repos.data.mobile+" already!");
          props.showAlert((repos.data.name+" registered with "+repos.data.mobile+" already!"),"info")
          setReferalName("")
          setReferalNumber("")
          setReferalGender("male")
          return false;
        } 
        else{
          addApplicant(name,mobile,gender);
          return true;
        }
      
    }) 
    .catch((err) => {props.showAlert((''+err),"error"); return false;});
    }
    else{
      props.showAlert("Mobile number length is below 10 digits!","error")
    }
  }



  const addApplicant=(name,mobile,gender)=>{
 
      let apiURL=process.env.REACT_APP_API_URL + "/applicants/addapplicant";
      let apiParams = {
          method:"POST",
          headers:{
              name: name,
              mobile: mobile,
              gender: gender,
              jobid: jobId,
              jobtitle: jobTitle,
              companyname:companyName,
              reference:encodeURIComponent("Candidate Referal: "+ name+" - "+mobile),
              createdbyid:user._id,
              createdbyname:user.name,
              authtoken: localStorage.getItem("loggedintoken")
          }
      };
      // console.log(apiParams)
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
              //setOutputMsg([...outputMsg,"\n"+name+" - "+mobile+" added successfully!\n"]);
           applyJob(repos.data)
          }else{ props.showAlert((repos.message || repos),"error")}
      }) 
      .catch((err) =>{props.showAlert((''+err),"error")})
    }


const handleUpload = async (e) =>{
  var file = e.target.files[0] //the file
  var reader = new FileReader() //this for convert to Base64 
  reader.readAsDataURL(e.target.files[0]) //start conversion...
  reader.onload = function (e) { //.. once finished..
    var rawLog = reader.result.split(',')[1]; //extract only thee file data part
    var dataSend = { dataReq: { data: rawLog, name:name[0].toUpperCase() + name.slice(1), type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
    fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
      { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
      .then(res => res.json()).then((data) => {
        //See response
        RetrieveResume(data)
        props.showAlert("Resume Uploaded Successfully","success")

      }).catch(e => console.log(e)) // Or Error in console
  }

}
const RetrieveResume = (data)=>{

  let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveResumeUrl/" + applicantId;
  let apiParams = {
      method:"POST",
      headers:{
        resumeurl:data.url,
        authtoken: localStorage.getItem("loggedintoken")
      }
}
fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
    if (repos.data) {
    setResumeUrl(data.url)
    }
  })
  .catch(e => console.log(e)) 
}

function renameFile(originalFile, newName) {
  return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
  });
}
    const applyJob=(applicant)=>{
      // console.log(job)
      let applicantid = applicant._id
      let apiURL=process.env.REACT_APP_API_URL + "/interview/applyinterview/" + applicantid;
      let apiParams = {
          method:"POST",
          headers:{
              hrname:hrName,
              name: applicant.name,
              mobile: applicant.mobile,
              jobid: jobId,
              jobtitle: jobTitle,
              companyname:companyName,
              round:round,
              interviewername:hrName,
              createdbyid:createdbyid,
              createdbyname:createdbyname,
              reference:encodeURIComponent("Candidate Referal: "+ name+" - "+mobile),
              authtoken: localStorage.getItem("loggedintoken")
          },
      };
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
            setReferalName("")
            setReferalNumber("")
            setReferalGender("male")
            // alert("Referral added Sucessfully!")
            props.showAlert("Referral added Sucessfully!","success")
              //setOutputMsg([...outputMsg,"\nInterviwer assigned successfully!"]);
          }else {
              //setOutputMsg([...outputMsg,"\nFailed to assign interviewer!"]);
            };
      }) 
      .catch((err) => props.showAlert((''+err),"error"));
  }
const LoadSelectedJob= (job)=>
{
 
    setOpenApplicant(false);
  if(job)
  {
    setJobId(job);
    setOpenScheduler(true);
    getInterviewRounds()
  }
  else
  {
    setOpenScheduler(false);

    props.close(openNext);}
  }


  const sendWhatsapp = () =>{
  
    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+name[0].toUpperCase() + name.slice(1)+",%0a%0aI am trying to reach you for a job vacancy. "+mobileStatus+"%0a%0aKindly call me back if interested to apply.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing"

    const newWindow = window.open(apiURL, '_blank')
  
  }

  const WhatsappMsg = () =>{
  
    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Congratulations "+name[0].toUpperCase() + name.slice(1)+",%0a%0aYou have cleared the HR Screening round.%0a%0aPlease stay in touch for further updates.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing";

    
    const newWindow = window.open(apiURL, '_blank')
    //WhatsappMsgSupervisor();

  
  }

  const WhatsappMsgSupervisor = () =>{

    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+user.reportingmobile+"&type=phone_number&app_absent=0&text="+name[0].toUpperCase() + name.slice(1)+" has cleared the HR Screening round.%0a%0aPlease call the candidate on "+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+" and conduct verification round if applicable.";
   
    
    const newWindow = window.open(apiURL, '_blank')
   
  }
  
  const SendMapLocation = () =>{
    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+name[0].toUpperCase() + name.slice(1)+",%0a%0aYour interview will be conducted at the below location. %0a%0a "+mapUrl+" %0a%0aKindly carry your resume hard copy and mention \"Bizi Bees\" on top of your resume without fail.%0a%0aPlease call me back on "+user.phone+" if any queries.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing"

    const newWindow = window.open(apiURL, '_blank')

  }

  const SendJD = async () =>{

    let apiURL="https://"+(user.device === "mobile"?"api":"web")+".whatsapp.com/send/?phone=+91"+props.interview.mobile.trim().replace(' ','').substring(props.interview.mobile.trim().replace(' ','').length-10)+"&type=phone_number&app_absent=0&text=Good Day "+name[0].toUpperCase() + name.slice(1)+",%0a%0aBelow is the Job Description and Skill Sets Required for the job discussed. %0a%0aJob Description:%0a"+jobDescription.split('\n').map(line=>line).join('%0a')+"%0a%0aSkills Required:%0a"+keySkills.split('\n').map(line=>line).join('%0a')+"%0a%0aPlease call me back on "+user.phone+" if any queries.%0a%0aFollow us on LinkedIn by clicking https://www.linkedin.com/company/bizi-bees-outsourcing"

    const newWindow = window.open(apiURL.replace('%0a,','%0a'), '_blank')

  }
 
  const SendEmail = () =>{
   setTotalExperience(parseInt(expYears*12)+parseInt(expMonth));
          setOpenEmailTemplate(true)
   
  }
 
       
        const showApplicant = () =>{
          setOpenScheduler(false)
          setOpenApplicant(true)
        }
    

       const joinedRound =() =>{
        let apiURL=process.env.REACT_APP_API_URL + "/interview/applyinterview/" + props.interview.applicantid;
        let apiParams = {
            method:"POST",
            headers:{
                name: props.interview.name,
                email: props.interview.mail,
                mobile: props.interview.mobile,
                jobid: jobId,
                jobtitle:jobTitle,
                hrname:props.interview.hrname,
                reference:props.interview.reference=="undefined"?"":props.interview.reference,
                feedback: encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+feedback+"\n"+(props.interview.feedback||"")+"\n"),
                companyname:companyName,
                interviewername: user.accesstype == 5?"Client Interviewer":"",
                round:"Joined",
                interviewdate:nextinterviewDate,
                interviewtime:nextinterviewDate.split('T')[1],
                location:"",
                status:"Joined",
                createdbyid:user._id,
                createdbyname:user.name,
                authtoken: localStorage.getItem("loggedintoken")
            },
        };
        // console.log(apiParams)
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              
           
            setTimeout(() => {
              props.showAlert("Applicant has been Marked as Joined","info")
            }, 2000);
             
              
            
            }else props.showAlert((repos.message || repos|| "already applied for this job"),"error");
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
    }
       
          
          const EditInterview=() =>{
            if(result === "Next Round" && language.length<2)
            {
           // alert("Please enter Candidate Languages!")
            props.showAlert("Please enter Candidate Languages!","error")
            return;
          }
          if ((result==="Next Round" || result==="Rejected" || result==="Cancelled") && egr.length === 0) {
            // alert("Select English Rating");
             props.showAlert("Select English Rating","error")
             return;
           }
          if ((result==="Next Round" || result==="Rejected" || result==="Cancelled") && cmr.length === 0) {
            //alert("Select Communication Rating");
            props.showAlert("Select Communication Rating","error")
            return;
          }
         
            props.close(openNext)
            let bookMarkObj = (props.interview.bookmark!==bookMark)?bookMarkCmts:(props.interview.bookmarkcomment && props.interview.bookmarkcomment !=="undefined")?props.interview.bookmarkcomment:"";
            let feedbackObj = encodeURIComponent(user.name+" ["+new Date().toLocaleString()+"]:\n"+feedback+"\n"+(props.interview.bookmark!==bookMark?bookMark?"Bookmark added with comments: "+bookMarkObj+"\n":"Bookmark removed with comments: "+bookMarkObj+"\n":"")+(props.interview.feedback && props.interview.feedback !== "undefined"?props.interview.feedback:"")+"\n"); 
           
            let apiURL=process.env.REACT_APP_API_URL + "/interview/editinterview/"
            let apiParams = {
                method:"POST",
                headers:{
                  applicantid:props.interview.applicantid,
                  interviewid:interviewId,
                  interviewername :interViewerName,
                  hrname:hrName,
                  interiewdate:timeDate,
                  interviewtime:timeDate.split("T")[1],
                  rounds: round,
                  location:encodeURIComponent(interViewLocation),
                  companyname:companyName,
                  jobid: jobId,
                  jobtitle: jobTitle,
                  createdbyid:user._id,
                  createdbyname:user.name,
                  feedback: feedbackObj,
                  bookmark:bookMark,
                  bookmarkcomments:bookMarkObj,
                  result:result,
                  languages: ((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
                  currentsalary:previousCurrent,
                  expectedsalary:expectedSalary,
                  
           preferences:JSON.stringify({
            voiceProcess:voiceProcess,
            nonVoiceProcess:nonVoiceProcess,
            workFromHome:workFromHome,
            workFromOffice:workFromOffice,
            partTime:partTime,
            fullTime:fullTime,
            nightShift:nightShift,
            dayShift:dayShift,
            companyTransportDay:companyTransportDay,
            companyTransportNight:companyTransportNight,
            ownTransport:ownTransport,
            willingToRelocate:willingToRelocate,
            }),
                  city:city,
                  area:area,
                  education:education,
                  experience:parseInt(expYears) * 12 + parseInt(expMonth),
                  noticeperiod:noticePeriod?parseInt(noticePeriod):0,
                  communicationrating:cmr,
                  englishrating:egr,
                  authtoken: localStorage.getItem("loggedintoken")
                },
              };
             
            
              fetch(apiURL, apiParams)
              .then((res)=> (res.status===200 ? res.json() : res.text()))
              .then((repos)=> {
                  if (repos.data) {
                   
                    if(result ==="Next Round" && roundResult!=="Next Round")
                    {
                    nextRound();

                  }else if(result ==="Joined" && round!=="Joined" ) {

                    joinedRound();

                  }else if( whatsappStatus===true && result==="Not Reachable") {
                      sendWhatsapp();
                  }
                  else if( whatsappStatus===true && (result==="Next Round" ||result==="Passed")) {
                    WhatsappMsg();
                  }                
               
                      props.showAlert("InterView Updated Successfully","success")
                   
                 
                    //  setTimeout(() => {
                    //    window.location.reload(false);
                    //  }, 3000);
                    
                    
                  }else props.showAlert(""+(repos.message || repos),"error");
              }) 
              .catch((err) => props.showAlert((''+err),"error"));
              
            } 
          
        

              const getCompanies = () =>{
                let apiURL = process.env.REACT_APP_API_URL + "/jobs/companies"
                let apiParams = {
                  method: "GET",
                  headers:{
                    authtoken: localStorage.getItem("loggedintoken")
                  },
                };
                fetch(apiURL, apiParams)
                    .then((res)=> (res.status===200 ? res.json() : res.text()))
                    .then((repos)=> {
                        if (repos.data) {
                        
                          setCompanies(repos.data)
                          setCompanyName(repos.data[0])
                          getVacancies(repos.data[0])
                         
                        }else props.showAlert(""+(repos.message || repos),"error");  
                    }) 
                    .catch((err) => props.showAlert((''+err),"error"));
                  }

                  const getUsers = async () =>{
                    let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
                    let apiParams = {
                      method: "GET",
                      headers: {
                        authtoken: localStorage.getItem("loggedintoken")
                      }
                    };
                    await fetch(apiURL, apiParams)
                        .then((res)=> (res.status===200 ? res.json() : res.text()))
                        .then((repos)=> {
                            if (repos.data) {
                            
                              //console.log(repos.data)
                              setHRNames(repos.data)
                            }else props.showAlert(""+(repos.message || repos),"error");  
                        }) 
                        .catch((err) => props.showAlert((''+err),"error"));
                      }

      const openJdModal = ()=>{
        setOpenJDModel(true)
      }


  //Getting Locations from Location Tab and mapping to get area
    const areaLoc = (areasOfinterview)?.map((loc) =>loc.area);
    const hrnames = (hRNames)?.map((hr)=>hr.name);


    // const rowData = [];      

    var cols = [

      {field:'id', headerName: 'id', hide: true },
      {field:'company', headerName: 'Company',width: 150, },
      {field:'office', headerName: 'Office',width: 150 },
      {field:'distance', headerName: 'Distance(km)',width: 150,headerAlign:'center',align:"center" },
      
  ];


const rowData = (companyCoordinates||[]).map((co) => {
       
  return{
 
  id: co._id,
  company:co.company,
  office:co.office,
  distance:parseInt(co.distance),
  
  };
});


           
  return (
    <> 
    <Modal
        open={openScheduler}
        onClose={props.handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
        >
      <Box sx={style4}>
        
          <Typography id="modal-modal-description">
          <Container style={{padding:"0px"}}>
          <div className = "content4">
          {/* <div className='container'> */}
          <h4 className="fnt2"><b>{name}</b>:&nbsp;<a style={{color:"white",textDecorationColor:"white"}} href={"tel:"+mobile}>{mobile}</a>
        
        <Button title="Close" size="small" variant="Standard" sx={{ bgcolor: "red",float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>{props.close(openNext); setOpenScheduler(false)} }> <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
       </h4> <div>
       <Button size='small' title="Schedule" style={{float:"right"}} closeFull={()=>{setOpenScheduler(false);props.close(openNext)}} onClick={()=>showApplicant()}><PersonSearchIcon/></Button>
      
</div><Timer style={{width:"50px"}} started={props.open}/>
<ReactInternetSpeedMeter  
            txtSubHeading="Internet is too slow"
            outputType="empty"
            customClassName={null}
            txtMainHeading="Opps..." 
            pingInterval={15000} // milliseconds 
            thresholdUnit='megabyte' // "byte" , "kilobyte", "megabyte" 
            threshold={10}
            imageUrl="/bizibees.jpeg"
            downloadSize="18800"  //bytes
            callbackFunctionOnNetworkDown={(speed)=>console.log(`Internet speed is down ${speed}`)}
            callbackFunctionOnNetworkTest={(speed)=>setWifiSpeed(speed)}

          />
  <div style={{zIndex:"10",position:"relative",color:wifiSpeed<10?"red":"cornflowerblue"}}>Internet speed: {wifiSpeed} mb/s</div>

        
     {status==="Applied" && props.interview.rounds === undefined? <Autocomplete
      disablePortal
      id="txtRounds"
      freeSolo
      options={rounds}
      sx={{ width: 300 }}
      value={round || ""} 
      onChange={(event,value)=>setRound(value.label)}
      renderInput={(params) => <TextField {...params}  
          //className="BOXLEFT"
          //sx={{minWidth: 420 }}
          size="small" label="Interview Round" 
          value={round || ""} 
          onChange={(e)=> 
            setRound(e.target.value) 
          }  />}
         />:
         <div style={{textAlign:"center", fontFamily: 'Times New Roman'}}>{[...new Set(candidateRounds)].map(rnd=>rnd._id === interviewId?<b>{round}</b>:<input type="button" style={{border:"1px solid", top:"150px",padding:"2px 6px"}} onClick={()=>{changeRound(rnd._id);}} value={rnd.rounds}/>)}</div>
         }
    
    <table border="0" >
      <tr>
      <td>  
       <table style={{width:360}}>
       <tr>
       <td>
        <Autocomplete
      disablePortal
      id="txtHRName"
      freeSolo
      options={hrnames}
      sx={{ width: 160 }}
      disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
      value={hrName || ""}
      onChange={(event,value)=>setHRName(value)}
      renderInput={(params) => <TextField {...params}  
          // className="BOXLEFT"
          sx={{width: 160}}
          size="small" label="HR Name" 
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={hrName || ""}
          onChange={(e)=> {
          setHRName(e.target.value);
        }} />}
         />
         </td>
         <td>
          <Autocomplete
      disablePortal
      id="txtLocation"
      freeSolo
      options={locations}
      sx={{ width: 160 }}
      disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
      value={interViewLocation ||"Mobile Call"}
      onChange={(event,value)=>setInterViewLocation(value.label)}
      renderInput={(params) => <TextField {...params}  
           //className="BOXLEFT"
          sx={{minWidth: 160 }}
          // style={{marginLeft:"240px",marginTop:"15px"}}
          size="small"
          label={round!=="Joined" ?"Interview Location":"Joined Location"}
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          multiline
         value={interViewLocation || ""}
          onChange={(e)=> {
          setInterViewLocation(e.target.value);
        }}/>}
         /></td>
         </tr>
         <tr><td colSpan={2} style={{textAlign:"center"}}> <small>{round!=="Joined" ?"Interview Date & Time: ":"Joined Date: "}</small><input type="datetime-local"  format="dd-mm-yyyy" disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}  style={{width:"200px",height:"15%",backgroundColor:"transparent",border:"1px solid", padding:"2px 6px"}} value={timeDate}  onChange={(e) =>setTimeDate(e.target.value)}/>
         {/* <InputLabel  >Resume Upload : </InputLabel> */}
        <FileUploadIcon style={{marginLeft:"5px",color:"cornflowerblue"}}  onClick={(e)=>document.getElementById('candidateResume').click()}/>
         <input  id="candidateResume" type="file" placeholder="Upload Resume" style={{display:"none"}} name="Resume" onChange={(e) => handleUpload(e)}></input>
         {resumeUrl && resumeUrl!=="" ? <FileDownloadIcon style={{marginLeft:"5px",color:"cornflowerblue"}}  onClick={(e)=>document.getElementById('candidateResumeDownload').click()}/>:""}
         {resumeUrl && resumeUrl!=="" ?  <a  id="candidateResumeDownload"  style={{display:"none"}} name="Resume" href={resumeUrl}  target="_blank" ></a>:""}
       </td>
         </tr>
         </table>
      
         </td>
        </tr>
      <tr>
      <td> 
      <table style={{width:360}}>
        <tr>
          <td>

       <FormControl variant="outlined" 
       //className="BOXLEFT"
        sx={{width: 160 }} size="small">
        <InputLabel id="companyName-label">Company Name</InputLabel>
        <Select
          labelId="company-name-labell"
          id="companyName"
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={companyName} 
          // defaultValue={companyName}
          onChange={(e)=> {
          
            setCompanyName(e.target.value);   
            getVacancies(e.target.value);
           } } >

          
          {
      companies && companies.map((company) =>  <MenuItem  value={company}>{company}</MenuItem>
        )}
        
        </Select>
      </FormControl> 
          </td>
          <td>

 <FormControl variant="outlined" sx={{width: 160 }} size="small" >
        <InputLabel id="vacancies-label">Available Jobs</InputLabel>
        <Select
          labelId="vacancies-labell"
          id="vacancies" 
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={jobId} onChange={(e)=> {
            
            setJobId(e.target.value);   
            setCandidatePreference(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].preferences)
            setJobTitle(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].jobtitle);   
            setMapUrl(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].mapUrl);
            setCandidatePreference(vacancies.filter(vacancy=>vacancy._id===e.target.value)[0].preferences)
            
           } } >

          { 
            
       vacancies && vacancies.map((vacancy) =><MenuItem value={vacancy._id}>{vacancy.jobtitle}</MenuItem>
  
        )} 
        </Select>        
      </FormControl> 
      
          </td>
        </tr>
        <tr><td style={{textAlign:"center"}} colSpan={2}>
        <a href="#" onClick={()=>openJdModal()}><VisibilityIcon/>View</a>&nbsp;&nbsp;
        <a href="#" onClick={()=>SendMapLocation()}><PlaceIcon/>Send Location</a>&nbsp;&nbsp;
        <a href="#" onClick={()=>{SendJD();}}><DescriptionIcon/>Send JD</a>
        </td></tr>
        <tr><td>
      <TextField 
      
        sx={{width: 160 }}  id="outlined-size-small"  size="small" label="City" variant="outlined"  value={city} onChange={(e)=> {
                  setCity(e.target.value);

        }} /> 
        </td>
        <td>

        {/* <TextField 
        sx={{width: 160 }}  id="outlined-size-small"  size="small" label="Area" variant="outlined"  value={area} onChange={(e)=> {
                  setArea(e.target.value);
        }} />  */}
        <Autocomplete
      disablePortal
      id="outlined-size-small"
      freeSolo
      options={areaLoc}
      sx={{width: 160 }}
      value={area || ""}
      
      onChange={(event,value)=>
      {
        setArea(value); 
      }}
      renderInput={(params) => <TextField {...params}  
           //className="BOXLEFT"
          sx={{width: 160 }}
          size="small" label="Area"
          value={area || ""} 
          onChange={(e)=> { 
            setArea(e.target.value);
        }} />}
         />
        </td>
                </tr> 
                {(areasOfinterview||[]).filter(rec=>(rec.area||"").toLowerCase().trim() === (area||"").toLowerCase().trim()).length > 0?
                <tr><td style={{textAlign:"center"}} colSpan={2}>
                <a href="#" onClick={()=>OpenLocationModal((areasOfinterview||[]).filter(rec=>(rec.area||"").toLowerCase().trim() === (area||"").toLowerCase().trim())[0])}>Show Office Distances</a>
                </td></tr>:""}
              </table>
        </td></tr>
        <tr><td>
        <tr><td style={{textAlign:"center"}}>
   <TextField 
      sx={{width: "350px",marginTop:"2px", }}  id="outlined-size-small"  size="small" label="Education" variant="outlined"  value={education} onChange={(e)=> {
      setEducation(e.target.value)
       }} /> 
   </td></tr>
        </td></tr>
            </table>

   <table>
         
  <tr>
    <td>
      <TextField 
//className="BOXLEFT" 
sx={{width: "350px",marginTop:"2px" }}  id="outlined-size-small"  size="small" label="Languages" variant="outlined"  value={language} onChange={(e)=> {
          setLanguage(e.target.value.length>0&&e.target.value.slice(0,1)===","?language.substring(1,e.target.value.length):e.target.value);

 }} /> 
       <div style={{textAlign:"center",fontSize:"small",marginTop:"3px"}}>
      
    {(language||"").includes("English")?"":  <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",English,")}>English</button>}
    {(language||"").includes("Hindi")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Hindi,")}>Hindi</button>}
    {(language||"").includes("Kannada")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Kannada,")}>Kannada</button>}
    {(language||"").includes("Urdu")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Urdu,")}>Urdu</button>}
    {(language||"").includes("Tamil")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Tamil,")}>Tamil</button>}
    {(language||"").includes("Telugu")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Telugu,")}>Telugu</button>}
    {(language||"").includes("Malayalam")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Malayalam,")}>Malayalam</button>}
    {(language||"").includes("Marathi")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Marathi,")}>Marathi</button>}
    {(language||"").includes("Punjabi")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Punjabi,")}>Punjabi</button>}
    {(language||"").includes("Bangla")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Bangla,")}>Bangla</button>}
    {(language||"").includes("Arabic")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Arabic,")}>Arabic</button>}
    {(language||"").includes("French")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",French,")}>French</button>}
    {(language||"").includes("Spanish")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Spanish,")}>Spanish</button>}
    {(language||"").includes("Russian")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Russian,")}>Russian</button>}
    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setLanguage("")}>Clear</button>
      <br/></div> 
    </td>
  </tr>
  </table>
  <table><tr><td>
  <TextField 
    sx={{width: "130px",marginTop:"2px" }}  id="outlined-size-small"  size="small" label="Current Salary" variant="outlined"  value={previousCurrent} onChange={(e)=> {
         setPreviousCurrent(e.target.value)

 }} />&nbsp; {parseInt(previousCurrent)&&parseInt(expectedSalary)?<button onClick={()=>{setPreviousCurrent(previousCurrent*12);setExpectedSalary(expectedSalary*12)}}>x</button>:""}</td><td>
{parseInt(previousCurrent)&&parseInt(expectedSalary)? <button onClick={()=>{setPreviousCurrent(previousCurrent/12);setExpectedSalary(expectedSalary/12)}}>/</button>:""}&nbsp; 
   <TextField 
    sx={{width: "130px",marginTop:"2px" }}  id="outlined-size-small"  size="small" label="Expected Salary" variant="outlined"  value={expectedSalary} onChange={(e)=> {
         setExpectedSalary(e.target.value)
 }} /> </td></tr>
  <tr>
      {/* <TextField 
      
        sx={{width: 160 }}  id="outlined-size-small"  size="small" label="ExpMonths" variant="outlined"  value={expMonth} onChange={(e)=> {
                  setExpMonths(e.target.value);

        }} />  */}
       
                      <td colSpan={1} style={{ textAlign: "center" }}>
                       
                        <TextField
                          sx={{ m: 0.5, width: "10ch" }}
                          
                          style={{backgroundColor:(((expYears?parseInt(expYears):0)*12)+(expMonth?parseInt(expMonth):0))>=parseInt(jobExperience)?"yellow":"transparent"}}
                          label="ExpYears"
                          variant="outlined"
                          size="small"
                          value={expYears}
                          onChange={(e) => {
                            setExpYears(e.target.value);

                          }}
                        />

                        <TextField
                          sx={{ m: 0.5, width: "10ch" }}
                          style={{backgroundColor:(((expYears?parseInt(expYears):0)*12)+(expMonth?parseInt(expMonth):0))>=parseInt(jobExperience)?"yellow":"transparent"}}
                          label="ExpMonths"
                          variant="outlined"
                          size="small"
                          value={expMonth}
                          onChange={(e) => {
                            setExpMonths(e.target.value);
                          }}
                        />
                      </td>
                  

        <td>
        <TextField 
  
        sx={{width: 140 }}  id="outlined-size-small"  size="small" label="NoticePeriod(Days)" variant="outlined"  value={noticePeriod} onChange={(e)=> {
                  setNoticePeriod(e.target.value);

        }} /> 
        </td>
                </tr>
     </table>   
     <table>
     <tr>
      <td style={{textAlign:"center"}}>
      <WorkPreferences type={props.type} preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} highlight={{candidatePreference}} setPreference={(key,value)=>setPreference(key,value)}/>
    
      <input type="checkbox" id="chkWillingtorelocate" name="chkWillingtorelocate" checked={willingToRelocate} onChange={()=>setWillingToRelocate(!willingToRelocate)}/>
        &nbsp;
        <label  for="chkWillingtorelocate" style={{color:willingToRelocate?"red":"black"}}>Willing To Relocate</label>
       
      </td>
     </tr>
     <tr><td>
  <TextField 
    sx={{width: "120px",marginTop:"2px" }}  id="outlined-size-small"  size="small" label="Refer Name" variant="outlined"  value={referalName} onChange={(e)=> {
         setReferalName(e.target.value)

 }} />  &nbsp;
   <TextField 
    sx={{width: "120px",marginTop:"2px" }}  id="outlined-size-small"  size="small" label="Refer Mobile" variant="outlined"  value={referalNumber} onChange={(e)=> {
         setReferalNumber(e.target.value)
         
 }} /> &nbsp;
 
  <FormControl variant="outlined" sx={{width: "80px",marginTop:"2px" }} size="small"  >
        <InputLabel id="demo-simple-select-label"  >Gender</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={referalGender} onChange={(e)=> {
          
            setReferalGender(e.target.value);   

           } } >
        
          <MenuItem value="male">Male</MenuItem>
          <MenuItem value="female">Female</MenuItem>
          <MenuItem value="others">Others</MenuItem>
        </Select>
      </FormControl> 
&nbsp;
      <input type="button" style={{border:"1px solid", padding:"2px 6px",marginTop:"6px" }} onClick={AddData} value="+" />
       </td>
 </tr>
     </table>   
<table><tr>
<td style={{textAlign:"center"}}>
<Autocomplete
      disablePortal
      id="txtInterviwer"
      freeSolo
      options={hrnames}
      sx={{ width: 150 }}
      value={interViewerName || ""}
      disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
      onChange={(event,value)=>setInterViewerName(value)}
      renderInput={(params) => <TextField {...params}  
           //className="BOXLEFT"
          sx={{width: 180 }}
          size="small" label="Interviewer Name"
          disabled={user.accesstype!=5 && (roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"}
          value={interViewerName || ""} 
          onChange={(e)=> { 
          setInterViewerName(e.target.value);
        }} />}
         /></td>
         <td style={{textAlign:"center"}}>
        
        {user.accesstype != 5 && (status==="Selected"||status==="Joined"||status==="Joined & Left"||status==="Invoiced") ?<div style={{textAlign:"center",color:"cornflowerblue", fontWeight:"bold"}}><h4>{status}</h4></div>:
        <div style={{textAlign:"center",color:"cornflowerblue", fontWeight:"bold"}}><FormControl variant="outlined" sx={{minWidth: 150 }} size="small"  >
        {(roundResult && (roundResult==="Next Round"||roundResult==="Passed"))?"Interview Result:Passed":
        <><InputLabel id="demo-simple-select-label">Interview Result</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          // disabled={props.interview && (props.interview.status==="Next Round"||props.interview.status==="Passed")}
          id="demo-simple-select"
          // displayEmpty={false}
          value={result} 
          onChange={(e)=> {
            setResult(e.target.value);   
     } } >
         {round!=="Joined" ?   <MenuItem value="Applied">Applied</MenuItem> :<></>}
         {round!=="Joined" ? <MenuItem value="Scheduled">Scheduled</MenuItem>:<></>} 
         {round!=="Joined" ?  <MenuItem value="Not Reachable">Not Reachable</MenuItem>:<></>}
         {round!=="Joined" ?  <MenuItem value="Rejected">Failed</MenuItem>:<></>}
         {round!=="Joined" && round!=="Client Round" ? <MenuItem value="Next Round">Passed</MenuItem>:<></>}
         {round!=="Joined" ? <MenuItem value="Cancelled">Cancelled</MenuItem>:<></>}
        {round==="Client Round" ? <MenuItem value="Selected">Selected</MenuItem>:<></>}
        {round==="Client Round" ?   <MenuItem value="Not Joined">Selected & Not Joined</MenuItem>:<></>}
        {round==="Client Round" ?   <MenuItem value="Joined">Joined</MenuItem>:<></>}
        {round==="Joined" ?   <MenuItem value="Joined & Left">Joined & Left</MenuItem>:<></>}
        {round==="Joined" ?   <MenuItem value="Invoiced">Invoiced</MenuItem>:<></>}

        </Select></>}
      </FormControl></div>}</td></tr>
      <tr>
      {result==="Next Round"?<td colSpan={2} style={{textAlign:"center"}}> <small>{result==="Next Round"?"Next Round On: ":(result==="Joined" && props.interview.status!=="Joined")?"Joined Date":""}</small>
      {result==="Next Round" ||(result==="Joined" && props.interview.status!=="Joined")?<input type="datetime-local" format="dd-mm-yyyy" style={{width:"160px",height:"5%",backgroundColor:"transparent",border:"1px solid", padding:"2px 6px"}} disabled={(roundResult==="Next Round"||roundResult==="Passed") && round==="HR Screening"} value={nextinterviewDate}  onChange={(e) =>setNextInterviewDate(e.target.value)}/>:""}
 </td>:""}
 </tr>

 <tr>
 </tr>

 <tr>
 <td colSpan={2} style={{textAlign:"center",fontSize:"small"}}>
 {result==="Not Reachable" ?<button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}}  onClick={e=>{setFeedback("Switched Off");setMobileStatus("Your number is switched off.");}}>Switched Off</button>:null}
      {result==="Not Reachable" ?<button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Not received call");setMobileStatus("Your number is not reachable.");}}>Not received</button>:null}
      {result==="Not Reachable" ?<button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Out of network");setMobileStatus("Your number is out of network.");}}>Out of network</button>:null} 
      {result==="Not Reachable" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("On another call");setMobileStatus("Your number is busy on another call.");}}>Another call</button>:null}
      {result==="Not Reachable" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Disconnecting");setMobileStatus("We are unable to connect with you.");}}>Disconnecting</button>:null}
      {result==="Not Reachable" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>{setFeedback("Some other person received the call.");setMobileStatus("Some other person received our call.");}}>Other Person Picked</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Worst Communication")}>Worst Comm</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Poor Communication")}>Poor Comm</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs work from home")}>WFH</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs Part Time Job")}>Part Time</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs non Voice Job")}>Non Voice</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Needs High salary")}>High salary</button>:null}
      {result==="Rejected" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Not interested")}>Not interested</button>:null}
      {result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>feedback.includes("Selected for Voice Process.")?setFeedback(feedback.replace(" Selected for Voice Process.",'').replace("Selected for Voice Process.",'')): setFeedback((feedback==''?'':feedback+' ')+"Selected for Voice Process.")}>Voice</button>:null}
      {result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>feedback.includes("Selected for Non-Voice Process.")?setFeedback(feedback.replace(" Selected for Non-Voice Process.",'').replace("Selected for Non-Voice Process.",'')): setFeedback((feedback==''?'':feedback+' ')+"Selected for Non-Voice Process.")}>Non-Voice</button>:null}
      {result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>feedback.includes("Selected for work from home Process.")?setFeedback(feedback.replace(" Selected for work from home Process.",'').replace("Selected for work from home Process.",'')): setFeedback((feedback==''?'':feedback+' ')+"Selected for work from home Process.")}>WFH</button>:null}
     
      {result==="Rejected" ||result==="Not Reachable" ||result==="Cancelled" ||result==="Next Round" ? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("")}>Clear</button>:null}
      {result==="Cancelled"? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Not interested")}>Not Interested</button>:null}
      {result==="Cancelled"? <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>setFeedback("Duplicate Record")}>Duplicate</button>:null}
       </td>
      </tr>
      </table>
      <table>
      <tr>
      <td>
      
      <FormControl variant="outlined" sx={{ width: 350,marginTop:"2px" }} size="small"  >
        <InputLabel id="demo-simple-select-label" > English Ratings</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={egr} onChange={(e)=> {
          
            setEgr(e.target.value);   

           } } >
        
          <MenuItem value={5}>A - Excellent</MenuItem>
          <MenuItem value={4}>B - Good</MenuItem>
          <MenuItem value={3}>C - Average</MenuItem>
          <MenuItem value={2}>D - Bad</MenuItem>
          <MenuItem value={1}>E - Pathetic</MenuItem>
        </Select>
      </FormControl> 
      </td>
      </tr>
      </table>
      <table>
      <tr>
      <td>
      
      <FormControl variant="outlined" sx={{ width: 350,marginTop:"2px" }} size="small"  >
        <InputLabel id="demo-simple-select-label"  >Communication Ratings</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={cmr} onChange={(e)=> {
          
            setCmr(e.target.value);   

           } } >
        
          <MenuItem value={5}>A - Excellent</MenuItem>
          <MenuItem value={4}>B - Good</MenuItem>
          <MenuItem value={3}>C - Average</MenuItem>
          <MenuItem value={2}>D - Bad</MenuItem>
          <MenuItem value={1}>E - Pathetic</MenuItem>
        </Select>
      </FormControl> 
      </td>
      </tr>
      </table>

  <table>
  <tr>
  <td>
 
<TextField
          id="outlined-multiline-flexible"
          //className="BOXLEFT"
          sx={{width: 350}}
          style={{marginRight:"20px"}}
          size="small"
          label="Previous Feedback"
          multiline
          rows={4} 
          value={prevfeedback || "" }
        //    onChange={(e)=> {
        //   setPrevFeedback(e.target.value);
        // }} 
        /> 
        </td></tr><tr><td>
<TextField
          id="outlined-multiline-flexible"
          sx={{width: 350,marginBottom:"5px" }}
          size="small"
          label="Enter Your Feedback"
          multiline
          rows={2} 
          value={feedback || "" }
           onChange={(e)=> {
          setFeedback(e.target.value);
        }} />
        <br/>
      <input type="checkbox" id="bookmark" name="bookmark" checked={bookMark} onChange={()=>setBookMark(!bookMark)}/>&nbsp;&nbsp;
      <label for="bookmark"> Bookmark</label>&nbsp;&nbsp;
      <input type="text" style={{width:"275px",border:"1px solid", padding:"2px 6px"}} id="bookmarkComments" name="bookmarkComments" placeholder="Bookmark Comments" disabled={(!props.interview && !bookMark)|| (!props.interview.bookmark&& !bookMark) || props.interview.bookmark===bookMark} value={bookMarkCmts} onChange={(e)=>setBookMarkCmts(e.target.value)} />
     
        </td>
        </tr>
        <tr><td>
        <input type="checkbox" id="chkOpenNext" name="chkOpenNext" checked={openNext} onChange={()=>setOpenNext(!openNext)}/>
        &nbsp;
        <label  for="chkOpenNext"> Open Next </label>
        </td></tr>
        
        {result ==='Not Reachable'||result ==='Next Round'?
        <tr><td style={{textAlign:"center"}}>
        <input type="checkbox" id="chkWhatsapp" name="chkWhatsapp" checked={whatsappStatus} onChange={()=>setWhatsappStatus(!whatsappStatus)}/>
        &nbsp;
        <label  for="chkWhatsapp"> Whatsapp Status </label>&nbsp;

      
       {companyName==="Focus Edumatics"? <input type="button" id="chkEmail" name="chkEmail" style={{border:"1px solid", padding:"2px 6px"}} value="Send Email" onClick={()=>{setSendEmail(!sendEmail);SendEmail()}}/>:""}
       
      </td></tr>:''}
     </table>  <div  style={{textAlign:"center"}}>
     {(user.accesstype != 5 && (status==="Selected"||status==="Joined"||status==="Joined & Left"||status==="Invoiced"))||(user.accesstype != 5 && (round == "Verification" || hrName!=user.name)) ?null:<Button type="reset" title="Update" disabled={!result}  variant="contained" onClick={()=>EditInterview()} >Update</Button> }&nbsp; &nbsp;
      </div>
      
      {/* <Button className="Box14" title="Cancel Interview" variant="contained" disabled={status!=="Scheduled"?true:false} onClick={()=>cancelinterview()} > Cancel Interview </Button> */}
    </div>
      </Container> 
          </Typography>
          </Box>
      </Modal>

      {/* Modal for nearby locations */}
      <Modal
        open={openLocationModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" 
      >
        <Box sx={NearbyLocations}>
        <div>
         
          <h4 className="fnt2"><b>NearBy Locations</b>
        
        <Button title="Close" size="small" variant="Standard" sx={{ bgcolor: "red",float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>handleClose()} > <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
       </h4>
         </div>
    
       <div style={{height:750,width: '100%',margin:"0px"}}>
       <DataGrid 
        initialState={{
        sorting: {
        sortModel: [{ field: 'distance', sort: 'asc' }],
        },
       }}
        rows={rowData} 
        columns={cols}
       components={{
          Toolbar: GridToolbar,
        }}

          />
       </div>
      
        </Box>
      </Modal>

    <ApplicantPopup open={openApplicant} showAlert={props.showAlert} applicantId={applicantId} close={job=>{
     
      setJobId(job);
      if(jobId===job){
        LoadSelectedJob(jobId)
      }
     }}
       
    />
   <EditJobs type={"View"} openJobs = {openJDModel} jobsData={jobsDataProps} showAlert={props.showAlert} close={closeJDModel}/>
   
    {/* Model for top Request cards*/}
    {/* <Modal
        open={openJDModel}
        onClose={closeJDModel}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
       >
          <Box sx={JdModal}>
        <br/>
      <div style={{display:"block",width:"100%"}}>
     {/* <h2 style={{backgroundColor:"white",width:"95%",color:"#14c871",top:0,textAlign:"left",padding:"5px",position:"absolute",border:"1px solid #14c871"}}></h2>  */}
     {/* <Button title="Close" variant="contained" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,backgroundColor:"red"}} onClick={()=>closeJDModel()} ><CloseIcon/></Button>   
        
     <div style={{display:"inline-block",padding:"5px"}}>
     
      <Card className="Box" sx={{ maxWidth: 340, height:"auto",padding:"0px"}}>
      <CardActionArea>
        <CardContent>
        <div style={{backgroundColor:"cornflowerblue",color:"white"}}>
        <Typography className="data" gutterBottom variant="h7" component="div">
          <h5><b>{companyName?companyName:""}</b></h5>
          </Typography>
        </div>
          <Typography className="data" gutterBottom variant="h8" component="div">
           <b>Job Title:</b> {jobTitle}
          </Typography>
          <div style={{overflowY:"scroll", maxHeight:"450px"}}>
          <Typography gutterBottom variant="h8" component="div"> */}
           {/* <b>Experience:</b> {((job.minexpmonths - ( job.minexpmonths%12))/12)} Yr {job.minexpmonths%12} Mn to {((job.maxexpmonths - ( job.maxexpmonths%12))/12)} Yr {job.maxexpmonths%12} Mn */}
          {/* </Typography>

          <Typography  id="copyDes" gutterBottom variant="h8">
         <b>Job Description:</b>{ jobDescription?jobDescription:""} 
          </Typography>
         
         
          <Typography gutterBottom variant="h8" component="div">
         <b>Key Skill:</b>{keySkills?keySkills:""} 
          </Typography>
          </div>
        </CardContent>
      </CardActionArea>
        </Card>
         */}
       {/* <div style={{textAlign:"-webkit-center"}}>
         <CardActions style={{display:"table-cell", textAlign:"center",width:"100%"}}>
      
        {user.name!=user1name ?<Button size="small" title="Connect" variant="contained" style={{backgroundColor:"#14c871"}} onClick={(e) =>{ConnectionReq(reqId,"Connected")}}>
          Connect
        </Button>:""}
        {user.name==user1name ? <Button size="small" title="unfollow" variant="contained" style={{backgroundColor:"#14c871"}} onClick={(e) =>{ConnectionReq(reqId,"Cancelled")}}>
          Cancel
        </Button>:""}
        {user.name!=user1name ? <Button size="small" title="Ignore" variant="contained" style={{backgroundColor:"#14c871"}}  onClick={(e) =>{ConnectionReq(reqId,"Ignored")}}>
          Ignore
        </Button>:""}
        {user.name!=user1name ? <Button size="small" title="Block" variant="contained" style={{backgroundColor:"#14c871"}}  onClick={(e) =>{ConnectionReq(reqId,"Blocked")}}>
          Block
        </Button>:""}
       </CardActions>
       </div>      */}
       {/* </div>
    </div>
          </Box>
      </Modal> */} 
    <EmailTemplatesPopup jobTitle={jobTitle} toEmail={mail} open={openEmailTemplate} showAlert={props.showAlert} applicantId={applicantId} applicantName={name} applicant={{area,previousCurrent,expectedSalary,mobile,noticePeriod,totalExperience}} close={()=>setOpenEmailTemplate(false)}
      
    />
    </>
  )
}

export default ScheduleInterview