import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button} from '@mui/material';
import './InterviewDetails.css'
import ScheduleInterview from './ScheduleInterview';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CheckIcon from '@mui/icons-material/Check';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { UserContext } from './UserContext';
import TimePicker from '@mui/lab/TimePicker';
import { useSearchParams } from 'react-router-dom';
import BulkDataUpload from './BulkDataUpload';
import Checkbox from '@mui/material/Checkbox';
import Timer from './Timer';
import ApplicantPopup from './ApplicantPopup';
import CandidateVacanciesTopBar from './PC/CandidateApplicationsTopBar';
import CandidateApplcationsTopBar from './PC/CandidateApplicationsTopBar';

// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
// import FileDownloadIcon from '@mui/icons-material/FileDownload';



const CandidateApplications = (props) => {
   
   //UseState 
const [searchParams] = useSearchParams();
const [interview, setInterview] = useState([]);
const [OpenShedule, setOpenScedule ]= React.useState();
const [name, setName] = useState();
const [mail, setMail] = useState();
const [mobile, setMobile] = useState();
const [jobId, setJobId] = React.useState();
const [jobTitle, setJobTitle] = React.useState();
const [applicantId,setApplicantId]= useState()
const [companyName, setCompanyName] = useState();
const [open, setOpen] = React.useState(false);
const [Rounds, setRounds] = useState();
const [InterViewerName, setInterViewerName]= useState("");
const [InterViewDate, setInterViewDate] = React.useState(new Date());
const [InterViewTime, setInterViewTime] = React.useState();
const [interviewlocation, setInterviewlocation] = useState();
const [selectedRow,setSelectedRow] = useState();
const [isLoading, setIsLoading] = useState(false);
const { user, setUser} = useContext(UserContext);


//UseEffects
useEffect (()=>{
if(user)
    getInterviews()
},[user])



  const rowData = ((interview)||[]).map((interview) => {
    let IntvwTime = interview.interviewtime?(interview.interviewtime && (interview.interviewtime).length < 6 ? (interview.interviewtime):new Date((interview.interviewtime)).toTimeString().slice(0,5)):new Date(new Date()).toTimeString().slice(0,5);

    return{
  id: interview?._id,  //applicant id
  applicantid:interview?.applicantId, 
  jobid:interview?.jobId,  //interview id
  name: interview?.name,
  mobile: interview?.mobile,
  jobtitle:interview?.jobtitle,
  companyname:interview?.companyname,
  interviewername:interview?.interviewername,
 
  hrname:(interview.hrname && interview.hrname !== "undefined")?interview.hrname:"",
  interviewdate:interview?.interviewdate && ( new Date(interview?.interviewdate)),
  interviewtime:IntvwTime,
  interviewlocation:interview?.location,
  rounds:interview?.rounds,
  reference:interview?.reference,
  status:interview?.status.replace("Nextround","Passed").replace("Next Round","Passed"),
  feedback:interview?.feedback,
  bookmark:(interview.bookmark && interview.bookmark !== "undefined")? interview.bookmark:false,
  bookmarkcomment:(interview.bookmarkcomments && interview.bookmarkcomments !== "undefined")?interview.bookmarkcomments:"",
  languages:interview?interview.languages:"",
  englishrating:interview?parseInt(interview.englishRating):"",
  communicationrating:interview?parseInt(interview.communicationRating):"",
  location:interview?interview.location:"Mobile Call",
  createdbyname:interview?.createdbyname,
  createdbyid:interview?.createdbyId,
  
    };
  });
  

const [filterModel, setFilterModel] = React.useState({
    // [
    // {columnField: 'interviewername', operatorValue: 'contains', value:user.name}
    // ],
});


const cols = [
  
  // {field:'name', headerName: 'Name',width: 190 },
  {field:'createdbyname', headerName: 'Created By',width: 190, hide: true },
  // {field:'mobile',headerName: 'Mobile No',width: 140},
  // {field:'location', headerName: 'Location',width: 190},
  // {field:'languages', headerName: 'Languages',width: 190},
 // {field:'hrname', headerName: 'HR Name',width: 190},
//   {field:'englishrating', headerName: 'English Rating',width: 180,
//   renderCell: (params) =>{
//     switch(params.row.englishrating){

//      case "1":
//      return  ["E - Pathetic"]
    
//      case "2":
//        return ["D - Bad"]
    
//      case "3":
//        return  ["C - Average"]
   
//      case "4":
//        return  ["B - Good"]
    
//      case "5":
//        return ["A - Excellent"]
      
//      default :
//      return  [""]
    
//     }
//  },
// },
//   {field:'communicationrating', headerName: 'Communication Rating',width: 180,renderCell: (params) =>{
//     switch(params.row.communicationrating){
//       case "1":
//         return  ["E - Pathetic"]
       
//         case "2":
//           return ["D - Bad"]
       
//         case "3":
//           return  ["C - Average"]
      
//         case "4":
//           return  ["B - Good"]
       
//         case "5":
//           return ["A - Excellent"]   
      
//      default :
//      return  [""]
    
//     }
//  },
// },
  {field:'jobtitle', headerName: 'Job Title',width: 200},
  {field:'companyname', headerName: 'Company Name',width: 200},
//   {field:'Resume', headerName: 'Resume',width: 150,

  {field:'interviewername', headerName: 'Interviewer Name',width: 190},
 
  {field:'interviewdate', headerName: 'Interview Date',type: 'date',
  valueGetter: ({ value }) => value && new Date(value).toDateString(), width: 140},
  // {field:'interviewtime', headerName: 'Interview Time',width: 140},
  {field:'rounds', headerName: 'Rounds',width: 150},
  {field:'interviewlocation', headerName: 'Interview Location',width: 200},
  
  {field:'status', headerName: 'Status',width: 110},
  
 
  ];
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  

  //Functions
  const getInterviews = ()=>{

    let apiURL=process.env.REACT_APP_API_URL + "/interview/candidApplications/"+user._id;
    
    let apiParams = {
        method:"GET", 
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
    
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          console.log(repos.data)
          setInterview(repos.data)
          
        }else props.showAlert(""+(repos.message || repos),"error");  
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }
  


  
  const scheduleinterview = (row) =>{

    setSelectedRow(row)
      
        setOpenScedule(true);
      //  alert(JSON.stringify(row))
      }
    
  const handleCloseView = () => setOpen(false)
 
  
 
  //Redirect To Job Page
  const addInterview = () =>{
    window.location.href="/jobs?applicantid="+searchParams.get('applicantid');
  }
 

  

  const showApplicant = (row) =>{
    window.location.href="/applicants?applicantid="+row.applicantid;
  }


  return (
    <div >
  {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?"": <CandidateApplcationsTopBar />}
 
    <div>
   {searchParams.get('applicantid')? <Button size='small' variant="contained" className='intern' title={"Add Interview"} disabled={searchParams.get('applicantid')?false:true} onClick={()=>addInterview()}>+ Add Interview</Button>:""}
   
    <div style={{height:750,width: '100%',position:"absolute",top:"15%"}}>
    
     <DataGrid rows={rowData}
                        columns={cols}  
                       
               components={{
                  Toolbar: GridToolbar,
                  }}
             
              onRowDoubleClick={(e)=>scheduleinterview(e.row)}
           />

 </div>
 {/* <ScheduleInterview open={OpenShedule} showAlert={props.showAlert}  interview={selectedRow}  close={()=>setOpenScedule(false)} /> */}

    </div>
    </div>
  )
}

export default CandidateApplications
