import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import './WorkPreferencesRecVacancyUI.css';



const WorkPreferences = (props) => {

//useState

    const [voiceProcess,setVoiceProcess] = useState(true)
    const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
    const [workFromHome,setWorkFromHome] = useState(false)
    const [workFromOffice,setWorkFromOffice] = useState(true)
    const [partTime,setPartTime] = useState(false)
    const [fullTime,setFullTime] = useState(true)
    const [nightShift,setNightShift] = useState(true)
    const [dayShift,setDayShift] = useState(true)
    const [companyTransportDay,setCompanyTransportDay] = useState(true)
    const [companyTransportNight,setCompanyTransportNight] = useState(true)
    const [ownTransport,setOwnTransport] = useState(true)
    const [hightlight,setHighlight] = useState({})

  //UseEffect     
 useEffect(()=>{
      let preferences = props.preferences;

      if(props.highlight)
    { 
       setHighlight(JSON.parse(props.highlight.candidatePreference))
       //console.log(JSON.parse(props.highlight.candidatePreference))
    }

    if(preferences){
            setVoiceProcess(preferences.voiceProcess)
            setNonVoiceProcess(preferences.nonVoiceProcess)
            setWorkFromHome(preferences.workFromHome)
            setWorkFromOffice(preferences.workFromOffice)
            setPartTime(preferences.partTime)
            setFullTime(preferences.fullTime)
            setNightShift(preferences.nightShift)
            setDayShift(preferences.dayShift)
            setCompanyTransportDay(preferences.companyTransportDay)
            setCompanyTransportNight(preferences.companyTransportNight)
            setOwnTransport(preferences.ownTransport)
    }
   
   },[props.preferences,props.highlight])

//Functions

 
  return (
    <div>

<div id="cat_grp">
		<input disabled={props.type==="View"} type="checkbox" class="chxbox_voice" checked={voiceProcess} onChange={()=>props.setPreference("voiceProcess",!voiceProcess)}/>
			
		<div id="Voice_lbl" style={{color:voiceProcess?"red":"black",backgroundColor:(hightlight.voiceProcess===true)?"yellow":"transparent"}}>
			<span>Voice</span>
		</div>
		<input disabled={props.type==="View"} type="checkbox" class="chxbox_non" checked={nonVoiceProcess} onChange={()=>props.setPreference("nonVoiceProcess",!nonVoiceProcess)}/>
			
		<div id="Non-Voicelbl" style={{color:nonVoiceProcess?"red":"black",backgroundColor:(hightlight.nonVoiceProcess===true)?"yellow":"transparent"}}>
			<span>Non-Voice</span>
		</div>
		<input disabled={props.type==="View"} type="checkbox" class="chxbox_SV" checked={nonVoiceProcess===true && voiceProcess===true} onChange={(e)=>{
        
        if(e.target.checked===true){
          props.setPreference("voiceProcess",true)
          props.setPreference("nonVoiceProcess",true)
        }
        
        }}/>
			
		<div id="Semi-Voice_lbl" style={{color:(nonVoiceProcess===true && voiceProcess===true?"red":"black"),backgroundColor:(hightlight.nonVoiceProcess===true) && (hightlight.voiceProcess===true) ?"yellow":"transparent"}}>
			<span>Semi-Voice</span>
		</div>

		<div id="Employment_Type_lbl">
			<span>Employment Type</span>
		</div>
		<input disabled={props.type==="View"} type="checkbox" class="chxbox_FT" checked={fullTime} onChange={()=> props.setPreference("fullTime",!fullTime)}/>
		<div id="Full_Time_lbl" style={{color:fullTime?"red":"black",backgroundColor:(hightlight.fullTime===true)?"yellow":"transparent"}}>
			<span>Full Time</span>
		</div>
		
    <input disabled={props.type==="View"} type="checkbox" class="chxbox_pt" checked={partTime} onChange={()=>props.setPreference("partTime",!partTime)}/>
    <div id="Part_Time_lbl" style={{color:partTime?"red":"black",backgroundColor:(hightlight.partTime===true)?"yellow":"transparent"}}>
			<span>Part Time</span>
		</div>

    <div id="Work_Mode_lbl">
			<span>Work Mode</span>
		</div>

		<input disabled={props.type==="View"} type="checkbox" class="chxbox_office" checked={workFromOffice} onChange={()=> props.setPreference("workFromOffice",!workFromOffice)}/>
		<div id="Office_lbl" style={{color:workFromOffice?"red":"black",backgroundColor:(hightlight.workFromOffice===true)?"yellow":"transparent"}}>
			<span>Office</span>
		</div>

		<input disabled={props.type==="View"} type="checkbox" class="chxbox_home" checked={workFromHome} onChange={()=> props.setPreference("workFromHome",!workFromHome)}/>
    <div id="Home_lbl" style={{color:workFromHome?"red":"black",backgroundColor:(hightlight.workFromHome===true)?"yellow":"transparent"}}>
			<span>Home</span>
		</div>
		
		<input disabled={props.type==="View"} type="checkbox" class="chxbox_hybrid" checked={workFromOffice===true && workFromHome===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("workFromHome",true)
          props.setPreference("workFromOffice",true)
          setWorkFromOffice(true)
          setWorkFromHome(true)
         }
         
        }}/>
    <div id="Hybrid_lbl" style={{color:workFromOffice===true && workFromHome===true?"red":"black",backgroundColor:(hightlight.workFromOffice===true)&& (hightlight.workFromHome===true)?"yellow":"transparent"}}>
			<span>Hybrid</span>
		</div>


    <div id="Transportation_lbl">
			<span>Transportation</span>
		</div>
		
		<input disabled={props.type==="View"} type="checkbox" class="chxbox_own" checked={ownTransport} onChange={()=> props.setPreference("ownTransport",!ownTransport)}/>
		<div id="Own_lbl" style={{color:ownTransport?"red":"black",backgroundColor:(hightlight.ownTransport===true)?"yellow":"transparent"}}>
			<span>Own</span>
		</div>
		
		
    <input disabled={props.type==="View"} type="checkbox" class="chxbox_com_day" checked={companyTransportDay} onChange={()=>props.setPreference("companyTransportDay",!companyTransportDay)}/>
		<div id="Company_Day_lbl" style={{color:companyTransportDay?"red":"black",backgroundColor:(hightlight.companyTransportDay===true)?"yellow":"transparent"}}>
			<span>Day Time</span>
		</div>

		
		<input disabled={props.type==="View"} type="checkbox" class="chxbox__com_nght" checked={companyTransportNight} onChange={()=>props.setPreference("companyTransportNight",!companyTransportNight)}/>
		
		<div id="Company_Night_lbl" style={{color:companyTransportNight?"red":"black",backgroundColor:(hightlight.companyTransportNight===true)?"yellow":"transparent"}}>
			<span>Night Time</span>
		</div>
    
		
		<div id="Shifts_lbl">
			<span>Shifts</span>
		</div>

		<input disabled={props.type==="View"} type="checkbox" class="chxbox_day" checked={dayShift} onChange={()=> props.setPreference("dayShift",!dayShift)}/>
		<div id="Day_lbl" style={{color:dayShift?"red":"black",backgroundColor:(hightlight.dayShift===true)?"yellow":"transparent"}}>
			<span>Day</span>
		</div>

		<input disabled={props.type==="View"} type="checkbox" class="chxbox_nght" checked={nightShift} onChange={()=> props.setPreference("nightShift",!nightShift)}/>
    <div id="Night_lbl" style={{color:nightShift?"red":"black",backgroundColor:(hightlight.nightShift===true)?"yellow":"transparent"}}>
			<span>Night</span>
		</div>

    <input disabled={props.type==="View"} type="checkbox" class="chxbox_rotational" checked={nightShift===true && dayShift===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("nightShift",true)
          props.setPreference("dayShift",true)
         }
          }}/>
		<div id="Rotational_lbl" style={{color:nightShift===true && dayShift===true?"red":"black",backgroundColor:(hightlight.nightShift===true)  && (hightlight.dayShift===true)?"yellow":"transparent"}}>
			<span>Rotational</span>
		</div>
	</div>

    </div>
  )
}

export default WorkPreferences
