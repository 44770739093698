import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import './JdKsPopUpUI.css'

const JdKsPopUpUI = (props) => {
    const { user, setUser} = useContext(UserContext);
    const [openJdKs,setOpenJdKs]= useState(false);
    const [jd,setJd] = useState("");
    const [ks,setKs] = useState("");
    const [jobTitle,setJobTitle] = useState("");


 useEffect(()=>{

    if(props.openJdKs){
        setOpenJdKs(props.openJdKs)
        if(props.vacancy){
           
            setJd(props.vacancy.jobDescription)
            setKs(props.vacancy.skills)
            setJobTitle(props.vacancy.jobTitle)
			

        }
		if(props.availableVacancy){
			setJd(props.availableVacancy.jobdescription?props.availableVacancy.jobdescription:props.availableVacancy.jobDescription)
            setKs(props.availableVacancy.skills)
            setJobTitle(props.availableVacancy.jobtitle?props.availableVacancy.jobtitle:props.availableVacancy.jobTitle)
		}
    
}

 },[props.openJdKs])   


const handleClose = ()=>{
    props.closeJdKs()
    setOpenJdKs(false)
}

const MyCopyFunction = async (jd)=>{
    // setCopiedText(heading,jd)

     await navigator.clipboard.writeText(jd);
      //alert("Copied the text: " + jd);
      props.showAlert("Copied To ClipBoard","success")
    }

  return (
    <div>
      <Modal
        open={openJdKs}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
      <div id="pop_up_jdks">
	<img id="overall_bg_pc" src="overall_bg_pc.png" srcset="overall_bg_pc.png 1x" alt='reload'/>
		
	<svg class="card_2_bg_color">
		<rect id="card_2_bg_color" rx="7.5" ry="7.5" x="0" y="0" width="355" height="15">
		</rect>
	</svg>
	<svg class="card_2_bdr">
		<rect id="card_2_bdr" rx="10" ry="10" x="0" y="0" width="355" height="321">
		</rect>
	</svg>
	<svg class="card_1_bg_color">
		<rect id="card_1_bg_color" rx="7.5" ry="7.5" x="0" y="0" width="355" height="15">
		</rect>
	</svg>
	<svg class="card_1_bdr">
		<rect id="card_1_bdr" rx="10" ry="10" x="0" y="0" width="355" height="321">
		</rect>
	</svg>
	<div id="Grassroots_BPO_lbl">
		<span>{jobTitle}</span>
	</div>
	<div id="Group_overall_jd">
		<div id="Group_Js_desandName">
			{/* <div id="Group_18"> */}
				<div id="JD_desc_grp_pc">
					{/* <div id="blablabla_js_pc_"> */}
						<span>{jd}</span>
					{/* </div> */}
				</div>
			{/* </div> */}
			<div id="Job_Description_lbl_pc">
				<span>Job Description :</span>
			</div>
		</div>
		<div id="Group_copy_jd_pc" onClick={() => MyCopyFunction("Job Description: \n"+jd)}>
			<svg class="btn_jd_pc">
				<rect id="btn_jd_pc" rx="9" ry="9" x="0" y="0" width="46" height="39">
				</rect>
			</svg>
			<img id="file_jd_img_pc" src="file_jd_img_pc.png" srcset="file_jd_img_pc.png 1x" alt='reload'/>
				
		</div>
	</div>
	<img id="close1_btn_pop_art" src="close1_btn_pop_art.png" srcset="close1_btn_pop_art.png 1x" alt='reload' onClick={()=>handleClose()}/>
		
	<div id="ks_full_copy">
		<div id="ks_and_desc">
			<div id="Key_Skills_pc_lbl">
				<span>Key Skills :</span>
			</div>
			<div id="ks_desc">
				{/* <div id="KS_info_pop">
					<div id="blblabla_ks_bl"> */}
						<span>{ks}</span><br/>
					{/* </div>
				</div> */}
			</div>
		</div>
		<div id="copy_ks_grp" onClick={() => MyCopyFunction("key Skills: \n"+ks)}>
			<svg class="copy_btn_ks">
				<rect id="copy_btn_ks" rx="9" ry="9" x="0" y="0" width="46" height="39">
				</rect>
			</svg>
			<img id="copy_img_pc" src="copy_img_pc.png" srcset="copy_img_pc.png 1x" alt='reload' />
		</div>
	</div>
</div>
</Modal>
    </div>
  )
}

export default JdKsPopUpUI
