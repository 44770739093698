import React, {useState, useEffect, useContext} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './Locations.css'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';
import CheckIcon from '@mui/icons-material/Check';
import ScheduleInterview from './ScheduleInterview';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Container } from '@mui/material';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import States from "./States.json";


const Locations = (props) => {

  const Locations = {
    
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 375,
  maxWidth: 500,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',
   
  };

//UseStates
 
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");

  const [open, setOpen] = React.useState(false);

  const [isLoading,setIsLoading]=useState(false);
  const [active,setActive ]=useState(true);
  const [state,setState] = useState("Karnataka");
  const [city,setCity] = useState("Bangalore")
  const [area,setArea] = useState("");
  
  const [longitude,setLongitude]= useState("")
  const [latitude,setLatitude]= useState("")
  const [locations,setLocations] = useState([])
  const { user} = useContext(UserContext);
  

  const [filterModel, setFilterModel] = React.useState({
    items: [
      {columnField: '', operatorValue: 'contains', value:user.name}
    ],
  });
  
  const cols = [
  {field:'id', headerName: 'id', hide: true },
  {field:'state', headerName: 'State',width: 190 },
  {field:'city', headerName: 'City',width: 120  },
  {field:'area', headerName: 'Area',width: 190, },
  {field:'latitude', headerName: 'Latitude',width: 140}, 
  {field:'longitude', headerName: 'Longitude',width: 140},
  {field:'active', headerName: 'Active',width: 100, renderCell: (params) =>{
    return[
      <input type="checkbox" checked={params.row.active} /> 
    ];
  },},

];

useEffect(()=>{
  getLocations()

},[])



const getLocations = ()=>{
  let apiURL=process.env.REACT_APP_API_URL + "/locations/getlocations" 
  let apiParams = {
      method:"GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {

        setLocations(repos.data)
       
      }else props.showAlert(""+(repos.message || repos),"error");  
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

const UpdateLocations = (id)=>{
    let apiURL =  process.env.REACT_APP_API_URL + "/locations/updateLocations/"+id;
    let apiParams ={
      method:"POST",
      headers:{

      state:state,
      city:city,
      area:area,
      latitude:latitude,
      longitude:longitude,
      active:active,
      authtoken: localStorage.getItem("loggedintoken")
     
    }
    };
    fetch(apiURL,apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=>{
      if(repos.data){
        setOpen(false)
        setIsLoading(false)
        
        props.showAlert("Location Updated Successfully","success")
        setTimeout(()=>{
         getLocations()
        
        },2000)
      }

    })
    .catch((err) => props.showAlert((''+err),"error"));
    }

const addLocations = () =>{

let apiURL = process.env.REACT_APP_API_URL + "/locations/addLocations";
let apiParams = {
  method:"POST",
  headers:{
    state:state,
    city:city,
    area:area,
    latitude:latitude,
    longitude:longitude,
    active:active,
    authtoken: localStorage.getItem("loggedintoken")
  }
};
fetch(apiURL,apiParams)
.then((res)=>(res.status===200 ? res.json() :res.text()))
.then((repos)=>{
  if(repos.data){
    setOpen(false)
    setIsLoading(false)
    
    props.showAlert("Locations Added Successfully","success")
    setTimeout(()=>{
        getLocations()
       
       },2000)
  }
  
  else{
         props.showAlert(JSON.parse(repos).message||repos||"Error","error");
  }
})
.catch((err)=>props.showAlert((''+(err.message || err)),"error"))
}

const addEmployee =()=>{
  setOpen(true);
}

const editLocations = (row) =>{
 // console.log(row)
  setId(row.id);
  setActive(row.active);
  setState(row.state);
  setCity(row.city);
  setArea(row.area);
  setLongitude(row.longitude)
  setLatitude(row.latitude)
  setOpen(true);
}

const handleClose = () =>{
  setOpen(false);
  resetApplicant()
}

const resetApplicant = ()=>{

  setId("");
//   setActive("");
  setState("");
  setCity("");
  setArea("");
  setLongitude("")
  setLatitude("")

  
}

const rowData = (locations)?.map((loc) => {

return{
  id:loc?._id,
  state:loc?.state,
  city:loc?.city,
  area:loc?.area,
  latitude:loc?.latitude,
  longitude:loc?.longitude,
  active:loc?.active,
 
};
});

  return (
  <div style={{position:"absolute",width:"100%",height:"calc(100% - 10px)"}}>
  <Button size='small' variant="contained" className='intern' title={"Add Location"}  onClick={()=>addEmployee()}>+ Add Location</Button>
  <br/>
  <br/>
      <div style={{height:'calc(100% - 50px)',width: '100%'}}>
      <DataGrid rows={rowData} columns={cols} components={{
          Toolbar: GridToolbar,
        }}
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
        onRowClick={(e)=>editLocations(e.row)}
          />

          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        
      >
      
        <Box sx={Locations}>
        <div>
          <Typography id="modal-modal-description">
          <Container style={{padding:"0px"}}>
          <div className = "Locations">
          
        
        <div>
          {/* <h4  className="fnt"><b>{id.length>0?name:"USER DETAILS"}</b> <Button disabled={isLoading} title="Close" variant="Standard"  onClick={()=>handleClose()} ><CloseIcon /></Button></h4>  */}
          <h4 className="fnt2"><b>ADD LOCATIONS</b>
        
        <Button title="Close" size="small" variant="Standard" sx={{ bgcolor: "red",float:"right",right:0,top:0,position:"absolute",height:28,width:5 }} onClick={()=>handleClose()} > <CloseIcon style={{height:"25px",width:"25px"}} /> </Button>
       </h4>
         </div>
         <br/>
         <div style={{textAlign:"center",margin:"2px"}}>
        <table>
        <tr><td>
          <FormControl
                              variant="outlined"
                              
                              sx={{ minWidth: 220 }}
                              size="small"
                            >
                              <InputLabel id="state-label">
                                State
                              </InputLabel>
                              <Select
                                labelId="state-name-labell"
                                id="State"
                                value={state}
                                // defaultValue={companyName}
                                onChange={(e) => {
                                    setState(e.target.value);
                                }}
                              >
                                {States &&
                                    States.map((state) => (
                                    <MenuItem value={state.name}>
                                      {state.name}
                                    </MenuItem>
                                  ))}
                              </Select>
                            </FormControl>
      </td>
      <td>
      
      <TextField  id="outlined-size-small"  size="small" label="City" variant="outlined"  value={city || ""} onChange={(e)=> {
          setCity(e.target.value); 
        }}/>
    
      </td>
      </tr>
           <tr><td>
           <TextField  id="outlined-size-small"  size="small" label="Area" variant="outlined"  value={area || ""} onChange={(e)=> {
          setArea(e.target.value); 
        }}/>
      </td><td>
      <TextField   id="outlined-basic"  size="small" label="latitude" variant="outlined"  value={latitude} 
           onChange={(e)=> {
            setLatitude(e.target.value);  
        }}/>
     
</td>
</tr>
<tr><td>
        <TextField  id="outlined-size-small"  size="small" label="longitude" variant="outlined"  value={longitude || ""} onChange={(e)=> {
          setLongitude(e.target.value); 
      }}/>
  </td>
     <td>
      Active: <input type="checkbox"  checked={active} onChange={(e)=>setActive(e.target.checked)}/>
      </td>
      </tr>
  
    
      </table>
      </div>
      <br/>
      
        <div style={{textAlign:"center",marginBottom:"5px"}}>
     
      <Button disabled={isLoading} type="reset" title={"Edit Add "}  variant="contained" onClick={()=>id.length>0?UpdateLocations(id):addLocations()}>{id.length>0?"Edit":"Add"}</Button> &nbsp; &nbsp;
      &nbsp; &nbsp;{isLoading ? <CircularProgress/>:""}
     </div>

      </div>
      </Container> 
          </Typography>  
           </div>
        </Box>
      </Modal>
      </div>
</div>
  
  )
}

export default Locations