import React, { useEffect, useState, useContext} from 'react'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { DataGrid,} from '@mui/x-data-grid'
import './jobs.css';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import EventIcon from '@mui/icons-material/Event';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';  
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ModeIcon from '@mui/icons-material/Mode';

// import { getValue } from '@testing-library/user-event/dist/utils';
// import { id } from 'date-fns/locale';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import EditJobs from './EditJobs';
import { set } from 'date-fns';
import AddJobVacancyPopUpUI from './AddJobVacancyPopUpUI';



const style1 = {
  position: 'absolute',
  top: '50%',

  transform: 'translate(5%, -50%)',
  height: 600,
  width: 1300,

  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  padding:0,
};

// const style3 = {
//   position: 'absolute',
//   top: '50%',

//   transform: 'translate(87%, -50%)',
//   height: 489,
//   width: 533,

//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 10,

// };

const style4 = {
  position: 'relative',
  margin:"auto",
  height: "auto",
  minWidth: 325,
  maxWidth: 385,
  scrollY:"auto",
  bgcolor: 'background.paper',
  border: '2px solid #000',

};
const Jobs = (props) => {

  //usestate
    const [jobsData,setJobsData]=useState([]);
    const [name, setName] = useState("");
    const [mail, setMail] = useState("");
    const [mobile, setMobile] = useState("");
    const [WhatsNumber,setWhatsNumber] = useState("");
    const [gender, setGender] = useState("");
    const [address, setAddress] = useState("");
    const [applicant, setApplicant] = useState([]);
    const [open, setOpen] = React.useState(false);
    const [openApplicant, setOpenApplicant] = React.useState(false);
    const [jobId, setJobId] = React.useState();
    const [jobTitle, setJobTitle] = React.useState("");
    const [openView, setOpenView] = React.useState(false);
    const [resume, setResume ] = useState();
    const [OpenShedule, setOpenScedule ]= React.useState();
    const [ApplicantId,setApplicantId]= useState()
    const [interviewlocation, setInterviewlocation] = useState();
    const [ExpYears, setExpYears]= useState();
    const [ExpMonth, setExpMonths]= useState();
    const [Dob, setDob ]= React.useState(new Date());
    const [ Skills,setSkills]= useState("");
    const [companyName, setCompanyName] = useState("");
    const [Reference, setReference]= useState();
    const [jobDescription, setJobDescription] = useState("");
    const [minYears, setMinYears] = useState(0);
    const [minMonths, setMinMonths] = useState(0);
    const [maxYears, setMaxYears] = useState(0);
    const [maxMonths, setMaxMonths] = useState(0);
    const [offices,setOffices] = useState([]);
    const [searchParams] = useSearchParams();
    const [officeName,setOfficeName]= useState("");
    const [mapUrl,setMapUrl] = useState("");
    const [latitude,setLatitude] = useState("");
    const [longitude,setLongitude]= useState("");
    const[resumeName,setResumeName]=useState();
    const [experience,setExperience]=useState();
    const [voiceProcess,setVoiceProcess] = useState(true)
    const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
    const [workFromHome,setWorkFromHome] = useState(false)
    const [workFromOffice,setWorkFromOffice] = useState(true)
    const [partTime,setPartTime] = useState(false)
    const [fullTime,setFullTime] = useState(true)
    const [nightShift,setNightShift] = useState(true)
    const [dayShift,setDayShift] = useState(true)
    const [cabRequired,setCabRequired] = useState(true)
    const [ownTransport,setOwnTransport] = useState(true)
    const [minCTC,setMinCTC] = useState(0)
    const [maxCTC,setMaxCTC] = useState(0)
    const [hrNames,setHRNames]= useState([])
    const { user, setUser} = useContext(UserContext);
    const [spocId,setSpocId]= useState(user?user._id:"")
    const [spocName,setSpocName]= useState(user?user.name:"")
    const [jobsDataProps,setJobsDataProps] = useState([]);
   
 useEffect(()=>{
  getUsers();
  getOffices();

 },[companyName])


    const handleOpen = (job) =>{ 
      
      setJobTitle(job.jobtitle);
      setJobId(job._id);
      setCompanyName(job.companyname);
      setOpen(true);
     
  }
  const applyJob=(job)=>{
    // console.log(job)
    let JobTitle=job.jobtitle;
    let JobId=job._id;
    let CompanyName=job.companyname;
    let applicantid = searchParams.get('applicantid')?searchParams.get('applicantid'):user.accesstype=== "c"?user._id:"";
    
    let apiURL=process.env.REACT_APP_API_URL + "/interview/applyinterview/" + applicantid;
    let apiParams = {
        method:"POST",
        headers:{
            name: name,
            email: mail,
            mobile: mobile,
            feedback:"",
            // number:WhatsNumber,
            // dob:Dob,
            // experience:experience,
            // skill:Skills,
            // gender: gender,
            round:"HR Screening",
            jobid: JobId,
            jobtitle: JobTitle,
            companyname:CompanyName,
            interviewername:user.name?user.name:name,
            hrname:user.name,
            createdbyid:user._id,
            createdbyname:user.name,
            // address: encodeURIComponent(address),
            reference:encodeURIComponent(Reference),
            authtoken: localStorage.getItem("loggedintoken")
        },
    };

    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
         // alert("Job applied successfully!");
          props.showAlert("Job applied successfully!","success")
          setTimeout(() => {
              window.location.href="/interviewdetails?applicantid="+applicantid;
          }, 1000);
          
  
        }else props.showAlert((repos.message || repos|| "Already applied for this job!"),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
}

   
 const handleClose = () => {
  resetApplicant()
  setOpenApplicant(false)
}

const resetApplicant = ()=>{
  console.log("jobsreset")
  setJobTitle("");
  setJobId("");
  setCompanyName("");
  setJobDescription("");
  setOfficeName("");
  setSkills("");
  setMinYears("");
  setMinMonths("");
  setMaxYears("");
  setMaxMonths("");
  setVoiceProcess(false)
    setNonVoiceProcess(false)
    setWorkFromHome(false)
    setWorkFromOffice(false)
    setPartTime(false)
    setFullTime(false)
    setNightShift(false)
    setDayShift(false)
    setCabRequired(false)
    setOwnTransport(false)
    setSpocId("");
    setSpocName("");
    setMaxCTC(0);
    setMinCTC(0);
}

 const handleView = (job) => {
  getApplicants(job._id);
setCompanyName(job.companyname)

setJobTitle(job.jobtitle);
  //  setOpenView(true);
}

 const handleEdit = (job) => {
      setJobTitle(job && job.jobtitle);
      setJobId(job && job._id);
      setCompanyName(job && job.companyname);
      setMapUrl(job && job.mapUrl)
      setLatitude(job.latitude)
      setLongitude(job.longitude)
      setJobDescription(job && job.jobdescription);
      setOfficeName(job && job.officeName);
      setSkills(job && job.skills);
      setMinYears(((job.minexpmonths)-((job.minexpmonths)%12))/12);
      setMinMonths((job.minexpmonths)%12);
      setMaxYears(((job.maxexpmonths)-((job.maxexpmonths)%12))/12);
      setMaxMonths((job.maxexpmonths)%12);
      setVoiceProcess(job && job.preferences?JSON.parse(job.preferences).voiceProcess:true)
      setNonVoiceProcess(job && job.preferences?JSON.parse(job.preferences).nonVoiceProcess:true)
      setWorkFromHome(job && job.preferences?JSON.parse(job.preferences).workFromHome:true)
      setWorkFromOffice(job && job.preferences?JSON.parse(job.preferences).workFromOffice:true)
      setPartTime(job && job.preferences?JSON.parse(job.preferences).partTime:true)
      setFullTime(job && job.preferences?JSON.parse(job.preferences).fullTime:true)
      setNightShift(job && job.preferences?JSON.parse(job.preferences).nightShift:true)
      setDayShift(job && job.preferences?JSON.parse(job.preferences).dayShift:true)
      setCabRequired(job && job.preferences?JSON.parse(job.preferences).cabRequired:true)
      setOwnTransport(job && job.preferences?JSON.parse(job.preferences).ownTransport:true)
      setSpocId(job && job.spocId);
      setSpocName(job && job.spocName);
      setMaxCTC(job.maxCTC);
      setMinCTC(job.minCTC);
      setJobsDataProps(job);
  setOpenApplicant(true)

}




const handleUpload = (e) =>{
  let formData = new FormData();
  formData.append("resume",e.target.files[0]);
  setResume(formData);
}
const downloadApplicant = (id) => {

  let apiURL = process.env.REACT_APP_API_URL + "/applicants/resume/" + id;
  let apiParams = {
    method: "GET",
    headers:{
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  fetch(apiURL, apiParams)
    .then((res) => (res.status === 200 ? res.json() : res.text()))
    .then((repos) => {
      if (repos.data) {
        let type = repos.data.fileType;
        let bytes = new Uint8Array(repos.data.resume.data.data);
        let newLink = saveByteArray(repos.data.fileName, bytes, type);
        newLink.link.click();
        props.showAlert("File Downloaded Successfully","success")
      } else props.showAlert(""+(repos.message || repos),"error");
    })
    .catch((err) => props.showAlert((''+err),"error"));
};
function saveByteArray(reportName, byte, type) {
  let blob = new Blob([byte], { type: type });
  let link = document.createElement("a");
  link.href = window.URL.createObjectURL(blob);

  let fileName = reportName;
  link.download = fileName;

  let newLink = {
    name: fileName,
    link: link,
  };
  return newLink;
}
 
 const handleCloseView = () => setOpen(false)

   
  // const 

  const cols = [
  
    {field:'name', headerName: 'Name',width: 190 },
    {field:'email', headerName: 'Email',width: 200},
    {field:'mobile', headerName: 'Mobile No',width: 140},
    // {field:'address', headerName: 'Address',width: 300},
    // {field:'jobtitle', headerName: 'Job Title',width: 200},
    {field:'experience', headerName: 'Experience',width: 100},
    {field:'skills', headerName: 'Skills',width: 290},
    {field:'Resume', headerName: 'Resume',width: 80,
    renderCell: (params) =>{
  
    return[
      <div>

    <Button size='small' className='btn' title="Download Resume" onClick={()=>downloadApplicant(params.id)}><FileDownloadIcon/></Button></div>
        ];
      },
     },

    
    {field:'Status', headerName: 'Status',width: 100},
    {field:'action', headerName: 'Action',width: 140,

    renderCell: (params) => {
     return[
        <div>
       <Button size='small' className='btn' title="Schedule" onClick={()=>approveApplicants(params.row)}><EventIcon/></Button>
    <Button size='small' className='btn' title="Reject" onClick={()=>RejectApplicants(params.id)}><CancelOutlinedIcon/></Button></div>
     ];
    },
  },  
    ];
 
//get applicant for particular job with job id  
  const getApplicants = (job_id)=>{
    //window.location.href="/applicants?jobid="+job_id;
    window.location.href="/interviewdetails?jobid="+job_id;

  }

  
  
//Get applicant by id
  const getApplicantDetails = (id)=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +id
    let apiParams = {
        method:"GET",
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
        
          setName(repos.data.name)
          setMail(repos.data.email)
          setMobile(repos.data.mobile)
          setWhatsNumber(repos.data.number)
          setDob(repos.data.dob)
          setGender(repos.data.gender)
         setExperience(repos.data.experience)
          setSkills(repos.data.skill)
          setAddress(repos.data.address)
          setReference(repos.data.reference)
         
        
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }
  
  const approveApplicants = (row) =>{
  setName(row.name)
  setJobId(row.jobid)
  setMobile(row.mobile)
  setJobTitle(row.jobtitle)
  setApplicantId(row.id)
  setMail(row.mail)
  setCompanyName(row.companyname)
    setOpenScedule(true);
  }
 


//Reject Applicants

const RejectApplicants = (id) =>{
 
  let apiURL=process.env.REACT_APP_API_URL + "/applicants/rejectapplicant/" +id;
  let apiParams = {
      method:"POST",
      headers:{
        status:"Rejected",
        authtoken: localStorage.getItem("loggedintoken")
      }
};

  fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
      if (repos.data) {
       // alert("Applicant Rejected");
        props.showAlert("Applicant Rejected","warning")
          setTimeout(() => {
            window.location.reload(false);
          }, 2000);
          
      
      }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }
 

//Add Jobs
const addJobs = () =>{
 
  setOpenApplicant(true)
}

const addjob = ()=>{
     if(companyName.length===0){
    
      props.showAlert("Please Select the Comapany Name!!","error")
     }
     if(jobTitle===0){
      
      props.showAlert("Please Enter Job Title!!","error")
     }
  let apiURL=process.env.REACT_APP_API_URL + "/jobs/addJobs";
  let apiParams = {
      method:"POST",
      headers:{
        companyname:companyName,
        jobtitle: jobTitle,
        jobdescription:encodeURIComponent(jobDescription),
        minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
        maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
        preferences:JSON.stringify({

          voiceProcess:voiceProcess,
          nonVoiceProcess:nonVoiceProcess,
          workFromHome:workFromHome,
          workFromOffice:workFromOffice,
          partTime:partTime,
          fullTime:fullTime,
          nightShift:nightShift,
          dayShift:dayShift,
          cabRequired:cabRequired,
          ownTransport:ownTransport,
         
          }),
        officename:officeName,
        mapurl:mapUrl,
        latitude:latitude,
        longitude:longitude,
        minctc:minCTC,
        maxctc:maxCTC,
        skills: encodeURIComponent(Skills),
        spocid:spocId,
        spocname:spocName,
        active:true,
        authtoken: localStorage.getItem("loggedintoken")
      },
     
  };
  fetch(apiURL, apiParams)
  .then((res)=> (res.status===200 ? res.json() : res.text()))
  .then((repos)=> {
      if (repos.data) {
       // alert("Job Added Successfully");
       props.showAlert("Job Added Successfully","success")
       setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      
      }else props.showAlert(""+(repos.message || repos),"error");
  }) 
  .catch((err) => props.showAlert((''+err),"error"));
}

const Updatejob = ()=>{
 
let apiURL=process.env.REACT_APP_API_URL + "/jobs/updateJobs/"+jobId;
let apiParams = {
   method:"POST",
   headers:{
     companyname:companyName,
     jobtitle: jobTitle,
     jobdescription:encodeURIComponent(jobDescription),
     minexpmonths:(parseInt(minYears)*12)+parseInt(minMonths),
     maxexpmonths:(parseInt(maxYears)*12)+parseInt(maxMonths),
     preferences:JSON.stringify({

       voiceProcess:voiceProcess,
       nonVoiceProcess:nonVoiceProcess,
       workFromHome:workFromHome,
       workFromOffice:workFromOffice,
       partTime:partTime,
       fullTime:fullTime,
       nightShift:nightShift,
       dayShift:dayShift,
       cabRequired:cabRequired,
       ownTransport:ownTransport,
      
       }),
     officename:officeName,
     mapurl:mapUrl,
     latitude:latitude,
     longitude:longitude,
     minctc:minCTC,
     maxctc:maxCTC,
     spocid:spocId,
     spocname:spocName,
     skills: encodeURIComponent(Skills),
     active:true,
     authtoken: localStorage.getItem("loggedintoken")
   },
  
};
fetch(apiURL, apiParams)
.then((res)=> (res.status===200 ? res.json() : res.text()))
.then((repos)=> {
   if (repos.data) {
    // alert("Job Added Successfully");
    handleClose()
    getJobsData()
    props.showAlert("Job Updated Successfully","success")
  //   setTimeout(() => {
  //    window.location.reload(false);
  //  }, 2000);
   
   }else props.showAlert(""+(repos.message || repos),"error");
}) 
.catch((err) => {props.showAlert((''+err),"error");handleClose()})
}


//Get Users
const getUsers = async () =>{
  let apiURL = process.env.REACT_APP_API_URL + "/users/getusers"
  let apiParams = {
    method: "GET",
    headers: {
      authtoken: localStorage.getItem("loggedintoken")
    }
  };
  await fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
          setHRNames(repos.data)
          }else props.showAlert(""+(repos.message || repos),"error");  
      }) 
      .catch((err) => props.showAlert((''+err),"error"));
    }


//Disable Job
 const updateIsActive = (job) =>{
  let confirmation=window.confirm("Are you sure ?")
  if(!confirmation){
    return
  }
  let apiURL=process.env.REACT_APP_API_URL + "/jobs/updateisactive/" +job._id;
  let apiParams = {
    method:"POST",
    headers:{
      active:false,
      authtoken: localStorage.getItem("loggedintoken")
    },
 };

 fetch(apiURL, apiParams)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {
      // alert("Job Removed Successfully");
      props.showAlert("Job Removed Successfully","success")
      setTimeout(() => {
        window.location.reload(false);
      }, 2000);
      
       
     }else props.showAlert(""+(repos.message || repos),"error");
 }) 
 .catch((err) => props.showAlert((''+err),"error"));
 
}

  // useEffect(()=>{
  //   getApplicants(id)
  // },[])
    

  //Deleting records from data base
  // const deleteApplicants=(id)=>{
  //   let apiURL=process.env.REACT_APP_API_URL + "/applicants/" +id
  //   let apiParams = {
  //       method:"DELETE",
  //       headers:{
  //         deletedId:id
  //       }
  //   };
  //   fetch(apiURL, apiParams)
  //  .then(()=>{
  //    alert('Applicant Deleted !!!');
  //    getApplicants()
  //  })
  //  .catch((error)=>{
  //    console.log(error)
  //  })
  // }

  const getOffices = () =>{
    let apiURL = process.env.REACT_APP_API_URL + "/officelocations/getOfficelocations";
    let apiParams = {
      method: "GET",
      headers:{
        authtoken: localStorage.getItem("loggedintoken")
      }
    };
    fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              //console.log(repos.data);
              if(repos.data.length>0)
              {
  
              setOffices(repos.data)
              
            }}else props.showAlert(""+(repos.message || repos),"error");  
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }


  const downloadResume=()=>{
    let type = resume.data.type;
    let bytes = new Uint8Array(resume.data.data);
    let newLink = saveByteArray(resumeName, bytes, type);
    newLink.link.click();
  }
  const getJobsData = ()=>{
       
    let apiURL=process.env.REACT_APP_API_URL + "/jobs/";
    let apiParams = {
        method:"GET",
        headers:{
          authtoken: localStorage.getItem("loggedintoken")
        },
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
            setJobsData(repos.data)
        }else props.showAlert(""+(repos.message || repos),"error");
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }
  
  useEffect(()=>{
  getJobsData()
  },[])

  useEffect(()=>{
    if(searchParams && searchParams.get('applicantid')!==null){
      let applicantid = searchParams.get('applicantid')
      
      getApplicantDetails(applicantid)
    }else if(searchParams && searchParams.get('applicantid')===null && user.accesstype ==="c"){
      getApplicantDetails(user._id)
    }
  },[])


  const MyCopyFunction = async (jd)=>{
    // setCopiedText(heading,jd)

     await navigator.clipboard.writeText(jd);
      //alert("Copied the text: " + jd);
      props.showAlert("JD Copied To ClipBoard","success")
    }
  
    

      // const rowData =[];
      
    const rowData = applicant.map((candidate) => {
    
      return{
      id:candidate._id,
    name:candidate.name,
    email:candidate.email,
    mobile:candidate.mobile,
    address:candidate.address,
    experience:((candidate.experience - (candidate.experience%12))/12),
    skills:candidate.skill,
   Status:candidate.status,
   companyname:candidate.companyname,
    jobtitle:candidate.jobtitle,
    jobid:candidate.jobid,
      };
    });


  return (
    <div style={{position:"absolute",top:"100px"}}>
    <div style={{float:"right"}}>
    {(user.accesstype >= 5)?<Button size='small' variant="contained"  title="Add Vacancy" onClick={()=>addJobs()}>+ Add Vacancy</Button>:""}
   </div>
    <div style={{display:"block",width:"100%",textAlign:"center"}}>
    {jobsData.map((job)=>(
      <div style={{display:"inline-block",padding:"5px",verticalAlign:"top",height:"100%"}}>
      {/* <Grid container spacing={3}>
      <Grid item xs={3}> */}
      <Card className="Box" sx={{ maxWidth: 340, height:"600px",padding:"0px"}}>
      <CardActionArea>
        <CardContent>
        <div style={{backgroundColor:"cornflowerblue",color:"white"}}>
        <Typography className="data" gutterBottom variant="h7" component="div">
          <h5><b>{job.companyname?job.companyname:""}</b></h5>
          {job.officeName}
          </Typography>
        </div>
          <Typography className="data" gutterBottom variant="h8" component="div">
           <b>Job Title:</b> {job.jobtitle?job.jobtitle:""}
          </Typography>
          <Typography className="data" gutterBottom variant="h8" component="div">
           <b>SPOC:</b> {job.spocName?job.spocName:""}
          </Typography>
          <div style={{overflowY:"scroll", maxHeight:"480px"}}>
          <Typography gutterBottom variant="h8" component="div">
           <b>Experience:</b> {((job.minexpmonths - ( job.minexpmonths%12))/12)} Yr {job.minexpmonths%12} Mn to {((job.maxexpmonths - ( job.maxexpmonths%12))/12)} Yr {job.maxexpmonths%12} Mn
          </Typography>

          <Typography  id="copyDes" gutterBottom variant="h8">
          <b>Job Description:</b>{ job.jobdescription}
          </Typography>
         
         
          <Typography gutterBottom variant="h8" component="div">
          <b>Key Skill:</b>{job.skills}
          </Typography>
          </div>
        </CardContent>
      </CardActionArea>
        </Card>
        {/* </Grid>
       </Grid> */}
       <div style={{textAlign:"-webkit-center"}}>
      <CardActions style={{display:"table-cell", textAlign:"center",width:"100%"}}>
       {((searchParams && searchParams.get("applicantid"))||user.accesstype=== "c") ? <Button size="small" title="Apply" variant="contained" onClick={() => applyJob(job)}>
          Apply
        </Button> : ""}
        {user.accesstype !== "c" ?<Button size="small" title="View" variant="contained"  onClick={() => handleView(job)}>
        <VisibilityIcon/>
        </Button>: ""}
        {(user.accesstype == 5)||(job.spocId===user._id) ?<Button size="small" title="Edit" variant="contained"  onClick={() => handleEdit(job)}>
        <ModeIcon/>
        </Button>:<></>}
        {user.accesstype == 5 ? <Button size="small" title="Delete" variant="contained"  onClick={() => updateIsActive(job)}>
        <DeleteIcon />
        </Button>:<></>}
        {user.accesstype !== "c" ?<Button size="small" title="Copy JD  " variant="contained" startIcon={<ContentCopyIcon />} onClick={() => MyCopyFunction("Job Description: \n"+job.jobdescription)}>
         JD
        </Button>: ""}
        {user.accesstype !== "c" ?<Button size="small" title="Copy KS  " variant="contained" startIcon={<ContentCopyIcon />} onClick={() => MyCopyFunction("key Skills: \n"+job.skills)}>
         KS
        </Button>: ""}
       </CardActions>
       </div>
        </div>
       
        
    ))}
   
      <Modal
        open={openView}
        onClose={handleCloseView}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style1}>

        <div className = "content2">
        
       <Button variant='contained' className='Box2' title="Close" size="small" onClick={()=>setOpenView(false)} ><CloseIcon /></Button>
          <Typography id="modal-modal-description" sx={{ mt: 1 }}>
         
          <div style={{height:555}}>
          <div className='heading'>
          {companyName} - {jobTitle}
          </div>
          {/* <br/>
          <br/> */}
      <DataGrid  rows={rowData} columns={cols} checkboxSelection/>
      </div>  
          </Typography>
          </div>
          </Box>
      </Modal>
     
      {/* <Modal
        open={openApplicant}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{overflow:'scroll',textAlign:"center"}}
      >
        <Box sx={style4}>
         
          <Typography id="modal-modal-description">
          <Container style={{padding:"0px"}}>
          <div className = "content5">
        <div style={{alignItems:"center"}}>
          <h2 className="fnt1"><b>{jobId?"Edit":"Post Job"}</b></h2>
          <Button title="Close" variant="Standard" sx={{float:"right",right:0,top:0,position:"absolute",height:35,width:5,color:"white" }} onClick={()=>handleClose()} ><CloseIcon /></Button>   
          </div>
          <FormControl variant="outlined" sx={{ m: 1, width: '35ch' }} size="small" >
        <InputLabel id="Office-label">Company Name</InputLabel>
        <Select
          labelId="Office-labell"
          id="Office" 
          sx={{ width: '35ch' }}
          value={companyName} onChange={(e)=> {
            
            setCompanyName(e.target.value);   
            setOfficeName(offices.filter(ofc=>ofc.company===e.target.value)[0].office);   
            setMapUrl(offices.filter(ofc=>ofc.company===e.target.value)[0].mapUrl);   
            setLatitude(offices.filter(ofc=>ofc.company===e.target.value)[0].latitude);   
            setLongitude(offices.filter(ofc=>ofc.company===e.target.value)[0].longitude);   
         
           } } >

          { 
            
            offices && ([...(new Set(offices.map((cpy) =>cpy.company)))]).map((company) =><MenuItem value={company}>{company}</MenuItem>
  
        )} 
        </Select>        
      </FormControl>
        <FormControl variant="outlined" sx={{ m: 1, width: '35ch' }} size="small" >
        <InputLabel id="Office-label">Office Name</InputLabel>
        <Select
          labelId="Office-labell"
          id="Office" 
          sx={{ width: '35ch' }}
          value={officeName} onChange={(e)=> {
            
            setOfficeName(e.target.value);   
            setMapUrl(offices.filter(ofc=>ofc.office===e.target.value)[0].mapUrl);   
            setLatitude(offices.filter(ofc=>ofc.office===e.target.value)[0].latitude);   
            setLongitude(offices.filter(ofc=>ofc.office===e.target.value)[0].longitude);   
         
           } } >

          { 
            
            offices && offices.filter(cmpy=>cmpy.company===companyName).map((ofc) =><MenuItem value={ofc.office}>{ofc.office}</MenuItem>
  
        )} 
        </Select>        
      </FormControl> 

    
      <TextField className="" id="standard-basic" sx={{ m: 2, width: '35ch' }}  label="Job Title" size="small" variant="outlined"  value={jobTitle} onChange={(e)=> {
          setJobTitle(e.target.value);
        }}/>

         <FormControl variant="outlined" sx={{ m: 2, width: '35ch' }} size="small">
        <InputLabel id="demo-simple-select-label">Spoc Name</InputLabel>
        <Select
          labelId="demo-simple-select-labell"
          id="demo-simple-select"
          
          value={spocId} onChange={(e)=> {
          
            setSpocId(e.target.value);  
            setSpocName(hrNames.filter(hr=>hr._id===e.target.value)[0].name);   
           } } >
          {
           hrNames && hrNames.map(hr=>  <MenuItem value={hr._id}>{hr.name}</MenuItem>)
            
        }
        </Select>
      </FormControl>
           <table border="0">
         <tr>
     <td style={{textAlign:"center"}}>
        <input type="checkbox" id="chkVoice" name="chkVoice" checked={voiceProcess} onChange={()=>setVoiceProcess(!voiceProcess)}/>
        &nbsp;
        <label  for="chkVoice"> Voice </label>
        &nbsp;
        <input type="checkbox" id="chkNonvoice" name="chkNonvoice" checked={nonVoiceProcess} onChange={()=>setNonVoiceProcess(!nonVoiceProcess)}/>
        &nbsp;
        <label  for="chkNonvoice"> Non Voice </label>
        &nbsp;
        <input type="checkbox" id="chkSemiVoice" name="chkSemiVoice" checked={nonVoiceProcess===true && voiceProcess===true} onChange={(e)=>{
          if(e.target.checked===true){
            setNonVoiceProcess(true)
            setVoiceProcess(true)
          }
          
          }}/>
        &nbsp;
        <label  for="chkSemiVoice"> Semi Voice </label>
        &nbsp;
       
        </td>
        </tr>
        <tr><td style={{textAlign:"center"}}>
        <input type="checkbox" id="chkPartTime" name="chkPartTime" checked={partTime} onChange={()=>setPartTime(!partTime)}/>
        &nbsp;
        <label  for="chkPartTime"> Part Time </label>
        &nbsp;
        <input type="checkbox" id="chkFullTime" name="chkFullTime" checked={fullTime} onChange={()=>setFullTime(!fullTime)}/>
        &nbsp;
        <label  for="chkFullTime"> Full Time </label>
        </td></tr>
        <tr><td style={{textAlign:"center"}}>
        <input type="checkbox" id="chkDayShift" name="chkDayShift" checked={dayShift} onChange={()=>setDayShift(!dayShift)}/>
        &nbsp;
        <label  for="chkDayShift">Day Shift </label>
        &nbsp;
        <input type="checkbox" id="chkNightShift" name="chkNightShift" checked={nightShift} onChange={()=>setNightShift(!nightShift)}/>
        &nbsp;
        <label  for="chkNightShift">Night Shift</label>
        &nbsp;
        <input type="checkbox" id="chkRotaionalShift" name="chkRotaionalShift" checked={nightShift===true && dayShift===true} onChange={(e)=>{
         if(e.target.checked===true){
          setNightShift(true);
          setDayShift(true)
         }
          }}/>
        &nbsp;
        <label  for="chkRotationalShift">Rotational Shift</label>
        &nbsp;
        </td></tr>
        <tr><td style={{textAlign:"center"}}>
        <input type="checkbox" id="chkWorkfromhome" name="chkWorkfromhome" checked={workFromHome} onChange={()=>setWorkFromHome(!workFromHome)}/>
        &nbsp;
        <label  for="chkWorkfromhome"> WFH</label>
        &nbsp;
        <input type="checkbox" id="chkWorkfromoffcice" name="chkWorkfromoffcice" checked={workFromOffice} onChange={()=>setWorkFromOffice(!workFromOffice)}/>
        &nbsp;
        <label  for="chkWorkfromoffcice">WFO</label>
        &nbsp;
        <input type="checkbox" id="chkHybrid" name="chkHybrid" checked={workFromOffice===true && workFromHome===true} onChange={(e)=>{
         if(e.target.checked===true){
          setWorkFromOffice(true)
          setWorkFromHome(true)
         }
         
        }}/>
        &nbsp;
        <label  for="chkHybrid">Hybrid</label>
        &nbsp;
      
        </td></tr>
       
        <tr><td style={{textAlign:"center"}}>
        <input type="checkbox" id="chkCabRequired" name="chkCabRequired" checked={cabRequired} onChange={()=>setCabRequired(!cabRequired)}/>
        &nbsp;
        <label  for="chkCabRequired"> Cab Required </label>
        &nbsp;
        <input type="checkbox" id="chkOwnTransport" name="chkOwnTransport" checked={ownTransport} onChange={()=>setOwnTransport(!ownTransport)}/>
        &nbsp;
        <label  for="chkOwnTransport">Own Transport</label>
        &nbsp;
       
        </td></tr>
      
</table>

      <TextField className="" id="outlined-multiline-static"
       multiline
        sx={{ m: 2, width: '35ch' }}
        size="small"
          rows={3} 
          label="Job Description" variant="outlined" value={jobDescription} onChange={(e)=> {
        setJobDescription(e.target.value); 

      }}/>
     
      <TextField className="" id="outlined-basic-normal" multiline rows={3}  sx={{ m: 2, width: '35ch' }} size="small" label="Required Skills" variant="outlined"  value={Skills} onChange={(e)=> {
        setSkills(e.target.value);     
      }}/>
          <br/>
          
           <InputLabel id="demo-simple-select-outlined-label" >Min Experience</InputLabel>
      <TextField id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Years" variant="outlined"    value={minYears} onChange={(e)=> {
          
          setMinYears(e.target.value);
        }}/>
      <TextField  id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Months" variant="outlined"    value={minMonths} onChange={(e)=> {
          
          setMinMonths(e.target.value);
        }}/>
      <InputLabel id="demo-simple-select-outlined-label" >Max Experience</InputLabel>

        <TextField  id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Years" variant="outlined"    value={maxYears} onChange={(e)=> {
          
          setMaxYears(e.target.value);
        }}/>
      <TextField id="standard-basic" sx={{ m: 1, width: '10ch' }} size="small" label="Months" variant="outlined"    value={maxMonths} onChange={(e)=> {
          
          setMaxMonths(e.target.value);
        }}/>
        <div>
      <InputLabel id="demo-simple-select-outlined-label" >Salary Range</InputLabel>
      <TextField id="standard-basic" sx={{ m: 1, width: '15ch' }} size="small" label="Min CTC" variant="outlined"    value={minCTC} onChange={(e)=> {
          
          setMinCTC(e.target.value);
        }}/>
      <TextField  id="standard-basic" sx={{ m: 1, width: '15ch' }} size="small" label="Max CTC" variant="outlined"    value={maxCTC} onChange={(e)=> {
          
          setMaxCTC(e.target.value);
        }}/>
        </div>
   
    <br/>
    <Button  variant="contained" sx={{ m: 1, width: '35ch' }} onClick={()=>jobId?Updatejob():addjob()}>{jobId?"Edit":"Submit"}</Button>  
   
      </div>
      </Container> 
          </Typography>
        </Box>
      </Modal> */}

      {/* <EditJobs type={jobsDataProps._id?"Edit":"Add"} openJobs={openApplicant} jobsData={jobsDataProps} showAlert={props.showAlert} close={()=>{setOpenApplicant(false);setJobsDataProps([]);getJobsData()}}/> */}

      <AddJobVacancyPopUpUI type={jobsDataProps._id?"Edit":"Add"} openJobs={openApplicant} jobsData={jobsDataProps} showAlert={props.showAlert} close={()=>{setOpenApplicant(false);setJobsDataProps([]);getJobsData()}}/>


    </div>
    </div>
  );
}

export default Jobs
