import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import './WorkPreferencesMobileUI.css'
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';


const WorkPreferencesMobileUI = (props) => {

//useState

const [voiceProcess,setVoiceProcess] = useState(true)
const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
const [workFromHome,setWorkFromHome] = useState(false)
const [workFromOffice,setWorkFromOffice] = useState(true)
const [partTime,setPartTime] = useState(false)
const [fullTime,setFullTime] = useState(true)
const [temporary,setTemporary] = useState(true)
const [nightShift,setNightShift] = useState(true)
const [dayShift,setDayShift] = useState(true)
const [companyTransportDay,setCompanyTransportDay] = useState(true)
const [companyTransportNight,setCompanyTransportNight] = useState(true)
const [ownTransport,setOwnTransport] = useState(true)
const [hightlight,setHighlight] = useState({})

//UseEffect     
useEffect(()=>{
  let preferences = props.preferences;

  if(props.highlight)
{ 
   setHighlight(JSON.parse(props.highlight.candidatePreference))
   //console.log(JSON.parse(props.highlight.candidatePreference))
}

if(preferences){
        setVoiceProcess(preferences.voiceProcess)
        setNonVoiceProcess(preferences.nonVoiceProcess)
        setWorkFromHome(preferences.workFromHome)
        setWorkFromOffice(preferences.workFromOffice)
        setPartTime(preferences.partTime)
        setFullTime(preferences.fullTime)
        setNightShift(preferences.nightShift)
        setDayShift(preferences.dayShift)
        setCompanyTransportDay(preferences.companyTransportDay)
        setCompanyTransportNight(preferences.companyTransportNight)
        setOwnTransport(preferences.ownTransport)
}

},[props.preferences,props.highlight])

  return (
    <div>
      
    <div className='chkVoiceMobile'>
    <input disabled={props.type==="View"}  type="checkbox" id="chkVoiceMobile" name="chkVoice" checked={voiceProcess} onChange={()=>props.setPreference("voiceProcess",!voiceProcess)}/>
    </div>
    <label id='WPVoiceMobile' for="chkVoice" style={{color:voiceProcess?"red":"black",backgroundColor:(hightlight.voiceProcess===true)?"yellow":"transparent"}}> Voice </label>
    
  

    <div className='chkNonvoiceMobile'>
<input disabled={props.type==="View"} type="checkbox" id="chkNonvoice" name="chkNonvoice" checked={nonVoiceProcess} onChange={()=>props.setPreference("nonVoiceProcess",!nonVoiceProcess)}/>
</div>
 <label id='Non-Voice-mobile' for="chkNonvoice" style={{color:nonVoiceProcess?"red":"black",backgroundColor:(hightlight.nonVoiceProcess===true)?"yellow":"transparent"}}> Non-Voice </label>


    <div className='chkSemiVoiceMobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkSemiVoiceMobile" name="chkSemiVoice" checked={nonVoiceProcess===true && voiceProcess===true} onChange={(e)=>{
        
        if(e.target.checked===true){
          props.setPreference("voiceProcess",true)
          props.setPreference("nonVoiceProcess",true)
        }
        
        }}/>
	</div>
    <label id='WPSemiVoiceMobilelbl' for="chkSemiVoice" style={{color:(nonVoiceProcess===true && voiceProcess===true?"red":"black"),backgroundColor:(hightlight.nonVoiceProcess===true) && (hightlight.voiceProcess===true) ?"yellow":"transparent"}}> Semi Voice </label>


    <div id="Employment_Type_Mobile_lbl">
		<span>Employment Type</span>
	</div>
    <div className='chkFullTimeMobile'>
        <input disabled={props.type==="View"} type="checkbox" id="chkFullTimeMobile" name="chkFullTime" checked={fullTime} onChange={()=> props.setPreference("fullTime",!fullTime)}/>
    </div>
    <label id='WPFull_TimeMobile_lbl' for="chkFullTime" style={{color:fullTime?"red":"black",backgroundColor:(hightlight.fullTime===true)?"yellow":"transparent"}}> Full Time </label>

  
    <div className='chkPartTimeMobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkPartTimeMobile" name="chkPartTime" checked={partTime} onChange={()=>props.setPreference("partTime",!partTime)}/>     
    </div>
    <label id='WPPart_Time_Mobile_lbl' for="chkPartTime" style={{color:partTime?"red":"black",backgroundColor:(hightlight.partTime===true)?"yellow":"transparent"}}> Part Time </label>


    <div className='chkTemporaryMobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkTemporaryMobile" name="chkPartTime" checked={temporary} onChange={()=>props.setPreference("temporary",!temporary)}/>     
    </div>
    <label id='WPTemporaryMobilelbl' for="chkPartTime" style={{color:temporary?"red":"black",backgroundColor:(hightlight.temporary===true)?"yellow":"transparent"}}> Temporary</label>

    <div className='chkWorkfromofficeMobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromofficeMobile" name="chkWorkfromoffcice" checked={workFromOffice} onChange={()=> props.setPreference("workFromOffice",!workFromOffice)}/>
    </div>
    <label id='WPOfficeMobileLbl' for="chkWorkfromoffcice" style={{color:workFromOffice?"red":"black",backgroundColor:(hightlight.workFromOffice===true)?"yellow":"transparent"}}>Office</label>
	

    <div className='chkWorkfromhomeMobile'>
        <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromhome" name="chkWorkfromhome" checked={workFromHome} onChange={()=> props.setPreference("workFromHome",!workFromHome)}/>
    </div>
        <label id='WPHomeMobilelbl' for="chkWorkfromhome" style={{color:workFromHome?"red":"black",backgroundColor:(hightlight.workFromHome===true)?"yellow":"transparent"}}>Home</label>
     

        <div className='chkHybridMobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkHybridMobile" name="chkHybrid" checked={workFromOffice===true && workFromHome===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("workFromHome",true)
          props.setPreference("workFromOffice",true)
          setWorkFromOffice(true)
          setWorkFromHome(true)
         }
         
        }}/>
    </div>

        <label id='WPHybridMobileLbl' for="chkHybrid" style={{color:workFromOffice===true && workFromHome===true?"red":"black",backgroundColor:(hightlight.workFromOffice===true)&& (hightlight.workFromHome===true)?"yellow":"transparent"}}>Hybrid</label>

        <div id="Shifts_Mobile_lbl">
		<span>Shifts</span>
	    </div>


    <div className='chkDayShift_Mobile_lbl'>
    <input disabled={props.type==="View"} type="checkbox" id="chkDayShift_Mobile_lbl" name="chkDayShift" checked={dayShift} onChange={()=> props.setPreference("dayShift",!dayShift)}/>
    </div>
    <label id='WPDay_mobile_lbl' for="chkDayShift" style={{color:dayShift?"red":"black",backgroundColor:(hightlight.dayShift===true)?"yellow":"transparent"}}>Day</label>


    <div className='chkNightShift_Mobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkNightShift_Mobile" name="chkNightShift" checked={nightShift} onChange={()=> props.setPreference("nightShift",!nightShift)}/>
    </div>
    <label id='WPNight_Mobile_lbl' for="chkNightShift" style={{color:nightShift?"red":"black",backgroundColor:(hightlight.nightShift===true)?"yellow":"transparent"}}>Night</label>

    <div className='chkRotaionalShift_mobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkRotaionalShift_mobile" name="chkRotaionalShift" checked={nightShift===true && dayShift===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("nightShift",true)
          props.setPreference("dayShift",true)
         }
          }}/>
    </div>
        <label id='WPRotational_Mobile_lbl' for="chkRotationalShift" style={{color:nightShift===true && dayShift===true?"red":"black",backgroundColor:(hightlight.nightShift===true)  && (hightlight.dayShift===true)?"yellow":"transparent"}}>Rotational</label>

        <div className='chkOwnTransport_mobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkOwnTransport_mobile" name="chkOwnTransport" checked={ownTransport} onChange={()=> props.setPreference("ownTransport",!ownTransport)}/>
    </div>
    <label id='WPOwn_Mobile_lbl' for="chkOwnTransport" style={{color:ownTransport?"red":"black",backgroundColor:(hightlight.ownTransport===true)?"yellow":"transparent"}}>Own</label>



    <div className='chkCompanyTransportDay_mobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkCompanyTransportDay_mobile" name="chkCompanyTransportDay" checked={companyTransportDay} onChange={()=>props.setPreference("companyTransportDay",!companyTransportDay)}/>
    </div>
    <label id='WPCompany_Day_mobile_lbl' for="chkCompanyTransportDay" style={{color:companyTransportDay?"red":"black",backgroundColor:(hightlight.companyTransportDay===true)?"yellow":"transparent"}}>Company Day</label>       


	<div className='chkCompanyTransportNight_Mobile'>
    <input disabled={props.type==="View"} type="checkbox" id="chkCompanyTransportNight_Mobile" name="chkCompanyTransportNight" checked={companyTransportNight} onChange={()=>props.setPreference("companyTransportNight",!companyTransportNight)}/>
    </div>
    
    <label id='WPCompany_Night' for="chkCompanyTransportNight" style={{color:companyTransportNight?"red":"black",backgroundColor:(hightlight.companyTransportNight===true)?"yellow":"transparent"}}>Company Night</label>       

    <div id="Work_Mode_Mobile_Lbl">
		<span>Work Mode</span>
	</div>
   
	<div id="WPTransportation">
		<span>Transportation</span>
	</div>
	
    <div id="WPShifts">
		<span>Shifts</span>
	</div>
	
    </div>
  )
}

export default WorkPreferencesMobileUI
