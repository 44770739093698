import React from 'react'
import ApplicantPopup from './ApplicantPopup'
import ApplicantPopupUI from './ApplicantPopupUI'
import RegisterUI from './RegisterUI'
import RegisterMobileUI from './RegisterMobileUI'

const MyProfile = (props) => {
  return (
    <div>
      {/* <ApplicantPopup open={true} showAlert={props.showAlert} applicantId={(localStorage.getItem("loggedInUser")&&JSON.parse(localStorage.getItem("loggedInUser"))._id)||""} /> */}
      {/* <ApplicantPopupUI open={true} showAlert={props.showAlert} applicantId={(localStorage.getItem("loggedInUser")&&JSON.parse(localStorage.getItem("loggedInUser"))._id)||""}/> */}
      {(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent))?<RegisterMobileUI openMobile={true} showAlert={props.showAlert} applicantId={(localStorage.getItem("loggedInUser") && ((JSON.parse(localStorage.getItem("loggedInUser")).accesstype==="c"||JSON.parse(localStorage.getItem("loggedInUser")).accesstype==="candidate") &&JSON.parse(localStorage.getItem("loggedInUser"))._id)?JSON.parse(localStorage.getItem("loggedInUser"))._id:"")||""}/>:<ApplicantPopupUI mobile={false} open={true} showAlert={props.showAlert} 
      applicantId={(localStorage.getItem("loggedInUser")&& ((JSON.parse(localStorage.getItem("loggedInUser")).accesstype==="c"||JSON.parse(localStorage.getItem("loggedInUser")).accesstype==="candidate") &&JSON.parse(localStorage.getItem("loggedInUser"))._id) && JSON.parse(localStorage.getItem("loggedInUser"))._id?JSON.parse(localStorage.getItem("loggedInUser"))._id:"")||""}
      />}
    </div>
  )
}

export default MyProfile
