import React, { useEffect, useState, useContext} from 'react'
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import TextField from "@mui/material/TextField";
import { Container } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import CloseIcon from '@mui/icons-material/Close';
import { useSearchParams } from 'react-router-dom';
import { UserContext } from './UserContext';



const WorkPreferences = (props) => {

//useState

    const [voiceProcess,setVoiceProcess] = useState(true)
    const [nonVoiceProcess,setNonVoiceProcess] = useState(false)
    const [workFromHome,setWorkFromHome] = useState(false)
    const [workFromOffice,setWorkFromOffice] = useState(true)
    const [partTime,setPartTime] = useState(false)
    const [fullTime,setFullTime] = useState(true)
    const [nightShift,setNightShift] = useState(true)
    const [dayShift,setDayShift] = useState(true)
    const [companyTransportDay,setCompanyTransportDay] = useState(true)
    const [companyTransportNight,setCompanyTransportNight] = useState(true)
    const [ownTransport,setOwnTransport] = useState(true)
    const [hightlight,setHighlight] = useState({})

  //UseEffect     
 useEffect(()=>{
      let preferences = props.preferences;

      if(props.highlight)
    { 
       setHighlight(JSON.parse(props.highlight.candidatePreference))
       //console.log(JSON.parse(props.highlight.candidatePreference))
    }

    if(preferences){
            setVoiceProcess(preferences.voiceProcess)
            setNonVoiceProcess(preferences.nonVoiceProcess)
            setWorkFromHome(preferences.workFromHome)
            setWorkFromOffice(preferences.workFromOffice)
            setPartTime(preferences.partTime)
            setFullTime(preferences.fullTime)
            setNightShift(preferences.nightShift)
            setDayShift(preferences.dayShift)
            setCompanyTransportDay(preferences.companyTransportDay)
            setCompanyTransportNight(preferences.companyTransportNight)
            setOwnTransport(preferences.ownTransport)
    }
   
   },[props.preferences,props.highlight])

//Functions

 
  return (
    <div>

           <table border="0">
         <tr>
     <td style={{textAlign:"center"}}>
        <input disabled={props.type==="View"}  type="checkbox" id="chkVoice" name="chkVoice" checked={voiceProcess} onChange={()=>props.setPreference("voiceProcess",!voiceProcess)}/>
        &nbsp;
        <label  for="chkVoice" style={{color:voiceProcess?"red":"black",backgroundColor:(hightlight.voiceProcess===true)?"yellow":"transparent"}}> Voice </label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkNonvoice" name="chkNonvoice" checked={nonVoiceProcess} onChange={()=>props.setPreference("nonVoiceProcess",!nonVoiceProcess)}/>
        &nbsp;
        <label  for="chkNonvoice" style={{color:nonVoiceProcess?"red":"black",backgroundColor:(hightlight.nonVoiceProcess===true)?"yellow":"transparent"}}> Non Voice </label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkSemiVoice" name="chkSemiVoice" checked={nonVoiceProcess===true && voiceProcess===true} onChange={(e)=>{
        
          if(e.target.checked===true){
            props.setPreference("voiceProcess",true)
            props.setPreference("nonVoiceProcess",true)
          }
          
          }}/>
        &nbsp;
        <label  for="chkSemiVoice" style={{color:(nonVoiceProcess===true && voiceProcess===true?"red":"black"),backgroundColor:(hightlight.nonVoiceProcess===true) && (hightlight.voiceProcess===true) ?"yellow":"transparent"}}> Semi Voice </label>
        &nbsp;       
        </td>
        </tr>
        <tr><td style={{textAlign:"center"}}>
        <input disabled={props.type==="View"} type="checkbox" id="chkFullTime" name="chkFullTime" checked={fullTime} onChange={()=> props.setPreference("fullTime",!fullTime)}/>
        &nbsp;
        <label  for="chkFullTime" style={{color:fullTime?"red":"black",backgroundColor:(hightlight.fullTime===true)?"yellow":"transparent"}}> Full Time </label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkPartTime" name="chkPartTime" checked={partTime} onChange={()=>props.setPreference("partTime",!partTime)}/>
        &nbsp;
        <label  for="chkPartTime" style={{color:partTime?"red":"black",backgroundColor:(hightlight.partTime===true)?"yellow":"transparent"}}> Part Time </label>
        </td></tr>

        <tr><td style={{textAlign:"center"}}>
        Shift&nbsp;<input disabled={props.type==="View"} type="checkbox" id="chkDayShift" name="chkDayShift" checked={dayShift} onChange={()=> props.setPreference("dayShift",!dayShift)}/>
        &nbsp;
        <label  for="chkDayShift" style={{color:dayShift?"red":"black",backgroundColor:(hightlight.dayShift===true)?"yellow":"transparent"}}>Day</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkNightShift" name="chkNightShift" checked={nightShift} onChange={()=> props.setPreference("nightShift",!nightShift)}/>
        &nbsp;
        <label  for="chkNightShift" style={{color:nightShift?"red":"black",backgroundColor:(hightlight.nightShift===true)?"yellow":"transparent"}}>Night</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkRotaionalShift" name="chkRotaionalShift" checked={nightShift===true && dayShift===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("nightShift",true)
          props.setPreference("dayShift",true)
         }
          }}/>
        &nbsp;
        <label  for="chkRotationalShift" style={{color:nightShift===true && dayShift===true?"red":"black",backgroundColor:(hightlight.nightShift===true)  && (hightlight.dayShift===true)?"yellow":"transparent"}}>Rotational</label>
        &nbsp;
        </td></tr>
        <tr><td style={{textAlign:"center"}}>
        Work from &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromoffcice" name="chkWorkfromoffcice" checked={workFromOffice} onChange={()=> props.setPreference("workFromOffice",!workFromOffice)}/>
        &nbsp;
        <label  for="chkWorkfromoffcice" style={{color:workFromOffice?"red":"black",backgroundColor:(hightlight.workFromOffice===true)?"yellow":"transparent"}}>Office</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkWorkfromhome" name="chkWorkfromhome" checked={workFromHome} onChange={()=> props.setPreference("workFromHome",!workFromHome)}/>
        &nbsp;
        <label  for="chkWorkfromhome" style={{color:workFromHome?"red":"black",backgroundColor:(hightlight.workFromHome===true)?"yellow":"transparent"}}>Home</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkHybrid" name="chkHybrid" checked={workFromOffice===true && workFromHome===true} onChange={(e)=>{
         if(e.target.checked===true){
          props.setPreference("workFromHome",true)
          props.setPreference("workFromOffice",true)
          setWorkFromOffice(true)
          setWorkFromHome(true)
         }
         
        }}/>
        &nbsp;
        <label  for="chkHybrid" style={{color:workFromOffice===true && workFromHome===true?"red":"black",backgroundColor:(hightlight.workFromOffice===true)&& (hightlight.workFromHome===true)?"yellow":"transparent"}}>Hybrid</label>
        </td></tr>
        
        <tr><td style={{textAlign:"center"}}>
        Transport<br/><input disabled={props.type==="View"} type="checkbox" id="chkOwnTransport" name="chkOwnTransport" checked={ownTransport} onChange={()=> props.setPreference("ownTransport",!ownTransport)}/>
        &nbsp;
        <label  for="chkOwnTransport" style={{color:ownTransport?"red":"black",backgroundColor:(hightlight.ownTransport===true)?"yellow":"transparent"}}>Own</label>
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkCompanyTransportDay" name="chkCompanyTransportDay" checked={companyTransportDay} onChange={()=>props.setPreference("companyTransportDay",!companyTransportDay)}/>
        &nbsp;
        <label  for="chkCompanyTransportDay" style={{color:companyTransportDay?"red":"black",backgroundColor:(hightlight.companyTransportDay===true)?"yellow":"transparent"}}> Company Day</label>       
        &nbsp;
        <input disabled={props.type==="View"} type="checkbox" id="chkCompanyTransportNight" name="chkCompanyTransportNight" checked={companyTransportNight} onChange={()=>props.setPreference("companyTransportNight",!companyTransportNight)}/>
        &nbsp;
        <label  for="chkCompanyTransportNight" style={{color:companyTransportNight?"red":"black",backgroundColor:(hightlight.companyTransportNight===true)?"yellow":"transparent"}}> Company Night</label>       
       </td></tr>
      
</table>

    </div>
  )
}

export default WorkPreferences
