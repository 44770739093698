import React, {useState, useEffect, useContext,Fragment} from 'react'
import { DataGrid,  GridToolbar} from '@mui/x-data-grid'
import { Button, Divider} from '@mui/material';
import './RegisterMobileUI.css';
import TextField from "@mui/material/TextField";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { useSearchParams } from 'react-router-dom';
// import DatePicker from '@mui/lab/DatePicker'; 
// import TimePicker from '@mui/lab/TimePicker';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
// import { Splitscreen } from '@mui/icons-material';
// import { id } from 'date-fns/locale';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { setSeconds } from 'date-fns';

import {Link, useNavigate} from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { id } from 'date-fns/locale';
import { UserContext } from './UserContext';
import { CircularProgress } from '@mui/material';
import { Autocomplete } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import WorkPreferences from './WorkPreferences';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import InputAdornment from '@mui/material/InputAdornment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import TransgenderIcon from '@mui/icons-material/Transgender';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
// import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import ApartmentIcon from '@mui/icons-material/Apartment';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import WorkPreferencesUI from './WorkPreferencesUI';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import TranslateIcon from '@mui/icons-material/Translate';
import PsychologyIcon from '@mui/icons-material/Psychology';
import HomeIcon from '@mui/icons-material/Home';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import { calculateNewValue } from '@testing-library/user-event/dist/utils';
import WorkPreferencesMobileUI from './WorkPreferencesMobileUI.js';

const RegisterMobileUI = (props) => {
   
  const style2 = {
    position: 'relative',
    margin:"auto",
    height: "auto",
    minWidth: 375,
    maxWidth: 450,
    scrollY:"auto",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    };
  
    const City = [
        { label: 'Bangalore' },
      ]  
      const Reference = [
        { label: 'Apna App' },
        { label: 'Linked In' },
        { label: 'Facebook' },
        { label: 'Naukri.com' },
        { label: 'Monster.com' },
        { label: 'Quickr.com' },
        { label: 'Walk-In' },
      ]  
    const label = { inputProps: { 'aria-label': 'Schedule Interview' } };
    const today=() => {
      var date = new Date();
      const day = (date.getDate() < 10 ? '0' : '')+date.getDate();
      const month = (date.getMonth() + 1 < 10 ? '0' : '')+(date.getMonth() + 1);
      const year = date.getFullYear();
      return year+'-'+month+'-'+day;
    }
  
  // const [interview, setInterview] = useState([]);
  const [OpenShedule, setOpenScedule ]= React.useState();
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [mail, setMail] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsNumber,setWhatsNumber] = useState("");
  const [gender, setGender] = useState("Male");
  const [experience,setExperience]=useState();
  const [address, setAddress] = useState("");
  const [ Skills,setSkills]= useState("");
  const [jobId, setJobId] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [openApplicantPopup, setOpenApplicantPopup] = React.useState(false);
  const [resume, setResume ] = useState();
  const [reference, setReference]= useState("");
  const [Dob, setDob ]= React.useState(today());
  const [ExpYears, setExpYears]= useState(0);
  const [ExpMonth, setExpMonths]= useState(0);
  const [companyName, setCompanyName] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [isLoading,setIsLoading]=useState(false);
  const [city ,setCity]= useState("");
  const [area,setArea] = useState("");
  const [scheduleInterview, setScheduleInterview] = useState(true);
  const [language, setLanguage ] = useState("");
  const [previousCurrent,setPreviousCurrent ]= useState(0);
  const [expectedSalary,setExpectedSalary ] = useState(0);
  const [voiceProcess,setVoiceProcess] = useState(true)
  const [nonVoiceProcess,setNonVoiceProcess] = useState(true)
  const [workFromHome,setWorkFromHome] = useState(true)
  const [workFromOffice,setWorkFromOffice] = useState(true)
  const [partTime,setPartTime] = useState(true)
  const [fullTime,setFullTime] = useState(true)
  const [nightShift,setNightShift] = useState(true)
  const [dayShift,setDayShift] = useState(true)
  const [companyTransportDay,setCompanyTransportDay] = useState(true)
  const [companyTransportNight,setCompanyTransportNight] = useState(true)
  const [ownTransport,setOwnTransport] = useState(true)
  const [willingToRelocate,setWillingToRelocate] = useState(true)
  const [jobApplications,setJobApplications] = useState([])
  const [education,setEducation] = useState("");
  const [resumeUrl,setResumeUrl] = useState("");
  const [applicantId,setApplicantId] = useState("");
  const [img,setImg]= useState("");
  const [profilePic,setProfilePic] = useState("")
  var navigate = useNavigate();
  
  
  useEffect(()=>{
    
    setOpenApplicantPopup(props.openMobile)

    if(props.openMobile)
      {
    
      
        if(props.applicantId.length > 0){
          setId(props.applicantId)
  
          showApplicant()
        }else{
          
          if(localStorage.getItem("loggedInUser"))
          {
          setName(JSON.parse(localStorage.getItem("loggedInUser")).name)
          setMail(JSON.parse(localStorage.getItem("loggedInUser")).email)
          setMobile(JSON.parse(localStorage.getItem("loggedInUser")).phone)
          setWhatsNumber(JSON.parse(localStorage.getItem("loggedInUser")).phone)
        }}

      }

  
    },[props.openMobile])
    
    useEffect(()=>{

      props.applicantId && Promise.all(props.applicantId).then( ()=>{
        
        searchJobByApplicantId()
        
      });
         
        
      },[id])


      useEffect(()=>{
        
       
       if(props.openApp)
         {
         setOpenApplicantPopup(props.openApp)
       if(props.id){
           setId(props.id)
           showApplicantDetails(props.id)
          }
           
        }
     
       },[props.openApp])
       
  
    const showApplicant = ()=>{
        if(props.applicantId){
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +props.applicantId
        let apiParams = {
            method:"GET",
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:today())
              setGender(repos.data.gender)
              setExpYears(repos.data.experience?(((repos.data.experience - (repos.data.experience%12))/12)):0)
              setExpMonths(repos.data.experience?(repos.data.experience%12):0);
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
              if(repos.data.profilePicUrl && repos.data.profilePicUrl.length>50){
                setImg("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                // console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                              }
              if(repos.data.resumeUrl && repos.data.resumeUrl.length>50){
                setResumeUrl(repos.data.resumeUrl)
              }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }
    }

    const showApplicantDetails = (id)=>{
        
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/getapplicantdetails/" +id;
        let apiParams = {
            method:"GET",
        };
        fetch(apiURL, apiParams)
        .then((res)=> (res.status===200 ? res.json() : res.text()))
        .then((repos)=> {
            if (repos.data) {
            // console.log(repos.data)
              setName(repos.data.name?repos.data.name:"")
              setMail(repos.data.email?repos.data.email:"")
              setMobile(repos.data.mobile?repos.data.mobile:"")
              setWhatsNumber(repos.data.number?repos.data.number:"")
              setDob(repos.data.dob?repos.data.dob:today())
              setGender(repos.data.gender)
              setExpYears(repos.data.experience?(((repos.data.experience - (repos.data.experience%12))/12)):0)
              setExpMonths(repos.data.experience?(repos.data.experience%12):0);
              setSkills(repos.data.skill?repos.data.skill:"")
              setAddress(repos.data.address?repos.data.address:"")
              setReference(repos.data.reference?repos.data.reference:"")
              setCity(repos.data.city?repos.data.city:"")
              setArea(repos.data.area?repos.data.area:"")
              setEducation(repos.data.education?repos.data.education:"")
              setLanguage(!repos.data.languages||repos.data.languages=="undefined"?"":repos.data.languages)
              setPreviousCurrent(!repos.data.currentSalary||repos.data.currentSalary=="undefined"?"":repos.data.currentSalary)
              setExpectedSalary(!repos.data.expectedSalary||repos.data.expectedSalary=="undefined"?"":repos.data.expectedSalary)
              setVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).voiceProcess:true)
              setNonVoiceProcess(repos.data.preferences?JSON.parse(repos.data.preferences).nonVoiceProcess:true)
              setWorkFromHome(repos.data.preferences?JSON.parse(repos.data.preferences).workFromHome:true)
              setWorkFromOffice(repos.data.preferences?JSON.parse(repos.data.preferences).workFromOffice:true)
              setPartTime(repos.data.preferences?JSON.parse(repos.data.preferences).partTime:true)
              setFullTime(repos.data.preferences?JSON.parse(repos.data.preferences).fullTime:true)
              setNightShift(repos.data.preferences?JSON.parse(repos.data.preferences).nightShift:true)
              setDayShift(repos.data.preferences?JSON.parse(repos.data.preferences).dayShift:true)
              setCompanyTransportDay(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportDay:true)
              setCompanyTransportNight(repos.data.preferences?JSON.parse(repos.data.preferences).companyTransportNight:true)
              setOwnTransport(repos.data.preferences?JSON.parse(repos.data.preferences).ownTransport:true)
              setWillingToRelocate(repos.data.preferences?JSON.parse(repos.data.preferences).willingToRelocate:true)
              if(repos.data.profilePicUrl && repos.data.profilePicUrl.length>50){
setImg("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
// console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
              }
            }else props.showAlert(""+(repos.message || repos),"error");  
        }) 
        .catch((err) => props.showAlert((''+err),"error"));
      }



  const handleClose = () =>{
  
   if(props.open===true){
    props.close();
   }
    if(props.openApp===true){
      resetApplicant()
      props.closeApp();
    }
   
    setOpenApplicantPopup(false);
    
    }
  
    const resetApplicant = ()=>{
        setId("");
        setName("");
        setMail("");
        setMobile("");
        setWhatsNumber("");
        setDob(today());
        setAddress("");
        setCity("");
        setArea("");
        setSkills("");
        setGender("");
        setExpYears(0);
        setExpMonths(0);
        setReference("");
      }

//Upload Resume to Google Drive
      const UploadToDrive = async (applicantId) =>{
     
        var file = resume //the file
        var reader = new FileReader() //this for convert to Base64 
        reader.readAsDataURL(resume) //start conversion...
        reader.onload = function (e) { //.. once finished..
          var rawLog = reader.result.split(',')[1]; //extract only thee file data part
          var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
         fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
            { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
            .then(res => res.json()).then((data) => {
              //See response
              console.log(data)
              RetrieveResume(data,applicantId)
              props.showAlert("Resume Uploaded Successfully","success")
      
            }).catch(err => console.log(err)) // Or Error in console
        }
      
      }

      const RetrieveResume =async (data,applicantId)=>{
       
if(applicantId){
 
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveResumeUrl/" + applicantId;
        let apiParams = {
            method:"POST",
            headers:{
              resumeurl:data.url,
              authtoken: localStorage.getItem("loggedintoken")
            }
      }
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
           
          // setResumeUrl(data.url)
          }
        })
        .catch(e => console.log(e)) 
  } 
 }


        const handleUpload = (e) =>{
        let formData = new FormData();
        formData.append("resume",e.target.files[0]);
        setResume(e.target.files[0]);
        // console.log(formData)
      }

      const checkduplicate =()=>{
        if(mobile.length>=10){
        let phone = mobile.toString().replace('-','').slice(-10);
        // console.log(phone)
        let apiURL=process.env.REACT_APP_API_URL + "/applicants/phone/" +phone;
          let apiParams = {
              method:"GET",
              
          };
           fetch(apiURL, apiParams)
          .then((res)=> (res.status===200 ? res.json() : res.text()))
          .then((repos)=> {
              if (repos.data) {
                if(user && user.accesstype== 5){
        let confirmation=window.confirm(repos.data.name+" registered with "+repos.data.mobile+". Do you want to create another registration with same number.")
        if(!confirmation){
          setMobile("")
        }else{
          return
        }}else{
          props.showAlert((repos.data.name+" registered with "+repos.data.mobile+" already!"),"error")
          setMobile("")
        }
              } 
          }) 
          .catch((err) => props.showAlert((''+err),"error"));
        }
      
        }


        //Upload Image To google Drive 


        const handleImageUpload = async (e) => {
          if (e.target.files[0].size > 71680) {
            //alert("Please choose a file with size below 70kb!");
            props.showAlert("Please choose a file with size below 70kb!","error")
            return;
          }
          handleFileSelect(e.target.files[0]);

          setProfilePic(e.target.files[0]);
         
        };

      //Upload Image
        const UploadImageToDrive = async (applicantId) =>{
     
          var file = profilePic //the file
          var reader = new FileReader() //this for convert to Base64 
          reader.readAsDataURL(profilePic) //start conversion...
          reader.onload = function (e) { //.. once finished..
            var rawLog = reader.result.split(',')[1]; //extract only thee file data part
            var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
           fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
              { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
              .then(res => res.json()).then((data) => {
                //See response
                
                 SaveProfilePic(data,applicantId)
                props.showAlert("Profile Pic Uploaded Successfully","success")
        
              }).catch(err => console.log(err)) // Or Error in console
          }
        
        }

  
      const SaveProfilePic = (data,applicantId)=>{
if(applicantId){

        let apiURL=process.env.REACT_APP_API_URL + "/applicants/saveProfileUrl/" + applicantId;
        let apiParams = {
            method:"POST",
            headers:{
              profilepicurl:data.url,
              authtoken: localStorage.getItem("loggedintoken")
            }
      }
      fetch(apiURL, apiParams)
      .then((res)=> (res.status===200 ? res.json() : res.text()))
      .then((repos)=> {
          if (repos.data) {
           
        
          }
        })
        .catch(e => console.log(e)) 
  } 
      }


        function handleFileSelect(f) {
          var reader = new FileReader();
          // Closure to capture the file information.
          reader.onload = (function (theFile) {
            return function (e) {
              var binaryData = e.target.result;
              //Converting Binary Data to base 64
              var base64String = window.btoa(binaryData);
              //showing file converted to base64
              setImg("data:image/jpeg;base64," + base64String);
            };
          })(f);
          // Read in the image file as a data URL.
          reader.readAsBinaryString(f);
        }


        const handlelogout = () =>{
          localStorage.clear();
          setUser(null);
          window.location.href = "/";
        }

        
 //functions
 const addApplicant=()=>{

  if (mobile.length === 0) {
   
    props.showAlert("Enter Mobile Number!!","error");
    return;
  }
  if (name.length === 0) {
      props.showAlert("Enter Name!!","error");
      return;
    }
    if (mail.length === 0) {
  
      props.showAlert("Enter Email!!","error");
      return;
    }
    if (gender.length === 0) {
    
      props.showAlert("Select Gender!!","error");
      return;
    }
  
   setIsLoading(true)

   let apiURL=process.env.REACT_APP_API_URL + "/applicants/addapplicant";
   let apiParams = {
       method:"POST",
       headers:{
           name: name,
           email: mail,
           mobile: mobile,
           number:whatsNumber,
           dob:Dob,
           city:city,
           area:area,
           education:education,
           preferences:JSON.stringify({
            voiceProcess:voiceProcess,
            nonVoiceProcess:nonVoiceProcess,
            workFromHome:workFromHome,
            workFromOffice:workFromOffice,
            partTime:partTime,
            fullTime:fullTime,
            nightShift:nightShift,
            dayShift:dayShift,
            companyTransportDay:companyTransportDay,
            companyTransportNight:companyTransportNight,
            ownTransport:ownTransport,
            willingToRelocate:willingToRelocate,
            }),
           languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
           currentsalary:previousCurrent,
           expectedsalary:expectedSalary,
           experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
           skill:encodeURIComponent(Skills),
           gender: gender,
           jobid: jobId,
           jobtitle: jobTitle,
           companyname:companyName,
           address: encodeURIComponent(address),
           reference:user && user.accesstype !== "c"?encodeURIComponent(reference):"Self Registered",
           createdbyid:user?user._id:"Candidate",
           createdbyname:user?user.name:"Candidate",
           authtoken: localStorage.getItem("loggedintoken")
       }, 
      // body: resume,
   };
   // console.log(apiParams)
   fetch(apiURL, apiParams)
   .then((res)=> (res.status===200 ? res.json() : res.text()))
   .then((repos)=> {
       if (repos.data) {
  
        setApplicantId(repos.data._id)
        
        profilePic && UploadImageToDrive(repos.data._id)
        resume && UploadToDrive(repos.data._id)
       
      
        
        setOpenApplicantPopup(false)
         setIsLoading(false)
         props.showAlert("Applicant Added Successfully","success")
        // alert("Applicant Added Successfull");
        //  if(user && scheduleInterview ){
        //    setTimeout(()=>{
        //      window.location.href="/jobs?applicantid="+repos.data._id;
        //    },1000)
        //  }
        //  else{
        //    setTimeout(()=>{
        //      sendEmailToCandidate(repos.data._id)
        //      window.location.reload(false);
        //    },1500)
        //  }

        setTimeout(()=>{
          handlelogout()
            },1200)

          
       }else{ props.showAlert(""+(repos.message || repos),"error");
       setIsLoading(false)}
   }) 
   .catch((err) =>{props.showAlert((''+err),"error")
   setIsLoading(false)})
 }

 const sendEmailToCandidate = ()=>{
  if(mail){
  let apiURL=process.env.REACT_APP_API_URL + "/users/sendemailtoapplicant";
  let apiParams = {
    method:"POST",
    headers:{
        email:mail,
        name:name,
     }
    };
   
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.message) {
       
          props.showAlert("Email Has been sent Successfully","success")
          
        }else{ props.showAlert(""+(repos.message || repos),"error");
        setIsLoading(false)
      }
  
    }) 
    .catch((err) =>{props.showAlert((''+err),"error")
    setIsLoading(false)})
  }
 }


 
 
 const searchJobByApplicantId = ()=>{
if(id)
{
    let apiURL = process.env.REACT_APP_API_URL +"/interview/getjobsbyapplicantid/"+ id;
  
 fetch(apiURL)
 .then((res)=> (res.status===200 ? res.json() : res.text()))
 .then((repos)=> {
     if (repos.data) {

      setJobId(repos.data[0] && repos.data[0]._id.jobId)
      setJobTitle(repos.data[0] && repos.data[0]._id.jobtitle)
      setJobApplications(repos.data)

      
     }else {
      props.showAlert(""+(repos.message || repos),"error");  
     }
 }) 
 .catch((err) => props.showAlert((''+err),"error"));}
}

const setPreference=(key,value)=>
{
switch (key) {
case "voiceProcess":
  setVoiceProcess(value)
  break;
case "nonVoiceProcess":
  setNonVoiceProcess(value)
  break;
case "workFromHome":
  setWorkFromHome(value)
  break;
case "workFromOffice":
  setWorkFromOffice(value)
  break;
  case "partTime":
    setPartTime(value)
    break;
  case "fullTime":
    setFullTime(value)
    break;
  case "nightShift":
    setNightShift(value)
    break;
  case "dayShift":
    setDayShift(value)
    break;
    case "companyTransportDay":
      setCompanyTransportDay(value)
      break;
      case "companyTransportNight":
        setCompanyTransportNight(value)
        break;
    case "ownTransport":
      setOwnTransport(value)
      break;
default:
  break;
}
}

//edit applicant
const editApplicantDetails=(id)=>{
    setIsLoading(true)
    let apiURL=process.env.REACT_APP_API_URL + "/applicants/editapplicant/"+id;
    let apiParams = {
        method:"POST",
        headers:{
            name: name,
            email: mail,
            mobile: mobile,
            number:whatsNumber,
            dob:Dob,
            city:city,
            area:area,
            education:education,
            preferences:JSON.stringify({
              voiceProcess:voiceProcess,
              nonVoiceProcess:nonVoiceProcess,
              workFromHome:workFromHome,
              workFromOffice:workFromOffice,
              partTime:partTime,
              fullTime:fullTime,
              nightShift:nightShift,
              dayShift:dayShift,
              companyTransportDay:companyTransportDay,
              companyTransportNight:companyTransportNight,
              ownTransport:ownTransport,
              willingToRelocate:willingToRelocate,
              }),
            languages:((language && language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language),
            currentsalary:previousCurrent,
            expectedsalary:expectedSalary,
            experience:(parseInt(ExpYears)*12)+parseInt(ExpMonth),
            skill:encodeURIComponent(Skills),
            gender: gender,
            address: encodeURIComponent(address),
            reference:encodeURIComponent(reference),
            updatedById:user?user._id:"Candidate",
            updatedByName:user?user.name:"Candidate",
            authtoken: localStorage.getItem("loggedintoken")
  
        }
    };
    fetch(apiURL, apiParams)
    .then((res)=> (res.status===200 ? res.json() : res.text()))
    .then((repos)=> {
        if (repos.data) {
          setOpenApplicantPopup(false)
          setIsLoading(false)
         
          profilePic && UploadImageToDrive(id)
          resume && UploadToDrive(id)

          props.showAlert("Applicant Updated Successfully","success")
          // setTimeout(()=>{
          //   if(props.applicantId){
          //     setId(props.applicantId)
        
          //     showApplicant()
      
          //    }else if(props.id){
          //     setId(props.id)
          //     showApplicantDetails(props.id)
          //    }
       
          // },2000)
          
        }else {
          props.showAlert(""+(repos.message || repos),"error");
            setIsLoading(false)
        }
    }) 
    .catch((err) => props.showAlert((''+err),"error"));
  }

  const updateLanguage = (lang) =>{

    var newLanguageValue = (((language.length>0 && language.lastIndexOf(",")===language.length-1)?language.substring(0,language.length-1):language)+lang);
    setLanguage(((newLanguageValue.length>0 && newLanguageValue.indexOf(",")===0)?newLanguageValue.substring(1,newLanguageValue.length-1):newLanguageValue));
    }

    const backtoLogin = ()=>{
      navigate("/");
    }


  return (
<div id="iPhone_14_Pro_Max__1" style={{height:user && ((user.accesstype !=="c")||(user.accesstype ===""))?"2470px":"2460px"}}>
	<svg class="BackColor4">
		<ellipse id="BackColor4" rx="120" ry="120" cx="120" cy="120">
		</ellipse>
	</svg>
	<svg class="BackColor2">
		<ellipse id="BackColor2" rx="120" ry="120" cx="120" cy="120">
		</ellipse>
	</svg>
	<svg class="BackColor3">
		<ellipse id="BackColor3" rx="120" ry="120" cx="120" cy="120">
		</ellipse>
	</svg>
	<div id="Register">
		<span>Register</span>
	</div>
	<svg class="BackColor1">
		<rect id="BackColor1" rx="205.5" ry="205.5" x="0" y="0" width="522.214" height="375">
		</rect>
	</svg>
  <div id="Upload__Image">
		<span>Upload Image
</span>
	</div>

                <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                      <img id="imageUpload1" width="100" height="100" src={img?img:"user3.png"}  alt="Reload?" />
                 </label>

  <div  className='CandidateMobileUI'>
<TextField id="CandidateMobileUI"  sx={{ width: 259,margin:"2px"}}  InputProps={{
            startAdornment: <InputAdornment position="start"><LocalPhoneIcon/></InputAdornment>,
          }}   type="number" size="small" label="Mobile No" variant="outlined"  value={mobile} onBlur={()=>checkduplicate()}
       onChange={(e)=> {
        setMobile(e.target.value);  
    }}/> 
    </div>
    <div className='CandidateNameUI'>
<TextField  id="CandidateNameUI"  sx={{ width: 259, margin:"2px" }}  InputProps={{
            startAdornment: <InputAdornment position="start"><AccountBoxIcon/></InputAdornment>,
          }} size="small" label="Name" variant="outlined"  value={name || ""} onChange={(e)=> {
      setName(e.target.value);
  }}/>
  </div>
  

	<div className='CandidateDOBUI'>
       <input type='date'
      style={{width: 259,margin:"2px" }}
      label="DOB"
      id="CandidateDOBUI"
      value={Dob}
      onChange={(e) => {
      setDob(e.target.value);
    }}
    />

</div>

  <div className='CandidateGenderUI'>
 <FormControl variant="outlined" sx={{minWidth: 259,margin:"2px"}}  size="small" >
    <InputLabel id="CandidateGenderUI"  ><TransgenderIcon/>&nbsp; Gender</InputLabel>
    <Select
      labelId="demo-simple-select-labell"
      id="demo-simple-select"
        
      sx={{minWidth: 219}}
      value={gender} onChange={(e)=> {
      
        setGender(e.target.value);   

       } } >
         <MenuItem selected disabled>
       
      </MenuItem>
    
      <MenuItem value="male">Male</MenuItem>
      <MenuItem value="female">Female</MenuItem>
      <MenuItem value="others">Others</MenuItem>
    </Select>
  </FormControl> 
</div>

  <div className="CandidateEmailUI">
<TextField id="CandidateEmailUI" sx={{width: 259,margin:"2px" }}  InputProps={{
            startAdornment: <InputAdornment position="start"><EmailIcon/></InputAdornment>,
          }}  size="small" label="Email" variant="outlined"  value={mail || ""} onChange={(e)=> {
    setMail(e.target.value);     
  }}/>
  </div>

  <div className='WhatsappNumberUI'>
<TextField  id="WhatsappNumberUI" sx={{width: 259,margin:"2px"}}  InputProps={{
            startAdornment: <InputAdornment position="start"><WhatsAppIcon/></InputAdornment>,
          }} size="small" label="Whatsapp No" variant="outlined"  value={whatsNumber} onChange={(e)=> {
        setWhatsNumber(e.target.value)  }}/>
 </div>
	
		
	
  <div id="Experience_reg_mob">
		<span>Experience</span>
	</div>
	
  <div className='ExpYearsUI'>
<TextField id="ExpYearsUI" sx={{ m: 0.5, width: '10ch' }} label="Years" variant="outlined" size="small" value={ExpYears} onChange={(e)=> {
      setExpYears(e.target.value);
    }}/>
</div>
	
  <div className='ExpMonthsUI'>
<TextField id="ExpMonthsUI" sx={{ m:0.5, width: '10ch' }} label="Months" variant="outlined" size="small" value={ExpMonth} onChange={(e)=> {
      
      setExpMonths(e.target.value);
    }}/>
</div>

<div className='CandidateCityUI'>
<FormControl variant="outlined" sx={{minWidth: 259,margin:"2px"}}   size="small" >
    <Autocomplete
      disablePortal
      id="CandidateCityUI"
      options={City}
      value={city || ""}
   
      onChange={(event,value)=>setCity(value.label)}
      renderInput={(params) => <TextField {...params}  
          sx={{width: 259,margin:"2px"}}
          size="small"  
          label={
        <Fragment>
          City
          <LocationCityIcon fontSize="small" />
        </Fragment>
      }
         
          value={city || ""}
          
          onChange={(e)=> {
            setCity(e.target.value);
          }}  />}
         /> 
         </FormControl>
</div>


  <div className='CandidateAreaUI'>
  <TextField  id="CandidateAreaUI" sx={{width: 259,margin:"2px"}}
        InputProps={{
            startAdornment: <InputAdornment position="start"><ApartmentIcon/></InputAdornment>,
          }} 
          size="small"
           label="Area" 
           variant="outlined"
             value={area} onChange={(e)=> {
            setArea(e.target.value)  }}/>
 </div>

	
  <div className='CurrentSalaryUI'>
  <TextField 
    sx={{width: 259,margin:"2px" }}  id="CurrentSalaryUI"
          InputProps={{
            startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon/></InputAdornment>,
          }} 
          size="small" 
          label="Current Salary"
         variant="outlined"
         value={previousCurrent} onChange={(e)=> {
         setPreviousCurrent(e.target.value)
 }} />
	</div>

  <div className='ExpectedSalaryUI'>
  <TextField 
    sx={{width:259,margin:"2px" }}  id="ExpectedSalaryUI" 
    InputProps={{
            startAdornment: <InputAdornment position="start"><CurrencyRupeeIcon/></InputAdornment>,
          }} 
           size="small"
            label="Expected Salary" 
            variant="outlined" 
             value={expectedSalary} onChange={(e)=> {
         setExpectedSalary(e.target.value)
 }} /> 
  </div>

  	{/* <svg class="Rectangle_5">
		<rect id="Rectangle_5" rx="12" ry="12" x="0" y="0" width="259" height="44">
		</rect>
	</svg> */}

  <div className='CandidateLanguagesUI'>
  <TextField 
      sx={{width: "310px",marginTop:"2px", }}  id="CandidateLanguagesUI"  
       InputProps={{
            startAdornment: <InputAdornment position="start"><LanguageIcon/></InputAdornment>,
          }}
           size="small" label="Languages" variant="outlined"  value={language} onChange={(e)=> {
          setLanguage(e.target.value.length>0&&e.target.value.slice(0,1)===","?language.substring(1,e.target.value.length):e.target.value);

 }} /> 

       <div style={{textAlign:"center",fontSize:"small",marginTop:"3px"}}>
      
    {(language||"").includes("English")?"":  <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",English,")}>English</button>}
    {(language||"").includes("Hindi")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Hindi,")}>Hindi</button>}
    {(language||"").includes("Kannada")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Kannada,")}>Kannada</button>}
    {(language||"").includes("Urdu")?"":   <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Urdu,")}>Urdu</button>}
    {(language||"").includes("Tamil")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Tamil,")}>Tamil</button>}
    {(language||"").includes("Telugu")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Telugu,")}>Telugu</button>}
    {(language||"").includes("Malayalam")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Malayalam,")}>Malayalam</button>}
    {(language||"").includes("Marathi")?"":     <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Marathi,")}>Marathi</button>}
    {(language||"").includes("Punjabi")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Punjabi,")}>Punjabi</button>}
    {(language||"").includes("Bangla")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Bangla,")}>Bangla</button>}
    {(language||"").includes("Arabic")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Arabic,")}>Arabic</button>}
    {(language||"").includes("French")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",French,")}>French</button>}
    {(language||"").includes("Spanish")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Spanish,")}>Spanish</button>}
    {(language||"").includes("Russian")?"":    <button style={{margin:"2px",border:"1px solid", padding:"2px 6px"}} onClick={e=>updateLanguage(",Russian,")}>Russian</button>}
    <button style={{margin:"2px",border:"1px solid black", padding:"2px 6px"}} onClick={e=>setLanguage("")}>Clear</button>
      <br/></div>
      </div>
	
  <div className='CandidateEducationUI'>
  <TextField 
      sx={{width: "310px",marginTop:"2px", }}  id="CandidateEducationUI" 
       InputProps={{
            startAdornment: <InputAdornment position="start"><SchoolIcon/></InputAdornment>,
          }} 
           size="small" label="Education" variant="outlined"  value={education} onChange={(e)=> {
      setEducation(e.target.value)
       }} /> 
   </div>
	

  <div className='CandidateSkillsUI'>
  <TextField  id="CandidateSkillsUI" multiline rows={2} 
   InputProps={{
            startAdornment: <InputAdornment position="start"><PsychologyIcon/></InputAdornment>,
          }}
           sx={{minWidth: 310 ,marginTop:"2px"}} 
           size="small"
            label="Skills" 
            variant="outlined" 
             value={Skills || ""} onChange={(e)=> {
    setSkills(e.target.value);     
  }}/>

  </div>
  {!user ? <div>
  <img onClick={()=>backtoLogin()} id="undo" src="undo.png" srcset="undo.png 1x, undo@2x.png 2x" />	
</div>:""}
	
<div id="AddressUI">
<div className='CandidateAddressUI'>
<TextField
    id="CandidateAddressUI"
    sx={{minWidth: 310 ,marginTop:"2px"}}
    InputProps={{
          startAdornment: <InputAdornment position="start"><HomeIcon/></InputAdornment>,
        }} 
    size="small"
    label="Address"
    multiline
    rows={2} value={address || ""} 
    onChange={(e)=> {
    setAddress(e.target.value);
  }} />
</div>
</div>
  <div className='ResumeUploadMobile' style={{fontSize:'26px'}}>
         Resume 
<FileUploadIcon style={{marginLeft:"15px",color:"cornflowerblue"}}  onClick={(e)=>document.getElementById('uploadResume').click()}/>
         <input  id="uploadResume" type="file" placeholder="Upload Resume" style={{width:"0px"}} name="uploadResume" onChange={(e) => handleUpload(e)}></input>

{resumeUrl && resumeUrl.length > 50?<a  id="downloadResume"  style={{width:"0px"}} name="downloadResume" href={resumeUrl} rel="noreferrer" target="_blank" ><FileDownloadIcon style={{marginLeft:"15px",color:"cornflowerblue"}}  onClick={(e)=>document.getElementById('downloadResume').click()}/> </a>:""}
        

</div>

	

  
	<div id="PreferencesMobile">
		<span>Preferences</span>
	</div>
	<WorkPreferencesMobileUI type={props.type} preferences={{voiceProcess,nonVoiceProcess,fullTime,partTime,workFromOffice,workFromHome,dayShift,nightShift,companyTransportDay,companyTransportNight,ownTransport}} setPreference={(key,value)=>setPreference(key,value)}/>

	
  <div className='chkWillingtorelocate_mobile'>
  <input type="checkbox" id="chkWillingtorelocate" name="chkWillingtorelocate_mobile" checked={willingToRelocate} onChange={()=>setWillingToRelocate(!willingToRelocate)}/>
  </div>
  <label id='AppWilling_to_Relocate_Mobile_lbl' for="chkWillingtorelocate" style={{color:willingToRelocate?"red":"black"}}>Willing To Relocate</label>

	<div id="Transportation">
		<span>Transportation</span>
	</div>

	
  {user && user.accesstype !== "c"?
		<div className='CandidateReference_mobile'>
  <Autocomplete
  disablePortal
  id="CandidateReference_mobile"
  freeSolo
  options={Reference}
  sx={{ width: 290}}
  value={reference || ""}
  onChange={(event,value)=>setReference(value.label)}
  renderInput={(params) => <TextField {...params}  
   
      sx={{minWidth: 290 }}
      size="small" label={
        <Fragment>
          Reference
          <PersonSearchIcon fontSize="small" />
        </Fragment>
      }
      value={reference || ""}
      onChange={(e)=> {
        setReference(e.target.value); 
      }}  
        
      />}
     />

</div> :""}

 { <div className='BtnSubmit_mobile_btn' style={{top:user && ((user.accesstype !=="c")||(user.accesstype ===""))?"2385px":"2330px"}}>
  <Button  disabled={isLoading} type="reset" title={"Edit Add "}  sx={{ m: 1, width: '36ch',backgroundColor:"rgba(90,98,236,1)" }}  variant="contained" onClick={()=>id.length>0?editApplicantDetails(id):addApplicant()}>{id.length>0?"Update":user && user._id?"Add Applicant":"Register"}</Button>
  {isLoading ? <CircularProgress/>:""}
  </div>}


</div>
  )
  
}

export default RegisterMobileUI
