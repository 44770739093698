import React, { useState, useEffect, useContext } from "react";
import Modal from "@mui/material/Modal";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "./UserContext";
import { CircularProgress } from "@mui/material";
import { Autocomplete } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import Switch from '@mui/material/Switch';
import States from "./States.json";
import './AddRecCitiesPopUpUI.css';


const AddRecCitiesPopUpUI = (props) => {
  const [searchParams] = useSearchParams();
  const [id, setId] = useState("");
  const [openOLPopUp, setOpenOLPopUp] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState(true);
  const [state, setState] = useState("Karnataka");
  const [city, setCity] = useState("Bangalore");
  const [area, setArea] = useState("");
  const [company, setCompany] = useState("");
  const [office, setOffice] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [locations, setLocations] = useState([]);
  const [mapUrl,setMapUrl] = useState("");
  const [documentUrl,setDocumentUrl] = useState("");
  const [clawPeriod,setClawPeriod] = useState(0);
  const [img,setImg] = useState("");
  const [profilePic,setProfilePic]= useState("");
  const [aldocument,setALdocument] = useState("");
  const [alDName,setAlDName] = useState("");
  const { user } = useContext(UserContext);

useEffect(()=>{
if(user){
    if(props.open){
        setOpenOLPopUp(props.open)
        if(props.selectedRowData){
           
            setId(props.selectedRowData.id);
    setActive(props.selectedRowData.active);
    setState(props.selectedRowData.state);
    setCity(props.selectedRowData.city);
   
    setLongitude(props.selectedRowData.longitude);
    setLatitude(props.selectedRowData.latitude);
    setMapUrl(props.selectedRowData.mapUrl);
    if(props.selectedRowData.citiesLogoUrl && props.selectedRowData.citiesLogoUrl.length>50){
      setImg("https://drive.google.com/uc?export=view&id="+props.selectedRowData.citiesLogoUrl.split('/')[5])
      // console.log("https://drive.google.com/uc?export=view&id="+repos.data.profilePicUrl.split('/')[5])
                    }
                  
        }
    }
}

},[props.open])


const handleClose = () =>{
   
    resetApplicant();
    setOpenOLPopUp(false);
    props.close();
    
}

const resetApplicant = () => {
    setId("");
    setImg("");
    setState("");
    setCity("");
    setLongitude("");
    setLatitude("");

    setMapUrl("");
  };


const handleImageUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    handleFileSelect(e.target.files[0]);

    setProfilePic(e.target.files[0]);
   
  };
  function handleFileSelect(f) {
    var reader = new FileReader();
    // Closure to capture the file information.
    reader.onload = (function (theFile) {
      return function (e) {
        var binaryData = e.target.result;
        //Converting Binary Data to base 64
        var base64String = window.btoa(binaryData);
        //showing file converted to base64
        setImg("data:image/jpeg;base64," + base64String);
      };
    })(f);
    // Read in the image file as a data URL.
    reader.readAsBinaryString(f);
  }

    //Upload Image
    const UploadImageToDrive = async (CitiesId,value,document) =>{
     
        var file = document //the file
        var reader = new FileReader() //this for convert to Base64 
        reader.readAsDataURL(document) //start conversion...
        reader.onload = function (e) { //.. once finished..
          var rawLog = reader.result.split(',')[1]; //extract only thee file data part
          var dataSend = { dataReq: { data: rawLog, name:file.name, type: file.type }, fname: "uploadFilesToGoogleDrive" }; //preapre info to send to API
           fetch(process.env.REACT_APP_GOOGLEDRIVE_URL, //your AppsScript URL
            { method: "POST", body: JSON.stringify(dataSend) }) //send to Api
            .then(res => res.json()).then((data) => {
              //See response
            
               SaveProfilePic(data,CitiesId,value)
              props.showAlert("Documents Uploaded Successfully","success")
      
            }).catch(err => console.log(err)) // Or Error in console
        }
      }

      const SaveProfilePic = (data,CitiesId,value)=>{
    
        if(CitiesId){
        
                let apiURL=process.env.REACT_APP_API_URL + "/cities/saveCitiesLogoUrl/" + CitiesId;
                let apiParams = {
                    method:"POST",
                    headers:{
                      citieslogourl:data.url,
                      value:value,
                      authtoken: localStorage.getItem("loggedintoken")
                    }
              }
              fetch(apiURL, apiParams)
              .then((res)=> (res.status===200 ? res.json() : res.text()))
              .then((repos)=> {
                  if (repos.data) {
                   
                
                  }
                })
                .catch(e => console.log(e)) 
          } 
              }
        

  const UpdateOfficeLocations = (id) => {

    let apiURL = process.env.REACT_APP_API_URL + "/cities/updaterecruitercities/" + id;
    let apiParams = {
      method: "POST",
      headers: {
        state: state,
        city: city,
        latitude: latitude,
        longitude: longitude,
        mapurl:mapUrl,
        active: active,
        authtoken: localStorage.getItem("loggedintoken")
      },
    
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            handleClose()
          setIsLoading(false);
         
          profilePic && UploadImageToDrive(repos.data._id,"logo",profilePic)
        
          props.showAlert("Updated Successfully!!!", "success");
        
        }
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };

  const addOfficeLocations = () => {
    let apiURL = process.env.REACT_APP_API_URL + "/cities/addrecruitercities";
    let apiParams = {
      method: "POST",
      headers: {
        state: state,
        city: city,
       
        latitude: latitude,
        longitude: longitude,
        mapurl:mapUrl,
        // recruiterid:user._id,
        // recruitername:user.name,
        active: active,
        authtoken: localStorage.getItem("loggedintoken")
      },
      body:profilePic,
    };
    fetch(apiURL, apiParams)
      .then((res) => (res.status === 200 ? res.json() : res.text()))
      .then((repos) => {
        if (repos.data) {
            handleClose()
          setIsLoading(false);
         
          profilePic && UploadImageToDrive(repos.data._id,"logo",profilePic)
         
          props.showAlert("Office Locations Added Successfully", "success");
        
        }
        else{
               props.showAlert(repos.message||repos||"Error","error");
        }
      })
      .catch((err) => props.showAlert("" + err, "error"));
  };


  const alDocUpload = async (e) => {
    if (e.target.files[0].size > 71680) {
      //alert("Please choose a file with size below 70kb!");
      props.showAlert("Please choose a file with size below 70kb!","error")
      return;
    }
    // handleCRDFileSelect(e.target.files[0]);
    alert("File Selected is "+e.target.files[0].name)
    setAlDName(e.target.files[0].name)
    setALdocument(e.target.files[0]);
   
  };

  


  return (
    <div>
       <Modal
          open={openOLPopUp}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
<div id="city_location">
	<img id="bdr_design_pfp_1_rec_cities" src="bdr_design_pfp_1_rec_cities.png" srcset="bdr_design_pfp_1_rec_cities.png 1x" />
		
	<div id="bg_design_grp_rec_cities">
		<svg class="bg_design_2_rec_cities" viewBox="1805.416 80.996 349.05 146.684">
			<path id="bg_design_2_rec_cities" d="M 2135.64599609375 81 C 2116.695068359375 81.37442016601562 1820.19140625 81 1820.19140625 81 C 1820.19140625 81 1812.0615234375 81.42062377929688 1807.897705078125 90.31346130371094 C 1804.803955078125 96.919189453125 1805.478271484375 111.1990280151367 1805.478271484375 118.5688171386719 C 1805.478271484375 135.2425537109375 2154.46630859375 227.6797332763672 2154.46630859375 227.6797332763672 L 2154.46630859375 104.5640258789062 C 2154.46630859375 104.5640258789062 2154.596923828125 80.62557983398438 2135.64599609375 81 Z">
			</path>
		</svg>
		<svg class="bg_design_1_rec_cities" viewBox="1805.416 80.996 349.05 146.684">
			<path id="bg_design_1_rec_cities" d="M 1824.236572265625 81 C 1843.1875 81.37442016601562 2139.69091796875 81 2139.69091796875 81 C 2139.69091796875 81 2147.82080078125 81.42062377929688 2151.98486328125 90.31346130371094 C 2155.078369140625 96.919189453125 2154.404052734375 111.1990280151367 2154.404052734375 118.5688171386719 C 2154.404052734375 135.2425537109375 1805.416259765625 227.6797332763672 1805.416259765625 227.6797332763672 L 1805.416259765625 104.5640258789062 C 1805.416259765625 104.5640258789062 1805.28564453125 80.62557983398438 1824.236572265625 81 Z">
			</path>
		</svg>
	</div>
	<svg class="pfp_behind_circle_rec_cities">
		<ellipse id="pfp_behind_circle_rec_cities" rx="50" ry="50" cx="50" cy="50">
		</ellipse>
	</svg>
  <img id="close_cities" src="close_cities.png" srcset="close_cities.png 1x" onClick={()=>handleClose()}/>

  <label for="image">
                        <input id="image" type="file" name="image"  hidden accept="image/*" onChange={(e) => handleImageUpload(e)}/>
                      <img id="profile_pfp_img_rec_cities" width="100" height="100" src={img?img:"profile_pfp_img_rec_cities.png"} alt="Reload?" style={{border:"1px solid black",objectFit:"contain"}}/>
      </label>
	{/* <img id="profile_pfp_img_rec_cities" src="profile_pfp_img_rec_cities.png" srcset="profile_pfp_img_rec_cities.png 1x" /> */}
		
	<div id="active_lbl_rec_cities">
		<span>Active</span>
	</div>
	
	{/* <svg class="active_chkbx_rec_cities">
		<rect id="active_chkbx_rec_cities" rx="0" ry="0" x="0" y="0" width="38" height="20">
		</rect>
	</svg> */}

  <div className="active_chkbx_rec_cities">
  <Switch
      id="active_chkbx_rec_cities"
      checked={active}
      onChange={(e)=>setActive(e.target.checked)}
      inputProps={{ 'aria-label': 'controlled' }}
    />
  </div>

	<div id="state_grp_prof_rec_cities">
		<div id="state_lbl_pfp_rec_cities">
			<span>State</span>
		</div>
		<select class="state_input_pfp_rec_cities" value={state}
                              
                              onChange={(e) => {
                                setState(e.target.value);
                              }}
                            >
                            <option></option>
                              {States &&
                                States.map((state) => (
                                  <option value={state.name}>
                                    {state.name}
                                  </option>
                                ))}
		
		</select>
	</div>
	<div id="city_grp_prof_rec_cities">
		<div id="city_lbl_pfp_rec_cities">
			<span>City</span>
		</div>
		<input class="city_input_pfp_rec_cities" value={city || ""}
                              onChange={(e) => {
                                setCity(e.target.value);
                              }}/>
		
	</div>
	<div id="latitiude_grp_rec_cities" >
		<div id="latitude_lbl_rec_cities">
			<span>Latitude</span>
		</div>
		<input class="latitude_input_rec_cities" value={latitude}
                              onChange={(e) => {
                                setLatitude(e.target.value);
                              }}/>
		
	</div>
	<div id="long_grp_rec_cities">
		<div id="long_lbl_rec_cities">
			<span>Longitude</span>
		</div>
		<input class="long_input_rec_cities" value={longitude || ""}
                              onChange={(e) => {
                                setLongitude(e.target.value);
                              }}/>
			
	</div>
	<div id="location_grp_cities">
		<div id="location_lbl_cities">
			<span>Maps Location URL</span>
		</div>
		<input class="location_input_cities" value={mapUrl || ""}
                              onChange={(e) => {
                                setMapUrl(e.target.value);
                              }}/>
	</div>

  <button id="change_pass_btn_grp_rec_cities" onClick={() =>
                          id.length > 0
                            ? UpdateOfficeLocations(id)
                            : addOfficeLocations()
                        }>
		<svg class="passs_bdr_btn">
			<rect id="passs_bdr_btn" rx="8" ry="8" x="0" y="0" width="123" height="41">
			</rect>
		</svg>
		<div id="Change_Password_lbl">
			<span>{id.length > 0 ? "EDIT" : "ADD"}</span>
		</div>
	</button>
		
</div>
        </Modal>
    </div>
  )
}

export default AddRecCitiesPopUpUI
